import React from 'react'

import { Box } from 'ui/components/Box'
import { Link } from 'ui/components/Link'
import { makeLineTruncationStyle } from 'ui/helpers/utilities'

import { useLongTextValueState } from './hooks/useLongTextValueState'

type LongTextValueProps = React.ComponentPropsWithoutRef<typeof Box> & {
    textValue?: string
    maxLines?: number
    renderMoreLink?: (renderOpts: {
        showFullText: boolean
        onClick: React.MouseEventHandler<HTMLElement>
    }) => React.ReactNode
}

export const LongTextValue: React.FC<LongTextValueProps> = ({
    textValue,
    maxLines,
    children,
    renderMoreLink,
    ...props
}) => {
    const { wrapperRef, isTruncated, showFullText, toggleShowFullText } = useLongTextValueState({
        textValue,
        maxLines,
    })

    const showMoreLink = isTruncated || showFullText

    return (
        <Box {...props}>
            <Box
                ref={wrapperRef}
                maxWidth="full"
                minWidth={0}
                style={{
                    ...(showFullText ? {} : makeLineTruncationStyle(maxLines)),
                    wordBreak: 'break-word',
                    whiteSpace: 'normal',
                }}
            >
                {children}
            </Box>
            {showMoreLink && !renderMoreLink && (
                <Link onClick={toggleShowFullText}>{showFullText ? 'read less' : 'read more'}</Link>
            )}
            {showMoreLink &&
                renderMoreLink &&
                renderMoreLink({ showFullText, onClick: toggleShowFullText })}
        </Box>
    )
}
