import React from 'react'

import { useControllableState } from '@radix-ui/react-use-controllable-state'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

type TabItem = {
    label: string
    value: string
    children: React.ReactNode
    disabled?: boolean
}

type UseDetailViewTabControlStateProps = {
    children: React.ReactNode
}

export function useDetailViewTabControlState({ children }: UseDetailViewTabControlStateProps) {
    const tabs = React.Children.toArray(children).reduce<TabItem[]>((acc, child) => {
        if (React.isValidElement(child)) {
            const childComponentName = typeof child.type === 'string' ? child.type : child.type.name
            if (childComponentName !== 'DetailViewTabControlItem') return acc

            const { value, label, children, disabled } = child.props
            if (!value) return acc

            acc.push({ value, label, children, disabled })
        }

        return acc
    }, [])

    const defaultValue = tabs.find((tab) => !tab.disabled)?.value
    const [value, setValue] = useControllableState({
        defaultProp: defaultValue,
    })

    return useDeepEqualsMemoValue({ value, setValue, tabs })
}
