import React from 'react'

import { Box } from 'ui/components/Box'
import { Field } from 'ui/components/Field'
import { Input } from 'ui/components/Input'
import { Body } from 'ui/components/Text'
import { Toggle } from 'ui/components/Toggle'

type EmailRendererConfigProps = {
    config: Record<string, any>
    onChange: (config: Record<string, any>) => void
}

export function EmailRendererConfig({ config, onChange }: EmailRendererConfigProps) {
    // This is just an example of a renderer config for an email. Not used yet.
    return (
        <Box flex column gap="m">
            <Field label="Display Options">
                <Box flex column gap="s">
                    <Box flex alignItems="center" gap="s">
                        <Toggle
                            checked={config.showHeaders ?? true}
                            onCheckedChange={(checked) =>
                                onChange({ ...config, showHeaders: checked })
                            }
                        />
                        <Body>Show Headers</Body>
                    </Box>
                    <Box flex alignItems="center" gap="s">
                        <Toggle
                            checked={config.showAttachments ?? true}
                            onCheckedChange={(checked) =>
                                onChange({ ...config, showAttachments: checked })
                            }
                        />
                        <Body>Show Attachments</Body>
                    </Box>
                </Box>
            </Field>

            <Field label="Max Width">
                <Input
                    type="number"
                    value={config.maxWidth ?? 800}
                    onChange={(e) => onChange({ ...config, maxWidth: parseInt(e.target.value) })}
                    placeholder="Enter max width in pixels"
                />
            </Field>

            <Field label="Font Size">
                <Input
                    type="number"
                    value={config.fontSize ?? 14}
                    onChange={(e) => onChange({ ...config, fontSize: parseInt(e.target.value) })}
                    placeholder="Enter font size in pixels"
                />
            </Field>
        </Box>
    )
}
