import React from 'react'

import { ErrorBoundary } from 'app/ErrorBoundaries'
import { Frame } from 'features/core/Frame'

import { LayoutEditorCanvas } from './LayoutEditorCanvas'
import { LayoutEditorFrame } from './LayoutEditorFrame'

type LayoutEditorProps = {
    page: PageDto
    view: ViewDto
}

export const LayoutEditor: React.FC<LayoutEditorProps> = React.memo(function LayoutEditor({
    page,
    view,
}) {
    return (
        <Frame page={page} view={view} paddingOverride overrideShowBreadcrumbs={false}>
            <LayoutEditorFrame>
                <ErrorBoundary app>
                    <LayoutEditorCanvas viewSid={view._sid} />
                </ErrorBoundary>
            </LayoutEditorFrame>
        </Frame>
    )
})
