import { useMutation, UseMutationOptions } from 'react-query'

import { fetchAndReturn } from 'data/utils/utils'

import { useToast } from 'ui/components/Toast'

import { invalidateExtDataIntegrations } from './useExtDataIntegrations'

type DeleteExtConnectionResponse = {
    success: boolean
}

type useDeleteExtConnectionInput = {
    nangoConnectionId: string
    integrationId: ExternalIntegrationId
}

export function useDeleteExtConnection(
    options: UseMutationOptions<
        DeleteExtConnectionResponse,
        unknown,
        useDeleteExtConnectionInput
    > = {}
) {
    const toast = useToast()
    return useMutation(
        async ({ nangoConnectionId, integrationId }: useDeleteExtConnectionInput) => {
            return fetchAndReturn('data-connections/integrations/', {
                method: 'DELETE',
                headers: {
                    'Content-type': 'application/json',
                },
                body: JSON.stringify({
                    nango_connection_id: nangoConnectionId,
                    integration_id: integrationId,
                }),
            }) as Promise<DeleteExtConnectionResponse>
        },
        {
            ...options,
            onSettled: async () => {
                await invalidateExtDataIntegrations()
            },
            onError: () => {
                toast({
                    title: 'There was a problem disconnecting your external account. Please try again or contact support.',
                    type: 'error',
                })
            },
        }
    )
}
