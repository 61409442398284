import React from 'react'

import { UserAttributeDisplay } from 'features/views/attributes/UserAttributeDisplay'
import { useDetailViewAttributeValue } from 'features/views/DetailView/attributes/hooks/useDetailViewAttributeValue'

import { BaseAttribute } from './BaseAttribute'
import { PrimaryHighlightsAttributeComponent } from './types'

type UserAttributeProps = {}

export const UserAttribute: PrimaryHighlightsAttributeComponent<UserAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const value = useDetailViewAttributeValue<UserRefDto>(field.api_name)
    const isEmpty = !isLoading && !value

    return (
        <BaseAttribute {...props} field={field} isEmpty={isEmpty} isLoading={isLoading}>
            <UserAttributeDisplay
                value={value}
                field={field}
                isLoading={isLoading}
                maxLines={1}
                avatarSize="s"
                size={{
                    mobile: 'm',
                    tablet: 'l',
                }}
                alwaysShowOverflowTooltip={{
                    mobile: false,
                    tablet: true,
                }}
                maxWidth="full"
                minWidth={0}
                trim
                style={{
                    color: 'inherit',
                }}
            />
        </BaseAttribute>
    )
}
