import React from 'react'

import { PercentageAttributeDisplay } from 'features/views/attributes/PercentageAttributeDisplay'
import { PercentageDisplay } from 'features/views/attributes/types'

import { BaseAttribute } from './BaseAttribute'
import { MAX_LENGTH, MAX_LINES } from './constants'
import { ThreadViewAttributeComponent } from './types'

type PercentageAttributeProps = {}

export const PercentageAttribute: ThreadViewAttributeComponent<
    number,
    PercentageAttributeProps
> = ({ value, attribute, isLoading, ...props }) => {
    const {
        field,
        rawOptions: { percentageDisplay },
        type,
    } = attribute

    const isEmpty = !isLoading && typeof value === 'undefined'

    const isMessage = type === 'message'
    const isTitle = type === 'title'
    const isEyebrow = type === 'eyebrow'
    const isFooter = type === 'footer'

    let maxLength: number | undefined = MAX_LENGTH
    if (isMessage || isTitle) {
        maxLength = undefined
    }

    let maxLines: number | undefined = MAX_LINES
    if (isMessage) {
        maxLines = undefined
    }

    return (
        <BaseAttribute attribute={attribute} isEmpty={isEmpty} isLoading={isLoading} {...props}>
            <PercentageAttributeDisplay
                alwaysShowOverflowTooltip={!isMessage}
                grow={percentageDisplay === 'percentBar'}
                value={value}
                valueDisplay={percentageDisplay as PercentageDisplay}
                field={field}
                whiteSpace="nowrap"
                isLoading={isLoading}
                trim={!isMessage}
                maxLength={maxLength}
                maxLines={maxLines}
                py="3xs"
                style={{
                    display: isEyebrow || isFooter ? 'block' : undefined,
                }}
            />
        </BaseAttribute>
    )
}
