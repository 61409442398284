import React, { useCallback, useEffect, useState } from 'react'

import { useSystemTools } from 'data/hooks/agents/skills'
import { SystemTool, ToolConfig } from 'data/hooks/agents/types'

import { Box } from 'ui/components/Box'
import {
    Modal,
    ModalContent,
    ModalContentInner,
    ModalHeader,
    ModalPortal,
    ModalTrigger,
} from 'ui/components/Modal'

import { ConfigureToolForm } from './ConfigureToolForm'
import { ConfigureToolFormWrapper } from './ConfigureToolFormWrapper'
import { getToolDisplayConfig } from './ToolDisplayConfig'
import { ToolListItem } from './ToolListItem'

type ConfigureToolModalProps = {
    children: React.ReactNode
    config?: ToolConfig
    onSubmit: (config: ToolConfig) => void
    agentSid: string
}

export function ConfigureToolModal({
    children,
    config,
    onSubmit,
    agentSid,
}: ConfigureToolModalProps) {
    const { data: systemTools = [] } = useSystemTools()
    const [screen, setScreen] = useState<'select' | 'configure'>(config ? 'configure' : 'select')
    const [selectedTool, setSelectedTool] = useState<SystemTool | null>(null)
    const [isOpen, setIsOpen] = useState(false)

    const resetValues = useCallback(() => {
        setSelectedTool(systemTools.find((tool) => tool.api_name === config?.tool_id) || null)
    }, [config?.tool_id, systemTools])

    const reset = useCallback(() => {
        setSelectedTool(null)
        if (config) {
            setScreen('configure')
        } else {
            setScreen('select')
        }

        resetValues()
    }, [config, resetValues])

    useEffect(() => {
        if (!isOpen) {
            reset()
        }
    }, [isOpen, reset])

    const handleToolSelect = (tool: SystemTool) => {
        setSelectedTool(tool)
        setScreen('configure')
    }

    const handleSubmit = (toolConnection: ToolConfig) => {
        onSubmit(toolConnection)
        setIsOpen(false)
    }

    useEffect(() => {
        if (config) {
            resetValues()
        }
    }, [config, resetValues, systemTools])

    const sortedSystemTools = systemTools.sort((a, b) => {
        const aConfig = getToolDisplayConfig(a.api_name)
        const bConfig = getToolDisplayConfig(b.api_name)
        return aConfig.sortOrder - bConfig.sortOrder
    })

    return (
        <Modal open={isOpen} onOpenChange={setIsOpen}>
            <ModalTrigger asChild>{children}</ModalTrigger>
            <ModalPortal>
                <ModalContent maxHeight="full" flex column>
                    <ModalHeader
                        title={screen === 'select' ? 'Select Tool' : 'Configure Tool'}
                        showBackButton={screen === 'configure'}
                        onBackButtonClick={() => setScreen('select')}
                    />
                    <ModalContentInner overflowY="auto" fullHeight shrink>
                        {screen === 'select' && (
                            <Box flex column gap="m" pb="l">
                                <Box display="flex" column gap="m">
                                    {sortedSystemTools.map((tool) => (
                                        <ToolListItem
                                            key={tool.api_name}
                                            tool={tool}
                                            onClick={() => handleToolSelect(tool)}
                                        />
                                    ))}
                                </Box>
                            </Box>
                        )}
                        {screen === 'configure' && selectedTool && (
                            <ConfigureToolFormWrapper tool={selectedTool} config={config}>
                                <ConfigureToolForm
                                    agentSid={agentSid}
                                    tool={selectedTool}
                                    onSubmit={handleSubmit}
                                />
                            </ConfigureToolFormWrapper>
                        )}
                    </ModalContentInner>
                </ModalContent>
            </ModalPortal>
        </Modal>
    )
}
