import React from 'react'

import { TriggerType } from 'data/hooks/agents/types'

import { Box, BoxProps } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Body } from 'ui/components/Text'

import { TRIGGER_TYPES } from './constants'
import { TriggerAvatar } from './TriggerAvatar'

type TriggerListItemProps = BoxProps & {
    type: TriggerType['type']
    name?: string
    onDelete?: () => void
}

export function TriggerListItem({ type, name, onDelete, ...props }: TriggerListItemProps) {
    const triggerType = TRIGGER_TYPES[type]

    return (
        <Box flex role="button" gap="m" center border px="m" py="s" {...props}>
            <TriggerAvatar triggerType={type} />
            <Box flex column grow minWidth={0}>
                <Body weight="bold" trim>
                    {name || triggerType?.name || 'Unknown'}
                </Body>
                <Body size="s" trim style={{ minWidth: 0 }}>
                    {triggerType?.description || 'Unknown'}
                </Body>
            </Box>
            {onDelete && (
                <Button
                    variant="ghost"
                    size="xs"
                    iconOnly
                    title="Delete tool"
                    startIcon={{ name: 'Trash' }}
                    onClick={(e) => {
                        e.preventDefault()
                        onDelete()
                    }}
                />
            )}
        </Box>
    )
}
