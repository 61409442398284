import React, { useMemo } from 'react'

import { getConditionSupportedRHSType } from 'features/conditions/utils'
import { relativeFiltersLabelsByValue } from 'features/records/components/relativeFiltersLabelsByValue'
import { useWorkflowEditorContext } from 'features/workflows/useWorkflowEditorContext'
import { WorkflowExpressionInput } from 'features/workflows/WorkflowExpressionInput'

import { Body } from 'ui/components/Text'

export function FilterValueExpression({
    field,
    value,
    operator,
    onChange,
    editMode,
}: {
    field: FieldDto
    value: WorkflowExpression | string
    operator: FilterOperation
    onChange: (value: WorkflowExpression | undefined) => void
    editMode?: boolean
}) {
    const { contextSchema } = useWorkflowEditorContext()
    const { type: rhsType, typeOptions: rhsTypeOptions } = getConditionSupportedRHSType(
        field.type,
        field.options?.options,
        operator
    )
    const rightSlotContent = useMemo(() => {
        if (['withinLast', 'withinNext'].includes(operator)) {
            return (
                <Body size="s" mr="m">
                    days
                </Body>
            )
        }
        return null
    }, [operator])

    const isDateField = ['date', 'datetime'].includes(field.type)
    if (!editMode && isDateField) {
        const relativeDateString =
            relativeFiltersLabelsByValue[value as keyof typeof relativeFiltersLabelsByValue]
        if (relativeDateString) {
            return <>{relativeDateString}</>
        }
    }

    if (!rhsType) {
        return null
    }

    if (typeof value === 'string') {
        value = {
            input_content: { type: 'doc', content: [{ type: 'text', text: value }] },
            value: { function: 'STRING_VALUE', value },
        }
    }

    return (
        <WorkflowExpressionInput
            contextSchema={contextSchema}
            returnType={rhsType}
            returnExtraOptions={{ options: rhsTypeOptions }}
            value={{ ...(value as unknown as WorkflowExpression) }}
            onChange={onChange}
            placeholder="select a value"
            disabled={!operator || !rhsType}
            rightSlotContent={rightSlotContent}
        />
    )
}
