export const DATA_CONNECTION_DETAIL_MODAL_KEY = 'DataConnectionDetailModal'

// integration ids of integration which don't have an equivalent concept of database, so
// database selection UI should not be shown
export const INTEGRATION_IDS_WITHOUT_DATABASES: ExternalIntegrationId[] = [
    'hubspot',
    'intercom',
    'gorgias',
    'netsuite-tba',
] as const
// integration ids of integration which support webhooks for realtime data updates
export const INTEGRATION_IDS_WITH_WEBHOOK_SUPPORT: ExternalIntegrationId[] = ['airtable'] as const

// integration id of integration which require additional input as part of connection setup (e.g. username/pw, subdomain, tokens)
export const INTEGRATION_IDS_REQUIRES_INPUTS: ExternalIntegrationId[] = [
    'gorgias',
    'netsuite-tba',
] as const

const DEFAULT_TRANSLATIONS = {
    Database: 'Account',
    databases: 'accounts',
    database: 'account',
    tables: 'tables',
    table: 'table',
    Username: 'Username',
    Password: 'Password',
}

export const TRANSLATIONS: Record<ExternalIntegrationId, Record<string, string>> = {
    airtable: {
        ...DEFAULT_TRANSLATIONS,
        Database: 'Base',
        databases: 'bases',
        database: 'base',
    },
    hubspot: DEFAULT_TRANSLATIONS,
    intercom: DEFAULT_TRANSLATIONS,
    gorgias: {
        ...DEFAULT_TRANSLATIONS,
        Password: 'API key',
    },
    'netsuite-tba': DEFAULT_TRANSLATIONS,
} as const

export const INTEGRATION_ID_TO_NAME: Record<ExternalIntegrationId, string> = {
    airtable: 'Airtable',
    hubspot: 'HubSpot',
    intercom: 'Intercom',
    gorgias: 'Gorgias',
    'netsuite-tba': 'NetSuite',
} as const

export const INTEGRATION_ID_TO_DC_TYPE: Record<ExternalIntegrationId, DataConnectionType> = {
    airtable: 'nango_airtable',
    hubspot: 'nango_hubspot',
    intercom: 'nango_intercom',
    gorgias: 'nango_gorgias',
    'netsuite-tba': 'nango_netsuite-tba',
} as const

export const DC_TYPE_TO_INTEGRATION_ID = Object.fromEntries(
    Object.entries(INTEGRATION_ID_TO_DC_TYPE).map(([key, value]) => [value, key])
) as Record<DataConnectionType, ExternalIntegrationId>

// mapping of integration id to description
export const INTEGRATIONS_DESCRIPTION: Record<ExternalIntegrationId, string> = {
    airtable: 'Effortlessly bring your Airtable info into one place.',
    hubspot: 'Transfer HubSpot CRM data in real-time.',
    intercom: 'Import user data for a complete customer view.',
    gorgias: 'Import data from your Gorgias account.',
    'netsuite-tba': 'Import your ERP data from your NetSuite account.',
} as const
