import React from 'react'

import { DeleteViewConfirmModal } from 'features/views/ListView/DeleteViewConfirmModal'

import {
    Dropdown,
    DropdownButton,
    DropdownContent,
    DropdownItem,
    DropdownSeparator,
} from 'ui/components/Dropdown'
import { stopPropagation } from 'ui/helpers/utilities'

import { useListHeaderMoreActionsState } from './hooks/useListHeaderMoreActionsState'

type ListHeaderMoreActionsProps = {}

export const ListHeaderMoreActions: React.FC<ListHeaderMoreActionsProps> = () => {
    const {
        allowDownload,
        onDownload,
        isLoading,
        allowCopyLink,
        onCopyLink,
        allowDelete,
        onDelete,
        deleteModalViewSid,
        isDeleteModalOpen,
        onDeleteModalOpenChange,
        allowFavorite,
        isFavorite,
        onToggleFavorite,
        allowDuplicate,
        onDuplicate,
        showMenu,
    } = useListHeaderMoreActionsState()

    if (!showMenu) return null

    return (
        <>
            <Dropdown>
                <DropdownButton
                    size="s"
                    aria-label="More actions"
                    variant="ghost"
                    startIcon={{ name: 'MoreHorizontal' }}
                    isLoading={isLoading}
                />
                <DropdownContent
                    onClick={stopPropagation}
                    align="end"
                    style={{ minWidth: '250px' }}
                >
                    {allowCopyLink && (
                        <DropdownItem
                            startIcon={{ name: 'Link' }}
                            label="Copy link"
                            onClick={onCopyLink}
                        />
                    )}

                    {allowFavorite && (
                        <>
                            <DropdownSeparator />
                            <DropdownItem
                                startIcon={{
                                    name: isFavorite ? 'StarOff' : 'Star',
                                }}
                                label={isFavorite ? 'Remove from favorites' : 'Add to favorites'}
                                onClick={onToggleFavorite}
                            />
                        </>
                    )}

                    {(allowCopyLink || allowFavorite) &&
                        (allowDuplicate || allowDownload || allowDelete) && <DropdownSeparator />}

                    {allowDuplicate && (
                        <DropdownItem
                            startIcon={{ name: 'Copy' }}
                            label="Duplicate List view"
                            onClick={onDuplicate}
                        />
                    )}

                    {allowDownload && (
                        <DropdownItem
                            startIcon={{ name: 'Download' }}
                            label="Export as .CSV"
                            onClick={onDownload}
                        />
                    )}
                    {allowDelete && (
                        <DropdownItem
                            variant="destructive"
                            startIcon={{ name: 'Trash2' }}
                            label="Delete layout"
                            onClick={onDelete}
                        />
                    )}
                </DropdownContent>
            </Dropdown>
            <DeleteViewConfirmModal
                viewSid={deleteModalViewSid}
                isOpen={isDeleteModalOpen}
                onOpenChange={onDeleteModalOpenChange}
            />
        </>
    )
}
