import React from 'react'

import { Box } from 'ui/components/Box'
import { Separator } from 'ui/components/Divider/Divider.parts'
import { Headline } from 'ui/components/Text'
import { Tooltip } from 'ui/components/Tooltip'

import { useThreadViewDayDividerState } from './hooks/useThreadViewDayDividerState'

type ThreadViewDayDividerProps = {
    day: string
}

export const ThreadViewDayDivider = React.memo(function ThreadViewDayDivider({
    day,
}: ThreadViewDayDividerProps) {
    const { formattedDay, date, isRelative } = useThreadViewDayDividerState({ day })

    const dateContent = (
        <Headline size="3xs" color="textWeaker" noShrink>
            {formattedDay}
        </Headline>
    )

    return (
        <Box flex center py="xl" gap="xl">
            <Separator type="or" variant="weak" />
            {isRelative ? (
                <Tooltip content={date} side="bottom" zIndex={200} asChild>
                    {dateContent}
                </Tooltip>
            ) : (
                dateContent
            )}
            <Separator type="or" variant="weak" />
        </Box>
    )
})
