import React from 'react'

import { Box } from 'ui/components/Box'
import { Container } from 'ui/components/Container'
import { theme } from 'ui/styling/Theme.css'

import { WorkflowCanvas } from './WorkflowCanvas'
import { WorkflowItemEditor } from './WorkflowItemEditor'
import { WorkflowNodeList } from './WorkflowNodeList'

type WorkflowCanvasPane = React.ComponentPropsWithoutRef<typeof Box> & {
    workflow: WorkflowDto
    selectedItem?: WorkflowItem
    selectedLine?: WorkflowLine
    onChange: (patch: Partial<WorkflowItem>) => void
}

export const WorkflowCanvasPane: React.FC<WorkflowCanvasPane> = ({
    workflow,
    selectedItem,
    selectedLine,
    onChange,
    ...props
}) => {
    return (
        <Box flex stretch {...props}>
            <WorkflowCanvas workflow={workflow} height="full" grow overflowY="auto" />
            <Container
                width="400px"
                flex
                column
                stretch
                boxShadow="m"
                noShrink
                style={{
                    borderLeft: `1px solid ${theme.color.border}`,
                    borderRadius: '0px',
                    zIndex: 1,
                }}
                overflowY="auto"
                pb="4xl"
            >
                {selectedItem && (
                    <WorkflowItemEditor
                        key={selectedItem.id}
                        workflow={workflow}
                        item={selectedItem}
                        onChange={onChange}
                    />
                )}
                {selectedLine && <WorkflowNodeList />}
            </Container>
        </Box>
    )
}
