import { useCallback } from 'react'

import { useWorkflowSchema } from 'data/hooks/workflows/workflows'
import { LIST_TYPES } from 'data/utils/fieldDefinitions'
import { convertOutputSchemaToOutputs } from 'features/workflows/OutputSchema/utils'

export function useReturnDataActionFinalSchema() {
    const { data: schema } = useWorkflowSchema()
    return useCallback(
        (
            config: WorkflowActionConfig,
            _existingSchema: WorkflowSchemaStateItem[]
        ): WorkflowSchemaNodeType | undefined => {
            const type = schema?.nodes.find(
                (t) => t.id === config.action_type
            ) as WorkflowSchemaActionType
            if (!type) return undefined

            const returnDataSchema = config.settings?.return_data as
                | WorkflowOutputSchema
                | undefined

            const stateItems = returnDataSchema
                ? convertOutputSchemaToOutputs(returnDataSchema)
                : []

            const inputs = stateItems.map(
                (v) =>
                    ({
                        id: v.id,
                        name: v.name,
                        type: v.type,
                        extra_options: {
                            allowed_input_modes: LIST_TYPES.includes(
                                v.type as (typeof LIST_TYPES)[number]
                            )
                                ? 'multi_value_modes'
                                : undefined,
                        },
                    }) as WorkflowSchemaNodeInput
            )

            return {
                ...type,
                state: [],
                inputs,
            }
        },
        [schema?.nodes]
    )
}
