import {
    convertV2FieldColorToTheme,
    getDefaultDropdownFieldOptionColorName,
} from 'utils/fieldUtils'

const SUPPORTED_SENDER_FIELD_TYPES: Set<FieldType> = new Set([
    'string',
    'long_text',
    'url',
    'dropdown',
    'multi_select',
    'checkbox',
])

export function getSupportedSenderFields(fields: FieldDto[]): FieldDto[] {
    return fields.filter((field) => SUPPORTED_SENDER_FIELD_TYPES.has(field.type))
}

export function getSenderFieldValueOptions(field: FieldDto): {
    label: string
    value: string
    color?: string
}[] {
    switch (field.type) {
        case 'checkbox':
            return [
                { label: 'is checked', value: 'true' },
                { label: 'is not checked', value: 'false' },
            ]

        case 'dropdown':
        case 'multi_select': {
            const options = field.options?.options ?? []
            const allowColors = !!field.options?.allow_dropdown_colors

            return options.map((option) => {
                let color = getDefaultDropdownFieldOptionColorName()
                if (allowColors && option?.color) {
                    // This will try to map the old color format to the new one.
                    // If the color is not found, it means that this is a new color,
                    // so use it as is.
                    color = convertV2FieldColorToTheme(option.color)
                }

                return {
                    label: option.label ?? option.value ?? '',
                    value: option.value ?? '',
                    color,
                }
            })
        }

        default:
            return []
    }
}

const SUPPORTED_CONTENT_FIELD_TYPES: Set<FieldType> = new Set(['string', 'long_text', 'document'])

export function getSupportedContentFields(fields: FieldDto[]): FieldDto[] {
    return fields.filter((field) => SUPPORTED_CONTENT_FIELD_TYPES.has(field.type))
}

const SUPPORTED_PROFILE_IMAGE_FIELD_TYPES: Set<FieldType> = new Set(['multi_file', 'url'])

export function getSupportedProfileImageFields(fields: FieldDto[]): FieldDto[] {
    return fields.filter((field) => SUPPORTED_PROFILE_IMAGE_FIELD_TYPES.has(field.type))
}
