import { useContext } from 'react'

import { updateObject } from 'data/hooks/objects/objectOperations'
import useLDFlags from 'data/hooks/useLDFlags'
import { PathContext } from 'features/utils/PathContext'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'

import { IconNameVariantType } from 'ui/components/Icon/Icon'

export function useDetailViewExperienceSwitcherState() {
    // The object is not available in the path context in the new editor.
    const { object: pathObject } = useContext(PathContext)
    const { object: editorObject } = useLayoutEditorContext()
    const object = editorObject || pathObject

    const { flags } = useLDFlags()

    const showButton = !!flags.turboDetailView && !!object

    const isTurboExperience = !!object?.options?.has_turbo_detail_view

    const onClick = async () => {
        if (!object) return

        const newState = isTurboExperience ? false : true
        await updateObject(object._sid, {
            options: {
                ...object.options,
                has_turbo_detail_view: newState,
            },
        })
    }

    const label = isTurboExperience ? 'Revert to old experience' : 'Use new experience'
    const icon: IconNameVariantType | undefined = isTurboExperience
        ? undefined
        : { name: 'Sparkles' }

    return {
        showButton,
        onClick,
        label,
        icon,
    }
}
