import React, { useContext } from 'react'
import { createPortal } from 'react-dom'

import styled from '@emotion/styled'
import { ViewLayoutContext } from 'v2/blocks/types'

import { CollapsedNavigationTrigger } from 'features/navigation/CollapsedNavigationTrigger'
import { useNavigationContext } from 'features/navigation/hooks/useNavigationContext'
import { LayoutEditorContext } from 'features/utils/LayoutEditorContext'
import { getDefaultedPageHeader } from 'features/views/ViewHeader/viewHeaderDefaults'
import { isHeaderEmpty } from 'features/views/ViewHeader/viewHeaderUtils'

import { NavMenuButtonDarkStyle } from 'v2/ui/components/Breadcrumbs.css'
import STYLE_CLASSES from 'v2/ui/styleClasses'

import { theme } from 'ui/styling/Theme.css'

import ViewHeaderDefault from './ViewHeaderDefault'
import ViewHeaderImage from './ViewHeaderImage'
import { ViewHeaderModular } from './ViewHeaderModular'

const StyledHeader = styled.header`
    position: relative;
`

type ViewHeaderProps = {
    viewLayoutContext?: ViewLayoutContext
    headerOptions: PageHeader | undefined
    renderInPlace?: boolean
    parentIsMounted: boolean
    onChange?: (patch: Partial<ListViewOptions>) => void
    listOptions?: ListViewOptions
}

const ViewHeader = ({
    viewLayoutContext,
    headerOptions,
    renderInPlace,
    parentIsMounted,
    onChange,
    listOptions,
}: ViewHeaderProps) => {
    const isEmpty = isHeaderEmpty(headerOptions)
    const { pageHeroHeader } = useContext(LayoutEditorContext)

    const { object } = viewLayoutContext ?? {}
    const isTurboDetailView = !!object?.options?.has_turbo_detail_view
    const displayType = listOptions?.display

    const { showCollapsedNavigationTrigger } = useNavigationContext()
    const shouldShowNavTrigger =
        showCollapsedNavigationTrigger &&
        ((displayType === 'single_record' && !isTurboDetailView) || displayType !== 'single_record')

    const defaultedHeaderOptions = getDefaultedPageHeader(headerOptions)

    let navTriggerColor: string | undefined
    if (defaultedHeaderOptions.type === 'default') {
        navTriggerColor = undefined
    } else if (defaultedHeaderOptions.type === 'image') {
        if (!!defaultedHeaderOptions.src || defaultedHeaderOptions.showBackgroundColor) {
            navTriggerColor = theme.color.iconInverse
        }
    } else {
        navTriggerColor = theme.color.iconInverse
    }

    if (isEmpty && shouldShowNavTrigger) {
        return (
            <CollapsedNavigationTrigger
                position="absolute"
                className={!!navTriggerColor ? NavMenuButtonDarkStyle : undefined}
                style={{
                    left: '-8px',
                    top: theme.space.l,
                    zIndex: 9,
                    color: navTriggerColor,
                }}
            />
        )
    }

    if ((isEmpty && !shouldShowNavTrigger) || !parentIsMounted) return null

    const output = (
        <StyledHeader className={STYLE_CLASSES.PAGE_HEADER} data-testId="view-header">
            {shouldShowNavTrigger && (
                <CollapsedNavigationTrigger
                    position="absolute"
                    left="l"
                    className={!!navTriggerColor ? NavMenuButtonDarkStyle : undefined}
                    style={{
                        top: defaultedHeaderOptions.type === 'default' ? '-48px' : theme.space.l,
                        zIndex: 9,
                        color: navTriggerColor,
                    }}
                />
            )}
            {defaultedHeaderOptions.type === 'default' && (
                <ViewHeaderDefault headerOptions={defaultedHeaderOptions} />
            )}
            {defaultedHeaderOptions.type === 'image' && (
                <ViewHeaderImage headerOptions={defaultedHeaderOptions} />
            )}
            {defaultedHeaderOptions.type === 'modular' && (
                <ViewHeaderModular
                    viewLayoutContext={viewLayoutContext!}
                    headerOptions={defaultedHeaderOptions}
                    onChange={(patch) => onChange?.(patch)}
                />
            )}
        </StyledHeader>
    )

    if (renderInPlace) return output

    if (!pageHeroHeader) return null
    return createPortal(output, pageHeroHeader)
}

export default ViewHeader
