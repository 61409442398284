import React from 'react'

import { Box } from 'ui/components/Box'

import { useRecordActionsState } from './hooks/useRecordActionsState'
import { ActionMenu } from './ActionMenu'
import { RecordActionButton } from './RecordActionButton'

type RecordActionsProps = React.ComponentPropsWithoutRef<typeof Box> & {
    record: RecordDto
    onOpenChange?: React.ComponentProps<typeof ActionMenu>['onOpenChange']
    onCloseAutoFocus?: React.ComponentProps<typeof ActionMenu>['onCloseAutoFocus']
    menuVariant?: React.ComponentProps<typeof ActionMenu>['variant']
    additionalEditFields?: FieldDto[]
    actionButtons?: ActionDto[]
    includeFields: string[]
}

export const RecordActions: React.FC<RecordActionsProps> = React.memo(function RecordActions({
    record,
    onOpenChange,
    onCloseAutoFocus,
    additionalEditFields,
    onClick,
    actionButtons,
    includeFields,
    menuVariant = 'secondary',
    ...props
}) {
    const { menuActions, visibleActions, handleClick } = useRecordActionsState({
        record,
        onClick,
        actionButtons,
    })

    return (
        <Box flex center gap="xs" noShrink onClick={handleClick} {...props}>
            {!!visibleActions.length && (
                <Box flex center gap="xs" noShrink>
                    {visibleActions.map((action, idx) => (
                        <RecordActionButton
                            key={action._sid}
                            record={record}
                            action={action}
                            includeFields={includeFields}
                            size="xs"
                            variant={idx === visibleActions.length - 1 ? 'primary' : 'secondary'}
                        />
                    ))}
                </Box>
            )}
            {!!menuActions.length && (
                <ActionMenu
                    record={record}
                    onOpenChange={onOpenChange}
                    onCloseAutoFocus={onCloseAutoFocus}
                    actionButtons={menuActions}
                    additionalEditFields={additionalEditFields}
                    includeFields={includeFields}
                    variant={menuVariant}
                />
            )}
        </Box>
    )
})
