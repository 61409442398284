import React from 'react'

import { Box } from 'ui/components/Box'
import { Body } from 'ui/components/Text'

type EmbeddedNoRecordsEmptyStateProps = {}

export const EmbeddedNoRecordsEmptyState: React.FC<EmbeddedNoRecordsEmptyStateProps> = React.memo(
    function EmbeddedNoRecordsEmptyState() {
        return (
            <Box flex flexDirection="column" alignSelf="stretch" py={{ mobile: '2xl', tablet: 0 }}>
                <Body size="m" color="textWeaker">
                    No records
                </Body>
            </Box>
        )
    }
)
