import React from 'react'
import Markdown from 'react-markdown'

import { useObjects } from 'data/hooks/objects'

import { Box } from 'ui/components/Box'
import { Container } from 'ui/components/Container'
import { Body } from 'ui/components/Text'

import { processMarkdownTemplate } from './markdownTemplateProcessor'
import { StackerRecordCardDisplay } from './StackerRecord'
import { QueryResultRendererConfig, RendererProps } from './types'

type QueryResult = {
    description: string
    object_id: string
    records: Record<string, any>[]
}

export function QueryResultRenderer({ data, instructions }: RendererProps) {
    const parsedData = JSON.parse(data) as QueryResult
    const { data: objects } = useObjects()
    const object = objects.find((object) => object.api_name === parsedData.object_id)

    if (!object) {
        return <div>Object not found</div>
    }

    const custom_render_config = instructions?.configured_renderers?.find(
        (renderer) => renderer.type === 'queryResult' && renderer.config.object_sid === object._sid
    )

    const config = custom_render_config?.config as QueryResultRendererConfig
    if (parsedData.records.length === 0) {
        return <Body color="textWeaker">No matching results found.</Body>
    }

    if (config?.display_mode === 'markdown') {
        return (
            <Box flex column gap="m">
                {processMarkdownTemplate(config?.markdown_header_template, parsedData)}
                {parsedData.records.map((record) => (
                    <Container key={record._sid} variant="neutralMuted" p="m">
                        <Markdown>
                            {processMarkdownTemplate(config?.markdown_record_template, record)}
                        </Markdown>
                    </Container>
                ))}
            </Box>
        )
    }

    return (
        <Box flex column gap="m">
            <Body color="textWeaker">{parsedData.description}</Body>
            {parsedData.records.map((record) => (
                <StackerRecordCardDisplay key={record._sid} data={record} object={object} />
            ))}
        </Box>
    )
}
