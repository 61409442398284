import { useCallback, useEffect, useMemo, useState } from 'react'

import { useDetailViewContext } from 'features/views/DetailView/hooks/useDetailViewContext'
import { DetailViewSidebarOverlayControlsStyles } from 'features/views/DetailView/layouts/DetailViewLayout.css'
import { SIDEBAR_WIDGET_AREA_ID } from 'features/views/LayoutEditor/constants'
import { useSwipeGesture } from 'utils/useSwipeGesture'

import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

const TOUCH_THRESHOLD_X = 200
const TOUCH_THRESHOLD_Y = 100

export function useDetailViewSidebarState() {
    const {
        isSidebarSticky,
        topOffset = '0px',
        view,
        contentWidth,
        isEditing,
        isEmbedded,
    } = useDetailViewContext()!

    const isEmpty = !view?.layout?.children?.[SIDEBAR_WIDGET_AREA_ID]?.length

    const styleTop = useResponsiveValue({
        mobile: undefined,
        tablet: isSidebarSticky ? `calc(${topOffset} + 64px)` : undefined,
    })

    const isSideOverlay = useResponsiveValue({
        mobile: contentWidth === 'full' && !isEmbedded,
        tablet: false,
    })

    const shouldHide = isSideOverlay && isEmpty && !isEditing

    const [isSideOverlayExpanded, setIsSideOverlayExpanded] = useState(false)
    useEffect(() => {
        // Reset the expanded state when the side overlay is hidden.
        if (!isSideOverlay) {
            setIsSideOverlayExpanded(false)
        }
    }, [isSideOverlay])

    const toggleSideOverlay = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation()
        e.preventDefault()

        setIsSideOverlayExpanded((prev) => !prev)
    }, [])

    useSwipeGesture(document.body, {
        onSwipeLeft: useCallback(
            (event: TouchEvent, startX: number, startY: number) => {
                if (!isSideOverlay) return

                // Don't allow swiping on interactive elements.
                const target = event.target as HTMLElement | null
                const closestInteractive = target?.closest('[role="button"], button, a')
                const isWithinSidebarControls = target?.closest(
                    `.${DetailViewSidebarOverlayControlsStyles.styleFunction({})}`
                )
                if (closestInteractive && !isWithinSidebarControls) return

                event.preventDefault()
                event.stopPropagation()

                // Don't allow vertical swipes or horizontal swipes greater than the threshold.
                const endY = event.changedTouches[0]?.clientY
                const endX = event.changedTouches[0]?.clientX
                const screenWidth = window.innerWidth
                const isVerticalSwipe = Math.abs(startY - endY) > TOUCH_THRESHOLD_Y
                if (isVerticalSwipe || screenWidth - endX > TOUCH_THRESHOLD_X) return

                setIsSideOverlayExpanded(true)
            },
            [isSideOverlay]
        ),
    })

    return useMemo(
        () => ({
            isSticky: isSidebarSticky,
            styleTop,
            isEmpty,
            isSideOverlay,
            shouldHide,
            isSideOverlayExpanded,
            toggleSideOverlay,
        }),
        [
            isSidebarSticky,
            styleTop,
            isEmpty,
            isSideOverlay,
            shouldHide,
            isSideOverlayExpanded,
            toggleSideOverlay,
        ]
    )
}
