import React from 'react'

import { AttachmentsAttributeTag } from 'features/views/attributes/AttachmentsAttributeTag'
import { CheckboxAttributeTag } from 'features/views/attributes/CheckboxAttributeTag'
import { CurrencyAttributeTag } from 'features/views/attributes/CurrencyAttributeTag'
import { DateAttributeTag } from 'features/views/attributes/DateAttributeTag'
import { DropdownAttributeTag } from 'features/views/attributes/DropdownAttributeTag'
import { LongTextAttributeTag } from 'features/views/attributes/LongTextAttributeTag'
import { NumberAttributeTag } from 'features/views/attributes/NumberAttributeTag'
import { PercentageAttributeTag } from 'features/views/attributes/PercentageAttributeTag'
import { RecordLinksAttributeTag } from 'features/views/attributes/RecordLinksAttributeTag'
import { RichTextAttributeTag } from 'features/views/attributes/RichTextAttributeTag'
import { TextAttributeTag } from 'features/views/attributes/TextAttributeTag'
import { UrlAttributeTag } from 'features/views/attributes/UrlAttributeTag'
import { UserAttributeTag } from 'features/views/attributes/UserAttributeTag'

import { Body } from 'ui/components/Text'
import { ResponsiveValue, useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'
import { theme } from 'ui/styling/Theme.css'

import { useDetailViewHeaderEyebrowAttributeState } from './hooks/useDetailViewHeaderEyebrowAttributeState'

const sizeMapping = {
    dropdown: {
        '2xs': 's' as const,
        xs: 'm' as const,
        s: 'l' as const,
    },
    recordLinks: {
        '2xs': 's' as const,
        xs: 'm' as const,
        s: 'l' as const,
    },
    attachments: {
        '2xs': 's' as const,
        xs: 'm' as const,
        s: 'l' as const,
    },
} as const

const MAX_LINES = 1
const MAX_LENGTH = 20

type DetailViewHeaderEyebrowAttributeProps = Pick<
    React.ComponentProps<typeof Body>,
    'style' | 'className' | 'noShrink' | 'shrink'
> & {
    isLoading?: boolean
    size?: ResponsiveValue<'2xs' | 'xs' | 's'>
}

export const DetailViewHeaderEyebrowAttribute: React.FC<DetailViewHeaderEyebrowAttributeProps> =
    React.memo(function DetailViewHeaderEyebrowAttribute({ size = 's', ...props }) {
        const { field, value, dereferencedRecords } = useDetailViewHeaderEyebrowAttributeState()

        const tagSize = useResponsiveValue(size)

        if (!field || typeof value === 'undefined') {
            return null
        }

        switch (field.type) {
            case 'string':
                return (
                    <TextAttributeTag
                        key={field._sid}
                        field={field}
                        value={value}
                        shape="pill"
                        size={tagSize}
                        shrink
                        trim
                        noShrink={false}
                        style={{
                            flexShrink: 1,
                        }}
                        maxLines={MAX_LINES}
                        maxLength={MAX_LENGTH}
                        {...props}
                    />
                )

            case 'long_text':
                return (
                    <LongTextAttributeTag
                        key={field._sid}
                        field={field}
                        value={value}
                        size={tagSize}
                        shrink
                        noShrink={false}
                        trim
                        style={{
                            flexShrink: 1,
                        }}
                        maxLines={MAX_LINES}
                        maxLength={MAX_LENGTH}
                        {...props}
                    />
                )

            case 'document':
                return (
                    <RichTextAttributeTag
                        key={field._sid}
                        field={field}
                        value={value}
                        size={tagSize}
                        shrink
                        noShrink={false}
                        trim
                        style={{
                            flexShrink: 1,
                        }}
                        maxLines={MAX_LINES}
                        maxLength={MAX_LENGTH}
                        {...props}
                    />
                )

            case 'url':
                return (
                    <UrlAttributeTag
                        key={field._sid}
                        field={field}
                        value={value}
                        size={tagSize}
                        shrink
                        noShrink={false}
                        trim
                        style={{
                            flexShrink: 1,
                            borderRadius: theme.tag.radius.pill,
                        }}
                        {...props}
                    />
                )

            case 'checkbox':
                return (
                    <CheckboxAttributeTag
                        key={field._sid}
                        field={field}
                        value={value}
                        shape="pill"
                        size={tagSize}
                        shrink
                        noShrink={false}
                        trim
                        style={{
                            flexShrink: 1,
                        }}
                        maxLines={MAX_LINES}
                        maxLength={MAX_LENGTH}
                        {...props}
                    />
                )

            case 'number':
                return (
                    <NumberAttributeTag
                        key={field._sid}
                        field={field}
                        value={value}
                        shape="pill"
                        size={tagSize}
                        shrink
                        noShrink={false}
                        trim
                        style={{
                            flexShrink: 1,
                        }}
                        maxLines={MAX_LINES}
                        maxLength={MAX_LENGTH}
                        {...props}
                    />
                )

            case 'currency':
                return (
                    <CurrencyAttributeTag
                        key={field._sid}
                        field={field}
                        value={value}
                        shape="pill"
                        size={tagSize}
                        shrink
                        noShrink={false}
                        trim
                        style={{
                            flexShrink: 1,
                        }}
                        maxLines={MAX_LINES}
                        maxLength={MAX_LENGTH}
                        {...props}
                    />
                )

            case 'percentage':
                return (
                    <PercentageAttributeTag
                        key={field._sid}
                        field={field}
                        value={value}
                        shape="pill"
                        size={tagSize}
                        shrink
                        noShrink={false}
                        trim
                        style={{
                            flexShrink: 1,
                        }}
                        maxLines={MAX_LINES}
                        maxLength={MAX_LENGTH}
                        {...props}
                    />
                )

            case 'date':
            case 'datetime':
                return (
                    <DateAttributeTag
                        key={field._sid}
                        field={field}
                        value={value}
                        shape="pill"
                        size={tagSize}
                        shrink
                        noShrink={false}
                        trim
                        style={{
                            flexShrink: 1,
                        }}
                        maxLines={MAX_LINES}
                        maxLength={MAX_LENGTH}
                        {...props}
                    />
                )

            case 'user_ref':
                return (
                    <UserAttributeTag
                        key={field._sid}
                        field={field}
                        value={value}
                        size={tagSize}
                        shrink
                        noShrink={false}
                        trim
                        maxLines={MAX_LINES}
                        maxLength={MAX_LENGTH}
                        style={{
                            flexShrink: 1,
                            borderRadius: theme.tag.radius.pill,
                        }}
                        {...props}
                    />
                )

            case 'multi_file':
                return (
                    <AttachmentsAttributeTag
                        key={field._sid}
                        field={field}
                        value={value}
                        shrink
                        noShrink={false}
                        trim
                        style={{
                            flexShrink: 1,
                        }}
                        maxLines={MAX_LINES}
                        maxLength={MAX_LENGTH}
                        size={sizeMapping.attachments[tagSize]}
                        {...props}
                    />
                )

            case 'multi_lookup':
            case 'lookup':
                return (
                    <RecordLinksAttributeTag
                        key={field._sid}
                        field={field}
                        value={value}
                        dereferencedRecords={dereferencedRecords}
                        shrink
                        noShrink={false}
                        trim
                        style={{
                            flexShrink: 1,
                        }}
                        maxLines={MAX_LINES}
                        maxLength={MAX_LENGTH}
                        size={sizeMapping.recordLinks[tagSize]}
                        {...props}
                    />
                )

            case 'dropdown':
            case 'multi_select':
                return (
                    <DropdownAttributeTag
                        key={field._sid}
                        field={field}
                        value={value}
                        type="solid"
                        shape="pill"
                        shrink
                        noShrink={false}
                        trim
                        style={{
                            flexShrink: 1,
                        }}
                        maxLines={MAX_LINES}
                        maxLength={MAX_LENGTH}
                        size={sizeMapping.dropdown[tagSize]}
                        {...props}
                    />
                )

            default:
                return (
                    <Body
                        key={field._sid}
                        shrink
                        noShrink={false}
                        trim
                        style={{
                            flexShrink: 1,
                        }}
                        maxLines={MAX_LINES}
                        maxLength={MAX_LENGTH}
                        {...props}
                    >
                        {!!value && String(value)}
                    </Body>
                )
        }
    })
