import React from 'react'

import { RichTextAttributeDisplay } from 'features/views/attributes/RichTextAttributeDisplay'
import { RichTextAttributeTag } from 'features/views/attributes/RichTextAttributeTag'
import { RichTextValue } from 'features/views/attributes/types'

import { BaseAttribute } from './BaseAttribute'
import { MAX_LENGTH, MAX_LENGTH_TAG, MAX_LINES, MAX_OVERFLOW_LENGTH } from './constants'
import { ThreadViewAttributeComponent } from './types'

type RichTextAttributeProps = {}

export const RichTextAttribute: ThreadViewAttributeComponent<
    RichTextValue,
    RichTextAttributeProps
> = ({ value, attribute, isLoading, showIcon, ...props }) => {
    const { field, type } = attribute

    const isMessage = type === 'message'
    const isContent = type === 'content'

    const isEmpty = !isLoading && (!value || (!!value && !value?.plainTextContent))

    let maxLines: number | undefined = MAX_LINES
    if (isMessage) {
        maxLines = undefined
    }

    let maxLength: number | undefined = MAX_LENGTH_TAG
    if (isMessage) {
        maxLength = undefined
    } else if (isContent) {
        maxLength = MAX_LENGTH
    }

    return (
        <BaseAttribute attribute={attribute} isEmpty={isEmpty} isLoading={isLoading} {...props}>
            {isMessage || isContent ? (
                <RichTextAttributeDisplay
                    value={value}
                    style={{
                        fontSize: 'inherit',
                        lineHeight: 'inherit',
                        letterSpacing: 'inherit',
                        fontWeight: 'inherit',
                        wordBreak: 'break-word',
                        whiteSpace: isMessage ? 'pre-wrap' : 'nowrap',
                    }}
                    field={field}
                    isLoading={isLoading}
                    maxLines={maxLines}
                    maxLength={maxLength}
                    maxOverflowLength={MAX_OVERFLOW_LENGTH}
                    showOverflowTooltip={{
                        mobile: false,
                        tablet: isContent,
                    }}
                />
            ) : (
                <RichTextAttributeTag
                    showOverflowTooltip={{
                        mobile: false,
                        tablet: true,
                    }}
                    value={value}
                    field={field}
                    maxOverflowLength={MAX_OVERFLOW_LENGTH}
                    isLoading={isLoading}
                    size="2xs"
                    showIcon={showIcon}
                    maxLength={maxLength}
                />
            )}
        </BaseAttribute>
    )
}
