import React, { useEffect, useRef } from 'react'

import { useAppContext } from 'app/useAppContext'
import { ContextSchema } from 'features/expressions/types'
import { getSchemaItemFromPath } from 'features/expressions/utilities'
import { StackerFieldFunction } from 'features/formulas/parser/formulaParserTypes'
import { ObjectPicker } from 'features/studio/ui/ObjectPicker'
import { useWorkflowEditorContext } from 'features/workflows/useWorkflowEditorContext'
import { ConfigurationHint, ConfigurationLabel } from 'features/workflows/WorkflowUI'

import { WorkflowActionEditorComponent } from './types'

export const GetRecordActionConfig: WorkflowActionEditorComponent = ({ action, onChange }) => {
    const { contextSchema } = useWorkflowEditorContext()
    const { selectedStack } = useAppContext()

    const targetRecordInput = action.inputs?.find(
        (i) => i.id === 'record_id'
    ) as WorkflowActionConfigInput
    const targetRecordExpression = targetRecordInput?.value

    const schemaItem = targetRecordExpression
        ? (getSchemaItemFromPath(
              (targetRecordExpression?.value as StackerFieldFunction)?.api_name ?? '',
              contextSchema as ContextSchema
          )?.item as WorkflowSchemaActionStateItem)
        : undefined

    const targetObjectId = schemaItem?.extra_options?.link_target_object_id

    const settingsRef = useRef(action.settings)
    settingsRef.current = action.settings

    useEffect(() => {
        onChange({
            settings: {
                ...settingsRef.current,
                object_id: targetObjectId || settingsRef.current?.object_id,
            },
        })
    }, [onChange, selectedStack?._sid, targetObjectId])

    if (!schemaItem) {
        return null
    }

    return (
        <>
            <ConfigurationLabel>Table</ConfigurationLabel>
            {!targetObjectId && (
                <ConfigurationHint error={!action.settings?.object_id}>
                    The selected Record ID doesn&apos;t come with table information. You must select
                    the correct table here.
                </ConfigurationHint>
            )}
            <ObjectPicker
                value={action.settings?.object_id}
                onChange={(item: string) => {
                    onChange({
                        settings: {
                            ...action.settings,
                            object_id: item,
                        },
                    })
                }}
                isClearable={false}
                disabled={!!targetObjectId}
            />
        </>
    )
}
