import { useMemo } from 'react'

import { useDetailViewProfileImageAttributeState } from 'features/views/DetailView/attributes/hooks/useDetailViewProfileImageAttributeState'
import { useDetailViewContext } from 'features/views/DetailView/hooks/useDetailViewContext'
import { DetailViewHeaderSpacious } from 'features/views/DetailView/types'

export function useDetailViewSpaciousHeaderProfileImageState() {
    const { header } = useDetailViewContext()!

    const coverType = (header as DetailViewHeaderSpacious).coverType ?? 'none'

    const { hasThumbnail } = useDetailViewProfileImageAttributeState()

    return useMemo(
        () => ({
            coverType,
            hasThumbnail,
        }),
        [coverType, hasThumbnail]
    )
}
