// @ts-strict-ignore
import React, { useCallback, useEffect, useState } from 'react'

import { useDataConnections } from 'data/hooks/dataConnections/useDataConnections'
import { useObjects } from 'data/hooks/objects'
import { useNewNativeTableModal } from 'features/admin/data-connector/editors/native-tables/useNewNativeTableModal'
import { ImportCsvTableModal } from 'features/ImportData/importCsv/ImportCsvTableModal'
import ImportTableDataModal from 'features/ImportData/importTableData/ImportTableDataModal'
import { SlidingPaneFrame } from 'features/workspace/AdminSideTray/common/SlidingPaneFrame'
import useSlidingPane from 'features/workspace/AdminSideTray/hooks/useSlidingPane'
import { useUpdateDirtyPanes } from 'features/workspace/AdminSideTray/hooks/useUpdateDirtyPanes'

import useEffectOnlyOnUpdate from 'v2/ui/utils/useEffectOnlyOnUpdate'

import { ManageDataEmptyState } from './tabs/ManageDataEmptyState'
import { ManageDataTabs } from './tabs/ManageDataTabs'
import ManageTablesDropdown from './tabs/ManageTablesDropdown'
import { DataGridContextProvider } from './DataGridContext'
import { ManageDataActionsDropdown } from './ManageDataActionsDropdown'

const EMPTY_OBJECTS_PLACEHOLDER: ObjectDto[] = []

type ManageDataSidePaneProps = {
    objectId?: string
    shouldShowNewTableModal?: boolean
}

export const ManageDataSidePane: React.VFC<ManageDataSidePaneProps> = ({
    objectId,
    shouldShowNewTableModal,
}) => {
    const { setDirty } = useSlidingPane()
    const [selectedObjectId, setSelectedObjectId] = useState(objectId)
    const [isUploadCsvModalOpen, setIsUploadCsvModalOpen] = useState(false)
    const [isUploadTableDataModalOpen, setIsUploadTableDataModalOpen] = useState(false)
    const { updateDirtyPanes, isDirtyPanes, setIsDirtyPanes } = useUpdateDirtyPanes()

    const { show: showNewTableModal } = useNewNativeTableModal()
    const {
        data: objects = EMPTY_OBJECTS_PLACEHOLDER,
        isLoading: isObjectsLoading,
    }: { data: ObjectDto[] | undefined; isLoading: boolean } = useObjects()
    const { isLoading: isConnectionsLoading, data: dataConnections } = useDataConnections()
    const isLoading = isConnectionsLoading || isObjectsLoading

    useEffectOnlyOnUpdate(() => setSelectedObjectId(objectId), [objectId])

    // On component startup, show the new table modal if needed
    useEffect(() => {
        if (shouldShowNewTableModal) {
            setTimeout(() => {
                // Give some time for the data grid to open before opening the modal
                onAddTable()
            }, 200)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        // we update the dirty flags each time the objects have been updated
        // it ensures that each object has an entry in isDirtyPanes but more importantly
        // it cleans the deleted objects, preventing to be blocked in a dirty state in case
        // a deleted one was dirty
        setIsDirtyPanes((prevIsDirtyPanes) =>
            objects.reduce(
                (currentDirtyPanes, { _sid }) => ({
                    ...currentDirtyPanes,
                    [_sid]: prevIsDirtyPanes[_sid] ?? false,
                }),
                {}
            )
        )
    }, [objects, setIsDirtyPanes])

    useEffect(() => {
        const isDirty = !!Object.values(isDirtyPanes).find((dirty) => dirty)
        setDirty(isDirty)
    }, [isDirtyPanes, setDirty])

    /**
     * Opens the modal to create a new table
     */
    const onAddTable = useCallback((): void => {
        showNewTableModal({
            // @ts-ignore
            onObjectCreated: (_tableName, objectId) => {
                setSelectedObjectId(objectId)
            },
        })
    }, [showNewTableModal])

    const selectedTabIndex = Math.max(
        objects.findIndex((o) => o._sid === selectedObjectId),
        0
    )

    return (
        <DataGridContextProvider>
            <SlidingPaneFrame
                title="Manage data"
                description="Manage the tables and data which power your app."
                contentAreaProps={{ bg: 'userInterface.accent.1200' }}
            >
                <ManageDataTabs
                    objects={objects}
                    dataConnections={dataConnections}
                    isLoading={isLoading}
                    selectedTabIndex={selectedTabIndex}
                    isDirtyPanes={isDirtyPanes}
                    updateDirtyPanes={updateDirtyPanes}
                    leftTabsHeader={
                        <ManageTablesDropdown
                            tables={objects}
                            selectedTableId={selectedObjectId}
                            onSelectTable={(table) => setSelectedObjectId(table._sid)}
                        />
                    }
                    onSelectObject={setSelectedObjectId}
                    actions={
                        <ManageDataActionsDropdown
                            onSelectObject={setSelectedObjectId}
                            onOpenCsvModal={() => setIsUploadCsvModalOpen(true)}
                            onOpenTableDataModal={() => setIsUploadTableDataModalOpen(true)}
                        />
                    }
                    emptyState={
                        <ManageDataEmptyState
                            hasAllTablesDeactivated={objects.length > 0}
                            onAddTable={onAddTable}
                            onImportCsvTable={() => {
                                setIsUploadCsvModalOpen(true)
                            }}
                            onImportTableData={() => {
                                setIsUploadTableDataModalOpen(true)
                            }}
                            showImportCsvTableButton={true}
                            showImportTableDataButton={true}
                        />
                    }
                />
            </SlidingPaneFrame>
            <ImportCsvTableModal
                isOpen={isUploadCsvModalOpen}
                onClose={() => setIsUploadCsvModalOpen(false)}
                onSuccess={(objectId) => {
                    setSelectedObjectId(objectId)
                    setIsUploadCsvModalOpen(false)
                }}
                onError={() => setIsUploadCsvModalOpen(false)}
            />
            <ImportTableDataModal
                isOpen={isUploadTableDataModalOpen}
                onClose={() => setIsUploadTableDataModalOpen(false)}
                onSuccess={(objectId) => {
                    setSelectedObjectId(objectId)
                    setIsUploadTableDataModalOpen(false)
                }}
            />
        </DataGridContextProvider>
    )
}
