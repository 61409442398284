import React from 'react'

import { useDetailViewContext } from 'features/views/DetailView/hooks/useDetailViewContext'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'
import { Link } from 'ui/components/Link'
import { Body } from 'ui/components/Text'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'
import { theme } from 'ui/styling/Theme.css'

type DetailViewTopBarBreadcrumbsProps = React.ComponentPropsWithoutRef<typeof Box> & {
    size?: React.ComponentProps<typeof Body>['size']
    variant?: 'slim' | 'spacious'
    shade?: 'light' | 'dark'
}

export const DetailViewTopBarBreadcrumbs: React.FC<DetailViewTopBarBreadcrumbsProps> = ({
    style,
    size = 'm',
    variant = 'spacious',
    shade = 'light',
    ...props
}) => {
    const { breadcrumbs } = useDetailViewContext()!

    const textColor = shade === 'light' ? ('textWeaker' as const) : ('lightAlpha700' as const)

    const linkSize = useResponsiveValue(size) as React.ComponentPropsWithoutRef<typeof Link>['size']

    return (
        <Box flex center gap="xs" style={{ userSelect: 'none', ...style }} {...props}>
            {breadcrumbs.map((breadcrumb, idx) => {
                if (breadcrumb.type === 'list') {
                    const isClickable =
                        (breadcrumbs.length > 2 && idx > 0) || breadcrumbs.length === 2

                    return (
                        <React.Fragment key={breadcrumb.path}>
                            {isClickable ? (
                                <Link
                                    to={breadcrumb.path}
                                    shrink
                                    size={linkSize}
                                    style={{ minWidth: '32px' }}
                                >
                                    <Box flex center grow shrink>
                                        <Body
                                            size={size}
                                            weight={variant === 'slim' ? 'bold' : 'regular'}
                                            color={{
                                                default: variant === 'slim' ? 'text' : textColor,
                                                hover: shade === 'light' ? 'theme600' : 'theme200',
                                                active: shade === 'light' ? 'theme700' : 'theme300',
                                            }}
                                            trim
                                            style={{
                                                transition: theme.transition.colors,
                                            }}
                                        >
                                            {breadcrumb.label}
                                        </Body>
                                    </Box>
                                </Link>
                            ) : (
                                <Body
                                    size={size}
                                    weight={variant === 'slim' ? 'bold' : 'regular'}
                                    color={variant === 'slim' ? 'text' : textColor}
                                    trim
                                    style={{
                                        transition: theme.transition.colors,
                                    }}
                                >
                                    {breadcrumb.label}
                                </Body>
                            )}
                            {variant === 'slim' && (
                                <Icon
                                    name="ChevronRight"
                                    size="m"
                                    color={shade === 'light' ? 'icon' : 'iconInverse'}
                                    noShrink
                                    style={{
                                        transition: theme.transition.colors,
                                    }}
                                />
                            )}
                            {variant === 'spacious' && (
                                <Body
                                    size={size}
                                    weight="regular"
                                    color={shade === 'light' ? 'textWeakest' : 'lightAlpha700'}
                                    noShrink
                                    style={{
                                        transition: theme.transition.colors,
                                    }}
                                >
                                    /
                                </Body>
                            )}
                        </React.Fragment>
                    )
                }

                return null
            })}
        </Box>
    )
}
