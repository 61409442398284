import { useEffect, useMemo, useRef } from 'react'

type UseAdvancedFilterValueTextStateOptions = {
    value?: string
}

export function useAttributeTextInputState(options: UseAdvancedFilterValueTextStateOptions) {
    const { value } = options

    const inputRef = useRef<HTMLInputElement>(null)
    const textRef = useRef<HTMLElement>(null)

    useEffect(() => {
        const input = inputRef.current
        if (!input) return

        const textEl = textRef.current
        if (!textEl) return

        // Auto-size input based on text width.
        const widthOffset = 2
        input.style.width = `${textEl.clientWidth + widthOffset}px`
    }, [value])

    return useMemo(
        () => ({
            inputRef,
            textRef,
        }),
        []
    )
}
