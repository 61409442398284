import { WidgetSchema } from 'features/views/LayoutEditor/types'

import { ListViewWidgetAdminControls } from './controls/ListViewWidgetAdminControls'
import { ListViewWidget } from './ListViewWidget'
import { ListViewWidgetType } from './listViewWidgetTypes'

export const ListViewWidgetMeta: WidgetSchema<ListViewWidgetType> = {
    category: 'data',
    label: 'List view',
    icon: 'KanbanSquare',
    component: ListViewWidget,
    adminControlsComponent: ListViewWidgetAdminControls,
    defaultAttrs: {
        title: '',
        subtitle: '',
        display: 'table',
        inlineSorting: 'disabled',
        allowDownload: false,
        allowCreateRecords: true,
        showAllFields: true,
        columnConfigs: [],
        coverImageAspectRatio: '1:1',
        tableShowFieldIcons: false,
        tablePinPrimaryColumn: false,
        onRecordClick: 'preview',
    },
    requireFields: (attrs, fields) => {
        const linkedField = fields?.get(attrs.linkedField?.fieldSid!)
        if (!linkedField) return []

        return [linkedField.api_name]
    },
}
