import React from 'react'

import { useObjects } from 'data/hooks/objects'
import { QueryResultRendererConfig } from 'features/Agents/chat/renderers/types'
import { ObjectPicker } from 'features/studio/ui/ObjectPicker'

import { Box } from 'ui/components/Box'
import { Field } from 'ui/components/Field'
import { RadioCard, RadioCardGroup } from 'ui/components/Radio'
import { Body } from 'ui/components/Text'
import { Textarea } from 'ui/components/Textarea'

type QueryResultRendererConfiguratorProps = {
    config: QueryResultRendererConfig

    onChange: (config: QueryResultRendererConfig) => void
    setName: (name: string) => void
}

export function QueryResultRendererConfigurator({
    config,
    onChange,
    setName,
}: QueryResultRendererConfiguratorProps) {
    const { data: objects } = useObjects()
    return (
        <Box flex column gap="m">
            <Field label="Table" width="5xl" shrink>
                <ObjectPicker
                    value={config.object_sid}
                    onChange={(value: string) => {
                        onChange({
                            ...config,
                            object_sid: value,
                        })
                        setName(`${objects?.find((o) => o._sid === value)?.name} records`)
                    }}
                />
            </Field>

            <Field label="Display Style">
                <RadioCardGroup
                    value={config.display_mode ?? 'cards'}
                    onValueChange={(value) =>
                        onChange({
                            ...config,
                            display_mode: value as QueryResultRendererConfig['display_mode'],
                        })
                    }
                >
                    <Box
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            gap: '8px',
                        }}
                    >
                        <RadioCard value="cards" icon={{ name: 'LayoutGrid' }}>
                            <Body>Cards</Body>
                        </RadioCard>

                        <RadioCard value="markdown" icon={{ name: 'FileText' }}>
                            <Body>Markdown</Body>
                        </RadioCard>
                    </Box>
                </RadioCardGroup>
            </Field>

            {config.display_mode === 'markdown' && (
                <>
                    <Field
                        label="Header Template"
                        helperText="The template displayed above the query results."
                    >
                        <Textarea
                            value={config.markdown_header_template}
                            onChange={(e) =>
                                onChange({ ...config, markdown_header_template: e.target.value })
                            }
                        />
                    </Field>
                    <Field label="Record Template">
                        <Textarea
                            value={config.markdown_record_template}
                            onChange={(e) =>
                                onChange({
                                    ...config,
                                    markdown_record_template: e.target.value,
                                })
                            }
                            placeholder="Enter a markdown template for each record. Use {field_name} to refer to the field value."
                        />
                    </Field>
                </>
            )}
        </Box>
    )
}
