import { useEffect, useRef } from 'react'

import { isEqual, throttle } from 'lodash'
import Pusher from 'pusher-js'
import shortid from 'shortid'

import settings from 'app/settings'
import { useObjects } from 'data/hooks/objects'
import {
    isRecordAttachmentArray,
    isRecordAttachmentArrayEqual,
} from 'features/records/utils/attachments'

let DEBUG_LOGGING_ON = true

const setLogging = (value: boolean) => {
    DEBUG_LOGGING_ON = value
    Pusher.logToConsole = DEBUG_LOGGING_ON
}

export const realtimeUpdatesDebugLog = (...args: any[]) => {
    if (DEBUG_LOGGING_ON) {
        console.log(...args)
    }
}

// real time logs are off by default
if (import.meta.env.VITE_REALTIME_LOGS === 'true') {
    setLogging(true)
}

// default logging on, even in prod, if there is an _rtdebug flag set to any value in localstorage
try {
    if (typeof window !== 'undefined' && typeof window.localStorage !== 'undefined') {
        const flag = localStorage.getItem('_rtdebug')

        // being set to any value means on
        // this flag should be manually set / unset in the browser by the person testing, no API to toggle this
        if (!!flag) {
            // confirm set up correctly if flag set
            console.log(
                '\n\n_rtdebug flag is set in localstorage, logging for realtime updates is turned on'
            )

            setLogging(true)
        }
    }
} catch {
    // silently swallow any error here,
    // doesn't matter at all if fails in a user's browser
    // and we shouldn't log anything that reveals this flag can be set
}

if (typeof window !== 'undefined') {
    try {
        ;(window as any).____toggle_debug_rt = () => {
            const newVal = !DEBUG_LOGGING_ON
            setLogging(newVal)

            console.log('debug logging turned ' + (newVal ? 'on' : 'off'))
        }
    } catch (err) {
        console.error('could not setup window.____toggle_debug_rt', err)
    }
}

// util to check if enabled before doing more expensive logging operations
export const realtimeUpdatesLoggingEnabled = () => {
    return !!DEBUG_LOGGING_ON
}

// eslint-disable-next-line unused-imports/no-unused-vars
export const pusherDebugLog = (msg: string) => {
    //(msg, ...args) => {
    // TODO: Re-enable outside of tiger org
    // realtimeUpdatesDebugLog('Pusher DEBUG: ' + msg, ...args)
}

let _pusherClient: (Pusher & { failedAuths: string[] }) | null = null

const getPusherClient = (): Pusher & { failedAuths: string[] } => {
    if (!_pusherClient) {
        initPusherClient()
    }
    return _pusherClient as any
}

const initPusherClient = () => {
    // open a connection and keep it active for the entire browser session
    //
    // note, we need to manage these connections to try to clean them up when not being used,
    // as they're a finite resource and using more of them means paying more
    //
    // couple of strategies for this
    //  - close the connection after a certin period of inactivity, reopen again on activity
    //  - close the connection when user closes the browser window or navs away
    //

    _pusherClient = new Pusher(settings.PUSHER.key, {
        cluster: settings.PUSHER.cluster,
    }) as Pusher & { failedAuths: string[] }

    // 1 hour = inactive
    // this is just a first guess, feels long enough to not be a UX issue, short enough
    // that it should reduce issues with open browser tabs hogging available connections
    // can change later based on usage analysis etc
    const INACTIVE_DURATION = 1000 * 60 * 60

    let inactiveTimeout: number | null = null

    const disconnectClient = () => {
        _pusherClient?.disconnect()
    }

    const startInactivityTimeout = () => {
        inactiveTimeout = setTimeout(disconnectClient, INACTIVE_DURATION) as any
    }

    const onGlobalActivityDetected = () => {
        if (inactiveTimeout !== null) {
            clearTimeout(inactiveTimeout)
        }

        if (_pusherClient?.connection.state === 'disconnected') {
            _pusherClient?.connect()
        }

        startInactivityTimeout()
    }

    // restart inactivity timeout on any of these events
    const GLOBAL_ACTIVITY_EVENTS = ['mousedown', 'mousemove', 'keydown', 'scroll', 'touchstart']
    GLOBAL_ACTIVITY_EVENTS.forEach((ev) => {
        document.addEventListener(ev, onGlobalActivityDetected)
    })

    // also start the timeout on first usage of this service
    startInactivityTimeout()

    // also disconnect on the browser close event
    window.addEventListener('beforeunload', disconnectClient)

    _pusherClient.failedAuths = []

    return _pusherClient
}

// this needs to match the Events enum in realtime_updates_service.py in the backend
enum RealTimeUpdateEventType {
    cache_filled = 'cache_filled',
    data_connection_status_update = 'data_connection_status_update',
    field_schema_imported = 'field_schema_imported',
    object_schema_imported = 'object_schema_imported',
    updated = 'updated',
}

const subscribe = ({ channelName, eventHandlers }: { channelName: string; eventHandlers: any }) => {
    // hard stop for bugs here, just do nothing if anything required is undefined
    if (!channelName || !eventHandlers) {
        pusherDebugLog(
            `***ERROR*** _subscribe: one of channelName or eventHandlers args is undefined, doing nothing...`
        )
        return
    }

    // if already subscribed then we can skip the auth and get the channel from the client
    const pusherClient = getPusherClient()
    if (pusherClient.failedAuths.includes(channelName)) return null
    let channel = pusherClient.channel(channelName)
    if (!channel) channel = pusherClient.subscribe(channelName)

    // eventHandlers is an array of { event, handler, key } objects
    // bind each handler to run on the specified event on the channel
    for (let { event, handler, key } of eventHandlers) {
        channel.bind(event, handler)
        pusherDebugLog(`Subscribing [${event}] event for channel [${channelName}]  -- [key ${key}]`)
    }

    if (realtimeUpdatesLoggingEnabled()) {
        const events = eventHandlers.map((eventHandler: any) => eventHandler.event).join(', ')
        pusherDebugLog(`listening on channel [${channelName}] for events [${events}]`)
    }

    return channel
}

const unsubscribe = ({
    channelName,
    eventHandlers,
}: {
    channelName: string
    eventHandlers: any
}) => {
    if (!channelName || !eventHandlers) {
        pusherDebugLog(
            `***ERROR*** _unsubscribe: one of channelName or eventHandlers args is undefined, doing nothing...`
        )
        return
    }

    const pusherClient = getPusherClient()
    // if already subscribed this is a no-op
    const channel = pusherClient.subscribe(channelName)
    // eventHandlers is an array of { event, handler, key } objects
    // remove each key from our list of active subscribers, and if
    // no active subscribers are left, unbind from the channel
    for (let { event, handler, key } of eventHandlers) {
        pusherDebugLog(
            `Unsubscribing [${event}] event for channel [${channelName}]  -- [key ${key}]`
        )
        // Unbind this particular handler from the channel
        channel.unbind(event, handler)
    }
}

// diffs two records, returns:
//
// {
//   equal: boolean,
//   diff: key: { valueA: ..., valueB: ... } }
// }
//
// note diff only contains values that are different,
// i.e. no key means the values are the same
//
// assumptions
//  recordA has the same keys as recordB
//  only records diff in number, string and boolean values
//
// notably, this means _permisssions are just ignored
// if we need them later in the context of realtime updates, we can add this
// but for now just need the actual data of the record
const DIFF_SUPPORTED_VALUE_TYPES = ['string', 'number', 'boolean', 'object']
const DIFF_IGNORE_FIELDS = [
    'dereferencedFields',
    'singleFieldsDereferenced',
    'fetchedFields',
    '_partial',
    'newlyCreated',
    '_comment_count',
    '_last_comment_at',
    '_comment_counts',
    '_date_modified',
    '_date_created',
    '_permissions',
    '_dereferenced_records',
]

const TRACE_LOGGING = false // dev only trace log for really fine detail, just flip manually in code to turn on

export const diffRecords = (
    recordA: Record<string, any> | null | undefined,
    recordB: Record<string, any> | null | undefined,
    options: { fieldsToIgnore?: string[] } = {}
) => {
    const fieldsToIgnore = options.fieldsToIgnore ?? []

    if (realtimeUpdatesLoggingEnabled()) {
        realtimeUpdatesDebugLog('diffing records:', { ...recordA }, { ...recordB })
    }

    const diff: Record<string, any> = {}

    // if either record is undefined/null,
    // return empty diff and only equal if both undefined / null
    const recordAUndefined = !recordA
    const recordBUndefined = !recordB
    if (recordAUndefined || recordBUndefined) {
        const equal = recordAUndefined && recordBUndefined

        realtimeUpdatesDebugLog(`at least one is undefined, returning equal=${equal}`)

        return {
            equal,
            diff,
        }
    }

    let equal = true

    const fieldApiNamesToIgnore = new Set([...DIFF_IGNORE_FIELDS, ...fieldsToIgnore])

    // compare each value in recordA against the value in recordB
    // adding any diffs to the map where they are not equal
    Object.keys(recordA).forEach((key) => {
        const valueA = recordA[key]

        // this is a bit hacky, but a solution to the fact that the records
        // reducer adds certain fields that can differ and/or not be added
        // at all on first load vs reload, which causes every record to always
        // report a diff, even when the actual values didn't change
        if (fieldApiNamesToIgnore.has(key)) {
            if (TRACE_LOGGING) {
                realtimeUpdatesDebugLog(`ignoring field=${key} because it is in DIFF_IGNORE_FIELDS`)
            }

            return
        }

        // TODO: Support more types.
        if (!DIFF_SUPPORTED_VALUE_TYPES.includes(typeof valueA)) {
            if (TRACE_LOGGING) {
                realtimeUpdatesDebugLog(
                    `ignoring field=${key} because value is of type=${typeof valueA}`
                )
            }

            return
        }

        const valueB = recordB[key]
        let valuesEqual = isEqual(valueA, valueB)

        const isAttachmentArrayValueA = isRecordAttachmentArray(valueA)
        const isAttachmentArrayValueB = isRecordAttachmentArray(valueB)

        switch (true) {
            case isAttachmentArrayValueA && !isAttachmentArrayValueB:
            case !isAttachmentArrayValueA && isAttachmentArrayValueB:
                if (TRACE_LOGGING) {
                    realtimeUpdatesDebugLog(`comparing field=${key} as attachment`)
                }

                valuesEqual = false
                break

            case isAttachmentArrayValueA && isAttachmentArrayValueB:
                if (TRACE_LOGGING) {
                    realtimeUpdatesDebugLog(`comparing field=${key} as attachment`)
                }

                valuesEqual = isRecordAttachmentArrayEqual(valueA, valueB)
                break
        }

        if (TRACE_LOGGING) {
            realtimeUpdatesDebugLog(
                `field=${key} valueA=${valueA} valueB=${valueB} valuesEqual=${valuesEqual}`
            )
        }

        if (!valuesEqual) {
            equal = false
            diff[key] = {
                valueA,
                valueB,
            }
        }
    })

    if (realtimeUpdatesLoggingEnabled()) {
        realtimeUpdatesDebugLog(`returning equal: ${equal}, diff:`, { ...diff })
    }

    return {
        equal,
        diff,
    }
}

// utility for constructing a brand new clean records diff for use in component state
// when for example we clear changes to a record and want to reset back to clean state
export const buildCleanRecordsDiff = () => ({
    equal: true,
    diff: {},
})

export const useIsRealtimeUpdatesEnabled = (stack: StackDto | undefined | null) => {
    // Enable for everyone
    return Boolean(stack)
}

const checkObjectHasReadPerms = (objects: ObjectDto[] = [], objectId: string) => {
    const obj = objects.find((o) => o._sid === objectId)
    return (
        obj?._permissions?.may_read_fields?.length ||
        obj?._permissions?.maybe_may_read_fields?.length
    )
}

export const useRealtimeObjectUpdates = ({
    stack,
    objectIds,
    handler,
    disabled = false,

    /**
     * If the handler should be throttled.
     *
     * Handler is called both on the leading and trailing edge of the timeout.
     */
    throttleHandler = true,
}: {
    stack?: StackDto | null
    objectIds: string[]
    handler: (event: RealTimeUpdateEventType) => void
    disabled?: boolean
    throttleHandler?: boolean
}) => {
    const { data: objects } = useObjects()
    const enabled = useIsRealtimeUpdatesEnabled(stack) && !disabled
    const key = useRef(shortid()).current
    const realtimeThrottleTimeout = 30000

    // This allows us to use a global client that is only initialised once
    if (!_pusherClient && enabled) {
        initPusherClient()
    }

    useEffect(() => {
        let innerHandler: typeof handler = handler

        if (throttleHandler) {
            innerHandler = throttle(innerHandler, realtimeThrottleTimeout, {
                leading: true,
                trailing: true,
            })
        }

        // hard stop for bugs here, just do nothing if any required args are undefined
        if (!objectIds || !innerHandler) {
            pusherDebugLog(
                `***ERROR*** useRealtimeObjectUpdates: one of objectId, handler args is undefined, doing nothing...`
            )
            return
        }

        // do nothing if realtime updates not enabled for this stack
        if (!enabled) {
            return
        }

        const allEventHandlers: any[] = []

        objectIds.forEach((objectId, index) => {
            if (!checkObjectHasReadPerms(objects, objectId)) return
            // subscribe _handler to run on
            //   - object update events
            //   - object cache_filled events
            const obj = objects.find((o) => o._sid === objectId)
            const channel_name = obj?.channel_name
            const eventHandlers = [
                {
                    event: RealTimeUpdateEventType.updated,
                    handler: () => {
                        pusherDebugLog(
                            `${RealTimeUpdateEventType.updated} event received for object [${objectId}] -- [key ${key}]`
                        )
                        innerHandler(RealTimeUpdateEventType.updated)
                    },
                    key,
                },
                {
                    event: RealTimeUpdateEventType.cache_filled,
                    handler: () => {
                        pusherDebugLog(
                            `${RealTimeUpdateEventType.cache_filled} event received for object [${objectId}] -- [key ${key}]`
                        )
                        innerHandler(RealTimeUpdateEventType.cache_filled)
                    },
                    key,
                },
            ]

            allEventHandlers[index] = eventHandlers

            if (channel_name) {
                subscribe({
                    channelName: channel_name,
                    eventHandlers,
                })
            }
        })

        // unbind the handlers when the component unmounts
        return () => {
            objectIds.forEach((objectId, index) => {
                if (!checkObjectHasReadPerms(objects, objectId)) return
                const obj = objects.find((o) => o._sid === objectId)
                const channel_name = obj?.channel_name
                if (channel_name) {
                    unsubscribe({
                        channelName: channel_name,
                        eventHandlers: allEventHandlers[index],
                    })
                }
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enabled, objects, objectIds, throttleHandler, realtimeThrottleTimeout])
}

export const useRealtimeRecordUpdates = ({
    stack,
    recordSid,
    handler,
    disabled = false,
    /**
     * If the handler should be throttled in case the BE sends out updates too frequently.
     **/
    throttleHandler = true,
}: {
    stack: StackDto
    recordSid: string
    handler: (event: RealTimeUpdateEventType) => void
    disabled?: boolean
    throttleHandler?: boolean
}) => {
    const enabled = useIsRealtimeUpdatesEnabled(stack) && !disabled
    const key = useRef(shortid()).current
    const realtimeThrottleTimeout = 5000

    // This allows us to use a global client that is only initialised once
    if (!_pusherClient && enabled) {
        initPusherClient()
    }

    useEffect(() => {
        let _handler = handler

        if (throttleHandler) {
            _handler = throttle(_handler, realtimeThrottleTimeout, {
                leading: true,
                trailing: true,
            })
        }

        // hard stop for bugs here, just do nothing if any required args are undefined
        if (!recordSid || !_handler) {
            pusherDebugLog(
                `***ERROR*** useRealtimeRecordUpdates: one of recordSid, handler args is undefined, doing nothing...`
            )
            return
        }

        // do nothing if realtime updates not enabled for this stack
        if (!enabled) {
            return
        }

        // subscribe _handler to run
        const eventHandlers = [
            {
                event: RealTimeUpdateEventType.updated,
                handler: () => {
                    pusherDebugLog(
                        `${RealTimeUpdateEventType.updated} event received for record [${recordSid}] -- [key ${key}]`
                    )
                    _handler(RealTimeUpdateEventType.updated)
                },
                key,
            },
        ]

        subscribe({
            channelName: recordSid,
            eventHandlers,
        })

        // unbind the handlers when the component unmounts
        return () => {
            unsubscribe({
                channelName: recordSid,
                eventHandlers,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enabled, recordSid, throttleHandler, realtimeThrottleTimeout])
}

export const useRealtimeUpdates = ({
    channel,
    handler: innerHandler,
    disabled = false,
}: {
    channel: string
    handler: (event?: RealTimeUpdateEventType, data?: any) => void
    disabled?: boolean
}) => {
    const enabled = !disabled
    const key = useRef(shortid()).current
    const handlerRef = useRef(innerHandler)
    handlerRef.current = innerHandler

    // This allows us to use a global client that is only initialised once
    if (!_pusherClient && enabled) {
        initPusherClient()
    }

    useEffect(() => {
        // hard stop for bugs here, just do nothing if any required args are undefined
        if (!channel || !handlerRef.current) {
            pusherDebugLog(
                `***ERROR*** useRealtimeUpdates: one of channel, handler args is undefined, doing nothing...`
            )
            return
        }

        // do nothing if realtime updates not enabled for this stack
        if (!enabled) {
            return
        }

        const eventHandlers = [
            {
                event: RealTimeUpdateEventType.updated,
                handler: (data: any) => {
                    pusherDebugLog(
                        `${RealTimeUpdateEventType.updated} event received for object [${channel}] -- [key ${key}]`
                    )
                    handlerRef.current(RealTimeUpdateEventType.updated, data)
                },
                key,
            },
            {
                event: RealTimeUpdateEventType.object_schema_imported,
                handler: () => {
                    pusherDebugLog(
                        `${RealTimeUpdateEventType.object_schema_imported} event received for object [${channel}] -- [key ${key}]`
                    )
                    handlerRef.current(RealTimeUpdateEventType.object_schema_imported)
                },
                key,
            },
            {
                event: RealTimeUpdateEventType.data_connection_status_update,
                handler: (data: any) => {
                    pusherDebugLog(
                        `${RealTimeUpdateEventType.data_connection_status_update} event received for stack [${channel}] -- [key ${key}]`
                    )
                    handlerRef.current(RealTimeUpdateEventType.data_connection_status_update, data)
                },
                key,
            },
        ]

        subscribe({
            channelName: channel,
            eventHandlers,
        })

        // unbind the handlers when the component unmounts
        return () => {
            unsubscribe({
                channelName: channel,
                eventHandlers: eventHandlers,
            })
        }
    }, [channel, enabled, handlerRef, key])
}
