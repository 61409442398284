import React, { useMemo } from 'react'

import { useWorkflowSchema } from 'data/hooks/workflows/workflows'

import { Box } from 'ui/components/Box'
import { BoxProps } from 'ui/components/Box/Box'
import { Button } from 'ui/components/Button'
import { theme } from 'ui/styling/Theme.css'

import { DEFAULT_ACTION_ICON } from './common'
import { ItemIcon } from './ItemIcon'
import { sortWorkflowItemsByGroup } from './types'
import { useWorkflowEditorContext } from './useWorkflowEditorContext'

type WorkflowNodeListProps = BoxProps & {}

export const WorkflowNodeList: React.FC<WorkflowNodeListProps> = (props) => {
    const { data: schema } = useWorkflowSchema()

    const { addNode, workflow } = useWorkflowEditorContext()
    const allowReturnData = schema?.triggers.find(
        (t) => t.id === workflow.trigger.trigger_type
    )?.allow_return_data
    const nodes = useMemo(
        () =>
            sortWorkflowItemsByGroup(
                schema?.nodes?.filter((n) => n.id !== 'return_data' || allowReturnData) ?? []
            ),
        [schema?.nodes, allowReturnData]
    )

    return (
        <Box p="xl" {...props}>
            <Box as="h4" fontSize="headlineXs" mb="xl">
                Add new step
            </Box>
            <Box flex column gap="s">
                {nodes.map((nodeType) => (
                    <WorkflowNodeListItem
                        key={nodeType.id}
                        nodeType={nodeType}
                        onClick={() => addNode(nodeType)}
                    />
                ))}
            </Box>
        </Box>
    )
}

type WorkflowNodeListItemProps = React.ComponentPropsWithoutRef<typeof Button> & {
    nodeType: WorkflowSchemaNodeType
}

const WorkflowNodeListItem: React.FC<WorkflowNodeListItemProps> = ({ nodeType, ...props }) => {
    return (
        <Button
            type="button"
            flex
            center
            variant="secondary"
            style={{
                justifyContent: 'flex-start',
                paddingLeft: 0,
                height: 'auto',
                fontWeight: theme.fontWeights.bodyRegular as any,
            }}
            {...props}
        >
            <ItemIcon
                icon={nodeType.icon || DEFAULT_ACTION_ICON}
                iconStyle={nodeType.icon_style}
                mr="2xs"
                fontSize="bodyS"
                style={{
                    borderRadius: 0,
                }}
            />
            <Box as="span">{nodeType.name}</Box>
        </Button>
    )
}
