import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { WidgetAdminControlsOpenDetailPaneParams } from 'features/views/LayoutEditor/types'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'

export function useLayoutEditorWidgetControlsState() {
    const { selection, schema, commands } = useLayoutEditorContext()

    const widgetSchema = useMemo(() => {
        if (!selection?.widget?.type) return undefined
        return schema.widgets[selection.widget.type]
    }, [schema.widgets, selection?.widget?.type])

    const [detailPane, setDetailPane] = useState<
        WidgetAdminControlsOpenDetailPaneParams<any> | undefined
    >()
    const detailPaneRef = useRef(detailPane)
    detailPaneRef.current = detailPane

    const closeDetailPane = useCallback(() => setDetailPane(undefined), [])

    // Close the detail pane when the widget is changed.
    useEffect(() => {
        closeDetailPane()
    }, [selection?.widget?.id, closeDetailPane])

    const Component = detailPane?.component ?? widgetSchema?.adminControlsComponent

    const label = detailPane?.label ?? widgetSchema?.label ?? 'Widget'

    const onBackClick = useCallback(() => {
        const detailPane = detailPaneRef.current
        if (!!detailPane) {
            detailPane.onBackClick?.()
            closeDetailPane()
        } else {
            commands.deselectWidget()
        }
    }, [commands, closeDetailPane])

    return useMemo(
        () => ({
            Component,
            selectedWidget: selection?.widget,
            onBackClick,
            onChangeWidgetAttrs: commands.onChangeSelectedWidgetAttrs,
            label,
            openDetailPane: setDetailPane,
            closeDetailPane,
        }),
        [
            Component,
            selection?.widget,
            onBackClick,
            commands.onChangeSelectedWidgetAttrs,
            label,
            closeDetailPane,
        ]
    )
}
