import React from 'react'

import { useThreadViewLoaderState } from './hooks/useThreadViewLoaderState'
import { ThreadViewMessage } from './ThreadViewMessage'

type ThreadViewLoaderProps = {}

export const ThreadViewLoader: React.FC<ThreadViewLoaderProps> = () => {
    const { placeholderRows } = useThreadViewLoaderState()

    return (
        <>
            {placeholderRows.map((record) => (
                <ThreadViewMessage key={record._sid} isLoading record={record} />
            ))}
        </>
    )
}
