import { LinkBlocksWidgetLink } from './types'

export function getLinkBlockLabel(link: LinkBlocksWidgetLink) {
    if (!!link.title) {
        return link.title
    }

    if (!!link.link) {
        return link.link.label || link.link.href
    }

    return 'Link block'
}

export function isInlineLink(link: LinkBlocksWidgetLink) {
    return !link.title && !link.subtitle && link.header?.type !== 'image' && !!link.link?.href
}

export function isEmptyLink(link: LinkBlocksWidgetLink) {
    return !link.title && !link.subtitle && !link.link && !link.header
}
