import React, { useEffect, useRef, useState } from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Select } from 'ui/components/Select/Select'
import { SelectOption } from 'ui/components/Select/SelectOption'
import { Body } from 'ui/components/Text/Body'

export type CronFrequency = 'minute' | 'hour' | 'day' | 'week' | 'month'

export type CronEditorProps = {
    value: string
    onChange: (cronExpression: string) => void
}

// Helper function to generate cron expression based on current state
const generateCronExpression = (
    currentFrequency: CronFrequency,
    currentMinutes: number[],
    currentHours: number[],
    currentDaysOfWeek: number[],
    currentDaysOfMonth: number[]
): string => {
    let cronExpression = ''

    // Filter out any NaN values that might have been introduced
    const validMinutes = currentMinutes.filter((m) => !isNaN(m))
    const validHours = currentHours.filter((h) => !isNaN(h))
    const validDaysOfWeek = currentDaysOfWeek.filter((d) => !isNaN(d))
    const validDaysOfMonth = currentDaysOfMonth.filter((d) => !isNaN(d))

    switch (currentFrequency) {
        case 'minute':
            cronExpression = '* * * * *'
            break
        case 'hour':
            cronExpression = `${validMinutes.length ? validMinutes.join(',') : '0'} * * * *`
            break
        case 'day':
            cronExpression = `${validMinutes.length ? validMinutes.join(',') : '0'} ${validHours.length ? validHours.join(',') : '0'} * * *`
            break
        case 'week':
            cronExpression = `${validMinutes.length ? validMinutes.join(',') : '0'} ${validHours.length ? validHours.join(',') : '0'} * * ${validDaysOfWeek.length ? validDaysOfWeek.join(',') : '0'}`
            break
        case 'month':
            cronExpression = `${validMinutes.length ? validMinutes.join(',') : '0'} ${validHours.length ? validHours.join(',') : '0'} ${validDaysOfMonth.length ? validDaysOfMonth.join(',') : '1'} * *`
            break
    }

    return cronExpression
}

export const CronEditor: React.FC<CronEditorProps> = ({ value, onChange }) => {
    // Parse initial value or set defaults
    const [frequency, setFrequency] = useState<CronFrequency>('hour')
    const [minutes, setMinutes] = useState<number[]>([0]) // For multi-select
    const [hours, setHours] = useState<number[]>([0]) // For multi-select
    const [daysOfWeek, setDaysOfWeek] = useState<number[]>([0]) // For multi-select
    const [daysOfMonth, setDaysOfMonth] = useState<number[]>([1]) // For multi-select

    // Ref to track the previous value to avoid unnecessary updates
    const prevValueRef = useRef('')

    // Helper function to update cron expression and call onChange if needed
    const updateCronExpression = (
        currentFrequency: CronFrequency = frequency,
        currentMinutes: number[] = minutes,
        currentHours: number[] = hours,
        currentDaysOfWeek: number[] = daysOfWeek,
        currentDaysOfMonth: number[] = daysOfMonth
    ): void => {
        const cronExpression = generateCronExpression(
            currentFrequency,
            currentMinutes,
            currentHours,
            currentDaysOfWeek,
            currentDaysOfMonth
        )
        if (cronExpression !== value) {
            onChange(cronExpression)
        }
    }

    // Parse the cron expression when the value prop changes
    useEffect(() => {
        // Skip if the value hasn't changed
        if (value === prevValueRef.current) {
            return
        }

        prevValueRef.current = value

        if (!value) return

        const parts = value.split(' ')
        if (parts.length !== 5) return

        const [minuteExp, hourExp, dayOfMonthExp /* monthExp */, , dayOfWeekExp] = parts

        // Helper function to safely parse comma-separated values
        const safeParseIntArray = (input: string): number[] => {
            if (input === '*') return []

            // Handle empty segments and invalid values
            return input
                .split(',')
                .map((val) => val.trim())
                .filter((val) => val !== '') // Filter out empty segments
                .map((val) => {
                    const parsed = parseInt(val, 10)
                    return isNaN(parsed) ? null : parsed
                })
                .filter((val): val is number => val !== null) // Type guard to filter out null values
        }

        // Basic parsing for single values and multiple values
        if (minuteExp === '*' && hourExp === '*') {
            setFrequency('minute')
            setMinutes([])
        } else if (hourExp === '*') {
            setFrequency('hour')
            if (minuteExp.includes(',')) {
                // Multiple minutes
                setMinutes(safeParseIntArray(minuteExp))
            } else if (minuteExp !== '*') {
                const parsed = parseInt(minuteExp, 10)
                setMinutes(isNaN(parsed) ? [0] : [parsed])
            } else {
                setMinutes([])
            }
        } else if (dayOfMonthExp === '*' && dayOfWeekExp === '*') {
            setFrequency('day')

            if (minuteExp.includes(',')) {
                setMinutes(safeParseIntArray(minuteExp))
            } else if (minuteExp !== '*') {
                const parsed = parseInt(minuteExp, 10)
                setMinutes(isNaN(parsed) ? [0] : [parsed])
            } else {
                setMinutes([])
            }

            if (hourExp.includes(',')) {
                setHours(safeParseIntArray(hourExp))
            } else if (hourExp !== '*') {
                const parsed = parseInt(hourExp, 10)
                setHours(isNaN(parsed) ? [0] : [parsed])
            } else {
                setHours([])
            }
        } else if (dayOfMonthExp === '*' && dayOfWeekExp !== '*') {
            setFrequency('week')

            if (minuteExp.includes(',')) {
                setMinutes(safeParseIntArray(minuteExp))
            } else if (minuteExp !== '*') {
                const parsed = parseInt(minuteExp, 10)
                setMinutes(isNaN(parsed) ? [0] : [parsed])
            } else {
                setMinutes([])
            }

            if (hourExp.includes(',')) {
                setHours(safeParseIntArray(hourExp))
            } else if (hourExp !== '*') {
                const parsed = parseInt(hourExp, 10)
                setHours(isNaN(parsed) ? [0] : [parsed])
            } else {
                setHours([])
            }

            if (dayOfWeekExp.includes(',')) {
                setDaysOfWeek(safeParseIntArray(dayOfWeekExp))
            } else if (dayOfWeekExp !== '*') {
                const parsed = parseInt(dayOfWeekExp, 10)
                setDaysOfWeek(isNaN(parsed) ? [0] : [parsed])
            } else {
                setDaysOfWeek([])
            }
        } else if (dayOfMonthExp !== '*' && dayOfWeekExp === '*') {
            setFrequency('month')

            if (minuteExp.includes(',')) {
                setMinutes(safeParseIntArray(minuteExp))
            } else if (minuteExp !== '*') {
                const parsed = parseInt(minuteExp, 10)
                setMinutes(isNaN(parsed) ? [0] : [parsed])
            } else {
                setMinutes([])
            }

            if (hourExp.includes(',')) {
                setHours(safeParseIntArray(hourExp))
            } else if (hourExp !== '*') {
                const parsed = parseInt(hourExp, 10)
                setHours(isNaN(parsed) ? [0] : [parsed])
            } else {
                setHours([])
            }

            if (dayOfMonthExp.includes(',')) {
                setDaysOfMonth(safeParseIntArray(dayOfMonthExp))
            } else if (dayOfMonthExp !== '*') {
                const parsed = parseInt(dayOfMonthExp, 10)
                setDaysOfMonth(isNaN(parsed) ? [1] : [parsed])
            } else {
                setDaysOfMonth([])
            }
        }
    }, [value])

    // Handle frequency change
    const handleFrequencyChange = (newFrequency: string) => {
        const typedFrequency = newFrequency as CronFrequency
        setFrequency(typedFrequency)
        updateCronExpression(typedFrequency)
    }

    // Handle clear button click
    const handleClear = () => {
        setFrequency('hour')
        setMinutes([0])
        setHours([0])
        setDaysOfWeek([0])
        setDaysOfMonth([1])
        onChange('0 * * * *') // Default to every hour at minute 0
    }

    // Handle multi-select minute change
    const handleSetMinutes = (selectedMinutes: string[]) => {
        const numericMinutes = selectedMinutes
            .map((m) => {
                const parsed = parseInt(m, 10)
                return isNaN(parsed) ? null : parsed
            })
            .filter((m): m is number => m !== null)

        setMinutes(numericMinutes.length ? numericMinutes : [0])
        updateCronExpression(frequency, numericMinutes.length ? numericMinutes : [0])
    }

    // Handle multi-select hour change
    const handleSetHours = (selectedHours: string[]) => {
        const numericHours = selectedHours
            .map((h) => {
                const parsed = parseInt(h, 10)
                return isNaN(parsed) ? null : parsed
            })
            .filter((h): h is number => h !== null)

        setHours(numericHours.length ? numericHours : [0])
        updateCronExpression(frequency, minutes, numericHours.length ? numericHours : [0])
    }

    // Handle multi-select day of week change
    const handleSetDaysOfWeek = (selectedDays: string[]) => {
        const numericDays = selectedDays
            .map((d) => {
                const parsed = parseInt(d, 10)
                return isNaN(parsed) ? null : parsed
            })
            .filter((d): d is number => d !== null)

        setDaysOfWeek(numericDays.length ? numericDays : [0])
        updateCronExpression(frequency, minutes, hours, numericDays.length ? numericDays : [0])
    }

    // Handle multi-select day of month change
    const handleSetDaysOfMonth = (selectedDays: string[]) => {
        const numericDays = selectedDays
            .map((day) => {
                const parsed = parseInt(day, 10)
                return isNaN(parsed) ? null : parsed
            })
            .filter((d): d is number => d !== null)

        setDaysOfMonth(numericDays.length ? numericDays : [1])
        updateCronExpression(
            frequency,
            minutes,
            hours,
            daysOfWeek,
            numericDays.length ? numericDays : [1]
        )
    }

    // Generate minute options (0-59)
    const minuteOptions = Array.from({ length: 60 }, (_, i) => i)

    // Generate hour options (0-23)
    const hourOptions = Array.from({ length: 24 }, (_, i) => i)

    // Generate day of week options (0-6, where 0 is Sunday)
    const dayOfWeekOptions = [
        { value: 0, label: 'Sunday' },
        { value: 1, label: 'Monday' },
        { value: 2, label: 'Tuesday' },
        { value: 3, label: 'Wednesday' },
        { value: 4, label: 'Thursday' },
        { value: 5, label: 'Friday' },
        { value: 6, label: 'Saturday' },
    ]

    // Generate day of month options (1-31)
    const dayOfMonthOptions = Array.from({ length: 31 }, (_, i) => i + 1)

    return (
        <Box display="flex" alignItems="center" gap="s" flexWrap="wrap">
            <Box display="flex" alignItems="center" gap="s">
                <Body>Every</Body>

                <Select
                    value={frequency}
                    onChange={handleFrequencyChange}
                    placeholder="Select frequency"
                    width="auto"
                >
                    <SelectOption value="minute" label="minute" />
                    <SelectOption value="hour" label="hour" />
                    <SelectOption value="day" label="day" />
                    <SelectOption value="week" label="week" />
                    <SelectOption value="month" label="month" />
                </Select>
            </Box>

            {/* Render selects based on selected frequency */}
            {frequency === 'hour' && (
                <Box display="flex" alignItems="center" gap="s">
                    <Body>at</Body>
                    <Select
                        value={minutes.map((m) => m.toString())}
                        onChange={handleSetMinutes}
                        placeholder="Select minutes"
                        multiSelect
                    >
                        {minuteOptions.map((m) => (
                            <SelectOption key={m} value={m.toString()} label={m.toString()} />
                        ))}
                    </Select>
                    <Body>minute(s)</Body>
                </Box>
            )}

            {(frequency === 'day' || frequency === 'week' || frequency === 'month') && (
                <Box display="flex" alignItems="center" gap="s">
                    <Body>at</Body>
                    <Select
                        value={hours.map((h) => h.toString())}
                        onChange={handleSetHours}
                        placeholder="Select hours"
                        multiSelect
                    >
                        {hourOptions.map((h) => (
                            <SelectOption key={h} value={h.toString()} label={h.toString()} />
                        ))}
                    </Select>
                    <Body>:</Body>
                    <Select
                        value={minutes.map((m) => m.toString())}
                        onChange={handleSetMinutes}
                        placeholder="Select minutes"
                        multiSelect
                    >
                        {minuteOptions.map((m) => (
                            <SelectOption
                                key={m}
                                value={m.toString()}
                                label={m < 10 ? `0${m}` : m.toString()}
                            />
                        ))}
                    </Select>
                </Box>
            )}

            {frequency === 'week' && (
                <Box display="flex" alignItems="center" gap="s">
                    <Body>on</Body>
                    <Select
                        value={daysOfWeek.map((d) => d.toString())}
                        onChange={handleSetDaysOfWeek}
                        placeholder="Select days"
                        multiSelect
                    >
                        {dayOfWeekOptions.map((option) => (
                            <SelectOption
                                key={option.value}
                                value={option.value.toString()}
                                label={option.label}
                            />
                        ))}
                    </Select>
                </Box>
            )}

            {frequency === 'month' && (
                <Box display="flex" alignItems="center" gap="s">
                    <Body>on day</Body>
                    <Select
                        value={daysOfMonth.map((d) => d.toString())}
                        onChange={handleSetDaysOfMonth}
                        placeholder="Select days"
                        multiSelect
                    >
                        {dayOfMonthOptions.map((d) => (
                            <SelectOption key={d} value={d.toString()} label={d.toString()} />
                        ))}
                    </Select>
                </Box>
            )}

            {/* Clear button */}
            <Button variant="secondary" onClick={handleClear}>
                Reset
            </Button>
        </Box>
    )
}

export default CronEditor
