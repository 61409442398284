import GridCardBlockType from 'v2/blocks/blockTypes/entity/GridCardBlock'
import ChartsBlock from 'v2/blocks/blockTypes/view/aggregationBlocks/ChartsBlock'
import MetricsBlock from 'v2/blocks/blockTypes/view/aggregationBlocks/MetricsBlock'
import AIAgentBlock from 'v2/blocks/blockTypes/view/AIAgentBlock'
import DetailViewRelatedList from 'v2/blocks/blockTypes/view/DetailViewRelatedListBlock'
import FieldContainerBlock from 'v2/blocks/blockTypes/view/FieldContainerBlock'
import FieldHighlightsBlock from 'v2/blocks/blockTypes/view/FieldHighlightsBlock'
import IframeBlock from 'v2/blocks/blockTypes/view/IframeBlock'
import PipelineBlock from 'v2/blocks/blockTypes/view/PipelineBlock'
import QuickLinksBlock from 'v2/blocks/blockTypes/view/QuickLinksBlock'
import TextBlock from 'v2/blocks/blockTypes/view/TextBlock'

import ContainerBlockType from 'features/pages/blocks/blockTypes/layout/Container_v1.1'
import { ViewBlockWrapper } from 'features/pages/blocks/blockTypes/view/ViewBlock'

import { AggregationBlockContextProvider } from './blockTypes/view/aggregationBlocks/common/AggregationBlockContext'
import CalloutBlock from './blockTypes/view/CalloutBlock'
import DataListBlock from './blockTypes/view/DataListBlock'
import RelatedRecordBlock from './blockTypes/view/RelatedRecordBlock'
import TaskListBlock from './blockTypes/view/TaskListBlock'
import type { blockType } from './types'

type BlockGroup = {
    name: string
    blocks: blockType[]
    hide?: boolean
}

export function getList(): BlockGroup[] {
    return [
        {
            name: 'Layout',
            blocks: [
                {
                    id: 'template_container',
                    label: 'Vertical Container',
                    icon: 'vertical-docking',
                    blockElement: ContainerBlockType,
                    isContainer: true,
                    defaultConfig: {
                        style: {
                            default: {
                                flexWrap: 'nowrap',
                                width: '300',
                                height: '100',
                            },
                        },
                        attributes: { direction: 'column', userAdded: true },
                    },
                },
                {
                    id: 'template_container',
                    label: 'Horizontal container',
                    icon: 'horizontal-docking',
                    blockElement: ContainerBlockType,
                    isContainer: true,
                    defaultConfig: {
                        style: {
                            default: {
                                flexWrap: 'nowrap',
                                width: '300',
                                height: '100',
                            },
                        },
                        attributes: { direction: 'row', userAdded: true },
                    },
                },
                {
                    id: 'template_gridcard',
                    label: 'Grid Card',
                    icon: 'unchecked-checkbox',
                    blockElement: GridCardBlockType,
                    isContainer: true,
                    defaultConfig: {
                        style: {
                            default: { boxShadow: 'none', borderRadius: '5px' },
                        },
                        attributes: { direction: 'row', userAdded: true },
                    },
                },
            ],
        },
        {
            name: 'Smart blocks',
            blocks: [
                {
                    id: 'template_ai_agent',
                    label: 'AI Agent',
                    icon: 'bot',
                    blockElement: AIAgentBlock,
                    defaultConfig: {
                        attributes: {
                            agentSid: undefined,
                        },
                        style: {
                            default: {
                                width: '100%',
                                height: '500px',
                            },
                        },
                    },
                },
                {
                    id: 'template_view',
                    label: 'View',
                    icon: 'eye',
                    blockElement: ViewBlockWrapper,
                },
                {
                    id: 'template_detail_view_related_list',
                    label: 'Related List',
                    icon: 'data-sheet',
                    blockElement: DetailViewRelatedList,
                },
                {
                    id: 'template_iframe',
                    label: 'Embed',
                    icon: 'domain',
                    blockElement: IframeBlock,
                },
                {
                    id: 'template_related_record',
                    label: 'Related record',
                    icon: 'domain',
                    blockElement: RelatedRecordBlock,
                    defaultConfig: {
                        attributes: {
                            fieldId: null,
                            content: [],
                            label: null,
                            columns: 3,
                            actionButtons: [],
                        },
                        style: {
                            default: {
                                width: '100%',
                            },
                        },
                    },
                },
                {
                    id: 'template_pipeline',
                    label: 'Pipeline',
                    icon: 'domain',
                    blockElement: PipelineBlock,
                    defaultConfig: {
                        attributes: {
                            stages: [],
                            readOnly: false,
                            fieldId: null,
                        },
                        style: {
                            default: {
                                width: '100%',
                            },
                        },
                    },
                },
                {
                    id: 'template_field_container',
                    label: 'Fields',
                    icon: 'data-sheet',
                    blockElement: FieldContainerBlock,
                    defaultConfig: {
                        attributes: {
                            contents: [],
                        },
                        style: {
                            default: {
                                width: '100%',
                            },
                        },
                    },
                },
                {
                    id: 'template_field_highlights',
                    label: 'Highlights',
                    icon: 'data-sheet',
                    /* @ts-expect-error */
                    blockElement: FieldHighlightsBlock,
                    defaultConfig: {
                        attributes: {
                            contents: [],
                        },
                        style: {
                            default: {
                                width: '100%',
                            },
                        },
                    },
                },
                {
                    id: 'template_callout',
                    label: 'Callout',
                    icon: 'data-sheet',
                    blockElement: CalloutBlock,
                    defaultConfig: {
                        attributes: {
                            title: '',
                            subtitle: '',
                            style: 'form',
                        },
                        style: {
                            default: {
                                width: '100%',
                            },
                        },
                    },
                },
                {
                    id: 'template_quicklinks',
                    label: 'Quick Links',
                    icon: 'data-sheet',
                    blockElement: QuickLinksBlock,
                    defaultConfig: {
                        attributes: {
                            items: [],
                        },
                        style: {
                            default: {
                                width: '100%',
                            },
                        },
                    },
                },
                {
                    id: 'template_tasks',
                    label: 'Task List',
                    icon: 'data-sheet',
                    blockElement: TaskListBlock,
                    defaultConfig: {
                        attributes: {
                            title: 'Tasks',
                        },
                        style: {
                            default: {
                                width: '100%',
                            },
                        },
                    },
                },
                {
                    id: 'template_text',
                    label: 'Text',
                    icon: 'multiline-text',
                    blockElement: TextBlock,
                    defaultConfig: {
                        attributes: {
                            content: '',
                        },
                        style: {
                            default: {
                                width: '100%',
                            },
                        },
                    },
                },
                {
                    id: 'template_table',
                    label: 'Table',
                    icon: 'data-sheet',
                    blockElement: DataListBlock,
                    defaultConfig: {
                        attributes: {
                            items: [],
                        },
                        style: {
                            default: {
                                width: '100%',
                            },
                        },
                    },
                },
                {
                    id: 'template_card',
                    label: 'Cards',
                    icon: 'data-sheet',
                    blockElement: DataListBlock,
                    defaultConfig: {
                        attributes: {
                            items: [],
                        },
                        style: {
                            default: {
                                width: '100%',
                            },
                        },
                    },
                },
                {
                    id: 'template_rows',
                    label: 'Rows',
                    icon: 'data-sheet',
                    blockElement: DataListBlock,
                    defaultConfig: {
                        attributes: {
                            items: [],
                        },
                        style: {
                            default: {
                                width: '100%',
                            },
                        },
                    },
                },
                {
                    id: 'template_metrics',
                    label: 'Big numbers',
                    icon: 'data-sheet',
                    blockElement: MetricsBlock,
                    contextProvider: AggregationBlockContextProvider,
                    defaultConfig: {
                        attributes: {
                            metrics: [],
                        },
                        style: {
                            default: {
                                width: '100%',
                            },
                        },
                    },
                },
                {
                    id: 'template_charts',
                    label: 'Charts',
                    icon: 'data-sheet',
                    blockElement: ChartsBlock,
                    contextProvider: AggregationBlockContextProvider,
                    defaultConfig: {
                        attributes: {
                            metrics: [],
                        },
                        style: {
                            default: {
                                width: '100%',
                            },
                        },
                    },
                },
            ],
        },
    ]
}

const blocksById = getList().reduce<Record<string, blockType>>((state, { blocks }) => {
    for (const block of blocks) {
        state[block.id] = block
    }

    return state
}, {})

export const getBlockByType = (type: string, defaultValue?: blockType): blockType => {
    return blocksById[`template_${type}`] || defaultValue || blocksById.template_card
}
