import React from 'react'

import { CollapsedNavigationTrigger } from 'features/navigation/CollapsedNavigationTrigger'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useDetailViewTopBarNavigationState } from './hooks/useDetailViewTopBarNavigationState'

import { DetailViewHeaderDarkButtonStyle } from './DetailViewHeader.css'

type DetailViewTopBarNavigationProps = React.ComponentPropsWithoutRef<typeof Box> & {
    shade?: 'light' | 'dark'
    allowBackButton?: boolean
}

export const DetailViewTopBarNavigation: React.FC<DetailViewTopBarNavigationProps> = ({
    shade = 'light',
    allowBackButton = true,
    ...props
}) => {
    const { showGoBackButton, showMenuButton, goBack } = useDetailViewTopBarNavigationState()

    const isMobile = useResponsiveValue({
        mobile: true,
        tablet: false,
    })

    const showBackButton = allowBackButton && isMobile && showGoBackButton

    const isEmpty = !showBackButton && !showMenuButton
    if (isEmpty) return null

    return (
        <Box flex center gap={{ mobile: 's', tablet: 'm' }} noShrink {...props}>
            {allowBackButton && isMobile && showGoBackButton && (
                <Button
                    size="xs"
                    variant="ghost"
                    startIcon={{ name: 'ArrowLeft' }}
                    onClick={goBack}
                    className={shade === 'dark' ? DetailViewHeaderDarkButtonStyle : undefined}
                />
            )}
            {showMenuButton && (
                <Box flex center>
                    <CollapsedNavigationTrigger
                        className={shade === 'dark' ? DetailViewHeaderDarkButtonStyle : undefined}
                    />
                </Box>
            )}
        </Box>
    )
}
