import React from 'react'

import { Box } from 'ui/components/Box'
import { Skeleton } from 'ui/components/Skeleton'
import { Body } from 'ui/components/Text'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'
import { theme } from 'ui/styling/Theme.css'

import { useDetailViewTopBarState } from './hooks/useDetailViewTopBarState'
import { DetailViewTopBarBreadcrumbs } from './DetailViewTopBarBreadcrumbs'
import { DetailViewTopBarNavigation } from './DetailViewTopBarNavigation'
import { DetailViewTopBarQuickActions } from './DetailViewTopBarQuickActions'

type DetailViewTopBarRef = HTMLDivElement

type DetailViewTopBarProps = React.ComponentPropsWithoutRef<typeof Box> & {
    shade?: 'light' | 'dark'
}

export const DetailViewTopBar: React.FC<DetailViewTopBarProps> = React.forwardRef<
    DetailViewTopBarRef,
    DetailViewTopBarProps
>(({ shade = 'light', ...props }, ref) => {
    const { isLoading, topBarStyle, title } = useDetailViewTopBarState()

    const showQuickActionsInline = useResponsiveValue({
        mobile: true,
        tablet: topBarStyle === 'breadcrumbs',
    })

    return (
        <Box
            ref={ref}
            flex
            center
            justifyContent={showQuickActionsInline ? 'flex-start' : 'space-between'}
            gap="m"
            {...props}
        >
            <Box flex center gap="m" shrink grow={!showQuickActionsInline}>
                <DetailViewTopBarNavigation shade={shade} allowBackButton={false} />
                <Skeleton isLoading={isLoading}>
                    <Box flex center grow shrink gap="xs">
                        <DetailViewTopBarBreadcrumbs
                            size={{ mobile: 's', tablet: 'm' }}
                            shade={shade}
                        />
                        <Skeleton isLoading={isLoading}>
                            <Body
                                display="block"
                                trim
                                size={{ mobile: 's', tablet: 'm' }}
                                color={shade === 'light' ? 'text' : 'textInverse'}
                                style={{
                                    transition: theme.transition.colors,
                                }}
                            >
                                {title}
                            </Body>
                        </Skeleton>
                    </Box>
                </Skeleton>
            </Box>
            <DetailViewTopBarQuickActions showAsDropdown={showQuickActionsInline} shade={shade} />
        </Box>
    )
})
