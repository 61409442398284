const EDITABLE_TYPES = new Set<FieldType>([
    'checkbox',
    'currency',
    'date',
    'datetime',
    'dropdown',
    'long_text',
    'document',
    'lookup',
    'multi_file',
    'multi_lookup',
    'multi_select',
    'number',
    'percentage',
    'string',
    'url',
])

export function isFieldEditable(field: FieldDto): boolean {
    return !field.is_read_only && EDITABLE_TYPES.has(field.type)
}

export function canFieldBeRequired(field: FieldDto): boolean {
    return !field.is_read_only && field.type !== 'checkbox'
}

export function canFieldBeTruncated(field: FieldDto): boolean {
    return ['long_text', 'document'].includes(field.type)
}
