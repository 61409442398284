import React from 'react'

import { DropdownAttributeDisplay } from 'features/views/attributes/DropdownAttributeDisplay'
import { DropdownAttributeTag } from 'features/views/attributes/DropdownAttributeTag'

import { useDropdownAttributeState } from './hooks/useDropdownAttributeState'
import { BaseAttribute } from './BaseAttribute'
import { MAX_LENGTH_TAG, MAX_LINES } from './constants'
import { ThreadViewAttributeComponent } from './types'

const MAX_OPTIONS = 2
const MAX_OPTIONS_TAG = 3

type DropdownAttributeProps = {}

export const DropdownAttribute: ThreadViewAttributeComponent<
    string | string[],
    DropdownAttributeProps
> = ({ value, attribute, isLoading, showIcon, ...props }) => {
    const { field, type } = attribute

    const isMessage = type === 'message'
    const isContent = type === 'content'
    const { isEmpty, size, showAsTag } = useDropdownAttributeState({ value, isLoading, attribute })

    let maxLines: number | undefined = MAX_LINES
    if (isMessage) {
        maxLines = undefined
    }

    let maxOptions: number | undefined = MAX_OPTIONS_TAG
    if (isMessage) {
        maxOptions = undefined
    } else if (isContent) {
        maxOptions = MAX_OPTIONS
    }

    return (
        <BaseAttribute attribute={attribute} isEmpty={isEmpty} isLoading={isLoading} {...props}>
            {showAsTag ? (
                <DropdownAttributeTag
                    size={size}
                    value={value}
                    field={field}
                    type="solid"
                    isLoading={isLoading}
                    maxOptions={MAX_OPTIONS_TAG}
                    minWidth={0}
                    showIcon={showIcon}
                    maxLength={MAX_LENGTH_TAG}
                />
            ) : (
                <DropdownAttributeDisplay
                    size={size}
                    value={value}
                    field={field}
                    maxLines={maxLines}
                    maxOptions={maxOptions}
                    isLoading={isLoading}
                />
            )}
        </BaseAttribute>
    )
}
