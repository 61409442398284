import React from 'react'

import { isEqual } from 'lodash'

import { Box } from 'ui/components/Box'
import { Popup, PopupContent, PopupInner, PopupTrigger } from 'ui/components/Popup'
import { Skeleton } from 'ui/components/Skeleton'
import { Tag } from 'ui/components/Tag'
import { stopPropagation } from 'ui/helpers/utilities'
import { ResponsiveValue, useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useDropdownAttributeTagState } from './hooks/useDropdownAttributeTagState'
import { AttributeDisplayComponent } from './types'

const sizeMapping = {
    tag: {
        s: '2xs',
        m: 'xs',
        l: 's',
    },
} as const

type DropdownAttributeTagProps = React.ComponentPropsWithoutRef<typeof Box> & {
    size?: ResponsiveValue<'s' | 'm' | 'l'>
    shape?: React.ComponentPropsWithoutRef<typeof Tag>['shape']
    type?: React.ComponentPropsWithoutRef<typeof Tag>['type']
    maxOptions?: number
    maxItemLength?: number
    isLoading?: boolean
    showIcon?: boolean
}

export const DropdownAttributeTag: AttributeDisplayComponent<
    string | string[],
    DropdownAttributeTagProps
> = React.memo(function DropdownAttributeTag({
    value,
    field,
    maxItemLength,
    isLoading,
    maxOptions,
    style,
    size = 'm',
    showIcon,
    shape,
    type = 'outline',
    ...props
}) {
    const effectiveSize = useResponsiveValue(size)
    const tagSize = sizeMapping.tag[effectiveSize]

    const {
        options,
        isOverflowPopupOpen,
        onOverflowPopupOpenChange,
        onOverflowLabelMouseEnter,
        onOverflowLabelMouseLeave,
        onOverflowLabelFocus,
        onOverflowLabelBlur,
        label,
        icon,
    } = useDropdownAttributeTagState({
        maxOptions,
        field,
        value,
        maxItemLength,
        isLoading,
        showIcon,
    })

    if (options.length === 0) {
        return null
    }

    const isSingle = options.length === 1

    return (
        <Box flex center {...props}>
            <Skeleton isLoading={isLoading}>
                {isSingle ? (
                    <Tag
                        color={options[0].color}
                        type={type}
                        size={tagSize}
                        noShrink
                        pointerEvents="none"
                        tabIndex={-1}
                        startIcon={icon}
                        shape={shape}
                        style={{
                            wordBreak: 'break-word',
                            maxWidth: '100%',
                            minWidth: 0,
                            ...style,
                        }}
                    >
                        <Box trim maxWidth="full" py="3xs">
                            {options[0].label}
                        </Box>
                    </Tag>
                ) : (
                    <Box minWidth={0} maxWidth="full" onClick={stopPropagation}>
                        <Popup open={isOverflowPopupOpen} onOpenChange={onOverflowPopupOpenChange}>
                            <PopupTrigger
                                asChild
                                onMouseEnter={onOverflowLabelMouseEnter}
                                onFocus={onOverflowLabelFocus}
                                onMouseLeave={onOverflowLabelMouseLeave}
                                onBlur={onOverflowLabelBlur}
                            >
                                <Tag
                                    type={type}
                                    color="Neutral"
                                    size={tagSize}
                                    noShrink
                                    startIcon={icon}
                                    shape={shape}
                                    style={{
                                        wordBreak: 'break-word',
                                        maxWidth: '100%',
                                        minWidth: 0,
                                        ...style,
                                    }}
                                >
                                    <Box trim maxWidth="full" py="3xs">
                                        {label}
                                    </Box>
                                </Tag>
                            </PopupTrigger>
                            <PopupContent
                                sideOffset={0}
                                onOpenAutoFocus={stopPropagation}
                                onCloseAutoFocus={stopPropagation}
                                onMouseEnter={onOverflowLabelMouseEnter}
                                onMouseLeave={onOverflowLabelMouseLeave}
                                side="bottom"
                                align="end"
                                style={{
                                    width: 'auto',
                                    maxWidth: '300px',
                                }}
                            >
                                <PopupInner flex center gap="xs" wrap>
                                    {options.map((option) => (
                                        <Tag
                                            color={option.color}
                                            size="xs"
                                            key={option.value}
                                            noShrink
                                            pointerEvents="none"
                                            mb="xs"
                                            tabIndex={-1}
                                            style={{
                                                wordBreak: 'break-word',
                                                ...style,
                                            }}
                                            minWidth={0}
                                            maxWidth="full"
                                        >
                                            <Box trim maxWidth="full" py="3xs">
                                                {option.label}
                                            </Box>
                                        </Tag>
                                    ))}
                                </PopupInner>
                            </PopupContent>
                        </Popup>
                    </Box>
                )}
            </Skeleton>
        </Box>
    )
}, isEqual)
