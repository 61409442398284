import { useMemo } from 'react'

import { useDetailViewContext } from 'features/views/DetailView/hooks/useDetailViewContext'

export function useDetailViewCompactLayoutState() {
    const { contentWidth, sidebarStyle, header, rightOffset } = useDetailViewContext()!

    const headerStyle = header.type

    return useMemo(
        () => ({
            contentWidth,
            sidebarStyle,
            headerStyle,
            rightOffset,
        }),
        [contentWidth, sidebarStyle, headerStyle, rightOffset]
    )
}
