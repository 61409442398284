import React from 'react'

import { EmbeddedNoRecordsEmptyState } from 'features/views/ListView/EmptyState/EmbeddedNoRecordsEmptyState'
import { EmbeddedNoRecordsFiltersEmptyState } from 'features/views/ListView/EmptyState/EmbeddedNoRecordsFiltersEmptyState'
import { EmbeddedServerErrorEmptyState } from 'features/views/ListView/EmptyState/EmbeddedServerErrorEmptyState'
import { NoRecordsEmptyState } from 'features/views/ListView/EmptyState/NoRecordsEmptyState'
import { NoRecordsFiltersEmptyState } from 'features/views/ListView/EmptyState/NoRecordsFiltersEmptyState'
import { ServerErrorEmptyState } from 'features/views/ListView/EmptyState/ServerErrorEmptyState'

import { Box } from 'ui/components/Box'

import { useThreadViewDataState } from './hooks/useThreadViewDataState'
import { ThreadViewPagination } from './Pagination/ThreadViewPagination'
import { ThreadViewContent } from './ThreadViewContent'
import { ThreadViewGrid } from './ThreadViewGrid'
import { ThreadViewLoader } from './ThreadViewLoader'

import { ThreadViewDataStyle } from './ThreadView.css'

type ThreadViewDataProps = {}

export const ThreadViewData: React.FC<ThreadViewDataProps> = React.memo(function ThreadViewData() {
    const {
        isEmpty,
        isEmptyWithFilters,
        clearFilters,
        showTable,
        isError,
        retryFetchRecords,
        isFetchingSlow,
        isEmbedded,
        showTopPadding,
        isSplitLayout,
    } = useThreadViewDataState()

    return (
        <Box
            px={{ mobile: 'xl', tablet: '4xl' }}
            pt={{ tablet: showTopPadding ? 'l' : undefined }}
            pb={{ tablet: '3xl' }}
            width="full"
            overflow="hidden"
            flex
            center
            flexDirection="column"
            background="surface"
            className={ThreadViewDataStyle}
        >
            {isEmpty && isEmbedded && <EmbeddedNoRecordsEmptyState />}
            {isEmpty && !isEmbedded && <NoRecordsEmptyState />}
            {isEmptyWithFilters && isEmbedded && <EmbeddedNoRecordsFiltersEmptyState />}
            {isEmptyWithFilters && !isEmbedded && (
                <NoRecordsFiltersEmptyState onClearFilters={clearFilters} />
            )}
            {isError && isEmbedded && <EmbeddedServerErrorEmptyState />}
            {isError && !isEmbedded && <ServerErrorEmptyState onRetry={retryFetchRecords} />}
            {showTable && (
                <>
                    <ThreadViewGrid gap={isSplitLayout ? 0 : 'm'}>
                        {isFetchingSlow ? <ThreadViewLoader /> : <ThreadViewContent />}
                    </ThreadViewGrid>
                    {!isFetchingSlow && <ThreadViewPagination />}
                </>
            )}
        </Box>
    )
})
