// eslint-disable-next-line simple-import-sort/imports
import { scan } from 'react-scan'

// @ts-strict-ignore
import React from 'react'
import { render } from 'react-dom'

import './utils/dev-tools'

import { RootWithLdProvider } from './app/RootWithLdProvider'
import store from './app/store'
import { consumeLoadAction } from './features/loadActions'

import 'v2/ui/theme/styles.scss'

// This is disabled in production by default.
scan({
    // Enable to see unnecessary renders.
    enabled: false,
})

function doRender(RootElem) {
    render(<RootElem store={store} />, document.getElementById('app'))
}

consumeLoadAction()
doRender(RootWithLdProvider)
