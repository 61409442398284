import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'

import { preloadImage } from 'features/views/attributes/utils'
import { HEADER_BACKGROUND_COLORS } from 'features/views/DetailView/headers/utils'
import { useDetailViewContext } from 'features/views/DetailView/hooks/useDetailViewContext'
import { DetailViewHeaderSpacious } from 'features/views/DetailView/types'

const COVER_IMAGE_LOAD_TIMEOUT = 5000

export function useDetailViewSpaciousHeaderCoverImageState() {
    const { header, recordManager, fields } = useDetailViewContext()!

    const coverType = (header as DetailViewHeaderSpacious).coverType ?? 'none'
    const coverBackgroundColor =
        (header as DetailViewHeaderSpacious).coverBackgroundColor ?? 'NeutralLight'
    const coverImage = (header as DetailViewHeaderSpacious).coverImage ?? null

    let backgroundImageUrl: string | undefined
    if (coverImage?.type === 'field') {
        const coverImageField = fields.find((field) => field.api_name === coverImage.fieldApiName)
        const fieldValue = recordManager.record?.[coverImageField?.api_name!]

        backgroundImageUrl = coverImageField
            ? extractSrcFromFieldValue(coverImageField, fieldValue)
            : undefined
    } else if (coverImage?.type === 'file') {
        backgroundImageUrl = coverImage.url
    }

    const isMounted = useRef(true)
    useLayoutEffect(() => {
        isMounted.current = true
        return () => {
            isMounted.current = false
        }
    }, [])

    const preloadedImage = useRef<HTMLImageElement | null>(null)

    const [isBackgroundImageLoaded, setIsBackgroundImageLoaded] = useState(false)
    useEffect(() => {
        setIsBackgroundImageLoaded(false)

        const loadBackgroundImage = async () => {
            if (!isMounted.current) return

            if (backgroundImageUrl) {
                // Store reference to the preloaded image, so it doesn't get garbage collected.
                preloadedImage.current = await preloadImage(backgroundImageUrl)
                setIsBackgroundImageLoaded(true)
            }
        }

        const timeoutPromise = async () => {
            return new Promise<void>((resolve) => {
                setTimeout(() => {
                    resolve()
                }, COVER_IMAGE_LOAD_TIMEOUT)
            })
        }

        requestAnimationFrame(() => {
            // If the image takes too long to load, cancel the promise.
            Promise.race([loadBackgroundImage(), timeoutPromise()])
        })
    }, [backgroundImageUrl])

    const hasBackgroundImage = !!backgroundImageUrl && isBackgroundImageLoaded

    const showOverlay = coverType === 'fullWidth' && hasBackgroundImage

    const backgroundColor =
        HEADER_BACKGROUND_COLORS[coverBackgroundColor as keyof typeof HEADER_BACKGROUND_COLORS]

    return useMemo(
        () => ({
            coverType,
            backgroundColor,
            backgroundImageUrl,
            showOverlay,
            hasBackgroundImage,
        }),
        [coverType, backgroundColor, backgroundImageUrl, showOverlay, hasBackgroundImage]
    )
}

function extractSrcFromFieldValue(field: FieldDto, value: unknown) {
    // Extract the URL from the attachment value.
    if (field.type === 'multi_file') {
        if (Array.isArray(value)) {
            return value[0]?.url
        } else if (value && typeof value === 'object') {
            return (value as any).url
        }
    }

    return undefined
}
