import { useMemo } from 'react'

import { useRecordFiltersForRelatedRecords } from 'v2/blocks/useRecordFiltersForRelatedRecords'

import { useFields } from 'data/hooks/fields'
import { useObject } from 'data/hooks/objects'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'
import { ListViewWidgetType } from 'features/views/LayoutEditor/widgets/ListViewWidget/listViewWidgetTypes'
import { convertWidgetToView } from 'features/views/LayoutEditor/widgets/ListViewWidget/utils'
import { ViewConfig } from 'features/views/ListView/ListViewContext'
import { useRecordManagerContext } from 'features/views/RecordManager/useRecordManagerContext'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

type UseListViewWidgetStateProps = {
    widget: ListViewWidgetType
}

export function useListViewWidgetState({ widget }: UseListViewWidgetStateProps) {
    const { object: localObject } = useLayoutEditorContext()
    const { record } = useRecordManagerContext()

    // This can be another object, so we need to fetch the fields.
    const { data: fields = [] } = useFields({ objectId: widget.attrs.linkedField?.objectSid })

    const linkedFieldSid = widget.attrs.linkedField?.fieldSid
    const linkedField = fields.find((field) => field._sid === linkedFieldSid)

    const view = convertWidgetToView(widget)

    const isExternalField = widget.attrs.linkedField?.objectSid !== localObject?._sid

    const additionalFilters = useRecordFiltersForRelatedRecords({
        isExternalField,
        localRecord: record,
        field: linkedField,
    })

    const { object } = useObject(widget.attrs.objectSid)

    const title = widget.attrs.title || object?.name

    const viewConfig: ViewConfig = useDeepEqualsMemoValue({
        setConfig: () => {},
        setViewData: () => {},
        saveConfig: async () => {},
        isConfigDirty: false,
        viewState: {
            loadedView: view,
            view: view,
            isConfigDirty: false,
            shouldSave: false,
        },
        pageRoles: [],
        setPageRoles: () => {},
        rolesDirty: false,
        revertChanges: () => {},
    })

    const hasObjectAccess = !!object
    const hasLinkedFieldAccess = linkedFieldSid ? !!linkedField : true

    return useMemo(
        () => ({
            record,
            viewConfig,
            linkedFieldSid,
            additionalFilters,
            title,
            hasObjectAccess,
            hasLinkedFieldAccess,
        }),
        [
            record,
            viewConfig,
            linkedFieldSid,
            additionalFilters,
            title,
            hasObjectAccess,
            hasLinkedFieldAccess,
        ]
    )
}
