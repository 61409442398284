import React from 'react'

import { Widget, WidgetComponent } from 'features/views/LayoutEditor/types'

import { Box } from 'ui/components/Box'
import { Divider } from 'ui/components/Divider'

export type DividerWidgetType = Widget<{}>

type DividerWidgetProps = {}

export const DividerWidget: WidgetComponent<DividerWidgetType, DividerWidgetProps> = () => {
    return (
        <Box flex column py="l">
            <Divider type="default" variant="strong" />
        </Box>
    )
}
