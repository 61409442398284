import React from 'react'

import classNames from 'classnames'

import { Box, BoxProps } from 'ui/components/Box'

import { ConfigurationLabelStyle } from './WorkflowUI.css'

type ConfigurationLabelProps = BoxProps
export function ConfigurationLabel({ ...props }: ConfigurationLabelProps) {
    return (
        <Box
            color="textWeak"
            fontSize="bodyS"
            mb="xs"
            mt="l"
            {...props}
            className={classNames(ConfigurationLabelStyle, props.className)}
        />
    )
}
export function ConfigurationHint({
    error,
    ...props
}: ConfigurationLabelProps & { error?: boolean }) {
    return (
        <Box
            color={error ? 'error500' : 'textWeak'}
            fontSize="bodyXs"
            mb="xs"
            {...props}
            className={classNames(ConfigurationLabelStyle, props.className)}
        />
    )
}
