import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { DropdownItem } from 'ui/components/Dropdown'

import { useDetailViewHeaderActionButtonState } from './hooks/useDetailViewHeaderActionButtonState'

type DetailViewHeaderActionButtonProps = React.ComponentPropsWithoutRef<typeof Button> & {
    action: ActionDto
}

export const DetailViewHeaderActionButton: React.FC<DetailViewHeaderActionButtonProps> = ({
    action,
    ...props
}) => {
    const { handleClick } = useDetailViewHeaderActionButtonState({ action })

    return (
        <Button {...props} onClick={handleClick}>
            <Box
                trim
                maxWidth="full"
                style={{
                    paddingTop: '2px',
                    paddingBottom: '2px',
                    marginTop: '-2px',
                    marginBottom: '-2px',
                }}
            >
                {action.name}
            </Box>
        </Button>
    )
}

type DetailViewHeaderActionDropdownItemProps = React.ComponentPropsWithoutRef<
    typeof DropdownItem
> & {
    action: ActionDto
}

export const DetailViewHeaderActionDropdownItem: React.FC<
    DetailViewHeaderActionDropdownItemProps
> = ({ action, ...props }) => {
    const { handleClick } = useDetailViewHeaderActionButtonState({ action })

    return <DropdownItem label={action.name} {...props} onClick={() => handleClick()} />
}
