import assign from 'lodash/assign'

// Some fields are internal and should not be overwritten by the new record.
const internalFieldsToKeep = [
    '_local_display_order',
    '_comment_count',
    '_comment_counts',
    '_dereferenced_records',
    '_document_count',
    '_last_comment_at',
    '_task_completed_count',
    '_task_count',
]

/**
 * Merges the new and current record
 *
 * Only top-level properties, i.e. fields of the records, are merged. Their values are not recursively merged
 * @param {object} record
 * @param {object} currentRecord
 */

export const getMergedRecord = (record: RecordDto, currentRecord: RecordDto) => {
    const partial = !!record._partial && (!currentRecord || !!currentRecord?._partial)

    const overrideValues = internalFieldsToKeep.reduce(
        (acc, field) => {
            acc[field] = record[field] ?? currentRecord[field]
            return acc
        },
        {} as Record<string, any>
    )

    const updatedRecord = !!record._partial
        ? assign({}, currentRecord, record, {
              _partial: partial,
              ...overrideValues,
          })
        : {
              ...record,
              ...overrideValues,
          }

    return updatedRecord
}
