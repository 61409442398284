import React, { useCallback, useEffect, useRef } from 'react'

import { getFieldIcon } from 'features/admin/fields/icons/utils'
import { ListViewControlItem } from 'features/views/ListView/ListViewControlItem'

import { Box } from 'ui/components/Box'
import { Select, SelectOption } from 'ui/components/Select'

import { getSupportedContentFields } from './utils'

type ThreadViewMessageContentControlsProps = {
    objectSid: string
    setConfig: (config: Partial<ListViewOptions>) => void
    config: ListViewOptions
    fields: FieldDto[]
}

export const ThreadViewMessageContentControls: React.FC<ThreadViewMessageContentControlsProps> = ({
    objectSid,
    fields,
    config,
    setConfig,
}) => {
    return (
        <ListViewControlItem label="Message content" icon="MenuSquare">
            <ThreadViewMessageContentGenericControls
                maxWidth="full"
                minWidth="300px"
                objectSid={objectSid}
                fields={fields}
                config={config}
                setConfig={setConfig}
            />
        </ListViewControlItem>
    )
}

type ThreadViewMessageContentGenericControlsProps = React.ComponentPropsWithoutRef<typeof Box> &
    ThreadViewMessageContentControlsProps

export const ThreadViewMessageContentGenericControls: React.FC<
    ThreadViewMessageContentGenericControlsProps
> = ({ fields, config, setConfig, objectSid, ...props }) => {
    return (
        <Box flex flexDirection="column" gap="l" {...props}>
            <ContentControls
                config={config}
                setConfig={setConfig}
                fields={fields}
                objectSid={objectSid}
            />
        </Box>
    )
}

const ContentControls: React.FC<ThreadViewMessageContentControlsProps> = ({
    config,
    setConfig,
    fields,
}) => {
    const existingConfigRef = useRef(config)
    existingConfigRef.current = config

    const supportedFields = getSupportedContentFields(fields)
    const supportedFieldsRef = useRef(supportedFields)
    supportedFieldsRef.current = supportedFields

    const fieldSid = config.threadMessageContent?.fieldSid
    const field = supportedFields.find((field) => field._sid === fieldSid)

    const onChangeFieldSid = useCallback(
        (value?: string) => {
            const existingConfig = existingConfigRef.current

            const newValue: ListViewOptions['threadMessageContent'] = value
                ? {
                      ...existingConfig.threadMessageContent,
                      fieldSid: value,
                  }
                : undefined

            setConfig({
                threadMessageContent: newValue,
            })
        },
        [setConfig]
    )

    useEffect(() => {
        if (!fieldSid) {
            const defaultFieldSid = supportedFieldsRef.current[0]?._sid
            if (defaultFieldSid) {
                onChangeFieldSid(defaultFieldSid)
            }
        }
    }, [fieldSid, onChangeFieldSid])

    return (
        <Select
            label="Content"
            size="m"
            placeholder="Select field..."
            value={field?._sid ?? undefined}
            isSearchable
            onChange={onChangeFieldSid}
        >
            {supportedFields.map((field) => (
                <SelectOption
                    key={field._sid}
                    value={field._sid}
                    label={field.label}
                    startIcon={getFieldIcon(field)}
                />
            ))}
        </Select>
    )
}
