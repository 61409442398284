import React from 'react'

import { BANNER_HEIGHT } from 'features/core/appBannerConstants'
import { CollapsedNavigationTrigger } from 'features/navigation/CollapsedNavigationTrigger'
import { useNavigationContext } from 'features/navigation/hooks/useNavigationContext'

import { Box } from 'ui/components/Box'
import { Tooltip } from 'ui/components/Tooltip'

export const AgentPageHeader = ({
    title,
    tabSection,
    titleSection,
}: {
    title: string
    tabSection?: React.ReactChild
    titleSection?: React.ReactChild
}) => {
    const effectiveIsSticky = true
    const isPreviewing = false
    const ListHeaderDefaultStyle = ''
    const { showCollapsedNavigationTrigger } = useNavigationContext()
    const showMenuButton = showCollapsedNavigationTrigger
    const isEmbedded = false
    const titleHeight = 30

    return (
        // <Box flex width="full" alignItems="flex-start" gap="m" mb="l">
        //     <AgentIcon agent={agent} size="xl" />
        //     <Headline size="s">{agent.title}</Headline>
        // </Box>
        <Box
            as="header"
            position={effectiveIsSticky ? 'sticky' : 'relative'}
            top={0}
            width="full"
            style={{
                zIndex: effectiveIsSticky ? 199 : 0,
                top: isPreviewing ? `${BANNER_HEIGHT}px` : undefined,
            }}
            className={ListHeaderDefaultStyle}
        >
            <Box
                px={{ mobile: 0, tablet: '4xl' }}
                flex
                alignItems="flex-start"
                flexDirection="column"
                alignSelf="stretch"
                borderWidth={0}
                borderBottomWidth="base"
                borderStyle="base"
                borderColor="borderWeak"
                background="surface"
                width="full"
            >
                <Box
                    flex
                    gap={{ mobile: 0, tablet: '3xl' }}
                    py={{ mobile: 0, tablet: 'm' }}
                    alignItems="flex-start"
                    alignSelf="stretch"
                    flexDirection={{ mobile: 'column', tablet: 'row' }}
                >
                    <Box
                        flex
                        center
                        grow
                        shrink
                        minWidth={0}
                        alignSelf="stretch"
                        gap={{ mobile: 'xs', tablet: 'm' }}
                        borderWidth={0}
                        borderBottomWidth={{ mobile: 'base', tablet: 0 }}
                        borderStyle="base"
                        borderColor="borderWeak"
                        py={{ mobile: 'm', tablet: 0 }}
                        pl={{ mobile: isEmbedded ? 'xl' : 'm', tablet: 0 }}
                        pr={{ mobile: 'xl', tablet: 0 }}
                        overflow="hidden"
                    >
                        {showMenuButton && <CollapsedNavigationTrigger />}
                        <Box grow minWidth={0} flex column gap="xs">
                            <Box
                                flex
                                center
                                gap="m"
                                grow
                                minWidth={0}
                                style={{ height: titleHeight }}
                                justifyContent="space-between"
                            >
                                {title && (
                                    <Tooltip
                                        content={title}
                                        side="bottom"
                                        align="end"
                                        zIndex={200}
                                        asChild
                                    >
                                        <Box
                                            minWidth={0}
                                            shrink
                                            trim
                                            role="button"
                                            flexDirection="row"
                                            flex
                                            gap="s"
                                        >
                                            {titleSection}
                                        </Box>
                                    </Tooltip>
                                )}
                                <Box>{tabSection}</Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
