import { useCallback, useMemo, useRef } from 'react'

import { getAbsoluteUrl } from 'app/UrlService'
import { useFavorite } from 'data/hooks/favorites/favorites'
import { FavoriteType } from 'data/hooks/favorites/types'
import useLDFlags from 'data/hooks/useLDFlags'
import { useViews } from 'data/hooks/views'
import useEditMode from 'features/admin/edit-mode/useEditMode'
import { NavigationAppMenuItem } from 'features/navigation/types'
import { useCopyLink } from 'features/utils/useCopyLink'
import { useConfirmDeleteViewModal } from 'features/views/useConfirmDeleteViewModal'
import { useDuplicateViewModal } from 'features/views/useDuplicateViewModal'

import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useAppNavigationContext } from './useAppNavigationContext'
import { useNavigationContext } from './useNavigationContext'

type UseAppNavigationTreeMenuItemDropdownStateProps = {
    item: NavigationAppMenuItem
}

export function useAppNavigationTreeMenuItemDropdownState({
    item,
}: UseAppNavigationTreeMenuItemDropdownStateProps) {
    const itemRef = useRef(item)
    itemRef.current = item

    const { permissions } = useNavigationContext()
    const { executeActionInItem } = useAppNavigationContext()

    const absoluteUrl = getAbsoluteUrl(item.url)
    const executeCopy = useCopyLink()
    const copyLink = useCallback(async () => {
        return executeCopy(absoluteUrl)
    }, [absoluteUrl, executeCopy])

    const { flags } = useLDFlags()
    const canFavorite = flags.favorites && permissions.canViewInternalZone
    const { favorite, toggleFavorite: toggleFavoriteApp } = useFavorite({
        targetType: FavoriteType.NavigationItem,
        stackId: item.stackSid,
        navigationId: item.id,
    })
    const isFavorite = !!favorite
    const toggleFavorite = useCallback(() => {
        requestAnimationFrame(() => {
            toggleFavoriteApp()
        })
    }, [toggleFavoriteApp])

    const { open } = useEditMode()

    const { data: views } = useViews({ stackId: item.stackSid })
    const view = views?.find((v) => v.url === item.path)
    const viewRef = useRef(view)
    viewRef.current = view

    const isMobile = useResponsiveValue({
        mobile: true,
        tablet: false,
    })

    const canEditLayout = permissions.canEditViewLayout && view?.type !== 'document' && !isMobile
    const editLayout = useCallback(() => {
        const item = itemRef.current

        executeActionInItem(item, open)
    }, [executeActionInItem, open])

    const showDuplicateViewModal = useDuplicateViewModal()
    const canDuplicateLayout = permissions.canDuplicateLayout
    const duplicateLayout = useCallback(() => {
        const view = viewRef.current
        if (!view) return

        executeActionInItem(item, () => showDuplicateViewModal({ view }))
    }, [executeActionInItem, item, showDuplicateViewModal])

    const showConfirmDeleteModal = useConfirmDeleteViewModal()

    const canDeleteLayout = useMemo(() => {
        if (!views || !view || !permissions.canDeleteLayout) return false

        // If this is the only list view for the object, prevent the user from deleting it
        return views.find(
            (v) => v.object_id === view.object_id && v._sid !== view._sid && v.type == 'list'
        )
    }, [views, permissions, view])

    const deleteLayout = useCallback(() => {
        const view = viewRef.current
        if (!view) return

        executeActionInItem(item, () => showConfirmDeleteModal({ view, skipRedirect: true }))
    }, [executeActionInItem, item, showConfirmDeleteModal])

    const deleteLabel = getDeleteLabel(view?.type)

    return useMemo(
        () => ({
            copyLink,
            canFavorite,
            isFavorite,
            toggleFavorite,
            canEditLayout,
            editLayout,
            canDuplicateLayout,
            duplicateLayout,
            canDeleteLayout,
            deleteLayout,
            deleteLabel,
        }),
        [
            copyLink,
            canFavorite,
            isFavorite,
            toggleFavorite,
            canEditLayout,
            editLayout,
            canDuplicateLayout,
            duplicateLayout,
            canDeleteLayout,
            deleteLayout,
            deleteLabel,
        ]
    )
}

function getDeleteLabel(viewType?: ViewDto['type']) {
    switch (viewType) {
        case 'list':
            return 'Delete List view'
        case 'blankpage':
            return 'Delete Custom page'
        case 'document':
            return 'Delete Document'
        default:
            return 'Delete view'
    }
}
