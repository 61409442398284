import { useEffect, useMemo, useState } from 'react'

import { getFieldIcon } from 'features/admin/fields/icons/utils'
import { formatDate } from 'utils/date/dateUtils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

const RELATIVE_DATE_UPDATE_INTERVAL = 1000 * 10 // 10 seconds

type UseDateAttributeDisplayStateProps = {
    field: FieldDto
    value?: string
    isLoading?: boolean
    showIcon?: boolean
    showAsRelativeTime?: boolean
}

export function useDateAttributeDisplayState({
    value,
    field,
    isLoading,
    showIcon,
    showAsRelativeTime,
}: UseDateAttributeDisplayStateProps) {
    const showTime = field.type === 'datetime'
    const timezone = field.options?.timezone

    const [relativeDate, setRelativeDate] = useState('')
    useEffect(() => {
        // If the date is being shown as relative time, update the relative date every minute.
        if (showAsRelativeTime) {
            const getRelativeDate = () => {
                setRelativeDate(formatDateValue({ value, showTime, timezone, showAsRelativeTime }))
            }
            getRelativeDate()

            const interval = setInterval(getRelativeDate, RELATIVE_DATE_UPDATE_INTERVAL)

            return () => clearInterval(interval)
        }
    }, [value, isLoading, showTime, timezone, showAsRelativeTime])

    const absoluteDate = formatDateValue({
        value: isLoading ? PLACEHOLDER_VALUE : value,
        showTime,
        timezone,
    })

    const fieldIcon = getFieldIcon(field)
    const icon = useDeepEqualsMemoValue(
        showIcon && fieldIcon?.type === 'lucide' ? fieldIcon : undefined
    )

    return useMemo(
        () => ({
            showTime,
            relativeDate,
            absoluteDate,
            icon,
        }),
        [showTime, relativeDate, absoluteDate, icon]
    )
}

function formatDateValue({
    showTime,
    timezone,
    value,
    showAsRelativeTime,
}: {
    showTime: boolean
    timezone: string
    value?: string
    showAsRelativeTime?: boolean
}): string {
    if (!value) return ''

    const { dateValue } = formatDate(value, timezone, showTime)
    if (!dateValue) return ''

    if (showAsRelativeTime) {
        return dateValue.fromNow()
    }

    const formattedValue = dateValue.format(showTime ? 'MMM D, YYYY LT' : 'MMM D, YYYY')

    return formattedValue
}

const PLACEHOLDER_VALUE = '2020-01-01T00:00:00Z'
