import React from 'react'

import { FieldIcon } from 'features/admin/fields/icons/FieldIcon'
import { ThreadViewAttributeValueStyles } from 'features/views/ListView/ThreadView/ThreadView.css'
import { ThreadViewAttribute } from 'features/views/ListView/ThreadView/types'

import { Box } from 'ui/components/Box'
import { Skeleton } from 'ui/components/Skeleton'
import { Body } from 'ui/components/Text'

import { useThreadViewBaseAttributeState } from './hooks/useThreadViewBaseAttributeState'

const LABEL_MAX_LENGTH = 40
const LABEL_MAX_LINES = 1

type BaseAttributeProps = React.ComponentPropsWithoutRef<typeof Box> & {
    attribute: ThreadViewAttribute
    isEmpty?: boolean
    isLoading?: boolean
}

export const BaseAttribute: React.FC<BaseAttributeProps> = React.memo(function BaseAttribute({
    children,
    isEmpty,
    attribute,
    isLoading,
    ...props
}) {
    const { showLabel, showIcon, emptyPlaceholder } = useThreadViewBaseAttributeState({
        attribute,
    })

    const { type } = attribute

    return (
        <Box minWidth={0} {...props}>
            {showLabel && (
                <Box>
                    <Skeleton isLoading={isLoading}>
                        <Body
                            maxLength={LABEL_MAX_LENGTH}
                            maxLines={LABEL_MAX_LINES}
                            trim
                            size="s"
                            weight="medium"
                            color="gray500"
                        >
                            {attribute.title}
                        </Body>
                    </Skeleton>
                </Box>
            )}
            <Box className={ThreadViewAttributeValueStyles.styleFunction({ type, showIcon })}>
                {showIcon && <FieldIcon value={attribute.icon!} size="s" color="icon" noShrink />}
                {isEmpty ? (
                    <Body
                        color="textWeakest"
                        style={{
                            fontSize: 'inherit',
                            lineHeight: 'inherit',
                            letterSpacing: 'inherit',
                            fontWeight: 'inherit',
                            maxWidth: '100%',
                        }}
                        trim
                        display="block"
                    >
                        {emptyPlaceholder}
                    </Body>
                ) : (
                    children
                )}
            </Box>
        </Box>
    )
})
