import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { getFieldIcon } from 'features/admin/fields/icons/utils'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'
import { FieldsWidgetType } from 'features/views/LayoutEditor/widgets/FieldsWidget/fieldWidgetTypes'
import { useRecordManagerContext } from 'features/views/RecordManager/useRecordManagerContext'

import { AttributeContext, AttributeContextValue } from './hooks/useAttributeContext'
import { FieldsWidgetAttributeField } from './types'

const LOADING_SLOW_THRESHOLD_TIMEOUT = 2000

export type AttributeContextProviderProps = {
    editingFields?: string[]
    toggleEditingField: (fieldApiName: string, enabled: boolean) => void
    labelPlacement: FieldsWidgetType['attrs']['labelPlacement']
    showFieldIcon: FieldsWidgetType['attrs']['showFieldIcon']
    isEditingLayout?: boolean
    field: FieldsWidgetAttributeField
    isLoading?: boolean
}

export const AttributeContextProvider: React.FC<AttributeContextProviderProps> = ({
    children,
    editingFields = [],
    toggleEditingField,
    labelPlacement,
    showFieldIcon,
    isEditingLayout = false,
    field,
    isLoading = false,
}) => {
    const isEditingValue = editingFields.includes(field.apiName)

    const {
        record,
        mutateAttribute,
        replaceAttribute,
        clearAttribute,
        saveChanges,
        discardChanges: discardRecordChanges,
    } = useRecordManagerContext()
    const attrValue = record?.[field.apiName] ?? undefined

    const { fields } = useLayoutEditorContext()
    const fieldsRef = useRef(fields)
    fieldsRef.current = fields

    const fieldRef = useRef(field)
    fieldRef.current = field

    const toggleEditingValueMode = useCallback(
        (enabled: boolean) => {
            toggleEditingField(field.apiName, enabled)
        },
        [field.apiName, toggleEditingField]
    )

    const icon = showFieldIcon ? field.icon || getFieldIcon(field.field) : undefined
    const label = field.label || field.field.label

    const [isSaving, setIsSaving] = useState(false)
    const saveValue = useCallback(async () => {
        setIsSaving(true)
        await saveChanges()

        toggleEditingValueMode(false)
        setIsSaving(false)
    }, [saveChanges, toggleEditingValueMode])

    const [isSavingSlow, setIsSavingSlow] = useState(false)
    useEffect(() => {
        let timer: number

        if (isSaving) {
            timer = window.setTimeout(() => {
                setIsSavingSlow(true)
            }, LOADING_SLOW_THRESHOLD_TIMEOUT)
        } else {
            setIsSavingSlow(false)
        }

        return () => {
            window.clearTimeout(timer)
        }
    }, [isSaving])

    const isRequired = field.isRequired ?? false

    const clearValue = useCallback(async () => {
        clearAttribute(field.apiName)

        return saveValue()
    }, [clearAttribute, field.apiName, saveValue])

    const discardChanges = useCallback(() => {
        discardRecordChanges()
        toggleEditingValueMode(false)
    }, [discardRecordChanges, toggleEditingValueMode])

    const isEditable = field.isEditable && !isLoading
    const isEditableRef = useRef(field.isEditable && !isLoading)
    isEditableRef.current = field.isEditable && !isLoading
    const requestEditValueMode = useCallback(() => {
        if (!isEditableRef.current) return

        toggleEditingValueMode(true)
    }, [toggleEditingValueMode])

    const mutateValue: AttributeContextValue<any>['mutateValue'] = useCallback(
        (tr) => {
            const currentField = fieldRef.current
            const fields = fieldsRef.current

            const field = fields.find((f) => f.api_name === currentField.apiName)
            if (!field) return

            mutateAttribute(currentField.apiName, tr)
            if (field.is_primary) {
                mutateAttribute('_primary', tr)
            }
        },
        [mutateAttribute]
    )

    const replaceValue: AttributeContextValue<any>['replaceValue'] = useCallback(
        (value) => {
            const currentField = fieldRef.current
            const fields = fieldsRef.current

            const field = fields.find((f) => f.api_name === currentField.apiName)
            if (!field) return

            replaceAttribute(currentField.apiName, value)
            if (field.is_primary) {
                replaceAttribute('_primary', value)
            }
        },
        [replaceAttribute]
    )

    const value = useMemo(
        () => ({
            isEditable,
            labelPlacement,
            showFieldIcon,
            isEditingLayout,
            isEditingValue,
            value: attrValue,
            toggleEditingValueMode,
            field,
            icon,
            label,
            mutateValue,
            replaceValue,
            saveValue,
            isSavingSlow,
            clearValue,
            discardChanges,
            requestEditValueMode,
            isRequired,
        }),
        [
            isEditable,
            labelPlacement,
            showFieldIcon,
            isEditingLayout,
            isEditingValue,
            attrValue,
            toggleEditingValueMode,
            field,
            icon,
            label,
            mutateValue,
            replaceValue,
            saveValue,
            isSavingSlow,
            clearValue,
            discardChanges,
            requestEditValueMode,
            isRequired,
        ]
    )

    return <AttributeContext.Provider value={value}>{children}</AttributeContext.Provider>
}
