import React from 'react'

import { useTheme } from '@chakra-ui/react'

import { useExtDataIntegrations } from 'data/hooks/dataConnections/useExtDataIntegrations'
import {
    INTEGRATION_ID_TO_DC_TYPE,
    INTEGRATION_ID_TO_NAME,
} from 'features/DataConnections/constants'
import { getIconForDataConnectionType } from 'features/DataConnections/getIconForDataConnectionType'

import { Box } from 'ui/components/Box'
import { DropdownFooterItem } from 'ui/components/Dropdown/DropdownFooterItem'
import { Select, SelectOptionComplex } from 'ui/components/Select'

type ExternalAccountSelectProps = React.ComponentPropsWithoutRef<typeof Select> & {
    externalIntegrationId: ExternalIntegrationId
    onAddExternalAccount: () => void
    isLoading?: boolean
}
export const ExternalAccountSelect: React.FC<ExternalAccountSelectProps> = ({
    externalIntegrationId,
    value,
    onChange,
    onAddExternalAccount,
    isLoading,
    contentProps,
    ...props
}) => {
    const theme = useTheme()

    const { data: integrationsData, isLoading: isLoadingExtDataIntegration } =
        useExtDataIntegrations()
    const extConnections = integrationsData?.connections.filter(
        (connection) => connection.provider_id === externalIntegrationId
    )

    const icon = getIconForDataConnectionType(INTEGRATION_ID_TO_DC_TYPE[externalIntegrationId])

    const IntegrationIcon = () => (
        <Box
            style={{
                backgroundColor: theme.colors.grey[100],
                padding: '8px',
                borderRadius: '6px',
            }}
        >
            <img height="16px" width="16px" alt={`${externalIntegrationId} icon`} src={icon} />
        </Box>
    )
    return (
        <Select
            placeholder="Select or connect account"
            onChange={onChange}
            value={value}
            {...props}
            isLoading={isLoadingExtDataIntegration || isLoading}
            contentProps={{
                ...contentProps,
                footer: (
                    <DropdownFooterItem
                        label={`Connect ${INTEGRATION_ID_TO_NAME[externalIntegrationId]} account`}
                        startIcon={{ name: 'Plus' }}
                        onClick={onAddExternalAccount}
                        variant="action"
                    />
                ),
            }}
        >
            {extConnections?.map((extConnection) => (
                <SelectOptionComplex
                    key={extConnection.id}
                    label={INTEGRATION_ID_TO_NAME[externalIntegrationId]}
                    leftSlotContent={() => <IntegrationIcon />}
                    value={extConnection.id}
                    subtitle={extConnection.external_user_email}
                />
            ))}
        </Select>
    )
}
