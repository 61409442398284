import React from 'react'
import Truncate from 'react-truncate-markup'

import { useRecordLinksAttributeDisplayState } from 'features/views/attributes/hooks/useRecordLinksAttributeDisplayState'
import { Users } from 'features/views/attributes/Users'

import { Box } from 'ui/components/Box'
import {
    Dropdown,
    DropdownContent,
    DropdownItemLink,
    DropdownTrigger,
} from 'ui/components/Dropdown'
import { Link } from 'ui/components/Link'
import { Skeleton } from 'ui/components/Skeleton'
import { Body } from 'ui/components/Text'
import { stopPropagation } from 'ui/helpers/utilities'

import { useRecordLinksAttributeState } from './hooks/useRecordLinksAttributeState'
import { BaseAttribute } from './BaseAttribute'
import { PrimaryHighlightsAttributeComponent } from './types'

type RecordLinksAttributeProps = {}

export const RecordLinksAttribute: PrimaryHighlightsAttributeComponent<
    RecordLinksAttributeProps
> = ({ field, isLoading, ...props }) => {
    return <RecordLinksAttributeValue field={field} isLoading={isLoading} {...props} />
}

const RecordLinksAttributeValue: PrimaryHighlightsAttributeComponent<RecordLinksAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const {
        records,
        isUsersObject,
        users,
        isOverflowPopupOpen,
        onOverflowLabelCloseAutoFocus,
        onOverflowLabelMouseEnter,
        onOverflowLabelMouseLeave,
        onOverflowPopupOpenChange,
        onLinkClick,
        attributeRef,
        isEmpty,
        visibleLinksLength,
        onTruncate,
    } = useRecordLinksAttributeState({
        field,
        isLoading,
    })

    if (isUsersObject) {
        return (
            <BaseAttribute {...props} field={field} isEmpty={isEmpty} isLoading={isLoading}>
                <Users
                    value={users}
                    isLoading={isLoading}
                    avatarSize="s"
                    size={{
                        mobile: 'm',
                        tablet: 'l',
                    }}
                    onClick={onLinkClick}
                    showSingleUserName={true}
                    style={{
                        color: 'inherit',
                    }}
                />
            </BaseAttribute>
        )
    }

    return (
        <BaseAttribute
            ref={attributeRef}
            {...props}
            field={field}
            isEmpty={isEmpty}
            isLoading={isLoading}
        >
            <Truncate
                lines={1}
                onTruncate={onTruncate}
                ellipsis={
                    <OverflowLinks
                        records={records}
                        visibleLinksLength={visibleLinksLength}
                        isOverflowPopupOpen={isOverflowPopupOpen}
                        onOverflowLabelCloseAutoFocus={onOverflowLabelCloseAutoFocus}
                        onOverflowLabelMouseEnter={onOverflowLabelMouseEnter}
                        onOverflowLabelMouseLeave={onOverflowLabelMouseLeave}
                        onOverflowPopupOpenChange={onOverflowPopupOpenChange}
                        onLinkClick={onLinkClick}
                    />
                }
            >
                <Box
                    whiteSpace="normal"
                    style={{
                        wordBreak: 'break-word',
                        fontWeight: 'inherit',
                        fontSize: 'inherit',
                        lineHeight: 'inherit',
                    }}
                >
                    <Skeleton isLoading={isLoading}>
                        {records.map((record, idx) => (
                            <Truncate.Atom key={record.url}>
                                {idx > 0 && ', '}
                                <Link
                                    to={record.url}
                                    onClick={onLinkClick}
                                    style={{
                                        fontSize: 'inherit',
                                        lineHeight: 'inherit',
                                    }}
                                >
                                    {record.name}
                                </Link>
                            </Truncate.Atom>
                        ))}
                    </Skeleton>
                </Box>
            </Truncate>
        </BaseAttribute>
    )
}

type OverflowLinksProps = Pick<
    ReturnType<typeof useRecordLinksAttributeDisplayState>,
    | 'records'
    | 'isOverflowPopupOpen'
    | 'onOverflowLabelCloseAutoFocus'
    | 'onOverflowLabelMouseEnter'
    | 'onOverflowLabelMouseLeave'
    | 'onOverflowPopupOpenChange'
> & {
    visibleLinksLength: number
    onLinkClick: (e: React.MouseEvent<HTMLElement>) => void
}

const OverflowLinks: React.FC<OverflowLinksProps> = ({
    records,
    visibleLinksLength,
    isOverflowPopupOpen,
    onOverflowLabelCloseAutoFocus,
    onOverflowLabelMouseEnter,
    onOverflowLabelMouseLeave,
    onOverflowPopupOpenChange,
    onLinkClick,
}) => {
    const overflowingRecordsLen = records.length - visibleLinksLength
    const overflowingRecords = records.slice(visibleLinksLength, records.length)

    if (overflowingRecordsLen > 0) {
        return (
            <Dropdown
                open={isOverflowPopupOpen}
                onOpenChange={onOverflowPopupOpenChange}
                modal={false}
            >
                <DropdownTrigger
                    onClick={stopPropagation}
                    onMouseEnter={onOverflowLabelMouseEnter}
                    onMouseLeave={onOverflowLabelMouseLeave}
                    style={{
                        fontSize: 'inherit',
                        fontWeight: 'inherit',
                        lineHeight: 'inherit',
                    }}
                >
                    <Body
                        ml="s"
                        color="linkText"
                        style={{
                            fontSize: 'inherit',
                            fontWeight: 'inherit',
                            lineHeight: 'inherit',
                        }}
                    >
                        +{overflowingRecordsLen}
                    </Body>
                </DropdownTrigger>
                <DropdownContent
                    sideOffset={0}
                    side="bottom"
                    align="end"
                    onCloseAutoFocus={onOverflowLabelCloseAutoFocus}
                    onMouseEnter={onOverflowLabelMouseEnter}
                    onMouseLeave={onOverflowLabelMouseLeave}
                >
                    {overflowingRecords.map((record) => (
                        <DropdownItemLink
                            key={record.url}
                            to={record.url}
                            label={record.name}
                            onClick={onLinkClick}
                        />
                    ))}
                </DropdownContent>
            </Dropdown>
        )
    }

    return <>...</>
}
