import React, { useCallback, useMemo } from 'react'

import {
    deleteRecords as originalDeleteRecords,
    updateRecord as originalUpdateRecord,
} from 'data/hooks/records/recordOperations'
import { RecordEditManagerContextProvider } from 'features/records/RecordEditManagerContextProvider'
import { useRecordEditManagerContext } from 'features/records/useRecordEditManagerContext'
import { useListViewRecords } from 'features/views/ListView/hooks/useListViewRecords'
import { useListViewContext } from 'features/views/ListView/useListViewContext'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useThreadViewFilters } from './Filters/hooks/useThreadViewFilters'
import { useAttributesFromView } from './hooks/useAttributesFromView'
import { ThreadViewContext, ThreadViewInternalContextValue } from './hooks/useThreadViewContext'
import { useThreadViewPagination } from './Pagination/hooks/useThreadViewPagination'
import { useThreadViewSearch } from './Search/hooks/useThreadViewSearch'
import { useThreadViewSort } from './Sort/hooks/useThreadViewSort'

export type ThreadViewContextProviderProps = {}

export const ThreadViewContextProvider: React.FC<ThreadViewContextProviderProps> = ({
    children,
}) => {
    const { object, stack, isEmbedded, allFields, view, header } = useListViewContext()

    const onRecordClick = view.options.onRecordClick || 'preview'
    const disableRealtimeUpdates = view.options.disableRealtimeUpdates

    const profileImageField = allFields.find(
        (f) => f.api_name === view.options.profileImage?.fieldApiName
    )
    const titleField = allFields.find((f) => f._sid === view.options.threadMessageTitle?.fieldSid)
    const eyebrowField = allFields.find(
        (f) => f._sid === view.options.threadMessageEyebrow?.fieldSid
    )
    const contentField = allFields.find(
        (f) => f._sid === view.options.threadMessageContent?.fieldSid
    )
    const senderField = allFields.find((f) => f._sid === view.options.threadSender?.fieldSid)

    const footerLeftField = allFields.find(
        (f) => f._sid === view.options.threadMessageFooter?.leftFieldSid
    )
    const footerRightField = allFields.find(
        (f) => f._sid === view.options.threadMessageFooter?.rightFieldSid
    )

    const additionalSearchFieldApiNames = useMemo(() => {
        const apiNames = new Set<string>()

        if (!!eyebrowField?.api_name) {
            apiNames.add(eyebrowField.api_name)
        }

        if (!!titleField?.api_name) {
            apiNames.add(titleField.api_name)
        }

        if (!!contentField?.api_name) {
            apiNames.add(contentField.api_name)
        }

        if (!!senderField?.api_name) {
            apiNames.add(senderField.api_name)
        }

        if (!!footerLeftField?.api_name) {
            apiNames.add(footerLeftField.api_name)
        }

        if (!!footerRightField?.api_name) {
            apiNames.add(footerRightField.api_name)
        }

        return Array.from(apiNames)
    }, [
        contentField?.api_name,
        eyebrowField?.api_name,
        footerLeftField?.api_name,
        footerRightField?.api_name,
        senderField?.api_name,
        titleField?.api_name,
    ])

    const additionalFieldApiNames = useMemo(() => {
        const apiNames = new Set<string>(additionalSearchFieldApiNames)

        if (!!profileImageField?.api_name) {
            apiNames.add(profileImageField.api_name)
        }

        return Array.from(apiNames)
    }, [additionalSearchFieldApiNames, profileImageField?.api_name])

    const {
        records,
        dereferencedRecords,
        recordCount = 0,
        retryFetchRecords,
        hasError,
        isLoading,
        isLoadingSlow: isFetchingSlow,
        effectiveFilters,
        includedFieldsApiNames,
    } = useListViewRecords({
        additionalFieldApiNames,
        additionalSearchFieldApiNames,
        disableRealtimeUpdates,
    })

    const { pageIndex, pageSize, setPageIndex } = useThreadViewPagination({
        recordCount,
        isLoading,
    })

    const { sortBy, setSortBy, defaultSortBy } = useThreadViewSort()
    const { query, setQuery } = useThreadViewSearch()
    const {
        hasFilters: hasInlineFilters,
        clearFilters: clearInlineFilters,
        inlineFilterType,
        availableInlineFilterFields,
    } = useThreadViewFilters()

    const hasFilters = !!query || hasInlineFilters
    const requestFilters = useDeepEqualsMemoValue(effectiveFilters)
    const requestIncludedFields = useDeepEqualsMemoValue(includedFieldsApiNames)

    const clearFilters = useCallback(() => {
        setQuery('')
        clearInlineFilters()
    }, [setQuery, clearInlineFilters])

    // empty addRecord callback as thread views don't need this
    const addRecord = useCallback(async (record: RecordDto) => record, [])

    const updateRecord = useCallback(
        async (recordId: string, patch: Partial<RecordDto>) => {
            await originalUpdateRecord(recordId, patch, requestIncludedFields, {
                deferStoreUpdate: true,
            })
        },
        [requestIncludedFields]
    )

    const deleteRecords = useCallback(
        async (recordIds: string[]) => {
            await originalDeleteRecords(object._sid, recordIds)
            await retryFetchRecords()
        },
        [object._sid, retryFetchRecords]
    )

    const viewMemo = useDeepEqualsMemoValue(view)

    const titleSize = view.options.threadMessageTitleSize ?? 'small'

    const labelStyle = view.options.threadFieldLabelStyle

    const fieldDirection = view.options.threadFieldDirection ?? 'horizontal'

    const messageSize = view.options.threadMessageSize ?? 'medium'

    const attributes = useAttributesFromView({ titleFieldSid: titleField?._sid })

    const value = useMemo(
        () => ({
            records,
            dereferencedRecords,
            recordCount,
            object,
            stack,
            currentPageIndex: pageIndex,
            pageSize,
            setCurrentPageIndex: setPageIndex,
            sortBy,
            setSortBy,
            defaultSortBy,
            isLoading,
            isFetchingSlow,
            hasFilters,
            clearFilters,
            requestFilters,
            requestIncludedFields,
            retryFetchRecords,
            hasError,
            isEmbedded,
            onRecordClick,
            profileImageField,
            titleField,
            eyebrowField,
            contentField,
            senderField,
            footerLeftField,
            footerRightField,
            view: viewMemo,
            header,
            titleSize,
            labelStyle,
            messageSize,
            inlineFilterType,
            availableInlineFilterFields,
            allFields,
            attributes,
            fieldDirection,
        }),
        [
            records,
            dereferencedRecords,
            recordCount,
            object,
            stack,
            pageIndex,
            pageSize,
            setPageIndex,
            sortBy,
            setSortBy,
            defaultSortBy,
            isLoading,
            isFetchingSlow,
            hasFilters,
            clearFilters,
            requestFilters,
            requestIncludedFields,
            retryFetchRecords,
            hasError,
            isEmbedded,
            onRecordClick,
            profileImageField,
            titleField,
            eyebrowField,
            contentField,
            senderField,
            footerLeftField,
            footerRightField,
            viewMemo,
            header,
            titleSize,
            labelStyle,
            messageSize,
            inlineFilterType,
            availableInlineFilterFields,
            allFields,
            attributes,
            fieldDirection,
        ]
    )

    return (
        <RecordEditManagerContextProvider
            records={records ?? []}
            addRecord={addRecord}
            updateRecord={updateRecord}
            deleteRecords={deleteRecords}
        >
            <ThreadViewInternalContext value={value}>{children}</ThreadViewInternalContext>
        </RecordEditManagerContextProvider>
    )
}

type ThreadViewInternalContextProps = {
    value: ThreadViewInternalContextValue
    children: React.ReactNode
}

const ThreadViewInternalContext: React.FC<ThreadViewInternalContextProps> = ({
    value,
    children,
}) => {
    const { records, pendingNewRecords } = useRecordEditManagerContext()

    let isLoading = value.isLoading
    // On the initial record load, it may take a few frames
    // until the record manager picks up the first batch of records.
    if (records.length < 1 && !!value.records?.length) {
        isLoading = true
    }

    const pendingRecords = pendingNewRecords.map((record) => record.record)

    const finalValue = {
        ...value,
        isLoading,
        pendingRecords,
    }

    return <ThreadViewContext.Provider value={finalValue}>{children}</ThreadViewContext.Provider>
}
