import { useLayoutEffect, useMemo, useRef, useState } from 'react'

import { DetailViewSpaciousTopBarStickyStyle } from 'features/views/DetailView/headers/spacious/DetailViewSpaciousHeader.css'
import { useDetailViewContext } from 'features/views/DetailView/hooks/useDetailViewContext'
import { DetailViewHeaderSpacious } from 'features/views/DetailView/types'

export function useDetailViewSpaciousHeaderTopBarState() {
    const { header, isEmbedded, topOffset } = useDetailViewContext()!

    const coverType = (header as DetailViewHeaderSpacious).coverType ?? 'none'
    const coverBackgroundColor =
        (header as DetailViewHeaderSpacious).coverBackgroundColor ?? 'NeutralLight'
    const coverImage = (header as DetailViewHeaderSpacious).coverImage

    const [isSticky, setIsSticky] = useState(false)

    // Add a classname to the top bar when it's sticky.
    const headerRef = useRef<HTMLElement>(null)
    useLayoutEffect(() => {
        let scrollParent: HTMLElement | null
        if (isEmbedded) {
            scrollParent = document.querySelector('#chakra-modal--body-preview-record-modal')
        } else {
            scrollParent = document.querySelector('#contentWrapper')
        }
        if (!scrollParent) return

        const scrollHandler = () => {
            const headerEl = headerRef.current
            if (!headerEl) return

            if (scrollParent.scrollTop > 0) {
                if (!headerEl.classList.contains(DetailViewSpaciousTopBarStickyStyle)) {
                    headerEl.classList.add(DetailViewSpaciousTopBarStickyStyle)
                    setIsSticky(true)
                }
            } else {
                headerEl.classList.remove(DetailViewSpaciousTopBarStickyStyle)
                setIsSticky(false)
            }
        }
        scrollHandler()

        scrollParent.addEventListener('scroll', scrollHandler)
        return () => {
            scrollParent.removeEventListener('scroll', scrollHandler)
        }
    }, [isEmbedded])

    let shade: 'light' | 'dark' = 'light'
    if (coverType === 'fullWidth' && !isSticky) {
        if (!!coverImage || coverBackgroundColor.endsWith('Dark')) {
            shade = 'dark'
        }
    }

    return useMemo(
        () => ({
            headerRef,
            coverType,
            topOffset,
            shade,
        }),
        [coverType, topOffset, shade]
    )
}
