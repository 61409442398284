import React from 'react'

import { OutputSchemaEditor } from 'features/workflows/OutputSchema/OutputSchemaEditor'
import { ConfigurationLabel } from 'features/workflows/WorkflowUI'

import { WorkflowActionEditorComponent } from './types'

export const ReturnDataActionConfig: WorkflowActionEditorComponent = ({ action, onChange }) => {
    const returnDataSchema = action.settings?.return_data as WorkflowOutputSchema | undefined

    const handleChangeSettings = (patch: Record<string, unknown>) => {
        onChange({
            settings: {
                ...action.settings,
                ...patch,
            },
        })
    }

    return (
        <>
            <ConfigurationLabel>Return data</ConfigurationLabel>
            <OutputSchemaEditor
                value={returnDataSchema}
                onChange={(value) => handleChangeSettings({ return_data: value })}
                mb="l"
            />
        </>
    )
}
