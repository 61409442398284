import React from 'react'

import { DropdownAttributeDisplay } from 'features/views/attributes/DropdownAttributeDisplay'
import { DropdownAttributeTag } from 'features/views/attributes/DropdownAttributeTag'

import { useDropdownAttributeState } from './hooks/useDropdownAttributeState'
import { BaseAttribute } from './BaseAttribute'
import { BoardViewAttributeComponent } from './types'

const MAX_OPTIONS = 2
const MAX_OPTIONS_SINGLE_LINE = 1
const MAX_OPTIONS_TAG = 3
const MAX_ITEM_LENGTH = 10
const MAX_ITEM_LENGTH_SINGLE = 30
const MAX_LINES = 1

type DropdownAttributeProps = {}

export const DropdownAttribute: BoardViewAttributeComponent<
    string | string[],
    DropdownAttributeProps
> = ({ value, attribute, isLoading, displayStyle, showIcon, ...props }) => {
    const { field, type } = attribute
    const isTitle = type === 'title'
    const isFooter = type === 'footer'
    const isSubtitle = type === 'subtitle'

    const isEmpty = !isLoading && (!value || (Array.isArray(value) && value.length < 1))

    const isSingle = !Array.isArray(value) || value.length === 1

    const { isTagListAttributeStyle } = useDropdownAttributeState()

    const isDisplayedAsTag = displayStyle === 'tags' || isFooter

    return (
        <BaseAttribute
            attribute={attribute}
            isEmpty={isEmpty}
            lineHeight={isDisplayedAsTag ? '1' : undefined}
            {...props}
        >
            {isDisplayedAsTag ? (
                <DropdownAttributeTag
                    size={isTagListAttributeStyle ? 's' : 'm'}
                    value={value}
                    field={field}
                    isLoading={isLoading}
                    maxItemLength={isSingle ? MAX_ITEM_LENGTH_SINGLE : MAX_ITEM_LENGTH}
                    maxOptions={MAX_OPTIONS_TAG}
                    minWidth={isFooter ? 0 : undefined}
                    showIcon={showIcon}
                />
            ) : (
                <DropdownAttributeDisplay
                    size={{
                        mobile: isTitle ? 'm' : 's',
                        tablet: 'm',
                    }}
                    value={value}
                    field={field}
                    maxOptions={isTitle || isSubtitle ? MAX_OPTIONS_SINGLE_LINE : MAX_OPTIONS}
                    maxItemLength={isTitle || isSubtitle ? MAX_ITEM_LENGTH : undefined}
                    maxLines={MAX_LINES}
                    isLoading={isLoading}
                />
            )}
        </BaseAttribute>
    )
}
