import { useMemo } from 'react'

import { useThreadViewContext } from 'features/views/ListView/ThreadView/hooks/useThreadViewContext'

export function useThreadViewListHeaderState() {
    const { records, requestFilters, requestIncludedFields } = useThreadViewContext()

    return useMemo(
        () => ({
            recordCount: records?.length,
            requestFilters,
            requestIncludedFields,
        }),
        [records?.length, requestFilters, requestIncludedFields]
    )
}
