import { IconName } from 'ui/components/Icon/Icon'
import { ColorSchemeLowerCase } from 'ui/styling/baseVariables/colors/colorPalette'

export type ToolDisplayConfig = {
    icon: IconName
    color: ColorSchemeLowerCase
    sortOrder: number
}

export const TOOL_DISPLAY_CONFIG: Record<string, ToolDisplayConfig> = {
    update_record: {
        icon: 'Grid2x2',
        color: 'blue',
        sortOrder: 1,
    },
    create_record: {
        icon: 'Grid2x2',
        color: 'blue',
        sortOrder: 2,
    },
    query_records: {
        icon: 'Grid2x2',
        color: 'blue',
        sortOrder: 3,
    },
    bulk_upsert_records: {
        icon: 'Grid2x2',
        color: 'blue',
        sortOrder: 4,
    },
    run_workflow: {
        icon: 'Workflow',
        color: 'purple',
        sortOrder: 5,
    },
    make_api_request: {
        icon: 'Globe',
        color: 'purple',
        sortOrder: 6,
    },
    extract_text_from_document: {
        icon: 'Paperclip',
        color: 'orange',
        sortOrder: 7,
    },
    query_pdf_document_from_url: {
        icon: 'Paperclip',
        color: 'orange',
        sortOrder: 8,
    },
    send_email: {
        icon: 'Mail',
        color: 'emerald',
        sortOrder: 9,
    },
    data_analysis_tool: {
        icon: 'LineChart',
        color: 'emerald',
        sortOrder: 10,
    },
    lookup_user: {
        icon: 'Search',
        color: 'emerald',
        sortOrder: 11,
    },
    create_task: {
        icon: 'ClipboardPlus',
        color: 'sky',
        sortOrder: 12,
    },
}

export const DEFAULT_AVATAR: ToolDisplayConfig = {
    icon: 'Wrench',
    color: 'sky',
    sortOrder: 99,
}

export const getToolDisplayConfig = (toolId: string) => {
    return TOOL_DISPLAY_CONFIG[toolId] || DEFAULT_AVATAR
}
