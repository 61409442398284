import React from 'react'

import { Avatar } from 'ui/components/Avatar'

import { useDetailViewProfileImageAttributeState } from './hooks/useDetailViewProfileImageAttributeState'

type DetailViewProfileImageAttributeProps = React.ComponentPropsWithoutRef<typeof Avatar> & {}

export const DetailViewProfileImageAttribute: React.FC<DetailViewProfileImageAttributeProps> =
    React.memo(function DetailViewProfileImageAttribute(props) {
        const { hasThumbnail, imageSrc, firstName, lastName } =
            useDetailViewProfileImageAttributeState()

        if (!hasThumbnail) return null

        return (
            <Avatar
                size="s"
                {...props}
                type={(!!imageSrc ? 'image' : 'initial') as any}
                imageUrl={imageSrc}
                firstName={firstName}
                lastName={lastName}
            />
        )
    })
