import React, { useCallback, useRef } from 'react'

import { getFieldIcon } from 'features/admin/fields/icons/utils'
import { ListViewControlItem } from 'features/views/ListView/ListViewControlItem'

import { Box } from 'ui/components/Box'
import { Field } from 'ui/components/Field'
import { Select, SelectOption } from 'ui/components/Select'
import { Toggle } from 'ui/components/Toggle'

import { getSupportedProfileImageFields } from './utils'

type ThreadViewMessageProfileImageControlsProps = {
    objectSid: string
    setConfig: (config: Partial<ListViewOptions>) => void
    config: ListViewOptions
    fields: FieldDto[]
}

export const ThreadViewMessageProfileImageControls: React.FC<
    ThreadViewMessageProfileImageControlsProps
> = ({ objectSid, fields, config, setConfig }) => {
    return (
        <ListViewControlItem label="Profile image" icon="Image">
            <ThreadViewMessageProfileImageGenericControls
                maxWidth="full"
                minWidth="300px"
                objectSid={objectSid}
                fields={fields}
                config={config}
                setConfig={setConfig}
            />
        </ListViewControlItem>
    )
}

type ThreadViewMessageProfileImageGenericControlsProps = React.ComponentPropsWithoutRef<
    typeof Box
> &
    ThreadViewMessageProfileImageControlsProps

export const ThreadViewMessageProfileImageGenericControls: React.FC<
    ThreadViewMessageProfileImageGenericControlsProps
> = ({ objectSid, fields, config, setConfig, ...props }) => {
    return (
        <Box flex flexDirection="column" gap="l" {...props}>
            <ProfileImageControls
                objectSid={objectSid}
                config={config}
                setConfig={setConfig}
                fields={fields}
            />
        </Box>
    )
}

const ProfileImageControls: React.FC<ThreadViewMessageProfileImageControlsProps> = ({
    config,
    setConfig,
    fields,
}) => {
    const supportedFields = getSupportedProfileImageFields(fields)

    const existingConfigRef = useRef(config)
    existingConfigRef.current = config

    let fieldApiName = config.profileImage?.fieldApiName
    // If the field is not in the list of fields, reset the fieldApiName.
    if (!supportedFields.find((field) => field.api_name === fieldApiName)) {
        fieldApiName = undefined
    }

    const onChangeFieldApiName = useCallback(
        (value: string) => {
            const existingConfig = existingConfigRef.current

            const newValue: ListViewOptions['profileImage'] = value
                ? {
                      ...existingConfig.profileImage,
                      fieldApiName: value,
                  }
                : undefined

            setConfig({
                profileImage: newValue,
            })
        },
        [setConfig]
    )

    const isSplitLayout = config.threadLayout === 'split'

    const hideReceiverImage = config.threadHideReceiverProfileImage
    const hideSenderImage = config.threadHideSenderProfileImage

    return (
        <>
            <Select
                placeholder="Select field..."
                label="Profile image"
                value={fieldApiName}
                onChange={onChangeFieldApiName}
                isClearable
                isSearchable
            >
                {supportedFields.map((field) => (
                    <SelectOption
                        key={field._sid}
                        value={field.api_name}
                        label={field.label}
                        startIcon={getFieldIcon(field)}
                    />
                ))}
            </Select>
            {isSplitLayout && (
                <>
                    <Field
                        htmlFor="hideReceiverProfileImage"
                        label="Receiver profile image"
                        rightSlotContent={
                            <Toggle
                                id="hideReceiverProfileImage"
                                checked={!hideReceiverImage}
                                onCheckedChange={(value) => {
                                    setConfig({
                                        threadHideReceiverProfileImage: !value,
                                    })
                                }}
                            />
                        }
                    />
                    <Field
                        htmlFor="hideSenderProfileImage"
                        label="Sender profile image"
                        rightSlotContent={
                            <Toggle
                                id="hideSenderProfileImage"
                                checked={!hideSenderImage}
                                onCheckedChange={(value) => {
                                    setConfig({
                                        threadHideSenderProfileImage: !value,
                                    })
                                }}
                            />
                        }
                    />
                </>
            )}
        </>
    )
}
