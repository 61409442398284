import React, { useMemo } from 'react'

import { Button } from 'ui/components/Button'
import { Body } from 'ui/components/Text'

import * as Parts from './Pagination.parts'
import { computeDisplayedPages } from './utils'

type PaginationNavigationProps = {
    pageCount: number
    pageIndex: number
    onChangePageIndex: (pageIndex: number) => void
}

export const PaginationNavigation: React.FC<PaginationNavigationProps> = ({
    pageCount,
    pageIndex,
    onChangePageIndex,
}) => {
    const hasPreviousPage = pageIndex > 0
    const hasNextPage = pageIndex < pageCount - 1

    const incrementPageIndex = () => {
        if (hasNextPage) {
            onChangePageIndex(pageIndex + 1)
        }
    }

    const decrementPageIndex = () => {
        if (hasPreviousPage) {
            onChangePageIndex(pageIndex - 1)
        }
    }

    const displayedPageGroups = useMemo(
        () => computeDisplayedPages(pageCount, pageIndex),
        [pageCount, pageIndex]
    )

    return (
        <nav aria-label="pagination navigation">
            <Parts.PageList>
                <Parts.PageListItem>
                    <Button
                        variant="ghost"
                        size="s"
                        startIcon={{ name: 'ChevronLeft' }}
                        disabled={!hasPreviousPage}
                        onClick={decrementPageIndex}
                        aria-label="Go to previous page"
                        tabIndex={0}
                    />
                </Parts.PageListItem>
                {displayedPageGroups.map((group, idx) => (
                    <React.Fragment key={idx}>
                        {group.map((pageNumber) => {
                            const isActive = pageIndex === pageNumber

                            return (
                                <Parts.PageListItem key={pageNumber}>
                                    <Parts.NumberButton
                                        type="button"
                                        onClick={() => onChangePageIndex(pageNumber)}
                                        aria-current={isActive ? 'true' : undefined}
                                        tabIndex={isActive ? -1 : 0}
                                        aria-label={`Go to page ${pageNumber + 1}`}
                                    >
                                        <Body size="m" weight="medium">
                                            {pageNumber + 1}
                                        </Body>
                                    </Parts.NumberButton>
                                </Parts.PageListItem>
                            )
                        })}

                        {idx < displayedPageGroups.length - 1 && (
                            <Parts.Ellipsis>
                                <Body size="m" weight="medium">
                                    …
                                </Body>
                            </Parts.Ellipsis>
                        )}
                    </React.Fragment>
                ))}
                <Parts.PageListItem>
                    <Button
                        variant="ghost"
                        size="s"
                        startIcon={{ name: 'ChevronRight' }}
                        disabled={!hasNextPage}
                        onClick={incrementPageIndex}
                        aria-label="Go to next page"
                        tabIndex={0}
                    />
                </Parts.PageListItem>
            </Parts.PageList>
        </nav>
    )
}
