import React from 'react'

import { UserAttributeDisplay } from 'features/views/attributes/UserAttributeDisplay'

import { useUserAttributeState } from './hooks/useUserAttributeState'
import { BaseAttribute } from './BaseAttribute'
import { ThreadViewAttributeComponent } from './types'

const MAX_LINES = 1

type UserAttributeProps = {}

export const UserAttribute: ThreadViewAttributeComponent<UserRefDto, UserAttributeProps> = ({
    value,
    attribute,
    isLoading,
    ...props
}) => {
    const { field, rawOptions, type } = attribute

    const isMessage = type === 'message'
    const isContent = type === 'content'

    const { isEmpty, size } = useUserAttributeState({
        value,
        attribute,
        isLoading,
    })

    return (
        <BaseAttribute attribute={attribute} isEmpty={isEmpty} isLoading={isLoading} {...props}>
            <UserAttributeDisplay
                size={size}
                value={value}
                field={field}
                maxLines={MAX_LINES}
                isLoading={isLoading}
                showSingleUserName={!rawOptions.hideUserName}
                color={{
                    default: isMessage || isContent ? 'text' : 'textWeaker',
                    hover: 'textTheme',
                }}
            />
        </BaseAttribute>
    )
}
