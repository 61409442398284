import React from 'react'
import { useFormContext } from 'react-hook-form'

import { ToolConfig } from 'data/hooks/agents/types'
import { useWorkflows } from 'data/hooks/workflows/workflows'

import { Field } from 'ui/components/Field'
import { Select, SelectOption } from 'ui/components/Select'

import { ConfigureToolProps } from './types'

function filterWorkflows(workflows: WorkflowDto[]): WorkflowDto[] {
    return workflows.filter((workflow) => {
        if (!workflow.is_enabled) return false

        const trigger = workflow.trigger
        if (!trigger) return false
        if (trigger.trigger_type !== 'started_by_agent') return false

        return true
    })
}

export function ConfigureRunWorkflowTool({}: ConfigureToolProps) {
    const { data: flows } = useWorkflows()
    const { watch, setValue } = useFormContext<ToolConfig>()

    const workflowSid = watch('supplied_inputs.workflow_sid')

    const workflowOptions = React.useMemo(() => {
        if (!flows) return []

        return filterWorkflows(flows).map((workflow) => ({
            label: workflow.name,
            value: workflow._sid,
        }))
    }, [flows])

    return (
        <Field label="Workflow to run">
            <Select
                value={workflowSid}
                onChange={(value) => setValue('supplied_inputs.workflow_sid', value)}
                placeholder="Choose a workflow to run"
            >
                {workflowOptions.map((option) => (
                    <SelectOption key={option.value} value={option.value} label={option.label} />
                ))}
            </Select>
        </Field>
    )
}
