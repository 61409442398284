import React, { useCallback } from 'react'

import { PickerResponse } from 'filestack-js'

import ReactFilestack from 'v2/ui/ReactFilestack'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Tooltip } from 'ui/components/Tooltip'

import { LayoutEditorImageControlClearButtonStyle } from './LayoutEditorImageControl.css'

type LayoutEditorImageControlProps = {
    value: string
    onChange: (url: string, filename: string) => void
}

export const LayoutEditorImageControl: React.FC<LayoutEditorImageControlProps> = ({
    value,
    onChange,
}) => {
    const onSuccess = useCallback(
        (value: PickerResponse) => {
            const file = value.filesUploaded[0]
            if (!file) return

            const src = file.url ?? ''
            const filename = file.filename ?? ''
            onChange(src, filename)
        },
        [onChange]
    )

    return (
        <Box
            style={{ height: '90px' }}
            borderWidth="base"
            borderStyle="base"
            borderColor="border"
            rounded="2xl"
            position="relative"
            overflow="hidden"
            background="surface"
        >
            {value && (
                <Box position="absolute" top={0} left={0} width="full" height="full">
                    <Tooltip content="Remove image" asChild zIndex={1201} side="top" align="end">
                        <Button
                            variant="ghost"
                            position="absolute"
                            size="xs"
                            top="m"
                            right="m"
                            aria-label="Remove image"
                            onClick={() => onChange('', '')}
                            startIcon={{ name: 'X' }}
                            style={{ zIndex: 99 }}
                            className={LayoutEditorImageControlClearButtonStyle}
                        />
                    </Tooltip>
                    <Box
                        width="full"
                        height="full"
                        position="relative"
                        pointerEvents="none"
                        background="gray900"
                        style={{
                            zIndex: 9,
                            opacity: 0.4,
                        }}
                    />
                    <Box
                        position="absolute"
                        as="img"
                        src={value}
                        alt=""
                        width="full"
                        height="full"
                        top={0}
                        left={0}
                        style={{
                            objectFit: 'cover',
                            objectPosition: 'center',
                        }}
                    />
                </Box>
            )}
            {!value && (
                <ReactFilestack
                    options={{
                        maxFiles: 1,
                        accept: 'image/*',
                    }}
                    onSuccess={onSuccess}
                    customRender={({ onPick }: { onPick: () => void }) => (
                        <Button
                            variant="ghost"
                            startIcon={{ name: 'Plus' }}
                            style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: 0,
                            }}
                            onClick={onPick}
                        >
                            Upload image
                        </Button>
                    )}
                />
            )}
        </Box>
    )
}
