import { palette } from 'ui/styling/baseVariables/colors/colorPalette'

export function getSupportedTitleFields(fields: FieldDto[]) {
    return fields.filter((f) => {
        return ['string', 'long_text', 'document'].includes(f.type)
    })
}

export function getSupportedSubtitleFields(fields: FieldDto[]) {
    return fields.filter((f) => {
        return ['string', 'long_text', 'document'].includes(f.type)
    })
}

export function getSupportedProfileImageFields(fields: FieldDto[]) {
    return fields.filter((f) => f.type === 'multi_file')
}

export function getSupportedSecondaryHighlightFields(fields: FieldDto[]) {
    return fields.filter((f) => {
        return [
            'string',
            'long_text',
            'document',
            'url',
            'currency',
            'number',
            'percentage',
            'date',
            'datetime',
            'checkbox',
            'user_ref',
            'lookup',
            'dropdown',
        ].includes(f.type)
    })
}

export function getSupportedCoverImageFields(fields: FieldDto[]) {
    return fields.filter((f) => f.type === 'multi_file')
}

export function getSupportedLogoCoverOverlayFields(fields: FieldDto[]) {
    return fields.filter((f) => f.type === 'multi_file')
}

export function getSupportedPlaceholderImageFields(fields: FieldDto[]) {
    return fields.filter((f) => f.type === 'multi_file')
}

export const HEADER_BACKGROUND_COLORS = {
    RoseLight: palette.rose50,
    PinkLight: palette.pink50,
    FuchsiaLight: palette.fuchsia50,
    PurpleLight: palette.purple50,
    VioletLight: palette.violet50,
    IndigoLight: palette.indigo50,
    BlueLight: palette.blue50,
    SkyLight: palette.sky50,
    CyanLight: palette.cyan50,
    TealLight: palette.teal50,
    EmeraldLight: palette.emerald50,
    LimeLight: palette.lime50,
    OrangeLight: palette.orange50,
    AmberLight: palette.amber50,
    AlmondLight: palette.almond50,
    TaupeLight: palette.taupe50,
    StoneLight: palette.stone50,
    NeutralLight: palette.gray50,
    RoseDark: palette.rose600,
    PinkDark: palette.pink600,
    FuchsiaDark: palette.fuchsia600,
    PurpleDark: palette.purple600,
    VioletDark: palette.violet600,
    IndigoDark: palette.indigo600,
    BlueDark: palette.blue600,
    SkyDark: palette.sky600,
    CyanDark: palette.cyan600,
    TealDark: palette.teal600,
    EmeraldDark: palette.emerald600,
    LimeDark: palette.lime600,
    OrangeDark: palette.orange600,
    AmberDark: palette.amber600,
    AlmondDark: palette.almond600,
    TaupeDark: palette.taupe600,
    StoneDark: palette.stone600,
    NeutralDark: palette.gray600,
}
