import React from 'react'

import { ListHeader } from 'features/views/ListView/ListHeader/ListHeader'

import { useThreadViewListHeaderState } from './hooks/useThreadViewListHeaderState'

type ThreadViewListHeaderProps = {}

export const ThreadViewListHeader: React.FC<ThreadViewListHeaderProps> = React.memo(
    function ThreadViewListHeader() {
        const { recordCount, requestFilters, requestIncludedFields } =
            useThreadViewListHeaderState()

        return (
            <ListHeader
                recordCount={recordCount}
                requestFilters={requestFilters}
                requestIncludedFields={requestIncludedFields}
                isSticky
            />
        )
    }
)
