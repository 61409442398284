import { useMemo } from 'react'

import { useFiltersContext } from 'features/views/ListView/Filters/useFiltersContext'

export function useThreadViewFilters() {
    const { clearFilters, inlineFilters, inlineFilterType, availableInlineFilterFields } =
        useFiltersContext()

    const hasFilters = inlineFilters.length > 0

    return useMemo(
        () => ({
            hasFilters,
            clearFilters,
            inlineFilterType,
            availableInlineFilterFields,
        }),
        [clearFilters, hasFilters, inlineFilterType, availableInlineFilterFields]
    )
}
