import React from 'react'

import { Box } from 'ui/components/Box'

type ThreadViewGridProps = React.ComponentPropsWithoutRef<typeof Box> & {}

export const ThreadViewGrid: React.FC<ThreadViewGridProps> = React.memo(
    function ThreadViewGrid(props) {
        return <Box height="full" width="full" {...props} />
    }
)
