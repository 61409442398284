import { useMemo } from 'react'

import { useDetailViewContext } from 'features/views/DetailView/hooks/useDetailViewContext'
import { DetailViewHeaderSpacious } from 'features/views/DetailView/types'

export function useDetailViewSpaciousHeaderState() {
    const { recordManager, topOffset, rightOffset, header } = useDetailViewContext()!

    const isLoading = recordManager.isFetchingSlow

    const effectiveRightOffset =
        (header as DetailViewHeaderSpacious)?.coverType === 'boxed' ? rightOffset : undefined

    return useMemo(
        () => ({ isLoading, topOffset, rightOffset: effectiveRightOffset }),
        [isLoading, topOffset, effectiveRightOffset]
    )
}
