import { useHotkeys } from 'react-hotkeys-hook'

import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'

export function useLayoutEditorCanvasKeyboardShortcuts() {
    const { commands, selection } = useLayoutEditorContext()

    useHotkeys('ctrl+z, meta+z', commands.undo, { preventDefault: true }, [commands.undo])
    useHotkeys('ctrl+shift+z, meta+shift+z', commands.redo, { preventDefault: true }, [
        commands.redo,
    ])

    useHotkeys(
        'delete, backspace',
        () => {
            commands.removeSelectedWidget()
        },
        { enabled: selection?.type === 'widget' },
        [commands.removeSelectedWidget]
    )

    useHotkeys(
        'ctrl+d, meta+d',
        () => {
            commands.duplicateSelectedWidget()
        },
        { enabled: selection?.type === 'widget' },
        [commands.duplicateSelectedWidget]
    )
}
