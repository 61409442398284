import React, { forwardRef, useCallback, useMemo, useRef } from 'react'

import { useComposedRefs } from '@radix-ui/react-compose-refs'

import { TipTapEditorHandle } from 'features/tiptap/TipTapEditor'

import { Field } from 'ui/components/Field'
import { Link } from 'ui/components/Link'
import { extractStandardProps } from 'ui/helpers/styles'
import { generateUniqueId } from 'ui/helpers/utilities'

import * as Parts from './Textarea.parts'
import { TextareaRichTextEditor } from './TextareaRichTextEditor'
import { TextareaWrapper } from './TextareaWrapper'

type LinkProps = {
    href?: string
    to?: string
    label: string
    target?: string
}

type RichTextAreaProps = React.ComponentPropsWithoutRef<typeof TextareaRichTextEditor>

type RegularTextAreaProps = Omit<
    React.ComponentPropsWithoutRef<typeof Parts.TextareaBase>,
    'value' | 'onChange'
>

type TextareaRef<RT extends boolean> = RT extends boolean ? TipTapEditorHandle : HTMLTextAreaElement

type TextareaProps<RT extends boolean> = RegularTextAreaProps &
    React.ComponentPropsWithoutRef<typeof Field> & {
        link?: LinkProps
        autoSize?: boolean
        richText?: RT

        value?: RT extends true ? RichTextAreaProps['value'] : string
        onChange?: RT extends true
            ? RichTextAreaProps['onChange']
            : (e: React.ChangeEvent<HTMLTextAreaElement>) => void

        enableFileUpload?: RT extends true ? boolean : never
        textareaProps?: RT extends true ? RichTextAreaProps : RegularTextAreaProps
    }

const TextareaInner = <RT extends boolean>(
    {
        autoSize = false,
        id,
        isError,
        disabled,
        label,
        infoText,
        helperText,
        required,
        link,
        optional,
        size = 'm',
        resizable = false,
        readOnly,
        richText = false as RT,
        textareaProps,
        fieldProps,
        ...props
    }: TextareaProps<RT>,
    ref: React.ForwardedRef<TextareaRef<RT>>
) => {
    // this is so the id property can be optional, but still use the
    // label htmlFor property to link the label to the input.
    const effectiveId = useMemo(() => id || generateUniqueId(), [id])

    const Component = useMemo(() => {
        if (richText) {
            return TextareaRichTextEditor
        }

        return autoSize ? Parts.TextareaAutosizable : Parts.TextareaBase
    }, [autoSize, richText])

    const [standardProps, { style, className, ...rootProps }] = extractStandardProps(props)

    const editorRef = useRef<TextareaRef<RT>>(null)
    const composedRef = useComposedRefs<any>(ref, editorRef)

    const handleLabelClick = useCallback(
        (e: React.MouseEvent<HTMLLabelElement>) => {
            if (richText) {
                e.preventDefault()

                editorRef?.current?.editor?.commands.focus()
            }

            props.onLabelClick?.(e)
        },
        [props, richText]
    )

    return (
        <Field
            htmlFor={effectiveId}
            label={label}
            isError={isError}
            infoText={infoText}
            helperText={helperText}
            required={required}
            disabled={disabled}
            optional={optional}
            rightSlotContent={
                link && (
                    <Link size="m" target={link.target} to={link.to} href={link.href}>
                        {link.label}
                    </Link>
                )
            }
            onLabelClick={handleLabelClick}
            {...fieldProps}
            {...standardProps}
        >
            <TextareaWrapper
                isError={isError}
                disabled={disabled}
                size={size}
                isResizable={resizable}
                readOnly={readOnly}
                autoSize={autoSize}
                richText={richText}
                style={style}
                className={className}
            >
                <Component
                    ref={composedRef}
                    id={effectiveId}
                    aria-invalid={isError}
                    disabled={disabled}
                    size={size}
                    readOnly={readOnly}
                    autoSize={autoSize}
                    {...rootProps}
                    {...textareaProps}
                />
            </TextareaWrapper>
        </Field>
    )
}

export const Textarea = forwardRef(TextareaInner) as <RT extends boolean = false>(
    props: TextareaProps<RT> & { ref?: React.ForwardedRef<TextareaRef<RT>> }
) => ReturnType<typeof TextareaInner>
