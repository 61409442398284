import React from 'react'

import { ErrorBoundary } from 'app/ErrorBoundaries'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'
import { Body, Headline } from 'ui/components/Text'

type LayoutEditorWidgetControlsErrorBoundaryProps = {
    children: React.ReactNode
}

export const LayoutEditorWidgetControlsErrorBoundary: React.FC<
    LayoutEditorWidgetControlsErrorBoundaryProps
> = ({ children }) => {
    if (!children) return null

    return (
        <ErrorBoundary
            errorContent={
                <Box flex column gap="xs" px="l" width="full" height="full">
                    <Box
                        flex
                        column
                        gap="xs"
                        p="m"
                        borderStyle="dashed"
                        borderColor="borderDisabled"
                        borderWidth={1}
                        background="surfaceDisabled"
                        rounded="m"
                    >
                        <Icon size="xl" name="AlertCircle" color="error700" mb="s" />
                        <Headline size="2xs" color="error700">
                            There was a problem displaying these controls
                        </Headline>
                        <Body size="s">
                            Please try again later. If the issue persists, contact support.
                        </Body>
                    </Box>
                </Box>
            }
        >
            {children}
        </ErrorBoundary>
    )
}
