import React, { useCallback, useState } from 'react'

import { useAgents } from 'data/hooks/agents/agents'
import { useAgentInstructions } from 'data/hooks/agents/instructions'
import { useAgentSkills } from 'data/hooks/agents/skills'
import { useObjects } from 'data/hooks/objects'
import { AgentChatBox } from 'features/Agents/chat/AgentChatBox'
import { AgentIcon } from 'features/Agents/chat/AgentIcon'
import {
    Widget,
    WidgetAdminControlsComponent,
    WidgetComponent,
} from 'features/views/LayoutEditor/types'
import { useRecordManagerContext } from 'features/views/RecordManager/useRecordManagerContext'

import { Box } from 'ui/components/Box'
import { Select, SelectOptionComplex } from 'ui/components/Select'
import { Body } from 'ui/components/Text'

export type AgentWidgetType = Widget<{
    agentSid?: string
    selectedSkills?: string[]
    selectedInstructionSet?: string
}>

type AgentWidgetProps = {}

export const AgentWidget: WidgetComponent<AgentWidgetType, AgentWidgetProps> = ({
    widget,
    isSelected,
}) => {
    const { data: agents } = useAgents()
    const [conversationSid, setConversationSid] = useState<string>()
    const { record } = useRecordManagerContext()
    const { data: objects } = useObjects()
    const { data: instructionsList = [] } = useAgentInstructions(widget.attrs.agentSid || '')
    const instructions = instructionsList.find(
        (x) => x._sid === widget.attrs.selectedInstructionSet
    )
    const trigger = instructions?.triggers.find((x) => x.type === 'app_chatbot')

    // Sanitize the record to remove the object IDs
    const sanitizeRecord = (record: RecordDto | Partial<RecordDto> | undefined) => {
        if (!record) return record
        const object = objects?.find((o) => o._sid === record._object_id)
        return { ...record, _object_id: object?.api_name }
    }
    const sanitizedRecord = (sanitizeRecord(record) as Partial<RecordDto>) || {}
    const dereferencedRecords = sanitizedRecord._dereferenced_records
        ?.map((r) => sanitizeRecord(r) as Partial<RecordDto>)
        .filter((r): r is Partial<RecordDto> => r !== undefined)
    sanitizedRecord._dereferenced_records = dereferencedRecords

    const conversationContext = `
        You are currently on the detail page for the following record:
        ${JSON.stringify(sanitizedRecord)}
        ${
            widget.attrs.selectedSkills?.length
                ? `
        You have been configured with the following skills:
        ${widget.attrs.selectedSkills.join(', ')}
        Please prioritize using these skills when responding to user queries.`
                : ''
        }
    `

    console.log(conversationContext)
    const agent = agents?.find((a) => a._sid === widget.attrs.agentSid)

    if (!agent || !instructions || !trigger) {
        return (
            <Box p="l">
                <Body color={isSelected ? 'textWeakest' : 'text'}>
                    Please select an agent with an app chatbot trigger in the settings
                </Body>
            </Box>
        )
    }

    // Filter agent's available skills to only use selected ones if specified
    const effectiveAgent = widget.attrs.selectedSkills?.length
        ? {
              ...agent,
              available_skills: widget.attrs.selectedSkills,
          }
        : agent

    return (
        <Box
            flex
            column
            height="full"
            width="full"
            background="surface"
            borderRadius="m"
            borderColor="border"
            p="l"
            mb="xl"
            alignItems="center"
            justifyContent="center"
        >
            <Box maxWidth="800px" flex grow width="full" minHeight="600px" alignItems="flex-end">
                <AgentChatBox
                    agent={effectiveAgent}
                    conversationSid={conversationSid}
                    setConversationSid={setConversationSid}
                    conversationContext={conversationContext}
                    instructions={instructions}
                    trigger={trigger}
                />
            </Box>
        </Box>
    )
}

type AgentWidgetAdminControlsProps = {}

export const AgentWidgetAdminControls: WidgetAdminControlsComponent<
    AgentWidgetType,
    AgentWidgetAdminControlsProps
> = ({ widget, onChange }) => {
    const { data: agents } = useAgents()
    const agentSid = widget.attrs.agentSid || ''
    const selectedSkills = widget.attrs.selectedSkills || []
    const selectedInstructionSet = widget.attrs.selectedInstructionSet || ''
    const agent = agents?.find((a) => a._sid === agentSid)
    const { data: skills = [] } = useAgentSkills(agentSid)
    const { data: instructions = [] } = useAgentInstructions(agentSid)

    const filteredInstructionSets = instructions.filter((i) =>
        i.triggers.some((t) => t.type === 'app_chatbot')
    )

    const onChangeAgent = useCallback(
        (value: string) => {
            onChange((attrs) => {
                attrs.set('agentSid', value)
                attrs.set('selectedSkills', []) // Reset skills when agent changes
            })
        },
        [onChange]
    )

    const onChangeSkills = useCallback(
        (values: string[]) => {
            onChange((attrs) => {
                attrs.set('selectedSkills', values)
            })
        },
        [onChange]
    )

    const onChangeInstructionSet = useCallback(
        (value: string) => {
            onChange((attrs) => attrs.set('selectedInstructionSet', value))
        },
        [onChange]
    )

    const skillOptions = skills.map((skill) => ({
        value: skill.api_name,
        label: skill.name,
    }))

    return (
        <Box px="l" flex column gap="l">
            <Select
                label="Select Agent"
                placeholder="Choose an AI agent"
                value={agentSid}
                onChange={onChangeAgent}
            >
                {agents?.map((agent) => (
                    <SelectOptionComplex
                        key={agent._sid}
                        label={agent.title}
                        leftSlotContent={() => <AgentIcon agent={agent} />}
                        value={agent._sid}
                    />
                ))}
            </Select>

            {agent && (
                <Select
                    label="Select instruction set"
                    placeholder="which instruction set to use"
                    value={selectedInstructionSet}
                    onChange={onChangeInstructionSet}
                >
                    {filteredInstructionSets.map((i) => (
                        <SelectOptionComplex key={i._sid} label={i.name} value={i._sid} />
                    ))}
                </Select>
            )}
            {agent && (
                <Select
                    label="Select Skills"
                    placeholder="Choose skills for this agent"
                    value={selectedSkills}
                    onChange={onChangeSkills}
                    multiSelect
                    options={skillOptions}
                >
                    {skills.map((skill) => (
                        <SelectOptionComplex
                            key={skill.api_name}
                            label={skill.name}
                            value={skill.api_name}
                        />
                    ))}
                </Select>
            )}
        </Box>
    )
}
