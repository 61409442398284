import React from 'react'

import { DropdownAttributeDisplay } from 'features/views/attributes/DropdownAttributeDisplay'
import { useDetailViewAttributeValue } from 'features/views/DetailView/attributes/hooks/useDetailViewAttributeValue'

import { BaseAttribute } from './BaseAttribute'
import { PrimaryHighlightsAttributeComponent } from './types'

const MAX_OPTIONS = 2

type DropdownAttributeProps = {}

export const DropdownAttribute: PrimaryHighlightsAttributeComponent<DropdownAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const value = useDetailViewAttributeValue<string | string[]>(field.api_name)
    const isEmpty = !isLoading && (!value || (Array.isArray(value) && value.length < 1))

    return (
        <BaseAttribute {...props} field={field} isEmpty={isEmpty} isLoading={isLoading}>
            <DropdownAttributeDisplay
                value={value}
                field={field}
                isLoading={isLoading}
                maxLines={1}
                maxOptions={MAX_OPTIONS}
                size={{
                    mobile: 'm',
                    tablet: 'l',
                }}
            />
        </BaseAttribute>
    )
}
