import React, { useState } from 'react'

import { AuthOptions } from '@nangohq/frontend'

import { INTEGRATION_ID_TO_NAME } from 'features/DataConnections/constants'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Input } from 'ui/components/Input'
import { ModalFooter, ModalHeader } from 'ui/components/Modal'

import { ConnectionSetupProps } from './types'

export const NetSuiteInputs: React.FC<ConnectionSetupProps> = ({
    integrationId,
    handleConfirmed,
    handleClose,
}) => {
    const [accountId, setAccountId] = useState('')
    const [consumerKey, setConsumerKey] = useState('')
    const [consumerSecret, setConsumerSecret] = useState('')
    const [tokenId, setTokenId] = useState('')
    const [tokenSecret, setTokenSecret] = useState('')

    const integrationName = INTEGRATION_ID_TO_NAME[integrationId]
    return (
        <Box>
            <ModalHeader title={`Connect ${integrationName}`} showCloseButton={true} />
            <Box mx="3xl" my="xl">
                <Input
                    width="full"
                    variant="borderless"
                    label="Account ID"
                    placeholder={`Your ${integrationName} account ID`}
                    fontSize="bodyL"
                    value={accountId}
                    onChange={(e) => setAccountId(e.target.value)}
                    mb="m"
                />

                <Input
                    width="full"
                    variant="borderless"
                    label="Consumer Key"
                    placeholder={`Your ${integrationName} consumer key (aka client ID)`}
                    fontSize="bodyL"
                    value={consumerKey}
                    onChange={(e) => setConsumerKey(e.target.value)}
                    type="password"
                    mb="m"
                />
                <Input
                    width="full"
                    variant="borderless"
                    label="Consumer Secret"
                    placeholder={`Your ${integrationName} consumer secret (aka client secret)`}
                    fontSize="bodyL"
                    value={consumerSecret}
                    onChange={(e) => setConsumerSecret(e.target.value)}
                    type="password"
                    mb="m"
                />

                <Input
                    width="full"
                    variant="borderless"
                    label="Token ID"
                    placeholder={`Your ${integrationName} token ID`}
                    fontSize="bodyL"
                    value={tokenId}
                    onChange={(e) => setTokenId(e.target.value)}
                    type="password"
                    mb="m"
                />

                <Input
                    width="full"
                    variant="borderless"
                    label="Token Secret"
                    placeholder={`Your ${integrationName} token secret`}
                    fontSize="bodyL"
                    value={tokenSecret}
                    onChange={(e) => setTokenSecret(e.target.value)}
                    type="password"
                    mb="m"
                />
            </Box>

            <ModalFooter flex flexDirection="row" style={{ justifyContent: 'flex-end' }}>
                <Button size="l" variant="ghost" onClick={handleClose} mr="xs">
                    Cancel
                </Button>
                <Button
                    size="l"
                    variant="primary"
                    onClick={() => {
                        const nangoAuthOptions: AuthOptions = {
                            credentials: {
                                token_id: tokenId,
                                token_secret: tokenSecret,
                                oauth_client_id_override: consumerKey,
                                oauth_client_secret_override: consumerSecret,
                            },
                            params: {
                                accountId,
                            },
                        }
                        handleConfirmed(nangoAuthOptions)
                    }}
                >
                    Connect
                </Button>
            </ModalFooter>
        </Box>
    )
}
