import React, { ChangeEvent, FocusEvent, useEffect, useMemo, useRef, useState } from 'react'
import { HorizontalScrollContainer, HorizontalScrollItem } from 'react-simple-horizontal-scroller'

import { TabList } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { updateObject } from 'data/hooks/objects/objectOperations'
import { getIsStackerNativeObject } from 'features/admin/stackerNativeObjectUtils'
import { getIconForDataConnectionType } from 'features/DataConnections/getIconForDataConnectionType'

import { Box, Flex, Input, Tab, Text } from 'v2/ui'
import { useScrollIntoView } from 'v2/ui/hooks/useScrollIntoView'
import stackerTheme from 'v2/ui/theme/styles/default'

import type { ManageDataTabsProps } from './types'

const { colors } = stackerTheme()

export const ManageDataTabsList: React.VFC<
    {
        overflow?: string
        tabHeight?: string
    } & Pick<
        ManageDataTabsProps,
        | 'objects'
        | 'dataConnections'
        | 'onSelectObject'
        | 'selectedTabIndex'
        | 'isDirtyPanes'
        | 'actions'
    >
> = ({
    objects,
    dataConnections,
    selectedTabIndex,
    onSelectObject,
    isDirtyPanes,
    actions,
    overflow = 'hidden',
    ...props
}) => {
    const [overflowEndReached, setOverflowEndReached] = useState<'LEFT' | 'RIGHT' | null>('LEFT')

    const editingTabId = useMemo(() => objects[selectedTabIndex]?._sid, [objects, selectedTabIndex])
    const [editingTabName, setEditingTabName] = useState<string | null>(
        objects[selectedTabIndex]?.name
    )

    const [editInputWidth, setEditInputWidth] = useState<number>(0)

    const ref = useScrollIntoView(true)
    const spanInputContentRef = useRef<HTMLSpanElement>(null)
    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        setEditInputWidth(
            spanInputContentRef?.current?.offsetWidth
                ? spanInputContentRef?.current?.offsetWidth + 29
                : 0
        )
    }, [editingTabName])

    useEffect(() => {
        setEditingTabName(objects[selectedTabIndex]?.name)
    }, [selectedTabIndex, objects])

    const handleOnKeyDown = async (e: KeyboardEvent) => {
        const target = e.target as HTMLInputElement
        if (e.key === 'Enter') {
            if (target.value === '') {
                setEditingTabName(objects[selectedTabIndex]?.name)
                return
            }
            inputRef?.current?.blur()
        }
        if (e.key === 'Escape') {
            setEditingTabName(objects[selectedTabIndex]?.name)
            inputRef?.current?.blur()
        }
    }

    const updateTableName = async (value: string, object_sid: string) => {
        await updateObject(object_sid, { name: value })
    }
    return (
        <>
            <ObjectTabList {...props}>
                <Flex style={{ overflow: overflow }}>
                    <HorizontalScrollContainerWrapper>
                        <HorizontalScrollContainer
                            childPosition={'start'}
                            controlsConfig={{
                                right: {
                                    defaultIconStyle: {
                                        opacity: overflowEndReached === 'RIGHT' ? '0' : undefined,
                                        borderColor: colors.userInterface.neutral[0],
                                    },
                                    visibility: 'AUTO',
                                },
                                left: {
                                    defaultIconStyle: {
                                        opacity: overflowEndReached === 'LEFT' ? '0' : undefined,
                                        borderColor: colors.userInterface.neutral[0],
                                    },
                                    visibility: 'AUTO',
                                },
                            }}
                            onScrollEnd={(reachedEnd) => setOverflowEndReached(reachedEnd)}
                        >
                            {objects.map((object, index) => {
                                const dataConnection = dataConnections?.find(
                                    (dc) => dc._sid == object.data_connection
                                )
                                const dcIcon = getIconForDataConnectionType(dataConnection?.type)

                                return (
                                    <HorizontalScrollItem key={object._sid} id={object._sid}>
                                        {editingTabId !== object._sid ||
                                        !getIsStackerNativeObject(object) ? (
                                            <ObjectTab
                                                id={`${index}`}
                                                ref={selectedTabIndex === index ? ref : null}
                                                isSelected={selectedTabIndex === index}
                                                onClick={() => {
                                                    if (selectedTabIndex !== index) {
                                                        onSelectObject(object._sid)
                                                    }

                                                    if (getIsStackerNativeObject(object)) {
                                                        setEditingTabName(objects[index]?.name)
                                                    }
                                                }}
                                                style={{
                                                    fontWeight:
                                                        selectedTabIndex === index
                                                            ? 'bold'
                                                            : 'normal',
                                                }}
                                                tabHeight={props.tabHeight}
                                                asButton={false}
                                            >
                                                <StyledText
                                                    nativeConnection={getIsStackerNativeObject(
                                                        object
                                                    )}
                                                    isSelected={selectedTabIndex === index}
                                                    color="inherit"
                                                    trimText
                                                    fontStyle={
                                                        isDirtyPanes[object._sid]
                                                            ? 'italic'
                                                            : 'normal'
                                                    }
                                                >
                                                    <Box
                                                        style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        {`${object.name}${
                                                            isDirtyPanes[object._sid] ? '*' : ''
                                                        }`}
                                                        {!!dcIcon && (
                                                            <img
                                                                height="20px"
                                                                width="20px"
                                                                alt={`${dataConnection?.label} icon`}
                                                                style={{
                                                                    padding: '2px',
                                                                    marginLeft: '6px',
                                                                }}
                                                                src={dcIcon}
                                                            />
                                                        )}
                                                    </Box>
                                                </StyledText>
                                            </ObjectTab>
                                        ) : (
                                            <>
                                                <span
                                                    style={{
                                                        position: 'absolute',
                                                        whiteSpace: 'pre',
                                                        zIndex: -100,
                                                        opacity: 0,
                                                    }}
                                                    ref={spanInputContentRef}
                                                >
                                                    {editingTabName}
                                                </span>
                                                <StyledInput
                                                    ref={inputRef}
                                                    isDirty={isDirtyPanes[object._sid]}
                                                    nativeConnection={getIsStackerNativeObject(
                                                        object
                                                    )}
                                                    isSelected={true}
                                                    style={{
                                                        width: editInputWidth + 'px',
                                                    }}
                                                    variant="dgTab"
                                                    value={
                                                        editingTabName +
                                                        (editingTabId && isDirtyPanes[editingTabId]
                                                            ? '*'
                                                            : '')
                                                    }
                                                    onChange={(
                                                        e: ChangeEvent<HTMLInputElement>
                                                    ) => {
                                                        e.stopPropagation()
                                                        setEditingTabName(e.target.value)
                                                    }}
                                                    onKeyDown={(e: KeyboardEvent) => {
                                                        e.stopPropagation()
                                                        handleOnKeyDown(e)
                                                    }}
                                                    onBlur={(e: FocusEvent<HTMLInputElement>) =>
                                                        updateTableName(
                                                            e?.target.value,
                                                            objects.find(
                                                                (o) => o._sid === object._sid
                                                            )?._sid as string
                                                        )
                                                    }
                                                    onFocus={(
                                                        e: React.FocusEvent<HTMLInputElement>
                                                    ) => {
                                                        e.target.select()
                                                    }}
                                                />
                                            </>
                                        )}
                                    </HorizontalScrollItem>
                                )
                            })}
                        </HorizontalScrollContainer>
                    </HorizontalScrollContainerWrapper>
                </Flex>
                <>{actions}</>
            </ObjectTabList>
        </>
    )
}

const StyledText = styled(Text)<{ isSelected: boolean; nativeConnection?: boolean }>`
    &:hover {
        cursor: ${(props) => (props.isSelected && props.nativeConnection ? 'text' : null)};
        border: ${(props) =>
            props.isSelected && props.nativeConnection ? `1px solid ${colors.grey[300]}` : null};
        border-radius: 3px;
    }
    border: solid 1px transparent;
    border-radius: 3px;
    transition: border 0.4s ease-in;
    padding: 2px 12px 2px 12px;
`

const HorizontalScrollContainerWrapper = styled(Flex)`
    flex-grow: 0;
    width: 100%;
`
const ObjectTabList = styled(TabList)`
    border-bottom: 0px;
    flex-grow: 0;
    flex-shrink: 1;
    min-width: 0;
`

const ObjectTab = styled(Tab)<{ isSelected: boolean; tabHeight?: string }>`
    height: ${(p) => (p.tabHeight ? p.tabHeight : '36px')};
    p {
        color: ${(props) =>
            props.isSelected
                ? colors.userInterface.neutral[1000]
                : colors.userInterface.neutral[0]};
    }
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    background-color: ${(props) =>
        props.isSelected ? colors.userInterface.neutral[0] : colors.userInterface.accent[1400]};
    border-width: ${(props) => (props.isSelected ? 0 : 1)};
    margin: 0 4px;
    border-bottom: 0;
    opacity: ${(props) => (props.isSelected ? 1 : 0.8)};
    &:hover {
        opacity: 1;
        color: ${(props) =>
            props.isSelected
                ? colors.userInterface.neutral[1000]
                : colors.userInterface.neutral[0]};
    }
`

const StyledInput = styled(Input)<{ isSelected: boolean; isDirty: boolean }>`
    & > input {
        font-style: ${(props) => (props.isDirty ? 'italic' : '')};
        border: 1px solid transparent;
        border-radius: 3px;
        transition: border 0.4s ease-in;
        &:hover {
            border: 1px solid ${colors.grey[300]};
            border-radius: 3px;
        }
    }
    & > input:focus {
        border: 1px solid ${colors.grey[500]};
        border-radius: 3px;
    }
`
