import React from 'react'
import { useFormContext } from 'react-hook-form'

import { Agent } from 'data/hooks/agents/types'

import { Box } from 'ui/components/Box'
import { Field } from 'ui/components/Field'
import { IconPickerDropdown } from 'ui/components/IconPicker/IconPickerDropdown'
import { Input } from 'ui/components/Input'
import { Textarea } from 'ui/components/Textarea'

export function EditAgentForm({ showAllOptions = false }: { showAllOptions?: boolean }) {
    const { register, setValue, watch } = useFormContext<Partial<Agent>>()
    const icon = watch('icon') || { type: 'lucide', name: 'Bot' }

    return (
        <Box flex column gap="m">
            <Field label="Agent Title" width="5xl" shrink>
                <Box flex gap="m">
                    <IconPickerDropdown
                        value={icon}
                        onChange={(newIcon) => setValue('icon', newIcon, { shouldDirty: true })}
                    />
                    <Input
                        grow
                        placeholder="Enter agent title"
                        {...register('title', { required: true })}
                    />
                </Box>
                {showAllOptions && (
                    <>
                        <Textarea
                            label="Tone and behavioral instructions which apply across all operations"
                            placeholder="Enter instructions"
                            {...register('custom_instructions')}
                            resizable
                            style={{ height: '200px' }}
                            mt="l"
                        />
                    </>
                )}
            </Field>
        </Box>
    )
}
