import React, { useRef, useState } from 'react'
import ReactDOM from 'react-dom'

import { Popover, PopoverContent, PopoverTrigger, Portal } from '@chakra-ui/react'
import styled from '@emotion/styled'
import classNames from 'classnames'

import { Text } from 'legacy/v1/ui'
import { Icon8 } from 'legacy/v1/ui/components/Icon8'
import useTrack from 'utils/useTrack'

import { Box, Icon } from 'v2/ui'

import { useTheme } from 'ui/styling/themes/useTheme'

import { Cover, MenuToggleButton } from './ui/utils'
import { formatCounterValue } from './utils/formatCounterValue'

const LabelText = styled(Text)`
    line-height: 18px;
    font-weight: 600;
`

type TriggerProps = {
    onClick: React.MouseEventHandler
    onKeyDown: React.KeyboardEventHandler
}

type MenuToggleProps = React.ComponentPropsWithoutRef<'div'> & {
    icon?: string
    hide?: boolean
    iconProps?: React.ComponentPropsWithoutRef<typeof Icon>
    faicon?: string
    secondFaIcon?: string
    secondIconProps?: React.ComponentPropsWithoutRef<typeof Icon>
    noBackground?: boolean
    label?: string
    fixedContainer?: boolean
    isInSettingsModal?: boolean
    toggleMenuCursor?: string
    textStyle?: React.CSSProperties
    counter?: number
    withEventTracking?: boolean
    contentProps?: Partial<React.ComponentPropsWithoutRef<typeof PopoverContent>>
    customToggleComponent?: React.FC<TriggerProps>
}

const MenuToggle: React.FC<MenuToggleProps> = ({
    hide,
    style,
    icon,
    iconProps,
    faicon,
    textStyle,
    noBackground,
    label,
    children,
    secondFaIcon,
    secondIconProps,
    toggleMenuCursor,
    counter = 0,
    withEventTracking,
    contentProps,
    customToggleComponent: СustomToggleComponent,
    ...props
}) => {
    const [isOpen, setIsOpen] = useState(false)
    const { track } = useTrack()

    const toggle = () => {
        if (!isOpen && withEventTracking) {
            track('WIP - Frontend - List View - Layout Options - Clicked', {
                option: label,
            })
        }
        setIsOpen(!isOpen)
    }

    const onToggleKeydown = (e: React.KeyboardEvent<HTMLElement>) => {
        if (e.key === 'Enter' || e.key === ' ') {
            e.preventDefault()

            toggle()
        }
    }

    const slidingPanePortal = useRef(document.getElementById('slidingPaneBackdropPortal'))
    const shouldDisplayCover = isOpen && slidingPanePortal.current

    const { themeClassName } = useTheme()

    return (
        <>
            <div
                style={{
                    display: hide ? 'none' : 'block',
                    cursor: 'pointer',
                    ...style,
                }}
                {...props}
            >
                <Popover
                    isOpen={isOpen}
                    onClose={toggle}
                    placement="bottom-end"
                    closeOnBlur={false}
                >
                    <PopoverTrigger>
                        {!!СustomToggleComponent ? (
                            <Box>
                                <СustomToggleComponent
                                    onClick={toggle}
                                    onKeyDown={onToggleKeydown}
                                />
                            </Box>
                        ) : (
                            <MenuToggleButton
                                onClick={toggle}
                                onKeyDown={onToggleKeydown}
                                role="button"
                                tabIndex={0}
                            >
                                {icon && (
                                    // @ts-expect-error
                                    <Icon8
                                        aria-hidden={true}
                                        iconStyle="ios-glyphs"
                                        displaySize="18"
                                        icon={icon}
                                        {...iconProps}
                                    />
                                )}
                                {faicon && <Icon aria-hidden={true} icon={faicon} {...iconProps} />}
                                {secondFaIcon && (
                                    <Icon
                                        aria-hidden={true}
                                        icon={secondFaIcon}
                                        {...secondIconProps}
                                    />
                                )}
                                {label && (
                                    <LabelText
                                        /* @ts-expect-error */
                                        size="fontS"
                                        padding="none"
                                        margin="none"
                                        style={textStyle}
                                    >
                                        {label}
                                        {counter > 0 && ` (${formatCounterValue(counter)})`}
                                    </LabelText>
                                )}
                            </MenuToggleButton>
                        )}
                    </PopoverTrigger>
                    <Portal>
                        <Box zIndex={1001} position="absolute" onClick={(e) => e.stopPropagation()}>
                            <PopoverContent
                                cursor={toggleMenuCursor}
                                background={noBackground ? 'transparent' : 'white'}
                                boxShadow={noBackground ? '' : '0 0 0 2px rgba(0, 0, 0, 0.1)'}
                                borderRadius={3}
                                maxWidth="600px"
                                maxHeight="70vh"
                                overflowY="auto"
                                width="max-content"
                                padding={3}
                                textAlign="left"
                                outline="none"
                                zIndex="sticky"
                                _focus={{
                                    boxShadow: noBackground ? '' : '0 0 0 2px rgba(0, 0, 0, 0.1)',
                                }}
                                {...contentProps}
                                className={classNames(themeClassName, contentProps?.className)}
                            >
                                {children}
                            </PopoverContent>
                        </Box>
                    </Portal>
                </Popover>
            </div>

            {shouldDisplayCover &&
                ReactDOM.createPortal(<Cover onClick={toggle} />, slidingPanePortal.current!)}
        </>
    )
}

export default React.memo(MenuToggle)
