export const HELP_LINKS = {
    PERMISSIONS:
        'https://docs.stackerhq.com/stacker/getting-started/introduction-to-users-and-permissions',
    FORMULA_FIELDS:
        'https://docs.stackerhq.com/stacker/data-management/edit-your-data-in-the-data-grid/formula-field',
    INLINE_FILTERS:
        'https://docs.stackerhq.com/stacker/customize-layouts/list-layout/edit-list-layouts#inline-filters',
    CUSTOM_DOMAINS: 'https://docs.stackerhq.com/stacker/interactivity/custom-domain',
    LIST_FILTERS: 'https://docs.stackerhq.com/stacker/security/data-filters#list-layout-filters',
    HOME: 'https://docs.stackerhq.com/stacker',
} as const
