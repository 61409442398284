import React from 'react'

import { ThreadViewPaginationStyle } from 'features/views/ListView/ThreadView/ThreadView.css'

import { Box } from 'ui/components/Box'
import { Pagination } from 'ui/components/Pagination'

import { useThreadViewPaginationState } from './hooks/useThreadViewPaginationState'

type ThreadViewPaginationProps = React.ComponentPropsWithoutRef<typeof Box> & {}

export const ThreadViewPagination: React.FC<ThreadViewPaginationProps> = (props) => {
    const { pageIndex, onChangePageIndex, pageSize, totalItemsCount, showPagination } =
        useThreadViewPaginationState()

    if (!showPagination) return null

    return (
        <Box flex center alignSelf="stretch" className={ThreadViewPaginationStyle} {...props}>
            <Pagination
                grow
                pageSize={pageSize}
                totalItemsCount={totalItemsCount}
                pageIndex={pageIndex}
                onChangePageIndex={onChangePageIndex}
            />
        </Box>
    )
}
