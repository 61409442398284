import { useCallback, useMemo } from 'react'

import { useActionButtons } from 'features/actions/actionHooks'
import { useActionButton } from 'features/actions/hooks/useActionButton'
import { useProcessFilter } from 'features/records/components/RecordFilters'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'
import { BannerWidgetType } from 'features/views/LayoutEditor/widgets/bannerWidgetTypes'
import { useRecordManagerContext } from 'features/views/RecordManager/useRecordManagerContext'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { Banner } from 'ui/components/Banner'

export function useBannerWidgetState(widget: BannerWidgetType) {
    const {
        title = '',
        subtitle = '',
        style = 'app',
        isDismissable = false,
        visibilityFilters = [],
        actionButton,
        link,
    } = widget.attrs

    const { isEditing, object } = useLayoutEditorContext()

    const { record, includeFields = [] } = useRecordManagerContext()

    const icon = getIcon(style)
    const type = getBannerType(style)

    const passesFilters = useProcessFilter()

    const matchesFilters = useMemo(() => {
        if (!record) return false

        return passesFilters([record], visibilityFilters).length > 0
    }, [passesFilters, visibilityFilters, record])

    const isDismissed = restoreFromLocalStorage(record?._sid!, widget.id)
    const isVisible = isEditing || (matchesFilters && !isDismissed)
    const onDismiss = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            if (isEditing) {
                e.stopPropagation()
                e.preventDefault()

                return
            }

            persistToLocalStorage(record?._sid!, widget.id, true)
        },
        [isEditing, record?._sid, widget.id]
    )

    const actions = useActionButtons(actionButton ? [actionButton] : [], record!, object!)
    const actionMemo = useDeepEqualsMemoValue(actions[0]?.action)

    const { executeAction } = useActionButton({
        action: actionMemo,
        recordId: record?._sid!,
        record: record!,
        includeFields,
    })

    const handleButtonClick = useCallback(
        async (e: React.MouseEvent<HTMLButtonElement>) => {
            e.stopPropagation()
            e.preventDefault()

            await executeAction()
        },
        [executeAction]
    )

    const buttonLabel = actionMemo?.name

    const bannerLink = !!link ? getBannerLink(link) : undefined

    return useMemo(
        () => ({
            type,
            title,
            subtitle,
            icon,
            isDismissable,
            isVisible,
            handleButtonClick,
            buttonLabel,
            link: bannerLink,
            onDismiss,
        }),
        [
            icon,
            title,
            subtitle,
            isDismissable,
            type,
            isVisible,
            handleButtonClick,
            buttonLabel,
            bannerLink,
            onDismiss,
        ]
    )
}

type BannerProps = React.ComponentProps<typeof Banner>

function getIcon(style: BannerWidgetType['attrs']['style']): BannerProps['icon'] {
    switch (style) {
        case 'success':
            return { name: 'CheckCircle2' }
        case 'warning':
            return { name: 'AlertOctagon' }
        case 'error':
            return { name: 'XCircle' }
        default:
            return { name: 'Info' }
    }
}

function getBannerType(style: BannerWidgetType['attrs']['style']): BannerProps['type'] {
    switch (style) {
        case 'success':
            return 'success'
        case 'warning':
            return 'warning'
        case 'error':
            return 'error'
        case 'app':
            return 'info'
        case 'gray':
            return 'neutralSolid'
        case 'border':
            return 'neutral'
        default:
            return 'info'
    }
}

function getBannerLink(link: BannerWidgetType['attrs']['link']): BannerProps['link'] {
    if (!link) return undefined

    const target = link.openAs === 'new_tab' ? '_blank' : undefined

    return {
        href: link.href,
        label: link.label || 'Learn more',
        target,
    }
}

function getLocalStorageKey(recordSid: string, widgetId: string) {
    return `${recordSid}_${widgetId}_is_dismissed`
}

function persistToLocalStorage(recordSid: string, widgetId: string, state: boolean) {
    const key = getLocalStorageKey(recordSid, widgetId)
    localStorage.setItem(key, state.toString())
}

function restoreFromLocalStorage(recordSid: string, widgetId: string): boolean | undefined {
    const key = getLocalStorageKey(recordSid, widgetId)
    const value = localStorage.getItem(key)
    if (!value) return

    return value.toLowerCase() === 'true'
}
