import * as Y from 'yjs'

import { toYType } from 'features/utils/useYjsState'
import { LayoutEditorSchema, Widget } from 'features/views/LayoutEditor/types'
import { LayoutEditorCommands } from 'features/views/LayoutEditor/useLayoutEditorContext'
import { createWidget } from 'features/views/LayoutEditor/utils'

import { TabsWidgetTab } from './tabsWidgetTypes'

export function createDefaultTabChildren(
    tabId: string,
    commands: LayoutEditorCommands,
    schema: LayoutEditorSchema,
    view?: ViewDto
) {
    let widgetType: string | undefined
    switch (tabId) {
        case 'details':
            widgetType = 'fields'
            break
        case 'activity':
            widgetType = 'feed'
            break
    }
    if (!widgetType) return

    commands.mutateSelectedWidget(
        (data) => {
            let children = data.get('children')
            if (!children) {
                children = data.set('children', new Y.Map())
            }

            if (children.has(tabId)) return // Tab already has children, skip.

            const newWidget = createWidget(widgetType, schema, view)
            if (!newWidget) return

            const newYWidget = toYType(newWidget)
            children.set(tabId, Y.Array.from([newYWidget]))
        },
        { skipHistory: true }
    )
}

const SPACE_REGEXP = /\s+/
const TEXT_REGEXP = /[^a-z0-9-]/

export function getTabUrlSlug(tabName: string, allTabs: TabsWidgetTab[]): string {
    const tabSlugs = new Set(allTabs.map((tab) => tab.urlSlug || tab.id))

    let slug = tabName.toLowerCase().replace(SPACE_REGEXP, '-').replace(TEXT_REGEXP, '')

    let idx = 1
    while (tabSlugs.has(slug)) {
        slug = `${slug}-${idx}`
        idx++
    }

    return slug
}

const TAB_WIDGET_SLUG = 'tab'

export function getTabWidgetUrlSlug(view?: ViewDto): string {
    const existingSlugs = new Set<string>()

    for (const widgetArea of Object.values(view?.layout?.children ?? {})) {
        for (const widget of widgetArea) {
            appendExistingSlugs(existingSlugs, widget)
        }
    }

    let slug = TAB_WIDGET_SLUG
    let idx = 1
    while (existingSlugs.has(slug)) {
        slug = `${slug}-${idx}`
        idx++
    }

    return slug
}

function appendExistingSlugs(existingSlugs: Set<string>, widget: Widget) {
    if (widget.type === 'tabs') {
        const slug = widget.attrs.urlSlug
        if (!!slug) {
            existingSlugs.add(slug)
        }
    }

    for (const widgetArea of Object.values(widget.children)) {
        for (const widget of widgetArea) {
            appendExistingSlugs(existingSlugs, widget)
        }
    }
}
