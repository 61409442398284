import { useMemo } from 'react'

import { extractPartsFromUserName } from 'features/views/attributes/utils'
import { useDetailViewContext } from 'features/views/DetailView/hooks/useDetailViewContext'

export function useDetailViewProfileImageAttributeState() {
    const { header, recordManager, fields } = useDetailViewContext()!

    const profileImageFieldApiName = header.profileImage?.fieldApiName
    const profileImageField = fields.find((field) => field.api_name === profileImageFieldApiName)

    const titleFieldApiName = header.title?.fieldApiName
    const titleField = fields.find((field) => field.api_name === titleFieldApiName)

    const hasField = !!profileImageField

    const fieldValue = hasField ? recordManager.record?.[profileImageField?.api_name!] : undefined
    const imageSrc = hasField ? extractSrcFromFieldValue(profileImageField, fieldValue) : undefined

    const recordTitleValue = recordManager.record?.[titleField?.api_name!] ?? undefined
    const recordTitle =
        !!recordTitleValue && typeof recordTitleValue === 'object'
            ? recordTitleValue.plainTextContent
            : recordTitleValue?.toString()

    const hasThumbnail = hasField && !!recordTitle

    const { firstName, lastName } = extractPartsFromUserName(recordTitle)

    return useMemo(
        () => ({
            hasThumbnail,
            imageSrc,
            firstName,
            lastName,
        }),
        [imageSrc, hasThumbnail, firstName, lastName]
    )
}

function extractSrcFromFieldValue(field: FieldDto, value: unknown) {
    // Extract the URL from the attachment value.
    if (field.type === 'multi_file') {
        if (Array.isArray(value)) {
            return value[0]?.url
        } else if (value && typeof value === 'object') {
            return (value as any).url
        }
    }

    return undefined
}
