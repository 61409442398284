import React, { useMemo } from 'react'

import { useAppContext } from 'app/useAppContext'
import { useObject } from 'data/hooks/objects'
import {
    filtersToValue,
    formatFilters,
    ObjectFieldsFilterV4 as Filters,
} from 'features/records/components/RecordFilters'
import { ObjectPicker } from 'features/studio/ui/ObjectPicker'
import { DEFAULT_ACTION_ICON } from 'features/workflows/common'
import { FilterValueExpression } from 'features/workflows/common/FilterValueExpression'
import { ItemIcon } from 'features/workflows/ItemIcon'
import { ConfigurationLabel } from 'features/workflows/WorkflowUI'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Container } from 'ui/components/Container'
import { Input } from 'ui/components/Input'

import { WorkflowActionEditorComponent, WorkflowActionNodeRendererComponent } from './types'

export const FindRecordsActionNode: WorkflowActionNodeRendererComponent = ({
    action,
    actionType,
    showType,
}) => {
    const { object } = useObject(action.settings?.object_id as string | undefined)

    return (
        <>
            <ItemIcon
                icon={actionType.icon || DEFAULT_ACTION_ICON}
                iconStyle={actionType.icon_style}
                mr="m"
            />

            <Box flex column alignItems="flex-start" fontWeight="bodyRegular">
                {showType && (
                    <Box fontSize="bodyXs" color="textWeakest">
                        Action
                    </Box>
                )}

                <Box flex center>
                    {action.name}
                    {object && (
                        <>
                            {' '}
                            in
                            <Container
                                as="span"
                                ml="s"
                                px="xs"
                                py="2xs"
                                borderRadius="s"
                                variant="accentMuted"
                            >
                                {object?.name}
                            </Container>
                        </>
                    )}
                </Box>
            </Box>
        </>
    )
}

type FindRecordsActionFilter = FilterValue

export const FindRecordsActionConfig: WorkflowActionEditorComponent = ({ action, onChange }) => {
    const { selectedStack } = useAppContext()

    const objectId = action.settings?.object_id as string | undefined
    const limit = action.settings?.limit as number | undefined

    const { object } = useObject(objectId)
    const fields = useMemo(() => {
        if (!object) return []

        return object.fields.filter((f) => !f.connection_options?.is_disabled)
    }, [object])

    const filtersValue = useMemo(() => {
        const filters = (action.settings?.filters as FindRecordsActionFilter[] | undefined) ?? []

        return filtersToValue(filters, object) as Filter[]
    }, [action.settings?.filters, object])

    const handleChangeSettings = (patch: Record<string, unknown>) => {
        onChange({
            settings: {
                ...action.settings,
                ...patch,
            },
        })
    }

    const handleChangeFilters = (newFilters: Filter[]) => {
        handleChangeSettings({
            filters: formatFilters(newFilters) as FindRecordsActionFilter[],
        })
    }

    return (
        <>
            <ConfigurationLabel>Table</ConfigurationLabel>
            <ObjectPicker
                value={objectId}
                onChange={(item: string) => {
                    handleChangeSettings({
                        object_id: item,
                        stack_id: selectedStack?._sid,
                    })
                }}
                filter={(o: ObjectDto) => !o.connection_options.read_only}
                isClearable={false}
            />
            <ConfigurationLabel>Max results (optional)</ConfigurationLabel>
            <Input
                value={limit}
                onChange={(e) => handleChangeSettings({ limit: e.target.value })}
            />
            {object && (
                <>
                    <ConfigurationLabel>Filters</ConfigurationLabel>
                    <Filters
                        object={object}
                        fields={fields}
                        value={filtersValue}
                        onChange={handleChangeFilters}
                        includeIncompleteFilters={false}
                        showRelativeDateFilters={true}
                        hideCurrentUserOption={true}
                        disableValidation
                        customButtonRender={(props) => (
                            <Box>
                                <Button
                                    {...props}
                                    type="button"
                                    variant="secondary"
                                    startIcon={{ name: 'Plus' }}
                                >
                                    Add filter
                                </Button>
                            </Box>
                        )}
                        // @ts-expect-error
                        FilterValueComponent={FilterValueExpression}
                    />
                </>
            )}
        </>
    )
}
