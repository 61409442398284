import React, { useCallback } from 'react'

import * as RadioGroupPrimitive from '@radix-ui/react-radio-group'
import { PickerResponse } from 'filestack-js'

import { getFieldIcon } from 'features/admin/fields/icons/utils'
import { toYType } from 'features/utils/useYjsState'
import {
    DetailHeaderTitleSizeControlCardStyle,
    DetailHeaderTitleSizeControlIconStyle,
} from 'features/views/DetailView/headers/DetailViewHeader.css'
import {
    getSupportedCoverImageFields,
    getSupportedProfileImageFields,
    getSupportedSubtitleFields,
    getSupportedTitleFields,
    HEADER_BACKGROUND_COLORS,
} from 'features/views/DetailView/headers/utils'
import {
    DetailViewHeaderFieldRef,
    DetailViewHeaderFileRef,
    DetailViewHeaderSpacious,
} from 'features/views/DetailView/types'
import { getYHeader } from 'features/views/DetailView/utils'
import { LayoutEditorCollapsibleControl } from 'features/views/LayoutEditor/controls/LayoutEditorCollapsibleControl'
import { LayoutEditorControlSeparator } from 'features/views/LayoutEditor/controls/LayoutEditorControlSeparator'
import { LayoutEditorFieldSelector } from 'features/views/LayoutEditor/controls/LayoutEditorFieldSelector'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'

import ReactFilestack from 'v2/ui/ReactFilestack'

import { Box } from 'ui/components/Box'
import { Dropdown, DropdownContent, DropdownTitle, DropdownTrigger } from 'ui/components/Dropdown'
import { DropdownFooterItem } from 'ui/components/Dropdown/DropdownFooterItem'
import { Field } from 'ui/components/Field'
import { Icon } from 'ui/components/Icon'
import { RadioCard, RadioCardGroup } from 'ui/components/Radio'
import { Select, SelectOption } from 'ui/components/Select'
import { TriggerStyles } from 'ui/components/Select/Select.css'
import { Body } from 'ui/components/Text'
import { stopPropagation } from 'ui/helpers/utilities'
import { theme } from 'ui/styling/Theme.css'

const MAX_PRIMARY_HIGHLIGHTS = 3

type DetailViewSpaciousHeaderControlsProps = {
    fields: FieldDto[]
    header: DetailViewHeaderSpacious
}

export const DetailViewSpaciousHeaderControls: React.FC<DetailViewSpaciousHeaderControlsProps> = ({
    fields,
    header,
}) => {
    const { commands } = useLayoutEditorContext()

    const profileImageFields = getSupportedProfileImageFields(fields)
    const titleFields = getSupportedTitleFields(fields)
    const subtitleFields = getSupportedSubtitleFields(fields)
    const eyebrowFields = fields

    const primaryHighlightFields = fields

    return (
        <>
            <CoverControls header={header} fields={fields} />
            <LayoutEditorControlSeparator />
            <LayoutEditorCollapsibleControl
                label="Content"
                startIcon={{ name: 'List' }}
                defaultOpen={false}
            >
                <Box flex column gap="l">
                    <Select
                        placeholder="Select field..."
                        label="Profile image"
                        isSearchable
                        isClearable
                        value={header.profileImage?.fieldApiName}
                        onChange={(value) => {
                            commands.transaction((data) => {
                                const header = getYHeader(data)
                                header?.set('profileImage', { fieldApiName: value })
                            })
                        }}
                    >
                        {profileImageFields.map((field) => (
                            <SelectOption
                                key={field.api_name}
                                value={field.api_name}
                                label={field.label}
                                startIcon={getFieldIcon(field)}
                            />
                        ))}
                    </Select>
                    <Select
                        placeholder="Select field..."
                        label="Title"
                        isSearchable
                        value={header.title?.fieldApiName}
                        onChange={(value) => {
                            commands.transaction((data) => {
                                const header = getYHeader(data)
                                header?.set('title', { fieldApiName: value })
                            })
                        }}
                    >
                        {titleFields.map((field) => (
                            <SelectOption
                                key={field.api_name}
                                value={field.api_name}
                                label={field.label}
                                startIcon={getFieldIcon(field)}
                            />
                        ))}
                    </Select>
                    <Select
                        placeholder="Select field..."
                        label="Subtitle"
                        isSearchable
                        isClearable
                        value={header.subtitle?.fieldApiName}
                        onChange={(value) => {
                            commands.transaction((data) => {
                                const header = getYHeader(data)
                                header?.set('subtitle', { fieldApiName: value })
                            })
                        }}
                    >
                        {subtitleFields.map((field) => (
                            <SelectOption
                                key={field.api_name}
                                value={field.api_name}
                                label={field.label}
                                startIcon={getFieldIcon(field)}
                            />
                        ))}
                    </Select>
                    <Select
                        placeholder="Select field..."
                        label="Eyebrow"
                        isSearchable
                        isClearable
                        value={header.eyebrow?.fieldApiName}
                        onChange={(value) => {
                            commands.transaction((data) => {
                                const header = getYHeader(data)
                                header?.set('eyebrow', { fieldApiName: value })
                            })
                        }}
                    >
                        {eyebrowFields.map((field) => (
                            <SelectOption
                                key={field.api_name}
                                value={field.api_name}
                                label={field.label}
                                startIcon={getFieldIcon(field)}
                            />
                        ))}
                    </Select>
                    <HighlightFieldSelector
                        label="Primary highlights"
                        fields={primaryHighlightFields}
                        value={header?.primaryHighlights ?? []}
                        onChange={(value) => {
                            commands.transaction((data) => {
                                const header = getYHeader(data)
                                header?.set('primaryHighlights', toYType(value))
                            })
                        }}
                        maxSelectedItems={MAX_PRIMARY_HIGHLIGHTS}
                    />
                </Box>
            </LayoutEditorCollapsibleControl>
        </>
    )
}

type DetailViewSpaciousHeaderStyleControlsProps = {
    header: DetailViewHeaderSpacious
}

export const DetailViewSpaciousHeaderStyleControls: React.FC<
    DetailViewSpaciousHeaderStyleControlsProps
> = ({ header }) => {
    const { commands } = useLayoutEditorContext()

    return (
        <>
            <Select
                label="Top bar"
                value={header.topBarStyle ?? 'toolbar'}
                onChange={(value) => {
                    commands.transaction((data) => {
                        const header = getYHeader(data)
                        header?.set('topBarStyle', value)
                    })
                }}
            >
                <SelectOption value="toolbar" label="Toolbar" />
                <SelectOption value="breadcrumbs" label="Breadcrumbs" />
            </Select>
            <Field label="Title size">
                <RadioCardGroup
                    value={header.titleSize ?? 'medium'}
                    onValueChange={(value: string) => {
                        commands.transaction((data) => {
                            const header = getYHeader(data)
                            header?.set('titleSize', value)
                        })
                    }}
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3, 1fr)',
                    }}
                >
                    <RadioCard
                        value="small"
                        icon={() => (
                            <Box className={DetailHeaderTitleSizeControlIconStyle}>
                                <Body size="m" weight="bold">
                                    Abc
                                </Body>
                            </Box>
                        )}
                        className={DetailHeaderTitleSizeControlCardStyle}
                    >
                        Small
                    </RadioCard>
                    <RadioCard
                        value="medium"
                        icon={() => (
                            <Box className={DetailHeaderTitleSizeControlIconStyle}>
                                <Body size="l" weight="bold">
                                    Abc
                                </Body>
                            </Box>
                        )}
                        className={DetailHeaderTitleSizeControlCardStyle}
                    >
                        Medium
                    </RadioCard>
                    <RadioCard
                        value="large"
                        icon={() => (
                            <Box className={DetailHeaderTitleSizeControlIconStyle}>
                                <Body size="xl" weight="bold">
                                    Abc
                                </Body>
                            </Box>
                        )}
                        className={DetailHeaderTitleSizeControlCardStyle}
                    >
                        Large
                    </RadioCard>
                </RadioCardGroup>
            </Field>
            <Field label="Primary highlights icon">
                <RadioCardGroup
                    value={header.showPrimaryHighlightsIcon?.toString() ?? 'false'}
                    onValueChange={(value: string) => {
                        commands.transaction((data) => {
                            const header = getYHeader(data)
                            header?.set('showPrimaryHighlightsIcon', value === 'true')
                        })
                    }}
                >
                    <RadioCard value="false" icon={{ name: 'EyeOff' }}>
                        Hide
                    </RadioCard>
                    <RadioCard value="true" icon={{ name: 'Eye' }}>
                        Show
                    </RadioCard>
                </RadioCardGroup>
            </Field>
        </>
    )
}

type HighlightFieldSelectorProps = {
    fields: FieldDto[]
    value: DetailViewHeaderFieldRef[]
    onChange: (value: DetailViewHeaderFieldRef[]) => void
    maxSelectedItems?: number
    label?: string
}

const HighlightFieldSelector: React.FC<HighlightFieldSelectorProps> = ({
    fields,
    value,
    onChange,
    maxSelectedItems,
    label,
}) => {
    const fieldApiNameToSidMap = new Map(fields.map((f) => [f.api_name, f._sid]))
    const fieldSidToApiNameMap = new Map(fields.map((f) => [f._sid, f.api_name]))

    const valueSids =
        value.reduce((acc, f) => {
            const fieldSid = fieldApiNameToSidMap.get(f.fieldApiName)
            if (fieldSid) {
                acc.push(fieldSid)
            }

            return acc
        }, [] as string[]) ?? []

    return (
        <Field
            label={label}
            leftSlotContent={
                !!maxSelectedItems ? (
                    <Body color="textWeaker" weight="regular" ml="xs">
                        (max. {maxSelectedItems})
                    </Body>
                ) : undefined
            }
        >
            <LayoutEditorFieldSelector
                fields={fields}
                value={valueSids}
                maxSelectedItems={maxSelectedItems}
                onChange={(fieldSids) => {
                    const newValue = fieldSids.reduce((acc, f) => {
                        const fieldApiName = fieldSidToApiNameMap.get(f)
                        if (fieldApiName) {
                            acc.push({ fieldApiName })
                        }

                        return acc
                    }, [] as DetailViewHeaderFieldRef[])

                    onChange(newValue)
                }}
            />
        </Field>
    )
}

type CoverControlsProps = {
    header: DetailViewHeaderSpacious
    fields: FieldDto[]
}

const CoverControls: React.FC<CoverControlsProps> = ({ header, fields }) => {
    const { commands } = useLayoutEditorContext()

    const coverType = header.coverType ?? 'none'

    const coverImageFields = getSupportedCoverImageFields(fields)

    return (
        <LayoutEditorCollapsibleControl
            label="Cover"
            startIcon={{ name: 'LayoutPanelTop' }}
            defaultOpen={false}
        >
            <Box flex column gap="l">
                <Field label="Cover type">
                    <RadioCardGroup
                        value={coverType ?? 'none'}
                        onValueChange={(value: string) => {
                            commands.transaction((data) => {
                                const header = getYHeader(data)
                                header?.set('coverType', value)
                            })
                        }}
                        style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fill, 82px)',
                            gap: theme.space.l,
                        }}
                    >
                        <RadioCard
                            value="none"
                            icon={() => <CoverTypeNoneIcon isActive={coverType === 'none'} />}
                            cardIconWrapperProps={{
                                style: { padding: 0, overflow: 'hidden' },
                            }}
                        >
                            None
                        </RadioCard>
                        <RadioCard
                            value="fullWidth"
                            icon={() => (
                                <CoverTypeFullWidthIcon isActive={coverType === 'fullWidth'} />
                            )}
                            cardIconWrapperProps={{
                                style: { padding: 0, overflow: 'hidden' },
                            }}
                        >
                            Full-width
                        </RadioCard>
                        <RadioCard
                            value="boxed"
                            icon={() => <CoverTypeBoxedIcon isActive={coverType === 'boxed'} />}
                            cardIconWrapperProps={{
                                style: { padding: 0, overflow: 'hidden' },
                            }}
                        >
                            Boxed
                        </RadioCard>
                    </RadioCardGroup>
                </Field>

                {coverType !== 'none' && (
                    <>
                        <Field
                            label="Background color"
                            rightSlotContent={
                                <DetailViewColorPicker
                                    value={header.coverBackgroundColor ?? 'NeutralLight'}
                                    onChange={(value) => {
                                        commands.transaction((data) => {
                                            const header = getYHeader(data)
                                            header?.set('coverBackgroundColor', value)
                                        })
                                    }}
                                />
                            }
                        />
                        <DetailViewImageFieldPicker
                            label="Background image"
                            fields={coverImageFields}
                            value={header.coverImage}
                            onChange={(value) => {
                                commands.transaction((data) => {
                                    const header = getYHeader(data)
                                    header?.set('coverImage', toYType(value))
                                })
                            }}
                        />
                    </>
                )}
            </Box>
        </LayoutEditorCollapsibleControl>
    )
}
type CoverTypeIconProps = {
    isActive: boolean
}

const CoverTypeNoneIcon: React.FC<CoverTypeIconProps> = ({ isActive }) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 82 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="8.19531"
                y="6"
                width="29"
                height="5"
                style={{
                    transition: theme.transition.colors,
                    fill: isActive ? theme.color.theme600 : theme.color.gray200,
                }}
            />
            <rect
                x="65.1953"
                y="6"
                width="11"
                height="5"
                style={{
                    transition: theme.transition.colors,
                    fill: isActive ? theme.color.theme600 : theme.color.gray200,
                }}
            />
            <rect
                x="8.66674"
                y="14.4714"
                width="67.5142"
                height="17.9143"
                rx="1.41429"
                style={{
                    stroke: theme.color.gray200,
                }}
                strokeWidth="0.942857"
            />
            <rect
                x="8.66674"
                y="36.3284"
                width="67.5142"
                height="17.9143"
                rx="1.41429"
                style={{
                    stroke: theme.color.gray200,
                }}
                strokeWidth="0.942857"
            />
        </svg>
    )
}

const CoverTypeFullWidthIcon: React.FC<CoverTypeIconProps> = ({ isActive }) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 82 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0"
                y="0"
                width="82"
                height="18"
                style={{
                    transition: theme.transition.colors,
                    fill: isActive ? theme.color.theme600 : theme.color.gray200,
                }}
            />
            <rect
                x="8.52734"
                y="23.8857"
                width="26.4"
                height="3.77143"
                style={{
                    transition: theme.transition.colors,
                    fill: isActive ? theme.color.theme600 : theme.color.gray200,
                }}
            />
            <rect
                opacity="0.6"
                x="66.9844"
                y="23.3999"
                width="9"
                height="4"
                style={{
                    transition: theme.transition.colors,
                    fill: isActive ? theme.color.theme600 : theme.color.gray200,
                }}
            />
            <rect
                x="8.99877"
                y="32.3855"
                width="66.9429"
                height="17.9143"
                rx="1.41429"
                style={{
                    stroke: theme.color.gray200,
                }}
                strokeWidth="0.942857"
            />
            <rect
                x="8.99877"
                y="55.9573"
                width="66.9429"
                height="17.9143"
                rx="1.41429"
                style={{
                    stroke: theme.color.gray200,
                }}
                strokeWidth="0.942857"
            />
        </svg>
    )
}

const CoverTypeBoxedIcon: React.FC<CoverTypeIconProps> = ({ isActive }) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 82 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M8.29297 9C8.29297 7.34315 9.63611 6 11.293 6H71.293C72.9498 6 74.293 7.34315 74.293 9V21C74.293 22.6569 72.9498 24 71.293 24H11.293C9.63612 24 8.29297 22.6569 8.29297 21V9Z"
                style={{
                    transition: theme.transition.colors,
                    fill: isActive ? theme.color.theme600 : theme.color.gray200,
                }}
            />
            <rect
                x="7.86328"
                y="28.8857"
                width="26.4"
                height="3.77143"
                style={{
                    transition: theme.transition.colors,
                    fill: isActive ? theme.color.theme600 : theme.color.gray200,
                }}
            />
            <rect
                opacity="0.6"
                x="66.3203"
                y="28.3999"
                width="9"
                height="4"
                style={{
                    transition: theme.transition.colors,
                    fill: isActive ? theme.color.theme600 : theme.color.gray200,
                }}
            />
            <rect
                x="8.33471"
                y="37.3855"
                width="66.9429"
                height="17.9143"
                rx="1.41429"
                style={{
                    stroke: theme.color.gray200,
                }}
                strokeWidth="0.942857"
            />
        </svg>
    )
}

type DetailViewImageFieldPickerProps = {
    label: string
    fields: FieldDto[]
    value?: DetailViewHeaderFieldRef | DetailViewHeaderFileRef
    onChange: (value?: DetailViewHeaderFieldRef | DetailViewHeaderFileRef) => void
}

const DetailViewImageFieldPicker: React.FC<DetailViewImageFieldPickerProps> = ({
    label,
    fields,
    value,
    onChange,
}) => {
    let selectedValue = ''
    if (value?.type === 'field') {
        selectedValue = value.fieldApiName
    } else if (value?.type === 'file') {
        selectedValue = value.url
    }

    const onUploadSuccess = useCallback(
        (value: PickerResponse) => {
            const file = value.filesUploaded[0]
            if (!file) return

            const src = file.url ?? ''
            const filename = file.filename ?? file.url

            onChange({
                type: 'file',
                url: src,
                filename,
            })
        },
        [onChange]
    )

    return (
        <ReactFilestack
            options={{
                maxFiles: 1,
                accept: 'image/*',
            }}
            onSuccess={onUploadSuccess}
            customRender={({ onPick }: { onPick: (e: React.MouseEvent) => void }) => (
                <Select
                    label={label}
                    value={selectedValue}
                    onChange={(value) => {
                        if (value) {
                            onChange({
                                type: 'field',
                                fieldApiName: value,
                            })
                        } else {
                            onChange(undefined)
                        }
                    }}
                    placeholder="Select field or upload image..."
                    isSearchable
                    isClearable
                    startAvatar={
                        value?.type === 'file'
                            ? {
                                  type: 'image',
                                  imageUrl: value.url,
                              }
                            : undefined
                    }
                >
                    {value?.type === 'file' && (
                        <SelectOption
                            value={value.url}
                            label={value.filename}
                            startAvatar={{
                                type: 'image',
                                imageUrl: value.url,
                            }}
                        />
                    )}
                    {fields.map((field) => (
                        <SelectOption
                            key={field.api_name}
                            value={field.api_name}
                            label={field.label}
                            startIcon={getFieldIcon(field)}
                        />
                    ))}

                    <DropdownFooterItem
                        variant="action"
                        label="Upload image"
                        startIcon={{ name: 'Upload' }}
                        onClick={(e) => {
                            // Allow for blur events to fire before opening the file picker.
                            requestAnimationFrame(() => {
                                onPick(e)
                            })
                        }}
                    />
                </Select>
            )}
        />
    )
}

type DetailViewColorPickerProps = {
    value?: string
    onChange: (value?: string) => void
}

const DetailViewColorPicker: React.FC<DetailViewColorPickerProps> = ({ value, onChange }) => {
    const defaultedValue = value ?? 'NeutralLight'

    return (
        <Dropdown>
            <DropdownTrigger
                className={TriggerStyles.styleFunction({ size: 'm' })}
                style={{
                    width: 'auto',
                }}
                asChild
            >
                <Box flex center gap="xs" role="button">
                    <ColorItem value={defaultedValue} />
                    <Icon size="m" name="ChevronDown" />
                </Box>
            </DropdownTrigger>
            <DropdownContent align="end" onClick={stopPropagation} width="200px">
                <DropdownTitle>Colors</DropdownTitle>
                <Box
                    as={RadioGroupPrimitive.Root}
                    px="xl"
                    flex
                    wrap
                    gap="s"
                    value={defaultedValue}
                    onValueChange={onChange}
                >
                    {Object.keys(HEADER_BACKGROUND_COLORS).map((colorName) => (
                        <Box as={RadioGroupPrimitive.Item} key={colorName} value={colorName}>
                            <ColorItem
                                value={colorName}
                                isSelected={defaultedValue === colorName}
                            />
                        </Box>
                    ))}
                </Box>
            </DropdownContent>
        </Dropdown>
    )
}

type ColorItemProps = React.ComponentPropsWithoutRef<typeof Box> & {
    value: string
}

const ColorItem: React.FC<ColorItemProps> = ({ value, isSelected, ...props }) => {
    const isDark = value.includes('Dark')
    const colorVariable =
        HEADER_BACKGROUND_COLORS[value as keyof typeof HEADER_BACKGROUND_COLORS] ??
        theme.color.surface

    return (
        <Box
            rounded="xs"
            borderWidth={1}
            borderColor="divider"
            borderStyle="base"
            flex
            center
            justifyContent="center"
            style={{
                width: '18px',
                height: '18px',
                background: colorVariable,
                transition: theme.transition.colors,
            }}
            {...props}
        >
            <Icon
                size="s"
                name="Check"
                color={isDark ? 'iconInverse' : 'icon'}
                style={{
                    opacity: isSelected ? 1 : 0,
                }}
                pointerEvents="none"
            />
        </Box>
    )
}
