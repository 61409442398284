import React from 'react'

import { Box } from 'ui/components/Box'
import {
    Dropdown,
    DropdownButton,
    DropdownContent,
    DropdownHeadSearch,
    DropdownItem,
} from 'ui/components/Dropdown'
import { Tag } from 'ui/components/Tag'
import { stopPropagation } from 'ui/helpers/utilities'

import { useLayoutEditorFieldSelectorState } from './hooks/useLayoutEditorFieldSelectorState'

type LayoutEditorFieldSelectorProps = {
    fields: FieldDto[]
    value: string[]
    onChange: (value: string[]) => void
    maxLabelLength?: number
    maxSelectedItems?: number
}

export const LayoutEditorFieldSelector: React.FC<LayoutEditorFieldSelectorProps> = ({
    fields,
    value,
    onChange,
    maxLabelLength,
    maxSelectedItems,
}) => {
    const { fieldOptions, onCheckedChange, valueSet, selectedOptions, showAddButton } =
        useLayoutEditorFieldSelectorState({
            fields,
            value,
            onChange,
            maxLength: maxLabelLength,
            maxSelectedItems,
        })

    return (
        <Box flex center wrap gap="xs">
            {selectedOptions.map((option) => (
                <Tag
                    key={option.value}
                    size="xs"
                    type="solid"
                    shape="rectangle"
                    color="Neutral"
                    shade="light"
                    onRemove={() => onCheckedChange(option.value, false)}
                    showRemoveButton
                    maxLength={maxLabelLength}
                >
                    {option.label}
                </Tag>
            ))}
            {showAddButton && (
                <Dropdown>
                    <DropdownButton
                        startIcon={{ name: 'Plus' }}
                        variant="dotted"
                        size="xs"
                        aria-label="Select recommended fields"
                    />
                    <DropdownContent
                        side="bottom"
                        align="end"
                        onClick={stopPropagation}
                        head={<DropdownHeadSearch />}
                    >
                        {fieldOptions.map((option) => (
                            <DropdownItem
                                key={option.value}
                                label={option.label}
                                checked={valueSet.has(option.value)}
                                onCheckedChange={(checked) =>
                                    onCheckedChange(option.value, checked)
                                }
                                startIcon={option.icon}
                                closeOnSelect={true}
                                multiSelect
                            />
                        ))}
                    </DropdownContent>
                </Dropdown>
            )}
        </Box>
    )
}
