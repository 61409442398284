import React from 'react'

import { LongTextAttributeDisplay } from 'features/views/attributes/LongTextAttributeDisplay'
import { LongTextValue } from 'features/views/attributes/LongTextValue'
import { RichTextAttributeDisplay } from 'features/views/attributes/RichTextAttributeDisplay'
import { TextAttributeDisplay } from 'features/views/attributes/TextAttributeDisplay'
import { RichTextValue } from 'features/views/attributes/types'

import { Body } from 'ui/components/Text'

import { useDetailViewSubtitleAttributeState } from './hooks/useDetailViewSubtitleAttributeState'

const MAX_LINES = 3

type DetailViewSubtitleAttributeProps = Pick<
    React.ComponentProps<typeof Body>,
    'style' | 'className' | 'size' | 'color' | 'weight' | 'mt'
> & {
    isLoading?: boolean
}

export const DetailViewSubtitleAttribute: React.FC<DetailViewSubtitleAttributeProps> = React.memo(
    function DetailViewSubtitleAttribute({ style, size = 'm', mt, ...props }) {
        const { field, value } = useDetailViewSubtitleAttributeState()

        if (!field || !value) {
            return null
        }

        switch (field.type) {
            case 'string':
                return (
                    <LongTextValue
                        mt={mt}
                        textValue={value as string}
                        maxLines={MAX_LINES}
                        renderMoreLink={(renderOpts) => <MoreLink size={size} {...renderOpts} />}
                    >
                        <TextAttributeDisplay
                            key={field._sid}
                            field={field}
                            value={value as string}
                            style={{
                                ...style,
                                wordBreak: 'break-word',
                                whiteSpace: 'pre-wrap',
                            }}
                            size={size}
                            {...props}
                        />
                    </LongTextValue>
                )

            case 'long_text':
                return (
                    <LongTextValue
                        mt={mt}
                        textValue={value as string}
                        maxLines={MAX_LINES}
                        renderMoreLink={(renderOpts) => <MoreLink size={size} {...renderOpts} />}
                    >
                        <LongTextAttributeDisplay
                            key={field._sid}
                            field={field}
                            value={value as string}
                            style={{
                                ...style,
                                wordBreak: 'break-word',
                                whiteSpace: 'pre-wrap',
                            }}
                            size={size}
                            {...props}
                        />
                    </LongTextValue>
                )

            case 'document':
                return (
                    <LongTextValue
                        mt={mt}
                        textValue={(value as RichTextValue).plainTextContent}
                        maxLines={MAX_LINES}
                        renderMoreLink={(renderOpts) => <MoreLink size={size} {...renderOpts} />}
                    >
                        <RichTextAttributeDisplay
                            key={field._sid}
                            field={field}
                            value={value as RichTextValue}
                            style={{
                                ...style,
                                wordBreak: 'break-word',
                                whiteSpace: 'pre-wrap',
                            }}
                            size={size}
                            {...props}
                        />
                    </LongTextValue>
                )

            default:
                return null
        }
    }
)

type MoreLinkProps = {
    size: React.ComponentProps<typeof Body>['size']
    showFullText: boolean
    onClick: React.MouseEventHandler<HTMLElement>
}

const MoreLink: React.FC<MoreLinkProps> = ({ size, showFullText, onClick }) => {
    return (
        <Body
            display="block"
            mt="3xs"
            size={size}
            color="text"
            weight="bold"
            role="button"
            onClick={onClick}
        >
            {showFullText ? 'Show less' : 'Show more'}
        </Body>
    )
}
