import React from 'react'

import { useAppContext } from 'app/useAppContext'
import { useAgents } from 'data/hooks/agents/agents'
import { useAgentSkills } from 'data/hooks/agents/skills'
import { ConfigurationLabel } from 'features/workflows/WorkflowUI'

import { Box } from 'ui/components/Box'
import { Select, SelectOption } from 'ui/components/Select'

import { WorkflowActionEditorComponent } from './types'

export const InvokeAgentSkillActionConfig: WorkflowActionEditorComponent = ({
    action,
    onChange,
}) => {
    const { selectedStack } = useAppContext()
    const { data: agents } = useAgents()
    const selectedAgent = agents?.find((agent) => agent._sid === action.settings?.agent_id)
    const { data: skills } = useAgentSkills(selectedAgent?._sid || '')

    return (
        <>
            <ConfigurationLabel>Agent</ConfigurationLabel>
            <Select
                value={action.settings?.agent_id as string | undefined}
                onChange={(value) => {
                    onChange({
                        settings: {
                            ...action.settings,
                            agent_id: value,
                            stack_id: selectedStack?._sid,
                            skill_id: undefined, // Clear skill when agent changes
                        },
                    })
                }}
                isClearable={false}
            >
                {agents?.map((agent) => (
                    <SelectOption key={agent._sid} value={agent._sid} label={agent.title} />
                ))}
            </Select>

            {selectedAgent && (
                <>
                    <Box mt="m">
                        <ConfigurationLabel>Skill</ConfigurationLabel>
                        <Select
                            value={action.settings?.skill_id as string | undefined}
                            onChange={(value) => {
                                onChange({
                                    settings: {
                                        ...action.settings,
                                        skill_id: value,
                                    },
                                })
                            }}
                            isClearable={false}
                        >
                            {skills?.map((skill) => (
                                <SelectOption
                                    key={skill._sid}
                                    value={skill._sid}
                                    label={skill.name}
                                />
                            ))}
                        </Select>
                    </Box>
                </>
            )}
        </>
    )
}
