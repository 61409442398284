import React from 'react'
import Markdown from 'react-markdown'

import { AgentInstructions } from 'data/hooks/agents/types'

import { QueryResultRenderer } from './renderers/QueryResult'
import { StackerRecordRenderer } from './renderers/StackerRecord'

const renderers = {
    stackerRecord: StackerRecordRenderer,
    queryResult: QueryResultRenderer,
}

export function processAgentMessage(
    message: string,
    instructions: AgentInstructions
): React.ReactNode[] {
    // Process the agent message to render any <Render> tags with custom renderers
    const parts: React.ReactNode[] = []
    let lastIndex = 0
    const regex = /<Render(?:\s?type="([^"]+)")?\s*>(.*?)<\/Render>/gs

    let match
    while ((match = regex.exec(message)) !== null) {
        // Add text before the match if any
        if (match.index > lastIndex) {
            parts.push(<Markdown>{message.substring(lastIndex, match.index)}</Markdown>)
        }

        // Add the Render component
        const [_, type, content] = match
        const Renderer = renderers[type as keyof typeof renderers]
        if (Renderer) {
            parts.push(<Renderer data={content} instructions={instructions} />)
        } else {
            parts.push(<Markdown>{content}</Markdown>)
        }

        lastIndex = regex.lastIndex
    }

    // Add remaining text after last match
    if (lastIndex < message.length) {
        parts.push(<Markdown>{message.substring(lastIndex)}</Markdown>)
    }

    return parts
}
