import { UseQueryOptions } from 'react-query'

import { useQuery } from 'data/hooks/_helpers'
import { getIntegrationRequiresDb } from 'features/DataConnections/getIntegrationRequiresDb'

export const EXTERNAL_OBJECTS_KEY = 'EXTERNAL_OBJECTS_KEY'

type ExternalObject = {
    id: string
    name: string
}

type useExternalObjectsInput = {
    integrationId: ExternalIntegrationId
    nangoConnectionId: string
    externalDatabaseId: string
}
export function useExternalObjects(
    { nangoConnectionId, integrationId, externalDatabaseId }: useExternalObjectsInput,
    options: UseQueryOptions<ExternalObject[]>
) {
    const requiresDatabase = getIntegrationRequiresDb(integrationId)
    if (!requiresDatabase) {
        // if the integration doesn't support/require external DB, the integration id is used as the external db id
        externalDatabaseId = integrationId
    }

    return useQuery<ExternalObject[]>(
        [EXTERNAL_OBJECTS_KEY, integrationId, nangoConnectionId, externalDatabaseId],
        `data-connections/external-objects/?nango_connection_id=${nangoConnectionId}&integration_id=${integrationId}&external_database_id=${externalDatabaseId}`,
        {
            ...options,
            enabled: !!nangoConnectionId && !!integrationId && !!externalDatabaseId,
        },
        {
            bypassQueryDeps: true,
        }
    )
}
