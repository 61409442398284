import React, { useCallback, useState } from 'react'

import { getFieldIcon } from 'features/admin/fields/icons/utils'
import { ListViewControlItem } from 'features/views/ListView/ListViewControlItem'

import useDebounce from 'v2/ui/utils/useDebounce'

import { Box } from 'ui/components/Box'
import { DropdownItemTag } from 'ui/components/Dropdown'
import { Field, InfoMark } from 'ui/components/Field'
import { Input } from 'ui/components/Input'
import { RadioCard, RadioCardGroup } from 'ui/components/Radio'
import { Select, SelectOption } from 'ui/components/Select'
import { Toggle } from 'ui/components/Toggle'

import { getSenderFieldValueOptions, getSupportedSenderFields } from './utils'

type ThreadViewLayoutControlsProps = {
    objectSid: string
    setConfig: (config: Partial<ListViewOptions>) => void
    config: ListViewOptions
    fields: FieldDto[]
}

type SenderFieldValue = boolean | string | string[]

export const ThreadViewLayoutControls: React.FC<ThreadViewLayoutControlsProps> = ({
    objectSid,
    fields,
    config,
    setConfig,
}) => {
    return (
        <ListViewControlItem label="Conversation layout" icon="AlignVerticalJustifyCenter">
            <ThreadViewLayoutGenericControls
                width="300px"
                objectSid={objectSid}
                fields={fields}
                config={config}
                setConfig={setConfig}
            />
        </ListViewControlItem>
    )
}

type ThreadViewLayoutGenericControlsProps = React.ComponentPropsWithoutRef<typeof Box> &
    ThreadViewLayoutControlsProps

export const ThreadViewLayoutGenericControls: React.FC<ThreadViewLayoutGenericControlsProps> = ({
    objectSid,
    fields,
    config,
    setConfig,
    ...props
}) => {
    return (
        <Box flex flexDirection="column" gap="l" {...props}>
            <RadioCardGroup
                value={config.threadLayout ?? 'linear'}
                onValueChange={(value) => {
                    setConfig({
                        threadLayout: value as 'linear' | 'split',
                    })
                }}
            >
                <RadioCard value="linear" icon={{ name: 'MessageSquare' }}>
                    Linear
                </RadioCard>
                <RadioCard value="split" icon={{ name: 'MessagesSquare' }}>
                    Split
                </RadioCard>
            </RadioCardGroup>
            {config.threadLayout === 'split' && (
                <SenderControls
                    objectSid={objectSid}
                    config={config}
                    setConfig={setConfig}
                    fields={fields}
                />
            )}
            <Field
                htmlFor="showDayDivider"
                label="Show day divider"
                leftSlotContent={
                    <InfoMark size="xs" zIndex={1500}>
                        Display divider between days when sorting by date
                    </InfoMark>
                }
                rightSlotContent={
                    <Toggle
                        id="showDayDivider"
                        checked={!!config.threadShowDayDivider}
                        onCheckedChange={(value) => {
                            setConfig({
                                threadShowDayDivider: value,
                            })
                        }}
                    />
                }
            />
        </Box>
    )
}

const SenderControls: React.FC<ThreadViewLayoutControlsProps> = ({ config, setConfig, fields }) => {
    const senderFields = getSupportedSenderFields(fields)

    let senderFieldSid = config.threadSender?.fieldSid ?? undefined
    // If the field is not in the list of supported fields, reset it.
    const senderField = senderFields.find((field) => field._sid === senderFieldSid)
    if (!senderField) {
        senderFieldSid = undefined
    }

    const onChangeFieldSid = useCallback(
        (value?: string) => {
            const newValue: ListViewOptions['threadSender'] = value
                ? {
                      fieldSid: value,
                      value: undefined,
                  }
                : undefined

            setConfig({
                threadSender: newValue,
            })
        },
        [setConfig]
    )

    const senderFieldValue = config.threadSender?.value

    const onChangeValue = useCallback(
        (value?: SenderFieldValue) => {
            setConfig({
                threadSender: {
                    fieldSid: senderFieldSid!,
                    value,
                },
            })
        },
        [senderFieldSid, setConfig]
    )

    return (
        <>
            <Select
                label="Decide sender based on"
                size="m"
                placeholder="Select field..."
                value={senderFieldSid}
                isSearchable
                isClearable
                onChange={onChangeFieldSid}
            >
                {senderFields.map((field) => (
                    <SelectOption
                        key={field._sid}
                        value={field._sid}
                        label={field.label}
                        startIcon={getFieldIcon(field)}
                    />
                ))}
            </Select>
            {!!senderField && (
                <SenderFieldValue
                    key={senderField._sid}
                    field={senderField}
                    value={senderFieldValue}
                    onChange={onChangeValue}
                />
            )}
        </>
    )
}

const DEBOUNCE_RATE = 300 // ms

type SenderFieldValueProps = {
    field: FieldDto
    value?: SenderFieldValue
    onChange: (value?: SenderFieldValue) => void
}

export const SenderFieldValue: React.FC<SenderFieldValueProps> = ({ field, value, onChange }) => {
    const [localValue, setLocalValue] = useState(value)

    const debouncedOnChange = useDebounce(onChange, DEBOUNCE_RATE) as typeof onChange

    const handleChange = useCallback(
        (value: string) => {
            if (field.type === 'checkbox') {
                onChange(value === 'true')
            } else {
                onChange(value)
            }
        },
        [field.type, onChange]
    )

    if (['string', 'long_text', 'url'].includes(field.type)) {
        return (
            <Input
                label="Field value equal to"
                value={localValue?.toString()}
                onChange={(e) => {
                    const newValue = e.target.value
                    setLocalValue(newValue)
                    debouncedOnChange(newValue)
                }}
            />
        )
    }

    const options = getSenderFieldValueOptions(field)

    return (
        <Select
            label="Field value"
            size="m"
            placeholder="Select value..."
            value={typeof value === 'boolean' ? value.toString() : value}
            isSearchable
            isClearable
            onChange={handleChange}
            multiSelect={field.type === 'multi_select'}
        >
            {options.map((option) => (
                <SelectOption
                    as={
                        !!option.color
                            ? (props: React.ComponentProps<typeof DropdownItemTag>) => {
                                  return <DropdownItemTag {...props} color={option.color} />
                              }
                            : undefined
                    }
                    key={option.value}
                    value={option.value}
                    label={option.label}
                    color={option.color}
                />
            ))}
        </Select>
    )
}
