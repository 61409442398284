import { useCallback, useEffect, useMemo, useState } from 'react'

import { PipelineWidgetType } from 'features/views/LayoutEditor/widgets/PipelineWidget/types'
import { useRecordManagerContext } from 'features/views/RecordManager/useRecordManagerContext'

import { usePipelineWidgetStages } from './usePipelineWidgetStages'

const LOADING_SLOW_THRESHOLD_TIMEOUT = 2000

export function usePipelineWidgetItemListState(widget: PipelineWidgetType) {
    const { activeStages } = usePipelineWidgetStages(widget)

    const { record, replaceAttribute, saveChanges, isFetchingSlow, permissions } =
        useRecordManagerContext()

    const fieldApiName = widget.attrs.field?.fieldApiName ?? ''
    const currentValue = record?.[fieldApiName] ?? undefined

    const currentStageIdx = activeStages.findIndex((stage) => stage.stage.id === currentValue)

    const [isSaving, setIsSaving] = useState(false)
    const onStageClick = useCallback(
        (stageId: string) => {
            setIsSaving(true)
            replaceAttribute(fieldApiName!, stageId as any)
            queueMicrotask(async () => {
                await saveChanges()
                setIsSaving(false)
            })
        },
        [replaceAttribute, fieldApiName, saveChanges]
    )

    const [isSavingSlow, setIsSavingSlow] = useState(false)
    useEffect(() => {
        let timer: number

        if (isSaving) {
            timer = window.setTimeout(() => {
                setIsSavingSlow(true)
            }, LOADING_SLOW_THRESHOLD_TIMEOUT)
        } else {
            setIsSavingSlow(false)
        }

        return () => {
            window.clearTimeout(timer)
        }
    }, [isSaving])

    const canReadField = permissions.canReadFields.includes(fieldApiName)
    const canUpdateField = permissions.canUpdateFields.includes(fieldApiName)

    const isReadOnly = widget.attrs.isReadOnly || !canUpdateField

    const isVisible = canReadField

    const isLoading = isFetchingSlow

    return useMemo(
        () => ({
            isVisible,
            items: activeStages,
            currentStageIdx,
            onStageClick,
            isReadOnly,
            isLoading,
            isSavingSlow,
        }),
        [
            activeStages,
            currentStageIdx,
            onStageClick,
            isReadOnly,
            isLoading,
            isSavingSlow,
            isVisible,
        ]
    )
}
