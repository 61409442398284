import { useCallback, useMemo } from 'react'

import { useActionButton } from 'features/actions/hooks/useActionButton'
import { useRecordManagerContext } from 'features/views/RecordManager/useRecordManagerContext'

type UseSectionWidgetActionButtonStateProps = {
    action: ActionDto
}

export function useSectionWidgetActionButtonState({
    action,
}: UseSectionWidgetActionButtonStateProps) {
    const { includeFields = [], record } = useRecordManagerContext()!

    const { executeAction } = useActionButton({
        action,
        recordId: record?._sid!,
        record: record!,
        includeFields,
    })

    const handleClick = useCallback(
        (e?: React.MouseEvent<HTMLElement>) => {
            e?.stopPropagation()
            e?.preventDefault()

            executeAction()
        },
        [executeAction]
    )

    return useMemo(
        () => ({
            handleClick,
        }),
        [handleClick]
    )
}
