import { useMemo } from 'react'

import { extractPartsFromUserName } from 'features/views/attributes/utils'

import { useThreadViewContext } from './useThreadViewContext'

type ThreadViewMessageProfileImageStateProps = {
    record?: RecordDto
    isFromSender?: boolean
}

export function useThreadViewMessageProfileImageState({
    record,
    isFromSender,
}: ThreadViewMessageProfileImageStateProps) {
    const { profileImageField, titleField, view } = useThreadViewContext()

    const hasField = !!profileImageField

    const fieldValue = hasField ? record?.[profileImageField?.api_name!] : undefined
    const imageSrc = hasField ? extractSrcFromFieldValue(profileImageField, fieldValue) : undefined

    const recordTitleValue = record?.[titleField?.api_name!] ?? undefined
    const recordTitle =
        !!recordTitleValue && typeof recordTitleValue === 'object'
            ? recordTitleValue.plainTextContent
            : recordTitleValue?.toString()

    const isSplitLayout = view.options?.threadLayout === 'split'
    const isHiddenInSplitLayout = isFromSender
        ? view.options?.threadHideSenderProfileImage
        : view.options?.threadHideReceiverProfileImage

    let hasThumbnail = hasField
    if (isSplitLayout && isHiddenInSplitLayout) {
        hasThumbnail = false
    }

    const { firstName, lastName } = extractPartsFromUserName(recordTitle)

    return useMemo(
        () => ({
            hasThumbnail,
            imageSrc,
            firstName,
            lastName,
        }),
        [imageSrc, hasThumbnail, firstName, lastName]
    )
}

function extractSrcFromFieldValue(field: FieldDto, value: unknown) {
    // Extract the URL from the attachment value.
    if (field.type === 'multi_file') {
        if (Array.isArray(value)) {
            return value[0]?.url
        } else if (value && typeof value === 'object') {
            return (value as any).url
        }
    } else if (field.type === 'url') {
        return value as string
    }

    return undefined
}
