import { useDataGridContext } from 'features/datagrid/components/useDataGridContext'
import { AddNewHeaderStyle } from 'features/datagrid/headers/AddNewHeader.css'

import { useIsGridElementInViewport } from './useIsGridElementInViewport'

export function useIsAddNewFieldPanelVisible(
    wrapperElement: HTMLElement | null,
    object: ObjectDto
) {
    const { isTrashMode } = useDataGridContext()

    const isNewFieldButtonInViewport = useIsGridElementInViewport(
        () => {
            return (wrapperElement?.querySelector(`.${AddNewHeaderStyle}`) as HTMLElement) ?? null
        },
        () => {
            return (wrapperElement?.querySelector('.ag-header-viewport') as HTMLElement) ?? null
        }
    )

    return !isNewFieldButtonInViewport && !isTrashMode && !object.is_schema_read_only
}
