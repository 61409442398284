import React, { useCallback, useState } from 'react'

import moment from 'moment-timezone'

import { invalidateDataConnections } from 'data/hooks/dataConnections/useDataConnections'
import { invalidateGetWebhooks, useGetWebhooks } from 'data/hooks/dataConnections/useGetWebhooks'
import { useReauthenticateConnection } from 'data/hooks/dataConnections/useReauthenticateConnection'
import { useRepairWebhooks } from 'data/hooks/dataConnections/useRepairWebhooks'
import { useSyncConnection } from 'data/hooks/dataConnections/useSyncConnection'
import { DC_TYPE_TO_INTEGRATION_ID, TRANSLATIONS } from 'features/DataConnections/constants'
import { AirtableOauthConfirmation } from 'features/DataConnections/DataConnectionDetailModal/AirtableOauthConfirmation'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Modal, ModalContent } from 'ui/components/Modal'
import { Spinner } from 'ui/components/Spinner'
import { Body } from 'ui/components/Text'
import { useToast } from 'ui/components/Toast'
import { theme } from 'ui/styling/Theme.css'

import { calculateSyncState } from './calculateSyncState'
import { calculateWebhookState } from './calculateWebhooksState'
import { DataConnectionSyncDetailPopup } from './DataConnectionSyncDetailPopup'

type DataConnectionSyncInfoProps = {
    dataConnections: DataConnectionDto[]
    nangoConnectionId: string
    doNotShowErrorButtons?: boolean
    prefix?: string
    style?: React.CSSProperties
}
/**
 * Render sync info for given data connection(s).
 *
 * This component also displays authentication/authorization error states and buttons to fix those issue, as well as
 * checking the webhook statuses and showing a button for fixing the webhook where appropriate.
 *
 * More than one data connection can be provided, in which case the aggregate status of the data connections is
 * rendered. All given DCs are expected to belong to the same nango connection
 * If any of the data connections is syncing we set the whole group as syncing
 * Otherwise last synced time is the max/latest time of the data connections sync completed
 *
 *
 * @param dataConnections DataConnections to aggregated show sync info for
 * @param nangoConnectionId id of the nango connection which all the DCs given belong to
 * @param doNotShowErrorButtons Whether buttons should be shown next to error states if some buttons could be shown
 *         (e.g. re-authenticate button for authn/authz error or repair one for webhook issues)
 * @param prefix Optional prefix to add before the sync info when there's no ongoing sync
 * @param style Optional style applied to container of rendered component
 */
export const DataConnectionSyncInfo: React.FC<DataConnectionSyncInfoProps> = ({
    dataConnections,
    nangoConnectionId,
    doNotShowErrorButtons,
    prefix,
    style,
}) => {
    const [showOauthConfirmation, setShowOauthConfirmation] = useState<boolean>(false)
    const [showSyncPopup, setShowSyncPopup] = useState<boolean>(false)

    const { reauthenticateConnection, isCreatingReconnectSessionToken } =
        useReauthenticateConnection()
    const integrationId = DC_TYPE_TO_INTEGRATION_ID[dataConnections[0]?.type]
    const toast = useToast()
    const { data: webhooks } = useGetWebhooks(nangoConnectionId, integrationId)
    const { mutateAsync: repairWebhooks, isLoading: isRepairingWebhooks } = useRepairWebhooks({
        onError: () => {
            toast({
                title: 'There was a problem repairing connections. Please try again later or contact support.',
                type: 'error',
            })
        },
    })
    const { mutateAsync: syncConnection, isLoading: isSyncingConnection } = useSyncConnection({
        onError: () => {
            toast({
                title: 'There was a problem triggering sync for the connection. Please try again later.',
                type: 'error',
            })
        },
    })

    const { hasAuthError, isSyncInProgress, lastSyncTime, earliestStatus } =
        calculateSyncState(dataConnections)

    const { hasWebhookError } = calculateWebhookState(webhooks ?? [])

    const handleReauthenticateConfirmed = useCallback(async () => {
        await reauthenticateConnection({
            nangoConnectionId,
            integrationId,
        })
        // we automatically attempt to repair the webhooks after fixing authn/authz issues
        // the endpoint checks the webhooks status automatically and this becomes a noop if the
        // webhook are already valid
        await repairWebhooks({
            nangoConnectionId,
            integrationId,
        })
        // trigger a sync immediately as the DC status will be updated as part of a sync and to get fresh data
        await syncConnection({
            nangoConnectionId,
            dataConnectionType: dataConnections[0]?.type,
        })
        await invalidateDataConnections()
        await invalidateGetWebhooks()
    }, [
        reauthenticateConnection,
        repairWebhooks,
        syncConnection,
        nangoConnectionId,
        integrationId,
        dataConnections,
    ])

    const handleReauthenticate = useCallback(async () => {
        if (integrationId === 'airtable') {
            setShowOauthConfirmation(true)
        } else {
            await handleReauthenticateConfirmed()
        }
    }, [integrationId, setShowOauthConfirmation, handleReauthenticateConfirmed])

    if (showOauthConfirmation) {
        return (
            <Modal open={showOauthConfirmation} onOpenChange={setShowOauthConfirmation}>
                <ModalContent>
                    <AirtableOauthConfirmation
                        handleConfirmed={async () => {
                            setShowOauthConfirmation(false)
                            await handleReauthenticateConfirmed()
                        }}
                        handleClose={() => setShowOauthConfirmation(false)}
                    />
                </ModalContent>
            </Modal>
        )
    }

    if (hasAuthError) {
        if (['AUTHENTICATION_ERROR', 'AUTHORIZATION_ERROR'].includes(earliestStatus)) {
            return (
                <Box
                    flex
                    style={{
                        alignItems: 'center',
                    }}
                >
                    <Body
                        size="s"
                        style={{
                            marginRight: theme.space.m,
                            color: theme.color.textError,
                        }}
                    >
                        {earliestStatus === 'AUTHENTICATION_ERROR'
                            ? `${TRANSLATIONS[integrationId].Database} disconnected`
                            : `${TRANSLATIONS[integrationId].Database} inaccessible`}
                    </Body>
                    {!doNotShowErrorButtons && (
                        <Button
                            variant="secondary"
                            size="xs"
                            startIcon={{ name: 'RefreshCcw' }}
                            isLoading={
                                isCreatingReconnectSessionToken ||
                                isRepairingWebhooks ||
                                isSyncingConnection
                            }
                            onClick={handleReauthenticate}
                        >
                            Re-authenticate
                        </Button>
                    )}
                </Box>
            )
        } else {
            // error must be unknown
            return (
                <Body
                    size="s"
                    style={{
                        marginRight: theme.space.m,
                        color: theme.color.textError,
                    }}
                >
                    Unexpected error. Please reach out to support.
                </Body>
            )
        }
    }
    if (!hasAuthError && hasWebhookError) {
        return (
            <Box
                flex
                style={{
                    alignItems: 'center',
                    // large margin to align the repair button with the column
                    marginLeft: theme.space['4xl'],
                    ...style,
                }}
            >
                <Body
                    size="s"
                    style={{
                        marginRight: theme.space.m,
                        color: theme.color.textError,
                    }}
                >
                    Record updates paused
                </Body>
                {!doNotShowErrorButtons && (
                    <Button
                        variant="secondary"
                        size="xs"
                        startIcon={{ name: 'Wand2' }}
                        isLoading={isRepairingWebhooks}
                        onClick={async () => {
                            await repairWebhooks({
                                nangoConnectionId,
                                integrationId,
                            })
                            await invalidateGetWebhooks()
                        }}
                    >
                        Repair
                    </Button>
                )}
            </Box>
        )
    }

    if (isSyncInProgress) {
        return (
            <DataConnectionSyncDetailPopup
                isOpen={showSyncPopup}
                onOpenChange={setShowSyncPopup}
                integrationId={integrationId}
                earliestStatus={earliestStatus}
                dataConnections={dataConnections}
            >
                <SyncInfo
                    dataConnectionStatus={earliestStatus || 'SYNC_NOT_STARTED'}
                    style={style}
                />
            </DataConnectionSyncDetailPopup>
        )
    }

    if (!lastSyncTime) {
        return <SyncInfo dataConnectionStatus="SYNC_NOT_STARTED" style={style} />
    }

    return (
        <DataConnectionSyncDetailPopup
            isOpen={showSyncPopup}
            onOpenChange={setShowSyncPopup}
            integrationId={integrationId}
            earliestStatus={earliestStatus}
            dataConnections={dataConnections}
        >
            <Body size="s" mr="m" style={{ paddingLeft: '165px', ...style }}>
                {prefix ?? ''}
                {moment(lastSyncTime).fromNow()}
            </Body>
        </DataConnectionSyncDetailPopup>
    )
}

type SyncingProps = {
    dataConnectionStatus: DataConnectionDto['status']
    style?: React.CSSProperties
}
export const SyncInfo: React.FC<SyncingProps> = ({ dataConnectionStatus, style }) => {
    const DC_STATUS_TO_STAGE: Record<DataConnectionDto['status'], string> = {
        SYNC_NOT_STARTED: 'Connecting',
        NANGO_STARTED: 'Gathering data',
        NANGO_COMPLETED: 'Starting sync',
        SYNCING_SCHEMA: 'Syncing',
        // A DC with SCHEMA_SYNC_COMPLETED status is not expected to render this component but
        // adding placeholder in case it happens
        SCHEMA_SYNC_COMPLETED: 'Sync completed',
        // this component is not expected to render when there are error
        AUTHENTICATION_ERROR: '',
        AUTHORIZATION_ERROR: '',
        UNKNOWN_ERROR: '',
    }
    return (
        <Box flex style={{ alignItems: 'center', paddingLeft: '165px', ...style }}>
            <Spinner size="2xs" mr="s" />
            <Body size="s" mr="m">
                {DC_STATUS_TO_STAGE[dataConnectionStatus]}
            </Body>
        </Box>
    )
}
