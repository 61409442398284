import { useMemo } from 'react'

import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'

export function useLayoutEditorControlsState() {
    const { view, selection, isSchemaOutdated } = useLayoutEditorContext()

    const viewType = view?.type

    const hasWidgetSelected = selection?.type === 'widget'

    return useMemo(
        () => ({ viewType, hasWidgetSelected, isSchemaOutdated }),
        [hasWidgetSelected, isSchemaOutdated, viewType]
    )
}
