import React from 'react'

import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

import { Box } from 'ui/components/Box'

const pulse = keyframes`
  0% {
    opacity: 0.3;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.3;
  }
`

const PulsingContainer = styled(Box)`
    animation: ${pulse} 2s ease-in-out infinite;
`

type AnimatedLogoProps = {
    src: string
}

export function AnimatedLogo({ src }: AnimatedLogoProps) {
    return (
        <Box fullWidth height="100dvh" flex column center justifyContent="center">
            <PulsingContainer>
                <img
                    alt=""
                    src={src}
                    style={{
                        height: 64,
                    }}
                />
            </PulsingContainer>
        </Box>
    )
}
