import { useMemo } from 'react'

import { useDetailViewContext } from 'features/views/DetailView/hooks/useDetailViewContext'
import { DetailViewHeaderSpacious } from 'features/views/DetailView/types'

export function useDetailViewTopBarState() {
    const { recordManager, header } = useDetailViewContext()!

    const isLoading = recordManager.isFetchingSlow

    const topBarStyle = (header as DetailViewHeaderSpacious).topBarStyle

    const title = recordManager.record?._primary ?? ''

    return useMemo(
        () => ({
            isLoading,
            topBarStyle,
            title,
        }),
        [isLoading, topBarStyle, title]
    )
}
