import React from 'react'

import { useDetailViewHeaderControlsState } from 'features/views/DetailView/headers/hooks/useDetailViewHeaderControlsState'
import { DetailViewHeader } from 'features/views/DetailView/types'
import { getYHeader } from 'features/views/DetailView/utils'
import { LayoutEditorCollapsibleControl } from 'features/views/LayoutEditor/controls/LayoutEditorCollapsibleControl'
import { LayoutEditorControlSeparator } from 'features/views/LayoutEditor/controls/LayoutEditorControlSeparator'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'

import { Box } from 'ui/components/Box'
import { Field } from 'ui/components/Field'
import { RadioCard, RadioCardGroup } from 'ui/components/Radio'
import { theme } from 'ui/styling/Theme.css'

import { DetailViewSlimHeaderControls } from './slim/DetailViewSlimHeaderControls'
import {
    DetailViewSpaciousHeaderControls,
    DetailViewSpaciousHeaderStyleControls,
} from './spacious/DetailViewSpaciousHeaderControls'

type DetailViewHeaderControlsProps = {}

export const DetailViewHeaderControls: React.FC<DetailViewHeaderControlsProps> = () => {
    const { header, fields } = useDetailViewHeaderControlsState()

    return (
        <Box flex column gap="l">
            <LayoutEditorCollapsibleControl label="Style" startIcon={{ name: 'Paintbrush' }}>
                <Box flex column gap="l">
                    <HeaderTypeControls header={header} />
                    {header.type === 'spacious' && (
                        <DetailViewSpaciousHeaderStyleControls header={header} />
                    )}
                </Box>
            </LayoutEditorCollapsibleControl>
            <LayoutEditorControlSeparator />
            {header.type === 'slim' && (
                <DetailViewSlimHeaderControls fields={fields} header={header} />
            )}
            {header.type === 'spacious' && (
                <DetailViewSpaciousHeaderControls fields={fields} header={header} />
            )}
        </Box>
    )
}

type HeaderTypeControlsProps = {
    header: DetailViewHeader
}

const HeaderTypeControls: React.FC<HeaderTypeControlsProps> = ({ header }) => {
    const { commands } = useLayoutEditorContext()

    return (
        <Field label="Header type">
            <RadioCardGroup
                value={header.type}
                onValueChange={(value: string) => {
                    commands.transaction((data) => {
                        const header = getYHeader(data)
                        header?.set('type', value)
                    })
                }}
                style={{
                    display: 'grid',
                    gridTemplateColumns: 'repeat(auto-fill, 82px)',
                    gap: theme.space.l,
                }}
            >
                <RadioCard
                    value="slim"
                    icon={() => <HeaderTypeSlimIcon isActive={header.type === 'slim'} />}
                    cardIconWrapperProps={{
                        style: { padding: 0, overflow: 'hidden' },
                    }}
                >
                    Slim bar
                </RadioCard>
                <RadioCard
                    value="spacious"
                    icon={() => <HeaderTypeSpaciousIcon isActive={header.type === 'spacious'} />}
                    cardIconWrapperProps={{
                        style: { padding: 0, overflow: 'hidden' },
                    }}
                >
                    Spacious
                </RadioCard>
            </RadioCardGroup>
        </Field>
    )
}

type HeaderTypeIconProps = {
    isActive: boolean
}

const HeaderTypeSlimIcon: React.FC<HeaderTypeIconProps> = ({ isActive }) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 82 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                width="82"
                height="8"
                style={{
                    transition: theme.transition.colors,
                    fill: isActive ? theme.color.theme600 : theme.color.gray200,
                }}
            />
            <rect
                x="8.03125"
                y="2.48535"
                width="26.4"
                height="3.77143"
                style={{ fill: theme.color.surface }}
            />
            <rect
                x="8.50268"
                y="13.6428"
                width="66.9429"
                height="17.9143"
                rx="1.41429"
                style={{
                    stroke: theme.color.gray200,
                }}
                strokeWidth="0.942857"
            />
            <rect
                x="8.07299"
                y="35.2146"
                width="66.9429"
                height="17.9143"
                style={{
                    stroke: theme.color.gray200,
                }}
                strokeWidth="0.942857"
            />
        </svg>
    )
}

const HeaderTypeSpaciousIcon: React.FC<HeaderTypeIconProps> = ({ isActive }) => {
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 82 58"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.0273438"
                y="0"
                width="82.0286"
                height="18"
                style={{
                    transition: theme.transition.colors,
                    fill: isActive ? theme.color.theme600 : theme.color.gray200,
                }}
            />
            <rect
                x="7.42969"
                y="23"
                width="26.4"
                height="3.77143"
                style={{
                    transition: theme.transition.colors,
                    fill: isActive ? theme.color.theme600 : theme.color.gray200,
                }}
            />
            <rect
                x="7.42969"
                y="30.7715"
                width="47.1429"
                height="1.88571"
                style={{
                    transition: theme.transition.colors,
                    fill: isActive ? theme.color.theme600 : theme.color.gray200,
                }}
            />
            <rect
                x="7.42969"
                y="36.6572"
                width="9"
                height="4"
                style={{
                    transition: theme.transition.colors,
                    fill: isActive ? theme.color.theme600 : theme.color.gray200,
                }}
            />
            <rect
                x="7.90112"
                y="45.1287"
                width="66.9429"
                height="17.9143"
                rx="1.41429"
                strokeWidth="0.942857"
                style={{
                    stroke: theme.color.gray200,
                }}
            />
        </svg>
    )
}
