import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import {
    Dropdown,
    DropdownButton,
    DropdownContent,
    DropdownItem,
    DropdownSeparator,
} from 'ui/components/Dropdown'
import { Tooltip } from 'ui/components/Tooltip'
import { stopPropagation } from 'ui/helpers/utilities'

import { useDetailViewTopBarActionsState } from './hooks/useDetailViewTopBarActionsState'
import { useDetailViewTopBarQuickActionsState } from './hooks/useDetailViewTopBarQuickActionsState'
import {
    DetailViewHeaderActionButton,
    DetailViewHeaderActionDropdownItem,
} from './DetailViewHeaderActionButton'

import { DetailViewHeaderDarkButtonStyle } from './DetailViewHeader.css'

type DetailViewTopBarQuickActionsProps = React.ComponentPropsWithoutRef<typeof Box> & {
    showAsDropdown?: boolean
    showActions?: boolean
    shade?: 'light' | 'dark'
}

export const DetailViewTopBarQuickActions: React.FC<DetailViewTopBarQuickActionsProps> = ({
    style,
    showAsDropdown,
    showActions,
    shade = 'light',
    ...props
}) => {
    const { copyLink, deleteRecord, actions, isLoading } = useDetailViewTopBarQuickActionsState()

    const buttonClassName = shade === 'dark' ? DetailViewHeaderDarkButtonStyle : undefined

    if (isLoading) return null

    return (
        <Box
            flex
            center
            gap="s"
            shrink
            style={{
                minWidth: 'min-content',
                ...style,
            }}
            {...props}
        >
            {!showAsDropdown && showActions && <DetailViewTopBarActions actions={actions} />}
            <Box flex center gap="s">
                {!!copyLink && !showAsDropdown && (
                    <Tooltip side="bottom" align="center" content="Copy link" zIndex={200} asChild>
                        <Button
                            size="s"
                            variant="ghost"
                            startIcon={{ name: 'Link' }}
                            aria-label="Copy link"
                            onClick={copyLink}
                            noShrink
                            className={buttonClassName}
                        />
                    </Tooltip>
                )}
                {!!deleteRecord && !showAsDropdown && (
                    <Tooltip side="bottom" align="center" content="Delete" zIndex={200} asChild>
                        <Button
                            size="s"
                            variant="critical"
                            startIcon={{ name: 'Trash2' }}
                            aria-label="Delete"
                            onClick={deleteRecord}
                            noShrink
                            className={buttonClassName}
                        />
                    </Tooltip>
                )}
                {showAsDropdown && (
                    <Dropdown>
                        <DropdownButton
                            size="s"
                            variant="ghost"
                            startIcon={{ name: 'MoreHorizontal' }}
                            aria-label="More actions"
                            noShrink
                            className={buttonClassName}
                        />
                        <DropdownContent
                            onClick={stopPropagation}
                            align="end"
                            side="bottom"
                            style={{ minWidth: '186px' }}
                        >
                            {showActions && actions.length > 0 && (
                                <>
                                    {actions.map((action) => (
                                        <DetailViewHeaderActionDropdownItem
                                            key={action._sid}
                                            action={action}
                                            startIcon={{ name: 'Workflow' }}
                                            closeOnSelect
                                        />
                                    ))}
                                    <DropdownSeparator />
                                </>
                            )}
                            {!!copyLink && (
                                <DropdownItem
                                    label="Copy link"
                                    onClick={copyLink}
                                    startIcon={{ name: 'Link' }}
                                />
                            )}
                            {!!deleteRecord && (
                                <DropdownItem
                                    variant="destructive"
                                    label="Delete"
                                    onClick={deleteRecord}
                                    startIcon={{ name: 'Trash2' }}
                                />
                            )}
                        </DropdownContent>
                    </Dropdown>
                )}
            </Box>
        </Box>
    )
}

type DetailViewTopBarActionsProps = React.ComponentPropsWithoutRef<typeof Box> & {
    actions?: ActionDto[]
}

const DetailViewTopBarActions: React.FC<DetailViewTopBarActionsProps> = ({
    actions,
    style,
    ...props
}) => {
    const { visibleActions, menuActions } = useDetailViewTopBarActionsState({ actions })

    if (!visibleActions.length && !menuActions.length) return null

    return (
        <Box
            display="grid"
            style={{
                gridTemplateColumns: `repeat(${visibleActions.length + (!!menuActions.length ? 1 : 0)}, minmax(10px, max-content))`,
                minWidth: `${(visibleActions.length + (!!menuActions.length ? 1 : 0)) * 32}px`,
                flexShrink: 1,
                ...style,
            }}
            gap="s"
            {...props}
        >
            {visibleActions.map((action, idx) => (
                <DetailViewHeaderActionButton
                    key={action._sid}
                    action={action}
                    size="s"
                    variant={idx === 0 ? 'primary' : 'secondary'}
                />
            ))}
            {menuActions.length > 0 && (
                <Dropdown>
                    <DropdownButton
                        size="s"
                        variant="secondary"
                        startIcon={{ name: 'MoreHorizontal' }}
                        noShrink
                    />
                    <DropdownContent align="end" side="bottom" style={{ minWidth: '286px' }}>
                        {menuActions.map((action) => (
                            <DetailViewHeaderActionDropdownItem
                                key={action._sid}
                                action={action}
                                closeOnSelect
                            />
                        ))}
                    </DropdownContent>
                </Dropdown>
            )}
        </Box>
    )
}
