import React from 'react'
import { useFormContext } from 'react-hook-form'

import { AgentInstructions, RendererConfig } from 'data/hooks/agents/types'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Field } from 'ui/components/Field'
import { Body } from 'ui/components/Text'

import { ConfigureRendererModal } from './ConfigureRendererModal'
import { RendererListItem } from './RendererListItem'

type EditRenderersProps = {
    agentSid: string
    instructionsSid?: string
}

export function EditRenderers({
    agentSid: _agentSid,
    instructionsSid: _instructionsSid,
}: EditRenderersProps) {
    const { watch, setValue } = useFormContext<Partial<AgentInstructions>>()
    const renderers = watch('configured_renderers') || []

    const addRenderer = (renderer: RendererConfig) => {
        setValue('configured_renderers', [...renderers, renderer], { shouldDirty: true })
    }

    const updateRenderer = (idx: number, config: RendererConfig) => {
        const newRenderers = [...renderers]
        newRenderers[idx] = config
        setValue('configured_renderers', newRenderers, { shouldDirty: true })
    }

    const removeRenderer = (idx: number) => {
        const newRenderers = renderers.filter((_, i) => i !== idx)
        setValue('configured_renderers', newRenderers, { shouldDirty: true })
    }

    return (
        <Field label="Configured Renderers">
            <Box flex fullWidth alignItems="stretch" column gap="m" maxWidth="full">
                <Box gap="xs" flex column overflowY="auto">
                    {renderers?.map((renderer, idx) => (
                        <ConfigureRendererModal
                            key={idx}
                            onSubmit={(config: RendererConfig) => updateRenderer(idx, config)}
                            config={renderer}
                        >
                            <RendererListItem
                                renderer={renderer}
                                onDelete={() => removeRenderer(idx)}
                            />
                        </ConfigureRendererModal>
                    ))}
                    {!renderers.length && <Body color="textWeaker">No renderers configured</Body>}
                </Box>
                <ConfigureRendererModal onSubmit={addRenderer}>
                    <Button
                        startIcon={{ name: 'Plus' }}
                        size="s"
                        variant="secondary"
                        alignSelf="flex-start"
                    >
                        Add Renderer
                    </Button>
                </ConfigureRendererModal>
            </Box>
        </Field>
    )
}
