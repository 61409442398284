import React, { CSSProperties, FC } from 'react'

import { useAppContext } from 'app/useAppContext'
import { useAppErrors } from 'data/hooks/appErrors'
import { useObject } from 'data/hooks/objects'
import { useStackRoles } from 'data/hooks/roles'
import { useUserProfileObjects } from 'data/hooks/userProfiles'
import { fieldHasError, isSyntheticLink } from 'data/utils/error_utils'
import InlineFilterItem from 'features/records/components/InlineFilterItem'
import { labelMap, nextFilterOptions } from 'features/records/components/RecordFilters/constants'
import { InlineLabel } from 'features/records/components/RecordFilters/InlineLabel'
import { InvalidFilter } from 'features/records/components/RecordFilters/InvalidFilter'
import { useRecordFilters } from 'features/records/components/RecordFilters/provider/useRecordFilters'
import { getFilterOptionsForType } from 'features/records/components/RecordFilters/utils/utils'
import { getFilterFieldFromId } from 'features/utils/filterToField'

import { getFilterErrorState } from './getFilterErrorState'

type Props = {
    color: string
    place: string | undefined
    prefix: string | undefined
    usePortal: boolean
    wrapItems: boolean
    itemStyle: CSSProperties
    overhangOperators: string[] | undefined
    closeOnOuterAction: boolean
    hideCurrentUserOption: boolean | undefined
    showRelativeDateFilters: boolean
    contextRecordObject?: ObjectDto
    disableValidation?: boolean
    FilterValueComponent?: (props: {
        field: FieldDto
        value: string
        operator: string
        onChange: (value: any) => void
        editMode: boolean
    }) => React.ReactNode
}

export const FieldsFilters: FC<Props> = ({
    color,
    place,
    prefix,
    usePortal,
    wrapItems,
    itemStyle,
    overhangOperators,
    closeOnOuterAction,
    hideCurrentUserOption,
    showRelativeDateFilters,
    contextRecordObject,
    FilterValueComponent,
    disableValidation,
}) => {
    const {
        state: { filters },
        object,
        onFieldChanged,
        onDeleteFilter,
    } = useRecordFilters()
    const { selectedStack } = useAppContext()
    const { object: userObject } = useObject(selectedStack?.options?.data_mapping?.user_object)
    const userProfileObjects = useUserProfileObjects(userObject?._sid)
    const { data: roles } = useStackRoles()

    const { data } = useAppErrors()
    const errors = data?.synthetic_field_errors

    return (
        <>
            {filters.map((filter, index) => {
                const field = getFilterFieldFromId(object, filter.field?._sid as string)

                let isInvalid
                if (isSyntheticLink(field)) {
                    isInvalid = fieldHasError(field, errors)
                }

                if (!field || isInvalid)
                    return (
                        <InvalidFilter
                            key={index}
                            handleDelete={() => onDeleteFilter(filter._id!)}
                        />
                    )

                const filterOptions = getFilterOptionsForType(
                    field,
                    Boolean(hideCurrentUserOption),
                    userObject as ObjectDto,
                    userProfileObjects,
                    showRelativeDateFilters,
                    contextRecordObject
                ).map((value) => ({
                    label: labelMap[value] || value,
                    value,
                }))

                const { hasFilterError, filterErrorMessage } = getFilterErrorState(
                    filter,
                    field,
                    userObject as ObjectDto,
                    userProfileObjects,
                    filterOptions,
                    disableValidation
                )

                // it's unsafe to use _id here as it is regenerates with random number sometims. And index is ok as we do not resort here
                return (
                    <div
                        key={index}
                        data-testid="record-filters-inline"
                        style={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            display: 'inline-flex',
                            alignItems: 'center',
                            maxWidth: '100%',
                            width: wrapItems ? 'auto' : '100%',
                            marginBottom: wrapItems ? '5px' : undefined,
                            position: 'relative',
                        }}
                    >
                        {prefix && index === 0 && <InlineLabel>{prefix}</InlineLabel>}
                        {index > 0 && (
                            <InlineLabel
                                position={overhangOperators && 'absolute'}
                                transform={overhangOperators && 'translateX(-100%)'}
                            >
                                and
                            </InlineLabel>
                        )}
                        <InlineFilterItem
                            contextRecordObject={contextRecordObject}
                            style={itemStyle}
                            roles={roles}
                            label={field.label}
                            field={field}
                            filterOptions={filter.options}
                            hideCurrentUserOption={hideCurrentUserOption}
                            userObjectId={selectedStack?.options?.data_mapping?.user_object}
                            options={filterOptions}
                            onChange={onFieldChanged}
                            id={filter._id}
                            valueOptionsByType={nextFilterOptions}
                            onRemove={() => onDeleteFilter(filter._id!)}
                            display="inline"
                            isNew={filter.isNew}
                            hasFilterError={hasFilterError}
                            filterErrorMessage={filterErrorMessage}
                            color={color}
                            closeOnOuterAction={closeOnOuterAction}
                            place={place}
                            usePortal={usePortal}
                            showRelativeDateFilters={showRelativeDateFilters}
                            FilterValueComponent={FilterValueComponent}
                        />
                    </div>
                )
            })}
        </>
    )
}
