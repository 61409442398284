import { useMemo } from 'react'

import { useThreadViewContext } from './useThreadViewContext'

type UseThreadViewMessageContentFieldsStateOptions = {
    isFromSender?: boolean
}

export function useThreadViewMessageContentFieldsState({
    isFromSender,
}: UseThreadViewMessageContentFieldsStateOptions) {
    const { labelStyle, attributes, fieldDirection, view } = useThreadViewContext()

    const hasLabels = labelStyle === 'text'
    const hasIcons = labelStyle === 'icon'

    const shouldHideOnSender = !!view.options.threadHideSenderFields && isFromSender
    const shouldHide = attributes.length < 1 || shouldHideOnSender

    return useMemo(
        () => ({
            hasLabels,
            hasIcons,
            attributes,
            fieldDirection,
            shouldHide,
        }),
        [hasLabels, hasIcons, attributes, fieldDirection, shouldHide]
    )
}
