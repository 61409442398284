import React from 'react'

import { Button } from 'ui/components/Button'

import { useDetailViewExperienceSwitcherState } from './hooks/useDetailViewExperienceSwitcherState'

type DetailViewExperienceSwitcherProps = React.ComponentPropsWithoutRef<typeof Button>

export const DetailViewExperienceSwitcher: React.FC<DetailViewExperienceSwitcherProps> = (
    props
) => {
    const { showButton, onClick, label, icon } = useDetailViewExperienceSwitcherState()

    if (!showButton) return null

    return (
        <Button size="2xs" variant="dotted" startIcon={icon} onClick={onClick} {...props}>
            {label}
        </Button>
    )
}
