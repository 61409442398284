import React from 'react'

import { RendererConfig } from 'data/hooks/agents/types'

import { Box, BoxProps } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Icon } from 'ui/components/Icon'
import { Body } from 'ui/components/Text'

import { RENDERER_TYPES } from './constants'

type RendererListItemProps = BoxProps & {
    renderer: RendererConfig
    onDelete?: () => void
    onClick?: () => void
}

export function RendererListItem({ renderer, onDelete, onClick, ...props }: RendererListItemProps) {
    const rendererType = RENDERER_TYPES.find((type) => type.type === renderer.type)
    return (
        <Box flex role="button" gap="m" center border px="m" py="s" onClick={onClick} {...props}>
            <Box
                flex
                center
                background="surfaceStrongest"
                borderRadius="m"
                height="4xl"
                width="4xl"
                color="textWeaker"
                justifyContent="center"
                noShrink
            >
                <Icon name={rendererType?.icon ?? 'Paintbrush'} />
            </Box>
            <Box flex column grow minWidth={0}>
                <Body weight="medium" trim>
                    {renderer.name || rendererType?.label}
                </Body>
                <Body size="s" trim style={{ minWidth: 0 }}>
                    {rendererType?.description}
                </Body>
            </Box>
            {onDelete && (
                <Button
                    variant="ghost"
                    size="xs"
                    iconOnly
                    title="Delete renderer"
                    startIcon={{ name: 'Trash' }}
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        onDelete()
                    }}
                />
            )}
        </Box>
    )
}
