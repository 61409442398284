import React from 'react'

import { UrlAttributeDisplay } from 'features/views/attributes/UrlAttributeDisplay'
import { UrlAttributeTag } from 'features/views/attributes/UrlAttributeTag'

import { useUrlAttributeState } from './hooks/useUrlAttributeState'
import { BaseAttribute } from './BaseAttribute'
import { BoardViewAttributeComponent } from './types'

const MAX_LINES = 1
const MAX_LENGTH = 30
const MAX_LENGTH_TAG = 20
const MAX_OVERFLOW_LENGTH = 300

type UrlAttributeProps = {}

export const UrlAttribute: BoardViewAttributeComponent<string, UrlAttributeProps> = ({
    value,
    attribute,
    isLoading,
    displayStyle,
    showIcon,
    ...props
}) => {
    const { field, rawOptions, type } = attribute
    const isTitle = type === 'title'
    const isFooter = type === 'footer'

    const isEmpty = !isLoading && !value

    const { isTagListAttributeStyle } = useUrlAttributeState()

    let tabletSize: 's' | 'm' | 'l' = 'm'
    if (isTitle) tabletSize = 'l'
    if (isTagListAttributeStyle) tabletSize = 's'

    const isDisplayedAsTag = displayStyle === 'tags'

    return (
        <BaseAttribute
            attribute={attribute}
            isEmpty={isEmpty}
            lineHeight={isDisplayedAsTag ? '1' : undefined}
            {...props}
        >
            {isDisplayedAsTag ? (
                <UrlAttributeTag
                    value={value}
                    field={field}
                    isLoading={isLoading}
                    maxLength={MAX_LENGTH_TAG}
                    maxOverflowLength={MAX_OVERFLOW_LENGTH}
                    buttonTitle={rawOptions.buttonTitle as string}
                    displayAsImage={rawOptions.displayAsImage as boolean}
                    imageAltText={rawOptions.imageAltText as string}
                    openInNewTab={rawOptions.openInNewTab as boolean}
                    showOverflowTooltip={{
                        mobile: false,
                        tablet: true,
                    }}
                    showIcon={showIcon}
                />
            ) : (
                <UrlAttributeDisplay
                    value={value}
                    size={{
                        mobile: isTitle ? 'l' : 's',
                        tablet: tabletSize,
                    }}
                    showOverflowTooltip={{
                        mobile: false,
                        tablet: true,
                    }}
                    buttonTitle={rawOptions.buttonTitle as string}
                    displayAsImage={rawOptions.displayAsImage as boolean}
                    imageAltText={rawOptions.imageAltText as string}
                    openInNewTab={rawOptions.openInNewTab as boolean}
                    field={field}
                    maxLines={MAX_LINES}
                    maxLength={isFooter ? undefined : MAX_LENGTH}
                    maxOverflowLength={MAX_OVERFLOW_LENGTH}
                    isLoading={isLoading}
                    trim
                    style={{
                        display: isFooter ? 'block' : undefined,
                        maxWidth: isFooter ? '100%' : undefined,
                    }}
                />
            )}
        </BaseAttribute>
    )
}
