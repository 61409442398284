import { IconName } from 'ui/components/Icon/Icon'
import { IconValue } from 'ui/components/IconPicker/IconPickerDropdown'
import { ColorSchemeLowerCase } from 'ui/styling/baseVariables/colors/colorPalette'

export type TriggerType = {
    type: 'app_chatbot' | 'external_chatbot' | 'record_created' | 'email_received' | 'cron_job'
    name: string
    icon: IconName
    description: string
    color: ColorSchemeLowerCase
}

export type Agent = {
    _sid: string
    // Basic Information
    title: string
    icon?: IconValue
    system_prompt?: string
    custom_instructions?: string

    available_skills: string[]
    configured_tools: ToolConfig[]

    // Reference Fields
    account_id: string // Foreign key to Account
    stack_id: string // Foreign key to Stack

    // System Fields
    created_by_id?: string // Foreign key to User
    created_date: string // ISO DateTime string
    modified_date: string // ISO DateTime string

    show_in_app_bar_for_roles: string[]
}

export enum JobStatus {
    NOT_STARTED = 'not_started',
    RUNNING = 'running',
    COMPLETED = 'completed',
    FAILED = 'failed',
    WAITING_FOR_CALLBACK = 'waiting_for_callback',
    WAITING_FOR_USER_INPUT = 'waiting_for_user_input',
}

export enum JobStepStatus {
    NOT_STARTED = 'not_started',
    COMPLETED = 'completed',
    FAILED = 'failed',
    WAITING = 'waiting',
}

export type AgentJobStep = {
    tool_to_use: string
    instructions: string
    status: JobStepStatus
    result?: any // JSONField maps to any in TypeScript
}

export type AgentJob = {
    _sid: string

    // Basic Information
    title: string
    summary: string
    status: JobStatus
    steps?: AgentJobStep[]
    result?: any // JSONField maps to any in TypeScript

    // Reference Fields
    agent_id: string // Foreign key to Agent

    // System Fields
    created_by_id?: string // Foreign key to User
    created_date: string // ISO DateTime string
    modified_date: string // ISO DateTime string
}

export type MessageRole = 'user' | 'assistant' | 'system' | 'tool'

export type AgentConversation = {
    _sid: string
    title?: string

    // Reference Fields
    user_id: string // Foreign key to User
    agent_id: string // Foreign key to Agent
    trigger_id: string // Foreign key to AgentTrigger

    error_message?: string

    // System Fields
    created_date: string // ISO DateTime string
    modified_date: string // ISO DateTime string
    is_deleted: boolean
}

export type ToolCallResult = {
    tool_call_id: string
    result: any
    status: 'ok' | 'failed' | 'cancelled'
    log: (string | object)[]
    tool_call_results: ToolCallResult[]
}

export type SkillInput = {
    name: string
    description: string
}

export type AgentSkillOptions = {
    icon?: IconValue
    color?: ColorSchemeLowerCase
}
export type AgentSkill = {
    _sid: string
    api_name: string

    // Basic Information
    name: string
    description?: string
    prompt: string
    inputs?: SkillInput[]
    output_schema?: WorkflowOutputSchema
    available_tools: string[]
    is_system_skill: boolean

    // Reference Fields
    agent_id: string
    available_skills: string[]
    configured_tools: ToolConfig[]

    // System Fields
    created_by_id?: string // Foreign key to User
    created_date: string // ISO DateTime string
    modified_date: string // ISO DateTime string

    options?: AgentSkillOptions
}

export type ToolInput = {
    name: string
    display_name: string
    description?: string
}

export type SystemTool = {
    name: string
    api_name: string
    description: string
    inputs: ToolInput[]
}

export type ToolConfig = {
    tool_id: string
    name: string
    api_name: string
    description?: string
    supplied_inputs?: Record<string, string>
}

export type RendererConfig = {
    type: 'queryResult' | 'email'
    name?: string
    config: Record<string, any>
}

export type AgentIntegrationsDto = {
    integration_id: string
    integration_provider: string
    name: string
}

export type AgentToolConnectionDto = {
    _sid: string
    agent_sid: string
    integration_id: string
    nango_connection_id?: string
    config_data: Record<string, any>
    description: string
    created_date: string
    modified_date: string
    is_deleted: boolean
}

export type AgentTrigger = {
    _sid: string
    name: string
    type: TriggerType['type']
    config: Record<string, any>
    is_enabled: boolean

    // Reference Fields
    agent_instructions_id?: string // Foreign key to AgentInstructions

    // System Fields
    created_by_id?: string // Foreign key to User
    created_date: string // ISO DateTime string
    modified_date: string // ISO DateTime string
    is_deleted: boolean
}

export type AgentInstructions = {
    _sid: string
    name: string
    prompt: string

    // Optional references
    agent_id?: string // Foreign key to Agent

    available_skills: string[]
    configured_tools: ToolConfig[]
    configured_renderers: RendererConfig[]
    triggers: AgentTrigger[]
    // System Fields
    created_by_id?: string // Foreign key to User
    created_date: string // ISO DateTime string
    modified_date: string // ISO DateTime string
    is_deleted: boolean

    options: {
        async_jobs_enabled?: boolean
        display_tool_usage?: boolean
    }
}
