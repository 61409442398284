import React from 'react'
import ReactLinkify from 'react-linkify'

import { Box } from 'ui/components/Box'
import { Link } from 'ui/components/Link'
import { Skeleton } from 'ui/components/Skeleton'
import { Body } from 'ui/components/Text'
import { Tooltip } from 'ui/components/Tooltip'
import { truncateText } from 'ui/helpers/utilities'
import { ResponsiveValue, useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useLongTextAttributeDisplayState } from './hooks/useLongTextAttributeDisplayState'
import { AttributeDisplayComponent } from './types'

type LongTextAttributeDisplayProps = React.ComponentPropsWithoutRef<typeof Body> & {
    maxLines?: number
    maxLength?: number
    maxOverflowLength?: number
    showOverflowTooltip?: ResponsiveValue<boolean>
    alwaysShowOverflowTooltip?: ResponsiveValue<boolean>
    isLoading?: boolean
}

export const LongTextAttributeDisplay: AttributeDisplayComponent<
    string,
    LongTextAttributeDisplayProps
> = React.memo(function LongTextAttributeDisplay({
    value,
    maxLines,
    maxLength,
    maxOverflowLength,
    showOverflowTooltip,
    alwaysShowOverflowTooltip,
    isLoading,
    ...props
}) {
    const effectiveShowOverflowTooltip = useResponsiveValue(showOverflowTooltip)
    const effectiveAlwaysShowOverflowTooltip = useResponsiveValue(alwaysShowOverflowTooltip)
    const effectiveMaxLines = useResponsiveValue(maxLines)

    const { content, isOverflowing } = useLongTextAttributeDisplayState({
        value,
        maxLength,
        isLoading,
    })

    if (!content) return null

    const children = (
        <Box minWidth={0} tabIndex={0} trim={!isLoading && effectiveMaxLines === 1}>
            <ReactLinkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                    <Link
                        target="_blank"
                        href={decoratedHref}
                        key={key}
                        onClick={(e) => e.stopPropagation()}
                        style={{
                            fontSize: 'inherit',
                            fontWeight: 'inherit',
                            lineHeight: 'inherit',
                        }}
                    >
                        {maxLength ? truncateText(decoratedText, maxLength) : decoratedText}
                    </Link>
                )}
            >
                <Body
                    size="m"
                    weight="regular"
                    maxLines={maxLines}
                    maxLength={maxLength}
                    {...props}
                >
                    {isLoading ? <Skeleton isLoading={isLoading}>{content}</Skeleton> : content}
                </Body>
            </ReactLinkify>
        </Box>
    )

    if (effectiveAlwaysShowOverflowTooltip || (isOverflowing && effectiveShowOverflowTooltip)) {
        return (
            <Tooltip
                content={
                    <Body
                        style={{
                            fontSize: 'inherit',
                            fontWeight: 'inherit',
                            lineHeight: 'inherit',
                        }}
                        maxLength={maxOverflowLength}
                    >
                        {content}
                    </Body>
                }
                side="bottom"
                zIndex={200}
                asChild
            >
                {children}
            </Tooltip>
        )
    }

    return children
})
