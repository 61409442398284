import { useCallback, useEffect, useMemo, useState } from 'react'

import moment from 'moment-timezone'

const DATE_FORMAT = 'MMM D, YYYY'

const RELATIVE_DATE_UPDATE_INTERVAL = 1000 * 60 // 1 minute

type ThreadViewDayDividerStateProps = {
    day: string
}

export function useThreadViewDayDividerState({ day }: ThreadViewDayDividerStateProps) {
    const [formattedDay, setFormattedDay] = useState('')

    const momentDay = moment(day)

    const computeFormattedDay = useCallback(() => {
        const newDay = !day
            ? 'Unknown date'
            : momentDay.calendar(null, {
                  sameDay: '[Today]',
                  lastDay: '[Yesterday]',
                  nextDay: '[Tomorrow]',
                  lastWeek: '[Last] dddd',
                  nextWeek: 'dddd',
                  sameElse: DATE_FORMAT,
              })

        setFormattedDay(newDay)
    }, [day, momentDay, setFormattedDay])

    useEffect(() => {
        computeFormattedDay()

        const interval = setInterval(computeFormattedDay, RELATIVE_DATE_UPDATE_INTERVAL)
        return () => clearInterval(interval)
    }, [computeFormattedDay, setFormattedDay])

    const date = !day ? 'Invalid date' : momentDay.format(DATE_FORMAT)

    const isRelative = formattedDay !== date

    return useMemo(
        () => ({
            formattedDay,
            date,
            isRelative,
        }),
        [formattedDay, date, isRelative]
    )
}
