import React, { useCallback, useRef, useState } from 'react'

import { Box } from 'ui/components/Box'
import { Dropdown, DropdownContent, DropdownTitle, DropdownTrigger } from 'ui/components/Dropdown'
import { Icon } from 'ui/components/Icon'
import { IconName } from 'ui/components/Icon/Icon'
import { IconPicker } from 'ui/components/IconPicker'
import { TriggerStyles } from 'ui/components/Select/Select.css'

import { IconVariants } from './IconPicker'

export type IconValue<V extends IconVariants = 'lucide'> = {
    type: V
    name: IconName<V>
}

type IconPickerDropdownProps<V extends IconVariants = 'lucide'> = {
    variant?: V
    value?: IconValue<V>
    onChange?: (value?: IconValue<V>) => void
    disabled?: boolean
    title?: string
    onOpenChange?: (open: boolean) => void
    isClearable?: boolean
}

export const IconPickerDropdown = <V extends IconVariants = 'lucide'>({
    variant = 'lucide' as V,
    value,
    onChange,
    disabled,
    title = 'Icons',
    onOpenChange,
    isClearable = true,
}: IconPickerDropdownProps<V>) => {
    const variantRef = useRef(variant)
    variantRef.current = variant

    const [isOpen, setIsOpen] = useState(false)

    const handleChange = useCallback(
        (iconName?: IconName<V>) => {
            if (iconName) {
                onChange?.({ type: variantRef.current, name: iconName })
            } else {
                onChange?.(undefined)
            }
        },
        [onChange]
    )

    const handleOpenChange = useCallback(
        (open: boolean) => {
            setIsOpen(open)
            onOpenChange?.(open)
        },
        [onOpenChange]
    )

    return (
        <Dropdown open={isOpen} onOpenChange={handleOpenChange} modal>
            <DropdownTrigger
                className={TriggerStyles.styleFunction({ size: 'm' })}
                style={{
                    width: 'auto',
                }}
                disabled={disabled}
                aria-disabled={disabled}
                asChild
            >
                <Box flex center gap="xs" role="button">
                    {value ? (
                        <Icon
                            family={variant as React.ComponentProps<typeof Icon>['family']}
                            name={value.name as React.ComponentProps<typeof Icon>['name']}
                            size="m"
                            color="text"
                        />
                    ) : (
                        <Box height="xl" width="xl" rounded="s" background="surfaceStrongest" />
                    )}
                    <Icon size="m" name="ChevronDown" color="icon" />
                </Box>
            </DropdownTrigger>
            <DropdownContent align="end">
                <DropdownTitle>{title}</DropdownTitle>
                <Box px="xl">
                    <IconPicker
                        value={value?.name ?? null}
                        onChange={handleChange}
                        isClearable={isClearable}
                        variant={variant}
                        style={{
                            height: '250px',
                        }}
                    />
                </Box>
            </DropdownContent>
        </Dropdown>
    )
}
