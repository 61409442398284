import { useObjects } from 'data/hooks/objects'
import { getIsStackerUserObject } from 'features/workspace/stackerUserUtils'

const EMPTY_NODE_ID = ''

export function generateEmptyNode(
    existingNodes: WorkflowOutputSchemaNode[] = []
): WorkflowOutputSchemaNode {
    const emptyNode: WorkflowOutputSchemaNode = {
        id: EMPTY_NODE_ID,
        type: 'string',
    }

    const existingIds = new Set(existingNodes.map((node) => node.id))

    // Generate a unique ID for the empty node.
    let index = 1
    while (existingIds.has(emptyNode.id)) {
        index++
        emptyNode.id = `${EMPTY_NODE_ID}_${index}`
    }

    return emptyNode
}

export function isPlaceholderNode(node: WorkflowOutputSchemaNode): boolean {
    return node.id === EMPTY_NODE_ID
}

function convertOutputSchemaNodeToOutput(
    node: WorkflowOutputSchemaNode
): WorkflowSchemaStateItemGroup | WorkflowSchemaStateItem {
    switch (node.type) {
        case 'object':
            return convertOutputSchemaObjectToOutput(node)
        case 'array':
            return convertOutputSchemaArrayToOutput(node)
        default:
            return convertOutputSchemaLiteralToOutput(node)
    }
}

function convertOutputSchemaObjectToOutput(
    node: WorkflowOutputObjectNode
): WorkflowSchemaStateItemGroup {
    const id = node.id

    const outputs: (WorkflowSchemaStateItem | WorkflowSchemaStateItemGroup)[] = []
    for (const value of node.children) {
        const parsed = convertOutputSchemaNodeToOutput(value)
        outputs.push(parsed)
    }

    return {
        type: 'group',
        id,
        name: id,
        items: outputs,
    }
}

function convertOutputSchemaArrayToOutput(
    node: WorkflowOutputArrayNode
): WorkflowSchemaStateItemGroup {
    const id = node.id

    const outputs: (WorkflowSchemaStateItem | WorkflowSchemaStateItemGroup)[] = []
    for (const value of node.children) {
        const parsed = convertOutputSchemaNodeToOutput(value)
        outputs.push(parsed)
    }

    return {
        type: 'group',
        id,
        name: id,
        items: outputs,
    }
}

function convertOutputSchemaLiteralToOutput(
    node: WorkflowOutputLiteralNode
): WorkflowSchemaStateItem {
    const id = node.id
    const output_type = mapOutputSchemaTypeToOutputType(node.type)

    return {
        id,
        name: id,
        type: output_type,
        extra_options: node.extra_options,
    }
}

function mapOutputSchemaTypeToOutputType(type: WorkflowOutputSchemaNodeType): FieldType {
    switch (type) {
        case 'string':
            return 'string'
        case 'number':
            return 'number'
        case 'boolean':
            return 'checkbox'
        case 'date':
            return 'date'
        case 'datetime':
            return 'datetime'
        case 'lookup':
            return 'lookup'
        case 'long_text':
            return 'long_text'
        default:
            return 'string'
    }
}

export function convertOutputSchemaToOutputs(
    outputSchema: WorkflowOutputSchema
): (WorkflowSchemaStateItemGroup | WorkflowSchemaStateItem)[] {
    const nodes = outputSchema.schema
    return nodes.map(convertOutputSchemaNodeToOutput)
}

const TYPES_WITH_CHILDREN = new Set(['object', 'array'])

export function isOutputSchemaTypeWithChildren(type: WorkflowOutputSchemaNodeType): boolean {
    return TYPES_WITH_CHILDREN.has(type)
}

export function changeOutputSchemaNodeType(
    node: WorkflowOutputSchemaNode,
    newType: WorkflowOutputSchemaNodeType,
    newExtraOptions?: WorkflowExtraOptions
): WorkflowOutputSchemaNode {
    const currentType = node.type
    if (currentType === newType) return node

    const hasCurrentTypeChildren = isOutputSchemaTypeWithChildren(currentType)
    const hasNewTypeChildren = isOutputSchemaTypeWithChildren(newType)

    if (hasCurrentTypeChildren && !hasNewTypeChildren) {
        // eslint-disable-next-line unused-imports/no-unused-vars
        const { children, ...rest } = node as WorkflowOutputArrayNode

        return {
            ...rest,
            type: newType,
            extra_options: newExtraOptions,
        } as WorkflowOutputSchemaNode
    }

    if (!hasCurrentTypeChildren && hasNewTypeChildren) {
        return {
            ...node,
            type: newType,
            extra_options: newExtraOptions,
            children: [],
        } as WorkflowOutputSchemaNode
    }

    return {
        ...node,
        type: newType,
        extra_options: newExtraOptions,
    } as WorkflowOutputSchemaNode
}

const ID_REGEXP = /^[a-zA-Z0-9_]+$/

export function validateOutputSchemaId(
    id: string,
    siblingNodes: WorkflowOutputSchemaNode[]
): string {
    if (!id) return "The ID can't be empty."

    const existingIds = new Set(siblingNodes.map((node) => node.id))
    if (existingIds.has(id)) return 'The ID must be unique.'

    if (!ID_REGEXP.test(id)) return 'The ID can only contain letters, numbers and underscores.'

    return ''
}

export function useSchemaEditorObjects() {
    const { data: allObjects = [], ...query } = useObjects()
    const filteredObjects = allObjects
        .filter((o) => !o.connection_options?.data_mapping_disabled && Boolean(o.data_connection))
        .sort((a, b) => {
            if (getIsStackerUserObject(a)) return -1
            if (getIsStackerUserObject(b)) return 1

            return a.name.localeCompare(b.name)
        })

    return { data: filteredObjects, ...query }
}

export function getSchemaEditorLookupName(object: ObjectDto): string {
    if (getIsStackerUserObject(object)) return 'User Id'

    return `${object.name} Id`
}
