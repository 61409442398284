import { WidgetSchema } from 'features/views/LayoutEditor/types'

import { DividerWidget, DividerWidgetType } from './DividerWidget'

export const DividerWidgetMeta: WidgetSchema<DividerWidgetType> = {
    category: 'layout',
    label: 'Divider',
    icon: 'SeparatorHorizontal',
    component: DividerWidget,
}
