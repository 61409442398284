import { useMemo } from 'react'

import { useThreadViewContext } from './useThreadViewContext'

export function useThreadViewLoaderState() {
    const { pageSize, object } = useThreadViewContext()

    return useMemo(
        () => ({
            placeholderRows: makePlaceholderRows(object._sid, pageSize),
        }),
        [pageSize, object._sid]
    )
}

function makePlaceholderRows(objectSid: string, length: number) {
    return Array.from({ length }).map((_, index) => ({
        _sid: index.toString(),
        _object_id: objectSid,
        _primary: `Record ${index + 1}`,
    }))
}
