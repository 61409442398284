import { useMemo } from 'react'

import { useThreadViewContext } from './useThreadViewContext'

export function useThreadViewDataState() {
    const {
        isLoading,
        isFetchingSlow,
        records,
        hasFilters,
        hasError,
        clearFilters,
        retryFetchRecords,
        isEmbedded,
        header,
        inlineFilterType,
        availableInlineFilterFields,
        view,
    } = useThreadViewContext()

    const hasRecords = !!records?.length

    const isError = !hasRecords && hasError

    const isEmpty = !isLoading && !isFetchingSlow && !hasRecords && !hasFilters && !isError
    const isEmptyWithFilters =
        !isLoading && !isFetchingSlow && !hasRecords && hasFilters && !isError

    const showTable =
        ((!isLoading && hasRecords) || isFetchingSlow) &&
        !isError &&
        !isEmpty &&
        !isEmptyWithFilters

    const showTopPadding =
        (header.type === 'portal' && header.style === 'full') ||
        (header.type === 'default' &&
            (inlineFilterType !== 'quick' ||
                (inlineFilterType === 'quick' && availableInlineFilterFields.length < 1)))

    const isSplitLayout = view.options?.threadLayout === 'split'

    return useMemo(
        () => ({
            isEmpty,
            isEmptyWithFilters,
            showTable,
            clearFilters,
            isError,
            retryFetchRecords,
            isFetchingSlow,
            isEmbedded,
            showTopPadding,
            isSplitLayout,
        }),
        [
            clearFilters,
            isEmpty,
            isEmptyWithFilters,
            showTable,
            isError,
            retryFetchRecords,
            isFetchingSlow,
            isEmbedded,
            showTopPadding,
            isSplitLayout,
        ]
    )
}
