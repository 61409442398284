import publicAsset from 'utils/publicAsset'

import { DC_TYPE_TO_INTEGRATION_ID } from './constants'

export const getIconForDataConnectionType = (dcType: DataConnectionType | undefined) => {
    if (!dcType) return

    const integrationId = DC_TYPE_TO_INTEGRATION_ID[dcType]
    return publicAsset(`/static/media/integrations/${integrationId}.svg`)
}
