import { useProcessFilter } from 'features/records/components/RecordFilters'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'
import { FieldsWidgetAttributeField } from 'features/views/LayoutEditor/widgets/FieldsWidget/attributes/types'
import {
    FieldsWidgetType,
    FieldsWidgetTypeField,
} from 'features/views/LayoutEditor/widgets/FieldsWidget/fieldWidgetTypes'
import { isFieldEditable } from 'features/views/LayoutEditor/widgets/FieldsWidget/utils'
import { getFieldPlaceholder } from 'features/views/ListView/Actions/utils'
import { useRecordManagerContext } from 'features/views/RecordManager/useRecordManagerContext'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

export function useFieldsWidgetFields(widget: FieldsWidgetType) {
    const { record, permissions } = useRecordManagerContext()
    const { fields, isEditing, view } = useLayoutEditorContext()

    const showAllFields = widget.attrs.showAllFields ?? true

    const widgetFields = widget.attrs.fields ?? []

    const editingMode = view?.layout?.editingMode ?? 'inline'
    const supportsEditing = editingMode !== 'disabled' && !isEditing

    const processFilter = useProcessFilter()

    const fieldsWithReadPerms = new Set(permissions.canReadFields)
    const fieldsWithWritePerms = new Set(permissions.canUpdateFields)

    let displayedFields: FieldsWidgetAttributeField[]
    let visibleFields: FieldsWidgetAttributeField[]
    if (showAllFields) {
        const widgetFieldsApiNames = widgetFields.reduce((acc, f) => {
            return acc.set(f.apiName, f)
        }, new Map<string, FieldsWidgetTypeField>())

        displayedFields = fields.reduce((acc, f) => {
            const widgetField = widgetFieldsApiNames.get(f.api_name)

            const isEditable =
                supportsEditing && isFieldEditable(f) && fieldsWithWritePerms.has(f.api_name)
            const isRequired = widgetField?.isRequired ?? false
            const placeholder = getFieldPlaceholder(f, isEditable)

            acc.push({
                ...widgetField,
                sid: f._sid,
                field: f,
                apiName: f.api_name,
                conditions: widgetField?.conditions ?? [],
                isEditable,
                placeholder,
                isRequired,
            })

            return acc
        }, [] as FieldsWidgetAttributeField[])
    } else {
        const visibleFieldApiNames = fields.reduce((acc, f) => {
            return acc.set(f.api_name, f)
        }, new Map<string, FieldDto>())

        displayedFields = widgetFields.reduce((acc, f) => {
            const field = visibleFieldApiNames.get(f.apiName)
            if (field) {
                const isEditable =
                    supportsEditing && isFieldEditable(field) && fieldsWithWritePerms.has(f.apiName)
                const isRequired = f?.isRequired ?? false
                const placeholder = getFieldPlaceholder(field, isEditable)

                acc.push({
                    ...f,
                    field,
                    isEditable,
                    placeholder,
                    isRequired,
                })
            }

            return acc
        }, [] as FieldsWidgetAttributeField[])
    }

    if (isEditing) {
        visibleFields = displayedFields
    } else {
        // If not editing, filter fields based on conditions.
        visibleFields = displayedFields.filter((f) => {
            if (!fieldsWithReadPerms.has(f.apiName)) return false

            if (!record && f.conditions.length) return false
            if (!record && !f.conditions.length) return true

            const filteredData = processFilter([record!], f.conditions)

            return filteredData.length > 0
        })
    }

    return useDeepEqualsMemoValue({ displayedFields, visibleFields })
}
