import * as React from 'react'

import * as ToggleGroupPrimitive from '@radix-ui/react-toggle-group'
import { useControllableState } from '@radix-ui/react-use-controllable-state'
import classNames from 'classnames'

import { Body } from 'ui/components/Text'

import {
    DetailViewSegmentedControlIndicatorStyle,
    DetailViewSegmentedControlItemLabelActiveStyle,
    DetailViewSegmentedControlItemLabelInactiveStyle,
    DetailViewSegmentedControlItemLabelStyle,
    DetailViewSegmentedControlItemStyle,
    DetailViewSegmentedControlStyle,
} from './DetailViewSegmentedControl.css'

type DetailViewSegmentedControlProps = Omit<
    React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Root>,
    'type' | 'value' | 'defaultValue' | 'onValueChange'
> & {
    value?: string
    defaultValue?: string
    onValueChange?: (value: string) => void
}

export const DetailViewSegmentedControl = React.forwardRef<
    HTMLDivElement,
    DetailViewSegmentedControlProps
>(
    (
        {
            className,
            children,
            value: valueProp,
            defaultValue: defaultValueProp,
            onValueChange: onValueChangeProp,
            disabled,
            ...props
        },
        ref
    ) => {
        const [value, setValue] = useControllableState({
            prop: valueProp,
            onChange: onValueChangeProp,
            defaultProp: defaultValueProp,
        })

        return (
            <ToggleGroupPrimitive.Root
                data-disabled={disabled || undefined}
                ref={ref}
                className={classNames(DetailViewSegmentedControlStyle, className)}
                onValueChange={(value) => {
                    if (value) {
                        setValue(value)
                    }
                }}
                {...props}
                type="single"
                value={value}
                asChild={false}
                disabled={!!disabled}
            >
                {children}
                <div className={DetailViewSegmentedControlIndicatorStyle} />
            </ToggleGroupPrimitive.Root>
        )
    }
)

type DetailViewSegmentedControlItemProps = Omit<
    React.ComponentPropsWithoutRef<typeof ToggleGroupPrimitive.Item>,
    'type' | 'value'
> & {
    value: string
}

export const DetailViewSegmentedControlItem = React.forwardRef<
    HTMLButtonElement,
    DetailViewSegmentedControlItemProps
>(({ children, className, disabled, ...props }, forwardedRef) => (
    <ToggleGroupPrimitive.Item
        ref={forwardedRef}
        className={classNames('rt-reset', DetailViewSegmentedControlItemStyle, className)}
        {...props}
        data-disabled={disabled || undefined}
        disabled={disabled}
        asChild={false}
    >
        <div className={DetailViewSegmentedControlItemLabelStyle}>
            <Body
                size="s"
                weight="bold"
                color="gray700"
                className={DetailViewSegmentedControlItemLabelActiveStyle}
                trim
            >
                {children}
            </Body>
            <Body
                size="s"
                color={disabled ? 'textDisabled' : 'gray700'}
                className={DetailViewSegmentedControlItemLabelInactiveStyle}
                trim
            >
                {children}
            </Body>
        </div>
    </ToggleGroupPrimitive.Item>
))
