import { useCallback, useMemo, useRef, useState } from 'react'

import { JSONContent } from '@tiptap/core'

import { TipTapEditorHandle } from 'features/tiptap/TipTapEditor'
import { useTipTapDocumentContent } from 'features/tiptap/useTipTapDocumentContent'
import { RichTextValue } from 'features/views/attributes/types'
import { FieldsWidgetFieldEditorStyles } from 'features/views/LayoutEditor/widgets/FieldsWidget/FieldsWidget.css'
import { isCmdOrCtrlPressed } from 'utils/isCmdOrCtrlPressed'

import { useAttributeContext } from './useAttributeContext'

type UseRichTextAttributeEditorStateProps = {
    isLoading?: boolean
}

export function useRichTextAttributeEditorState({
    isLoading,
}: UseRichTextAttributeEditorStateProps) {
    const editorRef = useRef<TipTapEditorHandle>(null)

    const {
        value: attributeValue,
        discardChanges,
        saveValue,
        replaceValue,
        clearValue,
        isRequired,
    } = useAttributeContext<RichTextValue>()

    const [value, setValue] = useState(attributeValue ?? null)
    const valueRef = useRef(value)
    valueRef.current = value

    const tipTapContent = useTipTapDocumentContent({
        content: value?.content ?? null,
        format: value?.format ?? 'tiptap',
    })

    const isEmpty = !isLoading && (!value || (!!value && !value?.plainTextContent))

    const onInputChange = useCallback((value: JSONContent | null) => {
        const plainTextContent = editorRef.current?.editor?.getText() ?? ''

        const attrValue: RichTextValue | null =
            // If the field is empty, set it back to null
            plainTextContent == ''
                ? null
                : {
                      format: 'tiptap',
                      content: value,
                      plainTextContent,
                  }

        setValue(attrValue)
    }, [])

    const onInputBlur = useCallback(
        (event?: React.FocusEvent<HTMLTextAreaElement>) => {
            if (!!document.querySelector('.ag-custom-component-popup')) {
                return
            }

            // If the blur event is triggered by the field value controls, we don't want to save the value.
            const isEditingClassName = `.${FieldsWidgetFieldEditorStyles.styleFunction({ isEditing: true }).split(' ').join('.')}`
            const targetEl = event?.relatedTarget as HTMLElement | null
            if (targetEl?.closest(isEditingClassName)) {
                return
            }

            queueMicrotask(() => {
                if (isRequired && !valueRef.current?.plainTextContent) {
                    discardChanges()
                    return
                }

                replaceValue(valueRef.current as any)
                saveValue()
            })
        },
        [discardChanges, isRequired, replaceValue, saveValue]
    )

    const onInputKeyDown = useCallback(
        (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
            if (e.key === 'Enter' && isCmdOrCtrlPressed(e.nativeEvent)) {
                e.preventDefault()
                onInputBlur()
            }

            if (e.key === 'Escape') {
                e.preventDefault()
                discardChanges()
            }
        },
        [discardChanges, onInputBlur]
    )

    const onClearValue = useCallback(() => {
        setValue(null)
        clearValue()
    }, [clearValue])

    return useMemo(
        () => ({
            onInputChange,
            onInputBlur,
            onInputKeyDown,
            value: tipTapContent,
            onClearValue,
            editorRef,
            isEmpty,
            isRequired,
        }),
        [
            onInputChange,
            onInputBlur,
            onInputKeyDown,
            tipTapContent,
            onClearValue,
            isEmpty,
            isRequired,
        ]
    )
}
