import React from 'react'

import { CurrencyAttributeDisplay } from 'features/views/attributes/CurrencyAttributeDisplay'

import { Body } from 'ui/components/Text'

import { useAttributeContext } from './hooks/useAttributeContext'
import { useCurrencyAttributeEditorState } from './hooks/useCurrencyAttributeEditorState'
import { AttributeTextInput } from './AttributeTextInput'
import { BaseAttribute } from './BaseAttribute'
import { FIELD_VALIDATION_REQUIRED_ERROR_MESSAGE } from './constants'
import { InlineValueWrapper } from './InlineValueWrapper'
import { FieldsWidgetAttributeComponent } from './types'

type CurrencyAttributeProps = {}

export const CurrencyAttribute: FieldsWidgetAttributeComponent<CurrencyAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const { value, isEditingValue } = useAttributeContext<number>()
    const isEmpty = !isLoading && typeof value === 'undefined'

    if (isEditingValue) {
        return (
            <CurrencyAttributeEditor
                key="currency-attribute"
                field={field}
                isLoading={isLoading}
                {...props}
            />
        )
    }

    return (
        <BaseAttribute
            key="currency-attribute"
            {...props}
            field={field}
            isEmpty={isEmpty}
            isLoading={isLoading}
        >
            <InlineValueWrapper>
                <CurrencyAttributeDisplay
                    value={value}
                    field={field.field}
                    isLoading={isLoading}
                    maxLines={1}
                    size="m"
                    trim
                    maxWidth="full"
                    minWidth={0}
                />
            </InlineValueWrapper>
        </BaseAttribute>
    )
}

const CurrencyAttributeEditor: FieldsWidgetAttributeComponent<CurrencyAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const {
        value,
        onInputBlur,
        onInputChange,
        onInputKeyDown,
        currencySign,
        onClearValue,
        isRequired,
    } = useCurrencyAttributeEditorState()

    const isEmpty = !isLoading && !value

    return (
        <BaseAttribute
            {...props}
            field={field}
            isEmpty={isEmpty}
            isLoading={isLoading}
            isClearValueButtonDisabled={isEmpty}
            onClearValue={onClearValue}
            validationError={
                isRequired && isEmpty ? FIELD_VALIDATION_REQUIRED_ERROR_MESSAGE : undefined
            }
        >
            <AttributeTextInput
                autoFocus={true}
                onBlur={onInputBlur}
                onChange={onInputChange}
                onKeyDown={onInputKeyDown}
                value={value}
                prefix={
                    <Body size="m" color="textWeaker" mr="3xs" noShrink>
                        {currencySign}
                    </Body>
                }
            />
        </BaseAttribute>
    )
}
