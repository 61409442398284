import React from 'react'

import { DetailViewHeaderEyebrowAttribute } from 'features/views/DetailView/attributes/DetailViewHeaderEyebrowAttribute'
import { DetailViewProfileImageAttribute } from 'features/views/DetailView/attributes/DetailViewProfileImageAttribute'
import { DetailViewTitleAttribute } from 'features/views/DetailView/attributes/DetailViewTitleAttribute'
import { DetailViewTopBarBreadcrumbs } from 'features/views/DetailView/headers/DetailViewTopBarBreadcrumbs'
import { DetailViewTopBarNavigation } from 'features/views/DetailView/headers/DetailViewTopBarNavigation'
import { DetailViewTopBarQuickActions } from 'features/views/DetailView/headers/DetailViewTopBarQuickActions'

import { Box } from 'ui/components/Box'
import { Skeleton } from 'ui/components/Skeleton'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useDetailViewSlimHeaderState } from './hooks/useDetailViewSlimHeaderState'

import { DetailViewSlimHeaderStyle } from './DetailViewSlimHeader.css'

type DetailViewSlimHeaderProps = {}

export const DetailViewSlimHeader: React.FC<DetailViewSlimHeaderProps> = () => {
    const { headerRef, isLoading, topOffset } = useDetailViewSlimHeaderState()

    const isMobile = useResponsiveValue({
        mobile: true,
        tablet: false,
    })

    return (
        <Box
            ref={headerRef}
            as="header"
            className={DetailViewSlimHeaderStyle}
            style={{
                top: topOffset,
            }}
        >
            <Box
                flex
                center
                justifyContent="space-between"
                gap="s"
                width="full"
                px={{
                    mobile: 'xl',
                    tablet: 0,
                }}
                pb={{
                    mobile: 'm',
                    tablet: 0,
                }}
            >
                <Box flex center gap={{ mobile: 's', tablet: 'm' }} shrink grow>
                    <DetailViewTopBarNavigation />
                    {!isMobile && (
                        <Skeleton isLoading={isLoading}>
                            <Box flex center grow shrink gap="xs">
                                <DetailViewTopBarBreadcrumbs variant="slim" />
                                <Box flex center shrink grow gap="m">
                                    <DetailViewProfileImageAttribute
                                        noShrink
                                        isLoading={isLoading}
                                    />
                                    <DetailViewTitleAttribute size="m" isLoading={isLoading} />
                                    <DetailViewHeaderEyebrowAttribute
                                        isLoading={isLoading}
                                        size={{
                                            mobile: '2xs',
                                            tablet: 's',
                                        }}
                                        noShrink
                                    />
                                </Box>
                            </Box>
                        </Skeleton>
                    )}
                </Box>
                <DetailViewTopBarQuickActions
                    showAsDropdown={isMobile}
                    shrink={false}
                    noShrink
                    showActions
                />
            </Box>
            {isMobile && (
                <Box
                    flex
                    column
                    pt="xl"
                    px="xl"
                    borderTopWidth={1}
                    borderColor="dividerWeak"
                    gap="xs"
                    width="full"
                >
                    <Skeleton isLoading={isLoading}>
                        <Box flex center grow shrink gap="xs">
                            <DetailViewTopBarBreadcrumbs variant="slim" size="m" />
                            <Box flex center shrink grow gap="m">
                                <DetailViewProfileImageAttribute noShrink isLoading={isLoading} />
                                <DetailViewTitleAttribute size="m" isLoading={isLoading} />
                            </Box>
                        </Box>
                    </Skeleton>
                    <DetailViewHeaderEyebrowAttribute
                        size={{
                            mobile: '2xs',
                            tablet: 's',
                        }}
                        isLoading={isLoading}
                    />
                </Box>
            )}
        </Box>
    )
}
