import React from 'react'

import { LongTextAttributeDisplay } from 'features/views/attributes/LongTextAttributeDisplay'

import { BaseCell } from './BaseCell'
import { TableViewCellComponent } from './types'

const MAX_LINES = 2
const MAX_OVERFLOW_LENGTH = 300

type LongTextCellProps = React.ComponentPropsWithoutRef<typeof BaseCell> & {}

export const LongTextCell: TableViewCellComponent<string, LongTextCellProps> = ({
    value,
    column,
    isLoading,
    ...props
}) => {
    const { field, isPrimary } = column

    const isEmpty = !isLoading && !value

    return (
        <BaseCell column={column} isEmpty={isEmpty} {...props}>
            <LongTextAttributeDisplay
                value={value}
                weight={isPrimary ? 'bold' : 'regular'}
                size={{
                    mobile: 's',
                    tablet: 'm',
                }}
                alwaysShowOverflowTooltip={{
                    mobile: false,
                    tablet: true,
                }}
                field={field}
                maxLines={MAX_LINES}
                maxOverflowLength={MAX_OVERFLOW_LENGTH}
                isLoading={isLoading}
                style={{
                    wordBreak: 'break-word',
                    whiteSpace: 'pre-wrap',
                }}
            />
        </BaseCell>
    )
}
