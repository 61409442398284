import React, { useLayoutEffect } from 'react'

import { LayoutEditorContextProvider } from 'features/views/LayoutEditor/LayoutEditorContext'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'

import { DetailView } from './DetailView'

type EmbeddedDetailViewProps = {
    viewSid: string
    recordSid?: string
    hideHeader?: boolean
    isEmbedded?: boolean
    isSingleRecordListView?: boolean
    urlParamStrategy?: React.ComponentProps<typeof LayoutEditorContextProvider>['urlParamStrategy']
}

export const EmbeddedDetailView: React.FC<EmbeddedDetailViewProps> = ({
    urlParamStrategy,
    ...props
}) => {
    return (
        <LayoutEditorContextProvider urlParamStrategy={urlParamStrategy}>
            <EmbeddedDetailViewInner {...props} />
        </LayoutEditorContextProvider>
    )
}

const EmbeddedDetailViewInner: React.FC<EmbeddedDetailViewProps> = ({
    viewSid,
    recordSid,
    hideHeader,
    isEmbedded = true,
    isSingleRecordListView = false,
}) => {
    const { commands } = useLayoutEditorContext()

    useLayoutEffect(() => {
        commands.initEditor({ viewSid })

        return () => {
            commands.closeEditor()
        }
    }, [commands, viewSid])

    return (
        <DetailView
            isEmbedded={isEmbedded}
            recordSid={recordSid}
            hideHeader={hideHeader}
            isSingleRecordListView={isSingleRecordListView}
        />
    )
}
