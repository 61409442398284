// @ts-strict-ignore

import { Editor } from '@tiptap/core'
import {
    Fragment as ProseMirrorFragment,
    Mark as ProseMirrorMark,
    MarkType as ProseMirrorMarkType,
    Node as ProseMirrorNode,
    Slice as ProseMirrorSlice,
} from '@tiptap/pm/model'
import { Transaction } from '@tiptap/pm/state'
import { ReplaceStep } from '@tiptap/pm/transform'
import { Content } from '@tiptap/react'
import LinkifyIt from 'linkify-it'
import tlds from 'tlds'

import { createRecordLinkExtension } from './Extensions/RecordLinkExtension'
import { parseDetailViewInputURL } from './Extensions/recordLinkExtensionFunctions'

export function removeMarkFromSlice(slice: ProseMirrorSlice, markType: ProseMirrorMarkType) {
    const updatedContent = removeMarkFromFragment(slice.content, markType)
    return new ProseMirrorSlice(updatedContent, slice.openStart, slice.openEnd)
}

export function removeMarkFromFragment(
    fragment: ProseMirrorFragment,
    markType: ProseMirrorMarkType
) {
    const updatedContent: ProseMirrorNode[] = []

    fragment.forEach((child) => {
        const updatedNode = removeMarkFromNode(child, markType)
        updatedContent.push(updatedNode)
    })

    return ProseMirrorFragment.fromArray(updatedContent)
}

export function removeMarkFromNode(node: ProseMirrorNode, markType: ProseMirrorMarkType) {
    if (node.isText) {
        const marks = node.marks.filter((mark) => mark.type !== markType)
        return node.mark(marks)
    }

    if (node.content) {
        const updatedContent = removeMarkFromFragment(node.content, markType)
        return node.copy(updatedContent)
    }

    return node
}

export function getDocumentSchemaVersion(content?: Content): number | undefined {
    if (!content) return undefined

    if (Array.isArray(content)) {
        const firstNode = content[0]
        if (firstNode && firstNode.type === 'doc') {
            return firstNode.attrs?.schemaVersion
        }
    } else if (typeof content === 'object' && content.type === 'doc') {
        return content.attrs?.schemaVersion
    }

    return undefined
}

export function isSchemaVersionSupported(
    content?: Content,
    currentSchemaVersion?: number
): boolean {
    if (!content) return true

    const hasCurrentSchemaVersion = typeof currentSchemaVersion !== 'undefined'
    if (!hasCurrentSchemaVersion) return false

    const docSchemaVersion = getDocumentSchemaVersion(content)
    const hasDocSchemaVersion = typeof docSchemaVersion !== 'undefined'
    if (!hasDocSchemaVersion) return true

    return currentSchemaVersion! >= docSchemaVersion!
}

export function hasTrAddedChar(char: string, tr: Transaction): boolean {
    return tr.steps.some((step) => {
        if (step instanceof ReplaceStep) {
            const stepContent = step.slice.content
            const stepText = stepContent.firstChild?.textContent

            if (stepText === char) {
                return true
            }
        }
        return false
    })
}

export function hasTrRemovedChar(char: string, tr: Transaction): boolean {
    const charLen = char.length

    return tr.steps.some((step) => {
        if (step instanceof ReplaceStep) {
            const stepContent = step.slice.content
            if (stepContent.size > 0) return false

            const { from, to } = step
            if (to - from !== charLen) return false

            const stepText = tr.doc.textBetween(from, to, ' ', ' ')
            const beforeStepText = tr.before.textBetween(from, to, ' ', ' ')

            return beforeStepText === char && stepText === ''
        }
    })
}

const NODES_WITHOUT_RECORD_LINKS = new Set(['heading', 'blockquote', 'codeBlock'])
const MARKS_WITHOUT_RECORD_LINKS = new Set(['code', 'link'])

export function preprocessContentForPreview(editor: Editor | null) {
    if (!editor) return null
    // Create a copy of the current editor state.
    let state = editor.state.reconfigure({})

    const linkify = new LinkifyIt()
    linkify.tlds(tlds)

    const linkMarkType = state.schema.marks.link

    const recordLinkExtension = editor.extensionManager.extensions.find(
        (e) => e.name === 'recordLink'
    ) as ReturnType<typeof createRecordLinkExtension> | undefined
    const recordLinkNodeType = state.schema.nodes.recordLink

    const changes: {
        newNode?: ProseMirrorNode
        newMark?: ProseMirrorMark
        nodeRange: { from: number; to: number }
    }[] = []

    // Traverse all nodes and find text nodes that contain links.
    state.doc.descendants((node, pos) => {
        // Prevent adding links on certain block types.
        if (NODES_WITHOUT_RECORD_LINKS.has(node.type.name)) return false

        const text = node.text
        // There's no text in this node, we can go deeper into its children.
        if (!text) return true

        const markNames = node.marks.map((mark) => mark.type.name)
        // Prevent adding links on certain mark types.
        if (markNames.some((markName) => MARKS_WITHOUT_RECORD_LINKS.has(markName))) {
            return false
        }

        // Find all links in the text.
        const matches = linkify.match(text)
        if (!matches) return false

        for (const link of matches) {
            const start = link.index
            const end = link.lastIndex
            const url = link.url

            if (recordLinkExtension) {
                const recordURLMatch = parseDetailViewInputURL(
                    url,
                    recordLinkExtension.options.fetchWorkspaceAccountFn,
                    recordLinkExtension.options.fetchStacksFn,
                    false
                )
                if (recordURLMatch) {
                    // If this is a link to a record, we need to use the record link node to render it.
                    const newNode = recordLinkNodeType.create(recordURLMatch.data)

                    const nodeRange = { from: pos + start, to: pos + end }
                    changes.push({ newNode, nodeRange })

                    continue
                }
            }

            const linkMark = linkMarkType.create({ href: url })
            const nodeRange = { from: pos + start, to: pos + end }
            changes.push({ newMark: linkMark, nodeRange })
        }

        return false
    })

    if (changes.length > 0) {
        const { tr } = state
        // Apply changes in reverse order to avoid position invalidation.
        for (let i = changes.length - 1; i >= 0; i--) {
            const { nodeRange, newNode, newMark } = changes[i]
            if (newNode) {
                tr.replaceWith(nodeRange.from, nodeRange.to, newNode)
            } else if (newMark) {
                tr.addMark(nodeRange.from, nodeRange.to, newMark)
            }
        }
        editor.view.dispatch(tr)
    }
}
