import React, { useMemo } from 'react'

import { useDetailViewContext } from 'features/views/DetailView/hooks/useDetailViewContext'
import { DetailViewHeaderSpacious } from 'features/views/DetailView/types'

import { Headline } from 'ui/components/Text'

const titleComponentSizes = {
    small: 'l',
    medium: 'xl',
    large: '2xl',
}

export function useDetailViewSpaciousHeaderContentState() {
    const { header } = useDetailViewContext()!

    const { titleSize = 'medium' } = header as DetailViewHeaderSpacious
    const titleComponentSize = useMemo(
        () =>
            ({
                mobile: 'm',
                tablet: titleComponentSizes[titleSize],
            }) as React.ComponentPropsWithoutRef<typeof Headline>['size'],
        [titleSize]
    )

    return useMemo(
        () => ({
            titleComponentSize,
        }),
        [titleComponentSize]
    )
}
