import { QueryKey, UseQueryOptions } from 'react-query'

import { getCurrentStackId } from 'app/GlobalStaticState'
import {
    queryClient,
    useCanRunStackScopedQueries,
    useCreateItem,
    useDeleteItem,
    useQuery,
    useQueryKeyBuilder,
    useUpdateItem,
} from 'data/hooks/_helpers'
import { STACK_QUERY_CONFIG } from 'data/reactQueryCache'

import { AgentInstructions } from './types'

// Instructions
const INSTRUCTIONS_LIST_NAME = 'useAgentInstructions'
const get_instructions_endpoint = (agent_sid?: string) =>
    agent_sid ? `agents/${agent_sid}/instructions/` : 'agent-instructions/'

type InstructionsOptionsType = UseQueryOptions<
    AgentInstructions[],
    unknown,
    AgentInstructions[],
    QueryKey
>

function useInstructionsQueryKey(agent_sid?: string) {
    return useQueryKeyBuilder([INSTRUCTIONS_LIST_NAME, agent_sid], {
        includeAuthKeys: true,
        includeStackId: true,
    })
}

export function useAgentInstructions(agent_sid?: string, options: InstructionsOptionsType = {}) {
    const enabled = useCanRunStackScopedQueries()
    const query_config = {
        ...(STACK_QUERY_CONFIG as InstructionsOptionsType),
        keepPreviousData: true,
        ...options,
        enabled: enabled && !!agent_sid,
    }
    return useQuery<AgentInstructions[]>(
        useInstructionsQueryKey(agent_sid),
        get_instructions_endpoint(agent_sid),
        query_config
    )
}

export function useCreateAgentInstructions(agent_sid?: string) {
    return useCreateItem<AgentInstructions>(
        useInstructionsQueryKey(agent_sid),
        get_instructions_endpoint(agent_sid),
        {
            onSuccess: () => {
                invalidateInstructions(agent_sid)
            },
        }
    )
}

export function useUpdateAgentInstructions(agent_sid?: string) {
    return useUpdateItem<AgentInstructions>(
        useInstructionsQueryKey(agent_sid),
        get_instructions_endpoint(agent_sid),
        {
            onSuccess: () => {
                invalidateInstructions(agent_sid)
            },
        }
    )
}

export function useDeleteAgentInstructions(agent_sid?: string) {
    return useDeleteItem(useInstructionsQueryKey(agent_sid), get_instructions_endpoint(agent_sid), {
        onSuccess: () => {
            invalidateInstructions(agent_sid)
        },
    })
}

export function invalidateInstructions(agent_sid?: string) {
    return queryClient.invalidateQueries([INSTRUCTIONS_LIST_NAME, getCurrentStackId(), agent_sid])
}
