import { WidgetSchema } from 'features/views/LayoutEditor/types'
import {
    getRequiredFieldsFromActionButtons,
    getRequiredFieldsFromFilters,
} from 'features/views/LayoutEditor/utils'

import { SectionWidget, SectionWidgetAdminControls } from './SectionWidget'
import { SectionWidgetType } from './sectionWidgetTypes'

export const SectionWidgetMeta: WidgetSchema<SectionWidgetType> = {
    category: 'layout',
    label: 'Section',
    icon: 'LayoutTemplate',
    component: SectionWidget,
    adminControlsComponent: SectionWidgetAdminControls,
    isContainer: true,
    requireFields: (attrs, fields) => {
        const { actionButtons = [], visibilityFilters = [] } = attrs

        const fieldsForButtons = getRequiredFieldsFromActionButtons(actionButtons, fields)
        const fieldsForFilters = getRequiredFieldsFromFilters(visibilityFilters, fields)

        return [...fieldsForButtons, ...fieldsForFilters]
    },
}
