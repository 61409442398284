import { useEffect, useRef } from 'react'

type UseAutoSelectSingleRecordProps = {
    records: RecordDto[]
    value?: string | string[]
    onChange: (value: string | string[]) => void
    mode?: string
    autoSelectEnabled?: boolean
    isMulti?: boolean
}

export function useAutoSelectSingleRecord({
    records,
    value,
    onChange,
    mode,
    autoSelectEnabled = false,
    isMulti = false,
}: UseAutoSelectSingleRecordProps) {
    const hasAutoSelected = useRef(false)
    const previousValue = useRef(value)

    const shouldAutoSelect =
        autoSelectEnabled &&
        mode === 'editing' &&
        records.length === 1 &&
        !previousValue.current &&
        !hasAutoSelected.current

    useEffect(() => {
        if (shouldAutoSelect) {
            const newValue = isMulti ? [records[0]._sid] : records[0]._sid
            onChange(newValue)
            hasAutoSelected.current = true
        }
    }, [records, value, onChange, mode, autoSelectEnabled, shouldAutoSelect, isMulti])
}
