import React from 'react'

import { OutputSchemaEditor } from 'features/workflows/OutputSchema/OutputSchemaEditor'
import { ConfigurationLabel } from 'features/workflows/WorkflowUI'

import { WorkflowTriggerEditorComponent } from './types'

export const StartedByAgentTriggerConfig: WorkflowTriggerEditorComponent = ({
    trigger,
    onChange,
}) => {
    const outputSchema = trigger.settings?.output_schema as WorkflowOutputSchema | undefined
    const handleOutputSchemaChange = (value: WorkflowOutputSchema) => {
        onChange({
            settings: {
                ...trigger.settings,
                output_schema: value,
            },
        })
    }

    return (
        <>
            <ConfigurationLabel>Data attributes</ConfigurationLabel>
            <OutputSchemaEditor value={outputSchema} onChange={handleOutputSchemaChange} mb="l" />
        </>
    )
}
