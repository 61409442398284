import React from 'react'

import { BANNER_HEIGHT } from 'features/core/appBannerConstants'
import { CollapsedNavigationTrigger } from 'features/navigation/CollapsedNavigationTrigger'
import { AdvancedFilters } from 'features/views/ListView/ListHeader/Filters/Advanced/AdvancedFilters'
import { ListHeaderAdvancedFilterControl } from 'features/views/ListView/ListHeader/Filters/Advanced/ListHeaderAdvancedFilterControl'
import { QuickFilters } from 'features/views/ListView/ListHeader/Filters/QuickFilters/QuickFilters'
import { ListHeaderAddNewButton } from 'features/views/ListView/ListHeader/ListHeaderAddNewButton'
import { ListHeaderMoreActions } from 'features/views/ListView/ListHeader/ListHeaderMoreActions'
import { ListHeaderSearchControl } from 'features/views/ListView/ListHeader/Search/ListHeaderSearchControl'
import { ListHeaderSortControl } from 'features/views/ListView/ListHeader/Sort/ListHeaderSortControl'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Body, Headline } from 'ui/components/Text'
import { Tooltip } from 'ui/components/Tooltip'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'
import { theme } from 'ui/styling/Theme.css'

import { useListHeaderDefaultState } from './hooks/useListHeaderDefaultState'
import { ListHeaderDefaultObjectLabel } from './ListHeaderDefaultObjectLabel'

import {
    ListHeaderDefaultActionsStyle,
    ListHeaderDefaultContentStyle,
    ListHeaderDefaultPrimaryBarStyle,
    ListHeaderDefaultSecondaryBarStyle,
    ListHeaderDefaultStyle,
} from './ListHeaderDefault.css'

const SUBTITLE_MAX_LINES = 3

type ListHeaderDefaultProps = {
    isSticky?: boolean
}

export const ListHeaderDefault: React.FC<ListHeaderDefaultProps> = ({ isSticky }) => {
    const {
        title,
        subtitle,
        recordCount,
        supportsInlineSorting,
        showRecordCount: showRecordCountValue,
        setShowRecordCount,
        supportsQuickFilters,
        supportsAdvancedFilters,
        advancedFiltersVisible,
        toggleAdvancedFilters,
        isEmbedded,
        allowCreate,
        headerRef,
        isSubtitleVisible,
        toggleIsSubtitleVisible,
        showMenuButton,
        isPreviewing,
    } = useListHeaderDefaultState()

    const actionsHeight = useResponsiveValue({
        mobile: 'auto',
        tablet: theme.button.sizes.m,
    })

    const titleHeight = useResponsiveValue({
        mobile: theme.button.sizes.s,
        tablet: theme.button.sizes.m,
    })

    const subtitleMaxWidth = useResponsiveValue({
        mobile: '100%',
        tablet: '40vw',
    })

    const showRecordCount = useResponsiveValue({
        mobile: true,
        tablet: false,
    })

    const effectiveIsSticky = isSticky && !isEmbedded

    return (
        <Box
            ref={headerRef}
            as="header"
            position={effectiveIsSticky ? 'sticky' : 'relative'}
            top={0}
            width="full"
            style={{
                zIndex: effectiveIsSticky ? 199 : 0,
                top: isPreviewing && !isEmbedded ? `${BANNER_HEIGHT}px` : undefined,
            }}
            className={ListHeaderDefaultStyle}
        >
            <Box
                className={ListHeaderDefaultContentStyle}
                px={{ mobile: 0, tablet: '4xl' }}
                flex
                alignItems="flex-start"
                flexDirection="column"
                alignSelf="stretch"
                borderWidth={0}
                borderBottomWidth="base"
                borderStyle="base"
                borderColor="borderWeak"
                background={isEmbedded ? undefined : 'surface'}
                width="full"
            >
                <Box
                    className={ListHeaderDefaultPrimaryBarStyle}
                    flex
                    gap={{ mobile: 0, tablet: '3xl' }}
                    py={{ mobile: 0, tablet: 'm' }}
                    alignItems="flex-start"
                    alignSelf="stretch"
                    flexDirection={{ mobile: 'column', tablet: 'row' }}
                >
                    <Box
                        flex
                        grow
                        shrink
                        minWidth={0}
                        alignSelf="stretch"
                        gap={{ mobile: 'xs', tablet: 'm' }}
                        borderWidth={0}
                        borderBottomWidth={{ mobile: 'base', tablet: 0 }}
                        borderStyle="base"
                        borderColor="borderWeak"
                        py={{ mobile: 'm', tablet: 0 }}
                        pl={{ mobile: isEmbedded ? 'xl' : 'm', tablet: 0 }}
                        pr={{ mobile: 'xl', tablet: 0 }}
                        overflow="hidden"
                    >
                        {showMenuButton && (
                            <Box flex center>
                                <CollapsedNavigationTrigger />
                            </Box>
                        )}
                        <Box shrink minWidth={0} flex column gap="xs">
                            <Box
                                flex
                                center
                                gap="m"
                                shrink
                                minWidth={0}
                                style={{ height: titleHeight }}
                            >
                                {title && (
                                    <Tooltip
                                        content={title}
                                        side="bottom"
                                        align="end"
                                        zIndex={200}
                                        asChild
                                    >
                                        <Box minWidth={0} shrink trim role="button">
                                            <Headline size="xs">{title}</Headline>
                                        </Box>
                                    </Tooltip>
                                )}
                                <Box flex center gap="xs" noShrink>
                                    {subtitle && (
                                        <Button
                                            startIcon={{
                                                name: isSubtitleVisible
                                                    ? 'ChevronUp'
                                                    : 'ChevronDown',
                                            }}
                                            size="xs"
                                            variant="ghost"
                                            aria-label={
                                                isSubtitleVisible
                                                    ? 'Collapse subtitle'
                                                    : 'Reveal subtitle'
                                            }
                                            onClick={toggleIsSubtitleVisible}
                                        />
                                    )}
                                    <ListHeaderDefaultObjectLabel />
                                </Box>
                            </Box>
                            {isSubtitleVisible && subtitle && (
                                <Box flex minWidth={0} shrink>
                                    <Tooltip
                                        content={subtitle}
                                        side="bottom"
                                        align="end"
                                        zIndex={200}
                                        asChild
                                    >
                                        <Box role="button" style={{ maxWidth: subtitleMaxWidth }}>
                                            <Body
                                                size={{ mobile: 's', tablet: 'm' }}
                                                maxLines={SUBTITLE_MAX_LINES}
                                            >
                                                {subtitle}
                                            </Body>
                                        </Box>
                                    </Tooltip>
                                </Box>
                            )}
                        </Box>
                    </Box>
                    <Box
                        flex
                        center
                        py={{ mobile: 'm', tablet: 0 }}
                        px={{
                            mobile: 'xl',
                            tablet: 0,
                        }}
                        justifyContent={{ mobile: 'space-between', tablet: 'flex-end' }}
                        width={{
                            mobile: 'full',
                            tablet: 'auto',
                        }}
                        noShrink
                        overflow="hidden"
                    >
                        {showRecordCount && (
                            <Box>
                                {showRecordCountValue && recordCount && (
                                    <Body size="s" weight="medium" color="textWeaker">
                                        Showing {recordCount}
                                    </Body>
                                )}
                            </Box>
                        )}
                        <Box
                            style={{
                                height: actionsHeight,
                                gap: theme.button.gap.s,
                            }}
                            flex
                            center
                            noShrink
                            className={ListHeaderDefaultActionsStyle}
                        >
                            {!supportsQuickFilters && (
                                <>
                                    <ListHeaderSearchControl
                                        setShowRecordCount={setShowRecordCount}
                                    />
                                    {supportsAdvancedFilters && (
                                        <ListHeaderAdvancedFilterControl
                                            onClick={toggleAdvancedFilters}
                                            isActive={advancedFiltersVisible}
                                        />
                                    )}
                                    {supportsInlineSorting && <ListHeaderSortControl />}
                                </>
                            )}
                            {allowCreate && <ListHeaderAddNewButton />}
                            <ListHeaderMoreActions />
                        </Box>
                    </Box>
                </Box>
            </Box>
            {supportsQuickFilters && (
                <Box
                    flex
                    center
                    gap="m"
                    background={isEmbedded ? undefined : 'surface'}
                    justifyContent="space-between"
                    alignSelf="stretch"
                    className={ListHeaderDefaultSecondaryBarStyle}
                    px={{ mobile: 'xl', tablet: '4xl' }}
                    py={{ mobile: 'm', tablet: 'xl' }}
                >
                    <QuickFilters grow />
                    <Box
                        style={{
                            height: actionsHeight,
                            gap: theme.button.gap.s,
                        }}
                        flex
                        center
                        noShrink
                        className={ListHeaderDefaultActionsStyle}
                    >
                        <ListHeaderSearchControl showLabel={false} />
                        {supportsInlineSorting && <ListHeaderSortControl />}
                    </Box>
                </Box>
            )}
            {advancedFiltersVisible && (
                <AdvancedFilters
                    alignSelf="stretch"
                    px={{ mobile: 'xl', tablet: '4xl' }}
                    py={{ mobile: 'm', tablet: 'xl' }}
                    borderWidth={0}
                    borderBottomWidth="base"
                    borderStyle="base"
                    borderColor="borderWeak"
                    background={isEmbedded ? undefined : 'surface'}
                    width="full"
                />
            )}
        </Box>
    )
}
