import { pxToRem } from 'ui/helpers/converters'

import { makeAppVariables } from './app'

export function makeButtonVariables(s: ReturnType<typeof makeAppVariables>) {
    return {
        color: {
            surfacePrimary: s.color.theme600,
            surfacePrimaryHover: s.color.theme700,
            surfacePrimaryActive: s.color.theme800,
            surfacePrimaryLoading: s.color.theme800,
            surfacePrimaryFocus: s.color.theme600,
            surfacePrimaryDisabled: s.color.surfaceDisabled,

            surfaceSecondary: s.color.surface,
            surfaceSecondaryHover: s.color.surface,
            surfaceSecondaryActive: s.color.darkAlpha50,
            surfaceSecondaryLoading: s.color.darkAlpha50,
            surfaceSecondaryFocus: s.color.surface,
            surfaceSecondaryDisabled: s.color.surfaceDisabled,

            surfaceDestructive: s.color.error600,
            surfaceDestructiveHover: s.color.error700,
            surfaceDestructiveActive: s.color.error800,
            surfaceDestructiveLoading: s.color.error800,
            surfaceDestructiveFocus: s.color.error600,
            surfaceDestructiveDisabled: s.color.surfaceDisabled,

            surfaceGhost: s.color.surfaceTransparent,
            surfaceGhostHover: s.color.darkAlpha50,
            surfaceGhostActive: s.color.darkAlpha100,
            surfaceGhostLoading: s.color.darkAlpha100,
            surfaceGhostFocus: s.color.surface,
            surfaceGhostDisabled: s.color.transparent,

            surfaceDotted: s.color.surfaceTransparent,
            surfaceDottedHover: s.color.surfaceTransparent,
            surfaceDottedActive: s.color.darkAlpha50,
            surfaceDottedLoading: s.color.darkAlpha50,
            surfaceDottedFocus: s.color.surface,
            surfaceDottedDisabled: s.color.surfaceDisabled,

            surfaceActivated: s.color.theme50,
            surfaceActivatedHover: s.color.theme100,
            surfaceActivatedActive: s.color.theme200,
            surfaceActivatedLoading: s.color.theme200,
            surfaceActivatedFocus: s.color.theme50,
            surfaceActivatedDisabled: s.color.surfaceDisabled,

            surfaceLink: s.color.surfaceTransparent,
            surfaceLinkHover: s.color.darkAlpha50,
            surfaceLinkActive: s.color.darkAlpha100,
            surfaceLinkLoading: s.color.darkAlpha100,
            surfaceLinkFocus: s.color.surface,
            surfaceLinkDisabled: s.color.transparent,

            surfaceCritical: s.color.surfaceTransparent,
            surfaceCriticalHover: s.color.darkAlpha50,
            surfaceCriticalActive: s.color.darkAlpha100,
            surfaceCriticalLoading: s.color.darkAlpha100,
            surfaceCriticalFocus: s.color.surface,
            surfaceCriticalDisabled: s.color.transparent,

            textPrimary: s.color.textInverse,
            textPrimaryHover: s.color.textInverse,
            textPrimaryActive: s.color.textInverse,

            textSecondary: s.color.gray900,
            textSecondaryHover: s.color.text,
            textSecondaryActive: s.color.text,

            textDestructive: s.color.textInverse,
            textDestructiveHover: s.color.textInverse,
            textDestructiveActive: s.color.textInverse,

            textGhost: s.color.textWeak,
            textGhostHover: s.color.text,
            textGhostActive: s.color.text,

            textDotted: s.color.textWeak,
            textDottedHover: s.color.text,
            textDottedActive: s.color.text,

            textLink: s.color.theme600,
            textLinkHover: s.color.theme600,
            textLinkActive: s.color.theme600,

            textCritical: s.color.error600,
            textCriticalHover: s.color.error600,
            textCriticalActive: s.color.error600,

            textActivated: s.color.theme700,
            textActivatedHover: s.color.theme700,
            textActivatedActive: s.color.theme700,

            textDisabled: s.color.textDisabled,

            borderPrimary: s.color.theme700,
            borderPrimaryHover: s.color.theme700,
            borderPrimaryActive: s.color.theme800,
            borderPrimaryLoading: s.color.theme800,
            borderPrimaryFocus: s.color.theme700,
            borderPrimaryDisabled: s.color.borderDisabled,

            borderSecondary: s.color.gray300,
            borderSecondaryHover: s.color.gray400,
            borderSecondaryActive: s.color.gray400,
            borderSecondaryLoading: s.color.gray400,
            borderSecondaryFocus: s.color.borderFocus,
            borderSecondaryDisabled: s.color.borderDisabled,

            borderDestructive: s.color.error700,
            borderDestructiveHover: s.color.error800,
            borderDestructiveActive: s.color.error800,
            borderDestructiveLoading: s.color.error800,
            borderDestructiveFocus: s.color.error700,
            borderDestructiveDisabled: s.color.borderDisabled,

            borderGhost: s.color.transparent,
            borderGhostHover: s.color.transparent,
            borderGhostActive: s.color.transparent,
            borderGhostLoading: s.color.transparent,
            borderGhostFocus: s.color.borderFocus,
            borderGhostDisabled: s.color.transparent,

            borderDotted: s.color.gray300,
            borderDottedHover: s.color.gray400,
            borderDottedActive: s.color.gray400,
            borderDottedLoading: s.color.gray400,
            borderDottedFocus: s.color.borderFocus,
            borderDottedDisabled: s.color.borderDisabled,

            borderLink: s.color.transparent,
            borderLinkHover: s.color.transparent,
            borderLinkActive: s.color.transparent,
            borderLinkLoading: s.color.transparent,
            borderLinkFocus: s.color.borderFocus,
            borderLinkDisabled: s.color.transparent,

            borderCritical: s.color.transparent,
            borderCriticalHover: s.color.transparent,
            borderCriticalActive: s.color.transparent,
            borderCriticalLoading: s.color.transparent,
            borderCriticalFocus: s.color.borderFocus,
            borderCriticalDisabled: s.color.transparent,

            borderActivated: s.color.transparent,
            borderActivatedHover: s.color.transparent,
            borderActivatedActive: s.color.transparent,
            borderActivatedLoading: s.color.transparent,
            borderActivatedFocus: s.color.borderFocus,
            borderActivatedDisabled: s.color.borderDisabled,

            iconPrimary: s.color.iconInverse,
            iconPrimaryHover: s.color.iconInverse,
            iconPrimaryActive: s.color.iconInverse,

            iconSecondary: s.color.icon,
            iconSecondaryHover: s.color.iconHover,
            iconSecondaryActive: s.color.iconActive,

            iconDestructive: s.color.iconInverse,
            iconDestructiveHover: s.color.iconInverse,
            iconDestructiveActive: s.color.iconInverse,

            iconGhost: s.color.icon,
            iconGhostHover: s.color.iconHover,
            iconGhostActive: s.color.iconActive,

            iconDotted: s.color.icon,
            iconDottedHover: s.color.iconHover,
            iconDottedActive: s.color.iconActive,

            iconLink: s.color.iconTheme,
            iconLinkHover: s.color.iconTheme,
            iconLinkActive: s.color.iconTheme,

            iconCritical: s.color.iconError,
            iconCriticalHover: s.color.iconError,
            iconCriticalActive: s.color.iconError,

            iconActivated: s.color.iconThemeStrong,
            iconActivatedHover: s.color.iconThemeStrong,
            iconActivatedActive: s.color.iconThemeStrong,

            iconDisabled: s.color.iconDisabled,
        },
        padding: {
            '2xs': s.space.s,
            xs: s.space.m,
            s: s.space.m,
            m: s.space.l,
            l: s.space.xl,
            '2xsIconOnly': s.space.xs,
            xsIconOnly: pxToRem(5),
            sIconOnly: s.space.s,
            mIconOnly: pxToRem(10),
            textS: s.space.xs,
            textM: s.space.s,
        },
        gap: {
            '2xs': s.space.xs,
            xs: s.space.xs,
            s: s.space.s,
            m: s.space.m,
            l: s.space.m,
            vertical: s.space.xl,
        },
        radius: {
            '2xs': s.radius.s,
            xs: s.radius.s,
            s: s.radius.m,
            m: s.radius.l,
            l: s.radius.xl,
        },
        sizes: {
            l: pxToRem(44),
            m: pxToRem(36),
            s: pxToRem(28),
            xs: pxToRem(24),
            '2xs': pxToRem(20),
            '2xsIcon': 'xs',
            xsIcon: 's',
            sIcon: 'm',
            mIcon: 'm',
            lIcon: 'm',
            '2xsSpinner': '3xs',
            xsSpinner: '2xs',
            sSpinner: 'xs',
            mSpinner: 'xs',
            lSpinner: 'xs',
        },
        fontSizes: {
            '2xs': s.fontSizes.bodyS,
            xs: s.fontSizes.bodyM,
            s: s.fontSizes.bodyM,
            m: s.fontSizes.bodyM,
            l: s.fontSizes.bodyL,
        },
        fontWeights: {
            '2xs': s.fontWeights.bodyMedium,
            xs: s.fontWeights.bodyMedium,
            s: s.fontWeights.bodyMedium,
            m: s.fontWeights.bodyBold,
            l: s.fontWeights.bodyBold,
        },
        borderWidths: {
            '2xs': s.borderWidths[1],
            xs: s.borderWidths[1],
            s: s.borderWidths[1],
            m: s.borderWidths[1],
            l: s.borderWidths[1],
        },
        borderStyles: {
            primary: s.borderStyles.base,
            secondary: s.borderStyles.base,
            destructive: s.borderStyles.base,
            ghost: s.borderStyles.base,
            link: s.borderStyles.base,
            dotted: s.borderStyles.dashed,
            activated: s.borderStyles.base,
            critical: s.borderStyles.base,
        },
        shadows: {
            secondary: s.shadows.xs,
            focus: s.shadows.focus,
        },
    }
}
