import { useDetailViewContext } from 'features/views/DetailView/hooks/useDetailViewContext'
import { DetailViewHeaderSpacious } from 'features/views/DetailView/types'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

export function useDetailViewSpaciousHeaderPrimaryHighlightsState() {
    const { header, fields } = useDetailViewContext()!

    const primaryHighlights = (header as DetailViewHeaderSpacious)?.primaryHighlights

    const fieldsByApiName = fields.reduce((acc, field) => {
        return acc.set(field.api_name, field)
    }, new Map<string, FieldDto>())

    const primaryHighlightFields = primaryHighlights?.reduce((acc, highlight) => {
        const field = fieldsByApiName.get(highlight.fieldApiName)
        if (field) {
            acc.push(field)
        }
        return acc
    }, [] as FieldDto[])

    return useDeepEqualsMemoValue({
        fields: primaryHighlightFields,
    })
}
