import React, { useState } from 'react'

import shortid from 'shortid'

import {
    useAgentInstructions,
    useCreateAgentInstructions,
    useDeleteAgentInstructions,
} from 'data/hooks/agents/instructions'
import { Agent, AgentInstructions } from 'data/hooks/agents/types'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from 'ui/components/Dropdown'
import { IconName } from 'ui/components/Icon/Icon'
import { Body } from 'ui/components/Text'
import { stopPropagation } from 'ui/helpers/utilities'

import { EditAgentConnections } from './connections/EditAgentConnections'
import { EditAgentGeneral } from './general/EditAgentGeneral'
import { EditAgentInstructions } from './instructions/EditAgentInstructions'
import { EditAgentSkills } from './skills/EditAgentSkills'

type SettingsTab =
    | 'instructions'
    | 'skills'
    | 'data'
    | 'tools'
    | 'connections'
    | 'settings'
    | string

type AgentSettingsProps = {
    agent: Agent
}

export const AgentSettings: React.FC<AgentSettingsProps> = ({ agent }) => {
    const [currentTab, setCurrentTab] = useState<SettingsTab>('settings')
    const { data: instructions } = useAgentInstructions(agent._sid)

    return (
        <Box flex height="full" width="full">
            {/* Sidebar */}
            <Box
                style={{ width: '250px', flexShrink: 0 }}
                background="surface"
                borderRightWidth="base"
                borderStyle="base"
                borderColor="borderWeak"
                flex
                column
                p="l"
                pl="3xl"
            >
                <NavGroup label="General">
                    <NavItem
                        id="settings"
                        label="Settings"
                        icon="Settings"
                        isSelected={currentTab === 'settings'}
                        onSelect={() => setCurrentTab('settings')}
                    />
                    <NavItem
                        id="skills"
                        label="Skills"
                        icon="Zap"
                        isSelected={currentTab === 'skills'}
                        onSelect={() => setCurrentTab('skills')}
                    />
                    <NavItem
                        id="connections"
                        label="Connections"
                        icon="Plug"
                        isSelected={currentTab === 'connections'}
                        onSelect={() => setCurrentTab('connections')}
                    />
                </NavGroup>
                <NavGroup label="Instructions">
                    {instructions
                        ?.sort((a, b) => a.name.localeCompare(b.name))
                        ?.map((i) => (
                            <NavItem
                                key={i._sid}
                                id={i._sid}
                                label={i.name}
                                icon="FileText"
                                isSelected={currentTab === i._sid}
                                onSelect={() => setCurrentTab(i._sid)}
                                rightSlotContent={
                                    <AgentInstructionsItemDropdown
                                        instructions={i}
                                        selectInstructions={(i) => setCurrentTab(i._sid)}
                                        onDeleted={() => setCurrentTab('settings')}
                                    />
                                }
                            />
                        ))}

                    <Button
                        variant="ghost"
                        startIcon={{ name: 'Plus' }}
                        size="s"
                        alignSelf="flex-start"
                        onClick={() => setCurrentTab('new_instructions')}
                        mt="m"
                    >
                        Add instructions...
                    </Button>
                </NavGroup>
            </Box>
            {/* Content Area */}
            <Box flex grow pr="7xl" p="xl" shrink>
                {currentTab === 'skills' && <EditAgentSkills agent={agent} />}
                {currentTab === 'data' && <Body>Data Access content</Body>}
                {currentTab === 'tools' && <Body>Tools content</Body>}
                {currentTab === 'connections' && <EditAgentConnections agent={agent} />}
                {currentTab === 'settings' && <EditAgentGeneral agent={agent} />}
                {currentTab?.startsWith('ins') && (
                    <EditAgentInstructions
                        key={currentTab!}
                        agent={agent}
                        instructions={instructions?.find((i) => i._sid === currentTab)}
                    />
                )}
                {currentTab === 'new_instructions' && (
                    <EditAgentInstructions agent={agent} onSaved={(o) => setCurrentTab(o._sid)} />
                )}
            </Box>
        </Box>
    )
}

function NavGroup({ label, children }: { label: string; children: React.ReactNode }) {
    return (
        <Box flex column mb="l">
            <Body flex color="textWeaker" pl="m" weight="bold" size="m" mb="s">
                {label}
            </Body>
            {children}
        </Box>
    )
}

function NavItem({
    id,
    label,
    icon,
    isSelected,
    onSelect,
    rightSlotContent,
}: {
    id: SettingsTab
    label: string
    icon: IconName
    isSelected: boolean
    onSelect: () => void
    rightSlotContent?: React.ReactNode
}) {
    return (
        <Button
            size="s"
            key={id}
            startIcon={{ name: icon }}
            variant={isSelected ? 'activated' : 'ghost'}
            justifyContent="flex-start"
            width="full"
            onClick={() => onSelect()}
            style={{ justifyContent: 'flex-start' }}
        >
            <Body trim>{label}</Body>
            <Box grow />
            {rightSlotContent}
        </Button>
    )
}

type AgentInstructionsItemDropdownProps = React.ComponentPropsWithoutRef<typeof DropdownButton> & {
    instructions: AgentInstructions
    selectInstructions: (instructions: AgentInstructions) => void
    onDeleted: () => void
}

export const AgentInstructionsItemDropdown: React.FC<AgentInstructionsItemDropdownProps> = ({
    instructions,
    selectInstructions,
    onDeleted,
    ...props
}) => {
    const { mutateAsync: deleteInstructions } = useDeleteAgentInstructions(instructions.agent_id)
    const { mutateAsync: createInstructions } = useCreateAgentInstructions(instructions.agent_id)
    const tempId = shortid()
    const handleDuplicate = async () => {
        const newInstructions = await createInstructions({
            ...instructions,
            name: `${instructions.name} (Copy)`,
            _sid: tempId,
            id: tempId,
        })
        selectInstructions(newInstructions)
    }
    const handleDelete = async () => {
        await deleteInstructions(instructions._sid)
        onDeleted()
    }
    return (
        <Dropdown modal={false}>
            <DropdownButton
                size="2xs"
                variant="ghost"
                startIcon={{
                    name: 'MoreHorizontal',
                }}
                noShrink
                {...props}
            />
            <DropdownContent style={{ width: '230px' }} onClick={stopPropagation}>
                <DropdownItem
                    startIcon={{ name: 'Copy' }}
                    label="Duplicate"
                    onClick={handleDuplicate}
                />
                <DropdownItem
                    variant="destructive"
                    startIcon={{ name: 'Trash2' }}
                    label="Delete"
                    onClick={handleDelete}
                />
            </DropdownContent>
        </Dropdown>
    )
}
