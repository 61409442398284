import { useMemo } from 'react'

import { useThreadViewContext } from 'features/views/ListView/ThreadView/hooks/useThreadViewContext'
import { ThreadViewAttribute } from 'features/views/ListView/ThreadView/types'
import { useStackerUsersObject } from 'features/workspace/stackerUserUtils'

import { ResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

type UseRecordLinksAttributeStateOptions = {
    attribute: ThreadViewAttribute
    value?: string | string[]
    isLoading?: boolean
}

const titleSizeMapping = {
    small: 's' as const,
    medium: 'm' as const,
    large: 'l' as const,
}

export function useRecordLinksAttributeState(options: UseRecordLinksAttributeStateOptions) {
    const { value, isLoading, attribute } = options
    const { field, type } = attribute

    const { dereferencedRecords, titleSize } = useThreadViewContext()

    const isEmpty = !isLoading && (!value || (Array.isArray(value) && value.length < 1))

    const usersObject = useStackerUsersObject()
    const objectSid = field.link_target_object_id
    const isUsersObject = objectSid === usersObject?._sid

    const isMessage = type === 'message'
    const isContent = type === 'content'
    const displayAsTag = !isMessage && !isContent && !isUsersObject

    const size: ResponsiveValue<'s' | 'm' | 'l'> = useMemo(() => {
        switch (type) {
            case 'title':
                return titleSizeMapping[titleSize]
            case 'message':
                return 'm'
            case 'content':
                return {
                    mobile: 's',
                    tablet: 'm',
                }
            default:
                return 's'
        }
    }, [type, titleSize])

    return useMemo(
        () => ({
            dereferencedRecords,
            isEmpty,
            displayAsTag,
            size,
        }),
        [dereferencedRecords, displayAsTag, isEmpty, size]
    )
}
