import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { Agent, AgentSkill, SystemTool } from 'data/hooks/agents/types'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Field } from 'ui/components/Field'
import { Modal, ModalContent, ModalHeader } from 'ui/components/Modal'
import { Body } from 'ui/components/Text'

import { EditSkillModal } from './EditSkillModal'
import { SkillListItem } from './SkillListItem'

export function EditEnabledSkills({
    agentSid,
    skills = [],
    preventEdit = false,
}: {
    agentSid: string
    skills: AgentSkill[]
    preventEdit?: boolean
}) {
    const { setValue, watch } = useFormContext<Partial<Agent>>()
    const selectedSkills = watch('available_skills') || []
    const [isModalOpen, setIsModalOpen] = useState(false)
    console.log('#data', watch())
    const handleAddSkill = (skill: SystemTool | AgentSkill) => {
        if ('api_name' in skill && !selectedSkills.includes(skill.api_name)) {
            setValue('available_skills', [...selectedSkills, skill.api_name], { shouldDirty: true })
        }
    }

    const existingSkills = skills.filter(
        (skill) => 'api_name' in skill && selectedSkills.includes(skill.api_name)
    )

    const availableSkills = skills.filter(
        (skill) => 'api_name' in skill && !selectedSkills.includes(skill.api_name)
    )

    return (
        <Field label="Enabled Skills">
            <Box flex fullWidth alignItems="flex-start" column gap="m">
                <Box gap="xs" flex fullWidth column overflowY="auto">
                    {existingSkills.map((skill) => (
                        <EditSkillModal
                            key={skill._sid}
                            agentId={agentSid}
                            skill={skill}
                            trigger={
                                <SkillListItem
                                    key={skill.api_name}
                                    skill={skill}
                                    onClear={() => {
                                        setValue(
                                            'available_skills',
                                            selectedSkills.filter((s) => s !== skill.api_name),
                                            { shouldDirty: true }
                                        )
                                    }}
                                    onClick={(e) => {
                                        if (preventEdit) {
                                            e.stopPropagation()
                                            e.preventDefault()
                                        }
                                    }}
                                ></SkillListItem>
                            }
                        />
                    ))}
                    {!existingSkills.length && (
                        <Body color="textWeaker">No skills added. Add a skill to get started.</Body>
                    )}
                </Box>
                <Button
                    variant="secondary"
                    size="s"
                    startIcon={{ name: 'Plus' }}
                    onClick={() => setIsModalOpen(true)}
                >
                    Select Skill
                </Button>
            </Box>
            <Modal open={isModalOpen} onOpenChange={() => setIsModalOpen(false)}>
                <ModalContent>
                    <ModalHeader title="Select Skill" />

                    <Box gap="m" flex column pt="s" p="3xl">
                        {availableSkills.map((skill) => (
                            <SkillListItem
                                key={'modal' + skill.api_name}
                                onClick={() => {
                                    handleAddSkill(skill)
                                    setIsModalOpen(false)
                                }}
                                style={{ minWidth: 0, cursor: 'pointer' }}
                                skill={skill}
                                maxWidth="full"
                            />
                        ))}
                        {!availableSkills.length && (
                            <Body>No skills available. Please add a skill first.</Body>
                        )}
                    </Box>
                </ModalContent>
            </Modal>
        </Field>
    )
}
