import { useCallback, useMemo, useRef } from 'react'

import isEqual from 'fast-deep-equal'
import { PreviewRecordListItem } from 'v2/views/List/PreviewRecord/PreviewRecordContext'

import { getUrl } from 'app/UrlService'
import { useBreadcrumbsContext } from 'features/breadcrumbs/hooks/useBreadcrumbsContext'
import { ActionContextMenuHandle } from 'features/views/ListView/Actions/ActionContextMenu'
import { useRecordActionButtons } from 'features/views/ListView/Actions/hooks/useRecordActionButtons'
import { ThreadViewMessageContentStyles } from 'features/views/ListView/ThreadView/ThreadView.css'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useThreadViewContext } from './useThreadViewContext'

type UseThreadViewMessageStateProps = {
    record?: RecordDto
}

export function useThreadViewMessageState({ record }: UseThreadViewMessageStateProps) {
    const {
        object,
        stack,
        records,
        pendingRecords,
        onRecordClick,
        view,
        requestIncludedFields,
        titleField,
        eyebrowField,
        contentField,
        senderField,
        profileImageField,
        footerLeftField,
        footerRightField,
    } = useThreadViewContext()

    const recordsRef = useRef(records)
    recordsRef.current = records

    const recordDetailUrl = useMemo(() => {
        return getUrl(`${object?.url}/view/${record?._sid}`, stack)
    }, [object?.url, record?._sid, stack])

    const to = record && onRecordClick !== 'none' ? recordDetailUrl : ''

    const { navigateTo } = useBreadcrumbsContext()

    const onClick = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            // Only open side peek on regular left click.
            if (e.ctrlKey || e.metaKey || e.button === 1 || !record?._sid) return

            e.preventDefault()

            // If the user is selecting text, don't open the side peek.
            const selection = window.getSelection()
            if (!!selection?.toString().length) {
                return
            }

            // Check for any record click overrides.
            switch (onRecordClick) {
                case 'none':
                    return
                case 'new_tab':
                    navigateTo({
                        type: 'detail',
                        recordSid: record._sid,
                        object: object!,
                        openAs: 'tab',
                        stack,
                    })
                    return
                case 'detail':
                    navigateTo({
                        type: 'detail',
                        recordSid: record._sid,
                        object: object!,
                        openAs: 'full',
                        stack,
                    })
                    return
                case 'preview': {
                    const records = recordsRef.current
                    const recordListItems: PreviewRecordListItem[] | undefined = records?.map(
                        (r) => ({
                            recordId: r._sid,
                        })
                    )

                    navigateTo({
                        type: 'detail',
                        recordSid: record._sid,
                        object: object!,
                        openAs: 'preview',
                        stack,
                        previewParams: {
                            partOfRecordList: recordListItems
                                ? {
                                      direction: 'vertical',
                                      items: recordListItems,
                                  }
                                : undefined,
                        },
                    })
                }
            }
        },
        [record?._sid, onRecordClick, navigateTo, object, stack]
    )

    const actions = useRecordActionButtons({
        record: record!,
        object,
        view,
        showSystemActions: true,
    })
    const actionsMemo = useDeepEqualsMemoValue(actions.map((a) => a.action))
    const actionsRef = useRef(actionsMemo)
    actionsRef.current = actionsMemo

    const messageRef = useRef<HTMLDivElement>(null)

    const actionContextMenuRef = useRef<ActionContextMenuHandle>(null)

    const onContextMenu = useCallback((e: React.MouseEvent<HTMLElement>) => {
        const message = messageRef.current
        if (!message) return

        // Don't open the context menu if there are no actions enabled.
        const actions = actionsRef.current
        if (!actions.length) return

        const target = e.target as HTMLElement
        const closestInteractive = target.closest('a') as HTMLElement | null
        if (
            closestInteractive &&
            closestInteractive.matches(`${ThreadViewMessageContentStyles.styleFunction({})} a`)
        ) {
            // Don't open the context menu if the click was on a link.
            return
        }

        // Don't open the context menu if the user selected text.
        const selection = window.getSelection()
        if (!!selection?.toString().length) {
            return
        }

        e.preventDefault()
        e.stopPropagation()

        actionContextMenuRef?.current?.openAt(e.clientX, e.clientY)
    }, [])

    const additionalEditFields = useMemo(() => {
        const fields = new Map<string, FieldDto>()

        if (titleField) {
            fields.set(titleField._sid, titleField)
        }

        if (footerLeftField) {
            fields.set(footerLeftField._sid, footerLeftField)
        }

        if (footerRightField) {
            fields.set(footerRightField._sid, footerRightField)
        }

        if (eyebrowField) {
            fields.set(eyebrowField._sid, eyebrowField)
        }

        if (contentField) {
            fields.set(contentField._sid, contentField)
        }

        if (senderField) {
            fields.set(senderField._sid, senderField)
        }

        if (profileImageField) {
            fields.set(profileImageField._sid, profileImageField)
        }

        return Array.from(fields.values())
    }, [
        titleField,
        footerLeftField,
        footerRightField,
        eyebrowField,
        contentField,
        senderField,
        profileImageField,
    ])

    const isPending = record && pendingRecords.some((r) => r._sid === record._sid)

    // Determine if the message is from the sender.
    let isFromSender = false

    const isSplitLayout = view?.options.threadLayout === 'split'
    if (isSplitLayout) {
        // We sort values in the same way to make sure the comparison is accurate.
        let senderFieldValue = !!senderField
            ? record?.[senderField?.api_name] || undefined
            : undefined
        if (Array.isArray(senderFieldValue)) {
            senderFieldValue = senderFieldValue.sort()
        }

        let requiredSenderFieldValue = view?.options.threadSender?.value || undefined
        if (Array.isArray(requiredSenderFieldValue)) {
            requiredSenderFieldValue = requiredSenderFieldValue.sort()
        }

        isFromSender = isEqual(senderFieldValue, requiredSenderFieldValue)
    }

    return useMemo(
        () => ({
            to,
            onClick,
            messageRef,
            onContextMenu,
            actionContextMenuRef,
            additionalEditFields,
            isPending,
            actionButtons: actionsMemo,
            includeFields: requestIncludedFields,
            isFromSender,
        }),
        [
            to,
            onClick,
            onContextMenu,
            additionalEditFields,
            isPending,
            actionsMemo,
            requestIncludedFields,
            isFromSender,
        ]
    )
}
