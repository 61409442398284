import { WidgetSchema } from 'features/views/LayoutEditor/types'
import {
    getRequiredFieldsFromActionButtons,
    getRequiredFieldsFromFilters,
} from 'features/views/LayoutEditor/utils'

import { BannerWidget, BannerWidgetAdminControls } from './BannerWidget'
import { BannerWidgetType } from './bannerWidgetTypes'

export const BannerWidgetMeta: WidgetSchema<BannerWidgetType> = {
    category: 'data',
    label: 'Banner',
    icon: 'AlertTriangle',
    component: BannerWidget,
    adminControlsComponent: BannerWidgetAdminControls,
    defaultAttrs: {
        style: 'app',
    },
    requireFields: (attrs, fields) => {
        const { actionButton, visibilityFilters = [] } = attrs

        let fieldsForButton: string[] = []
        if (!!actionButton) {
            fieldsForButton = getRequiredFieldsFromActionButtons([actionButton], fields)
        }

        const fieldsForFilters = getRequiredFieldsFromFilters(visibilityFilters, fields)

        return [...fieldsForButton, ...fieldsForFilters]
    },
}
