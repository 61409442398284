import { useCallback, useEffect, useMemo, useRef } from 'react'

import { ListViewUrlParams } from 'features/views/ListView/hooks/useListViewUrlParams'
import { useSyncListViewUrlParams } from 'features/views/ListView/hooks/useSyncListViewUrlParams'
import { useSortContext } from 'features/views/ListView/Sort/useSortContext'
import { useListViewContext } from 'features/views/ListView/useListViewContext'

export function useThreadViewSort() {
    const { urlParams } = useListViewContext()

    const urlParamsRef = useRef(urlParams)
    urlParamsRef.current = urlParams

    const { sortBy, setSortBy, availableFields, sortType, defaultSortBy } = useSortContext()

    const sortTypeRef = useRef(sortType)
    sortTypeRef.current = sortType

    const onParamLoad = useCallback(
        (urlParams: ListViewUrlParams) => {
            const { sortField, sortDirection } = urlParams
            if (!sortField) return

            setSortBy({
                id: sortField,
                desc: sortDirection === 'desc',
            })
        },
        [setSortBy]
    )
    const onParamSync = useCallback(
        (urlParams: ListViewUrlParams) => {
            urlParams.setParams({
                sortField: sortBy?.id,
                sortDirection: sortBy?.desc ? 'desc' : 'asc',
            })
        },
        [sortBy?.desc, sortBy?.id]
    )
    useSyncListViewUrlParams(onParamLoad, onParamSync)

    useEffect(() => {
        if (!sortBy?.id || availableFields.length < 1) return

        const selectedField = availableFields.find((field) => field.api_name === sortBy?.id)
        if (selectedField) return

        // If the current field is not in the view, reset it to the admin field.
        setSortBy(undefined)
    }, [availableFields, setSortBy, sortBy?.id, sortType])

    return useMemo(
        () => ({
            sortBy,
            setSortBy,
            defaultSortBy,
        }),
        [setSortBy, sortBy, defaultSortBy]
    )
}
