import React from 'react'

import { isEqual } from 'lodash'

import { Box } from 'ui/components/Box'
import { Skeleton } from 'ui/components/Skeleton'
import { Body } from 'ui/components/Text'
import { Tooltip } from 'ui/components/Tooltip'
import { ResponsiveValue, useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useDateAttributeDisplayState } from './hooks/useDateAttributeDisplayState'
import { AttributeDisplayComponent } from './types'

type DateAttributeDisplayProps = React.ComponentPropsWithoutRef<typeof Body> & {
    isLoading?: boolean
    showAsRelativeTime?: boolean
    showAbsoluteDateTooltip?: ResponsiveValue<boolean>
}

export const DateAttributeDisplay: AttributeDisplayComponent<string, DateAttributeDisplayProps> =
    React.memo(function DateAttributeDisplay({
        value,
        field,
        isLoading,
        showAsRelativeTime,
        maxLines,
        showAbsoluteDateTooltip,
        ...props
    }) {
        const effectiveMaxLines = useResponsiveValue(maxLines)
        const effectiveShowAbsoluteDateTooltip = useResponsiveValue(showAbsoluteDateTooltip)

        const { relativeDate, absoluteDate } = useDateAttributeDisplayState({
            value,
            field,
            isLoading,
            showAsRelativeTime,
        })

        const content = (
            <Box minWidth={0} tabIndex={0} trim={!isLoading && effectiveMaxLines === 1}>
                <Skeleton isLoading={isLoading} maxWidth="full" minWidth={0}>
                    <Body size="m" weight="regular" maxLines={effectiveMaxLines} {...props}>
                        {showAsRelativeTime ? relativeDate : absoluteDate}
                    </Body>
                </Skeleton>
            </Box>
        )

        if (effectiveShowAbsoluteDateTooltip) {
            return (
                <Tooltip content={absoluteDate} side="bottom" zIndex={200} asChild>
                    {content}
                </Tooltip>
            )
        }

        return content
    }, isEqual)
