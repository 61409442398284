import React from 'react'

import { Truncate } from '@re-dev/react-truncate'

import { Link } from 'ui/components/Link'
import { Skeleton } from 'ui/components/Skeleton'
import { Tooltip } from 'ui/components/Tooltip'

import { useAttributeContext } from './hooks/useAttributeContext'
import { useUrlAttributeEditorState } from './hooks/useUrlAttributeEditorState'
import { useUrlAttributeState } from './hooks/useUrlAttributeState'
import { AttributeTextInput } from './AttributeTextInput'
import { BaseAttribute } from './BaseAttribute'
import { FIELD_VALIDATION_REQUIRED_ERROR_MESSAGE } from './constants'
import { InlineValueWrapper } from './InlineValueWrapper'
import { FieldsWidgetAttributeComponent } from './types'

type UrlAttributeProps = {}

export const UrlAttribute: FieldsWidgetAttributeComponent<UrlAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const { isEditingValue } = useAttributeContext<string>()

    if (isEditingValue) {
        return (
            <UrlAttributeEditor
                key="url-attribute"
                field={field}
                isLoading={isLoading}
                {...props}
            />
        )
    }

    return <UrlAttributeValue key="url-attribute" field={field} isLoading={isLoading} {...props} />
}

const UrlAttributeValue: FieldsWidgetAttributeComponent<UrlAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const {
        isEmpty,
        isEditable,
        href,
        to,
        label,
        valueElementRef,
        attributeWidth,
        target,
        handleClick,
    } = useUrlAttributeState({ field, isLoading })

    return (
        <BaseAttribute
            key="url-attribute"
            {...props}
            field={field}
            isEmpty={isEmpty}
            isLoading={isLoading}
            cursor={isEditable ? 'text' : undefined}
        >
            {(!!href || !!to) && (
                <InlineValueWrapper ref={valueElementRef}>
                    {!!attributeWidth && (
                        <Skeleton isLoading={isLoading}>
                            <Tooltip
                                asChild
                                content={href || to || label}
                                zIndex={200}
                                side="bottom"
                                align="center"
                            >
                                <Link
                                    href={href}
                                    to={to}
                                    size="m"
                                    target={target}
                                    onClick={handleClick}
                                    tabIndex={0}
                                >
                                    <Truncate middle width={attributeWidth} end={7}>
                                        {label}
                                    </Truncate>
                                </Link>
                            </Tooltip>
                        </Skeleton>
                    )}
                </InlineValueWrapper>
            )}
        </BaseAttribute>
    )
}

const UrlAttributeEditor: FieldsWidgetAttributeComponent<UrlAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const { value, onInputBlur, onInputChange, onInputKeyDown, onClearValue, isRequired } =
        useUrlAttributeEditorState()

    const isEmpty = !isLoading && !value

    return (
        <BaseAttribute
            {...props}
            field={field}
            isEmpty={isEmpty}
            isLoading={isLoading}
            isClearValueButtonDisabled={isEmpty}
            onClearValue={onClearValue}
            validationError={
                isRequired && isEmpty ? FIELD_VALIDATION_REQUIRED_ERROR_MESSAGE : undefined
            }
        >
            <AttributeTextInput
                autoFocus={true}
                onBlur={onInputBlur}
                onChange={onInputChange}
                onKeyDown={onInputKeyDown}
                value={value}
            />
        </BaseAttribute>
    )
}
