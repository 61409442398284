import React from 'react'

import { useDetailViewHeaderState } from './hooks/useDetailViewHeaderState'
import { DetailViewSlimHeader } from './slim/DetailViewSlimHeader'
import { DetailViewSpaciousHeader } from './spacious/DetailViewSpaciousHeader'

type DetailViewHeaderProps = {}

export const DetailViewHeader: React.FC<DetailViewHeaderProps> = () => {
    const { headerType } = useDetailViewHeaderState()

    switch (headerType) {
        case 'slim':
            return <DetailViewSlimHeader />
        case 'spacious':
            return <DetailViewSpaciousHeader />
        default:
            return null
    }
}
