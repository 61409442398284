import { useMemo } from 'react'

import { useWorkflowSchema } from 'data/hooks/workflows/workflows'
import { USER_TABLE_KEY } from 'features/workflows/common'
import { useStackerUsersObject } from 'features/workspace/stackerUserUtils'

import { useAgentSkillActionFinalSchema } from './useAgentSkillActionFinalSchema'
import { useAPIRequestActionFinalSchema } from './useAPIRequestActionFinalSchema'
import { useCreateTaskActionFinalSchema } from './useCreateTaskActionFinalSchema'
import { useFindRecordsActionFinalSchema } from './useFindRecordsActionFinalSchema'
import { useGetRecordActionFinalSchema } from './useGetRecordActionFinalSchema'
import { useRecordChangeActionFinalSchema } from './useRecordChangeActionFinalSchema'
import { useReturnDataActionFinalSchema } from './useReturnDataActionFinalSchema'
import { useSetVariablesActionFinalSchema } from './useSetVariablesActionFinalSchema'

export function usePreparedActionSchema(
    action: WorkflowActionConfig,
    existingSchema: WorkflowSchemaStateItem[]
): WorkflowSchemaActionType | undefined {
    const getRecordChangeActionSchema = useRecordChangeActionFinalSchema()
    const getCreateTaskActionSchema = useCreateTaskActionFinalSchema()
    const getFindRecordsActionSchema = useFindRecordsActionFinalSchema()
    const getGetRecordActionSchema = useGetRecordActionFinalSchema()
    const getAPIRequestActionSchema = useAPIRequestActionFinalSchema()
    const getSetVariablesActionSchema = useSetVariablesActionFinalSchema()
    const getReturnDataActionSchema = useReturnDataActionFinalSchema()
    const getAgentSkillActionSchema = useAgentSkillActionFinalSchema()
    const { data: schema } = useWorkflowSchema()
    const usersObject = useStackerUsersObject()
    return useMemo(() => {
        if (!schema) return undefined

        const actionProcessors: Record<
            any,
            (
                action: WorkflowActionConfig,
                existingSchema: WorkflowSchemaStateItem[]
            ) => WorkflowSchemaNodeType | undefined
        > = {
            create_record: getRecordChangeActionSchema,
            update_record: getRecordChangeActionSchema,
            create_task: getCreateTaskActionSchema,
            find_records: getFindRecordsActionSchema,
            get_record: getGetRecordActionSchema,
            api_request: getAPIRequestActionSchema,
            set_variables: getSetVariablesActionSchema,
            return_data: getReturnDataActionSchema,
            invoke_agent_skill: getAgentSkillActionSchema,
        }

        const actionSchema = schema.nodes.find(
            (t) => t.id === action.action_type
        ) as WorkflowSchemaActionType
        const result =
            (actionProcessors[action.action_type]?.(action, existingSchema) as
                | WorkflowSchemaActionType
                | undefined) ?? actionSchema

        // general processing of inputs common to all action types.
        processInputs(result, usersObject)
        return result
    }, [
        schema,
        getRecordChangeActionSchema,
        getCreateTaskActionSchema,
        getFindRecordsActionSchema,
        getGetRecordActionSchema,
        getAPIRequestActionSchema,
        getSetVariablesActionSchema,
        getReturnDataActionSchema,
        getAgentSkillActionSchema,
        action,
        existingSchema,
        usersObject,
    ])
}

function processInputs(schema: WorkflowSchemaActionType, usersObject?: ObjectDto) {
    for (const input of schema.inputs) {
        // If this is a lookup to the user table, replace the lookup target with the actual object id
        if (
            input.type === 'lookup' &&
            input.extra_options?.link_target_object_id === USER_TABLE_KEY
        ) {
            input.extra_options.link_target_object_id = usersObject?._sid
        }
    }
}
