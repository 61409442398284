import { WidgetSchema } from 'features/views/LayoutEditor/types'

import { LinkBlocksWidget } from './LinkBlocksWidget'
import { LinkBlocksWidgetAdminControls } from './LinkBlocksWidgetAdminControls'
import { LinkBlocksWidgetType } from './types'

export const LinkBlocksWidgetMeta: WidgetSchema<LinkBlocksWidgetType> = {
    category: 'data',
    label: 'Link blocks',
    icon: 'Link',
    component: LinkBlocksWidget,
    adminControlsComponent: LinkBlocksWidgetAdminControls,
    defaultAttrs: {
        style: 'regular',
        size: 'regular',
        links: [],
    },
}
