import React, { useContext } from 'react'

import { ListViewSort } from 'features/views/ListView/Sort/types'
import { ThreadViewAttribute } from 'features/views/ListView/ThreadView/types'

export type ThreadViewInternalContextValue = {
    records?: RecordDto[]
    dereferencedRecords?: RecordDto[]
    recordCount: number
    isLoading: boolean
    isFetchingSlow: boolean
    retryFetchRecords: () => Promise<void>
    hasError: boolean
    object: ObjectDto
    stack: StackDto
    view: ViewDto
    currentPageIndex: number
    setCurrentPageIndex: (pageIndex: number) => void
    pageSize: number
    sortBy?: ListViewSort
    setSortBy: (sortBy?: ListViewSort) => void
    defaultSortBy?: ListViewSort
    clearFilters: () => void
    requestFilters: Filter[]
    requestIncludedFields: string[]
    hasFilters: boolean
    isEmbedded: boolean
    onRecordClick: Required<ListViewOptions['onRecordClick']>
    profileImageField?: FieldDto
    titleField?: FieldDto
    eyebrowField?: FieldDto
    contentField?: FieldDto
    senderField?: FieldDto
    footerLeftField?: FieldDto
    footerRightField?: FieldDto
    header: ListViewHeader
    titleSize: NonNullable<ListViewOptions['threadMessageTitleSize']>
    labelStyle?: ListViewOptions['threadFieldLabelStyle']
    fieldDirection?: ListViewOptions['threadFieldDirection']
    messageSize: NonNullable<ListViewOptions['threadMessageSize']>
    inlineFilterType?: ListViewInlineFilters
    availableInlineFilterFields: FieldDto[]
    allFields: FieldDto[]
    attributes: ThreadViewAttribute[]
}
export type ThreadViewContextValue = ThreadViewInternalContextValue & {
    pendingRecords: RecordDto[]
}
export const ThreadViewContext = React.createContext<ThreadViewContextValue | null>(null)

export function useThreadViewContext(): ThreadViewContextValue {
    const context = useContext(ThreadViewContext)

    if (!context) {
        throw new Error('useThreadViewContext must be used within a ThreadViewContextProvider')
    }

    return context
}
