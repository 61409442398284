import React, { forwardRef, useMemo } from 'react'

import { DropdownItemComplex } from 'ui/components/Dropdown'

import * as Parts from './Select.parts'
import { useSelectContext } from './selectContext'
import { SelectOptionItem } from './types'

type SelectOptionComplexRef = HTMLDivElement

type SelectOptionComplexProps = Omit<
    React.ComponentPropsWithoutRef<typeof Parts.ItemComplex>,
    'defaultChecked' | 'checked' | 'onCheckedChange' | 'multiSelect' | 'color'
> &
    Omit<SelectOptionItem, 'label'> & {}

export const SelectOptionComplex: React.FC<SelectOptionComplexProps> = forwardRef<
    SelectOptionComplexRef,
    SelectOptionComplexProps
    // eslint-disable-next-line unused-imports/no-unused-vars
>(({ as, value, label, onSelect, color, ...props }, ref) => {
    const { value: selectValue, setOptionState, multiSelect, close } = useSelectContext()

    const isChecked = useMemo(() => selectValue.includes(value), [selectValue, value])

    const handleCheckedChange = (checked: boolean) => {
        setOptionState(value, checked)
    }

    const handleSelect = (e: Event) => {
        if (!multiSelect) {
            // Close the dropdown when an option is selected in single select mode.
            close()
        }

        onSelect?.(e)
    }

    return (
        <Parts.ItemComplex
            as={as ?? DropdownItemComplex}
            {...props}
            checked={isChecked}
            onCheckedChange={handleCheckedChange}
            multiSelect
            role="option"
            label={label}
            ref={ref}
            onSelect={handleSelect}
        />
    )
})
