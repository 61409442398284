import React from 'react'

import { LayoutEditorWidgetControlsErrorBoundary } from 'features/views/LayoutEditor/LayoutEditorWidgetControlsErrorBoundary'

import { Box } from 'ui/components/Box'

import { useLayoutEditorWidgetControlsState } from './hooks/useLayoutEditorWidgetControlsState'
import { LayoutEditorDetailPane } from './LayoutEditorDetailPane'

type LayoutEditorWidgetControlsProps = {}

export const LayoutEditorWidgetControls: React.FC<LayoutEditorWidgetControlsProps> = () => {
    const {
        Component,
        selectedWidget,
        onBackClick,
        onChangeWidgetAttrs,
        label,
        closeDetailPane,
        openDetailPane,
    } = useLayoutEditorWidgetControlsState()

    if (!selectedWidget) return null

    return (
        <LayoutEditorDetailPane title={label} onBackClick={onBackClick}>
            <LayoutEditorWidgetControlsErrorBoundary key={Component?.name}>
                {Component && (
                    <Component
                        key={selectedWidget.id}
                        widget={selectedWidget}
                        onChange={onChangeWidgetAttrs}
                        openDetailPane={openDetailPane}
                        closeDetailPane={closeDetailPane}
                    />
                )}
                {!Component && <Box />}
            </LayoutEditorWidgetControlsErrorBoundary>
        </LayoutEditorDetailPane>
    )
}
