import React, { useCallback, useEffect, useRef, useState } from 'react'

import { AuthOptions, AuthResult } from '@nangohq/frontend'
import * as Sentry from '@sentry/react'

import { useCreateDataConnection } from 'data/hooks/dataConnections/useCreateDataConnection'
import { useCreateNangoSessionToken } from 'data/hooks/dataConnections/useCreateNangoSessionToken'
import {
    invalidateDataConnections,
    useDataConnections,
} from 'data/hooks/dataConnections/useDataConnections'
import { useEditDataConnection } from 'data/hooks/dataConnections/useEditDataConnection'
import { invalidateExtDataIntegrations } from 'data/hooks/dataConnections/useExtDataIntegrations'
import {
    ExternalDatabase,
    invalidateExternalDatabases,
    useExternalDatabases,
} from 'data/hooks/dataConnections/useExternalDatabases'
import { usePopulateUserInfoForExtConnection } from 'data/hooks/dataConnections/usePopulateUserInfoForExtConnection'
import { useReauthenticateConnection } from 'data/hooks/dataConnections/useReauthenticateConnection'
import { useValidateAirtableConnection } from 'data/hooks/dataConnections/useValidateAirtableConnection'
import {
    DATA_CONNECTION_DETAIL_MODAL_KEY,
    INTEGRATION_ID_TO_DC_TYPE,
    INTEGRATION_IDS_REQUIRES_INPUTS,
    TRANSLATIONS,
} from 'features/DataConnections/constants'
import { getIntegrationRequiresDb } from 'features/DataConnections/getIntegrationRequiresDb'
import { getNangoClient } from 'features/DataConnections/getNangoClient'

import useModalToggle from 'v2/ui/utils/useModalToggle'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Modal, ModalContent, ModalFooter, ModalHeader } from 'ui/components/Modal'
import { Body } from 'ui/components/Text'
import { useToast } from 'ui/components/Toast'

import { ConnectionSetupInputs } from './ConnectionSetupInputs/ConnectionSetupInputs'
import { AirtableOauthConfirmation } from './AirtableOauthConfirmation'
import { ExternalAccountSelect } from './ExternalAccountSelect'
import { ExternalDatabaseSelect, SelectedDatabaseType } from './ExternalDatabaseSelect'
import { ExternalObjectSelect } from './ExternalObjectSelect'
import { IntegrationPicker } from './IntegrationPicker'

export const DataConnectionDetailModal: React.FC = () => {
    const { isOpen, data, setIsOpen } = useModalToggle<{
        initialIntegrationId?: ExternalIntegrationId
        initialDataConnection?: DataConnectionDto
        initialSelectedExternalAccountId?: string
    }>(DATA_CONNECTION_DETAIL_MODAL_KEY)

    const { isLoading: isLoadingDataConnections, data: dataConnections } = useDataConnections()
    const [integrationId, setIntegrationId] = useState<ExternalIntegrationId>()

    // the external account to create new data connection for (the value will be the nango connection id)
    const [selectedExternalAccountId, setSelectedExternalAccountId] = useState<string>('')
    const [selectedDatabase, setSelectedDatabase] = useState<SelectedDatabaseType>({
        id: '',
        name: '',
    })
    const [selectedExternalObjectIds, setSelectedExternalObjectIds] = useState<Set<string>>(
        new Set()
    )
    const [alreadySyncedObjectIds, setAlreadySyncedObjectIds] = useState<Set<string>>(new Set())

    const [dataConnection, setDataConnection] = useState<DataConnectionDto | undefined>(
        data?.initialDataConnection
    )
    const [error, setError] = useState<string>('')
    const [showOauthConfirmation, setShowOauthConfirmation] = useState<boolean>(false)
    // certain integrations require additional inputs (e.g. username/pw, OAuth overrides, tokens, subdomain overrides etc)
    // before nango authentication stage, this state determinate if the screen to input any additional info before/during auth is needed
    const [showConnectionSetupInputs, setShowConnectionSetupInputs] = useState<boolean>(false)
    const [isSaving, setIsSaving] = useState(false)
    const oauthConfirmedCallback = useRef<() => void>()

    const { data: externalDatabases, isLoading: isLoadingExternalDatabases } = useExternalDatabases(
        { integrationId, nangoConnectionId: dataConnection?.nango_connection_id ?? '' },
        {
            // if a different account is selected we want to go into a loading state and stop showing stale data immediately
            keepPreviousData: false,
        }
    )
    const { mutateAsync: createSessionToken, isLoading: isCreatingSessionToken } =
        useCreateNangoSessionToken({
            onError: () => {
                toast({
                    title: 'There was a problem setting up a new connection. Please try again or contact support.',
                    type: 'error',
                })
            },
        })

    const { reauthenticateConnection, isCreatingReconnectSessionToken } =
        useReauthenticateConnection()

    useEffect(() => {
        // if modal is opened with an integration, select that integration id
        if (data?.initialIntegrationId) {
            setIntegrationId(data?.initialIntegrationId)
        }
    }, [data?.initialIntegrationId])

    useEffect(() => {
        // if modal is opened with an account to be preselected and nothing is selected, select that account
        if (data?.initialSelectedExternalAccountId && !selectedExternalAccountId) {
            setSelectedExternalAccountId(data?.initialSelectedExternalAccountId)
        }
    }, [data?.initialSelectedExternalAccountId, selectedExternalAccountId])

    useEffect(() => {
        if (data?.initialDataConnection) {
            setSelectedExternalAccountId(data.initialDataConnection.nango_connection_id ?? '')
            setSelectedDatabase({
                id: data.initialDataConnection.external_database_id ?? '',
                name: '',
            })
            setDataConnection(data.initialDataConnection)
        }
    }, [data?.initialDataConnection])

    useEffect(() => {
        const matchingDc = dataConnections?.find(
            (dc) => dc.external_database_id === selectedDatabase?.id
        )
        if (selectedDatabase?.id && matchingDc && matchingDc._sid !== dataConnection?._sid) {
            setDataConnection(matchingDc)
        } else if (selectedDatabase?.id && !matchingDc) {
            // if selected DB changes, and there's no match, we reset the data connection
            setDataConnection(undefined)
        }
    }, [dataConnection, dataConnections, selectedDatabase?.id])

    useEffect(() => {
        if (dataConnection) {
            setSelectedExternalObjectIds(new Set(dataConnection.external_object_ids ?? []))
            setAlreadySyncedObjectIds(new Set(dataConnection.external_object_ids ?? []))
        } else {
            // reset these states if no DC is selected now (but could have been before)
            setSelectedExternalObjectIds(new Set())
            setAlreadySyncedObjectIds(new Set())
        }

        if (dataConnection && dataConnection.nango_connection_id !== selectedExternalAccountId) {
            setError('This data source is already set up under a different account.')
        } else {
            setError('')
        }
    }, [dataConnection, selectedExternalAccountId])

    useEffect(() => {
        if (!isLoadingExternalDatabases && externalDatabases) {
            const db = externalDatabases.find(
                (x: ExternalDatabase) => x.id === dataConnection?.external_database_id
            )
            setSelectedDatabase({
                id: dataConnection?.external_database_id ?? '',
                name: db?.name ?? '',
            })
        }
    }, [dataConnection, externalDatabases, isLoadingExternalDatabases])

    const handleClose = useCallback(() => {
        data.initialSelectedExternalAccountId = ''
        data.initialIntegrationId = undefined
        setIntegrationId(undefined)
        setSelectedExternalAccountId('')
        setSelectedDatabase({ id: '', name: '' })
        setSelectedExternalObjectIds(new Set())
        setAlreadySyncedObjectIds(new Set())
        setDataConnection(undefined)
        setShowOauthConfirmation(false)
        setShowConnectionSetupInputs(false)
        setIsOpen(false)
    }, [data, setIsOpen])

    const handleOnOpenChange = useCallback(
        (open: boolean) => {
            if (open) {
                setIsOpen(true)
            } else {
                handleClose()
            }
        },
        [handleClose, setIsOpen]
    )

    const handleSelectExtAccountId = (newExternalAccountId: string) => {
        setSelectedExternalAccountId(newExternalAccountId)
        setSelectedDatabase({ id: '', name: '' })
        setSelectedExternalObjectIds(new Set())
    }

    const toast = useToast()
    const { mutateAsync: createDataConnection } = useCreateDataConnection()
    const { mutateAsync: editDataConnection } = useEditDataConnection({
        onError: () => {
            toast({
                title: 'There was a problem updating the data connection. Please try again later.',
                type: 'error',
            })
        },
    })

    const { mutateAsync: validateAirtableConnection, isLoading: isValidatingAirtableConnection } =
        useValidateAirtableConnection()

    const { mutateAsync: populateUserInfoInConnection, isLoading: isPopulatingUserInfo } =
        usePopulateUserInfoForExtConnection({
            onError: () => {
                toast({
                    title: 'There was a problem populating user info for the connection created',
                    type: 'error',
                })
            },
        })

    const handleAddNewExternalAccountConfirmed = useCallback(
        // callers can provide params or credentials overrides through nangoAuthOptions - for
        // typical OAuth 2 flows, nothing additional is needed and default value can be used
        async (nangoAuthOptions: AuthOptions = {}) => {
            let authResult: AuthResult
            if (!integrationId) {
                console.warn(
                    'handleAddNewExternalAccountConfirmed called without integrationId being selected'
                )
                return
            }

            try {
                const sessionToken = await createSessionToken()
                const nango = getNangoClient(integrationId, sessionToken.token)

                authResult = await nango.auth(integrationId, nangoAuthOptions)
            } catch (err) {
                console.error('nango auth error:', err)
                Sentry.captureException(err)

                toast({
                    title: 'There was a problem authenticating your connection. Please try again later.',
                    type: 'error',
                })
                return
            }

            await populateUserInfoInConnection({
                nangoConnectionId: authResult.connectionId,
                integrationId: integrationId,
            })

            if (integrationId === 'airtable') {
                // for airtable nango connections, we only allow nango connection per external user, so we need to
                // verify this is not a duplicate and delete it, if it is a duplicate
                await validateAirtableConnection({
                    nangoConnectionId: authResult.connectionId,
                    integrationId: integrationId,
                })
            }

            await invalidateExtDataIntegrations()
            handleSelectExtAccountId(authResult.connectionId)
            toast({ title: 'Connection added successfully.', type: 'success' })
        },
        [
            integrationId,
            createSessionToken,
            populateUserInfoInConnection,
            toast,
            validateAirtableConnection,
        ]
    )

    const handleAddNewExternalAccount = useCallback(async () => {
        // for airtable integration we show a confirmation stage with notes on which scopes to provide before
        // redirecting user to airtable OAuth screen
        if (integrationId === 'airtable') {
            setShowOauthConfirmation(true)
            oauthConfirmedCallback.current = handleAddNewExternalAccountConfirmed
        } else if (integrationId && INTEGRATION_IDS_REQUIRES_INPUTS.includes(integrationId)) {
            setShowConnectionSetupInputs(true)
        } else {
            await handleAddNewExternalAccountConfirmed()
        }
    }, [integrationId, setShowOauthConfirmation, handleAddNewExternalAccountConfirmed])

    const handleAddExternalDatabaseConfirmed = useCallback(async () => {
        if (!integrationId) {
            console.warn(
                'handleAddExternalDatabaseConfirmed called without integrationId being selected'
            )
            return
        }
        // we're re-authorising the existing connection in this flow to give access to different set of external DBs
        await reauthenticateConnection({
            nangoConnectionId: selectedExternalAccountId,
            integrationId,
        })
        await invalidateExternalDatabases()
    }, [integrationId, reauthenticateConnection, selectedExternalAccountId])

    const onSave = useCallback(async () => {
        if (!integrationId) {
            console.warn(
                'Attempting to create data connection without integrationId being selected'
            )
            return
        }

        setIsSaving(true)
        if (!dataConnection) {
            // Creating a new connection
            await createDataConnection({
                // if we selected db name is empty (e.g. for integrations which don't have a database), default to
                // integration id as the label
                label: selectedDatabase.name || integrationId,
                type: INTEGRATION_ID_TO_DC_TYPE[integrationId],
                nango_connection_id: selectedExternalAccountId,
                // if we selected db name is empty (e.g. for integrations which don't have a database), use
                // integration id as the ext db id. (This is important for when grouping records by db id to be processed in BE)
                external_database_id: selectedDatabase.id || integrationId,
                external_object_ids: Array.from(selectedExternalObjectIds),
            }).finally(() => setIsSaving(false))
        } else {
            await editDataConnection({
                _sid: dataConnection?._sid ?? '',
                external_object_ids: Array.from(selectedExternalObjectIds),
            }).finally(() => setIsSaving(false))
            // Updating an existing connection
        }
        invalidateDataConnections()
        handleClose()
    }, [
        createDataConnection,
        dataConnection,
        editDataConnection,
        handleClose,
        integrationId,
        selectedDatabase.id,
        selectedDatabase.name,
        selectedExternalAccountId,
        selectedExternalObjectIds,
    ])

    const handleAddExternalDatabase = useCallback(async () => {
        if (integrationId === 'airtable') {
            setShowOauthConfirmation(true)
            oauthConfirmedCallback.current = handleAddExternalDatabaseConfirmed
        } else {
            await handleAddExternalDatabaseConfirmed()
        }
    }, [integrationId, handleAddExternalDatabaseConfirmed])

    const requiresDatabase = integrationId ? getIntegrationRequiresDb(integrationId) : true

    const DataConnectionDetailModalContent: React.FC = () => {
        if (!integrationId) {
            return (
                <IntegrationPicker
                    onSelect={(integrationId: ExternalIntegrationId) =>
                        setIntegrationId(integrationId)
                    }
                />
            )
        }

        return (
            <>
                <ModalHeader title="Add data source" showCloseButton={true} />
                <Box pb="xl" px="3xl">
                    <Box pb="s">
                        <Body size="m" weight="bold" paddingBottom="m">
                            Account
                        </Body>
                    </Box>
                    <ExternalAccountSelect
                        externalIntegrationId={integrationId}
                        value={selectedExternalAccountId}
                        onChange={handleSelectExtAccountId}
                        onAddExternalAccount={handleAddNewExternalAccount}
                        isLoading={
                            isValidatingAirtableConnection ||
                            isPopulatingUserInfo ||
                            isCreatingSessionToken
                        }
                    />
                </Box>
                {/*
                    Some integrations do not support multiple "databases", so there's no DB selection
                    necessary. In Such cases we don't render ExternalDatabaseSelect component at all
                */}
                {requiresDatabase && (
                    <Box pb="xl" px="3xl">
                        <Box pb="s">
                            <Body size="m" weight="bold" paddingBottom="m">
                                {TRANSLATIONS[integrationId].Database}
                            </Body>
                        </Box>
                        <ExternalDatabaseSelect
                            externalIntegrationId={integrationId}
                            nangoConnectionId={selectedExternalAccountId}
                            isDisabled={!selectedExternalAccountId}
                            isLoading={isCreatingReconnectSessionToken}
                            onAddExternalDatabase={async () => {
                                await handleAddExternalDatabase()
                            }}
                            selectedDatabase={selectedDatabase}
                            onSelectedDatabase={(newDb) => {
                                setSelectedExternalObjectIds(new Set())
                                setSelectedDatabase(newDb)
                            }}
                        />
                    </Box>
                )}

                {!!selectedExternalAccountId && (!requiresDatabase || !!selectedDatabase.id) && (
                    <Box pb="xl" px="3xl">
                        <ExternalObjectSelect
                            externalIntegrationId={integrationId}
                            nangoConnectionId={selectedExternalAccountId}
                            externalDatabaseId={selectedDatabase.id}
                            selectedExternalObjectIds={selectedExternalObjectIds}
                            setSelectedExternalObjectIds={setSelectedExternalObjectIds}
                            forcedObjectIds={alreadySyncedObjectIds}
                        />
                    </Box>
                )}

                {error && (
                    <Box px="3xl">
                        <Body color="textError" size="s" mt="m" weight="medium">
                            {error}
                        </Body>
                    </Box>
                )}

                <ModalFooter flex flexDirection="row" style={{ justifyContent: 'flex-end' }}>
                    <Button size="l" variant="ghost" onClick={() => handleClose()}>
                        Cancel
                    </Button>
                    <Button
                        size="l"
                        variant="primary"
                        onClick={onSave}
                        isLoading={isSaving}
                        disabled={
                            !selectedExternalAccountId ||
                            (requiresDatabase && !selectedDatabase.id) ||
                            !selectedExternalObjectIds.size ||
                            isLoadingDataConnections ||
                            !!error
                        }
                    >
                        Continue
                    </Button>
                </ModalFooter>
            </>
        )
    }

    return (
        <Modal open={isOpen} onOpenChange={handleOnOpenChange}>
            <ModalContent
                style={{
                    // if integrationId is not defined, we'll render the IntegrationPicket, the width
                    // should be set to 800px
                    width: integrationId ? undefined : '800px',
                }}
            >
                {showOauthConfirmation ? (
                    <AirtableOauthConfirmation
                        handleConfirmed={() => {
                            setShowOauthConfirmation(false)
                            oauthConfirmedCallback.current?.()
                        }}
                        handleClose={handleClose}
                    />
                ) : showConnectionSetupInputs && integrationId ? (
                    <ConnectionSetupInputs
                        integrationId={integrationId}
                        handleConfirmed={async (nangoAuthOptions: AuthOptions) => {
                            setShowConnectionSetupInputs(false)
                            await handleAddNewExternalAccountConfirmed(nangoAuthOptions)
                        }}
                        handleClose={handleClose}
                    />
                ) : (
                    <DataConnectionDetailModalContent />
                )}
            </ModalContent>
        </Modal>
    )
}
