import React, { useEffect, useState } from 'react'

import { updateField } from 'data/hooks/fields'
import { useObject } from 'data/hooks/objects'
import { updateObject } from 'data/hooks/objects/objectOperations'
import useLDFlags from 'data/hooks/useLDFlags'
import { RenameNativeTableForm } from 'features/admin/settings/object/RenameNativeTableForm'
import {
    getIsStackerSharedObject,
    getIsStackerSharedObjectCopy,
} from 'features/admin/stackerNativeObjectUtils'
import { Divider, ExpandSection, Section } from 'legacy/v1/ui'
import { canBeExternalIdField, canBePrimaryField } from 'utils/fieldUtils'

import { Banner, Button, Dropdown } from 'v2/ui'
import { useToast } from 'v2/ui/components/useToast'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

import { DeleteTableSetting } from './DeleteTableSetting'
import { DisplaySettings } from './DisplaySettings'
import { IndexTableSettings } from './IndexTableSettings'
import { SharedCopySettings } from './SharedCopySettings'
import { SharedOriginalSettings } from './SharedOriginalSettings'
import { ShareTablesSettings } from './ShareTablesSettings'

export const AppSettingsModalDataSettings = ({
    noPadding,
    objectId,
}: {
    noPadding: boolean
    objectId: string
}): React.ReactElement | null => {
    const { flags } = useLDFlags()
    const { object } = useObject(objectId)
    const [externalIdButtonDisabled, setExternalIdButtonDisabled] = useState(true)
    const [externalIdFieldApiName, setExternalIdFieldApiName] = useState<string | null>(null)
    const [primaryFieldApiName, setPrimaryFieldApiNameApiName] = useState<string | null>(null)
    const toast = useToast()

    useEffect(() => {
        if (object && object.external_id_field_id) {
            const field = object.fields.find((f) => f._sid === object.external_id_field_id)
            if (field) {
                setExternalIdFieldApiName(field.api_name)
            }
        }
    }, [object])

    function getPrimaryFieldApi(object: ObjectDto) {
        if (primaryFieldApiName) {
            return primaryFieldApiName
        }

        if (object && object.fields) {
            const field = object.fields.find((f) => f.is_primary)
            if (field) return field.api_name
        }
    }

    function openErrorToast(operation: 'update' | 'delete' | 'unshare' = 'update') {
        toast({
            status: 'error',
            title: `Failed to ${operation} this object. Please try again or contact Support.`,
        })
    }

    const isSharedTable = getIsStackerSharedObject(object)
    const isSharedTableCopy = getIsStackerSharedObjectCopy(object)
    const isSharedTableOriginal = isSharedTable && !isSharedTableCopy

    if (!object) {
        return null
    }

    return (
        <Section
            style={{
                width: '100%',
                marginBottom: 0,
                padding: noPadding ? 0 : undefined,
            }}
        >
            <ExpandSection
                heading="Display Name"
                text="Configure the primary field for this table."
                classes={{
                    heading:
                        ONBOARDING_CLASSES.APP_SETTINGS_DATA_CONNECTION.SETTINGS_ITEM.DISPLAY_NAME,
                }}
            >
                {/*@ts-expect-error*/}
                <Section noPadding noMargin>
                    <Dropdown
                        value={getPrimaryFieldApi(object)}
                        options={object.fields.filter(canBePrimaryField).map((field: FieldDto) => {
                            return {
                                label: field.label,
                                value: field.api_name,
                            }
                        })}
                        onChange={(fieldApiName: string) => {
                            setPrimaryFieldApiNameApiName(fieldApiName)
                        }}
                        isClearable={false}
                    />

                    <Button
                        variant="Primary"
                        buttonSize="sm"
                        color={primaryFieldApiName ? 'success' : ''}
                        disabled={!primaryFieldApiName}
                        width="70px"
                        mt={1}
                        onClick={async () => {
                            // Set the new primary field
                            // We don't need to manually set the old primary field to False
                            const primaryField = object.fields.find(
                                (f: FieldDto) => f.api_name === primaryFieldApiName && !f.is_primary
                            )
                            if (!primaryField) {
                                return
                            }

                            try {
                                await updateField(primaryField._sid, {
                                    is_primary: true,
                                })
                                setPrimaryFieldApiNameApiName(null)
                            } catch {
                                openErrorToast()
                            }
                        }}
                    >
                        Save
                    </Button>
                </Section>
            </ExpandSection>

            {flags.externalIdField && (
                <>
                    <Divider />
                    <ExpandSection
                        heading="External ID field"
                        text="Set the field to use as the External ID for this table (Short Text fields only)"
                    >
                        {/*@ts-expect-error*/}
                        <Section noPadding noMargin>
                            <Dropdown
                                value={externalIdFieldApiName}
                                options={object.fields.filter(canBeExternalIdField).map((field) => {
                                    return {
                                        label: field.label,
                                        value: field.api_name,
                                    }
                                })}
                                onChange={(fieldApiName: string | null) => {
                                    setExternalIdFieldApiName(fieldApiName)
                                    setExternalIdButtonDisabled(false)
                                }}
                                isClearable={true}
                            />
                            <div>
                                <Button
                                    variant="Primary"
                                    buttonSize="sm"
                                    disabled={externalIdButtonDisabled}
                                    width="70px"
                                    mt={1}
                                    onClick={async () => {
                                        const externalIdField = object.fields.find(
                                            (f) => f.api_name === externalIdFieldApiName
                                        )

                                        try {
                                            await updateObject(object._sid, {
                                                external_id_field_id:
                                                    externalIdField && externalIdField._sid
                                                        ? externalIdField._sid
                                                        : null,
                                            })
                                            setExternalIdButtonDisabled(true)
                                        } catch {
                                            openErrorToast()
                                        }
                                    }}
                                >
                                    Save
                                </Button>
                            </div>
                        </Section>
                    </ExpandSection>
                </>
            )}
            {
                // The remove table button is handle separately in SharedCopySettings for shared copies
                !isSharedTableCopy && (
                    <DeleteTableSetting object={object} openErrorToast={openErrorToast} />
                )
            }
            {!isSharedTable && (
                <>
                    <Divider />
                    <ExpandSection
                        heading="Rename table"
                        text="Rename the table within App Settings. To rename the table in the menus, use the Navigation Settings."
                        helpUrl=""
                        helpText=""
                        classes={{
                            heading:
                                ONBOARDING_CLASSES.APP_SETTINGS_DATA_CONNECTION.SETTINGS_ITEM
                                    .RENAME_OBJECT,
                        }}
                    >
                        {/*@ts-expect-error*/}
                        <RenameNativeTableForm
                            object={object}
                            onChangeObject={async (data: Partial<ObjectDto>) => {
                                try {
                                    await updateObject(object._sid, data)
                                } catch {
                                    openErrorToast()
                                }
                            }}
                        />
                    </ExpandSection>

                    {flags.sharedTables && <ShareTablesSettings object={object} />}
                    {flags.indexTables && <IndexTableSettings object={object} />}
                </>
            )}
            {isSharedTableOriginal && (
                <SharedOriginalSettings object={object} openErrorToast={openErrorToast} />
            )}
            {isSharedTableCopy && (
                <SharedCopySettings object={object} openErrorToast={openErrorToast} />
            )}
            {isSharedTable && (
                <Banner icon="info" variant="Information" title="Shared table">
                    {object.is_shared_original
                        ? 'This table is shared and available to be added to other apps.'
                        : 'This is a shared table that is owned by another app.'}
                </Banner>
            )}
            {!noPadding && <Divider />}

            {localStorage.getItem('support_login') && (
                <>
                    <Divider />
                    <ExpandSection
                        heading="Display settings"
                        text="Change how this table is displayed in the Manage Data pane"
                        helpUrl=""
                        helpText=""
                    >
                        {/*@ts-expect-error*/}
                        <DisplaySettings object={object} />
                    </ExpandSection>
                </>
            )}
        </Section>
    )
}
