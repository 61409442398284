import React from 'react'

import {
    DetailViewHeaderActionButton,
    DetailViewHeaderActionDropdownItem,
} from 'features/views/DetailView/headers/DetailViewHeaderActionButton'

import { Box } from 'ui/components/Box'
import { Dropdown, DropdownButton, DropdownContent } from 'ui/components/Dropdown'
import { Skeleton } from 'ui/components/Skeleton'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useDetailViewSpaciousHeaderActionsState } from './hooks/useDetailViewSpaciousHeaderActionsState'

type DetailViewSpaciousHeaderActionsProps = React.ComponentPropsWithoutRef<typeof Box> & {
    isLoading?: boolean
}

export const DetailViewSpaciousHeaderActions: React.FC<DetailViewSpaciousHeaderActionsProps> = ({
    isLoading,
    ...props
}) => {
    const { visibleActions, menuActions } = useDetailViewSpaciousHeaderActionsState()

    const buttonSize = useResponsiveValue({
        mobile: 'm' as const,
        tablet: 'l' as const,
    })

    return (
        <Box
            display="grid"
            gap="m"
            style={{
                gridTemplateColumns: `repeat(${visibleActions.length + (!!menuActions.length ? 1 : 0)}, minmax(10px, max-content))`,
                minWidth: `${(visibleActions.length + (!!menuActions.length ? 1 : 0)) * 32}px`,
            }}
            {...props}
        >
            {visibleActions.map((action, idx) => (
                <Skeleton key={action._sid} isLoading={isLoading}>
                    <DetailViewHeaderActionButton
                        key={action._sid}
                        action={action}
                        size={buttonSize}
                        variant={idx === 0 ? 'primary' : 'secondary'}
                    />
                </Skeleton>
            ))}
            {menuActions.length > 0 && (
                <Skeleton isLoading={isLoading}>
                    <Dropdown>
                        <DropdownButton
                            size={buttonSize}
                            variant="secondary"
                            startIcon={{ name: 'MoreHorizontal' }}
                            noShrink
                        />
                        <DropdownContent align="end" side="bottom" style={{ minWidth: '286px' }}>
                            {menuActions.map((action) => (
                                <DetailViewHeaderActionDropdownItem
                                    key={action._sid}
                                    action={action}
                                    closeOnSelect
                                />
                            ))}
                        </DropdownContent>
                    </Dropdown>
                </Skeleton>
            )}
        </Box>
    )
}
