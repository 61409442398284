import React, { useCallback, useEffect, useRef, useState } from 'react'

import { v4 as uuid } from 'uuid'
import * as Y from 'yjs'

import { ObjectFieldsFilter } from 'features/records/components/RecordFilters'
import { toYType } from 'features/utils/useYjsState'
import * as yUtils from 'features/utils/yUtils'
import { LayoutEditorControlSeparator } from 'features/views/LayoutEditor/controls/LayoutEditorControlSeparator'
import { LayoutEditorImageControl } from 'features/views/LayoutEditor/controls/LayoutEditorImageControl'
import {
    WidgetAdminControlsComponent,
    WidgetAdminControlsProps,
} from 'features/views/LayoutEditor/types'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'

import { OrderableListSelector } from 'v2/ui'
import { Item } from 'v2/ui/components/OrderableListSelector/types'
import useDebounce from 'v2/ui/utils/useDebounce'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Field } from 'ui/components/Field'
import { IconPickerDropdown } from 'ui/components/IconPicker'
import { IconValue } from 'ui/components/IconPicker/IconPickerDropdown'
import { Input } from 'ui/components/Input'
import {
    Modal,
    ModalCloseTrigger,
    ModalContent,
    ModalContentInner,
    ModalFooterButtonGroup,
    ModalHeader,
} from 'ui/components/Modal'
import { ModalFooter } from 'ui/components/Modal/ModalFooter'
import { RadioCard } from 'ui/components/Radio'
import { RadioCardGroup } from 'ui/components/Radio/RadioCardGroup'
import { Select, SelectOption } from 'ui/components/Select'
import { Textarea } from 'ui/components/Textarea'
import { Toggle } from 'ui/components/Toggle'
import { theme } from 'ui/styling/Theme.css'

import { useLinkBlocksWidgetLinks } from './hooks/useLinkBlocksLinks'
import {
    LinkBlocksWidgetLink,
    LinkBlocksWidgetLinkHeaderIcon,
    LinkBlocksWidgetLinkHeaderImage,
    LinkBlocksWidgetType,
} from './types'
import { getLinkBlockLabel } from './utils'

type LinkBlocksWidgetAdminControlsProps = {}

export const LinkBlocksWidgetAdminControls: WidgetAdminControlsComponent<
    LinkBlocksWidgetType,
    LinkBlocksWidgetAdminControlsProps
> = ({ widget, onChange, openDetailPane }) => {
    const { style, size } = widget.attrs

    const { allLinks, activeLinks } = useLinkBlocksWidgetLinks(widget)

    const { view } = useLayoutEditorContext()
    const viewRef = useRef(view)
    viewRef.current = view

    const onEditDetails = useCallback(
        (linkId: string) => {
            openDetailPane({
                component: (props) => <LinkBlockDetailsControl {...props} linkId={linkId} />,
                label: 'Link blocks: Details',
            })
        },
        [openDetailPane]
    )

    const onAddNew = useCallback(() => {
        onChange((attrs) => {
            const newId = `link_${uuid()}`

            const addedYItem = toYType({
                id: newId,
                conditions: [],
                isActive: true,
            } as LinkBlocksWidgetLink)

            const links = attrs.get('links')
            if (links) {
                links.push([addedYItem])
            } else {
                attrs.set('links', Y.Array.from([addedYItem]))
            }

            queueMicrotask(() => onEditDetails(newId))
        })
    }, [onChange, onEditDetails])

    const onInsert = (item: Item) => {
        onChange((attrs) => {
            const addedItem = allLinks.find((link) => link.id === item.id)
            if (!addedItem) return

            const links = attrs.get('links')
            if (links) {
                const link = yUtils.arrayFind<Y.Map<any>>(links, (t) => t.get('id') === item.id)
                if (!!link) {
                    link.set('isActive', true)
                }
            } else {
                const addedYItem = toYType({ ...addedItem, isActive: true })
                attrs.set('links', Y.Array.from([addedYItem]))
            }
        })
    }

    const onUpdate = (items: Item[]) => {
        const activeItems = items.reduce((acc, item, idx) => {
            return acc.set(item.id, { ...item, idx })
        }, new Map<string, Item & { idx: number }>())

        onChange((attrs) => {
            const newLinks = allLinks.map((link) => {
                const activeItem = activeItems.get(link.id)

                return {
                    ...link,
                    isActive: !!activeItem,
                    idx: activeItem?.idx ?? -1,
                }
            })
            newLinks.sort((a, b) => a.idx - b.idx)

            attrs.set('links', toYType(newLinks))
        })
    }

    const [showConditionsModalLinkBlockId, setShowConditionsModalLinkBlockId] = useState('')

    const onEditConditions = useCallback((linkId: string) => {
        setShowConditionsModalLinkBlockId(linkId)
    }, [])

    const onOpenChangeConditionsModal = useCallback((open: boolean) => {
        if (!open) {
            setShowConditionsModalLinkBlockId('')
        }
    }, [])

    return (
        <Box flex column px="l" gap="l" height="full" overflowY="auto">
            <Field label="Size">
                <RadioCardGroup
                    value={size}
                    onValueChange={(value: string) => {
                        const newValue = value || undefined

                        onChange((attrs) => {
                            attrs.set('size', newValue)
                        })
                    }}
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fill, 1fr)',
                    }}
                >
                    <RadioCard
                        value="regular"
                        icon={() => <BlockSizeIcon size="regular" isActive={size === 'regular'} />}
                    >
                        Regular
                    </RadioCard>
                    <RadioCard
                        value="fullWidth"
                        icon={() => (
                            <BlockSizeIcon size="fullWidth" isActive={size === 'fullWidth'} />
                        )}
                    >
                        Full-width
                    </RadioCard>
                </RadioCardGroup>
            </Field>
            <Field label="Style">
                <RadioCardGroup
                    value={style}
                    onValueChange={(value: string) => {
                        const newValue = value || undefined

                        onChange((attrs) => {
                            attrs.set('style', newValue)
                        })
                    }}
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fill, 1fr)',
                    }}
                >
                    <RadioCard
                        value="regular"
                        icon={() => (
                            <BlockStyleIcon style="regular" isActive={style === 'regular'} />
                        )}
                    >
                        Regular
                    </RadioCard>
                    <RadioCard
                        value="boxed"
                        icon={() => <BlockStyleIcon style="boxed" isActive={style === 'boxed'} />}
                    >
                        Boxed
                    </RadioCard>
                </RadioCardGroup>
            </Field>
            <Field label="Links">
                <OrderableListSelector
                    onAdd={onInsert}
                    items={allLinks.map((link) => ({
                        name: getLinkBlockLabel(link),
                        label: getLinkBlockLabel(link),
                        id: link.id,
                    }))}
                    selectedItems={activeLinks.map((link) => ({
                        name: getLinkBlockLabel(link),
                        label: getLinkBlockLabel(link),
                        id: link.id,
                        conditions: link.conditions,
                        actions: [
                            {
                                icon: 'edit',
                                onClick: () => onEditDetails(link.id),
                                color: 'gray.300',
                                title: 'Edit link details',
                            },
                            {
                                icon: 'eye',
                                onClick: () => onEditConditions(link.id),
                                color: !!link.conditions?.length ? 'adminBrand' : 'gray.300',
                                title: 'Edit conditional visibility',
                            },
                        ],
                    }))}
                    onUpdate={onUpdate}
                    mainActionButton={{
                        children: 'Add',
                        startIcon: {
                            name: 'Plus',
                        },
                        onClick: onAddNew,
                    }}
                />
            </Field>
            <LinkBlockConditionsModal
                widget={widget}
                linkId={showConditionsModalLinkBlockId}
                onOpenChange={onOpenChangeConditionsModal}
                onChange={onChange}
            />
        </Box>
    )
}

type BlockStyleIconProps = {
    style: LinkBlocksWidgetType['attrs']['style']
    isActive: boolean
}

const BlockStyleIcon: React.FC<BlockStyleIconProps> = ({ style, isActive }) => {
    let background: React.ComponentProps<typeof Box>['background'] = isActive
        ? 'theme100'
        : 'gray200'

    let borderColor: React.ComponentProps<typeof Box>['borderColor'] = background
    if (style !== 'regular') {
        borderColor = isActive ? 'theme300' : 'gray300'
    }

    let itemHeight = '14px'
    if (style === 'regular') {
        itemHeight = '8px'
    }

    return (
        <Box
            flex
            center
            style={{
                width: '50%',
                height: '14px',
            }}
        >
            <Box
                display="grid"
                width="full"
                gap="xs"
                style={{ gridTemplateColumns: 'repeat(3, 1fr)' }}
            >
                {Array.from({ length: 3 }).map((_, index) => (
                    <Box
                        key={index}
                        width="full"
                        height="full"
                        background={background}
                        borderWidth={1}
                        borderColor={borderColor}
                        style={{ height: itemHeight, borderRadius: '2px' }}
                    />
                ))}
            </Box>
        </Box>
    )
}

type BlockSizeIconProps = {
    size: LinkBlocksWidgetType['attrs']['size']
    isActive: boolean
}

const BlockSizeIcon: React.FC<BlockSizeIconProps> = ({ size, isActive }) => {
    let gridTemplateColumns = 'repeat(3, 1fr)'
    if (size === 'fullWidth') {
        gridTemplateColumns = '1fr'
    }

    let background: React.ComponentProps<typeof Box>['background'] = isActive
        ? 'theme100'
        : 'gray200'

    const borderColor: React.ComponentProps<typeof Box>['borderColor'] = isActive
        ? 'theme300'
        : 'gray300'

    let boxLen = 3
    if (size === 'fullWidth') {
        boxLen = 1
    }

    return (
        <Box
            flex
            center
            style={{
                width: '50%',
                height: '14px',
            }}
        >
            <Box display="grid" width="full" gap="xs" style={{ gridTemplateColumns }}>
                {Array.from({ length: boxLen }).map((_, index) => (
                    <Box
                        key={index}
                        width="full"
                        height="full"
                        background={background}
                        borderWidth={1}
                        borderColor={borderColor}
                        style={{ height: '14px', borderRadius: '2px' }}
                    />
                ))}
            </Box>
        </Box>
    )
}

const DEBOUNCE_RATE = 300 // ms

type LinkBlockDetailsControlProps = {
    linkId: string
}

const LinkBlockDetailsControl: WidgetAdminControlsComponent<
    LinkBlocksWidgetType,
    LinkBlockDetailsControlProps
> = ({ widget, onChange, linkId }) => {
    const { allLinks } = useLinkBlocksWidgetLinks(widget)
    const link = allLinks.find((link) => link.id === linkId)

    const debouncedOnChange = useDebounce(onChange, DEBOUNCE_RATE) as typeof onChange

    const [localTitle, setLocalTitle] = useState(link?.title ?? '')
    const onChangeTitle = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value || ''

        setLocalTitle(value)
        debouncedOnChange((attrs) => {
            const links = attrs.get('links')
            if (!links) return

            const link = yUtils.arrayFind<Y.Map<any>>(links, (t) => t.get('id') === linkId)
            if (!!link) {
                link.set('title', value)
            }
        })
    }

    const [localSubtitle, setLocalSubtitle] = useState(link?.subtitle ?? '')
    const onChangeSubtitle = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.target.value || ''

        setLocalSubtitle(value)
        debouncedOnChange((attrs) => {
            const links = attrs.get('links')
            if (!links) return

            const link = yUtils.arrayFind<Y.Map<any>>(links, (t) => t.get('id') === linkId)
            if (!!link) {
                link.set('subtitle', value)
            }
        })
    }

    const [localLink, setLocalLink] = useState<LinkBlocksWidgetLink['link']>(link?.link)
    const onChangeLinkParams = useCallback(
        (partial: Partial<LinkBlocksWidgetLink['link']>) => {
            setLocalLink((prev) => {
                if (!partial || partial.href === '') {
                    return undefined
                }

                return { type: 'url', ...prev, ...partial } as LinkBlocksWidgetLink['link']
            })
            debouncedOnChange((attrs) => {
                const links = attrs.get('links')
                if (!links) return

                const link = yUtils.arrayFind<Y.Map<any>>(links, (t) => t.get('id') === linkId)
                if (!!link) {
                    if (!partial || partial.href === '') {
                        link.delete('link')
                    } else {
                        let linkTarget = link.get('link')
                        if (!linkTarget) {
                            linkTarget = link.set('link', new Y.Map())
                        }

                        for (const [key, value] of Object.entries(partial)) {
                            linkTarget.set(key, value)
                        }
                    }
                }
            })
        },
        [debouncedOnChange, linkId]
    )

    const linkHref = localLink?.href ?? ''
    const onChangeLinkHref = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value

            onChangeLinkParams({ href: value })
        },
        [onChangeLinkParams]
    )

    const linkLabel = localLink?.label ?? ''
    const onChangeLinkLabel = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value

            onChangeLinkParams({ label: value })
        },
        [onChangeLinkParams]
    )

    const linkOpenAs = (localLink?.openAs ?? 'full_page') === 'new_tab'
    const onChangeLinkOpenAs = useCallback(
        (value: boolean) => {
            const newValue = value ? 'new_tab' : 'full_page'
            onChangeLinkParams({ openAs: newValue })
        },
        [onChangeLinkParams]
    )

    const headerType = link?.header?.type ?? 'none'
    const onChangeHeaderType = useCallback(
        (value: string) => {
            onChange((attrs) => {
                const links = attrs.get('links')
                if (!links) return

                const link = yUtils.arrayFind<Y.Map<any>>(links, (t) => t.get('id') === linkId)
                if (!!link) {
                    let newValue: LinkBlocksWidgetLink['header'] = undefined
                    if (value === 'icon') {
                        newValue = {
                            type: 'icon',
                            icon: {
                                type: 'hugeicons',
                                name: 'smile',
                            },
                        }
                    } else if (value === 'image') {
                        newValue = {
                            type: 'image',
                        }
                    }

                    link.set('header', newValue)
                }
            })
        },
        [linkId, onChange]
    )

    const icon = (link?.header as LinkBlocksWidgetLinkHeaderIcon)?.icon
    const onChangeIcon = useCallback(
        (value?: IconValue<'hugeicons'>) => {
            onChange((attrs) => {
                const links = attrs.get('links')
                if (!links) return

                const link = yUtils.arrayFind<Y.Map<any>>(links, (t) => t.get('id') === linkId)
                if (!!link) {
                    link.set('header', {
                        type: 'icon',
                        icon: value,
                    })
                }
            })
        },
        [linkId, onChange]
    )

    const imageUrl = (link?.header as LinkBlocksWidgetLinkHeaderImage)?.image?.url ?? ''
    const onChangeImage = useCallback(
        (url: string, filename: string) => {
            onChange((attrs) => {
                const links = attrs.get('links')
                if (!links) return

                const link = yUtils.arrayFind<Y.Map<any>>(links, (t) => t.get('id') === linkId)
                if (!!link) {
                    let newValue: LinkBlocksWidgetLinkHeaderImage = {
                        type: 'image',
                    }
                    if (!!url) {
                        newValue.image = {
                            type: 'file',
                            url,
                            filename,
                        }
                    }
                    link.set('header', newValue)
                }
            })
        },
        [linkId, onChange]
    )

    return (
        <Box flex column px="l" gap="m" height="full" overflowY="auto">
            <Select label="Header" value={headerType} onChange={onChangeHeaderType}>
                <SelectOption label="None" value="none" />
                <SelectOption label="Icon" value="icon" />
                <SelectOption label="Image" value="image" />
            </Select>
            {headerType === 'icon' && (
                <Field
                    label="Icon"
                    rightSlotContent={
                        <IconPickerDropdown
                            variant="hugeicons"
                            value={icon}
                            onChange={onChangeIcon}
                            isClearable={false}
                        />
                    }
                />
            )}
            {headerType === 'image' && (
                <Field label="Image">
                    <LayoutEditorImageControl value={imageUrl} onChange={onChangeImage} />
                </Field>
            )}
            <LayoutEditorControlSeparator px={0} mt="l" mb="s" />
            <Box flex column gap="l">
                <Input
                    label="Title"
                    value={localTitle}
                    onChange={onChangeTitle}
                    autoFocus={!localTitle}
                />
                <Textarea
                    label="Subtitle"
                    value={localSubtitle}
                    onChange={onChangeSubtitle}
                    style={{
                        paddingTop: theme.input.padding.verticalM,
                        paddingBottom: theme.input.padding.verticalM,
                        width: '100%',
                        minHeight: theme.input.size.m,
                        alignItems: 'center',
                    }}
                    autoSize
                    textareaProps={{
                        style: {
                            maxHeight: theme.textarea.size.m,
                        },
                    }}
                />
            </Box>
            <LayoutEditorControlSeparator px={0} mt="l" mb="s" />
            <Box flex column gap="l">
                <Input
                    label="Link URL"
                    value={linkHref}
                    onChange={onChangeLinkHref}
                    placeholder="https://..."
                />
                {!!linkHref && (
                    <>
                        <Input label="Link label" value={linkLabel} onChange={onChangeLinkLabel} />
                        <Field
                            htmlFor="linkOpenAs"
                            label="Open link in new tab"
                            rightSlotContent={
                                <Toggle
                                    id="linkOpenAs"
                                    checked={linkOpenAs}
                                    onCheckedChange={onChangeLinkOpenAs}
                                />
                            }
                        />
                    </>
                )}
            </Box>
        </Box>
    )
}

type LinkBlockConditionsModalProps = {
    linkId: string
    widget: WidgetAdminControlsProps<LinkBlocksWidgetType>['widget']
    onChange: WidgetAdminControlsProps<LinkBlocksWidgetType>['onChange']
    onOpenChange: (open: boolean) => void
}

const LinkBlockConditionsModal: React.FC<LinkBlockConditionsModalProps> = ({
    widget,
    onChange,
    linkId,
    onOpenChange,
}) => {
    const { object, fields } = useLayoutEditorContext()

    const { allLinks } = useLinkBlocksWidgetLinks(widget)
    const link = allLinks.find((link) => link.id === linkId)

    const linkConditions = link?.conditions ?? []
    const [conditions, setConditions] = useState(linkConditions)
    const conditionsRef = useRef(conditions)
    conditionsRef.current = conditions

    const isOpen = !!link && !!object

    const onSave = useCallback(() => {
        const conditions = conditionsRef.current

        onChange((attrs) => {
            onOpenChange(false)

            const links = attrs.get('links')
            if (!links) return

            const link = yUtils.arrayFind<Y.Map<any>>(links, (t) => t.get('id') === linkId)
            if (!!link) {
                link.set('conditions', toYType(conditions as LinkBlocksWidgetLink['conditions']))
            }
        })
    }, [onChange, linkId, onOpenChange])

    useEffect(() => {
        if (isOpen) {
            // Prevent the modal from blocking pointer events on the body, so our nested dropdowns and modals still work.
            const timer = setTimeout(() => {
                document.body.style.pointerEvents = ''
            }, 0)

            return () => clearTimeout(timer)
        } else {
            document.body.style.pointerEvents = 'auto'
        }
    }, [isOpen])

    const onInteractOutside = useCallback((e: Event) => {
        const el = e.target as HTMLElement | null
        if (!el) return

        if (el.closest('.ag-custom-component-popup')) {
            e.preventDefault()
        }
    }, [])

    if (!isOpen) return null

    return (
        <Modal open={isOpen} onOpenChange={onOpenChange}>
            <ModalContent
                style={{ width: '700px', maxWidth: '100%' }}
                onInteractOutside={onInteractOutside}
                zIndex={1500}
            >
                <ModalHeader
                    title="Conditions"
                    subtitle="Select the conditions under which this link should be visible"
                />
                <ModalContentInner>
                    <ObjectFieldsFilter
                        object={object}
                        value={link.conditions}
                        showRoleFilter
                        fields={fields}
                        onChange={setConditions}
                        getShouldShowField={undefined}
                        hideCurrentUserOption={undefined}
                        hideTheRecordFilter={undefined}
                        showRelativeDateFilters={undefined}
                    />
                </ModalContentInner>
                <ModalFooter>
                    <ModalFooterButtonGroup layout="inline">
                        <ModalCloseTrigger asChild>
                            <Button variant="ghost" size="l">
                                Cancel
                            </Button>
                        </ModalCloseTrigger>
                        <Button size="l" onClick={onSave}>
                            Save
                        </Button>
                    </ModalFooterButtonGroup>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
