import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import { getUrl, Urls } from 'app/UrlService'
import { useConfirmModal } from 'app/useConfirmModal'
import { useDeleteAgent } from 'data/hooks/agents/agents'
import { Agent } from 'data/hooks/agents/types'
import { SaveAndDiscardFormButtons } from 'features/Agents/configuration/common/SaveAndDiscardFormButtons'

import { Box } from 'ui/components/Box'

import { EditAgentForm } from './EditAgentForm'
import { EditAgentFormWrapper } from './EditAgentFormWrapper'

type EditAgentGeneralProps = {
    agent: Agent
}

export const EditAgentGeneral: React.FC<EditAgentGeneralProps> = ({ agent }) => {
    const [error, setError] = useState<string>('')
    const { mutateAsync: deleteAgent } = useDeleteAgent()
    const history = useHistory()
    const { show: showConfirm } = useConfirmModal({
        message: 'Are you sure you want to delete this agent?',
        onConfirm: async (modal: any) => {
            await deleteAgent(agent._sid)
            history.push(getUrl(Urls.Root))
            modal.toggle()
        },
    })
    return (
        <Box flex column width="full">
            <EditAgentFormWrapper
                agent={agent}
                onSuccess={() => {
                    // Handle success if needed
                }}
                setError={setError}
            >
                <EditAgentForm showAllOptions />
                <SaveAndDiscardFormButtons error={error} onDelete={() => showConfirm()} />
            </EditAgentFormWrapper>
        </Box>
    )
}
