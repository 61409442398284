import { httpsPrefixRegExp } from './utils'

export function getEmbeddableFigmaUrl(url: string): string {
    if (!url) return ''

    let finalUrl = url.trim()

    if (!httpsPrefixRegExp.test(finalUrl)) {
        finalUrl = `https://${finalUrl}`
    }

    try {
        const finalUrlAsObj = new URL(finalUrl)
        const { pathname } = finalUrlAsObj

        if (pathname.startsWith('/design/')) {
            const newUrl = new URL(finalUrlAsObj)
            newUrl.hostname = 'embed.figma.com'
            newUrl.searchParams.set('embed-host', 'share')

            finalUrl = newUrl.toString()
        }
    } catch {}

    return finalUrl
}

export function isValidFigmaUrl(url: string): boolean {
    return url.includes('figma.com')
}
