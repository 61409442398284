import React from 'react'

import { useAppUsersForAdmin } from 'data/hooks/users/useAppUsersForAdmin'

import { Box } from 'ui/components/Box'
import { Field } from 'ui/components/Field'
import { Select, SelectOption } from 'ui/components/Select'
import { Body } from 'ui/components/Text/Body'

import { CronEditor } from './CronEditor'
import { TriggerConfigProps } from './types'

export type CronJobTriggerConfigType = {
    cron_expression: string
    user_sid: string
}

export function CronJobTriggerConfig({
    config,
    onChange,
}: TriggerConfigProps<CronJobTriggerConfigType>) {
    const { data: users } = useAppUsersForAdmin(true)

    return (
        <Box flex column gap="m">
            <Field label="Schedule" width="5xl" shrink>
                <Box marginBottom="s">
                    <Body color="textWeak">Set when this agent should run automatically</Body>
                </Box>
                <CronEditor
                    value={config.cron_expression || '0 * * * *'} // Default to hourly
                    onChange={(cronExpression) =>
                        onChange({
                            ...config,
                            cron_expression: cronExpression,
                        })
                    }
                />
            </Field>
            <Field label="Run as User" width="5xl" shrink>
                <Select
                    value={config.user_sid}
                    onChange={(value) =>
                        onChange({
                            ...config,
                            user_sid: value,
                        })
                    }
                >
                    {users?.map((user: any) => (
                        <SelectOption key={user._sid} value={user._sid} label={user.name} />
                    ))}
                </Select>
            </Field>
        </Box>
    )
}
