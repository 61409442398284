import React, { forwardRef, useCallback, useEffect, useRef, useState } from 'react'
import RDatePicker, {
    ReactDatePickerProps,
    registerLocale,
    setDefaultLocale,
} from 'react-datepicker'

import { composeRefs } from '@radix-ui/react-compose-refs'
import classNames from 'classnames'
import { enGB, enUS } from 'date-fns/locale'

import { ensureArray } from 'utils/utils'

import { CalendarHeader } from './CalendarHeader'
import { DatePickerDay } from './DatePickerDay'
import { filterTime } from './utils'

import 'react-datepicker/dist/react-datepicker.css'
import {
    DatePickerPopoverStyle,
    DatePickerTheme,
    DatePickerVariants,
    DatePickerWrapperStyle,
    DayOfWeekStyle,
    DayStyle,
    RootStyles,
    TimeSelectorItemStyle,
} from './DatePicker.css'

registerLocale('en-GB', enGB)
registerLocale('en-US', enUS)
setDefaultLocale('en-GB')

export type DatePickerRef = RDatePicker<never, boolean>

type DatePickerProps = Omit<
    ReactDatePickerProps<never, boolean>,
    'selectsRange' | 'placeholderText' | 'selected' | 'value'
> &
    DatePickerVariants & {
        value?: Date | [Date, Date]
        placeholder?: string
    }

export const DatePicker = forwardRef<DatePickerRef, DatePickerProps>(
    (
        {
            range = false,
            showTime,
            inline = true,
            minDate,
            maxDate,
            value,
            onChange: providedOnChange,
            placeholder,
            dateFormat: providedDateFormat,
            calendarClassName,
            wrapperClassName,
            ...props
        },
        ref
    ) => {
        const pickerRef = useRef<RDatePicker<never, boolean>>(null)

        const onKeyDown = useCallback((e: React.KeyboardEvent) => {
            if (e.key === 'Tab') {
                pickerRef.current?.setOpen(false)
            }
        }, [])

        const isControlled = typeof value !== 'undefined'
        const providedValue = value ? ensureArray(value) : [null, null]
        const [startDate, setStartDate] = useState<Date | null>(providedValue[0] ?? null)
        const [endDate, setEndDate] = useState<Date | null>(providedValue[1] ?? null)

        useEffect(() => {
            const newValue = value ? ensureArray(value) : [null, null]
            setStartDate(newValue[0] ?? null)
            setEndDate(newValue[1] ?? null)
        }, [value])

        const onChange = useCallback(
            (dates: Date | [Date, Date], event: React.SyntheticEvent) => {
                if (!isControlled) {
                    if (Array.isArray(dates)) {
                        setStartDate(dates[0])
                        setEndDate(dates[1])
                    } else {
                        setStartDate(dates)
                    }
                }

                providedOnChange?.(dates, event)
            },
            [isControlled, providedOnChange]
        )

        useEffect(() => {
            // Clear the end date if range is disabled.
            if (!range) {
                setEndDate(null)
            }
        }, [range])

        const filterVisibleTime = useCallback(
            (date: Date) => filterTime(date, minDate ?? undefined, maxDate ?? undefined),
            [minDate, maxDate]
        )

        const dateFormat = providedDateFormat ?? (showTime ? 'dd/MM/yyyy HH:mm' : 'dd/MM/yyyy')

        return (
            <RDatePicker<never, boolean>
                ref={composeRefs(ref, pickerRef)}
                onClickOutside={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                }}
                popperPlacement="bottom-end"
                preventOpenOnFocus={true}
                {...props}
                inline={inline}
                selected={startDate}
                startDate={startDate}
                endDate={endDate}
                onChange={onChange}
                showTimeSelect={showTime && !range}
                portalId="react-datepicker"
                popperClassName={`${DatePickerPopoverStyle} click-outside-ignore ag-custom-component-popup`}
                showPopperArrow={false}
                placeholderText={placeholder}
                popperModifiers={[
                    {
                        name: 'flip',
                        options: {
                            padding: { top: 50 },
                        },
                    },
                ]}
                fixedHeight={true}
                onKeyDown={onKeyDown}
                selectsRange={range}
                minDate={minDate}
                maxDate={maxDate}
                renderCustomHeader={(customHeaderProps) => (
                    <CalendarHeader
                        minDate={minDate ?? undefined}
                        maxDate={maxDate ?? undefined}
                        {...customHeaderProps}
                    />
                )}
                renderDayContents={(dayOfMonth, date) => (
                    <DatePickerDay dayOfMonth={dayOfMonth} date={date} />
                )}
                dayClassName={() => DayStyle}
                calendarClassName={classNames(
                    calendarClassName,
                    RootStyles.styleFunction({
                        inline,
                        range,
                        showTime,
                    }),
                    DatePickerTheme
                )}
                weekDayClassName={() => DayOfWeekStyle}
                timeClassName={() => TimeSelectorItemStyle}
                filterTime={filterVisibleTime}
                wrapperClassName={classNames(wrapperClassName, DatePickerWrapperStyle)}
                dateFormat={dateFormat}
                disabledKeyboardNavigation
            />
        )
    }
)
