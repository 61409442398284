import { useEffect, useState } from 'react'

import { useLDFlags } from 'data/hooks/useLDFlags'
import { computeDetailViewSchema } from 'features/views/DetailView/schema'
import { LayoutEditorSchema } from 'features/views/LayoutEditor/types'

const EMPTY_SCHEMA: LayoutEditorSchema = {
    version: 1,
    widgets: {},
}

type UseLayoutEditorSchemaOptions = {
    view?: ViewDto
}

export function useLayoutEditorSchema(options: UseLayoutEditorSchemaOptions) {
    const { view } = options

    const [schema, setSchema] = useState<LayoutEditorSchema | undefined>()
    const { flags } = useLDFlags()

    useEffect(() => {
        ;(async () => {
            let schema: LayoutEditorSchema | undefined
            switch (view?.type) {
                case 'detail':
                    schema = await computeDetailViewSchema(flags)
                    break
                default:
                    schema = EMPTY_SCHEMA
            }

            setSchema(schema)
        })()
    }, [view?.type, flags])

    return {
        schema: schema ?? EMPTY_SCHEMA,
        isLoaded: !!schema,
    }
}
