import { useCallback, useMemo } from 'react'

import { getAbsoluteUrl } from 'app/UrlService'
import { canDeleteRecords } from 'data/utils/getObjectRecordRestrictions'
import { useCopyLink } from 'features/utils/useCopyLink'
import { useDetailViewContext } from 'features/views/DetailView/hooks/useDetailViewContext'

import { useDetailViewHeaderActions } from './useDetailViewHeaderActions'

export function useDetailViewTopBarQuickActionsState() {
    const {
        object,
        viewPath,
        deleteRecord: executeDeleteRecord,
        recordManager,
    } = useDetailViewContext()!

    const absoluteUrl = viewPath ? getAbsoluteUrl(viewPath) : window.location.href
    const executeCopy = useCopyLink()
    const copyLink = useCallback(async () => {
        return executeCopy(absoluteUrl)
    }, [absoluteUrl, executeCopy])
    const canCopyLink = true

    const deleteRecord = useCallback(() => {
        // Using queueMicrotask to ensure that the modal is opened
        // after the dropdown is closed, otherwise the dropdown
        // focus lock will override the modal focus lock.
        queueMicrotask(() => {
            executeDeleteRecord()
        })
    }, [executeDeleteRecord])
    const canDeleteRecord = !!object
        ? canDeleteRecords(object) && !!recordManager.permissions.canDelete
        : false

    const actions = useDetailViewHeaderActions()

    const isLoading = recordManager.isFetchingSlow

    return useMemo(
        () => ({
            copyLink: canCopyLink ? copyLink : undefined,
            deleteRecord: canDeleteRecord ? deleteRecord : undefined,
            actions,
            isLoading,
        }),
        [canCopyLink, copyLink, canDeleteRecord, deleteRecord, actions, isLoading]
    )
}
