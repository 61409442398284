import React, { useState } from 'react'

import EmbeddableContent from 'features/embeddable-content/EmbeddableContent'
import { LayoutEditorControlSeparator } from 'features/views/LayoutEditor/controls/LayoutEditorControlSeparator'
import { WidgetAdminControlsComponent, WidgetComponent } from 'features/views/LayoutEditor/types'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'

import useDebounce from 'v2/ui/utils/useDebounce'

import { Box } from 'ui/components/Box'
import { Field, InfoMark } from 'ui/components/Field'
import { Input } from 'ui/components/Input'
import { RadioCard, RadioCardGroup } from 'ui/components/Radio'
import { Select, SelectOption } from 'ui/components/Select'
import { Skeleton } from 'ui/components/Skeleton'
import { Body } from 'ui/components/Text'
import { theme } from 'ui/styling/Theme.css'

import { useEmbedWidgetState } from './hooks/useEmbedWidgetState'
import { EmbedWidgetFieldRef, EmbedWidgetType, EmbedWidgetUrlRef } from './embedWidgetTypes'
import { getEmbedWidgetFields } from './embedWidgetUtils'

import { EmbedWidgetStyles } from './EmbedWidget.css'

type EmbedWidgetProps = {}

export const EmbedWidget: WidgetComponent<EmbedWidgetType, EmbedWidgetProps> = ({
    widget,
    isEditing,
}) => {
    const { url, isVisible, size, isLoading, onLoad, onError, isError } =
        useEmbedWidgetState(widget)

    if (!isVisible) return null

    return (
        <Box my="l">
            <Skeleton flex center justifyContent="center" isLoading={isLoading}>
                {isError && (
                    <Box py="l">
                        <Body color="textError">
                            There was a problem loading the embedded content.
                        </Body>
                    </Box>
                )}
                {!isError && (
                    <Box
                        as={EmbeddableContent}
                        className={EmbedWidgetStyles.styleFunction({ size })}
                        pointerEvents={isEditing ? 'none' : undefined}
                        allowFullScreen={true}
                        src={url}
                        onLoad={onLoad}
                        onError={onError}
                    />
                )}
            </Skeleton>
        </Box>
    )
}

const DEBOUNCE_RATE = 300 // ms

type EmbedWidgetAdminControlsProps = {}

export const EmbedWidgetAdminControls: WidgetAdminControlsComponent<
    EmbedWidgetType,
    EmbedWidgetAdminControlsProps
> = ({ widget, onChange }) => {
    const { size, src } = widget.attrs

    const debouncedOnChange = useDebounce(onChange, DEBOUNCE_RATE) as typeof onChange

    const sourceType = src?.type ?? 'field'
    const fieldApiName = (src as EmbedWidgetFieldRef)?.fieldApiName ?? ''
    const url = (src as EmbedWidgetUrlRef)?.url ?? ''

    const [localUrl, setLocalUrl] = useState(url)

    const { fields } = useLayoutEditorContext()
    const supportedFields = getEmbedWidgetFields(fields)

    return (
        <Box flex column px="l" gap="3xl" height="full" overflowY="auto">
            <Box flex column gap="l">
                <Field
                    label="Data source"
                    leftSlotContent={
                        <InfoMark size="xs" zIndex={1500}>
                            Supports YouTube, Figma and image URLs, or any URL that can be embedded
                            within an HTML iframe element
                        </InfoMark>
                    }
                >
                    <RadioCardGroup
                        value={sourceType}
                        onValueChange={(value: string) => {
                            let newValue: EmbedWidgetFieldRef | EmbedWidgetUrlRef
                            if (value === 'field') {
                                newValue = {
                                    type: 'field',
                                    fieldApiName: '',
                                }
                            } else {
                                newValue = {
                                    type: 'url',
                                    url: '',
                                }
                            }

                            onChange((attrs) => {
                                attrs.set('src', newValue)
                            })
                        }}
                        style={{
                            display: 'grid',
                            gridTemplateColumns: 'repeat(auto-fill, 1fr)',
                        }}
                    >
                        <RadioCard value="field" icon={{ name: 'Text' }}>
                            From field
                        </RadioCard>
                        <RadioCard value="url" icon={{ name: 'Link' }}>
                            From URL
                        </RadioCard>
                    </RadioCardGroup>
                </Field>
                {sourceType === 'field' && (
                    <Select
                        placeholder="Select field..."
                        label="Field"
                        isSearchable
                        value={fieldApiName}
                        onChange={(value) => {
                            onChange((attrs) => {
                                attrs.set('src', {
                                    type: 'field',
                                    fieldApiName: value,
                                })
                            })
                        }}
                    >
                        {supportedFields.map((field) => (
                            <SelectOption
                                key={field.api_name}
                                value={field.api_name}
                                label={field.label}
                            />
                        ))}
                    </Select>
                )}
                {sourceType === 'url' && (
                    <Input
                        label="URL"
                        placeholder="https://..."
                        value={localUrl}
                        onChange={(e) => {
                            const newValue = e.target.value

                            setLocalUrl(newValue)
                            debouncedOnChange((attrs) => {
                                attrs.set('src', {
                                    type: 'url',
                                    url: newValue,
                                })
                            })
                        }}
                    ></Input>
                )}
            </Box>
            <LayoutEditorControlSeparator px={0} />
            <Field label="Size">
                <RadioCardGroup
                    value={size}
                    onValueChange={(value: string) => {
                        const newValue = value || undefined

                        onChange((attrs) => {
                            attrs.set('size', newValue)
                        })
                    }}
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fill, 1fr)',
                    }}
                >
                    <RadioCard
                        value="small"
                        icon={() => <EmbedWidgetSizeSmallIcon isActive={size === 'small'} />}
                        cardIconWrapperProps={{
                            style: {
                                padding: `${theme.space.m} 0`,
                            },
                        }}
                    >
                        Small
                    </RadioCard>
                    <RadioCard
                        value="medium"
                        icon={() => <EmbedWidgetSizeMediumIcon isActive={size === 'medium'} />}
                        cardIconWrapperProps={{
                            style: {
                                padding: `${theme.space.m} 0`,
                            },
                        }}
                    >
                        Medium
                    </RadioCard>
                    <RadioCard
                        value="large"
                        icon={() => <EmbedWidgetSizeLargeIcon isActive={size === 'large'} />}
                        cardIconWrapperProps={{
                            style: {
                                padding: `${theme.space.m} 0`,
                            },
                        }}
                    >
                        Large
                    </RadioCard>
                </RadioCardGroup>
            </Field>
        </Box>
    )
}

type RadioCardIconProps = {
    isActive: boolean
}

const EmbedWidgetSizeSmallIcon: React.FC<RadioCardIconProps> = ({ isActive }) => {
    return (
        <svg
            width="40px"
            height="100%"
            viewBox="0 0 40 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.5"
                y="10"
                width="39"
                height="8"
                rx="1.5"
                style={{
                    transition: theme.transition.colors,
                    stroke: isActive ? theme.color.theme600 : theme.color.gray300,
                }}
            />
            <rect
                y="9.5"
                width="34"
                height="3"
                rx="1"
                transform="matrix(-1 0 0 1 37 3)"
                style={{
                    transition: theme.transition.colors,
                    fill: isActive ? theme.color.theme100 : theme.color.gray100,
                }}
            />
        </svg>
    )
}

const EmbedWidgetSizeMediumIcon: React.FC<RadioCardIconProps> = ({ isActive }) => {
    return (
        <svg
            width="40px"
            height="100%"
            viewBox="0 0 40 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.5"
                y="6"
                width="39"
                height="15"
                rx="1.5"
                style={{
                    transition: theme.transition.colors,
                    stroke: isActive ? theme.color.theme600 : theme.color.gray300,
                }}
            />
            <rect
                y="5.5"
                width="34"
                height="10"
                rx="1"
                transform="matrix(-1 0 0 1 37 3)"
                style={{
                    transition: theme.transition.colors,
                    fill: isActive ? theme.color.theme100 : theme.color.gray100,
                }}
            />
        </svg>
    )
}

const EmbedWidgetSizeLargeIcon: React.FC<RadioCardIconProps> = ({ isActive }) => {
    return (
        <svg
            width="40px"
            height="100%"
            viewBox="0 0 40 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.5"
                y="1.5"
                width="39"
                height="24"
                rx="1.5"
                style={{
                    transition: theme.transition.colors,
                    stroke: isActive ? theme.color.theme600 : theme.color.gray300,
                }}
            />
            <rect
                y="1"
                width="34"
                height="19"
                rx="1"
                transform="matrix(-1 0 0 1 37 3)"
                style={{
                    transition: theme.transition.colors,
                    fill: isActive ? theme.color.theme100 : theme.color.gray100,
                }}
            />
        </svg>
    )
}
