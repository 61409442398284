import { Box } from 'ui/components/Box'
import { Dropdown } from 'ui/components/Dropdown/Dropdown'
// Loading these separately to avoid circular import.
import {
    Label as DropdownTitle,
    Separator as DropdownSeparator,
    Trigger as DropdownTrigger,
} from 'ui/components/Dropdown/Dropdown.parts'
import { DropdownContent } from 'ui/components/Dropdown/DropdownContent'
import { DropdownHeadSearch } from 'ui/components/Dropdown/DropdownHeadSearch'
import { Icon } from 'ui/components/Icon'
import { Tag } from 'ui/components/Tag'
import { Body } from 'ui/components/Text'
import EmptyStyles from 'ui/helpers/emptyStyles.css'
import { makeComponent } from 'ui/helpers/recipes'

import {
    ClearButtonStyles,
    IndicatorIconStyles,
    MultiValueStyles,
    PlaceholderStyles,
    RightSlotContentStyles,
    RootStyles,
    SingleValueStyles,
    TriggerStyles,
    ValueWrapperStyles,
} from './Select.css'

export const Root = makeComponent(Dropdown, RootStyles)
export const Trigger = makeComponent(DropdownTrigger, TriggerStyles)
export const ValueWrapper = makeComponent('div', ValueWrapperStyles)
export const SingleValue = makeComponent('div', SingleValueStyles)
export const MultiValue = makeComponent('div', MultiValueStyles)
export const MultiValueItem = Tag
export const Placeholder = makeComponent(Body, PlaceholderStyles)
export const Content = DropdownContent
export const Item = makeComponent(Box, EmptyStyles)
export const ItemComplex = makeComponent(Box, EmptyStyles)
export const Separator = makeComponent(DropdownSeparator, EmptyStyles)
export const Title = makeComponent(DropdownTitle, EmptyStyles)
export const Search = makeComponent(DropdownHeadSearch, EmptyStyles)
export const IndicatorIcon = makeComponent(Icon, IndicatorIconStyles)
export const RightSlot = makeComponent('div', RightSlotContentStyles)
export const ClearButton = makeComponent('button', ClearButtonStyles)
