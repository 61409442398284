import React from 'react'

import { Agent, AgentInstructions } from 'data/hooks/agents/types'
import { ChatAiMessage } from 'features/AiAppBuilder/chatUtils/openAiTypes'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon/Icon'
import { Body } from 'ui/components/Text'

import { processAgentMessage } from './agentMessageProcessing'

export function AgentChatMessageItem<TMessage extends ChatAiMessage>({
    message,
    retryMessage,
    agent,
    instructions,
}: {
    message: TMessage
    retryMessage?: (message: TMessage) => Promise<void>
    agent: Agent
    instructions: AgentInstructions
}) {
    return (
        <>
            <Box
                pt="m"
                pb="m"
                pr="l"
                pl="l"
                background="surface"
                alignSelf="flex-start"
                className="markdown ChatMarkdown"
                borderRadius="2xl"
                position="relative"
                flex
            >
                <Box
                    background="surface"
                    borderRadius="pill"
                    borderStyle="base"
                    borderWidth="base"
                    borderColor="borderWeak"
                    p="s"
                    alignItems="center"
                    flex
                    width="4xl"
                    maxHeight="4xl"
                    style={{
                        marginTop: '-4px',
                        marginRight: '12px',
                        marginLeft: '-12px',
                    }}
                >
                    <Icon name={agent.icon?.name || 'Bot'} size="l" />
                </Box>
                <Body size="m" maxWidth="full">
                    {processAgentMessage(message.content?.toString() || '', instructions)}
                </Body>
            </Box>
            {'sendingFailed' in message && message.sendingFailed && (
                <Body size="xs" color="textError" alignSelf="flex-start">
                    Failed to send.{' '}
                    {retryMessage && (
                        <Box
                            as="span"
                            textDecoration="underline"
                            role="button"
                            onClick={(e: React.MouseEvent) => {
                                retryMessage(message)
                                e.preventDefault()
                            }}
                        >
                            Try again
                        </Box>
                    )}
                </Body>
            )}
        </>
    )
}
