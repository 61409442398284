import React from 'react'

import { WidgetComponent } from 'features/views/LayoutEditor/types'
import { BoardView } from 'features/views/ListView/BoardView/BoardView'
import { CardView } from 'features/views/ListView/CardView/CardView'
import { ListViewContextProvider } from 'features/views/ListView/ListViewContext'
import { TableView } from 'features/views/ListView/TableView/TableView'
import { ThreadView } from 'features/views/ListView/ThreadView/ThreadView'

import { Box } from 'ui/components/Box'
import { Body } from 'ui/components/Text'

import { useListViewWidgetState } from './hooks/useListViewWidgetState'
import { ListViewWidgetType } from './listViewWidgetTypes'

import { ListViewWidgetStyle } from './ListViewWidget.css'

export const ListViewWidget: WidgetComponent<ListViewWidgetType> = ({ widget, isEditing }) => {
    const {
        record,
        viewConfig,
        linkedFieldSid,
        additionalFilters,
        title,
        hasObjectAccess,
        hasLinkedFieldAccess,
    } = useListViewWidgetState({ widget })

    let content = <TableView />
    switch (widget.attrs.display) {
        case 'cards':
            content = <CardView />
            break
        case 'board':
            content = <BoardView />
            break
        case 'thread':
            content = <ThreadView />
            break
    }

    if (!record) return null
    if (!hasLinkedFieldAccess && !isEditing) return null
    if (!hasObjectAccess && !isEditing) return null
    if (!hasObjectAccess && isEditing) {
        return (
            <Box flex column gap="m" py="l">
                <Body color="textWeakest">Select a data source to get started</Body>
            </Box>
        )
    }

    return (
        <Box className={ListViewWidgetStyle} pointerEvents={isEditing ? 'none' : undefined}>
            <ListViewContextProvider
                viewConfig={viewConfig}
                isEmbedded
                isEditMode={isEditing}
                embeddedByFieldSid={linkedFieldSid}
                embeddedByFieldValue={record?._sid}
                embeddedByRecord={record}
                additionalFilters={additionalFilters}
                allowCreate={widget.attrs.allowCreateRecords ?? true}
                title={title}
            >
                {content}
            </ListViewContextProvider>
        </Box>
    )
}
