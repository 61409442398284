import { useMemo } from 'react'

import { useDetailViewContext } from 'features/views/DetailView/hooks/useDetailViewContext'
import { DetailViewHeaderSpacious } from 'features/views/DetailView/types'

export function useDetailViewSubtitleAttributeState() {
    const { header, recordManager, fields } = useDetailViewContext()!

    const subtitleFieldApiName = (header as DetailViewHeaderSpacious).subtitle?.fieldApiName
    const subtitleField = fields.find((field) => field.api_name === subtitleFieldApiName)

    const value = (recordManager.record?.[subtitleField?.api_name!] as unknown) ?? ('' as unknown)

    return useMemo(
        () => ({
            field: subtitleField,
            value,
        }),
        [subtitleField, value]
    )
}
