import React, { forwardRef, useMemo, useState } from 'react'

import { composeRefs } from '@radix-ui/react-compose-refs'
import { Editor, JSONContent } from '@tiptap/core'

import { useKeyboardShortcutsExtension } from 'features/tiptap/Extensions/KeyboardShortcutExtension'
import { TipTapEditor, TipTapEditorHandle } from 'features/tiptap/TipTapEditor'

import { SelectionExtension } from './Extensions/SelectionExtension'
import { TextareaToolbar } from './Toolbar/TextareaToolbar'
import * as Parts from './Textarea.parts'

export type RichTextValue = JSONContent | null

type TextareaRichTextEditorRef = TipTapEditorHandle

type TextareaRichTextEditorProps = Omit<
    React.ComponentPropsWithoutRef<typeof TipTapEditor>,
    'value' | 'onChange'
> & {
    value?: RichTextValue
    onChange?: (value: RichTextValue) => void
    autoSize?: boolean
    enableFileUpload?: boolean
}

export const TextareaRichTextEditor = forwardRef<
    TextareaRichTextEditorRef,
    TextareaRichTextEditorProps
>(
    (
        { additionalExtensions = [], onKeyDown, enableFileUpload = true, value = null, ...props },
        ref
    ) => {
        const keyboardShortcuts = useKeyboardShortcutsExtension({
            name: 'shortcuts',
            shortcuts: {
                'Mod-Enter': () => {
                    return true
                },
            },
        })

        const selection = useMemo(() => {
            return SelectionExtension.configure({})
        }, [])

        const handleKeyDown = (e: React.KeyboardEvent) => {
            onKeyDown?.(e)

            if (e.key === 'Tab') {
                e.preventDefault()
            }
        }

        const extensions = [keyboardShortcuts, selection, ...additionalExtensions]

        const [editor, setEditor] = useState<Editor | null>(null)

        return (
            <Parts.RichTextEditorWrapper>
                {editor && (
                    <TextareaToolbar
                        disabled={props.disabled}
                        readOnly={props.readOnly}
                        editor={editor}
                        enableFileUpload={enableFileUpload}
                    />
                )}
                <Parts.RichTextEditor
                    ref={composeRefs((ref) => setEditor(ref?.editor ?? null), ref)}
                    {...props}
                    content={value}
                    documentStyle={true}
                    allowMentions={false}
                    disableToolbar={true}
                    additionalExtensions={extensions}
                    onKeyDown={handleKeyDown}
                    aria-disabled={props.disabled}
                    showPlaceholderWhenReadOnly
                    linkOptions={{
                        openOnClick: false,
                    }}
                />
            </Parts.RichTextEditorWrapper>
        )
    }
)
