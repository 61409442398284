import React, { useEffect, useMemo, useRef, useState } from 'react'

import shortid from 'shortid'

import {
    cancelPendingMessage,
    ConversationResponseData,
    useAgentConversationChatState,
    usePostChatMessageAsync,
} from 'data/hooks/agents/chat'
import { invalidateConversations } from 'data/hooks/agents/conversations'
import { useJob } from 'data/hooks/agents/job'
import { Agent, AgentInstructions, AgentTrigger, ToolCallResult } from 'data/hooks/agents/types'
import { useWebsocketListener } from 'data/websockets/useWebsocketListener'
import { JobListItem } from 'features/Agents/Jobs/JobListItem'
import { ChatUserMessageContentType } from 'features/AiAppBuilder/chatUtils/chatTypes'
import { ChatMessageToolCall } from 'features/AiAppBuilder/chatUtils/openAiTypes'
import { ChatBox } from 'features/AiPlayground/components/ChatBox'

import { LoadingScreen } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from 'ui/components/Collapsible'
import { Icon } from 'ui/components/Icon'
import { Body, Headline } from 'ui/components/Text'

import { AgentChatMessageItem } from './AgentChatMessage'
import { AgentChatMessage } from './types'

import './agentChatBotStyles.css'

type AgentChatBoxProps = {
    agent: Agent
    instructions: AgentInstructions
    trigger: AgentTrigger
    conversationSid?: string
    setConversationSid?: (sid: string) => void
    conversationContext?: string
    showToolUsageOverride?: boolean
}

export function AgentChatBox({
    agent,
    instructions,
    trigger,
    conversationSid,
    setConversationSid,
    conversationContext,
    showToolUsageOverride,
}: AgentChatBoxProps) {
    const [pendingFirstMessage, setPendingFirstMessage] = useState<AgentChatMessage | undefined>()

    const sessionId = useRef<string>(conversationSid || shortid())
    const { data: conversationState, isLoading } = useAgentConversationChatState(
        agent._sid,
        sessionId.current,
        conversationSid
    )
    const { postMessageAsync } = usePostChatMessageAsync(sessionId.current)

    async function handlePostMessage(message: ChatUserMessageContentType) {
        if (!conversationSid) {
            setPendingFirstMessage({
                id: shortid(),
                content: message,
                type: 'human',
                isSending: true,
            })
        }

        try {
            const result = await postMessageAsync({
                agentSid: agent._sid,
                conversationSid,
                sessionId: sessionId.current,
                triggerSid: trigger?._sid,
                message,
                conversationContext,
            })

            if (result?.conversation_sid) {
                setConversationSid?.(result.conversation_sid)
            }
        } catch (error) {
            console.error(error)
            setPendingFirstMessage((existing) => {
                if (!existing) return
                return {
                    ...existing,
                    sendingFailed: true,
                }
            })
        }
    }

    async function handleRetryMessage(message: AgentChatMessage) {
        if (!message.id || message.type !== 'human') return

        if (conversationSid) {
            cancelPendingMessage(conversationSid, message.id)
        }

        await handlePostMessage(message.content)
    }

    useEffect(() => {
        if (conversationSid) {
            invalidateConversations()
        }
    }, [conversationSid])

    const serverMessages = conversationState?.messages
    const interruptMessages = conversationState?.interrupt_messages
    const pendingMessage = conversationState?.pendingMessage

    const messages = useMemo(() => {
        if (pendingFirstMessage && !conversationSid) {
            return [pendingFirstMessage]
        }
        return [
            ...(serverMessages?.filter((message) => message.type !== 'remove') ?? []),
            ...(interruptMessages ?? []),
            ...(pendingMessage ? [pendingMessage] : []),
        ]
    }, [conversationSid, pendingFirstMessage, serverMessages, interruptMessages, pendingMessage])
    //return <LoadingMessage conversationSid={conversationSid} />
    const welcomeMessage =
        trigger?.config?.welcome_message || 'Feel free to ask me a question to get started.'
    if (isLoading) {
        return <LoadingScreen isLoading={true} />
    }
    return (
        <ChatBox
            messages={messages}
            onPostMessage={handlePostMessage}
            retryMessage={handleRetryMessage}
            errorMessage={conversationState?.error_message}
            renderMessage={(message) =>
                renderMessage(
                    agent,
                    instructions,
                    message as AgentChatMessage,
                    conversationState as ConversationResponseData,
                    handleRetryMessage,
                    showToolUsageOverride
                )
            }
            renderLoadingMessage={() => (
                <LoadingMessage agent={agent} sessionId={sessionId.current} />
            )}
            aiIcon={agent.icon?.name}
            renderEmptyState={() => (
                <Box
                    flex
                    column
                    alignItems="center"
                    justifyContent="center"
                    height="50vh"
                    gap="xl"
                    py="2xl"
                >
                    <Box
                        background="surface"
                        borderWidth="base"
                        borderColor="borderWeak"
                        p="xl"
                        borderRadius="xl"
                    >
                        <Icon
                            name={(agent.icon && agent.icon.name) || 'Bot'}
                            color="textWeaker"
                            size="3xl"
                        />
                    </Box>

                    <Box flex column alignItems="center" gap="m">
                        <Headline size="l">{agent?.title || 'AI Assistant'}</Headline>
                        <Body color="textWeak">{welcomeMessage}</Body>
                    </Box>
                </Box>
            )}
        />
    )
}

function LoadingMessage({ sessionId }: { sessionId: string; agent: Agent }) {
    const [status, setStatus] = useState<string>('...')
    useWebsocketListener(sessionId, (message) => {
        if (typeof message === 'string') {
            setStatus(message)
        }
    })

    return (
        <Box
            pt="m"
            pb="m"
            pr="l"
            pl="l"
            background="surface"
            borderRadius="2xl"
            position="relative"
            flex
            alignItems="flex-start"
        >
            <Box
                background="surface"
                borderRadius="pill"
                borderStyle="base"
                borderWidth="base"
                borderColor="borderWeak"
                p="s"
                alignItems="center"
                flex
                width="4xl"
                maxHeight="4xl"
                style={{
                    marginTop: '-4px',
                    marginRight: '12px',
                    marginLeft: '-12px',
                }}
            >
                <Icon className="spin" name="Settings" size="l" />
            </Box>
            <Body
                size="m"
                maxWidth="full"
                background="surfaceStrong"
                color="textWeak"
                rounded="s"
                px="s"
            >
                {status}
            </Body>
        </Box>
    )
}

function renderMessage(
    agent: Agent,
    instructions: AgentInstructions,
    message: AgentChatMessage,
    conversationData: ConversationResponseData,
    retryMessage: (message: AgentChatMessage) => Promise<void>,
    showToolUsageOverride?: boolean
) {
    if (message.type === 'ai' && message.tool_calls && message.tool_calls?.length > 0) {
        if (instructions?.options?.display_tool_usage === false && !showToolUsageOverride) {
            return undefined
        }
        return message.tool_calls.map((toolCall) => {
            const result = conversationData.tool_call_results?.find(
                (result) => result.tool_call_id === toolCall.id
            )
            if (toolCall.name === 'plan_job' && result?.status === 'ok') {
                return (
                    <CreateJobChatMessage
                        key={toolCall.id}
                        agent={agent}
                        toolCall={toolCall}
                        conversationData={conversationData}
                    />
                )
            } else {
                return (
                    <ToolExecutedChatMessage
                        key={toolCall.id}
                        conversationData={conversationData}
                        toolCall={toolCall}
                    />
                )
            }
        })
    } else if (message.type === 'ai') {
        return (
            <AgentChatMessageItem
                agent={agent}
                instructions={instructions}
                message={message}
                retryMessage={retryMessage}
            />
        )
    }
    return undefined
}

function CreateJobChatMessage({
    agent,
    toolCall,
    conversationData,
}: {
    agent: Agent
    toolCall: ChatMessageToolCall
    conversationData: ConversationResponseData
}) {
    const result = conversationData.tool_call_results?.find(
        (result) => result.tool_call_id === toolCall.id
    )

    const { data: jobData } = useJob(agent._sid, result?.result)
    const job = jobData?.job

    if (!result) return null
    return (
        <Box
            borderRadius="2xl"
            p="m"
            background="teal100"
            rounded="m"
            alignSelf="flex-start"
            className="markdown"
            fontSize="bodyS"
        >
            {job && <JobListItem job={job} />}
        </Box>
    )
}

function ToolExecutedChatMessage({
    toolCall,
    conversationData,
}: {
    toolCall: ChatMessageToolCall
    conversationData: ConversationResponseData
}) {
    const result = conversationData.tool_call_results?.find(
        (result) => result.tool_call_id === toolCall.id
    )
    if (!result) return null

    return (
        <Box
            p="l"
            borderRadius="2xl"
            background="teal100"
            rounded="m"
            alignSelf="flex-start"
            className="markdown"
            fontSize="bodyS"
        >
            <ToolCallResultDisplay
                call={toolCall}
                callResults={conversationData.tool_call_results}
                isRootLevel={true}
            />
        </Box>
    )
}

function ToolCallResultDisplay({
    isRootLevel = false,
    call,
    callResults,
}: {
    isRootLevel?: boolean
    call: ChatMessageToolCall
    callResults?: ToolCallResult[]
}) {
    const result = callResults?.find((result) => result.tool_call_id === call.id)
    const displayName = (result as any)?.display_name || `Called tool: ${call.name || 'Unknown'}`

    const icon = isRootLevel && result?.status === 'ok' ? 'CheckSquare' : 'ArrowRightSquare'
    return (
        <Box flex column gap="m">
            <Collapsible>
                <CollapsibleTrigger width="full" display="block">
                    <Box flex center gap="s" width="full">
                        <Icon name={icon} />
                        {displayName}
                        <Box grow />
                        <Icon name="ChevronDown" color="textWeaker" />
                    </Box>
                </CollapsibleTrigger>
                <CollapsibleContent flex column p="m" rounded="m" gap="s">
                    <Box
                        as="ul"
                        flex
                        column
                        pl="4xl"
                        gap="m"
                        borderLeftWidth="base"
                        borderColor="teal300"
                    >
                        {result?.log?.map((log, index) => (
                            <Box as="li" key={index}>
                                {typeof log === 'object' &&
                                'args' in log &&
                                'name' in log &&
                                'id' in log ? (
                                    <ToolCallResultDisplay
                                        call={
                                            {
                                                id: log.id || shortid(),
                                                name: log.name || '',
                                                args: JSON.stringify(log.args),
                                                type: 'tool_call',
                                            } as ChatMessageToolCall
                                        }
                                        callResults={result?.tool_call_results}
                                    />
                                ) : (
                                    <Box whiteSpace="pre-wrap" flex alignItems="baseline">
                                        {log}
                                    </Box>
                                )}
                            </Box>
                        ))}
                        {result?.result && (
                            <Box as="li">
                                <Body>Result: {result?.result}</Body>
                            </Box>
                        )}
                    </Box>
                </CollapsibleContent>
            </Collapsible>
        </Box>
    )
}
