import React from 'react'

import { DetailViewContent } from './DetailViewContent'
import { DetailViewContextProvider } from './DetailViewContext'

type DetailViewProps = {
    isEmbedded?: boolean
    recordSid?: string
    hideHeader?: boolean
    isSingleRecordListView?: boolean
}

export const DetailView: React.FC<DetailViewProps> = ({
    isEmbedded = false,
    recordSid,
    hideHeader,
    isSingleRecordListView = false,
}) => {
    return (
        <DetailViewContextProvider
            isEmbedded={isEmbedded}
            recordSid={recordSid}
            isSingleRecordListView={isSingleRecordListView}
        >
            <DetailViewContent hideHeader={hideHeader} />
        </DetailViewContextProvider>
    )
}
