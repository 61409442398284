import { useMemo } from 'react'

import { useWorkflowSchema, WorkflowSchema } from 'data/hooks/workflows/workflows'
import { assertIsDefined } from 'data/utils/ts_utils'

import { useAgentSkillActionFinalSchema } from './Actions/useAgentSkillActionFinalSchema'
import { useAPIRequestActionFinalSchema } from './Actions/useAPIRequestActionFinalSchema'
import { useCreateTaskActionFinalSchema } from './Actions/useCreateTaskActionFinalSchema'
import { useFindRecordsActionFinalSchema } from './Actions/useFindRecordsActionFinalSchema'
import { useGetRecordActionFinalSchema } from './Actions/useGetRecordActionFinalSchema'
import { useRecordChangeActionFinalSchema } from './Actions/useRecordChangeActionFinalSchema'
import { useReturnDataActionFinalSchema } from './Actions/useReturnDataActionFinalSchema'
import { useSetVariablesActionFinalSchema } from './Actions/useSetVariablesActionFinalSchema'
import { useLoopFinalSchema } from './FlowControl/useLoopFinalSchema'
import { useActionButtonTriggerFinalSchema } from './Triggers/useActionButtonTriggerFinalSchema'
import { useRecordChangeTriggerFinalSchema } from './Triggers/useRecordChangeTriggerFinalSchema'
import { useStartedByAgentTriggerFinalSchema } from './Triggers/useStartedByAgentTriggerFinalSchema'
import { useWebhookTriggerFinalSchema } from './Triggers/useWebhookTriggerFinalSchema'
import { VARIABLES_STATE_KEY } from './common'

export type PreparedWorkflowSchema = WorkflowSchemaStateItem[]

export function useFinalWorkflowSchema(
    flow: WorkflowDto,
    forNodeAtPath?: string[]
): PreparedWorkflowSchema {
    const getRecordChangeTriggerSchema = useRecordChangeTriggerFinalSchema()
    const getWebhookTriggerSchema = useWebhookTriggerFinalSchema()
    const getStartedByAgentTriggerSchema = useStartedByAgentTriggerFinalSchema()
    const getActionButtonTriggerSchema = useActionButtonTriggerFinalSchema()
    const getRecordChangeActionSchema = useRecordChangeActionFinalSchema()
    const getRecordActionSchema = useGetRecordActionFinalSchema()
    const getFindRecordsActionSchema = useFindRecordsActionFinalSchema()
    const getLoopSchema = useLoopFinalSchema()
    const getCreateTaskActionSchema = useCreateTaskActionFinalSchema()
    const getAPIRequestActionSchema = useAPIRequestActionFinalSchema()
    const getSetVariablesActionSchema = useSetVariablesActionFinalSchema()
    const getReturnDataActionSchema = useReturnDataActionFinalSchema()
    const getAgentSkillActionSchema = useAgentSkillActionFinalSchema()

    const { data: schema } = useWorkflowSchema()
    console.log('## forNodeAtPath', forNodeAtPath)
    return useMemo(() => {
        const triggerProcessors: Record<
            string,
            (trigger: WorkflowTriggerConfig) => WorkflowSchemaTriggerType | undefined
        > = {
            record_created: getRecordChangeTriggerSchema,
            record_updated: getRecordChangeTriggerSchema,
            record_created_or_updated: getRecordChangeTriggerSchema,
            webhook: getWebhookTriggerSchema,
            action_button_clicked: getActionButtonTriggerSchema,
            started_by_agent: getStartedByAgentTriggerSchema,
        }

        const nodeProcessors: Record<
            string,
            (node: WorkflowNode) => WorkflowSchemaNodeType | undefined
        > = {
            create_record: getRecordChangeActionSchema as (
                node: WorkflowNode
            ) => WorkflowSchemaNodeType | undefined,
            update_record: getRecordChangeActionSchema as (
                node: WorkflowNode
            ) => WorkflowSchemaNodeType | undefined,
            create_task: getCreateTaskActionSchema as (
                node: WorkflowNode
            ) => WorkflowSchemaNodeType | undefined,
            find_records: getFindRecordsActionSchema as (
                node: WorkflowNode
            ) => WorkflowSchemaNodeType | undefined,
            api_request: getAPIRequestActionSchema as (
                node: WorkflowNode
            ) => WorkflowSchemaNodeType | undefined,
            get_record: getRecordActionSchema as (
                node: WorkflowNode
            ) => WorkflowSchemaNodeType | undefined,
            set_variables: getSetVariablesActionSchema as (
                node: WorkflowNode
            ) => WorkflowSchemaNodeType | undefined,
            return_data: getReturnDataActionSchema as (
                node: WorkflowNode
            ) => WorkflowSchemaNodeType | undefined,
            invoke_agent_skill: getAgentSkillActionSchema as (
                node: WorkflowNode
            ) => WorkflowSchemaNodeType | undefined,
        }
        const result: WorkflowSchemaStateItemGroup[] = []

        if (flow.trigger.trigger_type) {
            const triggerSchema =
                triggerProcessors[flow.trigger.trigger_type]?.(flow.trigger) ??
                schema?.triggers.find((trigger) => trigger.id === flow.trigger.trigger_type)

            assertIsDefined(triggerSchema)

            result.push({
                id: 'trigger',
                name: flow.trigger.name ?? 'Trigger',
                type: 'group',
                items: triggerSchema.outputs,
            })
        }

        // This is the global Variables state group. All
        // SetVariables actions write into here.
        result.push({
            id: VARIABLES_STATE_KEY,
            name: 'Variables',
            type: 'group',
            items: [],
        })

        if (forNodeAtPath) {
            addAccessibleStateItems(result, {
                schema,
                nodeProcessors,
                getLoopSchema,
                workflow: flow,
                nodePath: forNodeAtPath,
            })
        } else {
            // If there's no node id provided, just handle all the root nodes.
            flow.chain?.forEach((item) => {
                addStateItemsFromNode(result, {
                    node: item,
                    schema,
                    nodeProcessors,
                    getLoopSchema,
                    existingSchema: result,
                })
            })
        }

        return result
    }, [
        getRecordChangeTriggerSchema,
        getWebhookTriggerSchema,
        getActionButtonTriggerSchema,
        getStartedByAgentTriggerSchema,
        getRecordChangeActionSchema,
        getCreateTaskActionSchema,
        getFindRecordsActionSchema,
        getAPIRequestActionSchema,
        getRecordActionSchema,
        getSetVariablesActionSchema,
        getReturnDataActionSchema,
        getAgentSkillActionSchema,
        flow,
        forNodeAtPath,
        schema,
        getLoopSchema,
    ])
}

// Only get the state items that can be used by the current node.
// This means state items from all the previous nodes (both in the parent chains and in the same chain).
function addAccessibleStateItems(
    groups: WorkflowSchemaStateItemGroup[],
    options: {
        schema?: WorkflowSchema
        nodeProcessors: Record<string, (node: WorkflowNode) => WorkflowSchemaNodeType | undefined>
        getLoopSchema: (
            config: WorkflowLoopConfig,
            existingSchema: WorkflowSchemaStateItem[]
        ) => WorkflowSchemaLoopType | undefined
        workflow: WorkflowDto
        nodePath: string[]
    }
) {
    const { schema, nodeProcessors, getLoopSchema, workflow, nodePath } = options
    if (nodePath.length < 1) return []

    const [nodeId, ...parentPath] = nodePath
    const parents = new Set(parentPath)

    function findNodeAndExtractState(
        nodeId: string,
        chain: WorkflowNode[]
    ): WorkflowNode | undefined {
        for (const item of chain) {
            if (item.id === nodeId) {
                return item
            } else {
                // We only include the state items for the nodes that are within the scope.
                const isItemWithinScope = parents.has(item.id)

                addStateItemsFromNode(groups, {
                    node: item,
                    schema,
                    nodeProcessors,
                    getLoopSchema,
                    existingSchema: groups,
                    keepLocalState: isItemWithinScope,
                })

                if (item.kind === 'loop' && isItemWithinScope) {
                    const node = findNodeAndExtractState(nodeId, item.chain)
                    if (node) return node
                }
            }
        }
    }

    const node = findNodeAndExtractState(nodeId, workflow.chain)
    if (!node) return []
}

function addStateItemsFromNode(
    groups: WorkflowSchemaStateItemGroup[],
    options: {
        node: WorkflowNode
        schema?: WorkflowSchema
        nodeProcessors: Record<
            string,
            (
                node: WorkflowNode,
                existingSchema: WorkflowSchemaStateItem[]
            ) => WorkflowSchemaNodeType | undefined
        >
        getLoopSchema: (
            config: WorkflowLoopConfig,
            existingSchema: WorkflowSchemaStateItem[]
        ) => WorkflowSchemaLoopType | undefined
        existingSchema: WorkflowSchemaStateItem[]
        keepLocalState?: boolean
    }
) {
    const { node, schema, nodeProcessors, getLoopSchema, existingSchema, keepLocalState } = options

    let nodeSchema: WorkflowSchemaItemBase | undefined
    let stateItems: WorkflowSchemaStateItem[] | undefined
    let targetStateKey: string | undefined
    switch (node.kind) {
        case 'action':
            nodeSchema =
                nodeProcessors[node.action_type]?.(node, existingSchema) ??
                schema?.nodes.find((n) => n.id === node.action_type)
            stateItems = (nodeSchema as WorkflowSchemaActionType)?.state ?? []
            targetStateKey = (nodeSchema as WorkflowSchemaActionType)?.target_state_key
            break

        case 'loop':
            nodeSchema = getLoopSchema(node, existingSchema ?? [])
            stateItems = (nodeSchema as WorkflowSchemaLoopType)?.state ?? []
            if (!keepLocalState) {
                // Remove local state items.
                stateItems = stateItems.filter(
                    (item) => (item as WorkflowSchemaControlFlowStateItem).scope === 'global'
                )
            }
            break
    }

    assertIsDefined(nodeSchema)

    // If the node has a target state key, use that. Otherwise, use the node id.
    // This is used for SetVariables actions which all store their state in the same group.
    const existingItem = targetStateKey
        ? groups.find((item) => item.id === targetStateKey)
        : undefined

    if (existingItem) {
        existingItem.items = [...existingItem.items, ...stateItems]
    } else {
        groups.push({
            id: targetStateKey || node.id,
            name: (node as WorkflowActionConfig).name ?? nodeSchema.name,
            type: 'group',
            items: stateItems,
        } as WorkflowSchemaStateItemGroup)
    }
}
