import { useCallback, useMemo, useState } from 'react'

import * as Y from 'yjs'

import { useActionsFromObjectId } from 'features/actions/helpers'
import { toYType } from 'features/utils/useYjsState'
import { WidgetAdminControlsProps } from 'features/views/LayoutEditor/types'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'
import { BannerWidgetType } from 'features/views/LayoutEditor/widgets/bannerWidgetTypes'

import useDebounce from 'v2/ui/utils/useDebounce'

const DEBOUNCE_RATE = 300 // ms

type UseBannerWidgetAdminControlsStateProps = {
    widget: BannerWidgetType
    onChange: WidgetAdminControlsProps<BannerWidgetType>['onChange']
}
export function useBannerWidgetAdminControlsState({
    widget,
    onChange,
}: UseBannerWidgetAdminControlsStateProps) {
    const {
        title = '',
        subtitle = '',
        style = 'app',
        isDismissable = false,
        visibilityFilters = [],
        actionButton,
        link,
    } = widget.attrs

    const { object, fields } = useLayoutEditorContext()

    const debouncedOnChange = useDebounce(onChange, DEBOUNCE_RATE) as typeof onChange

    const [localTitle, setLocalTitle] = useState(title)
    const onChangeTitle = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value

            setLocalTitle(value)
            debouncedOnChange((attrs) => {
                attrs.set('title', value)
            })
        },
        [debouncedOnChange]
    )

    const [localSubtitle, setLocalSubtitle] = useState(subtitle)
    const onChangeSubtitle = useCallback(
        (e: React.ChangeEvent<HTMLTextAreaElement>) => {
            const value = e.target.value

            setLocalSubtitle(value)
            debouncedOnChange((attrs) => {
                attrs.set('subtitle', value)
            })
        },
        [debouncedOnChange]
    )

    const onChangeIsDismissable = useCallback(
        (checked: boolean) => {
            debouncedOnChange((attrs) => {
                attrs.set('isDismissable', checked)
            })
        },
        [debouncedOnChange]
    )

    const styleOptions = useMemo(() => STYLE_OPTIONS_LIST, [])
    const selectedStyleOption = useMemo(() => STYLE_OPTIONS[style], [style])

    const onChangeStyle = useCallback(
        (value: string) => {
            onChange((attrs) => {
                attrs.set('style', value)
            })
        },
        [onChange]
    )

    const onChangeVisibilityFilters = useCallback(
        (filters: Filter[]) => {
            onChange((attrs) => attrs.set('visibilityFilters', toYType(filters)))
        },
        [onChange]
    )

    const { data: actions = [] } = useActionsFromObjectId(object?._sid ?? '')

    const actionOptions = useMemo(() => {
        return actions.map((action) => ({
            label: action.name,
            value: action._sid,
        }))
    }, [actions])

    const selectedActionId = actionButton?.id

    const onChangeActionButton = useCallback(
        (value: string) => {
            const newValue = value ? { id: value } : undefined

            onChange((attrs) => {
                if (!!newValue) {
                    attrs.set('actionButton', toYType(newValue))
                } else {
                    attrs.delete('actionButton')
                }
            })
        },
        [onChange]
    )

    const [localLink, setLocalLink] = useState<BannerWidgetType['attrs']['link']>(link)
    const onChangeLinkParams = useCallback(
        (partial: Partial<BannerWidgetType['attrs']['link']>) => {
            setLocalLink((prev) => {
                if (!partial || partial.href === '') {
                    return undefined
                }

                return { type: 'url', ...prev, ...partial } as BannerWidgetType['attrs']['link']
            })
            debouncedOnChange((attrs) => {
                if (!partial || partial.href === '') {
                    attrs.delete('link')
                } else {
                    let link = attrs.get('link')
                    if (!link) {
                        link = attrs.set('link', new Y.Map())
                    }

                    for (const [key, value] of Object.entries(partial)) {
                        link.set(key, value)
                    }
                }
            })
        },
        [debouncedOnChange]
    )

    const linkHref = localLink?.href ?? ''
    const onChangeLinkHref = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value

            onChangeLinkParams({ href: value })
        },
        [onChangeLinkParams]
    )

    const linkLabel = localLink?.label ?? ''
    const onChangeLinkLabel = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value

            onChangeLinkParams({ label: value })
        },
        [onChangeLinkParams]
    )

    const linkOpenAs = (localLink?.openAs ?? 'full_page') === 'new_tab'
    const onChangeLinkOpenAs = useCallback(
        (value: boolean) => {
            const newValue = value ? 'new_tab' : 'full_page'
            onChangeLinkParams({ openAs: newValue })
        },
        [onChangeLinkParams]
    )

    return {
        title: localTitle,
        onChangeTitle,
        subtitle: localSubtitle,
        onChangeSubtitle,
        isDismissable,
        onChangeIsDismissable,
        styleOptions,
        selectedStyleOption,
        onChangeStyle,
        object,
        fields,
        visibilityFilters,
        onChangeVisibilityFilters,
        actionOptions,
        selectedActionId,
        onChangeActionButton,
        onChangeLinkHref,
        linkHref,
        onChangeLinkLabel,
        linkLabel,
        onChangeLinkOpenAs,
        linkOpenAs,
    }
}

const STYLE_OPTIONS = {
    success: {
        label: 'Success',
        value: 'success',
    },
    warning: {
        label: 'Warning',
        value: 'warning',
    },
    error: {
        label: 'Error',
        value: 'error',
    },
    app: {
        label: 'App color',
        value: 'app',
    },
    gray: {
        label: 'Gray',
        value: 'gray',
    },
    border: {
        label: 'Border',
        value: 'border',
    },
}

const STYLE_OPTIONS_LIST = Object.values(STYLE_OPTIONS)
