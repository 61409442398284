import { useMemo } from 'react'

import { useRecordActionButtons } from 'features/views/ListView/Actions/hooks/useRecordActionButtons'
import { CardViewFooterAttribute } from 'features/views/ListView/CardView/types'
import { useCardViewContext } from 'features/views/ListView/CardView/useCardViewContext'

type UseCardViewFooterStateOptions = {
    record?: RecordDto
}

export function useCardViewFooterState({ record }: UseCardViewFooterStateOptions) {
    const { object, view, allFields, requestIncludedFields } = useCardViewContext()

    const cardFooter = view.options.cardFooter

    const actions = useRecordActionButtons({
        record: record!,
        object,
        view,
        showSystemActions: false,
        filterByActionDisplay: false,
    })

    const footerLeftValue = cardFooter?.leftFieldSid
    const footerLeftButton = useMemo(
        () => actions?.find((a) => a.action._sid === footerLeftValue)?.action,
        [actions, footerLeftValue]
    )

    const footerLeftAttribute = useMemo(() => {
        if (!!footerLeftButton) return undefined

        return getFooterField(allFields, footerLeftValue, cardFooter?.leftFieldDisplayOptions)
    }, [footerLeftButton, allFields, footerLeftValue, cardFooter?.leftFieldDisplayOptions])

    const footerRightValue = cardFooter?.rightFieldSid
    const footerRightButton = useMemo(
        () => actions?.find((a) => a.action._sid === footerRightValue)?.action,
        [actions, footerRightValue]
    )

    const footerRightAttribute = useMemo(() => {
        if (!!footerRightButton) return undefined

        return getFooterField(allFields, footerRightValue, cardFooter?.rightFieldDisplayOptions)
    }, [allFields, footerRightButton, footerRightValue, cardFooter?.rightFieldDisplayOptions])

    return useMemo(
        () => ({
            leftAction: footerLeftButton,
            leftAttribute: footerLeftAttribute,
            rightAction: footerRightButton,
            rightAttribute: footerRightAttribute,
            includeFields: requestIncludedFields,
        }),
        [
            footerLeftButton,
            footerLeftAttribute,
            footerRightButton,
            footerRightAttribute,
            requestIncludedFields,
        ]
    )
}

const getFooterField = (
    fields: FieldDto[],
    value?: string,
    displayOptions?: CardFooterDisplayOptions
): CardViewFooterAttribute | undefined => {
    if (value === '_record_stats') {
        return value
    }

    const field = fields.find((f) => f._sid === value)
    if (!field) return undefined

    return {
        id: field._sid,
        title: field.label,
        field,
        rawOptions: displayOptions ?? {},
        type: 'footer',
    }
}
