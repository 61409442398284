import { WidgetSchema } from 'features/views/LayoutEditor/types'

import { AgentWidget, AgentWidgetAdminControls, AgentWidgetType } from './AgentWidget'

export const AgentWidgetMeta: WidgetSchema<AgentWidgetType> = {
    category: 'data',
    label: 'AI Agent',
    icon: 'Bot',
    component: AgentWidget,
    adminControlsComponent: AgentWidgetAdminControls,
}
