import React from 'react'

import { Box } from 'ui/components/Box'

import { DetailViewHeader } from './headers/DetailViewHeader'
import { useDetailViewContentState } from './hooks/useDetailViewContentState'
import { DetailViewLayout } from './layouts/DetailViewLayout'

import { DetailViewLayoutStyles } from './layouts/DetailViewLayout.css'

type DetailViewContentProps = {
    hideHeader?: boolean
}

export const DetailViewContent: React.FC<DetailViewContentProps> = ({ hideHeader }) => {
    const { contentWidth } = useDetailViewContentState()

    return (
        <Box
            width="full"
            flex
            column
            grow
            gap="3xs"
            minWidth={0}
            minHeight="full"
            className={DetailViewLayoutStyles.styleFunction({ contentWidth })}
        >
            {!hideHeader && <DetailViewHeader />}
            <DetailViewLayout />
        </Box>
    )
}
