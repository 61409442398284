import React, { useMemo, useState } from 'react'

import Document from '@tiptap/extension-document'
import History from '@tiptap/extension-history'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import { Extensions, JSONContent } from '@tiptap/react'
import classNames from 'classnames'

import { isMultiLineText } from 'data/utils/fieldDefinitions'
import { LIST_TYPES, NUMERIC_TYPES, TEXT_TYPES } from 'features/admin/fields/common'
import { SimpleBlockTypes } from 'features/tiptap/BlockTypes'
import { createMentionsExtension } from 'features/tiptap/Extensions/createMentionsExtension'
import { useRecordLinkExtension } from 'features/tiptap/Extensions/useRecordLinkExtension'
import { SimpleRichTextStyle } from 'features/tiptap/SimpleRichTextStyle.css'
import { TipTapEditorBase, TipTapEditorBaseHandle } from 'features/tiptap/TipTapEditorBase'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'
import { useInstanceId } from 'v2/ui/utils/useInstanceId'

import { Box } from 'ui/components/Box'
import { Container } from 'ui/components/Container'
import { InputVariants, RootStyles } from 'ui/components/Input/Input.css'

import { ContextMenuManager } from './ContextItemsExtension/ContextMenuManager'
import { useExpressionTokenExtension } from './ExpressionTokenExtension'
import { useFormulaFunctionExtension } from './FormulaFunctionExtension'
import { ContextGroup, ContextSchema, ExpressionResult } from './types'
import { useCheckContextItemType } from './useCheckContextItemType'
import { useFormulaFunctionsGroup } from './useFormulaFunctionsGroup'
import { useReturnTypeGroup } from './useReturnTypeGroup'
import {
    buildContextSchemaWithPaths,
    extractExpressionsFromContent,
    makeFinalValue,
} from './utilities'

import { ContextTokenStyle, ExpressionInputStyle } from './ExpressionInputStyles.css'

type ExpressionInputProps = React.ComponentPropsWithoutRef<typeof Container> &
    InputVariants & {
        contextSchema: ContextSchema
        returnType?: FieldType
        returnExtraOptions?: Partial<WorkflowExtraOptions>
        value?: ExpressionResult
        onChange: (value: ExpressionResult | undefined) => void
        renderGroupTitle: (props: { item: ContextGroup; queryTerms?: string[] }) => React.ReactNode
        label?: string
        singleValue?: boolean
        allowFormulaFunctions?: boolean
        autoFocus?: boolean
        rightSlotContent?: React.ReactNode
    }

const SingleLineDocument = Document.extend({
    content: 'inline*',
})

export const ExpressionInput: React.FC<ExpressionInputProps> = ({
    contextSchema,
    returnType,
    returnExtraOptions,
    value,
    onChange,
    renderGroupTitle,
    placeholder,
    label,
    singleValue,
    allowFormulaFunctions = true,
    autoFocus,
    rightSlotContent,
    ...props
}) => {
    const [editorRef, setEditorRef] = useState<TipTapEditorBaseHandle | null>(null)
    const instanceId = useInstanceId()

    const returnsDocument = returnType === 'document'

    const allowRichText = returnsDocument
    const richTextMode = returnExtraOptions?.richTextMode ?? 'simplified'
    const allowedBlockTypes =
        allowRichText && richTextMode === 'simplified' ? SimpleBlockTypes : undefined

    const onlySingleValue =
        singleValue ||
        (returnType
            ? !TEXT_TYPES.includes(returnType) &&
              !LIST_TYPES.includes(returnType) &&
              !NUMERIC_TYPES.includes(returnType) &&
              !returnsDocument
            : false)
    const multiLineText = returnType ? isMultiLineText(returnType, returnExtraOptions) : false

    const { applyTypeCheckToItems } = useCheckContextItemType(returnType, returnExtraOptions)
    const baseExtensions: Extensions = [
        multiLineText ? Document : SingleLineDocument,
        Paragraph,
        Text,
        History,
    ]

    const returnTypeGroup = useReturnTypeGroup({
        type: returnType,
        extraOptions: returnExtraOptions,
        inputLabel: label,
    })

    const functionsGroup = useFormulaFunctionsGroup({
        returnType,
        returnTypeOptions: returnExtraOptions?.options,
    })

    const contextSchemaWithPaths = useMemo(() => {
        // first filter down the schema to those that match our return type
        // and filter our root groups down to those that have children

        const result = buildContextSchemaWithPaths({
            contextSchema: applyTypeCheckToItems(contextSchema),
            returnTypeGroup,
            additionalGroups: allowFormulaFunctions ? [functionsGroup] : undefined,
        })

        return result
    }, [
        applyTypeCheckToItems,
        contextSchema,
        returnTypeGroup,
        allowFormulaFunctions,
        functionsGroup,
    ])

    const allowMentions = returnExtraOptions?.allow_mentions

    const expressionTokenExtension = useExpressionTokenExtension({
        className: ContextTokenStyle,
        clearContent: onlySingleValue,
        allowMentions,
    })

    const formulaFunctionExtension = useFormulaFunctionExtension({
        className: ContextTokenStyle,
        contextSchema,
        renderGroupTitle,
        returnType,
        returnTypeOptions: returnExtraOptions?.options,
    })

    const extensions: Extensions = [expressionTokenExtension, formulaFunctionExtension]

    if (!allowRichText) {
        extensions.push(...baseExtensions)
    }

    const recordLinkExtension = useRecordLinkExtension()
    if (returnExtraOptions?.allow_record_links) {
        extensions.push(recordLinkExtension)
    }

    if (allowMentions) {
        extensions.push(
            createMentionsExtension({
                fetchUsersFn: () => [],
            })
        )
    }

    const suppliedValue = useDeepEqualsMemoValue(value)
    const content = suppliedValue?.input_content

    const handleChange = (content: JSONContent) => {
        const editorState = editorRef?.editor?.state
        if (!editorState) return

        if (!content.content?.length) {
            onChange(undefined)
            return
        }

        const expr = extractExpressionsFromContent(
            content,
            returnType,
            contextSchemaWithPaths,
            editorState
        )

        onChange({
            input_content: content,
            value: expr ? makeFinalValue(expr, returnType, allowFormulaFunctions) : undefined,
        })
    }

    const isEmpty = !value?.input_content?.content?.length
    return (
        <TipTapEditorBase
            ref={setEditorRef}
            id={instanceId}
            extensions={extensions}
            disableBaseExtensions={!allowRichText}
            disableToolbar={!allowRichText}
            content={content}
            onChange={handleChange}
            className={classNames(
                RootStyles.styleFunction({ variant: 'default', size: 'm' }),
                SimpleRichTextStyle,
                ExpressionInputStyle
            )}
            fontSize="bodyS"
            allowedBlockTypes={allowedBlockTypes}
            tiptapProps={{
                editorProps: { attributes: { id: instanceId, 'data-expression-editor': true } },
            }}
            autoFocus={autoFocus}
            {...props}
        >
            {isEmpty && (
                <Box
                    position="absolute"
                    height="fit-content"
                    style={{
                        top: 0,
                        bottom: 0,
                        margin: 'auto',
                    }}
                    color="textWeakest"
                    pointerEvents="none"
                >
                    {placeholder}
                </Box>
            )}
            {rightSlotContent}
            {editorRef?.editor && (
                <ContextMenuManager
                    editorInstanceId={instanceId}
                    editor={editorRef.editor}
                    container={editorRef.container}
                    renderGroupTitle={renderGroupTitle}
                    contextSchema={contextSchemaWithPaths}
                    openOnFocus={onlySingleValue}
                />
            )}
        </TipTapEditorBase>
    )
}
