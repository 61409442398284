import React, { useCallback, useMemo } from 'react'

import {
    createRecord,
    deleteRecords as originalDeleteRecords,
    updateRecord as originalUpdateRecord,
} from 'data/hooks/records/recordOperations'
import { getErrorMessage } from 'features/datagrid/getDataGridErrorMessage'
import { RecordEditManagerContextProvider } from 'features/records/RecordEditManagerContextProvider'
import { useRecordEditManagerContext } from 'features/records/useRecordEditManagerContext'
import { useListViewRecords } from 'features/views/ListView/hooks/useListViewRecords'
import { useListViewContext } from 'features/views/ListView/useListViewContext'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useBoardViewFilters } from './Filters/hooks/useBoardViewFilters'
import { useAttributesFromView } from './hooks/useAttributesFromView'
import { useBoardColumns } from './hooks/useBoardColumns'
import { useBoardViewSearch } from './Search/hooks/useBoardViewSearch'
import { useBoardViewSort } from './Sort/hooks/useBoardViewSort'
import { BoardViewContext, BoardViewInternalContextValue } from './useBoardViewContext'

export type BoardViewContextProviderProps = {}

export const BoardViewContextProvider: React.FC<BoardViewContextProviderProps> = ({ children }) => {
    const {
        object,
        stack,
        view,
        embeddedByField,
        embeddedByFieldValue,
        embeddedByRecord,
        isEmbedded,
        allFields,
        header,
    } = useListViewContext()

    const disableRealtimeUpdates = view.options.disableRealtimeUpdates

    const statusField: FieldDto | undefined = useMemo(() => {
        const statusFieldSid = view.options.statusField
        if (!statusFieldSid) return undefined

        return allFields.find((field) => field._sid === statusFieldSid)
    }, [allFields, view.options.statusField])
    const statusFieldMemo = useDeepEqualsMemoValue(statusField)

    const columns = useBoardColumns(statusFieldMemo)

    const {
        defaultFilters,
        hasFilters: hasInlineFilters,
        inlineFilterType,
        availableInlineFilterFields,
    } = useBoardViewFilters({
        columns,
        statusField: statusFieldMemo,
    })

    const coverImageFieldApiName = view.options.coverImage?.id
    const coverImageField = allFields.find((field) => field.api_name === coverImageFieldApiName)

    const profileImageFieldApiName = view.options.profileImage?.fieldApiName
    const profileImageField = allFields.find((field) => field.api_name === profileImageFieldApiName)

    const eyebrowFieldSid = view.options.boardCardEyebrow?.fieldSid
    const eyebrowField = allFields.find((field) => field._sid === eyebrowFieldSid)
    const eyebrowFieldApiName = eyebrowField?.api_name

    const titleFieldSid = view.options.boardCardTitle?.fieldSid
    const titleField = allFields.find((field) => field._sid === titleFieldSid)
    const titleFieldApiName = titleField?.api_name

    const subtitleFieldSid = view.options.boardCardSubtitle?.fieldSid
    const subtitleField = allFields.find((field) => field._sid === subtitleFieldSid)
    const subtitleFieldApiName = subtitleField?.api_name

    const attributes = useAttributesFromView({ titleFieldSid })

    const additionalSearchFieldApiNames = useMemo(() => {
        const apiNames = new Set<string>()

        // Include card footer fields.
        const footerLeftFieldSid = view.options.boardCardFooter?.leftFieldSid
        if (footerLeftFieldSid && footerLeftFieldSid !== '_record_stats') {
            const field = allFields.find((field) => field._sid === footerLeftFieldSid)
            if (field) {
                apiNames.add(field.api_name)
            }
        }

        const footerRightFieldSid = view.options.boardCardFooter?.rightFieldSid
        if (footerRightFieldSid && footerRightFieldSid !== '_record_stats') {
            const field = allFields.find((field) => field._sid === footerRightFieldSid)
            if (field) {
                apiNames.add(field.api_name)
            }
        }

        // Include eyebrow field.
        if (eyebrowFieldApiName) {
            apiNames.add(eyebrowFieldApiName)
        }

        // Include title field.
        if (titleFieldApiName) {
            apiNames.add(titleFieldApiName)
        }

        // Include subtitle field.
        if (subtitleFieldApiName) {
            apiNames.add(subtitleFieldApiName)
        }

        return Array.from(apiNames)
    }, [
        allFields,
        eyebrowFieldApiName,
        titleFieldApiName,
        subtitleFieldApiName,
        view.options.boardCardFooter?.leftFieldSid,
        view.options.boardCardFooter?.rightFieldSid,
    ])

    const additionalFieldApiNames = useMemo(() => {
        const apiNames = new Set<string>(additionalSearchFieldApiNames)

        if (statusFieldMemo) {
            apiNames.add(statusFieldMemo.api_name)
        }

        // Include cover image field.
        if (coverImageFieldApiName) {
            apiNames.add(coverImageFieldApiName)
        }

        // Include profile image field.
        if (profileImageFieldApiName) {
            apiNames.add(profileImageFieldApiName)
        }

        return Array.from(apiNames)
    }, [
        additionalSearchFieldApiNames,
        coverImageFieldApiName,
        profileImageFieldApiName,
        statusFieldMemo,
    ])

    const {
        records,
        dereferencedRecords,
        recordCount = 0,
        retryFetchRecords,
        hasError,
        isLoading,
        isLoadingSlow: isFetchingSlow,
        effectiveFilters,
        includedFieldsApiNames,
    } = useListViewRecords({
        additionalFieldApiNames,
        additionalSearchFieldApiNames,
        additionalFilters: defaultFilters,
        allowManualSort: true,
        disableRealtimeUpdates,
    })

    const { sortBy, manualSortKey } = useBoardViewSort()
    const { query } = useBoardViewSearch()

    const hasFilters = !!query || hasInlineFilters
    const allowReorder = view.options?.orderType === 'manual'
    const requestFilters = useDeepEqualsMemoValue(effectiveFilters)
    const requestIncludedFields = useDeepEqualsMemoValue(includedFieldsApiNames)

    const addRecord = useCallback(
        async (record: RecordDto) => {
            const newRecord = await createRecord(
                {
                    ...record,
                    object_id: object._sid,
                } as RecordDtoForCreation,
                requestIncludedFields,
                false
            )
            await retryFetchRecords()

            return newRecord
        },
        [object._sid, retryFetchRecords, requestIncludedFields]
    )

    const updateRecord = useCallback(
        async (recordId: string, patch: Partial<RecordDto>) => {
            // used for drag and drop
            await originalUpdateRecord(recordId, patch, requestIncludedFields, {
                deferStoreUpdate: true,
            })
        },
        [requestIncludedFields]
    )

    const deleteRecords = useCallback(
        async (recordIds: string[]) => {
            await originalDeleteRecords(object._sid, recordIds)
            await retryFetchRecords()
        },
        [object._sid, retryFetchRecords]
    )

    const attributeStyle = view.options.boardFieldStyle ?? 'list'
    const labelStyle = view.options.boardLabelStyle

    const titleSize = view.options.boardTitleSize ?? 'medium'

    const onRecordClick = view.options.onRecordClick || 'preview'

    const value = {
        columns,
        records,
        dereferencedRecords,
        recordCount,
        object,
        stack,
        isLoading,
        isFetchingSlow,
        retryFetchRecords,
        hasError,
        statusField: statusFieldMemo,
        allowReorder,
        sortBy,
        manualSortKey,
        attributes,
        attributeStyle,
        labelStyle,
        embeddedByField,
        embeddedByFieldValue,
        embeddedByRecord,
        view,
        hasFilters,
        inlineFilterType,
        availableInlineFilterFields,
        isEmbedded,
        allFields,
        requestFilters,
        requestIncludedFields,
        coverImageField,
        profileImageField,
        titleSize,
        titleField,
        eyebrowField,
        subtitleField,
        header,
        onRecordClick,
    }

    return (
        <RecordEditManagerContextProvider
            records={records ?? []}
            addRecord={addRecord}
            updateRecord={updateRecord}
            deleteRecords={deleteRecords}
        >
            <BoardViewInternalContext value={value}>{children}</BoardViewInternalContext>
        </RecordEditManagerContextProvider>
    )
}

type BoardViewInternalContextProps = {
    value: BoardViewInternalContextValue
    children: React.ReactNode
}

const BoardViewInternalContext: React.FC<BoardViewInternalContextProps> = ({ value, children }) => {
    const { records, editRecord, failedRecords, addRecord, pendingNewRecords } =
        useRecordEditManagerContext()

    const errorMessage =
        Object.values(failedRecords).length > 0 ? getErrorMessage(failedRecords) : undefined

    const pendingRecords = pendingNewRecords.map((record) => record.record)

    const finalValue = {
        ...value,
        records,
        updateRecord: editRecord,
        errorMessage,
        createRecord: addRecord,
        pendingRecords,
    }

    return <BoardViewContext.Provider value={finalValue}>{children}</BoardViewContext.Provider>
}
