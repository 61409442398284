import React, { useMemo } from 'react'

import { CheckboxAttributeDisplay } from 'features/views/attributes/CheckboxAttributeDisplay'

import { BaseAttribute } from './BaseAttribute'
import { ThreadViewAttributeComponent } from './types'

type CheckboxAttributeProps = {}

export const CheckboxAttribute: ThreadViewAttributeComponent<boolean, CheckboxAttributeProps> = ({
    value,
    attribute,
    isLoading,
    ...props
}) => {
    const {
        field,
        type,
        rawOptions: { checkboxDisplay },
    } = attribute

    const valueDisplay = useMemo(() => {
        switch (checkboxDisplay) {
            case 'default':
                return 'default'
            case 'big':
                return 'bigFilled'
            case 'filled':
                return 'filled'
        }
    }, [checkboxDisplay])

    const isEmpty = !isLoading && typeof value === 'undefined'

    const isContent = type === 'content'

    const size: React.ComponentProps<typeof CheckboxAttributeDisplay>['size'] = useMemo(() => {
        if (isContent) {
            return {
                mobile: checkboxDisplay === 'big' ? 'm' : 's',
                tablet: checkboxDisplay === 'big' ? 'l' : 'm',
            }
        }

        return undefined
    }, [checkboxDisplay, isContent])

    return (
        <BaseAttribute attribute={attribute} isEmpty={isEmpty} isLoading={isLoading} {...props}>
            <CheckboxAttributeDisplay
                value={value}
                valueDisplay={valueDisplay}
                field={field}
                isLoading={isLoading}
                size={size}
            />
        </BaseAttribute>
    )
}
