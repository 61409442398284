import { useMemo } from 'react'

import { AttachmentValue } from 'features/views/attributes/types'
import { useThreadViewContext } from 'features/views/ListView/ThreadView/hooks/useThreadViewContext'
import { ThreadViewAttribute } from 'features/views/ListView/ThreadView/types'

import { ResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

type UseAttachmentsAttributeStateOptions = {
    attribute: ThreadViewAttribute
    value?: AttachmentValue[]
    isLoading?: boolean
}

const titleSizeMapping = {
    small: 's' as const,
    medium: 'm' as const,
    large: 'l' as const,
}

export function useAttachmentsAttributeState(options: UseAttachmentsAttributeStateOptions) {
    const { value, isLoading, attribute } = options
    const { rawOptions, type } = attribute

    const isMessage = type === 'message'
    const isContent = type === 'content'

    const isEmpty = !isLoading && (!value || value.length < 1)

    const displayAsImage = rawOptions.displayAsImage as boolean
    const displayAsTag = !isMessage && !isContent && !displayAsImage

    const { titleSize } = useThreadViewContext()
    const size: ResponsiveValue<'s' | 'm' | 'l'> = useMemo(() => {
        switch (type) {
            case 'title':
                return titleSizeMapping[titleSize]
            case 'message':
                return 'm'
            case 'content':
                return {
                    mobile: 's',
                    tablet: 'm',
                }
            default:
                return 's'
        }
    }, [type, titleSize])

    return useMemo(
        () => ({
            isEmpty,
            displayAsTag,
            displayAsImage,
            size,
        }),
        [displayAsImage, displayAsTag, isEmpty, size]
    )
}
