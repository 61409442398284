import { useMemo, useState } from 'react'

export function useThreadViewRecordActionsState() {
    const [isOpen, setIsOpen] = useState(false)

    return useMemo(
        () => ({
            isOpen,
            setIsOpen,
        }),
        [isOpen]
    )
}
