import React from 'react'

import { DetailViewHeaderEyebrowAttribute } from 'features/views/DetailView/attributes/DetailViewHeaderEyebrowAttribute'
import { DetailViewProfileImageAttribute } from 'features/views/DetailView/attributes/DetailViewProfileImageAttribute'
import { DetailViewSubtitleAttribute } from 'features/views/DetailView/attributes/DetailViewSubtitleAttribute'
import { DetailViewTitleAttribute } from 'features/views/DetailView/attributes/DetailViewTitleAttribute'
import { PrimaryHighlightsAttribute } from 'features/views/DetailView/attributes/primaryHighlights/PrimaryHighlightsAttribute'
import { DetailViewTopBar } from 'features/views/DetailView/headers/DetailViewTopBar'
import { DetailViewLayoutSectionStyle } from 'features/views/DetailView/layouts/DetailViewLayout.css'

import { Box } from 'ui/components/Box'
import { Skeleton } from 'ui/components/Skeleton'
import { Headline } from 'ui/components/Text'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useDetailViewSpaciousHeaderContentState } from './hooks/useDetailViewSpaciousHeaderContentState'
import { useDetailViewSpaciousHeaderCoverImageState } from './hooks/useDetailViewSpaciousHeaderCoverImageState'
import { useDetailViewSpaciousHeaderPrimaryHighlightsState } from './hooks/useDetailViewSpaciousHeaderPrimaryHighlightsState'
import { useDetailViewSpaciousHeaderProfileImageState } from './hooks/useDetailViewSpaciousHeaderProfileImageState'
import { useDetailViewSpaciousHeaderState } from './hooks/useDetailViewSpaciousHeaderState'
import { useDetailViewSpaciousHeaderTopBarState } from './hooks/useDetailViewSpaciousHeaderTopBarState'
import { DetailViewSpaciousHeaderActions } from './DetailViewSpaciousHeaderActions'

import {
    DetailViewSpaciousHeaderCoverImageStyles,
    DetailViewSpaciousHeaderProfileImageStyles,
    DetailViewSpaciousHeaderProfileImageWrapperStyles,
    DetailViewSpaciousHeaderStyle,
    DetailViewSpaciousTopBarStyles,
} from './DetailViewSpaciousHeader.css'

type DetailViewSpaciousHeaderProps = {}

export const DetailViewSpaciousHeader: React.FC<DetailViewSpaciousHeaderProps> = () => {
    const { isLoading, topOffset, rightOffset } = useDetailViewSpaciousHeaderState()

    return (
        <>
            <DetailViewSpaciousHeaderTopBar isLoading={isLoading} />
            <Box
                as="header"
                className={DetailViewSpaciousHeaderStyle}
                style={{
                    top: topOffset,
                    marginRight: rightOffset,
                }}
            >
                <DetailViewSpaciousHeaderCoverImage isLoading={isLoading} />
                <Box flex column px={{ mobile: 'xl', tablet: '3xl' }} shrink grow>
                    <Box className={DetailViewLayoutSectionStyle}>
                        <DetailViewSpaciousHeaderProfileImage isLoading={isLoading} />
                        <DetailViewSpaciousHeaderContent isLoading={isLoading} />
                    </Box>
                </Box>
            </Box>
        </>
    )
}

type DetailViewSpaciousHeaderContentProps = {
    isLoading?: boolean
}

const DetailViewSpaciousHeaderContent: React.FC<DetailViewSpaciousHeaderContentProps> = ({
    isLoading,
}) => {
    const { titleComponentSize } = useDetailViewSpaciousHeaderContentState()

    return (
        <Box
            flex
            column
            shrink
            grow
            mt={{
                mobile: 'xl',
                tablet: '4xl',
            }}
        >
            <Box flex center shrink grow gap="xl">
                <Headline size={titleComponentSize} shrink display="block">
                    <DetailViewTitleAttribute
                        style={{
                            fontSize: 'inherit',
                            fontWeight: 'inherit',
                            lineHeight: 'inherit',
                        }}
                        isLoading={isLoading}
                    />
                </Headline>
                <Box height="full" flex center noShrink>
                    <DetailViewHeaderEyebrowAttribute
                        size={{
                            mobile: 'xs',
                            tablet: 's',
                        }}
                        isLoading={isLoading}
                        noShrink
                        shrink={false}
                    />
                </Box>
            </Box>
            <Box
                flex
                column
                shrink
                grow
                style={{
                    maxWidth: '500px',
                }}
            >
                <DetailViewSpaciousHeaderPrimaryHighlights isLoading={isLoading} mt="xl" />
                <DetailViewSubtitleAttribute
                    isLoading={isLoading}
                    size={{ mobile: 'm', tablet: 'l' }}
                    color="textWeaker"
                    weight="medium"
                    mt={{ mobile: 'm', tablet: 'xl' }}
                />
                <DetailViewSpaciousHeaderActions
                    mt={{ mobile: 'xl', tablet: '3xl' }}
                    isLoading={isLoading}
                />
            </Box>
        </Box>
    )
}

type DetailViewSpaciousHeaderCoverImageProps = {
    isLoading?: boolean
}

const DetailViewSpaciousHeaderCoverImage: React.FC<
    DetailViewSpaciousHeaderCoverImageProps
> = () => {
    const { coverType, backgroundColor, backgroundImageUrl, showOverlay, hasBackgroundImage } =
        useDetailViewSpaciousHeaderCoverImageState()

    if (coverType === 'none') return null

    return (
        <Box className={DetailViewSpaciousHeaderCoverImageStyles.styleFunction({ coverType })}>
            <Box
                width="full"
                height="full"
                position="relative"
                overflow="hidden"
                style={{
                    backgroundColor,
                    backgroundImage: hasBackgroundImage ? `url(${backgroundImageUrl!})` : undefined,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    borderRadius: 'inherit',
                }}
            >
                {showOverlay && (
                    <Box
                        width="full"
                        height="full"
                        position="absolute"
                        top={0}
                        left={0}
                        style={{
                            background: 'rgba(0, 0, 0, 0.5)',
                            zIndex: 1,
                        }}
                    />
                )}
            </Box>
        </Box>
    )
}

type DetailViewSpaciousHeaderTopBarProps = {
    isLoading?: boolean
}

const DetailViewSpaciousHeaderTopBar: React.FC<DetailViewSpaciousHeaderTopBarProps> = () => {
    const { headerRef, topOffset, coverType, shade } = useDetailViewSpaciousHeaderTopBarState()

    return (
        <DetailViewTopBar
            ref={headerRef}
            width="full"
            px={{ mobile: 'xl', tablet: '3xl' }}
            py={{ mobile: 'm', tablet: 'l' }}
            shade={shade}
            className={DetailViewSpaciousTopBarStyles.styleFunction({ coverType })}
            style={{
                top: topOffset,
            }}
        />
    )
}

type DetailViewSpaciousHeaderProfileImageProps = {
    isLoading?: boolean
}

const DetailViewSpaciousHeaderProfileImage: React.FC<DetailViewSpaciousHeaderProfileImageProps> = ({
    isLoading,
}) => {
    const { coverType, hasThumbnail } = useDetailViewSpaciousHeaderProfileImageState()

    const size = useResponsiveValue({
        mobile: 'xl',
        tablet: '3xl',
    })

    if (!hasThumbnail) return null

    return (
        <Box
            className={DetailViewSpaciousHeaderProfileImageWrapperStyles.styleFunction({
                coverType,
            })}
        >
            <Box
                className={DetailViewSpaciousHeaderProfileImageStyles.styleFunction({
                    coverType,
                })}
            >
                <Skeleton isLoading={isLoading}>
                    <DetailViewProfileImageAttribute noShrink isLoading={isLoading} size={size} />
                </Skeleton>
            </Box>
        </Box>
    )
}

type DetailViewSpaciousHeaderPrimaryHighlightsProps = React.ComponentPropsWithoutRef<typeof Box> & {
    isLoading?: boolean
}

const DetailViewSpaciousHeaderPrimaryHighlights: React.FC<
    DetailViewSpaciousHeaderPrimaryHighlightsProps
> = ({ isLoading, ...props }) => {
    const { fields } = useDetailViewSpaciousHeaderPrimaryHighlightsState()

    if (!fields?.length) return null

    return (
        <Box
            display="grid"
            style={{
                gridTemplateColumns: `repeat(${fields.length}, minmax(10px, max-content))`,
            }}
            gap={{ mobile: 'xl', tablet: '3xl' }}
            shrink
            grow
            {...props}
        >
            {fields?.map((field) => (
                <PrimaryHighlightsAttribute
                    key={field.api_name}
                    field={field}
                    isLoading={isLoading}
                />
            ))}
        </Box>
    )
}
