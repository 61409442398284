import { WidgetSchema } from 'features/views/LayoutEditor/types'

import { PipelineWidget, PipelineWidgetAdminControls } from './PipelineWidget'
import { PipelineWidgetType } from './types'

export const PipelineWidgetMeta: WidgetSchema<PipelineWidgetType> = {
    category: 'data',
    label: 'Pipeline',
    icon: 'Merge',
    component: PipelineWidget,
    adminControlsComponent: PipelineWidgetAdminControls,
    defaultAttrs: {
        style: 'horizontal',
        stages: [],
        isReadOnly: false,
    },
    requireFields: (attrs) => {
        const fieldApiName = attrs.field?.fieldApiName
        if (!fieldApiName) return []

        return [fieldApiName]
    },
}
