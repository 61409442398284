import { useMemo } from 'react'

import { LinkBlocksWidgetType } from 'features/views/LayoutEditor/widgets/LinkBlocksWidget/types'
import { isInlineLink } from 'features/views/LayoutEditor/widgets/LinkBlocksWidget/utils'

import { useContainerResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useLinkBlocksWidgetLinks } from './useLinkBlocksLinks'

export function useLinkBlocksWidgetLinkListState(widget: LinkBlocksWidgetType) {
    const { size, style } = widget.attrs

    // Force regular size on smaller containers (sidebar or mobile).
    const { value: effectiveSize, ref: wrapperRef } = useContainerResponsiveValue({
        mobile: 'regular' as const,
        tablet: size,
    })

    const { visibleLinks } = useLinkBlocksWidgetLinks(widget)

    const hasOnlyInlineLinks = visibleLinks.every(isInlineLink)

    return useMemo(
        () => ({
            wrapperRef: wrapperRef as React.MutableRefObject<HTMLDivElement>,
            visibleLinks,
            hasOnlyInlineLinks,
            size: effectiveSize,
            style,
        }),
        [wrapperRef, visibleLinks, hasOnlyInlineLinks, effectiveSize, style]
    )
}
