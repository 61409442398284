import { INTEGRATION_IDS_WITHOUT_DATABASES } from './constants'

/**
 * Return whether the integration requires a database to be selected as well. (Otherwise the
 * integration doesn't have an equivalent concept to database and all relevant logic/UI should be skipped)
 * @param integrationId
 */
export const getIntegrationRequiresDb = (integrationId: ExternalIntegrationId) => {
    return !INTEGRATION_IDS_WITHOUT_DATABASES.includes(integrationId)
}
