import React, { useEffect, useState } from 'react'

import { Editor } from '@tiptap/core'

import { useSubscribeToEditorUpdates } from 'features/tiptap/useSubscribeToEditorUpdates'
import { useOverflowX } from 'features/views/ListView/hooks/useOverflowX'

import { Box } from 'ui/components/Box'
import { ScrollArea } from 'ui/components/ScrollArea'
import {
    ToolbarIsOverflowingRightStyle,
    ToolbarWrapperStyle,
} from 'ui/components/Textarea/Textarea.css'
import * as Parts from 'ui/components/Textarea/Textarea.parts'

import { Panel } from './textareaToolbarContext'
import { TextareaToolbarContextProvider } from './TextareaToolbarContextProvider'
import { TextareaToolbarExtra } from './TextareaToolbarExtra'
import { TextareaToolbarHeadingFormat } from './TextareaToolbarHeadingFormat'
import { TextareaToolbarImagePanel } from './TextareaToolbarImagePanel'
import { TextareaToolbarLinkPanel } from './TextareaToolbarLinkPanel'
import { TextareaToolbarListFormat } from './TextareaToolbarListFormat'
import { TextareaToolbarSeparator } from './TextareaToolbarSeparator'
import { TextareaToolbarTextFormat } from './TextareaToolbarTextFormat'

type TextareaToolbarProps = Omit<
    React.ComponentPropsWithoutRef<typeof Parts.ToolbarRoot>,
    'isDisabled' | 'isReadOnly'
> & {
    editor: Editor
    disabled?: boolean
    readOnly?: boolean
    enableFileUpload?: boolean
}

export const TextareaToolbar: React.FC<TextareaToolbarProps> = ({
    editor,
    disabled,
    readOnly,
    enableFileUpload,
    ...props
}) => {
    const isToolbarGroupDisabled = disabled || readOnly

    const [currentPanel, setCurrentPanel] = useState<Panel | null>(null)
    useEffect(() => {
        // Reset panel state when disabled or readOnly.
        if (disabled || readOnly) {
            setCurrentPanel(null)
        }
    }, [disabled, readOnly])

    useEffect(() => {
        // Reset panel state when the selection changes.
        const handler = () => {
            setCurrentPanel(null)
        }

        editor.on('selectionUpdate', handler)
        return () => {
            editor.off('selectionUpdate', handler)
        }
    }, [editor])

    // Make sure updates to the editor are reflected in the toolbar.
    useSubscribeToEditorUpdates(editor)

    const { targetRef, scrollAreaRef } = useOverflowX({
        rightClassName: ToolbarIsOverflowingRightStyle,
    })

    return (
        <Box className={ToolbarWrapperStyle} ref={targetRef} flexShrink={0}>
            <ScrollArea direction="horizontal" ref={scrollAreaRef}>
                <Parts.ToolbarRoot
                    {...props}
                    isDisabled={disabled}
                    isReadOnly={readOnly}
                    aria-label="Formatting options"
                >
                    <TextareaToolbarContextProvider
                        currentPanel={currentPanel}
                        setCurrentPanel={setCurrentPanel}
                    >
                        {!currentPanel && (
                            <>
                                <TextareaToolbarHeadingFormat
                                    editor={editor}
                                    disabled={isToolbarGroupDisabled}
                                />
                                <TextareaToolbarSeparator />
                                <TextareaToolbarTextFormat
                                    editor={editor}
                                    disabled={isToolbarGroupDisabled}
                                />
                                <TextareaToolbarSeparator />
                                <TextareaToolbarListFormat
                                    editor={editor}
                                    disabled={isToolbarGroupDisabled}
                                />
                                <TextareaToolbarSeparator />
                                <TextareaToolbarExtra
                                    editor={editor}
                                    disabled={isToolbarGroupDisabled}
                                />
                            </>
                        )}
                        {currentPanel === 'link' && <TextareaToolbarLinkPanel editor={editor} />}
                        {currentPanel === 'image' && (
                            <TextareaToolbarImagePanel
                                editor={editor}
                                enableFileUpload={enableFileUpload}
                            />
                        )}
                    </TextareaToolbarContextProvider>
                </Parts.ToolbarRoot>
            </ScrollArea>
        </Box>
    )
}
