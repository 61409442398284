import React from 'react'

import { ErrorBoundary } from 'app/ErrorBoundaries'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'
import { Body, Headline } from 'ui/components/Text'

type LayoutEditorWidgetErrorBoundaryProps = {
    children: React.ReactNode
}

export const LayoutEditorWidgetErrorBoundary: React.FC<LayoutEditorWidgetErrorBoundaryProps> = ({
    children,
}) => {
    return (
        <ErrorBoundary
            errorContent={
                <Box
                    flex
                    column
                    gap="xs"
                    px="l"
                    borderStyle="dashed"
                    borderColor="borderDisabled"
                    borderWidth={1}
                    background="surfaceDisabled"
                    py="xl"
                    width="full"
                >
                    <Icon size="3xl" name="AlertCircle" color="error700" mb="m" />
                    <Headline size="s" color="error700">
                        There was a problem displaying this widget
                    </Headline>
                    <Body>Please try again later. If the issue persists, contact support.</Body>
                </Box>
            }
        >
            {children}
        </ErrorBoundary>
    )
}
