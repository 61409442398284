import {
    convertV2FieldColorToTheme,
    getDefaultDropdownFieldOptionColorName,
} from 'utils/fieldUtils'

import { PipelineWidgetStage, PipelineWidgetType } from './types'

export function getSupportedFields(fields: FieldDto[]) {
    return fields.filter((field) => field.type === 'dropdown')
}

export function getAvailableStages(
    attrs: PipelineWidgetType['attrs'],
    configuredStages: Map<string, PipelineWidgetStage>,
    selectedField?: FieldDto
) {
    const allowColors = !!attrs.useFieldColors && !!selectedField?.options?.allow_dropdown_colors

    return (
        selectedField?.options?.options?.map((option) => {
            const stage = configuredStages.get(option.value!)

            let color = getDefaultDropdownFieldOptionColorName()
            if (allowColors && option?.color) {
                // This will try to map the old color format to the new one.
                // If the color is not found, it means that this is a new color,
                // so use it as is.
                color = convertV2FieldColorToTheme(option.color)
            } else if (!allowColors) {
                color = 'Theme'
            }

            return {
                stage: {
                    id: option.value!,
                    description: stage?.description ?? '',
                    idx: stage?.idx ?? -1,
                    isActive: stage?.isActive ?? false,
                },
                label: option.label || '',
                color,
            }
        }) ?? []
    )
}
