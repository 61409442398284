import React, { useMemo, useState } from 'react'

import styled from '@emotion/styled'

import { useObject } from 'data/hooks/objects'
import FieldEditorPopoverButton from 'features/admin/fields/FieldEditorPopoverButton'
import { getCanModifySchema } from 'features/admin/fields/logic/schemaUtils'
import { NewFieldConfigurationEditor } from 'features/admin/settings/object/NewFieldConfigurationEditor'
import { Heading, Section } from 'legacy/v1/ui'

import { Checkbox, Flex, Text } from 'v2/ui'
import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'
import stackerTheme from 'v2/ui/theme/styles/default'

const colors = stackerTheme().colors

type AppModalDataFieldSettingsProps = {
    objectId: string
    noPadding: boolean
}

export const AppSettingsModalDataFields: React.FC<AppModalDataFieldSettingsProps> = ({
    objectId,
    noPadding,
}: AppModalDataFieldSettingsProps) => {
    const { object, changeField, deleteField, bulkChangeFields } = useObject(objectId)
    const [showDeveloperDetails, setShowDeveloperDetails] = useState(false)

    return useMemo(
        () => (
            <Section
                style={{ width: '100%', marginBottom: 0, marginTop: 0 }}
                noMargin={noPadding}
                noPadding={noPadding}
            >
                <Section
                    style={{
                        width: '100%',
                        marginBottom: 0,
                        marginTop: 0,
                        overflow: 'hidden',
                    }}
                    noMargin={noPadding}
                    noPadding={noPadding}
                >
                    <Flex mb={4} justifyContent="space-between">
                        <Heading helpAlign="right" margin="none" padding="none" size="small">
                            Fields
                        </Heading>

                        <Flex>
                            <Checkbox
                                id="field-developer-name-checkbox"
                                small
                                onChange={() => setShowDeveloperDetails(!showDeveloperDetails)}
                                value={showDeveloperDetails ? 'true' : undefined}
                            />
                            <CheckboxText
                                as="label"
                                htmlFor="field-developer-name-checkbox"
                                size="fontS"
                            >
                                Show developer name
                            </CheckboxText>
                            {getCanModifySchema(object) && (
                                <FieldEditorPopoverButton
                                    objectId={object?._sid}
                                    className={ONBOARDING_CLASSES.APP_SETTINGS_TABLE_NEW_FIELD}
                                >
                                    Add field
                                </FieldEditorPopoverButton>
                            )}
                        </Flex>
                    </Flex>
                    <NewFieldConfigurationEditor
                        object={object}
                        changeField={changeField}
                        deleteField={deleteField}
                        bulkChangeFields={bulkChangeFields}
                        showDeveloperDetails={showDeveloperDetails}
                    />
                </Section>
            </Section>
        ),
        [bulkChangeFields, changeField, deleteField, noPadding, object, showDeveloperDetails]
    )
}

const CheckboxText = styled(Text)`
    margin-right: 20px;
    margin-left: 10px;
    color: ${colors.neutral[900]};
    font-weight: 900;
    font-size: 15px;
`
