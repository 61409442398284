import React from 'react'

import { useDataConnections } from 'data/hooks/dataConnections/useDataConnections'
import { useNewDataConnectionModal } from 'features/DataConnections/useNewDataConnectionModal'
import { BaseEmptyState } from 'features/views/ListView/EmptyState/BaseEmptyState'

import { LoadingScreen } from 'v2/ui'
import { ServerError } from 'v2/ui/svgs/list-view-empty-states/ServerError'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Headline } from 'ui/components/Text'
import { theme } from 'ui/styling/Theme.css'

import {
    DataConnectionGroupHeading,
    DataConnectionGroupRow,
} from './DataConnectionGroupHeadingAndRow'
import { groupDataConnectionsByTypeAndNangoConnectionId } from './groupDataConnections'

const NewDataConnectionButton = ({
    showNewDataConnectionModal,
    size,
}: {
    showNewDataConnectionModal: (data: any) => void
    size: 's' | 'm' | 'l'
}): React.ReactElement => (
    <Button
        type="button"
        startIcon={{ name: 'Plus' }}
        size={size}
        onClick={() =>
            showNewDataConnectionModal({
                initialIntegrationId: '',
                initialSelectedExternalAccountId: '',
            })
        }
    >
        Add data source
    </Button>
)

export const DataConnectionsManager: React.FC = () => {
    const { data: dataConnections, isLoading } = useDataConnections()
    const dataConnectionsGroupedByTypeAndEmail =
        groupDataConnectionsByTypeAndNangoConnectionId(dataConnections)
    const { show: showNewDataConnectionModal } = useNewDataConnectionModal()

    return (
        <>
            <Box
                flex
                style={{
                    width: '100%',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
                pt="3xl"
                pb="3xl"
                pr="4xl"
                pl="4xl"
            >
                <LoadingScreen isLoading={isLoading}>
                    <Box flex column stretch>
                        <Box flex justifyContent="space-between">
                            <Headline size="s">Data sources</Headline>

                            <NewDataConnectionButton
                                showNewDataConnectionModal={showNewDataConnectionModal}
                                size="s"
                            />
                        </Box>
                        {dataConnections?.length ? (
                            <Box
                                style={{
                                    paddingTop: theme.space['3xl'],
                                    paddingBottom: theme.space['10xl'],
                                    overflowX: 'scroll',
                                    height: '90vh',
                                }}
                            >
                                <DataConnectionGroupHeading />
                                {dataConnectionsGroupedByTypeAndEmail?.map((group) => (
                                    <DataConnectionGroupRow
                                        key={group.key}
                                        dataConnections={group.dataConnections}
                                        nangoConnectionId={group.nangoConnectionId}
                                    />
                                ))}
                            </Box>
                        ) : (
                            <BaseEmptyState
                                image={<ServerError />}
                                title="No data sources"
                                description="You don't have any data sources. Connect your first source by clicking the button below."
                                footer={
                                    <NewDataConnectionButton
                                        showNewDataConnectionModal={showNewDataConnectionModal}
                                        size="m"
                                    />
                                }
                            />
                        )}
                    </Box>
                </LoadingScreen>
            </Box>
        </>
    )
}
