import * as Y from 'yjs'

import {
    DetailViewHeader,
    DetailViewHeaderSpacious,
    DetailViewLayout,
} from 'features/views/DetailView/types'
import { getRequiredFieldsFromActionButtons, getYLayout } from 'features/views/LayoutEditor/utils'

import { getSupportedTitleFields } from './headers/utils'

export function getDetailViewHeader(
    layout?: DetailViewLayout,
    fields?: FieldDto[]
): DetailViewHeader {
    const supportedTitleFields = getSupportedTitleFields(fields ?? [])
    const defaultTitleField = supportedTitleFields[0]

    let defaultHeader: DetailViewHeader = {
        type: 'slim',
        title: !!defaultTitleField
            ? {
                  fieldApiName: defaultTitleField.api_name,
              }
            : undefined,
    }

    if (layout?.header?.type === 'spacious') {
        defaultHeader = {
            ...defaultHeader,
            type: 'spacious',
            primaryHighlights: [],
            secondaryHighlights: [],
            topBarStyle: 'toolbar',
            titleSize: 'medium',
            showPrimaryHighlightsIcon: false,
            coverType: 'none',
            coverPlaceholder: 'none',
            coverBackgroundColor: 'NeutralLight',
        } as DetailViewHeaderSpacious
    }

    const header: DetailViewHeader = {
        ...defaultHeader,
        ...layout?.header,
    }

    return header
}

export function getYHeader(view?: Y.Map<any>): Y.Map<any> | undefined {
    if (!view) return

    const layout = getYLayout(view)

    let header = layout.get('header')
    if (!header) {
        header = layout.set('header', new Y.Map())
    }

    return header
}

export function getYActions(view?: Y.Map<any>): Y.Map<any> | undefined {
    if (!view) return

    const layout = getYLayout(view)

    let actions = layout.get('actions')
    if (!actions) {
        actions = layout.set('actions', new Y.Map())
    }

    return actions
}

export function getDetailViewRequiredFields(view?: ViewDto, fields?: FieldDto[]): string[] {
    if (!view) return []

    const fieldsSet = new Set<string>()

    const header = getDetailViewHeader(view.layout, fields)

    const titleField = header?.title?.fieldApiName
    if (!!titleField) {
        fieldsSet.add(titleField)
    }

    const subtitleField = (header as DetailViewHeaderSpacious)?.subtitle?.fieldApiName
    if (!!subtitleField) {
        fieldsSet.add(subtitleField)
    }

    const profileField = header?.profileImage?.fieldApiName
    if (!!profileField) {
        fieldsSet.add(profileField)
    }

    const eyebrowField = header?.eyebrow?.fieldApiName
    if (!!eyebrowField) {
        fieldsSet.add(eyebrowField)
    }

    const primaryHighlights = (header as DetailViewHeaderSpacious)?.primaryHighlights
    if (!!primaryHighlights) {
        for (const highlight of primaryHighlights) {
            fieldsSet.add(highlight.fieldApiName)
        }
    }

    const secondaryHighlights = (header as DetailViewHeaderSpacious)?.secondaryHighlights
    if (!!secondaryHighlights) {
        for (const highlight of secondaryHighlights) {
            fieldsSet.add(highlight.fieldApiName)
        }
    }

    const coverImage = (header as DetailViewHeaderSpacious)?.coverImage
    if (!!coverImage && coverImage.type === 'field') {
        fieldsSet.add(coverImage.fieldApiName)
    }

    const coverLogoOverlay = (header as DetailViewHeaderSpacious)?.coverLogoOverlay
    if (!!coverLogoOverlay && coverLogoOverlay.type === 'field') {
        fieldsSet.add(coverLogoOverlay.fieldApiName)
    }

    // Handle action buttons.
    const actionButtons = view.layout?.actions?.pageButtons
    if (!!actionButtons) {
        const allFieldsBySid = fields?.reduce((acc, field) => {
            acc.set(field._sid, field)
            return acc
        }, new Map<string, FieldDto>())

        const fieldsForButtons = getRequiredFieldsFromActionButtons(actionButtons, allFieldsBySid)
        for (const field of fieldsForButtons) {
            fieldsSet.add(field)
        }
    }

    return Array.from(fieldsSet)
}
