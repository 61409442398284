import { useProcessFilter } from 'features/records/components/RecordFilters'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'
import { LinkBlocksWidgetType } from 'features/views/LayoutEditor/widgets/LinkBlocksWidget/types'
import { useRecordManagerContext } from 'features/views/RecordManager/useRecordManagerContext'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

export function useLinkBlocksWidgetLinks(widget: LinkBlocksWidgetType) {
    const activeLinks = widget.attrs.links || []

    const effectiveActiveLinks = activeLinks.filter((link) => link.isActive)

    const { record } = useRecordManagerContext()

    const { isEditing } = useLayoutEditorContext()

    const processFilter = useProcessFilter()
    const visibleLinks = effectiveActiveLinks.filter((link) => {
        // If we are editing, we want to show all links.
        if (!!record && !isEditing && !!link.conditions?.length) {
            const filteredData = processFilter([record], link.conditions)
            return filteredData.length > 0
        }

        return true
    })

    return useDeepEqualsMemoValue({
        allLinks: activeLinks,
        activeLinks: effectiveActiveLinks,
        visibleLinks,
    })
}
