import React, { ReactNode } from 'react'

import { AlertProps, useToast } from '@chakra-ui/react'
import * as Sentry from '@sentry/react'

import { useAppUserContext } from 'app/useAppUserContext'
import { useExecuteAction } from 'data/hooks/actions'

import { Alert } from 'v2/ui'

const useToastAlert = () => {
    const toast = useToast()

    return ({
        status,
        title,
        description,
        duration,
    }: AlertProps & { description?: string | ReactNode; duration: number }) => {
        toast({
            status: status,
            render: ({ onClose }) => (
                <Alert onClose={onClose} status={status} title={title} description={description} />
            ),
            duration,
        })
    }
}

export const useExecuteInvisibleAction = ({
    recordId,
    actionId,
    objectId,
    name,
    hasActionButtonStep = false,
    includeFields,
}: {
    recordId: string
    actionId: string
    objectId: string
    name: string
    hasActionButtonStep: boolean
    includeFields: string[]
}): (() => Promise<void>) => {
    const { isAdmin } = useAppUserContext()
    const executeAction = useExecuteAction(objectId)
    const toast = useToastAlert()

    const run = async () => {
        try {
            const { action_button_pressed_event_sent: eventWasSent } = await executeAction({
                record_id: recordId,
                action_id: actionId,
                include_fields: includeFields,
            })

            // Show error if we were expecting to send a zap and could not
            // Otherwise show success b/c either the zap was sent, or this is an update action
            if (hasActionButtonStep && !eventWasSent) {
                if (!isAdmin) return
                else
                    toast({
                        status: 'warning',
                        duration: 10000,
                        title: `${name}: Inactive Action`,
                        description: (
                            <>
                                The action button <strong>{name}</strong> does not have an external
                                integration listening.
                                <br />
                                You can configure it in{' '}
                                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                <a target="_blank" href="https://make.com/">
                                    Make
                                </a>
                                {' or '}
                                {/* eslint-disable-next-line react/jsx-no-target-blank */}
                                <a target="_blank" href="https://zapier.com/">
                                    Zapier
                                </a>
                                .
                            </>
                        ),
                    })
            } else {
                toast({
                    status: 'success',
                    duration: 3000,
                    title: `${name}: success!`,
                })
            }
        } catch (ex) {
            toast({
                status: 'error',
                title: `${name}: error!`,
                description: (
                    <>
                        An unexpected error has occurred.{' '}
                        <a
                            href="."
                            onClick={(e) => {
                                e.preventDefault()
                                // @ts-ignore
                                window.groove.widget.open()
                            }}
                        >
                            Get in touch
                        </a>{' '}
                        for assistance.
                    </>
                ),
                duration: 10000,
            })
            console.error(ex)

            Sentry.withScope((scope) => {
                scope.setExtra('action', actionId)
                scope.setLevel('error')
                Sentry.captureException(ex)
            })
        }
    }

    return run
}
