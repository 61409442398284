import React, { useCallback, useState } from 'react'

import { useActionButton } from 'features/actions/hooks/useActionButton'

import { DropdownItem } from 'ui/components/Dropdown'
import { Spinner } from 'ui/components/Spinner'

type ActionMenuItemActionButtonRef = HTMLDivElement

type ActionMenuItemActionButtonProps = React.ComponentPropsWithoutRef<typeof DropdownItem> & {
    action: ActionDto
    record: RecordDto
    includeFields: string[]
    onOpenChange?: (open: boolean) => void
}

export const ActionMenuItemActionButton = React.forwardRef<
    ActionMenuItemActionButtonRef,
    ActionMenuItemActionButtonProps
>(({ action, record, includeFields, onOpenChange, ...props }, ref) => {
    const [isExecuting, setIsExecuting] = useState(false)
    const { executeAction } = useActionButton({
        action,
        recordId: record._sid,
        record,
        includeFields,
    })

    const handleClick = useCallback(
        async (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation()
            e.preventDefault()

            if (isExecuting) return

            setIsExecuting(true)
            try {
                await executeAction()
                setIsExecuting(false)
            } finally {
                onOpenChange?.(false)
            }
        },
        [executeAction, isExecuting, onOpenChange]
    )

    return (
        <DropdownItem
            ref={ref}
            onClick={handleClick}
            label={action.name}
            startIcon={{ name: 'Workflow' }}
            rightSlotContent={isExecuting ? () => <Spinner size="m" /> : undefined}
            disabled={isExecuting}
            {...props}
        />
    )
})
