import React, { useCallback } from 'react'

import { DetailViewExperienceSwitcher } from 'features/views/DetailView/DetailViewExperienceSwitcher'
import { LayoutEditorControlSeparator } from 'features/views/LayoutEditor/controls/LayoutEditorControlSeparator'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'
import { getYLayout } from 'features/views/LayoutEditor/utils'

import { Box } from 'ui/components/Box'
import { Field } from 'ui/components/Field'
import { RadioCard, RadioCardGroup } from 'ui/components/Radio'
import { Body } from 'ui/components/Text'
import { Toggle } from 'ui/components/Toggle'
import { theme } from 'ui/styling/Theme.css'

type DetailViewLayoutControlsProps = {}

export const DetailViewLayoutControls: React.FC<DetailViewLayoutControlsProps> = () => {
    const { commands, view } = useLayoutEditorContext()

    const contentWidth = view?.layout?.contentWidth ?? 'full'
    const onContentWidthChange = useCallback(
        (value: string) => {
            commands.transaction((data) => {
                const layout = getYLayout(data)
                layout.set('contentWidth', value)
            })
        },
        [commands]
    )

    const sidebarStyle = view?.layout?.sidebarStyle ?? 'none'
    const onSidebarStyleChange = useCallback(
        (value: string) => {
            commands.transaction((data) => {
                const layout = getYLayout(data)
                layout.set('sidebarStyle', value)
            })
        },
        [commands]
    )

    const isSidebarSticky = view?.layout?.isSidebarSticky ?? false
    const onSidebarStickyChange = useCallback(
        (value: boolean) => {
            commands.transaction((data) => {
                const layout = getYLayout(data)
                layout.set('isSidebarSticky', value)
            })
        },
        [commands]
    )

    return (
        <Box flex column gap="3xl" px="l" height="full">
            <Field label="Content width">
                <RadioCardGroup
                    value={contentWidth}
                    onValueChange={onContentWidthChange}
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, 136px)',
                        gap: theme.space.m,
                    }}
                >
                    <RadioCard
                        value="full"
                        icon={() => <ContentWidthFullIcon isActive={contentWidth === 'full'} />}
                        cardIconWrapperProps={{
                            style: {
                                padding: `${theme.space.m} 0`,
                            },
                        }}
                    >
                        Full-width
                    </RadioCard>
                    <RadioCard
                        value="boxed"
                        icon={() => <ContentWidthBoxedIcon isActive={contentWidth === 'boxed'} />}
                        cardIconWrapperProps={{
                            style: {
                                padding: `${theme.space.m} 0`,
                            },
                        }}
                    >
                        Boxed width
                    </RadioCard>
                </RadioCardGroup>
            </Field>
            <LayoutEditorControlSeparator px={0} />
            <Field label="Sidebar">
                <RadioCardGroup
                    value={sidebarStyle}
                    onValueChange={onSidebarStyleChange}
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fill, 1fr)',
                        gap: theme.space.m,
                    }}
                >
                    <RadioCard
                        value="none"
                        icon={() => <SidebarNoneIcon isActive={sidebarStyle === 'none'} />}
                        cardIconWrapperProps={{
                            style: {
                                padding: `${theme.space.m} 0`,
                            },
                        }}
                    >
                        None
                    </RadioCard>
                    <RadioCard
                        value="left"
                        icon={() => <SidebarLeftIcon isActive={sidebarStyle === 'left'} />}
                        cardIconWrapperProps={{
                            style: {
                                padding: `${theme.space.m} 0`,
                            },
                        }}
                    >
                        Left
                    </RadioCard>
                    <RadioCard
                        value="right"
                        icon={() => <SidebarRightIcon isActive={sidebarStyle === 'right'} />}
                        cardIconWrapperProps={{
                            style: {
                                padding: `${theme.space.m} 0`,
                            },
                        }}
                    >
                        Right
                    </RadioCard>
                </RadioCardGroup>
            </Field>
            {sidebarStyle !== 'none' && (
                <Box flex center justifyContent="space-between">
                    <Box as="label" htmlFor="isSidebarSticky" cursor="pointer">
                        <Body size="s">Make sidebar sticky</Body>
                    </Box>
                    <Toggle
                        id="isSidebarSticky"
                        checked={isSidebarSticky}
                        onCheckedChange={onSidebarStickyChange}
                    />
                </Box>
            )}
            <LayoutEditorControlSeparator px={0} />
            <DetailViewExperienceSwitcher size="m" width="full" variant="dotted" />
        </Box>
    )
}

type RadioCardIconProps = {
    isActive: boolean
}

const ContentWidthFullIcon: React.FC<RadioCardIconProps> = ({ isActive }) => {
    return (
        <svg
            width="40px"
            height="100%"
            viewBox="0 0 40 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.5"
                y="0.5"
                width="39"
                height="26"
                rx="1.5"
                style={{
                    transition: theme.transition.colors,
                    stroke: isActive ? theme.color.theme600 : theme.color.gray300,
                }}
            />
            <rect
                width="34"
                height="21"
                rx="1"
                transform="matrix(-1 0 0 1 37 3)"
                style={{
                    transition: theme.transition.colors,
                    fill: isActive ? theme.color.theme100 : theme.color.gray100,
                }}
            />
        </svg>
    )
}

const ContentWidthBoxedIcon: React.FC<RadioCardIconProps> = ({ isActive }) => {
    return (
        <svg
            width="40px"
            height="100%"
            viewBox="0 0 40 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.5"
                y="0.5"
                width="39"
                height="26"
                rx="1.5"
                style={{
                    transition: theme.transition.colors,
                    stroke: isActive ? theme.color.theme600 : theme.color.gray300,
                }}
            />
            <rect
                width="24"
                height="21"
                rx="1"
                transform="matrix(-1 0 0 1 32 3)"
                style={{
                    transition: theme.transition.colors,
                    fill: isActive ? theme.color.theme100 : theme.color.gray100,
                }}
            />
        </svg>
    )
}

const SidebarNoneIcon: React.FC<RadioCardIconProps> = ({ isActive }) => {
    return (
        <svg
            width="40px"
            height="100%"
            viewBox="0 0 40 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.5"
                y="0.5"
                width="39"
                height="26"
                rx="1.5"
                style={{
                    transition: theme.transition.colors,
                    stroke: isActive ? theme.color.theme600 : theme.color.gray300,
                }}
            />
            <rect
                width="34"
                height="21"
                rx="1"
                transform="matrix(-1 0 0 1 37 3)"
                style={{
                    transition: theme.transition.colors,
                    fill: isActive ? theme.color.theme100 : theme.color.gray100,
                }}
            />
        </svg>
    )
}

const SidebarLeftIcon: React.FC<RadioCardIconProps> = ({ isActive }) => {
    return (
        <svg
            width="40px"
            height="100%"
            viewBox="0 0 40 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.5"
                y="0.5"
                width="39"
                height="26"
                rx="1.5"
                style={{
                    transition: theme.transition.colors,
                    stroke: isActive ? theme.color.theme600 : theme.color.gray300,
                }}
            />
            <rect
                x="3"
                y="3"
                width="10"
                height="21"
                rx="1"
                style={{
                    transition: theme.transition.colors,
                    fill: isActive ? theme.color.theme100 : theme.color.gray100,
                }}
            />
            <rect
                width="22"
                height="21"
                rx="1"
                transform="matrix(-1 0 0 1 37 3)"
                style={{
                    transition: theme.transition.colors,
                    fill: isActive ? theme.color.theme100 : theme.color.gray100,
                }}
            />
        </svg>
    )
}

const SidebarRightIcon: React.FC<RadioCardIconProps> = ({ isActive }) => {
    return (
        <svg
            width="40px"
            height="100%"
            viewBox="0 0 40 27"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="0.5"
                y="0.5"
                width="39"
                height="26"
                rx="1.5"
                style={{
                    transition: theme.transition.colors,
                    stroke: isActive ? theme.color.theme600 : theme.color.gray300,
                }}
            />
            <rect
                width="22"
                height="21"
                rx="1"
                transform="matrix(-1 0 0 1 25 3)"
                style={{
                    transition: theme.transition.colors,
                    fill: isActive ? theme.color.theme100 : theme.color.gray100,
                }}
            />
            <rect
                x="27"
                y="3"
                width="10"
                height="21"
                rx="1"
                style={{
                    transition: theme.transition.colors,
                    fill: isActive ? theme.color.theme100 : theme.color.gray100,
                }}
            />
        </svg>
    )
}
