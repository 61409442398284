import React from 'react'

import { RecordLinksAttributeDisplay } from 'features/views/attributes/RecordLinksAttributeDisplay'
import { RecordLinksAttributeTag } from 'features/views/attributes/RecordLinksAttributeTag'

import { useRecordLinksAttributeState } from './hooks/useRecordLinksAttributeState'
import { BaseAttribute } from './BaseAttribute'
import { MAX_LENGTH_TAG } from './constants'
import { ThreadViewAttributeComponent } from './types'

const MAX_LINKS = 2
const MAX_LINES = 1

type RecordLinksAttributeProps = {}

export const RecordLinksAttribute: ThreadViewAttributeComponent<
    string | string[],
    RecordLinksAttributeProps
> = ({ value, attribute, isLoading, showIcon, ...props }) => {
    const { field, rawOptions, type } = attribute

    const { dereferencedRecords, displayAsTag, isEmpty, size } = useRecordLinksAttributeState({
        value,
        isLoading,
        attribute,
    })

    const isMessage = type === 'message'
    const isContent = type === 'content'

    let maxLines: number | undefined = MAX_LINES
    if (isMessage) {
        maxLines = undefined
    }

    let maxLinks: number | undefined = MAX_LINKS
    if (isMessage) {
        maxLinks = undefined
    }

    return (
        <BaseAttribute attribute={attribute} isEmpty={isEmpty} isLoading={isLoading} {...props}>
            {displayAsTag ? (
                <RecordLinksAttributeTag
                    size={size}
                    value={value}
                    field={field}
                    dereferencedRecords={dereferencedRecords}
                    isLoading={isLoading}
                    showIcon={showIcon}
                    maxLength={MAX_LENGTH_TAG}
                />
            ) : (
                <RecordLinksAttributeDisplay
                    size={size}
                    value={value}
                    field={field}
                    dereferencedRecords={dereferencedRecords}
                    maxLines={maxLines}
                    maxLinks={maxLinks}
                    isLoading={isLoading}
                    showSingleUserName={!rawOptions.hideUserName}
                    color={{
                        default: isMessage || isContent ? 'text' : 'textWeaker',
                        hover: 'textTheme',
                    }}
                />
            )}
        </BaseAttribute>
    )
}
