import React from 'react'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'
import { theme } from 'ui/styling/Theme.css'

import { useQuickFiltersState } from './hooks/useQuickFiltersState'
import { QuickFiltersItem } from './QuickFiltersItem'
import { QuickFiltersScrollArea } from './QuickFiltersScrollArea'

import { QuickFiltersClearButtonStyle } from './QuickFilters.css'

type QuickFiltersProps = React.ComponentPropsWithoutRef<typeof Box> & {}

export const QuickFilters: React.FC<QuickFiltersProps> = React.memo(function QuickFilters(props) {
    const { fields, clearFilters, isActive } = useQuickFiltersState()

    const clearButtonSize = useResponsiveValue({
        mobile: '2xs' as const,
        tablet: 's' as const,
    })

    const clearButtonHeight = useResponsiveValue({
        mobile: theme.tag.size['2xs'],
        tablet: theme.tag.size.m,
    })

    return (
        <QuickFiltersScrollArea {...props}>
            <Box
                flex
                center
                style={{
                    gap: theme.tag.gap.m,
                }}
            >
                {fields.map((field) => (
                    <QuickFiltersItem key={field._sid} field={field} />
                ))}
                {isActive && (
                    <Button
                        variant="ghost"
                        size={clearButtonSize}
                        onClick={clearFilters}
                        style={{ height: clearButtonHeight }}
                        noShrink
                        className={QuickFiltersClearButtonStyle}
                    >
                        Clear filters
                    </Button>
                )}
            </Box>
        </QuickFiltersScrollArea>
    )
})
