import { useCallback, useEffect, useMemo, useState } from 'react'

import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'
import {
    EmbedWidgetFieldRef,
    EmbedWidgetType,
    EmbedWidgetUrlRef,
} from 'features/views/LayoutEditor/widgets/embedWidgetTypes'
import { useRecordManagerContext } from 'features/views/RecordManager/useRecordManagerContext'

export function useEmbedWidgetState(widget: EmbedWidgetType) {
    const { isEditing, fields } = useLayoutEditorContext()
    const { record, isFetchingSlow } = useRecordManagerContext()

    const { size, src } = widget.attrs

    const field = fields.find((f) => f.api_name === (src as EmbedWidgetFieldRef)?.fieldApiName)
    const srcFieldValue = getFieldValue(record, field)
    const srcUrlValue = (src as EmbedWidgetUrlRef)?.url ?? ''

    const url = cleanUrl(src?.type === 'field' ? srcFieldValue : srcUrlValue)

    const isVisible = isEditing || !!url

    const [isLoaded, setIsLoaded] = useState(false)

    const onLoad = useCallback(() => {
        setIsLoaded(true)
    }, [])

    const [isError, setIsError] = useState(false)

    const onError = useCallback(() => {
        setIsError(true)
    }, [])

    // Reset isLoaded and isError when src changes.
    useEffect(() => {
        setIsLoaded(false)
        setIsError(false)
    }, [src])

    const isLoading = !isError && (isFetchingSlow || !isLoaded)

    return useMemo(
        () => ({
            url,
            isVisible,
            size,
            isLoading,
            onLoad,
            onError,
            isError,
        }),
        [url, isVisible, size, isLoading, onLoad, onError, isError]
    )
}

const HTTP_REGEXP = /^http:\/\//i

function cleanUrl(url: string) {
    if (!url) return ''

    // Add https:// if no protocol specified.
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
        return `https://${url}`
    }

    // Replace http:// with https://.
    return url.replace(HTTP_REGEXP, 'https://')
}

function getFieldValue(record?: RecordDto, field?: FieldDto): string {
    if (!record || !field) return ''

    const value = record[field.api_name]

    if (field.type === 'multi_file') {
        if (Array.isArray(value)) {
            return value[0]?.url ?? ''
        } else if (value && typeof value === 'object') {
            return (value as any).url ?? ''
        }
    }

    return value ?? ''
}
