import React from 'react'

import { TextAttributeDisplay } from 'features/views/attributes/TextAttributeDisplay'

import { useAttributeContext } from './hooks/useAttributeContext'
import { useTextAttributeEditorState } from './hooks/useTextAttributeEditorState'
import { AttributeTextInput } from './AttributeTextInput'
import { BaseAttribute } from './BaseAttribute'
import { FIELD_VALIDATION_REQUIRED_ERROR_MESSAGE } from './constants'
import { InlineValueWrapper } from './InlineValueWrapper'
import { FieldsWidgetAttributeComponent } from './types'

type TextAttributeProps = {}

export const TextAttribute: FieldsWidgetAttributeComponent<TextAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const { value, isEditingValue, isEditable } = useAttributeContext<string>()
    const isEmpty = !isLoading && !value

    if (isEditingValue) {
        return (
            <TextAttributeEditor
                key="text-attribute"
                field={field}
                isLoading={isLoading}
                {...props}
            />
        )
    }

    return (
        <BaseAttribute
            key="text-attribute"
            {...props}
            field={field}
            isEmpty={isEmpty}
            isLoading={isLoading}
            cursor={isEditable ? 'text' : undefined}
        >
            <InlineValueWrapper>
                <TextAttributeDisplay
                    value={value}
                    field={field.field}
                    isLoading={isLoading}
                    maxLines={1}
                    size="m"
                    alwaysShowOverflowTooltip={{
                        mobile: false,
                        tablet: true,
                    }}
                    maxWidth="full"
                    minWidth={0}
                    trim
                />
            </InlineValueWrapper>
        </BaseAttribute>
    )
}

const TextAttributeEditor: FieldsWidgetAttributeComponent<TextAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const { value, onInputBlur, onInputChange, onInputKeyDown, onClearValue, isRequired } =
        useTextAttributeEditorState()

    const isEmpty = !isLoading && !value

    return (
        <BaseAttribute
            {...props}
            field={field}
            isEmpty={isEmpty}
            isLoading={isLoading}
            isClearValueButtonDisabled={isEmpty}
            onClearValue={onClearValue}
            validationError={
                isRequired && isEmpty ? FIELD_VALIDATION_REQUIRED_ERROR_MESSAGE : undefined
            }
        >
            <AttributeTextInput
                autoFocus={true}
                onBlur={onInputBlur}
                onChange={onInputChange}
                onKeyDown={onInputKeyDown}
                value={value}
            />
        </BaseAttribute>
    )
}
