import React from 'react'

import { useWorkflowSchema } from 'data/hooks/workflows/workflows'
import { assertIsDefined } from 'data/utils/ts_utils'

import { Box } from 'ui/components/Box'

import { FindRecordsActionNode } from './Actions/FindRecordsAction'
import { RecordChangeActionNode } from './Actions/RecordChangeAction'
import { WorkflowActionNodeRendererComponent } from './Actions/types'
import { DEFAULT_ACTION_ICON } from './common'
import { ItemIcon } from './ItemIcon'
import { WorkflowItemWrapper } from './WorkflowItemWrapper'

type WorkflowActionProps = {
    action: WorkflowActionConfig
    showType?: boolean
}

export function WorkflowActionNode({ action }: WorkflowActionProps) {
    return (
        <WorkflowItemWrapper item={action} flex center>
            <WorkflowAction action={action} />
        </WorkflowItemWrapper>
    )
}

export const WorkflowAction: React.FC<WorkflowActionProps> = ({ action, showType }) => {
    const { data: schema } = useWorkflowSchema()
    const actionType = schema?.nodes?.find((node) => node.id === action.action_type)

    const Renderer = ActionNodeRenderers[action.action_type] || DefaultActionNodeRenderer
    assertIsDefined(actionType)

    return (
        <Renderer
            action={action}
            actionType={actionType as WorkflowSchemaActionType}
            showType={showType}
        />
    )
}

const DefaultActionNodeRenderer: WorkflowActionNodeRendererComponent = ({
    action,
    actionType,
    showType,
}) => (
    <>
        <ItemIcon
            icon={actionType.icon || DEFAULT_ACTION_ICON}
            iconStyle={actionType.icon_style}
            iconUrl={action.icon?.url}
            mr="m"
        />

        <Box flex column alignItems="flex-start" fontWeight="bodyRegular">
            {showType && (
                <Box fontSize="bodyXs" color="textWeakest">
                    Action
                </Box>
            )}
            {action.name}
        </Box>
    </>
)

const ActionNodeRenderers: Record<string, WorkflowActionNodeRendererComponent> = {
    create_record: RecordChangeActionNode,
    update_record: RecordChangeActionNode,
    get_record: RecordChangeActionNode,
    find_records: FindRecordsActionNode,
}
