import React from 'react'

import { Box } from 'ui/components/Box'

type InlineValueWrapperRef = HTMLDivElement

type InlineValueWrapperProps = React.ComponentPropsWithoutRef<typeof Box>

export const InlineValueWrapper = React.forwardRef<InlineValueWrapperRef, InlineValueWrapperProps>(
    ({ children, ...props }, ref) => {
        return (
            <Box ref={ref} maxWidth="full" overflow="hidden" {...props}>
                <Box display="inline-block" verticalAlign="middle" maxWidth="full">
                    {children}
                </Box>
            </Box>
        )
    }
)
