import React, { useState } from 'react'

import styled from '@emotion/styled'

import { useExtDataIntegrations } from 'data/hooks/dataConnections/useExtDataIntegrations'
import {
    INTEGRATION_ID_TO_DC_TYPE,
    INTEGRATION_ID_TO_NAME,
} from 'features/DataConnections/constants'
import { getIconForDataConnectionType } from 'features/DataConnections/getIconForDataConnectionType'

import { Flex } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Spinner } from 'ui/components/Spinner'
import { Body, Headline } from 'ui/components/Text'
import { theme } from 'ui/styling/Theme.css'

import { DeleteExtConnectionConfirmationModal } from './DeleteExtConnectionConfirmationModal'

const ExtConnectionHeaderContainer = styled.div`
    display: flex;
    justify-content: space-between;
    color: inherit;
    width: 100%;
    padding-top: ${theme.space.xs};
    padding-bottom: ${theme.space.xs};
    border-style: ${theme.borderStyles.base};
    border-width: 0;
    border-bottom-width: ${theme.borderWidths[1]};
    border-bottom-color: ${theme.color.borderWeak};
`
const ConnectionRowContainer = styled(ExtConnectionHeaderContainer)`
    padding-top: ${theme.space.l};
    padding-bottom: ${theme.space.l};
    flex-direction: column;

    &:hover {
        background-color: ${theme.color.surfaceStrong};
    }
`

export const ExternalConnectionsSettings: React.FC = () => {
    const { data: integrationsData, isLoading: isLoadingExtDataIntegration } =
        useExtDataIntegrations()

    const extConnections = integrationsData?.connections

    return (
        <Flex column p={5} alignItems="flexStart">
            <Box
                style={{
                    paddingTop: theme.space['3xl'],
                    paddingBottom: theme.space['10xl'],
                    overflowX: 'scroll',
                    height: '90vh',
                }}
            >
                <Headline size="s">External Accounts</Headline>
                {isLoadingExtDataIntegration ? (
                    <Box
                        flex
                        style={{
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '600px',
                            height: '100px',
                        }}
                    >
                        <Spinner size="2xl" />
                    </Box>
                ) : (
                    extConnections?.map((extConnection) => (
                        <ExtConnectionRow key={extConnection.id} extConnection={extConnection} />
                    ))
                )}
            </Box>
        </Flex>
    )
}

type ExtConnectionRowProps = {
    extConnection: ExtDataIntegrationConnectionDto
}

export const ExtConnectionRow: React.FC<ExtConnectionRowProps> = ({ extConnection }) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)

    const integrationName = INTEGRATION_ID_TO_NAME[extConnection.provider_id]
    const icon = getIconForDataConnectionType(INTEGRATION_ID_TO_DC_TYPE[extConnection.provider_id])

    return (
        <ConnectionRowContainer>
            <Box flex style={{ width: '600px', justifyContent: 'space-between' }}>
                <Box
                    flex
                    style={{
                        paddingTop: theme.space.s,
                        paddingBottom: theme.space.s,
                        paddingRight: theme.space.xs,
                        paddingLeft: theme.space.xs,
                    }}
                >
                    <img
                        height="35px"
                        width="35px"
                        alt={`${integrationName} icon`}
                        style={{
                            marginLeft: theme.space.m,
                            marginRight: theme.space.l,
                        }}
                        src={icon}
                    />
                    <Box flex column>
                        <Body size="m" weight="bold">
                            {integrationName}
                        </Body>
                        <Body size="s">{extConnection.external_user_email}</Body>
                    </Box>
                </Box>
                <Box
                    flex
                    style={{
                        paddingRight: theme.space.xl,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                    }}
                >
                    <Button
                        variant="critical"
                        startIcon={{ name: 'Link2Off' }}
                        aria-label="Delete"
                        size="xs"
                        onClick={() => setIsDeleteModalOpen(true)}
                    >
                        Disconnect
                    </Button>
                </Box>
            </Box>

            <DeleteExtConnectionConfirmationModal
                isOpen={isDeleteModalOpen}
                onOpenChange={setIsDeleteModalOpen}
                extConnection={extConnection}
            />
        </ConnectionRowContainer>
    )
}
