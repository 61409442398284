import React, { useCallback, useEffect, useState } from 'react'

import { RendererConfig } from 'data/hooks/agents/types'
import { QueryResultRendererConfig } from 'features/Agents/chat/renderers/types'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import {
    Modal,
    ModalContent,
    ModalContentInner,
    ModalHeader,
    ModalPortal,
    ModalTrigger,
} from 'ui/components/Modal'

import { EmailRendererConfig } from './rendererTypes/EmailRendererConfig'
import { QueryResultRendererConfigurator } from './rendererTypes/QueryResultRendererConfigurator'
import { RENDERER_TYPES } from './constants'
import { RendererListItem } from './RendererListItem'

type ConfigureRendererModalProps = {
    children: React.ReactNode
    onSubmit: (config: RendererConfig) => void
    config?: RendererConfig
}

export function ConfigureRendererModal({
    children,
    onSubmit,
    config,
}: ConfigureRendererModalProps) {
    const [isOpen, setIsOpen] = useState(false)
    const [screen, setScreen] = useState<'select' | 'configure'>(config ? 'configure' : 'select')
    const [name, setName] = useState<string>(config?.name || '')
    const [selectedType, setSelectedType] = useState<RendererConfig['type'] | undefined>(
        config?.type || undefined
    )
    const [rendererConfig, setRendererConfig] = useState<Record<string, any>>(config?.config || {})

    const handleTypeSelect = (type: RendererConfig['type']) => {
        setSelectedType(type)
        setRendererConfig({})
        setScreen('configure')
    }

    const handleSubmit = () => {
        onSubmit({
            type: selectedType!,
            config: rendererConfig,
            name,
        })
        setIsOpen(false)
    }

    const renderConfigForm = () => {
        switch (selectedType) {
            case 'queryResult':
                return (
                    <QueryResultRendererConfigurator
                        config={rendererConfig as QueryResultRendererConfig}
                        onChange={setRendererConfig}
                        setName={setName}
                    />
                )
            case 'email':
                return <EmailRendererConfig config={rendererConfig} onChange={setRendererConfig} />
            default:
                return null
        }
    }

    const resetValues = useCallback(() => {
        const rendererType = RENDERER_TYPES.find((type) => type.type === config?.type)
        setSelectedType(config?.type)
        setName(config?.name || rendererType?.label || '')
        setRendererConfig(config?.config || {})
    }, [config?.config, config?.name, config?.type])

    const resetForm = useCallback(() => {
        if (config) {
            setScreen('configure')
        } else {
            setScreen('select')
        }

        resetValues()
    }, [config, resetValues])

    useEffect(() => {
        if (!isOpen) {
            resetForm()
        }
    }, [isOpen, resetForm])

    return (
        <Modal open={isOpen} onOpenChange={setIsOpen}>
            <ModalTrigger asChild>{children}</ModalTrigger>
            <ModalPortal>
                <ModalContent>
                    <ModalHeader
                        title={screen === 'select' ? 'Select Renderer' : 'Configure Renderer'}
                        showBackButton={screen === 'configure'}
                        onBackButtonClick={() => setScreen('select')}
                    />
                    <ModalContentInner>
                        {screen === 'select' && (
                            <Box flex column gap="m" pb="l">
                                <Box display="flex" column gap="m">
                                    {RENDERER_TYPES.map((type) => (
                                        <RendererListItem
                                            key={type.type}
                                            renderer={{ type: type.type, config: {} }}
                                            onClick={() => handleTypeSelect(type.type)}
                                        />
                                    ))}
                                </Box>
                            </Box>
                        )}
                        {screen === 'configure' && (
                            <Box flex column gap="m" pb="l">
                                {renderConfigForm()}
                                <Box flex justifyContent="flex-end" gap="s">
                                    <Button variant="secondary" onClick={() => setIsOpen(false)}>
                                        Cancel
                                    </Button>
                                    <Button onClick={handleSubmit}>
                                        {config ? 'Update' : 'Add'}
                                    </Button>
                                </Box>
                            </Box>
                        )}
                    </ModalContentInner>
                </ModalContent>
            </ModalPortal>
        </Modal>
    )
}
