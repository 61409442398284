import React from 'react'
import Truncate from 'react-truncate-markup'

import { useRecordLinksAttributeDisplayState } from 'features/views/attributes/hooks/useRecordLinksAttributeDisplayState'
import { Users } from 'features/views/attributes/Users'

import { Box } from 'ui/components/Box'
import {
    Dropdown,
    DropdownContent,
    DropdownHeadSearch,
    DropdownItem,
    DropdownItemLink,
    DropdownTrigger,
} from 'ui/components/Dropdown'
import { Link } from 'ui/components/Link'
import { ScrollArea } from 'ui/components/ScrollArea'
import { Skeleton } from 'ui/components/Skeleton'
import { Tag } from 'ui/components/Tag'
import { Body } from 'ui/components/Text'
import { stopPropagation } from 'ui/helpers/utilities'

import { useAttributeContext } from './hooks/useAttributeContext'
import { useRecordLinksAttributeEditorState } from './hooks/useRecordLinksAttributeEditorState'
import { useRecordLinksAttributeState } from './hooks/useRecordLinksAttributeState'
import { BaseAttribute } from './BaseAttribute'
import { FIELD_VALIDATION_REQUIRED_ERROR_MESSAGE } from './constants'
import { InlineValueWrapper } from './InlineValueWrapper'
import { FieldsWidgetAttributeComponent } from './types'

import { RecordLinksAttributeEditorScrollAreaStyle } from './RecordLinksAttribute.css'

const MAX_LINES = 3

type RecordLinksAttributeProps = {}

export const RecordLinksAttribute: FieldsWidgetAttributeComponent<RecordLinksAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const { isEditingValue } = useAttributeContext<string | string[]>()

    if (isEditingValue) {
        return (
            <RecordLinksAttributeEditor
                key="record-links-attribute"
                field={field}
                isLoading={isLoading}
                {...props}
            />
        )
    }

    return (
        <RecordLinksAttributeValue
            key="record-links-attribute"
            field={field}
            isLoading={isLoading}
            {...props}
        />
    )
}

const RecordLinksAttributeValue: FieldsWidgetAttributeComponent<RecordLinksAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const {
        records,
        isUsersObject,
        users,
        isOverflowPopupOpen,
        onOverflowLabelCloseAutoFocus,
        onOverflowLabelMouseEnter,
        onOverflowLabelMouseLeave,
        onOverflowPopupOpenChange,
        onLinkClick,
        attributeRef,
        labelPlacement,
        isEmpty,
        isEditable,
        visibleLinksLength,
        onTruncate,
    } = useRecordLinksAttributeState({
        field,
        isLoading,
    })

    if (isUsersObject) {
        return (
            <BaseAttribute
                {...props}
                field={field}
                isEmpty={isEmpty}
                labelPlacement={labelPlacement}
                isLoading={isLoading}
                alignItems={labelPlacement === 'left' ? 'center' : 'flex-start'}
                cursor={isEditable ? 'pointer' : undefined}
            >
                <InlineValueWrapper>
                    <Users
                        value={users}
                        isLoading={isLoading}
                        size="m"
                        onClick={onLinkClick}
                        showSingleUserName={true}
                    />
                </InlineValueWrapper>
            </BaseAttribute>
        )
    }

    return (
        <BaseAttribute
            ref={attributeRef}
            {...props}
            field={field}
            isEmpty={isEmpty}
            labelPlacement={labelPlacement}
            isLoading={isLoading}
            alignItems={labelPlacement === 'left' ? 'center' : 'flex-start'}
            cursor={isEditable ? 'pointer' : undefined}
        >
            <Truncate
                lines={MAX_LINES}
                onTruncate={onTruncate}
                ellipsis={
                    <OverflowLinks
                        records={records}
                        visibleLinksLength={visibleLinksLength}
                        isOverflowPopupOpen={isOverflowPopupOpen}
                        onOverflowLabelCloseAutoFocus={onOverflowLabelCloseAutoFocus}
                        onOverflowLabelMouseEnter={onOverflowLabelMouseEnter}
                        onOverflowLabelMouseLeave={onOverflowLabelMouseLeave}
                        onOverflowPopupOpenChange={onOverflowPopupOpenChange}
                        onLinkClick={onLinkClick}
                    />
                }
            >
                <Box
                    whiteSpace="normal"
                    style={{
                        wordBreak: 'break-word',
                    }}
                >
                    <Skeleton isLoading={isLoading}>
                        {records.map((record, idx) => (
                            <Truncate.Atom key={record.url}>
                                {idx > 0 && ', '}
                                <Link to={record.url} onClick={onLinkClick} size="m">
                                    {record.name}
                                </Link>
                            </Truncate.Atom>
                        ))}
                    </Skeleton>
                </Box>
            </Truncate>
        </BaseAttribute>
    )
}

type OverflowLinksProps = Pick<
    ReturnType<typeof useRecordLinksAttributeDisplayState>,
    | 'records'
    | 'isOverflowPopupOpen'
    | 'onOverflowLabelCloseAutoFocus'
    | 'onOverflowLabelMouseEnter'
    | 'onOverflowLabelMouseLeave'
    | 'onOverflowPopupOpenChange'
> & {
    visibleLinksLength: number
    onLinkClick: (e: React.MouseEvent<HTMLElement>) => void
}

const OverflowLinks: React.FC<OverflowLinksProps> = ({
    records,
    visibleLinksLength,
    isOverflowPopupOpen,
    onOverflowLabelCloseAutoFocus,
    onOverflowLabelMouseEnter,
    onOverflowLabelMouseLeave,
    onOverflowPopupOpenChange,
    onLinkClick,
}) => {
    const overflowingRecordsLen = records.length - visibleLinksLength
    const overflowingRecords = records.slice(visibleLinksLength, records.length)

    if (overflowingRecordsLen > 0) {
        return (
            <Dropdown
                open={isOverflowPopupOpen}
                onOpenChange={onOverflowPopupOpenChange}
                modal={false}
            >
                <DropdownTrigger
                    onClick={stopPropagation}
                    onMouseEnter={onOverflowLabelMouseEnter}
                    onMouseLeave={onOverflowLabelMouseLeave}
                >
                    <Body ml="s" weight="medium" color="linkText">
                        +{overflowingRecordsLen}
                    </Body>
                </DropdownTrigger>
                <DropdownContent
                    sideOffset={0}
                    side="bottom"
                    align="end"
                    onCloseAutoFocus={onOverflowLabelCloseAutoFocus}
                    onMouseEnter={onOverflowLabelMouseEnter}
                    onMouseLeave={onOverflowLabelMouseLeave}
                >
                    {overflowingRecords.map((record) => (
                        <DropdownItemLink
                            key={record.url}
                            to={record.url}
                            label={record.name}
                            onClick={onLinkClick}
                        />
                    ))}
                </DropdownContent>
            </Dropdown>
        )
    }

    return <>...</>
}

const RecordLinksAttributeEditor: FieldsWidgetAttributeComponent<RecordLinksAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const {
        valueOptions,
        selectableOptions,
        onClearValue,
        isSingle,
        value,
        onValueChange,
        isOpen,
        onOpenChange,
        onEscapeKeyDown,
        onInteractOutside,
        isError,
        isFetchingSlow,
        searchQuery,
        setSearchQuery,
        isLabelLoading,
        usersValueOptions,
        isUsersObject,
        isRequired,
    } = useRecordLinksAttributeEditorState()
    const isEmpty = !isLoading && Array.isArray(valueOptions) && valueOptions.length < 1

    return (
        <Dropdown open={isOpen} onOpenChange={onOpenChange} modal={false}>
            <DropdownTrigger asChild>
                <BaseAttribute
                    {...props}
                    field={field}
                    isEmpty={isEmpty}
                    isLoading={isLoading}
                    isClearValueButtonDisabled={isEmpty}
                    onClearValue={onClearValue}
                    validationError={
                        isRequired && isEmpty ? FIELD_VALIDATION_REQUIRED_ERROR_MESSAGE : undefined
                    }
                >
                    <ScrollArea
                        direction="vertical"
                        type="auto"
                        minWidth={0}
                        maxWidth="full"
                        className={RecordLinksAttributeEditorScrollAreaStyle}
                    >
                        <Skeleton
                            isLoading={isLabelLoading}
                            flex
                            center
                            minWidth={0}
                            maxWidth="full"
                        >
                            <Box
                                flex
                                wrap
                                gap="xs"
                                minWidth={0}
                                maxWidth="full"
                                onPointerDown={stopPropagation}
                            >
                                {isUsersObject && (
                                    <Users
                                        isLoading={isLabelLoading}
                                        value={usersValueOptions}
                                        size="m"
                                        avatarSize="s"
                                        overflow
                                        showSingleUserName={true}
                                        showTooltips={false}
                                    />
                                )}

                                {!isUsersObject &&
                                    valueOptions.map((option) => (
                                        <Skeleton
                                            isLoading={isLoading}
                                            key={option.value}
                                            style={{
                                                maxWidth: '100%',
                                                minWidth: 0,
                                            }}
                                        >
                                            <Tag
                                                size="xs"
                                                tabIndex={-1}
                                                style={{
                                                    wordBreak: 'break-word',
                                                    maxWidth: '100%',
                                                    minWidth: 0,
                                                }}
                                                pointerEvents={isSingle ? 'none' : undefined}
                                                showRemoveButton={!isSingle}
                                                onRemove={() => onValueChange(option.value, false)}
                                            >
                                                {option.label}
                                            </Tag>
                                        </Skeleton>
                                    ))}
                            </Box>
                        </Skeleton>
                    </ScrollArea>
                </BaseAttribute>
            </DropdownTrigger>
            <DropdownContent
                onClick={stopPropagation}
                head={!isFetchingSlow && <DropdownHeadSearch />}
                searchQuery={searchQuery}
                setSearchQuery={setSearchQuery}
                onEscapeKeyDown={onEscapeKeyDown}
                onInteractOutside={onInteractOutside}
                onCloseAutoFocus={stopPropagation}
            >
                {isError && (
                    <DropdownItem
                        label={
                            <Body size="m" color="textError">
                                There was a problem displaying options
                            </Body>
                        }
                        disabled
                    />
                )}
                {!isError &&
                    selectableOptions.map((item) => (
                        <DropdownItem
                            key={item.value}
                            multiSelect
                            label={
                                isFetchingSlow ? (
                                    <Skeleton isLoading={isFetchingSlow}>{item.label}</Skeleton>
                                ) : (
                                    item.label
                                )
                            }
                            checked={value.has(item.value)}
                            closeOnSelect={isSingle}
                            onCheckedChange={(checked) => {
                                onValueChange(item.value, checked)
                            }}
                            disabled={isFetchingSlow}
                            startAvatar={item.avatar}
                        />
                    ))}

                {!isError && selectableOptions.length < 1 && (
                    <DropdownItem
                        label={
                            <Body size="m" color="textDisabled">
                                No options available
                            </Body>
                        }
                        disabled
                    />
                )}
            </DropdownContent>
        </Dropdown>
    )
}
