import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useGetLinkedFields } from 'v2/blocks/useGetLinkedFields'

import { useObjects } from 'data/hooks/objects'
import { ObjectFieldsFilterV4 as Filters } from 'features/records/components/RecordFilters'
import { toYType } from 'features/utils/useYjsState'
import { LayoutEditorCollapsibleControl } from 'features/views/LayoutEditor/controls/LayoutEditorCollapsibleControl'
import { LayoutEditorControlSeparator } from 'features/views/LayoutEditor/controls/LayoutEditorControlSeparator'
import { WidgetAdminControlsComponent } from 'features/views/LayoutEditor/types'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'
import { createWidget } from 'features/views/LayoutEditor/utils'
import { ListViewWidgetType } from 'features/views/LayoutEditor/widgets/ListViewWidget/listViewWidgetTypes'
import { makeAllFields } from 'features/views/ListView/utils'

import useEffectOnlyOnUpdate from 'v2/ui/utils/useEffectOnlyOnUpdate'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Collapsible, CollapsibleContent } from 'ui/components/Collapsible'
import { DropdownItem } from 'ui/components/Dropdown'
import { Field } from 'ui/components/Field'
import { Select, SelectOption } from 'ui/components/Select'
import { Body } from 'ui/components/Text'
import { Toggle } from 'ui/components/Toggle'

export const ListViewWidgetDataControls: WidgetAdminControlsComponent<ListViewWidgetType> = ({
    widget,
    onChange,
}) => {
    const { object, schema, view } = useLayoutEditorContext()
    const schemaRef = useRef(schema)
    schemaRef.current = schema
    const viewRef = useRef(view)
    viewRef.current = view

    const { objectSid, linkedField, filters, sortByFieldApiName, sortOrder = 'asc' } = widget.attrs

    const { data: objects = [], isFetching } = useObjects()
    const objectsRef = useRef(objects)
    objectsRef.current = objects

    const targetObject = objects.find((object) => object._sid === objectSid)
    const targetObjectFields = makeAllFields(targetObject)

    const [showOnlyRelatedRecords, setShowOnlyRelatedRecords] = useState(!!linkedField)
    useEffect(() => {
        if (linkedField) {
            setShowOnlyRelatedRecords(true)
        }
    }, [linkedField])

    const handleDataSourceChange = useCallback(
        (value: string) => {
            const object = objectsRef.current.find((object) => object._sid === value)
            if (!object) return

            onChange((attrs) => {
                const display = attrs.get('display')

                // Clear the attributes to avoid having old data in the widget.
                attrs.clear()

                const defaultWidget = createWidget(widget.type, schemaRef.current, viewRef.current)
                if (!defaultWidget) return

                for (const [key, value] of Object.entries(defaultWidget.attrs)) {
                    attrs.set(key, value)
                }

                // Keep display type
                attrs.set('display', display)

                attrs.set('objectSid', object._sid)
                attrs.set('stackSid', object.stack_id)
            })
        },
        [onChange, widget.type]
    )

    const [localLinkedFields, foreignLinkedFields] = useGetLinkedFields(object!, targetObject)

    const availableFields = useMemo(
        () => [...localLinkedFields, ...foreignLinkedFields],
        [foreignLinkedFields, localLinkedFields]
    )
    const availableFieldsRef = useRef(availableFields)
    availableFieldsRef.current = availableFields

    const handleLinkedFieldChange = useCallback(
        (value: string) => {
            const field = availableFieldsRef.current.find((field) => field.fieldId === value)
            if (!field) return

            onChange((attrs) => {
                attrs.set(
                    'linkedField',
                    toYType({
                        fieldSid: field.fieldId,
                        objectSid: field.objectId,
                        isExternal: field.isExternalField,
                    })
                )
            })
        },
        [onChange]
    )

    const prevObjectSid = useRef(objectSid)
    useEffectOnlyOnUpdate(() => {
        if (objectSid === prevObjectSid.current) return

        // Reset related records settings when the target object changes.
        setShowOnlyRelatedRecords(false)

        prevObjectSid.current = objectSid
    }, [objectSid, onChange])

    useEffect(() => {
        if (showOnlyRelatedRecords) return

        // Reset the linked field when the "show only related records" option is disabled.
        onChange((attrs) => {
            attrs.delete('linkedField')
        })
    }, [onChange, showOnlyRelatedRecords])

    return (
        <Box flex column gap="l">
            <LayoutEditorCollapsibleControl
                label="Data source"
                startIcon={{ name: 'LayoutPanelTop' }}
            >
                <Box flex column gap="l">
                    <Select
                        label="Table"
                        placeholder="Select data source..."
                        onChange={handleDataSourceChange}
                        value={objectSid}
                        isLoading={isFetching}
                        isSearchable
                    >
                        {objects.map((object) => (
                            <SelectOption
                                key={object._sid}
                                value={object._sid}
                                label={object.name}
                            />
                        ))}
                    </Select>
                    <Field
                        htmlFor="onlyShowRelatedRecords"
                        label="Show only related records"
                        rightSlotContent={
                            <Toggle
                                id="onlyShowRelatedRecords"
                                checked={showOnlyRelatedRecords}
                                onCheckedChange={setShowOnlyRelatedRecords}
                            />
                        }
                    />
                    <Collapsible open={showOnlyRelatedRecords}>
                        <CollapsibleContent
                            animation="fade"
                            style={{
                                margin: '-5px',
                                padding: '5px',
                            }}
                        >
                            <Select
                                label="Link field"
                                placeholder="Select link field..."
                                onChange={handleLinkedFieldChange}
                                value={linkedField?.fieldSid}
                                isSearchable
                            >
                                {availableFields.length < 1 && (
                                    <DropdownItem
                                        label={
                                            <Body size="m" color="textDisabled">
                                                No options available
                                            </Body>
                                        }
                                        disabled
                                    />
                                )}
                                {availableFields.map((field) => (
                                    <SelectOption
                                        key={field.fieldId}
                                        value={field.fieldId}
                                        label={field.label}
                                    />
                                ))}
                            </Select>
                        </CollapsibleContent>
                    </Collapsible>
                </Box>
            </LayoutEditorCollapsibleControl>
            <LayoutEditorControlSeparator />
            <LayoutEditorCollapsibleControl
                label="Data filter"
                startIcon={{ name: 'Filter' }}
                defaultOpen={false}
            >
                <Body size="s" mb="l" display="block">
                    Filters let you change which records are displayed in this list. This only
                    changes the appearance of the app, and is not a security feature.
                </Body>
                <Filters
                    value={filters}
                    object={targetObject}
                    fields={targetObjectFields}
                    onChange={(f) =>
                        onChange((attrs) => {
                            attrs.set('filters', toYType(f))
                        })
                    }
                    place="bottom-end"
                    showRelativeDateFilters
                    customButtonRender={(props) => (
                        <Box>
                            <Button
                                {...props}
                                size="2xs"
                                variant="primary"
                                startIcon={{ name: 'Plus' }}
                            >
                                Add filter
                            </Button>
                        </Box>
                    )}
                />
            </LayoutEditorCollapsibleControl>
            <LayoutEditorControlSeparator />
            <LayoutEditorCollapsibleControl
                label="Default sorting"
                startIcon={{ name: 'ArrowUpNarrowWide' }}
                defaultOpen={false}
            >
                <Box flex center gap="m">
                    <Box grow>
                        <Select
                            placeholder="Select field"
                            value={sortByFieldApiName ?? ''}
                            isClearable
                            isSearchable
                            onChange={(value) => {
                                const newValue = value || undefined

                                onChange((attrs) => attrs.set('sortByFieldApiName', newValue))
                            }}
                        >
                            {targetObjectFields.map((field) => (
                                <SelectOption
                                    key={field.api_name}
                                    value={field.api_name}
                                    label={field.label}
                                />
                            ))}
                        </Select>
                    </Box>
                    <Box style={{ width: '90px' }}>
                        <Select
                            value={sortOrder}
                            onChange={(value) => {
                                onChange((attrs) => {
                                    attrs.set('sortOrder', value)
                                })
                            }}
                        >
                            <SelectOption value="asc" label="A->Z" />
                            <SelectOption value="desc" label="Z->A" />
                        </Select>
                    </Box>
                </Box>
            </LayoutEditorCollapsibleControl>
        </Box>
    )
}
