import { WidgetSchema } from 'features/views/LayoutEditor/types'
import { getRequiredFieldsFromFilters } from 'features/views/LayoutEditor/utils'

import { TabsWidget, TabsWidgetAdminControls } from './TabsWidget'
import { TabsWidgetType } from './tabsWidgetTypes'
import { getTabWidgetUrlSlug } from './tabsWidgetUtils'

export const TabsWidgetMeta: WidgetSchema<TabsWidgetType> = {
    category: 'layout',
    label: 'Tabs',
    icon: 'Folders',
    component: TabsWidget,
    adminControlsComponent: TabsWidgetAdminControls,
    isContainer: true,
    defaultAttrs: (_id, view) => {
        return {
            tabs: [],
            urlSlug: getTabWidgetUrlSlug(view),
        }
    },
    requireFields: (attrs, fields) => {
        const fieldsSet = new Set<string>()

        for (const tab of attrs.tabs) {
            const fieldsForFilters = getRequiredFieldsFromFilters(tab.conditions, fields)
            for (const field of fieldsForFilters) {
                fieldsSet.add(field)
            }
        }

        return Array.from(fieldsSet)
    },
}
