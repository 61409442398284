import React, { ComponentProps } from 'react'

import { Box } from 'ui/components/Box'
import { Dropdown, DropdownContent, DropdownTrigger } from 'ui/components/Dropdown'
import { Icon } from 'ui/components/Icon'
import { TriggerStyles } from 'ui/components/Select/Select.css'
import {
    ColorSchemeLowerCase,
    colorSchemeNames,
} from 'ui/styling/baseVariables/colors/colorPalette'

export type ColorPickerProps = ComponentProps<typeof Dropdown> & {
    /**
     * The currently selected color (lowercase color scheme name)
     */
    value?: ColorSchemeLowerCase
    /**
     * Callback when color selection changes
     */
    onChange?: (color: ColorSchemeLowerCase) => void
    /**
     * Optional className for styling
     */
    className?: string
}

export const ColorPicker: React.FC<ColorPickerProps> = ({ value, onChange }) => {
    // Convert value to lowercase for comparison
    const selectedColor = value?.toLowerCase() || 'gray'

    return (
        <Dropdown>
            <DropdownTrigger
                className={TriggerStyles.styleFunction({ size: 'm' })}
                style={{
                    width: 'auto',
                }}
                asChild
            >
                <Box flex center gap="xs" role="button">
                    <Box
                        height="2xl"
                        width="2xl"
                        rounded="s"
                        // @ts-ignore
                        background={`${selectedColor}500`}
                    />
                    <Icon size="m" name="ChevronDown" />
                </Box>
            </DropdownTrigger>
            <DropdownContent>
                <Box
                    display="grid"
                    px="m"
                    style={{
                        gridTemplateColumns: 'repeat(4, 1fr)',
                    }}
                >
                    {colorSchemeNames.map((colorName) => {
                        const colorKey = colorName.toLowerCase()
                        const isSelected = colorKey === selectedColor

                        return (
                            <Box
                                key={colorKey}
                                onClick={() =>
                                    onChange?.(colorKey.toLowerCase() as ColorSchemeLowerCase)
                                }
                                width="3xl"
                                height="3xl"
                                rounded="m"
                                role="button"
                                border
                                // @ts-ignore
                                background={`${colorKey}500`}
                                flex
                                center
                                justifyContent="center"
                            >
                                {isSelected && <Icon name="Check" color="white00" />}
                            </Box>
                        )
                    })}
                </Box>
            </DropdownContent>
        </Dropdown>
    )
}
