import { pxToRem } from 'ui/helpers/converters'

import { makeAppVariables } from './app'

export function makeTextareaVariables(s: ReturnType<typeof makeAppVariables>) {
    return {
        color: {
            surface: s.color.surface,
            surfaceDisabled: s.color.surfaceDisabled,
            surfaceReadOnly: s.color.surfaceDisabled,

            border: s.color.gray300,
            borderHover: s.color.gray400,
            borderDisabled: s.color.borderDisabled,
            borderFocus: s.color.borderFocus,
            borderReadOnly: s.color.gray300,
            borderError: s.color.borderError,
            borderDivider: s.color.gray300,

            text: s.color.text,
            textPlaceholder: s.color.textWeakest,
            textDisabled: s.color.textDisabled,

            resizeHandle: s.color.borderStrong,
            resizeHandleReadOnly: s.color.gray300,
            resizeHandleDisabled: s.color.borderDisabled,

            selection: s.color.theme100,
        },
        size: {
            m: pxToRem(80),
            l: pxToRem(120),
            resizeHandleWidthM: pxToRem(7),
            resizeHandleHeightM: pxToRem(7),
            resizeHandleWidthL: pxToRem(8),
            resizeHandleHeightL: pxToRem(9.25),
            resizeHandleStrokeWidth: pxToRem(1),
            toolbarSeparator: pxToRem(20),
            toolbar: pxToRem(28),
            width: pxToRem(250),
            richTextWidth: pxToRem(500),
        },
        radius: {
            container: s.radius.l,
        },
        borderWidth: {
            container: s.borderWidths[1],
        },
        borderStyle: {
            container: s.borderStyles.base,
        },
        padding: {
            verticalM: s.space.l,
            horizontalM: s.space.l,
            verticalL: s.space.l,
            horizontalL: s.space.xl,
            toolbar: s.space.xs,
            toolbarSeparator: s.space.s,
            toolbarPanel: s.space.s,
        },
        fontWeights: {
            text: s.fontWeights.bodyRegular,
            toolbarInput: s.fontWeights.bodyRegular,
        },
        lineHeights: {
            textM: s.lineHeights.bodyM,
            textL: s.lineHeights.bodyL,
        },
        fontSizes: {
            textM: s.fontSizes.bodyM,
            textL: s.fontSizes.bodyL,
            toolbarInput: s.fontSizes.bodyS,
        },
        shadows: {
            focus: s.shadows.focus,
        },
        gap: {
            resizeHandleRightM: pxToRem(6),
            resizeHandleBottomM: pxToRem(6),
            resizeHandleRightL: pxToRem(6),
            resizeHandleBottomL: pxToRem(6),
            toolbarItems: s.space.xs,
            toolbarIcon: s.space.xs,
        },
    }
}
