import React from 'react'

import { CheckboxAttributeDisplay } from 'features/views/attributes/CheckboxAttributeDisplay'

import { useCheckboxAttributeState } from './hooks/useCheckboxAttributeState'
import { BaseAttribute } from './BaseAttribute'
import { InlineValueWrapper } from './InlineValueWrapper'
import { FieldsWidgetAttributeComponent } from './types'

type CheckboxAttributeProps = {}

export const CheckboxAttribute: FieldsWidgetAttributeComponent<CheckboxAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const { value, isEditable, onClick, onKeyDown } = useCheckboxAttributeState({
        isLoading,
    })

    return (
        <BaseAttribute
            {...props}
            field={field}
            isLoading={isLoading}
            onKeyDown={onKeyDown}
            hideClearValueButton={true}
            cursor={isEditable ? 'pointer' : undefined}
            onClick={onClick}
        >
            <InlineValueWrapper>
                <CheckboxAttributeDisplay value={value} field={field.field} isLoading={isLoading} />
            </InlineValueWrapper>
        </BaseAttribute>
    )
}
