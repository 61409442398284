import React, { useCallback, useMemo, useRef, useState } from 'react'

import * as Y from 'yjs'

import { FieldEditorPopover } from 'features/admin/fields/FieldEditorPopover'
import { FieldIcon } from 'features/admin/fields/icons/FieldIcon'
import { getFieldIcon } from 'features/admin/fields/icons/utils'
import { toYType } from 'features/utils/useYjsState'
import * as yUtils from 'features/utils/yUtils'
import {
    DetailViewTabControl,
    DetailViewTabControlItem,
} from 'features/views/DetailView/DetailViewTabControl'
import { WidgetAdminControlsComponent, WidgetComponent } from 'features/views/LayoutEditor/types'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'

import FieldListEditor from 'v2/ui/components/FieldsEditor/FieldListEditor'
import { Item } from 'v2/ui/components/OrderableListSelector/types'
import useDebounce from 'v2/ui/utils/useDebounce'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Field } from 'ui/components/Field'
import { IconPickerDropdown } from 'ui/components/IconPicker'
import { IconValue } from 'ui/components/IconPicker/IconPickerDropdown'
import { Input } from 'ui/components/Input'
import { RadioButton, RadioCard, RadioCardGroup, RadioGroup } from 'ui/components/Radio'
import { Body, Headline } from 'ui/components/Text'
import { Toggle } from 'ui/components/Toggle'
import { theme } from 'ui/styling/Theme.css'

import { Attribute } from './attributes/Attribute'
import { AttributeContextProvider } from './attributes/AttributeContext'
import { useFieldsWidgetFields } from './hooks/useFieldsWidgetFields'
import { useFieldsWidgetState } from './hooks/useFieldsWidgetState'
import { FieldsWidgetType, FieldsWidgetTypeField } from './fieldWidgetTypes'
import { canFieldBeRequired, canFieldBeTruncated } from './utils'

import { FieldsWidgetContentStyles } from './FieldsWidget.css'

type FieldsWidgetProps = {}

export const FieldsWidget: WidgetComponent<FieldsWidgetType, FieldsWidgetProps> = ({
    widget,
    isEditing,
}) => {
    const {
        showFieldIcon,
        labelPlacement,
        hasRecord,
        isFetchingSlow,
        fieldsByColumn,
        setWrapperRef,
        hasFields,
        toggleEditingField,
        editingFieldsApiNames,
    } = useFieldsWidgetState({ widget })

    if (!hasRecord && !isFetchingSlow) return null
    if (!hasFields && !isEditing) return null

    if (!hasFields && isEditing) {
        return (
            <Box flex column gap="m" py="l">
                <Body color="textWeakest">No fields to display</Body>
            </Box>
        )
    }

    return (
        <Box ref={setWrapperRef} width="full">
            <Box className={FieldsWidgetContentStyles.styleFunction({ labelPlacement })}>
                {fieldsByColumn.map((column, idx) => (
                    <Box
                        flex
                        column
                        grow
                        shrink
                        gap={{
                            mobile: 'm',
                            tablet: labelPlacement === 'top' ? 'xl' : 'm',
                        }}
                        key={idx}
                    >
                        {column.map((f) => (
                            <AttributeContextProvider
                                key={f.sid}
                                labelPlacement={labelPlacement}
                                showFieldIcon={showFieldIcon}
                                editingFields={editingFieldsApiNames}
                                toggleEditingField={toggleEditingField}
                                isEditingLayout={isEditing}
                                field={f}
                                isLoading={isFetchingSlow}
                            >
                                <Attribute
                                    field={f}
                                    isLoading={isFetchingSlow}
                                    isEditingLayout={isEditing}
                                />
                            </AttributeContextProvider>
                        ))}
                    </Box>
                ))}
            </Box>
        </Box>
    )
}

type FieldsWidgetAdminControlsProps = {}

export const FieldsWidgetAdminControls: WidgetAdminControlsComponent<
    FieldsWidgetType,
    FieldsWidgetAdminControlsProps
> = ({ widget, onChange, ...props }) => {
    return (
        <DetailViewTabControl>
            <DetailViewTabControlItem value="content" label="Content">
                <FieldsContentControls widget={widget} onChange={onChange} {...props} />
            </DetailViewTabControlItem>
            <DetailViewTabControlItem value="style" label="Style">
                <FieldsStyleControls widget={widget} onChange={onChange} {...props} />
            </DetailViewTabControlItem>
        </DetailViewTabControl>
    )
}

type FieldItem = Item & {
    fieldId: string
    fieldName: string
    conditions: Filter[]
    fieldLabel: string
}

type FieldsContentControlsProps = {}

const FieldsContentControls: WidgetAdminControlsComponent<
    FieldsWidgetType,
    FieldsContentControlsProps
> = ({ widget, onChange, openDetailPane }) => {
    const { object, fields } = useLayoutEditorContext()

    const showAllFields = widget.attrs.showAllFields ?? true

    const onShowAllFieldsChange = useCallback(
        (value: 'showAllFields' | 'specific') => {
            onChange((attrs) => {
                attrs.set('showAllFields', value === 'showAllFields')
            })
        },
        [onChange]
    )

    const addFieldTargetRef = useRef<HTMLButtonElement>(null)

    const [isAddFieldOpen, setIsAddFieldOpen] = useState(false)

    const onAddFieldClick = useCallback(
        (e: React.MouseEvent<HTMLButtonElement>) => {
            setIsAddFieldOpen((prev) => !prev)
            e.preventDefault()
            e.stopPropagation()
        },
        [setIsAddFieldOpen]
    )

    const onAddFieldClose = useCallback(() => {
        setIsAddFieldOpen(false)
    }, [setIsAddFieldOpen])

    const { displayedFields } = useFieldsWidgetFields(widget)

    const onFieldUpdate = useCallback(
        (items: FieldItem[]) => {
            onChange((attrs) => {
                const newFields: FieldsWidgetTypeField[] = items.map((i) => {
                    return {
                        sid: i.fieldId,
                        apiName: i.fieldName,
                        conditions: i.conditions ?? [],
                    }
                })

                attrs.set('fields', toYType(newFields))
            })
        },
        [onChange]
    )

    const onFieldEdit = useCallback(
        (item: FieldItem) => {
            openDetailPane({
                component: (props) => <FieldDetailsControl {...props} fieldSid={item.fieldId} />,
                label: `Field: ${item.fieldLabel}`,
            })
        },
        [openDetailPane]
    )

    const conditionalVisibilityFilters = useMemo(() => {
        return displayedFields.reduce(
            (acc, f) => {
                acc[f.sid] = f.conditions

                return acc
            },
            {} as Record<string, Filter[]>
        )
    }, [displayedFields])

    const onChangeConditionalVisibilityFilters = useCallback(
        (filters: Record<string, Filter[]>) => {
            onChange((attrs) => {
                const fields = attrs.get('fields')
                if (!fields) return

                for (const field of fields) {
                    const changedFilters = filters[field.get('sid')]
                    if (changedFilters) {
                        field.set('conditions', toYType(changedFilters))
                    }
                }
            })
        },
        [onChange]
    )

    if (!object) return null

    return (
        <Box px="l" flex column height="full">
            <Box flex center justifyContent="space-between">
                <Headline size="xs" color="text">
                    Fields
                </Headline>
                <Button
                    variant="primary"
                    size="s"
                    startIcon={{ name: 'Plus' }}
                    ref={addFieldTargetRef}
                    onClick={onAddFieldClick}
                >
                    Add new
                </Button>
            </Box>
            <Box role="group" flexDirection="column" flex gap="m" mt="s" mb="l">
                <RadioGroup
                    value={showAllFields ? 'showAllFields' : 'specific'}
                    onValueChange={onShowAllFieldsChange}
                    style={{
                        gap: theme.space.xs,
                    }}
                >
                    <RadioButton value="showAllFields">
                        <Body size="m">Show all fields</Body>
                    </RadioButton>
                    <RadioButton value="specific">
                        <Body size="m">Choose fields to display</Body>
                    </RadioButton>
                </RadioGroup>
            </Box>
            <FieldListEditor
                object={object}
                fields={fields}
                selectedItems={displayedFields.map((f) => ({
                    fieldId: f.field._sid,
                    fieldName: f.field.api_name,
                    fieldLabel: f.field.label,
                    objectId: f.field.object_id,
                    readOnly: false,
                    required: false,
                    conditions: f.conditions,
                }))}
                allowEditFields={true}
                onUpdate={onFieldUpdate}
                onEdit={onFieldEdit as any}
                allFieldsModeEnabled={showAllFields}
                disallowSections={true}
                autoHideEditButton={true}
                allowEditSections={false}
                hideTopSection={true}
                showConditionalVisibility={true}
                conditionalVisibilityFilters={conditionalVisibilityFilters}
                onConditionalVisibilityFiltersChange={onChangeConditionalVisibilityFilters}
            />
            <FieldEditorPopover
                objectId={object?._sid}
                open={isAddFieldOpen}
                target={addFieldTargetRef.current ?? undefined}
                onSuccess={onAddFieldClose}
                onCancel={onAddFieldClose}
                onClose={onAddFieldClose}
                placement="bottom-start"
            />
        </Box>
    )
}

const DEBOUNCE_RATE = 300 // ms

type FieldDetailsControlProps = {
    fieldSid: string
}

const FieldDetailsControl: WidgetAdminControlsComponent<
    FieldsWidgetType,
    FieldDetailsControlProps
> = ({ widget, onChange, fieldSid }) => {
    const { fields } = useLayoutEditorContext()

    const field = fields.find((f) => f._sid === fieldSid)

    const widgetField: FieldsWidgetTypeField = widget.attrs.fields.find(
        (f) => f.sid === fieldSid
    ) ?? {
        sid: fieldSid,
        apiName: field?.api_name ?? '',
        conditions: [],
    }

    const setFieldAttribute = (attrs: Y.Map<any>, key: string, value: any) => {
        const fields = attrs.get('fields')

        const field = yUtils.arrayFind<typeof attrs>(fields, (f) => f.get('sid') === fieldSid)
        if (!!field) {
            field.set(key, value)
        } else if (typeof value !== 'undefined') {
            fields.push([
                toYType({
                    ...widgetField,
                    [key]: value,
                }),
            ])
        }
    }

    const debouncedOnChange = useDebounce(onChange, DEBOUNCE_RATE) as typeof onChange

    const defaultTitle = field?.label || ''
    const [localTitle, setLocalTitle] = useState(widgetField.label || '')
    const onChangeLabel = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value || ''

        setLocalTitle(value)
        debouncedOnChange((attrs) => {
            setFieldAttribute(attrs, 'label', value)
        })
    }

    const defaultIcon = field ? getFieldIcon(field) : undefined
    const icon = widgetField?.icon
    const onChangeIcon = (value?: IconValue) => {
        onChange((attrs) => {
            const newValue = value ? toYType(value as FieldsWidgetTypeField['icon']) : undefined
            setFieldAttribute(attrs, 'icon', newValue)
        })
    }

    const canBeRequired = field ? canFieldBeRequired(field) : false
    const isRequired = widgetField.isRequired ?? false
    const onChangeIsRequired = (value: boolean) => {
        onChange((attrs) => {
            setFieldAttribute(attrs, 'isRequired', value)
        })
    }

    const canBeTruncated = field ? canFieldBeTruncated(field) : false
    const disableTruncation = widgetField.disableTruncation ?? false
    const onChangeDisableTruncation = (value: boolean) => {
        onChange((attrs) => {
            setFieldAttribute(attrs, 'disableTruncation', value)
        })
    }

    if (!field) return <div />

    return (
        <Box px="l" flex column gap="3xl" height="full" overflowY="auto">
            <Box flex alignItems="flex-start" gap="m">
                <Box noShrink>
                    <Field
                        label="Icon"
                        helperText={!!defaultIcon ? <FieldIcon value={defaultIcon} /> : undefined}
                    >
                        <IconPickerDropdown value={icon} onChange={onChangeIcon} />
                    </Field>
                </Box>
                <Box grow>
                    <Input
                        label="Label"
                        name="label"
                        size="m"
                        autoFocus
                        helperText={defaultTitle}
                        value={localTitle}
                        onChange={onChangeLabel}
                    />
                </Box>
            </Box>
            <Box flex column gap="l">
                {canBeRequired && (
                    <Field
                        htmlFor="isRequired"
                        label="Required"
                        rightSlotContent={
                            <Toggle
                                id="isRequired"
                                checked={isRequired}
                                onCheckedChange={onChangeIsRequired}
                            />
                        }
                    />
                )}
                {canBeTruncated && (
                    <Field
                        htmlFor="disableTruncation"
                        label="Disable truncation"
                        rightSlotContent={
                            <Toggle
                                id="disableTruncation"
                                checked={disableTruncation}
                                onCheckedChange={onChangeDisableTruncation}
                            />
                        }
                    />
                )}
            </Box>
        </Box>
    )
}

type FieldsStyleControlsProps = {}

const FieldsStyleControls: WidgetAdminControlsComponent<
    FieldsWidgetType,
    FieldsStyleControlsProps
> = ({ widget, onChange }) => {
    const labelPlacement = widget.attrs.labelPlacement || 'top'

    const onChangeLabelPlacement = useCallback(
        (value: string) => {
            onChange((attrs) => {
                attrs.set('labelPlacement', value)
            })
        },
        [onChange]
    )

    const showFieldIcon = widget.attrs.showFieldIcon ?? false

    const onChangeShowFieldIcon = useCallback(
        (value: boolean) => {
            onChange((attrs) => {
                attrs.set('showFieldIcon', value)
            })
        },
        [onChange]
    )

    return (
        <Box px="l" flex column gap="3xl">
            <Field
                label="Label placement"
                startIcon={{
                    name: 'Text',
                }}
            >
                <RadioCardGroup
                    value={labelPlacement}
                    onValueChange={onChangeLabelPlacement}
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(3, auto-fill)',
                        gap: theme.space.m,
                    }}
                >
                    <RadioCard value="hide" icon={{ name: 'EyeOff' }}>
                        Hide
                    </RadioCard>
                    <RadioCard value="top" icon={{ name: 'ArrowUpFromLine' }}>
                        Top
                    </RadioCard>
                    <RadioCard value="left" icon={{ name: 'ArrowLeftFromLine' }}>
                        Left
                    </RadioCard>
                </RadioCardGroup>
            </Field>
            <Field
                htmlFor="showFieldIcon"
                label="Show field icon"
                startIcon={{ name: 'CheckSquare2' }}
                rightSlotContent={
                    <Toggle
                        id="showFieldIcon"
                        checked={showFieldIcon}
                        onCheckedChange={onChangeShowFieldIcon}
                    />
                }
            />
        </Box>
    )
}
