import React from 'react'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'

import { useBaseAttributeState } from './hooks/useBaseAttributeState'
import { PrimaryHighlightsAttributeProps } from './types'

type BaseAttributeProps = React.ComponentPropsWithoutRef<typeof Box> &
    PrimaryHighlightsAttributeProps & {
        isEmpty?: boolean
    }

export const BaseAttribute = React.forwardRef<HTMLDivElement, BaseAttributeProps>(
    function BaseAttribute({ field, isEmpty, children, style, ...props }, ref) {
        const { fieldIcon, showFieldIcon } = useBaseAttributeState({
            field,
        })

        if (isEmpty) return null

        return (
            <Box
                ref={ref}
                flex
                center
                fontFamily="body"
                fontSize={{
                    mobile: 'bodyM',
                    tablet: 'bodyL',
                }}
                fontWeight="bodyMedium"
                lineHeight={{
                    mobile: 'bodyM',
                    tablet: 'bodyL',
                }}
                color="textWeaker"
                trim
                gap={{
                    mobile: 'xs',
                    tablet: 'm',
                }}
                style={{
                    height: '28px',
                    ...style,
                }}
                {...props}
            >
                {showFieldIcon && !!fieldIcon && (
                    <Icon {...fieldIcon} size="m" color="icon" noShrink />
                )}
                {children}
            </Box>
        )
    }
)
