import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { useAppUsersForAdmin } from 'data/hooks/users/useAppUsersForAdmin'
import { fetchWithAuth } from 'data/utils/fetchWithAuth'
import { SelectConnectionInput } from 'features/Agents/configuration/connections/SelectConnectionInput'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Field } from 'ui/components/Field'
import { Input } from 'ui/components/Input'
import {
    Modal,
    ModalCloseTrigger,
    ModalContent,
    ModalContentInner,
    ModalFooter,
    ModalFooterButtonGroup,
    ModalHeader,
    ModalTrigger,
} from 'ui/components/Modal'
import { Select, SelectOption } from 'ui/components/Select'
import { Body } from 'ui/components/Text'
import { Textarea } from 'ui/components/Textarea'
import { useToast } from 'ui/components/Toast'

import { TriggerConfigProps } from './types'

type RerunMessagesModalProps = {
    agentSid: string
    connectionId: string
    triggerSid: string
}

function RerunMessagesModal({ agentSid, connectionId, triggerSid }: RerunMessagesModalProps) {
    const [messageIds, setMessageIds] = React.useState('')
    const [open, setOpen] = React.useState(false)
    const toast = useToast()

    const handleRunMessages = React.useCallback(async () => {
        try {
            await fetchWithAuth(`agents/${agentSid}/connections/${connectionId}/rerun_messages/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    trigger_sid: triggerSid,
                    message_ids: messageIds.split('\n'),
                }),
            })
            setOpen(false)
            toast({
                title: 'Messages re-run successfully',
                type: 'success',
            })
        } catch (error) {
            console.error(error)
            toast({
                title: 'Error re-running messages',
                type: 'error',
            })
        }
    }, [agentSid, connectionId, messageIds, triggerSid, toast])

    return (
        <Modal open={open} onOpenChange={setOpen}>
            <ModalTrigger asChild>
                <Button variant="secondary">Re-run Messages</Button>
            </ModalTrigger>
            <ModalContent>
                <ModalHeader
                    title="Re-run Messages"
                    subtitle="Paste message IDs to re-run (one per line)"
                    showCloseButton={true}
                />
                <ModalContentInner>
                    <Textarea
                        placeholder="Enter message IDs here..."
                        rows={10}
                        value={messageIds}
                        onChange={(e) => setMessageIds(e.target.value)}
                    />
                </ModalContentInner>
                <ModalFooter>
                    <ModalFooterButtonGroup>
                        <Button size="s" variant="primary" onClick={handleRunMessages}>
                            Run Messages
                        </Button>
                        <ModalCloseTrigger asChild>
                            <Button variant="secondary" size="s">
                                Cancel
                            </Button>
                        </ModalCloseTrigger>
                    </ModalFooterButtonGroup>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}

export type EmailReceivedTriggerConfigType = {
    connection_id: string
    filters?: {
        from_address?: string
        to_address?: string
        subject_contains?: string
    }
    user_sid: string
}

export function EmailReceivedTriggerConfig({
    agentSid,
    triggerSid,
    config,
    onChange,
}: TriggerConfigProps<EmailReceivedTriggerConfigType>) {
    const { data: users } = useAppUsersForAdmin(true)

    const methods = useForm({
        defaultValues: {
            connection_id: config.connection_id || '',
        },
    })

    // Watch for changes to connection_id and propagate to parent
    React.useEffect(() => {
        const subscription = methods.watch((value) => {
            if (value.connection_id !== config.connection_id) {
                onChange({
                    ...config,
                    connection_id: value.connection_id || '',
                })
            }
        })
        return () => subscription.unsubscribe()
    }, [config, methods, onChange])

    return (
        <Box flex column gap="m">
            <FormProvider {...methods}>
                <SelectConnectionInput
                    name="connection_id"
                    label="Email Connection"
                    agentSid={agentSid}
                />
            </FormProvider>
            <Field label="Filters (optional)" width="5xl" shrink mt="m">
                <Box display="grid" style={{ gridTemplateColumns: 'auto 1fr' }} gap="m">
                    <Box display="flex" alignItems="center">
                        <Body style={{ verticalAlign: 'middle' }} size="s">
                            From Address
                        </Body>
                    </Box>
                    <Input
                        name="from_address"
                        value={config.filters?.from_address}
                        onChange={(e) =>
                            onChange({
                                ...config,
                                filters: {
                                    ...config.filters,
                                    from_address: e.target.value,
                                },
                            })
                        }
                    />
                    <Box display="flex" alignItems="center">
                        <Body style={{ verticalAlign: 'middle' }} size="s">
                            To Address
                        </Body>
                    </Box>
                    <Input
                        name="to_address"
                        value={config.filters?.to_address}
                        onChange={(e) =>
                            onChange({
                                ...config,
                                filters: {
                                    ...config.filters,
                                    to_address: e.target.value,
                                },
                            })
                        }
                    />
                    <Box display="flex" alignItems="center">
                        <Body style={{ verticalAlign: 'middle' }} size="s">
                            Subject
                        </Body>
                    </Box>
                    <Input
                        name="subject"
                        value={config.filters?.subject_contains}
                        onChange={(e) =>
                            onChange({
                                ...config,
                                filters: {
                                    ...config.filters,
                                    subject_contains: e.target.value,
                                },
                            })
                        }
                    />
                </Box>
            </Field>
            <Field label="Run as User" width="5xl" shrink>
                <Select
                    value={config.user_sid}
                    onChange={(value) =>
                        onChange({
                            ...config,
                            user_sid: value,
                        })
                    }
                >
                    {users?.map((user: UserDto) => (
                        <SelectOption key={user._sid} value={user._sid} label={user.name} />
                    ))}
                </Select>
            </Field>
            {triggerSid && (
                <Field label="Actions" width="5xl" shrink>
                    <RerunMessagesModal
                        agentSid={agentSid}
                        connectionId={config.connection_id}
                        triggerSid={triggerSid}
                    />
                </Field>
            )}
        </Box>
    )
}
