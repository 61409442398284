import React from 'react'

import { UrlAttributeDisplay } from 'features/views/attributes/UrlAttributeDisplay'

import { BaseAttribute } from './BaseAttribute'
import { MAX_LENGTH, MAX_LINES } from './constants'
import { ThreadViewAttributeComponent } from './types'

const MAX_OVERFLOW_LENGTH = 300

type UrlAttributeProps = {}

export const UrlAttribute: ThreadViewAttributeComponent<string, UrlAttributeProps> = ({
    value,
    attribute,
    isLoading,
    ...props
}) => {
    const { field, rawOptions, type } = attribute

    const isTitle = type === 'title'
    const isMessage = type === 'message'
    const isEyebrow = type === 'eyebrow'
    const isFooter = type === 'footer'
    const isContent = type === 'content'
    const isEmpty = !isLoading && !value

    let maxLength: number | undefined = MAX_LENGTH
    if (isMessage || isTitle) {
        maxLength = undefined
    }

    let maxLines: number | undefined = MAX_LINES
    if (isMessage) {
        maxLines = undefined
    }

    return (
        <BaseAttribute attribute={attribute} isEmpty={isEmpty} isLoading={isLoading} {...props}>
            <UrlAttributeDisplay
                alwaysShowOverflowTooltip={!isMessage}
                value={value}
                size={{
                    mobile: isTitle || isMessage ? 'm' : 's',
                    tablet: isTitle || isMessage || isContent ? 'm' : 's',
                }}
                showOverflowTooltip={{
                    mobile: false,
                    tablet: true,
                }}
                buttonTitle={rawOptions.buttonTitle as string}
                displayAsImage={rawOptions.displayAsImage as boolean}
                imageAltText={rawOptions.imageAltText as string}
                openInNewTab={rawOptions.openInNewTab as boolean}
                field={field}
                maxLength={maxLength}
                maxLines={maxLines}
                maxOverflowLength={MAX_OVERFLOW_LENGTH}
                isLoading={isLoading}
                trim
                py="3xs"
                style={{
                    display: isEyebrow || isFooter ? 'block' : undefined,
                }}
            />
        </BaseAttribute>
    )
}
