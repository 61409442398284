import React from 'react'

import { ListViewControlFieldInput } from 'features/views/ListView/ListViewControlFieldInput'
import { ListViewControlItem } from 'features/views/ListView/ListViewControlItem'

import { Box } from 'ui/components/Box'
import { RadioCard, RadioCardGroup } from 'ui/components/Radio'

type ThreadViewMessageFooterControlsProps = {
    setConfig: (config: Partial<ListViewOptions>) => void
    config: ListViewOptions
    fields: FieldDto[]
    objectSid: string
}

export const ThreadViewMessageFooterControls: React.FC<ThreadViewMessageFooterControlsProps> = ({
    fields,
    config,
    setConfig,
    objectSid,
}) => {
    return (
        <ListViewControlItem label="Message footer" icon="PanelBottom">
            <ThreadViewMessageFooterGenericControls
                maxWidth="full"
                minWidth="300px"
                config={config}
                setConfig={setConfig}
                fields={fields}
                objectSid={objectSid}
            />
        </ListViewControlItem>
    )
}

type ThreadViewMessageFooterGenericControlsProps = React.ComponentPropsWithoutRef<typeof Box> &
    ThreadViewMessageFooterControlsProps

export const ThreadViewMessageFooterGenericControls: React.FC<
    ThreadViewMessageFooterGenericControlsProps
> = ({ config, setConfig, fields, objectSid, ...props }) => {
    return (
        <Box flex flexDirection="column" gap="xl" {...props}>
            <RadioCardGroup
                value={!!config.threadMessageFooter?.hide ? 'true' : 'false'}
                onValueChange={(value) => {
                    setConfig({
                        threadMessageFooter: {
                            ...config.threadMessageFooter,
                            hide: value === 'true',
                        },
                    })
                }}
            >
                <RadioCard value="true" icon={{ name: 'EyeOff' }}>
                    Hide
                </RadioCard>
                <RadioCard value="false" icon={{ name: 'PanelBottom' }}>
                    Show
                </RadioCard>
            </RadioCardGroup>
            <ListViewControlFieldInput
                objectSid={objectSid}
                label="Left area"
                fields={fields}
                value={config.threadMessageFooter?.leftFieldSid}
                displayOptions={config.threadMessageFooter?.leftFieldDisplayOptions}
                onChange={(value) => {
                    setConfig({
                        threadMessageFooter: {
                            ...config.threadMessageFooter,
                            leftFieldSid: value,
                        },
                    })
                }}
                onDisplayOptionsChange={(value) => {
                    setConfig({
                        threadMessageFooter: {
                            ...config.threadMessageFooter,
                            leftFieldDisplayOptions: value,
                        },
                    })
                }}
            />
            <ListViewControlFieldInput
                objectSid={objectSid}
                label="Right area"
                fields={fields}
                value={config.threadMessageFooter?.rightFieldSid}
                displayOptions={config.threadMessageFooter?.rightFieldDisplayOptions}
                onChange={(value) => {
                    setConfig({
                        threadMessageFooter: {
                            ...config.threadMessageFooter,
                            rightFieldSid: value,
                        },
                    })
                }}
                onDisplayOptionsChange={(value) => {
                    setConfig({
                        threadMessageFooter: {
                            ...config.threadMessageFooter,
                            rightFieldDisplayOptions: value,
                        },
                    })
                }}
            />
        </Box>
    )
}
