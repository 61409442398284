import React, { useCallback } from 'react'

import { deleteObject } from 'data/hooks/objects/objectOperations'
import { DeleteExternalTableButton } from 'features/admin/settings/object/DeleteExternalTableButton'
import { DeleteNativeTableButton } from 'features/admin/settings/object/DeleteNativeTableButton'
import {
    getIsStackerNativeObject,
    getIsStackerSharedObject,
    getIsStackerSharedObjectCopy,
} from 'features/admin/stackerNativeObjectUtils'
import { Divider, ExpandSection, Section } from 'legacy/v1/ui'

import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

import { Body } from 'ui/components/Text'

type DeleteTableSettingProps = {
    object: ObjectDto
    openErrorToast: (operation?: 'update' | 'delete' | 'unshare') => void
}

/**
 * Delete table setting
 *
 * This component shows a delete/remove table button in following scenarios:
 *  - Table is native and not shared -> can delete the [native] table
 *  - Table is not native (external) and not shared -> can unimport the table
 *  - Table is shared and is in a consumer app -> can delete (essentially undoes the import)
 *
 * If the table is shared and the original copy, the table can't be deleted. It can only be unshared
 * This component does not handle unsharing and nothing is rendered for such a table.
 *
 * (If the table is a system object, nothing is rendered either as delete is not allowed)
 * @param object
 * @param openErrorToast
 * @constructor
 */
export const DeleteTableSetting: React.FC<DeleteTableSettingProps> = ({
    object,
    openErrorToast,
}) => {
    const isNativeTable = getIsStackerNativeObject(object)
    const isSharedTable = getIsStackerSharedObject(object)
    const isSharedTableCopy = getIsStackerSharedObjectCopy(object)
    const isSharedTableOriginal = isSharedTable && !isSharedTableCopy

    let heading = ''
    let text = ''
    if (isSharedTableCopy) {
        heading = 'Remove shared table'
        text = 'Remove shared table from this app.'
    } else if (isNativeTable) {
        heading = 'Delete table'
        text = 'Remove this table and all associated data.'
    } else {
        heading = 'Delete synced table'
        text = 'Delete synced table and all associated data.'
    }

    if (isSharedTableOriginal || object?.system_object_type) {
        return null
    }

    return (
        <>
            <Divider />
            <ExpandSection
                heading={heading}
                text={text}
                helpUrl=""
                helpText=""
                classes={{
                    heading:
                        ONBOARDING_CLASSES.APP_SETTINGS_DATA_CONNECTION.SETTINGS_ITEM.DELETE_OBJECT,
                }}
            >
                {/* @ts-expect-error */}
                <Section noPadding noMargin>
                    <DeleteTableSettingContent object={object} openErrorToast={openErrorToast} />
                </Section>
            </ExpandSection>
        </>
    )
}

type DeleteTableSettingContentProps = {
    object: ObjectDto
    openErrorToast: (operation?: 'update' | 'delete' | 'unshare') => void
}

export const DeleteTableSettingContent: React.FC<DeleteTableSettingContentProps> = ({
    object,
    openErrorToast,
}) => {
    const onDeleteObject = useCallback(
        async (objectSid) => {
            try {
                await deleteObject(objectSid)
            } catch {
                openErrorToast('delete')
            }
        },
        [openErrorToast]
    )

    const isNativeTable = getIsStackerNativeObject(object)
    const isSharedTableCopy = getIsStackerSharedObjectCopy(object)
    const isProtectedByGrant = object?.connection_options?.is_protected_by_grant
    const showNativeDelete = !isProtectedByGrant && (isNativeTable || isSharedTableCopy)

    return (
        <>
            {isProtectedByGrant ? (
                <Body size="s" color="textWeaker">
                    This table is required in user table group. Please first remove associated table
                    groups if you wish to delete.
                </Body>
            ) : showNativeDelete ? (
                <DeleteNativeTableButton
                    object={object}
                    onDeleteObject={onDeleteObject}
                    isSharedTableCopy={isSharedTableCopy}
                />
            ) : (
                <DeleteExternalTableButton object={object} />
            )}
        </>
    )
}
