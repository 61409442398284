import { useMemo } from 'react'

import { useThreadViewContext } from 'features/views/ListView/ThreadView/hooks/useThreadViewContext'

export function useThreadViewPaginationState() {
    const { currentPageIndex, setCurrentPageIndex, pageSize, recordCount } = useThreadViewContext()

    const hasMoreThanOnePage = recordCount > pageSize
    const showPagination = hasMoreThanOnePage

    return useMemo(
        () => ({
            pageIndex: currentPageIndex,
            onChangePageIndex: setCurrentPageIndex,
            pageSize,
            totalItemsCount: recordCount,
            showPagination,
        }),
        [currentPageIndex, setCurrentPageIndex, pageSize, recordCount, showPagination]
    )
}
