import React from 'react'

import { useAppUsersForAdmin } from 'data/hooks/users/useAppUsersForAdmin'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Field } from 'ui/components/Field'
import { Select, SelectOption } from 'ui/components/Select'
import { useToast } from 'ui/components/Toast'

import { TriggerConfigProps } from './types'

export type ExternalChatbotTriggerConfigType = {
    status: 'enabled' | 'disabled' | 'development'
    user_sid: string
}

const SCRIPT_TEMPLATE = `
  <script>
    (function (w, d, s, o, f, js, fjs) {
      w['StackerChatBot'] = o; w[o] = w[o] || function (m) { console.log("got here", w[o]); (w[o].queue = w[o].queue || []).push(m) };
      js = d.createElement(s), fjs = d.getElementsByTagName(s)[0];
      js.id = o; js.src = f; js.async = 1; fjs.parentNode.insertBefore(js, fjs);
      const l = d.createElement('link');
      l.rel = 'stylesheet'; l.type = 'text/css'; l.href = 'https://resources.go.stackerhq.com/scripts/chatbot.css'; d.head.appendChild(l);
      root = d.createElement('div')
      root.id = 'StackerChatBot-Root'; root.style.zIndex = 100000; root.style.position = "relative";
      d.body.appendChild(root);
    }(window, document, 'script', 'StackerChat', 'https://resources.go.stackerhq.com/scripts/chatbot.js'));
    StackerChat({ 'type': 'init', 'config': { "name": "StackerChat", "targetElementId": "StackerChatBot-Root", "chatBubble": { "side": "left", "xOffset": 40, "yOffset": 20, "bg": "#000", "fg": "#fff" }, "agent": { "id": "{agent_sid}", "triggerId": "{trigger_sid}", "name": "{chatbot_name}", "avatar": "", "welcomeMessage": "How can I help you today?" } } });
  </script>
`

export function ExternalChatbotTriggerConfig({
    agentSid,
    triggerSid,
    instructionsSid,
    config,
    onChange,
    name,
}: TriggerConfigProps<ExternalChatbotTriggerConfigType>) {
    const { data: users } = useAppUsersForAdmin(true)
    const toast = useToast()
    function copyScript() {
        const script = SCRIPT_TEMPLATE.replace('{agent_sid}', agentSid)
            .replace('{trigger_sid}', triggerSid || '')
            .replace('{chatbot_name}', name || '')
        navigator.clipboard.writeText(script)
        toast({ title: 'Script copied to clipboard', type: 'success' })
    }
    return (
        <Box flex column gap="m">
            <Field label="Status">
                <Select
                    value={config.status || 'development'}
                    onChange={(status: string) =>
                        onChange({
                            ...config,
                            status: status as 'enabled' | 'disabled' | 'development',
                        })
                    }
                >
                    <SelectOption value="enabled" label="Enabled" />
                    <SelectOption value="disabled" label="Disabled" />
                    <SelectOption value="development" label="Development" />
                </Select>
            </Field>
            <Field label="Run as User" width="5xl" shrink>
                <Select
                    value={config.user_sid}
                    onChange={(value) =>
                        onChange({
                            ...config,
                            user_sid: value,
                        })
                    }
                >
                    {users?.map((user: UserDto) => (
                        <SelectOption key={user._sid} value={user._sid} label={user.name} />
                    ))}
                </Select>
            </Field>
            <Field
                label="Embed Script"
                helperText={
                    !triggerSid || !instructionsSid
                        ? 'Save this instruction set after adding the trigger in order to see the embed script'
                        : 'Copy the script and paste into the <head> tag of your website.'
                }
            >
                <Button
                    startIcon={{ name: 'Copy' }}
                    variant="secondary"
                    size="s"
                    onClick={copyScript}
                    disabled={!instructionsSid || !triggerSid}
                >
                    Copy script
                </Button>
            </Field>
        </Box>
    )
}
