import React from 'react'

import { SIDEBAR_WIDGET_AREA_ID } from 'features/views/LayoutEditor/constants'
import { LayoutEditorWidgetArea } from 'features/views/LayoutEditor/LayoutEditorWidgetArea'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'

import { useDetailViewSidebarState } from './hooks/useDetailViewSidebarState'

import {
    DetailViewSidebarOverlayControlsStyles,
    DetailViewSidebarStyles,
} from './DetailViewLayout.css'

type DetailViewSidebarProps = React.ComponentPropsWithoutRef<typeof Box>

export const DetailViewSidebar: React.FC<DetailViewSidebarProps> = (props) => {
    const {
        isSticky,
        isEmpty,
        isSideOverlay,
        shouldHide,
        styleTop,
        isSideOverlayExpanded,
        toggleSideOverlay,
    } = useDetailViewSidebarState()

    if (shouldHide) return null

    return (
        <Box minWidth={0} maxWidth="full" {...props}>
            <LayoutEditorWidgetArea
                id={SIDEBAR_WIDGET_AREA_ID}
                as="aside"
                className={DetailViewSidebarStyles.styleFunction({
                    isSticky,
                    isEmpty,
                    isSideOverlay,
                    isSideOverlayExpanded,
                })}
                style={{
                    top: styleTop,
                }}
            />
            {isSideOverlay && (
                <Box
                    className={DetailViewSidebarOverlayControlsStyles.styleFunction({
                        isExpanded: isSideOverlayExpanded,
                    })}
                    onClick={toggleSideOverlay}
                    role="button"
                >
                    <Icon name="ChevronLeft" color="icon" size="2xl" />
                </Box>
            )}
        </Box>
    )
}
