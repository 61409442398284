import React from 'react'

import { LongTextAttributeDisplay } from 'features/views/attributes/LongTextAttributeDisplay'
import { LongTextValue } from 'features/views/attributes/LongTextValue'

import { Textarea } from 'ui/components/Textarea'

import { useAttributeContext } from './hooks/useAttributeContext'
import { useLongTextAttributeEditorState } from './hooks/useLongTextAttributeEditorState'
import { BaseAttribute } from './BaseAttribute'
import { FIELD_VALIDATION_REQUIRED_ERROR_MESSAGE } from './constants'
import { InlineValueWrapper } from './InlineValueWrapper'
import { FieldsWidgetAttributeComponent } from './types'

import { LongTextAttributeEditorStyle } from './LongTextAttribute.css'

const MAX_LINES = 3

type LongTextAttributeProps = {}

export const LongTextAttribute: FieldsWidgetAttributeComponent<LongTextAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const { value, isEditingValue, isEditable } = useAttributeContext<string>()
    const isEmpty = !isLoading && !value

    if (isEditingValue) {
        return (
            <LongTextAttributeEditor
                key="long-text-attribute"
                field={field}
                isLoading={isLoading}
                {...props}
            />
        )
    }

    const { disableTruncation } = field
    const children = (
        <LongTextAttributeDisplay
            value={value}
            field={field.field}
            isLoading={isLoading}
            size="m"
            style={{
                wordBreak: 'break-word',
                whiteSpace: 'pre-wrap',
            }}
        />
    )

    return (
        <BaseAttribute
            key="long-text-attribute"
            {...props}
            field={field}
            isEmpty={isEmpty}
            isLoading={isLoading}
            center={false}
            cursor={isEditable ? 'text' : undefined}
        >
            <InlineValueWrapper>
                {disableTruncation ? (
                    children
                ) : (
                    <LongTextValue textValue={value} maxLines={MAX_LINES}>
                        {children}
                    </LongTextValue>
                )}
            </InlineValueWrapper>
        </BaseAttribute>
    )
}

const LongTextAttributeEditor: FieldsWidgetAttributeComponent<LongTextAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const {
        value,
        onInputBlur,
        onInputChange,
        onInputKeyDown,
        onClearValue,
        editorRef,
        isRequired,
    } = useLongTextAttributeEditorState()

    const isEmpty = !isLoading && !value

    return (
        <BaseAttribute
            {...props}
            field={field}
            isEmpty={isEmpty}
            isLoading={isLoading}
            isClearValueButtonDisabled={isEmpty}
            onClearValue={onClearValue}
            validationError={
                isRequired && isEmpty ? FIELD_VALIDATION_REQUIRED_ERROR_MESSAGE : undefined
            }
        >
            <Textarea
                richText={false}
                ref={editorRef as any}
                size="m"
                autoSize
                onBlur={onInputBlur}
                onChange={onInputChange}
                onKeyDown={onInputKeyDown}
                value={value}
                className={LongTextAttributeEditorStyle}
            />
        </BaseAttribute>
    )
}
