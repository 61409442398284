import React, { useState } from 'react'

import { AuthOptions } from '@nangohq/frontend'

import { INTEGRATION_ID_TO_NAME, TRANSLATIONS } from 'features/DataConnections/constants'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Input } from 'ui/components/Input'
import { ModalFooter, ModalHeader } from 'ui/components/Modal'

import { ConnectionSetupProps } from './types'

export const GorgiasInputs: React.FC<ConnectionSetupProps> = ({
    integrationId,
    handleConfirmed,
    handleClose,
}) => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [subdomain, setSubdomain] = useState('')

    const integrationName = INTEGRATION_ID_TO_NAME[integrationId]
    return (
        <Box>
            <ModalHeader title={`Connect ${integrationName}`} showCloseButton={true} />
            <Box mx="3xl" my="xl">
                <Input
                    width="full"
                    variant="borderless"
                    label={TRANSLATIONS[integrationId].Username}
                    placeholder={`Your ${integrationName} ${TRANSLATIONS[integrationId].Username.toLowerCase()}`}
                    fontSize="bodyL"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    mb="m"
                />
                <Input
                    width="full"
                    variant="borderless"
                    label={TRANSLATIONS[integrationId].Password}
                    placeholder={`Your ${integrationName} ${TRANSLATIONS[integrationId].Password.toLowerCase()}`}
                    fontSize="bodyL"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    mb="m"
                />
                <Input
                    width="full"
                    variant="borderless"
                    label="Subdomain"
                    placeholder={`Your ${integrationName} subdomain`}
                    fontSize="bodyL"
                    value={subdomain}
                    onChange={(e) => setSubdomain(e.target.value)}
                    mb="m"
                />
            </Box>

            <ModalFooter flex flexDirection="row" style={{ justifyContent: 'flex-end' }}>
                <Button size="l" variant="ghost" onClick={handleClose} mr="xs">
                    Cancel
                </Button>
                <Button
                    size="l"
                    variant="primary"
                    onClick={() => {
                        const nangoAuthOptions: AuthOptions = {
                            credentials: {
                                username,
                                password,
                            },
                            params: {
                                subdomain,
                            },
                        }
                        handleConfirmed(nangoAuthOptions)
                    }}
                >
                    Connect
                </Button>
            </ModalFooter>
        </Box>
    )
}
