import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import { useCreateAgentSkill, useUpdateAgentSkill } from 'data/hooks/agents/skills'
import { AgentSkill } from 'data/hooks/agents/types'

type EditSkillFormWrapperProps = {
    agentId: string
    skill?: AgentSkill
    onSuccess?: () => void
    setError?: (error: string) => void
    children: React.ReactNode
}

export function EditSkillFormWrapper({
    agentId,
    skill,
    onSuccess,
    setError,
    children,
}: EditSkillFormWrapperProps) {
    const createSkill = useCreateAgentSkill(agentId)
    const updateSkill = useUpdateAgentSkill(agentId)

    const methods = useForm<Partial<AgentSkill>>({
        defaultValues: skill || {
            name: '',
            description: '',
            prompt: '',
            available_tools: [],
            available_skills: [],
            is_system_skill: false,
            options: {
                icon: { name: 'Zap', type: 'lucide' },
                color: 'sky',
            },
        },
    })

    const handleSubmit = async (data: Partial<AgentSkill>, e?: React.BaseSyntheticEvent) => {
        e?.preventDefault()
        e?.stopPropagation()
        try {
            setError?.('')
            if (skill?._sid) {
                await updateSkill.mutateAsync({
                    id: skill._sid,
                    patch: { ...data },
                })
            } else {
                await createSkill.mutateAsync({
                    ...data,
                    agent_id: agentId,
                })
            }
            onSuccess?.()
        } catch (error) {
            console.error('Failed to save skill:', error)
            setError?.('Failed to save skill')
        }
    }

    return (
        <FormProvider {...methods}>
            <form
                style={{ display: 'flex', flexDirection: 'column', flexGrow: 1, height: '100%' }}
                onSubmit={methods.handleSubmit(handleSubmit)}
            >
                {children}
            </form>
        </FormProvider>
    )
}
