import { useCallback } from 'react'

import { useActionButton } from 'features/actions/hooks/useActionButton'
import { useDetailViewContext } from 'features/views/DetailView/hooks/useDetailViewContext'

type UseDetailViewHeaderActionButtonStateProps = {
    action: ActionDto
}

export function useDetailViewHeaderActionButtonState({
    action,
}: UseDetailViewHeaderActionButtonStateProps) {
    const { recordManager, requestIncludedFields } = useDetailViewContext()!

    const record = recordManager.record

    const { executeAction } = useActionButton({
        action,
        recordId: record?._sid!,
        record: record!,
        includeFields: requestIncludedFields,
    })

    const handleClick = useCallback(
        (e?: React.MouseEvent<HTMLElement>) => {
            e?.stopPropagation()
            e?.preventDefault()

            executeAction()
        },
        [executeAction]
    )

    return {
        handleClick,
    }
}
