import React from 'react'

import styled from '@emotion/styled'

import { useExtDataIntegrations } from 'data/hooks/dataConnections/useExtDataIntegrations'
import { INTEGRATIONS_DESCRIPTION } from 'features/DataConnections/constants'

import { Box } from 'ui/components/Box'
import { ModalHeader } from 'ui/components/Modal'
import { Spinner } from 'ui/components/Spinner'
import { Body, Headline } from 'ui/components/Text'
import { theme } from 'ui/styling/Theme.css'

const IntegrationCard = styled(Box)`
    display: flex;
    flex-direction: column;
    cursor: pointer;
    padding: ${theme.space.xl};
    border-radius: ${theme.space.m};
    border: 1px solid ${theme.color.gray200};
    width: 240px;
    &:hover {
        background-color: ${theme.color.gray50};
    }
`
type IntegrationPickerProps = {
    onSelect: (integrationId: ExternalIntegrationId) => void
}

export const IntegrationPicker: React.FC<IntegrationPickerProps> = ({ onSelect }) => {
    const { data: integrationsData, isLoading: isLoadingExtDataIntegration } =
        useExtDataIntegrations()

    const dataSyncIntegrations = integrationsData?.integrations.filter(
        (integration) => integration.supports_data_sync
    )

    if (isLoadingExtDataIntegration) {
        return (
            <Box
                flex
                style={{ minHeight: '300px', alignItems: 'center', justifyContent: 'center' }}
            >
                <Spinner size="2xl" />
            </Box>
        )
    }

    return (
        <>
            <ModalHeader
                title="Where is your data stored?"
                showCloseButton={false}
                subtitle="Sync data from other tools"
            />
            <Box
                flex
                flexDirection="column"
                pl="3xl"
                pr="3xl"
                pb="3xl"
                width="800px"
                maxHeight="75vh"
                overflow="scroll"
            >
                <Box
                    pt="2xl"
                    style={{
                        display: 'grid',
                        columnGap: theme.space.xl,
                        rowGap: theme.space.xl,
                        gridTemplateColumns: 'repeat(3, 1fr)',
                    }}
                >
                    {dataSyncIntegrations?.map((integration) => (
                        <IntegrationCard
                            key={integration.id}
                            onClick={() => onSelect(integration.id)}
                        >
                            <Box
                                style={{
                                    borderRadius: '48px',
                                    height: '48px',
                                    width: '48px',
                                    padding: '8px',
                                    border: `1px solid ${theme.color.gray200}`,
                                }}
                            >
                                <img src={integration.image_url} alt={`${integration.name} logo`} />
                            </Box>
                            <Box pt="xl" flex flexDirection="column">
                                <Headline size="2xs">{integration.name}</Headline>
                                <Body>{INTEGRATIONS_DESCRIPTION[integration.id]}</Body>
                            </Box>
                        </IntegrationCard>
                    ))}
                </Box>
            </Box>
        </>
    )
}
