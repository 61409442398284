import {
    AUGMENTED_DATA_FIELD_TYPES,
    MAGIC_SYNTHETIC_FIELD_TYPES,
    SYNTHETIC_FIELD_TYPES,
} from 'data/utils/fieldDefinitions'
import {
    EditableFieldTypeDefinition,
    editableFieldTypeDefinitionList,
} from 'features/admin/fields/definitions/editableFieldTypeDefinitions'
import { getIsStackerNativeObject } from 'features/admin/stackerNativeObjectUtils'
import { getIsSymmetricRelationshipField, getIsSyntheticField } from 'utils/fieldUtils'

import { getCanModifySchema } from './schemaUtils'

export const unsupportedTypes: Partial<Record<DataConnectionType, FieldType[]>> = {
    native_tables: ['image'],
}

const getIsAllowedFieldConversion = (
    existingField: Partial<FieldDto>,
    fieldTemplate: EditableFieldTypeDefinition['field_template']
): boolean => {
    // only allow dynamic fields to be used with other dynamic fields and non-dynamic fields to be used with other
    // non-dynamic fields
    return getIsSyntheticFieldTemplate(fieldTemplate) == getIsSyntheticFieldTemplate(existingField)
}

export const getAvailableFieldTypes = ({
    connectionType,
    existingField,
}: {
    connectionType: DataConnectionType | undefined
    existingField?: Partial<FieldDto>
}): EditableFieldTypeDefinition[] => {
    const supportedTypes = connectionType
        ? editableFieldTypeDefinitionList.filter(
              (x) =>
                  !x.field_template.type ||
                  !unsupportedTypes[connectionType]?.includes(x.field_template.type)
          )
        : editableFieldTypeDefinitionList

    if (existingField) {
        return supportedTypes.filter((f) =>
            getIsAllowedFieldConversion(existingField, f.field_template)
        )
    } else if (connectionType !== 'native_tables') {
        return supportedTypes.filter((field) =>
            getIsFieldAvailableOutsideStackerTables(field.field_template)
        )
    } else {
        return supportedTypes
    }
}

const magicFieldTypeSet = new Set<string>(MAGIC_SYNTHETIC_FIELD_TYPES)

const syntheticFieldTypeSet = new Set<string>(SYNTHETIC_FIELD_TYPES)

const augmentedFieldTypeSet = new Set<string>(AUGMENTED_DATA_FIELD_TYPES)

export const getIsMagicFieldTemplate = (
    field: EditableFieldTypeDefinition['field_template']
): boolean => !!field.synthetic_field_type && magicFieldTypeSet.has(field.synthetic_field_type)

export const getIsSyntheticFieldTemplate = (
    field: EditableFieldTypeDefinition['field_template']
): boolean => !!field.synthetic_field_type && syntheticFieldTypeSet.has(field.synthetic_field_type)

export const getIsAugmentedDataFieldTemplate = (
    field: EditableFieldTypeDefinition['field_template']
): boolean => !!field.type && augmentedFieldTypeSet.has(field.type)

const getIsFieldAvailableOutsideStackerTables = (
    field: EditableFieldTypeDefinition['field_template']
): boolean => {
    const isUsableSyntheticField =
        getIsSyntheticFieldTemplate(field) &&
        (!getIsMagicFieldTemplate(field) || field.synthetic_field_type == 'record_id')
    const isUsableAugmentedDataField = getIsAugmentedDataFieldTemplate(field)
    return isUsableSyntheticField || isUsableAugmentedDataField
}

export const getIsFieldConfigurable = ({}: { field: FieldDto }): boolean => {
    // TODO: figure out the right logic when we bring back external data connectors
    return true
}

export const isFieldProtected = (field?: FieldDto): boolean => {
    return (
        !!field?.connection_options?.is_protected ||
        !!field?.connection_options?.is_protected_by_grant
    )
}

export const getCanDeleteField = ({
    field,
    object,
}: {
    field?: FieldDto
    object?: ObjectDto
}): boolean => {
    if (!getCanModifySchema(object)) return false

    const isNativeTableField = getIsStackerNativeObject(object)
    // If it's a new field, or it's an existing field that is dynamically generated
    const isStackerDynamicField = getIsSyntheticField(field ?? undefined)
    // Symmetric fields can only be removed from the source side of the relationship
    const isSymmetricRelationship = getIsSymmetricRelationshipField(field ?? undefined)
    return (
        !!field &&
        !field.is_foreign &&
        (isNativeTableField || isStackerDynamicField || field.is_stacker_augmented_field) &&
        !isSymmetricRelationship &&
        !isFieldProtected(field) &&
        !field.is_primary &&
        !field.connection_options.is_shadowed
    )
}

const getCanChangeFieldType = ({
    field,
    dataConnection,
}: {
    field?: FieldDto
    dataConnection?: DataConnectionDto
}): boolean => {
    // When the field is not protected, allow the field type to be changed if:
    // 1. We're not editing an existing field
    // 2. or we are editing an existing field and
    //    a) field is not protected
    //    b) and field is not foreign (i.e. points to a shared table that's not imported)
    //    c) and data connection is falsy (i.e. this is a field on a native object) and this isn't a dynamic field
    //        (which we don't support changing at this time)
    //        or this is an augmented field
    const doesConnectionAllowEditingFields = !dataConnection && !getIsSyntheticField(field)
    return (
        !field ||
        (!isFieldProtected(field) &&
            !field?.is_foreign &&
            (doesConnectionAllowEditingFields || field.is_stacker_augmented_field))
    )
}

export const getFieldEditionData = ({
    dataConnection,
    field,
}: {
    dataConnection?: DataConnectionDto
    field?: FieldDto
}): {
    allowedTypes: EditableFieldTypeDefinition[]
    canChangeFieldType: boolean
} => {
    const canChangeFieldType = getCanChangeFieldType({ field: field, dataConnection })

    const dataConnectionType = dataConnection?.type ?? 'native_tables'
    const allowedTypes = canChangeFieldType
        ? getAvailableFieldTypes({
              connectionType: dataConnectionType,
              existingField: field ?? undefined,
          })
        : editableFieldTypeDefinitionList
    return { allowedTypes, canChangeFieldType }
}

export const getCanChooseTableForLink = (
    // Ideally we should be using this next approach, but @storybook/react overwrites the native TS
    // utility type Parameters, so we need to keep the params in sync.
    // params: Parameters<typeof getFieldEditionData>
    params: {
        dataConnection?: DataConnectionDto
        field?: FieldDto
    }
): boolean => {
    const { allowedTypes, canChangeFieldType } = getFieldEditionData(params)
    return (
        canChangeFieldType &&
        !!allowedTypes.find(
            (type) => type.value === 'lookup' && !type.field_template.synthetic_field_type
        )
    )
}

export const getAreObjectRecordsEditable = (object: ObjectDto): boolean =>
    !object.connection_options.read_only || object.fields.some(isFieldAugmentedAndEditable)

export const isFieldAugmentedAndEditable = (field: FieldDto): boolean =>
    field.is_stacker_augmented_field && !field.is_read_only
