import React, { useState } from 'react'

import { Agent, AgentInstructions } from 'data/hooks/agents/types'
import { SaveAndDiscardFormButtons } from 'features/Agents/configuration/common/SaveAndDiscardFormButtons'

import { Box } from 'ui/components/Box'

import { EditAgentInstructionsForm } from './EditAgentInstructionsForm'
import { EditAgentInstructionsFormWrapper } from './EditAgentInstructionsFormWrapper'

type EditAgentInstructionsProps = {
    agent: Agent
    instructions?: AgentInstructions
    onSaved?: (result: AgentInstructions) => void
}

export const EditAgentInstructions: React.FC<EditAgentInstructionsProps> = ({
    agent,
    instructions,
    onSaved,
}) => {
    const [error, setError] = useState<string>('')

    return (
        <Box flex column width="full">
            <EditAgentInstructionsFormWrapper
                instructions={instructions}
                agentSid={agent._sid}
                onSuccess={onSaved}
                setError={setError}
            >
                <EditAgentInstructionsForm
                    agentSid={agent._sid}
                    instructionsSid={instructions?._sid}
                />

                <SaveAndDiscardFormButtons error={error} />
            </EditAgentInstructionsFormWrapper>
        </Box>
    )
}
