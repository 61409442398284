import { QueryKey, UseQueryOptions } from 'react-query'

import { getCurrentStackId } from 'app/GlobalStaticState'
import {
    queryClient,
    useCanRunStackScopedQueries,
    useDeleteItem,
    useQuery,
    useQueryKeyBuilder,
} from 'data/hooks/_helpers'
import { STACK_QUERY_CONFIG } from 'data/reactQueryCache'

import { AgentConversation } from './types' // You'll need to add this type

// Conversations
const CONVERSATIONS_LIST_NAME = 'useAgentConversations'
const get_conversations_endpoint = (agent_sid: string) => `agents/${agent_sid}/conversations/`

function useConversationsQueryKey() {
    return useQueryKeyBuilder(CONVERSATIONS_LIST_NAME, {
        includeAuthKeys: true,
        includeStackId: true,
    })
}

type ConversationsOptionsType = UseQueryOptions<
    AgentConversation[],
    unknown,
    AgentConversation[],
    QueryKey
>

export function useAgentConversations(agent_sid: string, options: ConversationsOptionsType = {}) {
    const enabled = useCanRunStackScopedQueries()
    const query_config = {
        ...(STACK_QUERY_CONFIG as ConversationsOptionsType),
        keepPreviousData: true,
        ...options,
        enabled,
    }
    return useQuery<AgentConversation[]>(
        useConversationsQueryKey(),
        get_conversations_endpoint(agent_sid),
        query_config
    )
}

export function useDeleteAgentConversation(agent_sid: string) {
    return useDeleteItem(useConversationsQueryKey(), get_conversations_endpoint(agent_sid), {
        onSuccess: () => {
            invalidateConversations()
        },
    })
}

export function invalidateConversations() {
    return queryClient.invalidateQueries([CONVERSATIONS_LIST_NAME, getCurrentStackId()])
}
