// @ts-strict-ignore
import React, { useCallback } from 'react'

import { PickerResponse } from 'filestack-js'

import ReactFilestack from 'v2/ui/ReactFilestack'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Field } from 'ui/components/Field'
import { Input } from 'ui/components/Input'
import { RadioCard, RadioCardGroup } from 'ui/components/Radio'
import { Select, SelectOption } from 'ui/components/Select'
import { Textarea } from 'ui/components/Textarea'
import { Tooltip } from 'ui/components/Tooltip'
import { theme } from 'ui/styling/Theme.css'

import { useListHeaderPortalControlsState } from './hooks/useListHeaderPortalControlsState'

import {
    ListHeaderPortalCoverImageOverlayStyles,
    ListHeaderPortalRemoveCoverImageButtonStyle,
} from './ListHeaderPortal.css'

type ListHeaderPortalControlsProps = {
    header: ListViewHeaderPortal
    updateHeader: (header: Partial<ListViewHeaderPortal>) => void
}

export const ListHeaderPortalControls: React.FC<ListHeaderPortalControlsProps> = ({
    updateHeader,
    header,
}) => {
    const {
        style,
        handleChangeStyle,
        backgroundColorOptions,
        backgroundColor,
        backgroundColorPreview,
        handleChangeBackgroundColor,
        title,
        handleChangeTitle,
        titlePlaceholder,
        subtitle,
        handleChangeSubtitle,
        eyebrow,
        handleChangeEyebrow,
        coverImage,
        handleChangeCoverImage,
        searchPlacement,
        handleChangeSearchPlacement,
        textAlignment,
        handleChangeTextAlignment,
    } = useListHeaderPortalControlsState({
        header,
        updateHeader,
    })

    return (
        <Box flex flexDirection="column" gap="l">
            <Field label="Style">
                <RadioCardGroup
                    value={style}
                    onValueChange={handleChangeStyle}
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                    }}
                >
                    <RadioCard value="boxed" icon={{ name: 'Box' }}>
                        Boxed
                    </RadioCard>
                    <RadioCard value="full" icon={{ name: 'Fullscreen' }}>
                        Full-width
                    </RadioCard>
                </RadioCardGroup>
            </Field>
            <Field label="Cover image">
                <HeaderImageControl
                    value={coverImage}
                    onChange={handleChangeCoverImage}
                    textAlignment={textAlignment}
                />
            </Field>
            <Select
                label="Background"
                value={backgroundColor}
                onChange={handleChangeBackgroundColor}
                isClearable
                startAvatar={{
                    firstName: '',
                    lastName: '',
                    shape: 'square',
                    style: {
                        backgroundColor: theme.color[backgroundColorPreview],
                    },
                }}
            >
                {backgroundColorOptions.map((option) => (
                    <SelectOption
                        key={option.value}
                        value={option.value}
                        label={option.value}
                        startAvatar={{
                            firstName: '',
                            lastName: '',
                            shape: 'square',
                            style: {
                                backgroundColor: theme.color[option.color],
                            },
                        }}
                    />
                ))}
            </Select>
            <Input label="Eyebrow" value={eyebrow} onChange={handleChangeEyebrow} />
            <Input
                label="Title"
                value={title}
                onChange={handleChangeTitle}
                placeholder={titlePlaceholder}
            />
            <Textarea
                label="Subtitle"
                value={subtitle}
                onChange={handleChangeSubtitle}
                autoSize
                style={{
                    paddingTop: theme.input.padding.verticalM,
                    paddingBottom: theme.input.padding.verticalM,
                    width: '100%',
                    minHeight: theme.input.size.m,
                    alignItems: 'center',
                }}
                textareaProps={{
                    style: {
                        maxHeight: theme.textarea.size.m,
                    },
                }}
            />
            <Field label="Text alignment">
                <RadioCardGroup
                    value={textAlignment}
                    onValueChange={handleChangeTextAlignment}
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                    }}
                >
                    <RadioCard value="left" icon={{ name: 'AlignLeft' }}>
                        Left
                    </RadioCard>
                    <RadioCard value="center" icon={{ name: 'AlignCenter' }}>
                        Center
                    </RadioCard>
                </RadioCardGroup>
            </Field>
            <Field label="Search box placement">
                <RadioCardGroup
                    value={searchPlacement}
                    onValueChange={handleChangeSearchPlacement}
                    style={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                    }}
                >
                    <RadioCard value="toolbar" icon={{ name: 'Wrench' }}>
                        Toolbar
                    </RadioCard>
                    <RadioCard value="header" icon={{ name: 'LayoutPanelTop' }}>
                        Header
                    </RadioCard>
                </RadioCardGroup>
            </Field>
        </Box>
    )
}

type HeaderImageControlProps = {
    value: string
    onChange: (value: string) => void
    textAlignment?: ListViewHeaderPortal['textAlignment']
}

const HeaderImageControl: React.FC<HeaderImageControlProps> = ({
    value,
    onChange,
    textAlignment,
}) => {
    const onSuccess = useCallback(
        (value: PickerResponse) => {
            const src = value.filesUploaded[0]?.url ?? ''
            onChange(src)
        },
        [onChange]
    )

    return (
        <Box
            style={{ height: '90px' }}
            borderWidth="base"
            borderStyle="base"
            borderColor="border"
            rounded="2xl"
            position="relative"
            overflow="hidden"
            background="surface"
        >
            {value && (
                <Box position="absolute" top={0} left={0} width="full" height="full">
                    <Tooltip
                        content="Remove cover image"
                        asChild
                        zIndex={1201}
                        side="top"
                        align="end"
                    >
                        <Button
                            variant="ghost"
                            position="absolute"
                            size="xs"
                            top="m"
                            right="m"
                            aria-label="Remove cover image"
                            onClick={() => onChange('')}
                            startIcon={{ name: 'X' }}
                            style={{ zIndex: 99 }}
                            className={ListHeaderPortalRemoveCoverImageButtonStyle}
                        />
                    </Tooltip>
                    <Box
                        pointerEvents="none"
                        className={ListHeaderPortalCoverImageOverlayStyles.styleFunction({
                            textAlignment,
                        })}
                    />
                    <Box
                        as="img"
                        src={value}
                        alt=""
                        width="full"
                        height="full"
                        top={0}
                        left={0}
                        style={{
                            objectFit: 'cover',
                            objectPosition: 'center',
                        }}
                    />
                </Box>
            )}
            {!value && (
                <ReactFilestack
                    options={{
                        maxFiles: 1,
                        accept: 'image/*',
                    }}
                    onSuccess={onSuccess}
                    customRender={({ onPick }: { onPick: () => void }) => (
                        <Button
                            variant="ghost"
                            startIcon={{ name: 'Plus' }}
                            style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: 0,
                            }}
                            onClick={onPick}
                        >
                            Upload image
                        </Button>
                    )}
                />
            )}
        </Box>
    )
}
