import React from 'react'

import { EmbeddedNoRecordsEmptyState } from 'features/views/ListView/EmptyState/EmbeddedNoRecordsEmptyState'
import { EmbeddedNoRecordsFiltersEmptyState } from 'features/views/ListView/EmptyState/EmbeddedNoRecordsFiltersEmptyState'
import { EmbeddedServerErrorEmptyState } from 'features/views/ListView/EmptyState/EmbeddedServerErrorEmptyState'
import { NoRecordsEmptyState } from 'features/views/ListView/EmptyState/NoRecordsEmptyState'
import { NoRecordsFiltersEmptyState } from 'features/views/ListView/EmptyState/NoRecordsFiltersEmptyState'
import { ServerErrorEmptyState } from 'features/views/ListView/EmptyState/ServerErrorEmptyState'

import { Box } from 'ui/components/Box'

import { TableViewCalculations } from './Calculations/TableViewCalculations'
import { useTableViewDataState } from './hooks/useTableViewDataState'
import { TableViewPagination } from './Pagination/TableViewPagination'
import { TableViewContent } from './TableViewContent'
import { TableViewGrid } from './TableViewGrid'
import { TableViewHeading } from './TableViewHeading'
import { TableViewLoader } from './TableViewLoader'
import { TableViewScrollArea } from './TableViewScrollArea'

import { TableViewDataStyle } from './TableView.css'

type TableViewDataProps = {}

export const TableViewData: React.FC<TableViewDataProps> = React.memo(function TableViewData() {
    const {
        isEmpty,
        isEmptyWithFilters,
        clearFilters,
        showTable,
        isError,
        retryFetchRecords,
        isFetchingSlow,
        isEmbedded,
        showTopPadding,
    } = useTableViewDataState()

    return (
        <Box
            px={{ tablet: '4xl' }}
            pt={{ tablet: showTopPadding ? 'xl' : undefined }}
            pb={{ tablet: '3xl' }}
            width="full"
            overflow="hidden"
            flex
            center
            flexDirection="column"
            background="surface"
            className={TableViewDataStyle}
        >
            {isEmpty && isEmbedded && <EmbeddedNoRecordsEmptyState />}
            {isEmpty && !isEmbedded && <NoRecordsEmptyState />}
            {isEmptyWithFilters && isEmbedded && <EmbeddedNoRecordsFiltersEmptyState />}
            {isEmptyWithFilters && !isEmbedded && (
                <NoRecordsFiltersEmptyState onClearFilters={clearFilters} />
            )}
            {isError && isEmbedded && <EmbeddedServerErrorEmptyState />}
            {isError && !isEmbedded && <ServerErrorEmptyState onRetry={retryFetchRecords} />}
            {showTable && (
                <>
                    <TableViewScrollArea>
                        <TableViewGrid>
                            <TableViewHeading />
                            <TableViewCalculations />
                            {isFetchingSlow ? <TableViewLoader /> : <TableViewContent />}
                        </TableViewGrid>
                    </TableViewScrollArea>
                    {!isFetchingSlow && <TableViewPagination />}
                </>
            )}
        </Box>
    )
})
