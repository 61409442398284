import { sha256 } from 'js-sha256'

import settings from 'app/settings'

import { WebsocketMessage } from './types'
const MAX_RETRIES = 3
const RETRY_BACKOFF_MS = 5000

class WebsocketHandler {
    private user: AuthedUserDto | undefined = undefined
    private websocket: WebSocket | undefined = undefined
    private pendingRetries = MAX_RETRIES
    private isClosing = false
    private currentConnectionAttempt = 0
    private listeners: Record<string, ((message: unknown) => void)[]> = {}

    public onUserChange(user: AuthedUserDto | undefined) {
        this.user = user
        this.disconnectWebsocket()
        this.currentConnectionAttempt += 1
        this.connectToWebsocket(this.currentConnectionAttempt)
    }

    private disconnectWebsocket() {
        this.isClosing = true
        if (this.websocket) {
            this.websocket.close()
            this.websocket = undefined
            this.pendingRetries = MAX_RETRIES
        }
    }

    private connectToWebsocket(connectionAttempt: number) {
        // Always keep track of the current connection attempt number, so that if we've
        // changed the user and try to reconnect, we don't end up with race conditions.
        if (connectionAttempt === this.currentConnectionAttempt) {
            this.isClosing = false
            if (this.pendingRetries > 0) {
                this.websocket = new WebSocket(settings.WEBSOCKET_SERVER + '/ws/connect')

                this.websocket.addEventListener('open', () => {
                    if (
                        connectionAttempt === this.currentConnectionAttempt &&
                        this.user?.integration_key &&
                        this.websocket
                    ) {
                        this.pendingRetries = MAX_RETRIES

                        const hash = sha256(this.user.integration_key)
                        const id = `${this.user._sid}:${this.getRandomString()}:${hash}`

                        this.websocket?.send(
                            JSON.stringify({
                                type: 'id',
                                id,
                            })
                        )
                    }
                })

                this.websocket.addEventListener('message', (e) => {
                    if (connectionAttempt === this.currentConnectionAttempt) {
                        this.handleMessage(e)
                    }
                })

                this.websocket.addEventListener('error', async (e) => {
                    if (connectionAttempt === this.currentConnectionAttempt) {
                        console.warn('Error from websocket')
                        console.warn(e)
                        this.reconnectOnUnexpectedErrorOrClose(connectionAttempt)
                    }
                })

                this.websocket.addEventListener('close', (e) => {
                    if (connectionAttempt === this.currentConnectionAttempt && !this.isClosing) {
                        console.warn('Unexpected websocket closure')
                        console.warn(e)
                        this.reconnectOnUnexpectedErrorOrClose(connectionAttempt)
                    }
                })
            } else {
                console.warn(
                    `Failed to connect to the websocket server after ${MAX_RETRIES} attempts`
                )
            }
        }
    }

    private async reconnectOnUnexpectedErrorOrClose(connectionAttempt: number) {
        this.websocket?.close()
        this.websocket = undefined
        this.pendingRetries -= 1
        await new Promise((resolve) => setTimeout(resolve, RETRY_BACKOFF_MS))
        this.connectToWebsocket(connectionAttempt)
    }

    private handleMessage(e: MessageEvent) {
        let message: WebsocketMessage | undefined = undefined
        try {
            message = JSON.parse(e.data) as WebsocketMessage
        } catch {
            console.warn(`Failed to parse websocket message: ${e.data}`)
        }
        if (message) {
            if (message.type === 'wrongId') {
                console.warn(`Failed to connect to the websocket server - authentication failure`)
                this.pendingRetries = 0
            } else {
                this.listeners[message.subject]?.forEach((listener) => listener(message.data))
            }
        }
        console.log(e)
    }

    private getRandomString(): string {
        const values = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
        let result = ''
        for (let i = 0; i < 6; i++) {
            result += values[Math.floor(Math.random() * values.length)]
        }
        return result
    }

    public addListener(subject: string, listener: (message: unknown) => void) {
        if (!this.listeners[subject]) {
            this.listeners[subject] = []
        }
        this.listeners[subject].push(listener)
    }

    public removeListener(subject: string, listener: (message: unknown) => void) {
        this.listeners[subject] = this.listeners[subject].filter((l) => l !== listener)
    }
}

export const websocketHandler = new WebsocketHandler()
