import React from 'react'

import { AttachmentsAttributeDisplay } from 'features/views/attributes/AttachmentsAttributeDisplay'
import { AttachmentValue } from 'features/views/attributes/types'
import { useDetailViewAttributeValue } from 'features/views/DetailView/attributes/hooks/useDetailViewAttributeValue'

import { BaseAttribute } from './BaseAttribute'
import { PrimaryHighlightsAttributeComponent } from './types'

const MAX_ATTACHMENTS = 2

type AttachmentsAttributeProps = {}

export const AttachmentsAttribute: PrimaryHighlightsAttributeComponent<
    AttachmentsAttributeProps
> = ({ field, isLoading, ...props }) => {
    const value = useDetailViewAttributeValue<AttachmentValue[]>(field.api_name)
    const isEmpty = !isLoading && (!value || value.length < 1)

    return (
        <BaseAttribute {...props} field={field} isEmpty={isEmpty} isLoading={isLoading}>
            <AttachmentsAttributeDisplay
                value={value}
                field={field}
                isLoading={isLoading}
                maxAttachments={MAX_ATTACHMENTS}
                size={{
                    mobile: 'm',
                    tablet: 'l',
                }}
                maxWidth="full"
                minWidth={0}
                trim
            />
        </BaseAttribute>
    )
}
