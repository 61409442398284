import React from 'react'

import ActionButtonsSelector from 'v2/views/ActionButtonsSelector'

import { ActionsContextProvider } from 'features/actions/ActionsContextProvider'
import { LayoutEditorCollapsibleControl } from 'features/views/LayoutEditor/controls/LayoutEditorCollapsibleControl'
import { LayoutEditorControlSeparator } from 'features/views/LayoutEditor/controls/LayoutEditorControlSeparator'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { RadioCard, RadioCardGroup } from 'ui/components/Radio'
import { Body } from 'ui/components/Text'
import { Tooltip } from 'ui/components/Tooltip'
import { theme } from 'ui/styling/Theme.css'

import { useDetailViewActionControlsState } from './hooks/useDetailViewActionControlsState'

type DetailViewActionControlsProps = {}

export const DetailViewActionControls: React.FC<DetailViewActionControlsProps> = () => {
    const { object, selectedButtons, updateConfig, editingMode, updateEditingMode } =
        useDetailViewActionControlsState()

    return (
        <Box flex column gap="l">
            {object && (
                <>
                    <LayoutEditorCollapsibleControl
                        label="Buttons"
                        startIcon={{ name: 'MousePointerClick' }}
                    >
                        <Box style={{ margin: '0 0 -16px 0' }}>
                            <ActionsContextProvider object={object}>
                                <ActionButtonsSelector
                                    object={object}
                                    selectedButtons={selectedButtons}
                                    setConfig={updateConfig}
                                    showHeader={true}
                                    canDeleteActions={true}
                                    canEditActions={true}
                                    canSearchActions={true}
                                    canReorderActions={true}
                                    animate={false}
                                    height="auto"
                                    maxHeight="none"
                                    customHeader={({ onCreateActionClick }) => (
                                        <Box flex center justifyContent="space-between" mb="m">
                                            <Body size="m" weight="medium">
                                                Select actions
                                            </Body>
                                            <Tooltip
                                                asChild
                                                zIndex={1500}
                                                side="bottom"
                                                align="end"
                                                content="Create new action"
                                            >
                                                <Button
                                                    variant="secondary"
                                                    size="xs"
                                                    onClick={onCreateActionClick}
                                                    startIcon={{ name: 'Plus' }}
                                                    aria-label="Create new action"
                                                />
                                            </Tooltip>
                                        </Box>
                                    )}
                                />
                            </ActionsContextProvider>
                        </Box>
                    </LayoutEditorCollapsibleControl>
                    <LayoutEditorControlSeparator />
                </>
            )}
            <LayoutEditorCollapsibleControl
                label="Editing"
                startIcon={{ name: 'FilePenLine' }}
                defaultOpen={false}
            >
                <RadioCardGroup
                    value={editingMode}
                    onValueChange={updateEditingMode}
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(2, auto-fill)',
                        gap: theme.space.m,
                    }}
                >
                    <RadioCard value="disabled" icon={{ name: 'EyeOff' }}>
                        Disabled
                    </RadioCard>
                    <RadioCard value="inline" icon={{ name: 'MoreHorizontal' }}>
                        Inline
                    </RadioCard>
                </RadioCardGroup>
            </LayoutEditorCollapsibleControl>
        </Box>
    )
}
