// @ts-strict-ignore
import React from 'react'

import {
    CardStyleControlCardStyle,
    CardStyleIconInnerContainer,
    CardStyleIconOuterContainer,
    CardStyleIconShapeLargeRow,
    CardStyleIconShapeMediumRow,
    CardStyleIconShapeSmallRow,
} from 'features/views/ListView/CardView/CardView.css'

import { Box, Text } from 'v2/ui'

import { RadioCard, RadioCardGroup } from 'ui/components/Radio'

export const CardStyleSettings = ({ config, onChange, value }) => {
    if (!config.coverImage) return null

    return (
        <Box>
            <Text variant="adminFieldLabel" mt="0">
                Card style
            </Text>
            <Box role="group">
                <RadioCardGroup value={value} onValueChange={onChange}>
                    <RadioCard
                        value="border"
                        className={CardStyleControlCardStyle}
                        icon={() => <CardBorderIcon hasBorder={true} />}
                    >
                        With border
                    </RadioCard>
                    <RadioCard
                        value="no_border"
                        className={CardStyleControlCardStyle}
                        icon={() => <CardBorderIcon hasBorder={false} />}
                    >
                        Without border
                    </RadioCard>
                </RadioCardGroup>
            </Box>
        </Box>
    )
}

export const CardBorderIcon = ({ hasBorder }) => {
    return (
        <div className={CardStyleIconOuterContainer}>
            <div
                className={CardStyleIconInnerContainer.styleFunction({
                    border: hasBorder,
                })}
            >
                <div className={CardStyleIconShapeLargeRow} />
                <div
                    style={{
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                        gap: 1,
                        display: 'flex',
                    }}
                >
                    <div className={CardStyleIconShapeMediumRow} />
                    <div className={CardStyleIconShapeSmallRow} />
                </div>
            </div>
        </div>
    )
}
