import React from 'react'
import { useFormContext } from 'react-hook-form'

import { AgentTrigger } from 'data/hooks/agents/types'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Field } from 'ui/components/Field'
import { Body } from 'ui/components/Text'

import { ConfigureTriggerModal } from './ConfigureTriggerModal'
import { TriggerListItem } from './TriggerListItem'

type Props = {
    agentSid: string
    instructionsSid?: string
}
export function EditTriggers({ agentSid, instructionsSid }: Props) {
    const { watch, setValue } = useFormContext<{ triggers: AgentTrigger[] }>()
    const triggers = watch('triggers') || []

    const addTrigger = (trigger: AgentTrigger) => {
        setValue('triggers', [...triggers, trigger], { shouldDirty: true })
    }

    const updateTrigger = (idx: number, config: AgentTrigger) => {
        const newTriggers = [...triggers]
        newTriggers[idx] = config
        setValue('triggers', newTriggers, { shouldDirty: true })
    }

    const removeTrigger = (triggerSid: string) => {
        const newTriggers = triggers.filter((t) => t._sid !== triggerSid)
        setValue('triggers', newTriggers, { shouldDirty: true })
    }

    return (
        <Field label="Configured Triggers">
            <Box flex fullWidth alignItems="stretch" column gap="m" maxWidth="full">
                <Box gap="xs" flex column overflowY="auto">
                    {triggers?.map((trigger, idx) => (
                        <ConfigureTriggerModal
                            key={trigger._sid}
                            agentSid={agentSid}
                            instructionsSid={instructionsSid}
                            onSubmit={(config) => updateTrigger(idx, config)}
                            trigger={trigger}
                        >
                            <TriggerListItem
                                type={trigger.type}
                                name={trigger.name}
                                onDelete={() => removeTrigger(trigger._sid)}
                            />
                        </ConfigureTriggerModal>
                    ))}
                    {!triggers.length && <Body color="textWeaker">No triggers configured</Body>}
                </Box>
                <ConfigureTriggerModal
                    agentSid={agentSid}
                    instructionsSid={instructionsSid}
                    onSubmit={addTrigger}
                >
                    <Button
                        startIcon={{ name: 'Plus' }}
                        size="s"
                        variant="secondary"
                        alignSelf="flex-start"
                    >
                        Add Trigger
                    </Button>
                </ConfigureTriggerModal>
            </Box>
        </Field>
    )
}
