import React from 'react'

import { withDataConnections } from 'data/wrappers/withDataConnections'
import { WithObjects } from 'data/wrappers/WithObjectsComponent'

import { Box } from 'ui/components/Box'
import { Select, SelectOption, SelectTitle } from 'ui/components/Select'

type DataConnectionDto = {
    _sid: string
    label: string
}

type ObjectDto = {
    _sid: string
    name: string
    data_connection: string
    connection_options: {
        data_mapping_disabled: boolean
    }
}

type ObjectOption = {
    label: string
    value: string
    object: ObjectDto
}

type InnerObjectPickerProps = {
    id?: string
    showFeatureConfigData?: boolean
    onChange: (value: any) => void
    placeholder?: string
    value?: any
    style?: React.CSSProperties
    returnObject?: boolean
    objectSidWhitelist?: string[]
    filter?: (obj: ObjectDto) => boolean
    dataConnections?: DataConnectionDto[]
}

type GroupedObjects = {
    nativeTables: ObjectOption[]
    connectionTables: Record<
        string,
        {
            label: string
            options: ObjectOption[]
        }
    >
}

const groupObjectsByConnection = (
    objects: ObjectDto[],
    dataConnections: DataConnectionDto[],
    sidWhitelist?: string[],
    filter?: (obj: ObjectDto) => boolean
): GroupedObjects => {
    const connectionMap = new Map(dataConnections.map((dc) => [dc._sid, dc.label]))

    return objects.reduce(
        (grouped: GroupedObjects, obj) => {
            if (
                (filter && !filter(obj)) ||
                (sidWhitelist && !sidWhitelist.includes(obj._sid)) ||
                obj.connection_options.data_mapping_disabled
            ) {
                return grouped
            }

            const option: ObjectOption = {
                label: obj.name,
                value: obj._sid,
                object: obj,
            }

            if (!obj.data_connection) {
                grouped.nativeTables.push(option)
            } else if (connectionMap.has(obj.data_connection)) {
                // Initialize connection group if it doesn't exist
                if (!grouped.connectionTables[obj.data_connection]) {
                    grouped.connectionTables[obj.data_connection] = {
                        label: connectionMap.get(obj.data_connection)!,
                        options: [],
                    }
                }
                grouped.connectionTables[obj.data_connection].options.push(option)
            }

            return grouped
        },
        {
            nativeTables: [],
            connectionTables: {},
        }
    )
}

const InnerObjectPicker: React.FC<InnerObjectPickerProps> = ({
    id,
    onChange,
    placeholder = 'Select data source',
    value,
    style,
    objectSidWhitelist,
    filter,
    dataConnections = [],
    ...props
}) => {
    return (
        <WithObjects>
            {({ objects = [] }) => {
                const { nativeTables, connectionTables } = groupObjectsByConnection(
                    objects,
                    dataConnections,
                    objectSidWhitelist,
                    filter
                )

                const hasConnectionTables = Object.values(connectionTables).some(
                    (group) => group.options.length > 0
                )

                return (
                    <Box style={{ ...style }}>
                        <Select
                            id={id}
                            onChange={onChange}
                            placeholder={placeholder}
                            value={value}
                            optionsOverride={[
                                ...nativeTables,
                                ...Object.values(connectionTables).flatMap(
                                    (group) => group.options
                                ),
                            ]}
                            {...props}
                        >
                            {nativeTables.length > 0 && (
                                <>
                                    {hasConnectionTables && (
                                        <SelectTitle>Stacker Tables</SelectTitle>
                                    )}
                                    {nativeTables.map((option) => (
                                        <SelectOption
                                            key={option.value}
                                            value={option.value}
                                            label={option.label}
                                        />
                                    ))}
                                </>
                            )}

                            {Object.entries(connectionTables)
                                .filter(([_, group]) => group.options.length > 0)
                                .map(([dcId, group]) => (
                                    <React.Fragment key={dcId}>
                                        <SelectTitle>{group.label}</SelectTitle>
                                        {group.options.map((option) => (
                                            <SelectOption
                                                key={option.value}
                                                value={option.value}
                                                label={option.label}
                                            />
                                        ))}
                                    </React.Fragment>
                                ))}
                        </Select>
                    </Box>
                )
            }}
        </WithObjects>
    )
}

export const ObjectPicker = withDataConnections(InnerObjectPicker)
