import React from 'react'

import { useAgents } from 'data/hooks/agents/agents'
import { useAgentInstructions } from 'data/hooks/agents/instructions'
import { AgentIcon } from 'features/Agents/chat/AgentIcon'

import { Box } from 'ui/components/Box'
import { Select, SelectOptionComplex } from 'ui/components/Select'
import { Body } from 'ui/components/Text'

type AIAgentBlockEditorProps = {
    value?: {
        agentSid?: string
        selectedInstructionSet?: string
    }
    onChange: (value: { agentSid?: string; selectedInstructionSet?: string }) => void
    context: {
        editor: {
            isEditing: boolean
        }
    }
}

const AIAgentBlockEditor = ({ value, onChange }: AIAgentBlockEditorProps) => {
    const { data: agents } = useAgents()
    const { data: instructions = [] } = useAgentInstructions(value?.agentSid || '')

    const filteredInstructionSets = instructions.filter((i) =>
        i.triggers.some((t) => t.type === 'app_chatbot')
    )

    if (!agents?.length) {
        return (
            <Box p="l">
                <Body>No agents available. Please create an agent first.</Body>
            </Box>
        )
    }

    return (
        <Box p="l" flex column gap="m">
            <Select
                label="Select Agent"
                value={value?.agentSid || ''}
                onChange={(agentSid) => onChange({ agentSid })}
                options={agents.map((agent) => ({
                    label: agent._sid,
                    value: agent._sid,
                }))}
            >
                {agents?.map((agent) => (
                    <SelectOptionComplex
                        key={agent._sid}
                        label={agent.title}
                        leftSlotContent={() => <AgentIcon agent={agent} />}
                        value={agent._sid}
                    />
                ))}
            </Select>
            {value?.agentSid && (
                <Select
                    label="Select instruction set"
                    placeholder="which instruction set to use"
                    value={value?.selectedInstructionSet}
                    onChange={(selectedInstructionSet) =>
                        onChange({ agentSid: value?.agentSid, selectedInstructionSet })
                    }
                >
                    {filteredInstructionSets.map((i) => (
                        <SelectOptionComplex key={i._sid} label={i.name} value={i._sid} />
                    ))}
                </Select>
            )}
        </Box>
    )
}

export default AIAgentBlockEditor
