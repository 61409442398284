import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import History from 'history'

import { getUrl } from 'app/UrlService'
import { useObjects } from 'data/hooks/objects'
import { getRecord } from 'data/hooks/records/getRecord'
import useEditMode from 'features/admin/edit-mode/useEditMode'

import useRunOnce from 'v2/ui/utils/useRunOnce'

import { NavigationState, PreviewRecordContext, PreviewRecordList } from './PreviewRecordContext'

const PREVIEW_RECORD_ID_PARAM = 'preview_record_id'
type PreviewRecordContextProviderProps = React.PropsWithChildren<{}>
export const PreviewRecordContextProvider: React.FC<PreviewRecordContextProviderProps> = ({
    children,
}) => {
    const history = useHistory()
    const location = useLocation()

    const recordId = useMemo(() => {
        const hash = location.hash.slice(1)
        const hashParams = new URLSearchParams(hash)
        return hashParams.get(PREVIEW_RECORD_ID_PARAM)
    }, [location.hash])

    const objectId = useMemo(
        () => (location.state as NavigationState)?.currentObjectId,
        [location.state]
    )

    const recordListState = useMemo(() => {
        const state = location.state as NavigationState
        return state?.recordListState
    }, [location.state])

    const { data: objects } = useObjects()

    const object = useMemo(() => {
        return objects?.find((o) => o._sid === objectId)
    }, [objects, objectId])

    const getRecordPath = useCallback(
        (recordId: string, objectUrl: string): History.LocationDescriptor => {
            const recordUrl = getUrl(`${objectUrl}/view/${recordId}`)
            const location = history.location
            const { state, hash: currentHash, search } = location

            let hash: string | undefined
            const existingState = state as NavigationState
            if (existingState?.detailTabName) {
                hash = `#${existingState.detailTabName}`
            }

            // Create merged params from the url and hash.
            const mergedParams = new URLSearchParams(search)
            const hashParams = new URLSearchParams(currentHash.slice(1))
            hashParams.forEach((value, key) => mergedParams.set(key, value))

            // Handle old-school detail view tab name.
            const detailTabName = (state as NavigationState)?.detailTabName
            if (!!detailTabName) {
                mergedParams.set('detailTabName', detailTabName)
            }

            // Remove the preview record id param, if it exists.
            mergedParams.delete(PREVIEW_RECORD_ID_PARAM)

            return {
                pathname: recordUrl,
                hash,
                search: mergedParams.toString(),
                state: {
                    prev: location,
                    type: 'detail',
                    title: document.title,
                },
            }
        },
        [history.location]
    )

    const navigateToRecord = useCallback(
        (recordId: string, objectUrl: string) => {
            const path = getRecordPath(recordId, objectUrl)
            history.replace(path)
        },
        [getRecordPath, history]
    )

    const [initialLoadDone, setInitialLoadDone] = useState(false)

    // Redirect to the record detail view if the user tries accessing the preview URL directly.
    useRunOnce(async () => {
        const restoreRecord = async () => {
            if (!recordId) return

            const record = await getRecord(recordId, [])
            if (!record) return

            const object = objects?.find((f) => f._sid === record._object_id)
            if (!object) return

            navigateToRecord(recordId, object.url)
        }

        await restoreRecord()
        window.requestAnimationFrame(() => {
            setInitialLoadDone(true)
        })
    }, Boolean(objects))

    const isModalOpen = Boolean(initialLoadDone && recordId)

    const initialWindowTitle = useRef<string>('')
    useLayoutEffect(() => {
        if (isModalOpen) {
            initialWindowTitle.current = document.title
        } else {
            document.title = initialWindowTitle.current
            initialWindowTitle.current = ''
        }
    }, [isModalOpen])

    const closePreview = useCallback(() => {
        const hash = location.hash.slice(1)
        const hashParams = new URLSearchParams(hash)
        hashParams.delete(PREVIEW_RECORD_ID_PARAM)

        const existingState = location.state as NavigationState
        const newState = {
            ...existingState,
            recordListState: undefined,
            previousItem: undefined,
            scrollPosition: undefined,
            detailTabName: undefined,
            currentObjectId: undefined,
        }

        history.replace({
            ...location,
            hash: '',
            state: newState,
        })
    }, [history, location])

    // Persist the modal's scroll position when navigating between records.
    const persistScrollPosition = useCallback(() => {
        const el = document.querySelector('#chakra-modal--body-preview-record-modal')

        const scrollPosition = [el?.scrollLeft ?? 0, el?.scrollTop ?? 0]
        const existingState = location.state as NavigationState
        const newState = {
            ...existingState,
            scrollPosition,
        }
        // We store this in history, so we won't have to
        // deal with problems such as memory leaks.
        history.replace({
            ...location,
            state: newState,
        })
    }, [history, location])

    const previewRecord = useCallback(
        ({
            recordId: newRecordId,
            objectId: newObjectId,
            partOfRecordList,
            recordListOnlyOnInitialOpen = true,
        }: {
            recordId: string
            objectId: string
            partOfRecordList?: PreviewRecordList
            recordListOnlyOnInitialOpen?: boolean
        }) => {
            if (isModalOpen) {
                persistScrollPosition()
            }

            const hash = location.hash.slice(1)
            const hashParams = new URLSearchParams(hash)
            hashParams.set(PREVIEW_RECORD_ID_PARAM, newRecordId)

            let listState: typeof recordListState
            if ((!isModalOpen || !recordListOnlyOnInitialOpen) && partOfRecordList) {
                const { items, direction } = partOfRecordList

                const currIdx = items.findIndex((item) => item.recordId === newRecordId)

                const nextRecordId = items[currIdx + 1]?.recordId ?? null
                const prevRecordId = items[currIdx - 1]?.recordId ?? null

                listState = {
                    currentIndex: currIdx,
                    direction: direction,
                    items: items,
                    totalCount: items.length,
                    nextRecordId,
                    prevRecordId,
                }
            }

            const state: NavigationState = {
                recordListState: listState,
            }
            if (recordId && objectId) {
                state.previousItem = {
                    recordId,
                    objectId,
                    title: document.title,
                    hash,
                    recordListState,
                    detailTabName: (location.state as NavigationState)?.detailTabName,
                }
            }

            const newHash = encodeHashParams(hashParams)
            const existingState = location.state as NavigationState
            const newState = {
                ...existingState,
                ...state,
                currentObjectId: newObjectId,
            }
            history.push({
                ...location,
                hash: `#${newHash}`,
                state: newState,
            })
        },
        [history, isModalOpen, location, persistScrollPosition, recordId, objectId, recordListState]
    )

    const recordListGoNext = useCallback(() => {
        if (!recordListState || !objectId) {
            return
        }
        if (recordListState.currentIndex === recordListState.totalCount - 1) return

        const newRecordId = recordListState.nextRecordId
        if (!newRecordId) return

        const newRecordListState = {
            ...recordListState,
        }

        newRecordListState.currentIndex += 1

        newRecordListState.nextRecordId =
            recordListState.items[newRecordListState.currentIndex + 1]?.recordId ?? null
        newRecordListState.prevRecordId =
            recordListState.items[newRecordListState.currentIndex - 1]?.recordId ?? null

        previewRecord({
            recordId: newRecordId,
            objectId,
            partOfRecordList: recordListState,
            recordListOnlyOnInitialOpen: false,
        })
    }, [objectId, previewRecord, recordListState])

    const recordListGoPrevious = useCallback(() => {
        if (!recordListState || !objectId) {
            return
        }
        if (recordListState.currentIndex === 0) return

        const newRecordId = recordListState.prevRecordId
        if (!newRecordId) return

        const newRecordListState = {
            ...recordListState,
        }

        newRecordListState.currentIndex -= 1

        newRecordListState.nextRecordId =
            recordListState.items[newRecordListState.currentIndex + 1]?.recordId ?? null
        newRecordListState.prevRecordId =
            recordListState.items[newRecordListState.currentIndex - 1]?.recordId ?? null

        previewRecord({
            recordId: newRecordId,
            objectId,
            partOfRecordList: recordListState,
            recordListOnlyOnInitialOpen: false,
        })
    }, [objectId, previewRecord, recordListState])

    const goFullscreen = useCallback(() => {
        if (!object?.url || !recordId) return

        navigateToRecord(recordId, object.url)
    }, [object?.url, recordId, navigateToRecord])

    // Close modal and go onto fullscreen mode when we enter edit mode.
    const { isOpen: isEditMode } = useEditMode()
    useEffect(() => {
        if (isEditMode && isModalOpen) {
            goFullscreen()
        }
    }, [isEditMode, isModalOpen, goFullscreen])

    const breadcrumbs = useMemo(() => {
        const state = location.state as NavigationState
        if (state?.previousItem) return [state.previousItem]

        return []
    }, [location.state])

    const goBack = useCallback(() => {
        const prevItem = breadcrumbs?.[breadcrumbs.length - 1]
        if (!prevItem) {
            closePreview()
            return
        }

        window.history.back()
    }, [breadcrumbs, closePreview])

    const fullscreenPath = useMemo(() => {
        if (object?.url && recordId) {
            return getRecordPath(recordId, object.url)
        }

        return undefined
    }, [object?.url, recordId, getRecordPath])

    const value = useMemo(
        () => ({
            recordId,
            isModalOpen,
            previewRecord,
            goFullscreen,
            closePreview,
            recordList: recordListState
                ? {
                      ...recordListState,
                      goNext: recordListGoNext,
                      goPrevious: recordListGoPrevious,
                  }
                : undefined,
            breadcrumbs,
            goBack,
            objectId: object?._sid ?? null,
            object,
            fullscreenPath,
        }),
        [
            recordId,
            isModalOpen,
            previewRecord,
            goFullscreen,
            closePreview,
            recordListState,
            recordListGoNext,
            recordListGoPrevious,
            breadcrumbs,
            goBack,
            fullscreenPath,
            object,
        ]
    )

    return <PreviewRecordContext.Provider value={value}>{children}</PreviewRecordContext.Provider>
}

// Encode hash params to a string, without including `=` for empty values.
function encodeHashParams(params: URLSearchParams) {
    const parts: string[] = []

    for (const [key, value] of params.entries()) {
        if (value) {
            parts.push(`${key}=${encodeURIComponent(value)}`)
        } else {
            parts.push(key)
        }
    }

    return parts.join('&')
}
