import React from 'react'

// @ts-ignore
import { Box } from 'ui/components/Box'
import { Input } from 'ui/components/Input'
import { Body } from 'ui/components/Text'

type WorkflowTitleProps = React.ComponentPropsWithoutRef<typeof Box> & {
    title: string
    onChange: (title: string) => void
    autoFocus?: boolean
}

export const WorkflowTitle: React.FC<WorkflowTitleProps> = ({
    title,
    onChange,
    autoFocus = false,
    ...props
}) => {
    return (
        <Box fontSize="headlineXs" flex center gap="m" {...props}>
            <Body color="textWeakest">Name</Body>
            <Input value={title} onChange={(e) => onChange(e.target.value)} autoFocus={autoFocus} />
        </Box>
    )
}
