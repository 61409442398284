import { getUrl, Urls } from 'app/UrlService'

type AttributeMap = {
    [T in Block as T['type']]: T['config']['attributes']
}

export function makeEmbedDummyAttributes(): AttributeMap['iframe'] {
    return {
        url: 'https://www.youtube.com/embed/oKqDV00dyXU',
        type: 'url',
        title: '',
        height: '600px',
    }
}

export function makeTextDummyAttributes(): AttributeMap['text'] {
    return {
        content: {
            ops: [
                {
                    insert: 'You can select from two header sizes',
                },
                {
                    insert: '\n',
                    attributes: {
                        header: 3,
                    },
                },
                {
                    insert: '\nThe text block comes loaded with Rich Text functionality to bring your content to life. From the menu bar, you can access a number of formatting capabilities, including the ability to ',
                },
                {
                    insert: 'add hyperlinks',
                    attributes: {
                        link: 'http://www.stackerhq.com',
                    },
                },
                {
                    insert: '. As you would expect, you can add ',
                },
                {
                    insert: 'bold,',
                    attributes: {
                        bold: true,
                    },
                },
                {
                    insert: ' ',
                },
                {
                    insert: 'italics,',
                    attributes: {
                        italic: true,
                    },
                },
                {
                    insert: ' and ',
                },
                {
                    insert: 'strikethrough',
                    attributes: {
                        strike: true,
                    },
                },
                {
                    insert: '.\n\n',
                },
                {
                    insert: 'You can also make sentences and paragraphs into quotations to highlight particular pieces of content.',
                    attributes: {
                        italic: true,
                    },
                },
                {
                    insert: '\n',
                    attributes: {
                        blockquote: true,
                    },
                },
                {
                    insert: '\nAdding bullet points is a ',
                },
                {
                    insert: 'breeze',
                    attributes: {
                        bold: true,
                    },
                },
                {
                    insert: '\nBullet 1',
                },
                {
                    insert: '\n',
                    attributes: {
                        list: 'bullet',
                    },
                },
                {
                    insert: 'Bullet 2',
                },
                {
                    insert: '\n',
                    attributes: {
                        list: 'bullet',
                    },
                },
                {
                    insert: 'Bullet 3',
                },
                {
                    insert: '\n',
                    attributes: {
                        list: 'bullet',
                    },
                },
                {
                    insert: '\nYou can add numbered lists too:\nFirst',
                },
                {
                    insert: '\n',
                    attributes: {
                        list: 'ordered',
                    },
                },
                {
                    insert: 'Second',
                },
                {
                    insert: '\n',
                    attributes: {
                        list: 'ordered',
                    },
                },
                {
                    insert: 'Third',
                },
                {
                    insert: '\n',
                    attributes: {
                        list: 'ordered',
                    },
                },
                {
                    insert: '\nLastly, you can also embed images by simply entering an image URL like the example below taken from our website. \n\n',
                },
                {
                    insert: {
                        image: 'https://assets-global.website-files.com/60798d77be76070f831d5989/63404df52892be2ea39dd1a7_glide-alternatives.png',
                    },
                    attributes: {
                        alt: 'https://assets-global.website-files.com/60798d77be76070f831d5989/63404df52892be2ea39dd1a7_glide-alternatives.png',
                    },
                },
                {
                    insert: '\n',
                },
            ],
        },
    }
}

export function makeQuickLinksDummyAttributes(
    baseUrl = window.location.origin
): AttributeMap['quicklinks'] {
    const homeUrl = `${baseUrl}${getUrl(Urls.Home)}`

    return {
        items: [
            {
                title: 'Homepage',
                subtitle: "A quick link for navigating back to your app's homepage",
                icon: 'fa-home',
                link: {
                    type: 'external',
                    label: 'Take me home',
                    url: homeUrl,
                },
                order: 0,
            },
            {
                title: 'Stacker Help Centre',
                subtitle: 'Learn more about Stacker',
                icon: 'fa-question-circle',
                link: {
                    type: 'external',
                    label: 'View',
                    url: 'https://docs.stackerhq.com/stacker',
                },
                order: 2,
            },
            {
                title: 'Request feature',
                subtitle: 'Request a new feature or improvement to an existing feature',
                icon: 'fa-inbox',
                link: {
                    type: 'external',
                    label: 'Submit',
                    url: 'https://feedback.stackerhq.com/feature-requests',
                },
                order: 3,
            },
        ],
    }
}

export function makeBlankPageDummyBlocks(): Block[] {
    return [
        {
            id: 'page',
            type: 'container',
            config: {
                style: {
                    default: {
                        width: '100%',
                        height: '100%',
                        margin: '0px auto',
                        flexGrow: 0,
                        justifyContent: 'flex-start',
                    },
                },
                locked: true,
                broadcast: [],
                attributes: {
                    label: 'Page',
                    direction: 'column',
                },
            },
            childBlocks: [
                {
                    id: 'center_content',
                    type: 'container',
                    config: {
                        style: {
                            default: {
                                width: '100%',
                                flexGrow: 0,
                                maxWidth: '1000px',
                                marginTop: '20px',
                                minHeight: '50px',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                marginBottom: '0px',
                            },
                        },
                        locked: true,
                        broadcast: [],
                        attributes: {
                            label: 'Center Content',
                            direction: 'column',
                        },
                    },
                    childBlocks: [],
                },
            ],
        },
    ]
}
