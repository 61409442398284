import React from 'react'

// import { useConfirmModal, useConfirmModalWithPromise } from 'app/useConfirmModal'
import { AgentSkill } from 'data/hooks/agents/types'

import { Box, BoxProps } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Icon } from 'ui/components/Icon'
import { Body } from 'ui/components/Text'

type SkillListItemProps = BoxProps & {
    skill: AgentSkill
    onClear?: () => void
    onDelete?: () => void
}

export function SkillListItem({ skill, onClear, onDelete, ...props }: SkillListItemProps) {
    // const confirmDeleteModalData = {
    //     confirmVariant: 'PrimaryError',
    //     message: (
    //         <>
    //             <Body weight="bold">Delete {skill.name}?</Body>

    //             <Body mt="xl">Are you sure you want to delete this skill?</Body>
    //         </>
    //     ),
    // }
    // const { show: showDeleteConfirm } = useConfirmModal({
    //     ...confirmDeleteModalData,
    //     onConfirm: () => {
    //         return deleteSkill({ id: skill._sid })
    //     },
    // })

    return (
        <Box flex role="button" border gap="m" center px="m" py="s" {...props}>
            <Box
                flex
                center
                // @ts-ignore
                background={`${skill.options?.color ?? 'sky'}500`}
                borderRadius="m"
                height="4xl"
                width="4xl"
                color="lightAlpha900"
                justifyContent="center"
                noShrink
            >
                <Icon name={skill.options?.icon?.name ?? 'Zap'} size="xl" />
            </Box>
            <Box flex column grow minWidth={0}>
                <Body weight="medium" trim>
                    {skill.name}
                </Body>
                <Body size="s" trim style={{ minWidth: 0 }}>
                    {skill.description}
                </Body>
            </Box>
            {onClear && (
                <Button
                    variant="ghost"
                    size="xs"
                    iconOnly
                    title="Remove skill"
                    startIcon={{ name: 'X' }}
                    onClick={(e) => {
                        e.preventDefault()
                        onClear()
                    }}
                />
            )}
            {onDelete && (
                <Button
                    variant="ghost"
                    size="xs"
                    iconOnly
                    title="Delete skill"
                    startIcon={{ name: 'Trash' }}
                    onClick={(e) => {
                        e.preventDefault()
                        onDelete()
                    }}
                />
            )}
        </Box>
    )
}
