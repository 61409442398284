import React from 'react'

import { getFieldIcon } from 'features/admin/fields/icons/utils'
import {
    getSupportedProfileImageFields,
    getSupportedTitleFields,
} from 'features/views/DetailView/headers/utils'
import { DetailViewHeaderSlim } from 'features/views/DetailView/types'
import { getYHeader } from 'features/views/DetailView/utils'
import { LayoutEditorCollapsibleControl } from 'features/views/LayoutEditor/controls/LayoutEditorCollapsibleControl'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'

import { Box } from 'ui/components/Box'
import { Select, SelectOption } from 'ui/components/Select'

type DetailViewSlimHeaderControlsProps = {
    fields: FieldDto[]
    header: DetailViewHeaderSlim
}

export const DetailViewSlimHeaderControls: React.FC<DetailViewSlimHeaderControlsProps> = ({
    fields,
    header,
}) => {
    const { commands } = useLayoutEditorContext()

    const profileImageFields = getSupportedProfileImageFields(fields)
    const titleFields = getSupportedTitleFields(fields)
    const eyebrowFields = fields

    return (
        <LayoutEditorCollapsibleControl
            label="Content"
            startIcon={{ name: 'List' }}
            defaultOpen={false}
        >
            <Box flex column gap="l">
                <Select
                    placeholder="Select field..."
                    label="Profile image"
                    isSearchable
                    isClearable
                    value={header.profileImage?.fieldApiName}
                    onChange={(value) => {
                        commands.transaction((data) => {
                            const header = getYHeader(data)
                            header?.set('profileImage', { fieldApiName: value })
                        })
                    }}
                >
                    {profileImageFields.map((field) => (
                        <SelectOption
                            key={field.api_name}
                            value={field.api_name}
                            label={field.label}
                            startIcon={getFieldIcon(field)}
                        />
                    ))}
                </Select>
                <Select
                    placeholder="Select field..."
                    label="Title"
                    isSearchable
                    value={header.title?.fieldApiName}
                    onChange={(value) => {
                        commands.transaction((data) => {
                            const header = getYHeader(data)
                            header?.set('title', { fieldApiName: value })
                        })
                    }}
                >
                    {titleFields.map((field) => (
                        <SelectOption
                            key={field.api_name}
                            value={field.api_name}
                            label={field.label}
                            startIcon={getFieldIcon(field)}
                        />
                    ))}
                </Select>
                <Select
                    placeholder="Select field..."
                    label="Eyebrow"
                    isSearchable
                    isClearable
                    value={header.eyebrow?.fieldApiName}
                    onChange={(value) => {
                        commands.transaction((data) => {
                            const header = getYHeader(data)
                            header?.set('eyebrow', { fieldApiName: value })
                        })
                    }}
                >
                    {eyebrowFields.map((field) => (
                        <SelectOption
                            key={field.api_name}
                            value={field.api_name}
                            label={field.label}
                            startIcon={getFieldIcon(field)}
                        />
                    ))}
                </Select>
            </Box>
        </LayoutEditorCollapsibleControl>
    )
}
