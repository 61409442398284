import React from 'react'

import { ViewLayoutContext } from 'v2/blocks/types'
import ListViewWrapper from 'v2/views/List/ListViewWrapper'
import useViewConfig from 'v2/views/utils/useViewConfig'

import { Container } from 'v2/ui'

import { BoardView } from './BoardView/BoardView'
import { CardView } from './CardView/CardView'
import { TableView } from './TableView/TableView'
import { ThreadView } from './ThreadView/ThreadView'
import { ListViewContextProvider, ViewConfig } from './ListViewContext'
import { ListViewControls } from './ListViewControls'
import { ListViewFrame } from './ListViewFrame'
import { isTurboView } from './utils'

type ListViewProps = React.ComponentPropsWithoutRef<typeof ListViewWrapper> & {
    view: ViewDto
    showControls?: boolean
    context?: ViewLayoutContext
    onChange?: (options: Partial<ListViewOptions>) => void
}

export const ListView: React.FC<ListViewProps> = ({
    view: originalView,
    context,
    onChange,
    showControls,
    // @ts-expect-error
    isRecordList,
    // @ts-expect-error
    recordListTitle,
    // @ts-expect-error
    hideSearch,
    // @ts-expect-error
    relatedFieldMayCreateNewRecords,
    // @ts-expect-error
    relatedListField,
    // @ts-expect-error
    autoFilledRelatedListRecord,
    // @ts-expect-error
    relatedListRecord,
    // @ts-expect-error
    additionalFilters,
    // @ts-expect-error
    allowDownload,
    ...props
}) => {
    const viewConfig = useViewConfig(
        originalView,
        context?.page,
        undefined,
        context?.object
    ) as ViewConfig

    const view = viewConfig?.viewState?.view
    if (!view) return null

    if (view.type === 'list' && isTurboView(view.options.display)) {
        let content = <TableView />
        if (view.options.display === 'boardV2') {
            content = <BoardView />
        }

        if (view.options.display === 'cardV2') {
            content = <CardView />
        }

        if (view.options.display === 'thread') {
            content = <ThreadView />
        }

        const defaultAllowCreate = !isRecordList

        return (
            <ListViewContextProvider
                context={context}
                isEditMode={showControls}
                isEmbedded={isRecordList}
                title={recordListTitle}
                allowSearch={!hideSearch}
                allowCreate={relatedFieldMayCreateNewRecords ?? defaultAllowCreate}
                embeddedByFieldSid={relatedListField}
                embeddedByFieldValue={autoFilledRelatedListRecord}
                embeddedByRecord={relatedListRecord}
                additionalFilters={additionalFilters}
                allowDownload={allowDownload}
                viewConfig={viewConfig}
            >
                {isRecordList ? (
                    <Container p={0} m={0} overflow="hidden">
                        {content}
                    </Container>
                ) : (
                    <ListViewFrame>
                        {content}
                        {showControls && <ListViewControls />}
                    </ListViewFrame>
                )}
            </ListViewContextProvider>
        )
    }

    // Render V2 list view if it's not one of the new list views.
    return (
        <ListViewWrapper
            // @ts-expect-error
            view={view}
            context={context}
            onChange={onChange}
            showControls={showControls}
            isRecordList={isRecordList}
            recordListTitle={recordListTitle}
            hideSearch={hideSearch}
            relatedFieldMayCreateNewRecords={relatedFieldMayCreateNewRecords}
            relatedListField={relatedListField}
            autoFilledRelatedListRecord={autoFilledRelatedListRecord}
            relatedListRecord={relatedListRecord}
            additionalFilters={additionalFilters}
            allowDownload={allowDownload}
            viewConfig={viewConfig}
            {...props}
        />
    )
}
