import React from 'react'

import History from 'history'

type NavigationBreadcrumbItem = {
    title: string
    recordId: string
    objectId: string
    hash: string
    recordListState?: Omit<PreviewRecordContextRecordList, 'goNext' | 'goPrevious'>
    detailTabName?: string
}

export type NavigationState = {
    recordListState?: Omit<PreviewRecordContextRecordList, 'goNext' | 'goPrevious'>
    previousItem?: NavigationBreadcrumbItem
    scrollPosition?: PreviewRecordScrollPosition
    detailTabName?: string
    currentObjectId?: string
}

export type PreviewRecordListItem = {
    recordId: string
}

export type PreviewRecordList = {
    items: PreviewRecordListItem[]
    direction: 'horizontal' | 'vertical'
}

export type PreviewRecordContextRecordList = {
    items: PreviewRecordListItem[]
    totalCount: number
    currentIndex: number
    direction: 'horizontal' | 'vertical'
    prevRecordId: string | null
    nextRecordId: string | null
    goNext: () => void
    goPrevious: () => void
}

export type PreviewRecordContextBreadcrumbs = NavigationBreadcrumbItem[]

export type PreviewRecordScrollPosition = [number, number]

export type PreviewRecordContext = {
    recordId: string | null
    objectId: string | null
    object?: ObjectDto
    isModalOpen: boolean
    previewRecord: (params: {
        recordId: string
        objectId: string
        partOfRecordList?: PreviewRecordList
        recordListOnlyOnInitialOpen?: boolean
    }) => void
    goFullscreen: () => void
    closePreview: () => void
    recordList?: PreviewRecordContextRecordList
    breadcrumbs?: NavigationBreadcrumbItem[]
    goBack: () => void
    fullscreenPath?: History.LocationDescriptor
}

const defaultContext: PreviewRecordContext = {
    recordId: null,
    objectId: null,
    isModalOpen: false,
    previewRecord: () => {},
    goFullscreen: () => {},
    closePreview: () => {},
    goBack: () => {},
}

export const PreviewRecordContext = React.createContext<PreviewRecordContext>(defaultContext)
