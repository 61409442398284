import React, { useState } from 'react'

import { indexTable } from 'data/hooks/objects/objectOperations'
import { Divider, ExpandSection } from 'legacy/v1/ui'

import { Box, Button, Checkbox, Text, Toast } from 'v2/ui'

type IndexTableSettingsProps = {
    object: ObjectDto
}

export const IndexTableSettings: React.FC<IndexTableSettingsProps> = ({ object }) => {
    const [hasIndexError, setHasIndexError] = useState(false)

    return (
        <>
            <Divider />
            <ExpandSection
                heading="Index settings"
                text="Enable semantic search for this table for us with AI agents."
                helpUrl=""
                helpText=""
            >
                {/*@ts-expect-error*/}
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box>
                        <Text fontWeight="bold" color="neutral.900">
                            Index table for AI search
                        </Text>
                    </Box>
                    <IndexTableButton
                        object={object}
                        onIndexSuccess={() => setHasIndexError(false)}
                        onIndexError={() => setHasIndexError(true)}
                    />
                    <Toast
                        status="error"
                        show={hasIndexError}
                        title="Indexing table failed. Please try again."
                        onCloseComplete={() => setHasIndexError(false)}
                    />
                </Box>
            </ExpandSection>
        </>
    )
}

type IndexTableButtonProps = {
    object: ObjectDto
    onIndexSuccess: () => void
    onIndexError: () => void
}

const IndexTableButton: React.FC<IndexTableButtonProps> = ({
    object,
    onIndexSuccess,
    onIndexError,
}) => {
    function handleIndexTable() {
        return indexTable(object._sid, onIndexSuccess, onIndexError)
    }

    if (object?.is_embeddings_enabled) {
        return (
            <Checkbox isDisabled isChecked={true}>
                This table is indexed and searchable.
            </Checkbox>
        )
    }

    return (
        <Button buttonSize="medium" variant="Primary" onClick={handleIndexTable} alignSelf="start">
            Index
        </Button>
    )
}
