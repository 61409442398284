import { useCallback, useMemo, useRef, useState } from 'react'

import { useRecordLinksAttributeDisplayState } from 'features/views/attributes/hooks/useRecordLinksAttributeDisplayState'
import { useDetailViewAttributeValue } from 'features/views/DetailView/attributes/hooks/useDetailViewAttributeValue'
import { useRecordManagerContext } from 'features/views/RecordManager/useRecordManagerContext'

type UseRecordLinksAttributeStateProps = {
    field: FieldDto
    isLoading?: boolean
}

export function useRecordLinksAttributeState({
    field,
    isLoading,
}: UseRecordLinksAttributeStateProps) {
    const value = useDetailViewAttributeValue<string | string[]>(field.api_name)

    const { dereferencedRecords } = useRecordManagerContext()

    const {
        records,
        isUsersObject,
        users,
        isOverflowPopupOpen,
        onOverflowLabelCloseAutoFocus,
        onOverflowLabelMouseEnter,
        onOverflowLabelMouseLeave,
        onOverflowPopupOpenChange,
    } = useRecordLinksAttributeDisplayState({
        field,
        value,
        dereferencedRecords,
        isLoading,
    })
    const onLinkClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
    }, [])

    const attributeRef = useRef<HTMLDivElement>(null)

    const isEmpty = !isLoading && (!value || (Array.isArray(value) && value.length < 1))

    const initialLinksLength = attributeRef.current?.querySelectorAll('a')?.length ?? 0
    const [visibleLinksLength, setVisibleLinksLength] = useState(initialLinksLength)

    const onTruncate = useCallback(() => {
        const linksLength = attributeRef.current?.querySelectorAll('a')?.length ?? 0
        setVisibleLinksLength(linksLength)
    }, [])

    return useMemo(
        () => ({
            records,
            isUsersObject,
            users,
            onLinkClick,
            attributeRef,
            isOverflowPopupOpen,
            onOverflowLabelCloseAutoFocus,
            onOverflowLabelMouseEnter,
            onOverflowLabelMouseLeave,
            onOverflowPopupOpenChange,
            isEmpty,
            visibleLinksLength,
            onTruncate,
        }),
        [
            records,
            isUsersObject,
            users,
            onLinkClick,
            isOverflowPopupOpen,
            onOverflowLabelCloseAutoFocus,
            onOverflowLabelMouseEnter,
            onOverflowLabelMouseLeave,
            onOverflowPopupOpenChange,
            isEmpty,
            visibleLinksLength,
            onTruncate,
        ]
    )
}
