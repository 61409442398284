import { useMemo } from 'react'

import { getFieldIcon } from 'features/admin/fields/icons/utils'
import { useDetailViewContext } from 'features/views/DetailView/hooks/useDetailViewContext'
import { DetailViewHeaderSpacious } from 'features/views/DetailView/types'

type BaseAttributeStateProps = {
    field: FieldDto
}

export function useBaseAttributeState({ field }: BaseAttributeStateProps) {
    const { header } = useDetailViewContext()!

    const fieldIcon = getFieldIcon(field)
    const showFieldIcon = !!(header as DetailViewHeaderSpacious)?.showPrimaryHighlightsIcon

    return useMemo(
        () => ({
            fieldIcon,
            showFieldIcon,
        }),
        [fieldIcon, showFieldIcon]
    )
}
