import React, { useCallback, useRef } from 'react'

import { getFieldIcon } from 'features/admin/fields/icons/utils'
import { useListViewControlFieldInputState } from 'features/views/ListView/hooks/useListViewControlFieldInputState'
import { ListViewControlItem } from 'features/views/ListView/ListViewControlItem'

import { Box } from 'ui/components/Box'
import { Select, SelectOption } from 'ui/components/Select'
import { Toggle } from 'ui/components/Toggle'

type ThreadViewMessageHeaderControlsProps = {
    objectSid: string
    setConfig: (config: Partial<ListViewOptions>) => void
    config: ListViewOptions
    fields: FieldDto[]
}

export const ThreadViewMessageHeaderControls: React.FC<ThreadViewMessageHeaderControlsProps> = ({
    objectSid,
    fields,
    config,
    setConfig,
}) => {
    return (
        <ListViewControlItem label="Message header" icon="PanelTop">
            <ThreadViewMessageHeaderGenericControls
                maxWidth="full"
                minWidth="300px"
                objectSid={objectSid}
                fields={fields}
                config={config}
                setConfig={setConfig}
            />
        </ListViewControlItem>
    )
}

type ThreadViewMessageHeaderGenericControlsProps = React.ComponentPropsWithoutRef<typeof Box> &
    ThreadViewMessageHeaderControlsProps

export const ThreadViewMessageHeaderGenericControls: React.FC<
    ThreadViewMessageHeaderGenericControlsProps
> = ({ objectSid, fields, config, setConfig, ...props }) => {
    return (
        <Box flex flexDirection="column" gap="l" {...props}>
            <TitleControls
                objectSid={objectSid}
                config={config}
                setConfig={setConfig}
                fields={fields}
            />
            <EyebrowControls
                objectSid={objectSid}
                config={config}
                setConfig={setConfig}
                fields={fields}
            />
        </Box>
    )
}

const EyebrowControls: React.FC<ThreadViewMessageHeaderControlsProps> = ({
    config,
    setConfig,
    fields,
    objectSid,
}) => {
    const existingConfigRef = useRef(config)
    existingConfigRef.current = config

    const eyebrowConfig = config.threadMessageEyebrow

    const { selectedField } = useListViewControlFieldInputState({
        fields,
        value: eyebrowConfig?.fieldSid,
        objectSid,
    })

    let fieldSid = eyebrowConfig?.fieldSid
    // If the field is not in the list of fields, reset the fieldSid.
    if (!selectedField) {
        fieldSid = undefined
    }

    const onChangeFieldSid = useCallback(
        (value: string) => {
            const existingConfig = existingConfigRef.current

            const newValue: ListViewOptions['threadMessageEyebrow'] = value
                ? {
                      ...existingConfig.threadMessageEyebrow,
                      fieldSid: value,
                  }
                : undefined

            setConfig({
                threadMessageEyebrow: newValue,
            })
        },
        [setConfig]
    )

    return (
        <>
            <Select
                placeholder="Select field..."
                label="Eyebrow"
                value={fieldSid}
                onChange={onChangeFieldSid}
                isClearable
                isSearchable
            >
                {fields.map((field) => (
                    <SelectOption
                        key={field._sid}
                        value={field._sid}
                        label={field.label}
                        startIcon={getFieldIcon(field)}
                    />
                ))}
            </Select>
            {(selectedField?.type === 'date' || selectedField?.type === 'datetime') && (
                <Toggle
                    checked={eyebrowConfig?.fieldDisplayOptions?.showAsRelativeTime}
                    onCheckedChange={(isChecked) => {
                        setConfig({
                            threadMessageEyebrow: {
                                ...eyebrowConfig,
                                fieldDisplayOptions: {
                                    ...eyebrowConfig?.fieldDisplayOptions,
                                    showAsRelativeTime: isChecked,
                                },
                            } as ListViewOptions['threadMessageEyebrow'],
                        })
                    }}
                >
                    Show as relative
                </Toggle>
            )}
        </>
    )
}

const TitleControls: React.FC<ThreadViewMessageHeaderControlsProps> = ({
    config,
    setConfig,
    fields,
}) => {
    const existingConfigRef = useRef(config)
    existingConfigRef.current = config

    const field = fields.find((field) => field._sid === config.threadMessageTitle?.fieldSid)

    const onChangeFieldSid = useCallback(
        (value?: string) => {
            const existingConfig = existingConfigRef.current

            const newValue: ListViewOptions['threadMessageTitle'] = value
                ? {
                      ...existingConfig.threadMessageTitle,
                      fieldSid: value,
                  }
                : undefined

            setConfig({
                threadMessageTitle: newValue,
            })
        },
        [setConfig]
    )

    return (
        <Select
            label="Title"
            size="m"
            placeholder="Select field..."
            value={field?._sid ?? undefined}
            onChange={onChangeFieldSid}
            isClearable
            isSearchable
        >
            {fields.map((field) => (
                <SelectOption
                    key={field._sid}
                    value={field._sid}
                    label={field.label}
                    startIcon={getFieldIcon(field)}
                />
            ))}
        </Select>
    )
}
