import React, { useMemo } from 'react'

import { useObjects } from 'data/hooks/objects'
import { useAppUsersForAdmin } from 'data/hooks/users/useAppUsersForAdmin'
import {
    filtersToValue,
    formatFilters,
    ObjectFieldsFilterV4 as Filters,
} from 'features/records/components/RecordFilters'
import { ObjectPicker } from 'features/studio/ui/ObjectPicker'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Field } from 'ui/components/Field'
import { Select, SelectOption } from 'ui/components/Select'

import { TriggerConfigProps } from './types'

export type RecordCreatedTriggerConfigType = {
    object_sid: string
    user_sid: string
    filters?: FilterValue[]
}

export function RecordCreatedTriggerConfig({
    config,
    onChange,
}: TriggerConfigProps<RecordCreatedTriggerConfigType>) {
    const { data: users } = useAppUsersForAdmin(true)
    const { data: objects } = useObjects()
    const selectedObject = useMemo(
        () => objects.find(({ _sid }) => _sid === config.object_sid),
        [objects, config.object_sid]
    )

    return (
        <Box flex column gap="m">
            <Field label="Table" width="5xl" shrink>
                <ObjectPicker
                    value={config.object_sid}
                    onChange={(value: string) =>
                        onChange({
                            ...config,
                            object_sid: value,
                        })
                    }
                />
            </Field>
            {false && ( // Filters component doesn't work in a modal right now. Need to rework that component.
                <Field label="Filters" width="5xl" shrink>
                    <Filters
                        object={selectedObject}
                        fields={selectedObject?.fields}
                        value={filtersToValue(config.filters || [], selectedObject)}
                        onChange={(filters) =>
                            onChange({ ...config, filters: formatFilters(filters) })
                        }
                        color="white"
                        customButtonRender={(props) => (
                            <Button
                                {...props}
                                type="button"
                                variant="link"
                                startIcon={{ name: 'Plus' }}
                            >
                                Add filter
                            </Button>
                        )}
                        hideTheRecordFilter
                    />
                </Field>
            )}
            <Field label="Run as User" width="5xl" shrink>
                <Select
                    value={config.user_sid}
                    onChange={(value) =>
                        onChange({
                            ...config,
                            user_sid: value,
                        })
                    }
                >
                    {users?.map((user: UserDto) => (
                        <SelectOption key={user._sid} value={user._sid} label={user.name} />
                    ))}
                </Select>
            </Field>
        </Box>
    )
}
