import React from 'react'

import { LongTextAttributeDisplay } from 'features/views/attributes/LongTextAttributeDisplay'
import { useDetailViewAttributeValue } from 'features/views/DetailView/attributes/hooks/useDetailViewAttributeValue'

import { BaseAttribute } from './BaseAttribute'
import { PrimaryHighlightsAttributeComponent } from './types'

type LongTextAttributeProps = {}

export const LongTextAttribute: PrimaryHighlightsAttributeComponent<LongTextAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const value = useDetailViewAttributeValue<string>(field.api_name)
    const isEmpty = !isLoading && !value

    return (
        <BaseAttribute {...props} field={field} isEmpty={isEmpty} isLoading={isLoading}>
            <LongTextAttributeDisplay
                value={value}
                field={field}
                isLoading={isLoading}
                maxLines={1}
                style={{
                    fontSize: 'inherit',
                    fontWeight: 'inherit',
                    lineHeight: 'inherit',
                }}
                alwaysShowOverflowTooltip={{
                    mobile: false,
                    tablet: true,
                }}
                trim
                maxWidth="full"
                minWidth={0}
            />
        </BaseAttribute>
    )
}
