import React from 'react'

import { Body } from 'ui/components/Text'

import { FieldsWidgetAttributeField } from './types'

type ValuePlaceholderProps = React.ComponentPropsWithoutRef<typeof Body> & {
    field: FieldsWidgetAttributeField
}

export const ValuePlaceholder = React.forwardRef<HTMLSpanElement, ValuePlaceholderProps>(
    ({ field, ...props }, ref) => {
        return (
            <Body ref={ref} size="m" color="textWeakest" {...props}>
                {field.placeholder}
            </Body>
        )
    }
)
