import React from 'react'

import { useRoleListOptions } from 'data/hooks/roles'

import { Box } from 'ui/components/Box'
import { Dropdown, DropdownButton, DropdownContent, DropdownItem } from 'ui/components/Dropdown'
import { Field } from 'ui/components/Field'
import { Input } from 'ui/components/Input'

import { TriggerConfigProps } from './types'

export type AppChatBotTriggerConfigType = {
    welcome_message: string
    show_in_app_bar_for_roles: string[]
}

export function AppChatBotTriggerConfig({
    config,
    onChange,
}: TriggerConfigProps<AppChatBotTriggerConfigType>) {
    const { data: roles } = useRoleListOptions()

    return (
        <Box flex column gap="m">
            <Field label="Welcome Message" width="5xl">
                <Input
                    name="welcome_message"
                    value={config.welcome_message}
                    onChange={(e) =>
                        onChange({
                            ...config,
                            welcome_message: e.target.value,
                        })
                    }
                    placeholder="Enter a welcome message for your chatbot"
                />
            </Field>
            <Field label="Show in sidebar for" width="5xl">
                <Dropdown>
                    <DropdownButton variant="secondary" endIcon={{ name: 'ChevronDown' }}>
                        {config.show_in_app_bar_for_roles?.length
                            ? `${config.show_in_app_bar_for_roles.length} roles selected`
                            : 'Select roles'}
                    </DropdownButton>
                    <DropdownContent>
                        {roles?.map((role) => (
                            <DropdownItem
                                key={role.value}
                                label={role.label}
                                multiSelect
                                checked={config.show_in_app_bar_for_roles?.includes(role.value)}
                                onCheckedChange={(checked) => {
                                    const newRoles = checked
                                        ? [...(config.show_in_app_bar_for_roles || []), role.value]
                                        : (config.show_in_app_bar_for_roles || []).filter(
                                              (r) => r !== role.value
                                          )
                                    onChange({
                                        ...config,
                                        show_in_app_bar_for_roles: newRoles,
                                    })
                                }}
                            />
                        ))}
                    </DropdownContent>
                </Dropdown>
            </Field>
        </Box>
    )
}
