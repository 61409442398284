import React, { forwardRef } from 'react'

import { Button } from 'ui/components/Button'

type Props = Omit<React.ComponentPropsWithoutRef<typeof Button>, 'children'>
const CloseButton = forwardRef<HTMLButtonElement, Props>(({ onClick, ...props }, ref) => {
    return (
        <Button
            ref={ref}
            onClick={onClick}
            variant="ghost"
            size="s"
            startIcon={{
                name: 'X',
            }}
            {...props}
        />
    )
})

export default CloseButton
