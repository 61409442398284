import { isMultiLineText } from 'data/utils/fieldDefinitions'
import { ContextEntry, ContextGroup, ContextItem } from 'features/expressions/types'
import { filterableTypes } from 'features/records/components/RecordFilters/constants'

export function getConditionSupportedRHSType(
    lhsType: FieldType | undefined,
    lhsTypeOptions: FieldOptions['options'] | undefined,
    operation: FilterOperation
): {
    type?: FieldType
    typeOptions?: FieldOptions['options']
} {
    if (!lhsType) return {}

    switch (operation) {
        case 'is':
        case 'isnt':
            switch (lhsType) {
                case 'dropdown':
                case 'multi_select':
                case 'checkbox':
                case 'lookup':
                case 'multi_lookup':
                case 'user_role':
                    return {
                        type: lhsType,
                        typeOptions: lhsTypeOptions,
                    }
                default:
                    return {
                        type: 'string',
                        typeOptions: lhsTypeOptions,
                    }
            }

        case 'listIs':
        case 'listIsnt':
        case 'oneOf':
        case 'noneOf':
        case 'containsAny':
        case 'containsNone':
            switch (lhsType) {
                case 'dropdown':
                case 'multi_select':
                    return { type: 'multi_select', typeOptions: lhsTypeOptions }
                case 'lookup':
                case 'multi_lookup':
                    return {
                        type: 'multi_lookup',
                        typeOptions: lhsTypeOptions,
                    }
                case 'user_role':
                    return {
                        type: lhsType,
                        typeOptions: lhsTypeOptions,
                    }
                default:
                    return {}
            }

        case 'contains':
        case "doesn't contain":
        case 'startsWith':
        case 'endsWith':
        case 'equals':
        case 'notEquals':
        case 'greaterThan':
        case 'lessThan':
        case 'equalsOrGreaterThan':
        case 'equalsOrLessThan':
            return {
                type: 'string',
                typeOptions: lhsTypeOptions,
            }

        case 'beforeDate':
        case 'afterDate':
        case 'sameDay':
        case 'withinNext':
        case 'withinLast':
        case 'isCurrentUserField':
        case 'isNotCurrentUserField':
        case 'containsCurrentUserField':
        case 'doesNotContainCurrentUserField':
        case 'isContextRecordField':
        case 'isNotContextRecordField':
        case 'containsContextRecordField':
        case 'doesNotContainContextRecordField':
            return {
                type: lhsType,
                typeOptions: lhsTypeOptions,
            }
    }

    return {}
}

export function filterSupportedContextItems(entries: ContextEntry[]): ContextEntry[] {
    return entries.reduce<ContextEntry[]>((acc, entry) => {
        if (entry.type === 'group') {
            const group = entry as ContextGroup
            const filteredItems = group.items ? filterSupportedContextItems(group.items) : undefined

            acc.push({
                ...group,
                items: filteredItems,
            } as ContextGroup)
        }

        if (isContextItemSupported(entry)) {
            acc.push(entry)
        }

        return acc
    }, [])
}

function isContextItemSupported(entry: ContextEntry): boolean {
    if (entry.type === 'group') {
        return true
    }

    if (
        isMultiLineText(
            (entry as ContextItem).type as FieldType,
            (entry as ContextItem).extra_options
        )
    )
        return false

    return filterableTypes.includes(entry.type)
}
