import { useMemo } from 'react'

import { useRecordActionButtons } from 'features/views/ListView/Actions/hooks/useRecordActionButtons'
import { ThreadViewFooterAttribute } from 'features/views/ListView/ThreadView/types'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useThreadViewContext } from './useThreadViewContext'

type UseThreadViewMessageFooterStateProps = {
    record?: RecordDto
}

export function useThreadViewMessageFooterState({ record }: UseThreadViewMessageFooterStateProps) {
    const { footerLeftField, footerRightField, view, object } = useThreadViewContext()

    const threadFooter = view.options.threadMessageFooter

    const actions = useRecordActionButtons({
        record: record!,
        object,
        view,
        showSystemActions: false,
        filterByActionDisplay: false,
    })

    const footerLeftValue = threadFooter?.leftFieldSid
    const footerLeftButton = useMemo(() => {
        const action = actions?.find((a) => a.action._sid === footerLeftValue)

        return action?.action
    }, [actions, footerLeftValue])

    const footerLeftAttribute = useMemo(() => {
        if (!!footerLeftButton) return undefined

        return getFooterAttribute(
            footerLeftField,
            footerLeftValue,
            threadFooter?.leftFieldDisplayOptions
        )
    }, [footerLeftButton, footerLeftField, footerLeftValue, threadFooter?.leftFieldDisplayOptions])

    const footerRightValue = threadFooter?.rightFieldSid
    const footerRightButton = useMemo(() => {
        const action = actions?.find((a) => a.action._sid === footerRightValue)

        return action?.action
    }, [actions, footerRightValue])

    const footerRightAttribute = useMemo(() => {
        if (!!footerRightButton) return undefined

        return getFooterAttribute(
            footerRightField,
            footerRightValue,
            threadFooter?.rightFieldDisplayOptions
        )
    }, [
        footerRightButton,
        footerRightField,
        footerRightValue,
        threadFooter?.rightFieldDisplayOptions,
    ])

    const hasDoubleActions = !!footerLeftButton && !!footerRightButton

    let hideFooter =
        !footerLeftAttribute && !footerLeftButton && !footerRightAttribute && !footerRightButton
    if (!!threadFooter?.hide) {
        hideFooter = threadFooter.hide
    }

    return useDeepEqualsMemoValue({
        footerLeftAttribute,
        footerLeftButton,
        footerRightAttribute,
        footerRightButton,
        hasDoubleActions,
        hideFooter,
    })
}

const getFooterAttribute = (
    field?: FieldDto,
    value?: string,
    displayOptions?: ListViewColumnConfigDisplayOptions
): ThreadViewFooterAttribute | undefined => {
    if (value === '_record_stats') {
        return value
    }

    if (!field) return undefined

    return {
        id: field._sid,
        title: field.label,
        field,
        rawOptions: displayOptions ?? {},
        type: 'footer',
    }
}
