import React, { useCallback } from 'react'

import { MiddleTruncate } from '@re-dev/react-truncate'

import { useUrlAttributeDisplayState } from 'features/views/attributes/hooks/useUrlAttributeDisplayState'
import { useDetailViewAttributeValue } from 'features/views/DetailView/attributes/hooks/useDetailViewAttributeValue'

import { Link } from 'ui/components/Link'
import { Skeleton } from 'ui/components/Skeleton'
import { Tooltip } from 'ui/components/Tooltip'

import { BaseAttribute } from './BaseAttribute'
import { PrimaryHighlightsAttributeComponent } from './types'

type UrlAttributeProps = {}

export const UrlAttribute: PrimaryHighlightsAttributeComponent<UrlAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const value = useDetailViewAttributeValue<string>(field.api_name)

    const isEmpty = !isLoading && !value

    const { target, to, href, label } = useUrlAttributeDisplayState({
        field: field,
        value,
        isLoading,
    })

    const handleClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
    }, [])

    return (
        <BaseAttribute
            {...props}
            field={field}
            isEmpty={isEmpty}
            isLoading={isLoading}
            width="100vw"
            maxWidth="full"
            minWidth={0}
        >
            {(!!href || !!to) && (
                <Skeleton isLoading={isLoading} width="full">
                    <Tooltip
                        asChild
                        content={href || to || label}
                        zIndex={200}
                        side="bottom"
                        align="start"
                    >
                        <Link
                            href={href}
                            to={to}
                            target={target}
                            onClick={handleClick}
                            tabIndex={0}
                            width="full"
                            style={{
                                fontSize: 'inherit',
                                fontWeight: 'inherit',
                                lineHeight: 'inherit',
                            }}
                        >
                            <MiddleTruncate end={7}>{label}</MiddleTruncate>
                        </Link>
                    </Tooltip>
                </Skeleton>
            )}
        </BaseAttribute>
    )
}
