import { useCallback } from 'react'

import { useObjects } from 'data/hooks/objects'
import { useWorkflowSchema } from 'data/hooks/workflows/workflows'
import { WorkflowSchemaActionStateItemAvailability } from 'data/hooks/workflowTypes'
import { fieldToWorkflowExtraOptions } from 'features/workflows/fieldToWorkflowExtraOptions'

export function useGetRecordActionFinalSchema() {
    const { data: objects } = useObjects()
    const { data: schema } = useWorkflowSchema()
    return useCallback(
        (config: WorkflowActionConfig): WorkflowSchemaNodeType | undefined => {
            const type = schema?.nodes.find(
                (t) => t.id === config.action_type
            ) as WorkflowSchemaActionType
            const object = objects?.find((o) => o._sid === config.settings?.object_id)

            if (!type || !object) {
                return undefined
            }

            return {
                ...type,
                state: [
                    ...type.state.map((o) => {
                        if (['record_data'].includes(o.id)) {
                            const group = o as WorkflowSchemaStateItemGroup
                            return {
                                ...group,
                                items: group.items.concat(
                                    object?.fields.map((f) => ({
                                        id: f.api_name,
                                        name: f.label,
                                        type: f.type,
                                        extra_options: fieldToWorkflowExtraOptions(f),
                                        availability:
                                            WorkflowSchemaActionStateItemAvailability.AfterExecution,
                                    })) as WorkflowSchemaActionStateItem[]
                                ),
                            }
                        } else if (o.id === 'record_id') {
                            return {
                                ...o,
                                extra_options: { link_target_object_id: object._sid },
                            }
                        }
                        return o
                    }),
                ],
            }
        },
        [objects, schema?.nodes]
    )
}
