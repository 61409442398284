import React from 'react'

import { RichTextAttributeDisplay } from 'features/views/attributes/RichTextAttributeDisplay'
import { RichTextValue } from 'features/views/attributes/types'
import { useDetailViewAttributeValue } from 'features/views/DetailView/attributes/hooks/useDetailViewAttributeValue'

import { BaseAttribute } from './BaseAttribute'
import { PrimaryHighlightsAttributeComponent } from './types'

type RichTextAttributeProps = {}

export const RichTextAttribute: PrimaryHighlightsAttributeComponent<RichTextAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const value = useDetailViewAttributeValue<RichTextValue>(field.api_name)
    const isEmpty = !isLoading && (!value || (!!value && !value?.plainTextContent))

    return (
        <BaseAttribute {...props} field={field} isEmpty={isEmpty} isLoading={isLoading}>
            <RichTextAttributeDisplay
                value={value}
                field={field}
                isLoading={isLoading}
                maxLines={1}
                style={{
                    fontSize: 'inherit',
                    fontWeight: 'inherit',
                    lineHeight: 'inherit',
                }}
                alwaysShowOverflowTooltip={{
                    mobile: false,
                    tablet: true,
                }}
                trim
                maxWidth="full"
                minWidth={0}
            />
        </BaseAttribute>
    )
}
