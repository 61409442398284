import { useNotesEnabled } from 'features/documents/useNotesEnabled'
import { useProcessFilter } from 'features/records/components/RecordFilters'
import { useTasksEnabled } from 'features/tasks/useTasksEnabled'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'
import { TabsWidgetTab, TabsWidgetType } from 'features/views/LayoutEditor/widgets/tabsWidgetTypes'
import { useRecordManagerContext } from 'features/views/RecordManager/useRecordManagerContext'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

const SYSTEM_TABS: TabsWidgetTab[] = [
    {
        id: 'details',
        urlSlug: 'details',
        title: 'Details',
        conditions: [],
        isActive: true,
    },
    {
        id: 'activity',
        urlSlug: 'feed',
        title: 'Feed',
        conditions: [],
        isActive: true,
    },
    {
        id: 'tasks',
        urlSlug: 'tasks',
        title: 'Tasks',
        conditions: [],
        isActive: true,
    },
    {
        id: 'documents',
        urlSlug: 'notes',
        title: 'Notes',
        conditions: [],
        isActive: true,
    },
]

export function useTabsWidgetTabs(widget: TabsWidgetType) {
    const activeTabs = widget.attrs.tabs || []

    const allTabs = activeTabs.reduce(
        (acc, tab) => {
            acc[tab.id] = tab
            return acc
        },
        {} as Record<string, TabsWidgetTab>
    )

    // Add system tabs if they are not already in the active tabs list.
    for (const tab of SYSTEM_TABS) {
        if (!allTabs[tab.id]) {
            allTabs[tab.id] = tab
        }
    }

    const effectiveAllTabs = useFilteredTabs(Object.values(allTabs))
    const effectiveActiveTabs = useFilteredTabs(activeTabs.filter((tab) => tab.isActive))

    const { record } = useRecordManagerContext()

    const { isEditing } = useLayoutEditorContext()

    const processFilter = useProcessFilter()
    const visibleTabs = effectiveActiveTabs.filter((tab) => {
        // If the tab has no title or icon, we don't want to show it.
        if (!tab.title && !tab.icon) {
            return false
        }

        // If we are editing, we want to show all tabs.
        if (!!record && !isEditing) {
            const filteredData = processFilter([record], tab.conditions)
            return filteredData.length > 0
        }

        return true
    })

    return useDeepEqualsMemoValue({
        allTabs: effectiveAllTabs,
        activeTabs: effectiveActiveTabs,
        visibleTabs,
    })
}

function useFilteredTabs(tabs: TabsWidgetTab[]) {
    const tasksEnabled = useTasksEnabled()
    const notesEnabled = useNotesEnabled()

    return tabs.filter((tab) => {
        if (tab.id === 'tasks' && !tasksEnabled) {
            return false
        }

        if (tab.id === 'documents' && !notesEnabled) {
            return false
        }

        return true
    })
}
