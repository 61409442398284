import React from 'react'
import { Link } from 'react-router-dom'

import { ActionContextMenu } from 'features/views/ListView/Actions/ActionContextMenu'
import { RecordActionButton } from 'features/views/ListView/Actions/RecordActionButton'

import { Avatar } from 'ui/components/Avatar'
import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'
import { Skeleton } from 'ui/components/Skeleton'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { ThreadViewRecordActions } from './Actions/ThreadViewRecordActions'
import { Attribute } from './attributes/Attribute'
import { useThreadViewMessageContentFieldsState } from './hooks/useThreadViewMessageContentFieldsState'
import { useThreadViewMessageContentState } from './hooks/useThreadViewMessageContentState'
import { useThreadViewMessageFooterState } from './hooks/useThreadViewMessageFooterState'
import { useThreadViewMessageHeaderState } from './hooks/useThreadViewMessageHeaderState'
import { useThreadViewMessageProfileImageState } from './hooks/useThreadViewMessageProfileImageState'
import { useThreadViewMessageState } from './hooks/useThreadViewMessageState'
import { ThreadViewCommentCount } from './ThreadViewCommentCount'
import { ThreadViewFooterAttribute } from './types'

import {
    ThreadViewMessageContentFieldsStyles,
    ThreadViewMessageContentStyles,
    ThreadViewMessageFooterColumnStyle,
    ThreadViewMessageFooterStyle,
    ThreadViewMessageHeaderStyles,
    ThreadViewMessageStyles,
    ThreadViewMessageTitleStyles,
} from './ThreadView.css'

type ThreadViewMessageProps = {
    record?: RecordDto
    isLoading?: boolean
}

export const ThreadViewMessage: React.FC<ThreadViewMessageProps> = ({
    record,
    isLoading = false,
}) => {
    const {
        to,
        onClick,
        actionContextMenuRef,
        messageRef,
        onContextMenu,
        isPending,
        actionButtons,
        includeFields,
        additionalEditFields,
        isFromSender,
    } = useThreadViewMessageState({ record })

    const tabIndex = isLoading || isPending ? -1 : undefined

    return (
        <Box
            ref={messageRef}
            className={ThreadViewMessageStyles.styleFunction({
                isLoading,
                isPending,
            })}
        >
            {record && (
                <>
                    <Box
                        flex
                        alignItems="flex-start"
                        flexDirection={isFromSender ? 'row-reverse' : 'row'}
                    >
                        <Box flex column noShrink>
                            <ThreadViewMessageProfileImage
                                record={record}
                                isLoading={isLoading}
                                to={to}
                                onClick={onClick}
                                onContextMenu={onContextMenu}
                                tabIndex={tabIndex}
                                isFromSender={isFromSender}
                            />
                        </Box>
                        <Box flex column shrink grow>
                            <ThreadViewMessageHeader
                                record={record}
                                isLoading={isLoading}
                                to={to}
                                onClick={onClick}
                                onContextMenu={onContextMenu}
                                tabIndex={tabIndex}
                                isFromSender={isFromSender}
                            />
                            <Box
                                flex
                                flexDirection={isFromSender ? 'row-reverse' : 'row'}
                                alignItems="flex-start"
                                py="2xs"
                                pl="l"
                                pr={{ mobile: 0, tablet: 'l' }}
                                gap="m"
                            >
                                <ThreadViewMessageContent
                                    record={record}
                                    isLoading={isLoading}
                                    onContextMenu={onContextMenu}
                                    tabIndex={tabIndex}
                                    isFromSender={isFromSender}
                                >
                                    <ThreadViewMessageFooter
                                        record={record}
                                        isLoading={isLoading}
                                        includeFields={includeFields}
                                    />
                                </ThreadViewMessageContent>
                                <ThreadViewRecordActions
                                    record={record}
                                    includeFields={includeFields}
                                    actionButtons={actionButtons}
                                    additionalEditFields={additionalEditFields}
                                />
                            </Box>
                        </Box>
                    </Box>
                    {!isLoading && (
                        <>
                            <ActionContextMenu
                                ref={actionContextMenuRef}
                                record={record}
                                actionButtons={actionButtons}
                                includeFields={includeFields}
                            />
                        </>
                    )}
                </>
            )}
        </Box>
    )
}

type ThreadViewMessageContentProps = Omit<React.ComponentPropsWithoutRef<typeof Box>, 'color'> & {
    record?: RecordDto
    isLoading?: boolean
    isFromSender?: boolean
}

const ThreadViewMessageContent: React.FC<ThreadViewMessageContentProps> = ({
    record,
    isLoading,
    children,
    isFromSender,
    ...props
}) => {
    const { attribute, value, messageSize } = useThreadViewMessageContentState({ record })

    return (
        <Box
            shrink
            draggable={false}
            className={ThreadViewMessageContentStyles.styleFunction({
                size: messageSize,
                isFromSender,
            })}
            {...props}
        >
            <ThreadViewMessageContentFields
                record={record}
                isLoading={isLoading}
                isFromSender={isFromSender}
            />
            {attribute && (
                <Box py="xs">
                    <Attribute attribute={attribute} isLoading={isLoading} value={value} />
                </Box>
            )}
            {children}
        </Box>
    )
}

type ThreadViewMessageContentFieldsProps = {
    record?: RecordDto
    isLoading?: boolean
    isFromSender?: boolean
}

const ThreadViewMessageContentFields: React.FC<ThreadViewMessageContentFieldsProps> = ({
    record,
    isLoading,
    isFromSender,
}) => {
    const { hasLabels, hasIcons, attributes, fieldDirection, shouldHide } =
        useThreadViewMessageContentFieldsState({ isFromSender })

    if (!record || shouldHide) return null

    return (
        <Box
            className={ThreadViewMessageContentFieldsStyles.styleFunction({
                hasLabels,
                hasIcons,
                direction: fieldDirection,
            })}
        >
            {attributes.map((attribute, idx) => {
                const value = record[attribute.field.api_name] ?? undefined
                const showSeparator =
                    !hasLabels &&
                    !hasIcons &&
                    fieldDirection === 'horizontal' &&
                    idx < attributes.length - 1

                return (
                    <Box key={attribute.id} flex center minWidth={0} maxWidth="full">
                        <Attribute attribute={attribute} isLoading={isLoading} value={value} />
                        {showSeparator && <Icon name="Dot" size="s" ml="s" color="gray900" />}
                    </Box>
                )
            })}
        </Box>
    )
}

type ThreadViewMessageHeaderProps = Omit<React.ComponentPropsWithoutRef<typeof Box>, 'color'> & {
    record?: RecordDto
    isLoading?: boolean
    isFromSender?: boolean
}

const ThreadViewMessageHeader: React.FC<ThreadViewMessageHeaderProps> = ({
    record,
    isLoading,
    isFromSender,
    to,
    ...props
}) => {
    const { titleAttribute, titleValue, eyebrowAttribute, eyebrowValue, titleSize } =
        useThreadViewMessageHeaderState({ record })

    if (!titleAttribute && !eyebrowAttribute) return null

    return (
        <Box
            py="s"
            px="l"
            maxWidth="full"
            flex
            justifyContent={isFromSender ? 'flex-end' : 'flex-start'}
            textAlign={isFromSender ? 'right' : 'left'}
        >
            <Box
                as={!!to ? Link : 'div'}
                // @ts-expect-error: TS doesn't infer that `to` is defined when `as` is a link.
                to={to}
                draggable={false}
                className={ThreadViewMessageHeaderStyles.styleFunction({})}
                {...props}
            >
                {titleAttribute && (
                    <Box
                        className={ThreadViewMessageTitleStyles.styleFunction({
                            size: titleSize,
                        })}
                        trim
                        shrink
                    >
                        <Attribute
                            attribute={titleAttribute}
                            isLoading={isLoading}
                            value={titleValue}
                        />
                    </Box>
                )}
                {eyebrowAttribute && (
                    <Box noShrink>
                        <Attribute
                            attribute={eyebrowAttribute}
                            isLoading={isLoading}
                            value={eyebrowValue}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    )
}

type ThreadViewMessageProfileImageProps = Omit<
    React.ComponentPropsWithoutRef<typeof Link>,
    'color'
> & {
    record?: RecordDto
    isLoading?: boolean
    isFromSender?: boolean
}

const ThreadViewMessageProfileImage: React.FC<ThreadViewMessageProfileImageProps> = ({
    record,
    isLoading,
    to,
    isFromSender,
    ...props
}) => {
    const { hasThumbnail, imageSrc, firstName, lastName } = useThreadViewMessageProfileImageState({
        record,
        isFromSender,
    })

    const avatarSize = useResponsiveValue({
        mobile: 's',
        tablet: 'l',
    })

    if (!hasThumbnail) return null

    return (
        <Box
            as={!!to ? Link : 'div'}
            // @ts-expect-error: TS doesn't infer that `to` is defined when `as` is a link.
            to={to}
            pt="s"
            {...props}
        >
            <Skeleton isLoading={isLoading}>
                <Avatar
                    size={avatarSize}
                    shape="square"
                    type={(!!imageSrc ? 'image' : 'initial') as any}
                    imageUrl={imageSrc}
                    firstName={firstName}
                    lastName={lastName}
                />
            </Skeleton>
        </Box>
    )
}

type ThreadViewMessageFooterProps = Omit<React.ComponentPropsWithoutRef<typeof Box>, 'color'> & {
    record: RecordDto
    isLoading?: boolean
    includeFields?: string[]
}

const ThreadViewMessageFooter: React.FC<ThreadViewMessageFooterProps> = ({
    record,
    isLoading,
    includeFields,
    ...props
}) => {
    const {
        footerLeftAttribute,
        footerLeftButton,
        footerRightAttribute,
        footerRightButton,
        hasDoubleActions,
        hideFooter,
    } = useThreadViewMessageFooterState({ record })

    if (hideFooter) return null

    return (
        <Box
            center
            gap={hasDoubleActions ? '2xs' : 's'}
            mt="xs"
            alignSelf="stretch"
            justifyContent={hasDoubleActions ? 'flex-end' : 'space-between'}
            className={ThreadViewMessageFooterStyle}
            {...props}
        >
            <Box trim shrink className={ThreadViewMessageFooterColumnStyle}>
                {(footerLeftAttribute || footerLeftButton) && (
                    <FooterAttribute
                        record={record}
                        isLoading={isLoading}
                        attribute={footerLeftAttribute}
                        action={footerLeftButton}
                        includeFields={includeFields}
                    />
                )}
            </Box>
            <Box trim shrink className={ThreadViewMessageFooterColumnStyle}>
                {(footerRightAttribute || footerRightButton) && (
                    <FooterAttribute
                        record={record}
                        isLoading={isLoading}
                        attribute={footerRightAttribute}
                        action={footerRightButton}
                        includeFields={includeFields}
                    />
                )}
            </Box>
        </Box>
    )
}

type FooterAttributeProps = {
    record: RecordDto
    attribute?: ThreadViewFooterAttribute
    isLoading?: boolean
    action?: ActionDto
    includeFields?: string[]
    actionVariant?: React.ComponentProps<typeof RecordActionButton>['variant']
}

const FooterAttribute: React.FC<FooterAttributeProps> = React.memo(function FooterAttribute({
    record,
    isLoading,
    attribute,
    action,
    includeFields,
    actionVariant = 'link',
}) {
    if (action) {
        return (
            <Skeleton isLoading={isLoading}>
                <RecordActionButton
                    key={action._sid}
                    record={record}
                    action={action}
                    includeFields={includeFields ?? []}
                    size="2xs"
                    variant={actionVariant}
                    maxWidth="full"
                    startIcon={undefined}
                />
            </Skeleton>
        )
    }

    if (!attribute) return null

    if (attribute === '_record_stats') {
        return <ThreadViewCommentCount record={record} isLoading={isLoading} />
    }

    return (
        <Attribute
            attribute={attribute}
            isLoading={isLoading}
            value={record[attribute.field.api_name] ?? undefined}
        />
    )
})
