import React from 'react'

import { useObjects } from 'data/hooks/objects'

import { Container } from 'ui/components/Container'
import { Body, Headline } from 'ui/components/Text'

export function StackerRecordRenderer({ data }: { data: string }) {
    const parsedData = JSON.parse(data)
    const { data: objects } = useObjects()

    const object = objects.find((object) => object.api_name === parsedData._object_id)

    if (!object) {
        return <div>Object not found</div>
    }

    return <StackerRecordCardDisplay data={parsedData} object={object} />
}

export function StackerRecordCardDisplay({
    data,
    object,
}: {
    data: Record<string, any>
    object: ObjectDto
}) {
    const primaryFieldApiName =
        object.fields.find((field) => field.is_primary)?.api_name || Object.keys(data)[0]

    const titleFieldValue = data[primaryFieldApiName]
    const otherFields = Object.entries(data).filter(
        ([key]) => key !== primaryFieldApiName && !key.startsWith('_')
    )
    const showLabels = otherFields.length > 1
    return (
        <Container variant="neutralMuted" p="m">
            <Headline size="3xs">{titleFieldValue}</Headline>
            <table>
                {otherFields.map(([key, value]) => (
                    <tr key={key}>
                        {showLabels && (
                            <td valign="top">
                                <Body pr="m" color="textWeak">
                                    {object.fields.find((field) => field.api_name === key)?.label}
                                </Body>
                            </td>
                        )}
                        <td>{value}</td>
                    </tr>
                ))}
            </table>
        </Container>
    )
}
