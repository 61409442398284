import React, { useCallback, useEffect, useMemo, useState } from 'react'

import classNames from 'classnames'

import { SERVER_PAGE_SIZE } from 'app/settings'
import { useAppContext } from 'app/useAppContext'
import { useRecords, useRecordsByIds } from 'data/hooks/records'
import { getCachedRecord } from 'data/hooks/records/getCachedRecord'
import { useAutoSelectSingleRecord } from 'features/records/hooks/useAutoSelectSingleRecord'
import { useIsSupportLoginPermitted } from 'utils/supportLogin'
import { ensureArray } from 'utils/utils'

import { Dropdown } from 'v2/ui'
import STYLE_CLASSES from 'v2/ui/styleClasses'
import useDebounce from 'v2/ui/utils/useDebounce'
import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { recordsToOptions } from './recordsToOptions'

function useSelectedRecordsFromCache(recordIds: string[]): RecordDto[] {
    // Note: want to exclude records here that are marked as
    // _fetch_failed, as those are only stub records left in the cache
    // to show that the fetch failed due to perms.
    return recordIds
        .map((id) => getCachedRecord(id))
        .filter((record): record is RecordDto => !!record && !record._fetch_failed)
}

export type RecordDropdownProps = {
    value: string | string[]
    includeFields: string[]
    distinctBy: string
    distinctOrderBy: string
    filters: Filter[]
    filter: (record: RecordDto) => boolean
    bypassPreviewAs: boolean
    objectId: string
    onChange: (value: string | string[]) => void
    onRecordsSelected: (records: RecordDto[]) => void
    forwardedRef: React.ForwardedRef<Element>
    showCreateButton: boolean
    isMulti?: boolean
    additionalRecords?: RecordDto[]
    style?: React.CSSProperties
    className?: string
    mode?: string
    autoSelectEnabled?: boolean
}

export const RecordDropdown: React.FC<RecordDropdownProps> = ({
    value,
    includeFields = [],
    distinctBy,
    distinctOrderBy,
    filters,
    bypassPreviewAs,
    filter,
    objectId,
    onChange,
    onRecordsSelected,
    forwardedRef,
    showCreateButton,
    additionalRecords,
    style,
    ...props
}) => {
    const getIncludeFields = () => {
        if (!includeFields.length) {
            includeFields.push('_primary')
        }
        if (distinctBy && !includeFields.includes(distinctBy)) {
            includeFields.push(distinctBy)
        }
        if (distinctOrderBy && !includeFields.includes(distinctOrderBy)) {
            includeFields.push(distinctOrderBy)
        }
        return includeFields
    }

    const [state, setState] = useState({
        filters: filters || [],
        searchValue: '',
    })

    const { selectedStack } = useAppContext()

    const fetchOptions = {
        includeFields: getIncludeFields(),
        pageSize: SERVER_PAGE_SIZE,
        forcePartials: true,
        bypassPreviewAs: bypassPreviewAs,
        stackId: selectedStack?._sid,
    }

    const incomingFilters = useDeepEqualsMemoValue(filters)
    const selectedValues = useMemo(() => (Array.isArray(value) ? value : [value]), [value])
    // We always want to have the selected records on hand, in case they don't come back
    // as part of the first page of results from the server query.
    // First we try to get them out of redux. They will be there if the detail page is
    // shown in non-edit mode first, as they are loaded as part of the attribute display.
    const selectedRecordsFromCache = useSelectedRecordsFromCache(selectedValues)
    const selectedRecordsFromAdditionalRecords = useMemo(
        () => additionalRecords?.filter((r) => selectedValues.includes(r._sid)) || [],
        [additionalRecords, selectedValues]
    )

    // don't need to fetch from the server if we have all the selected records already available
    const useCache =
        selectedRecordsFromCache.length + selectedRecordsFromAdditionalRecords.length ===
        selectedValues.length
    // However if the page starts in edit mode, then they may not be in redux, and if they aren't,
    // the query below will fetch them from the server. This is because the selected records MAY
    // not be included in the first 1000 results (SERVER_PAGE_SIZE) of the main query.
    // (Note: this only applies when backend filtering is enabled.
    //  Otherwise all records will be returned from the server in the main query.)
    const { data: { records: selectedRecordsFromQuery = [] } = {} } = useRecordsByIds({
        objectSid: objectId,
        recordIds: selectedValues,
        fetchOptions,
        options: {
            enabled: !useCache,
            refetchOnMount: 'always',
        },
    })

    const selectedRecords = useMemo(
        () =>
            useCache
                ? [...selectedRecordsFromCache, ...selectedRecordsFromAdditionalRecords]
                : selectedRecordsFromQuery,
        [
            useCache,
            selectedRecordsFromCache,
            selectedRecordsFromAdditionalRecords,
            selectedRecordsFromQuery,
        ]
    )
    const isSupportLoginPermitted = useIsSupportLoginPermitted()

    useEffect(() => {
        // update the filters when the search value changes
        const filters = [...(incomingFilters || [])]
        if (state.searchValue) {
            filters.push({
                field: { api_name: '_search' },
                options: { value: state.searchValue, operator: 'AND' },
            })
        }
        setState({ filters, searchValue: state.searchValue })
    }, [incomingFilters, state.searchValue])

    const changeInput = useCallback(
        (value) => {
            if (value !== state.searchValue) {
                setState((state) => ({ ...state, searchValue: value }))
            }
        },
        [state]
    )

    const onInputChange = useDebounce(changeInput, 300)

    const { data: { records = [] } = {}, isFetching: isServerLoading } = useRecords({
        objectSid: objectId,
        filters: state.filters,
        fetchOptions,
        // Always refetch on mount so we refresh any stale data in the cache.
        // When/if we have live update invalidating the cache automatically, we wouldn't
        // need to do this
        options: { refetchOnMount: 'always' },
    })

    const getFilteredRecords = useCallback(
        (records: RecordDto[]): RecordDto[] => {
            const filteredRecords = filter
                ? (records || []).filter((record) => filter(record))
                : records || []

            return [...filteredRecords]
        },
        [filter]
    )

    const filteredRecords = useMemo(() => {
        const combinedRecords = [...records]
        if (additionalRecords) {
            combinedRecords.unshift(...additionalRecords)
        }
        return getFilteredRecords(combinedRecords)
    }, [records, getFilteredRecords, additionalRecords])

    const availableRecords = useMemo(() => {
        const selectedSids = new Set(
            selectedRecords.map((selectedRecord: RecordDto) => selectedRecord._sid)
        )
        const filteredWithoutSelected = filteredRecords.filter(
            (filteredRecord) => !selectedSids.has(filteredRecord._sid)
        )
        return [...selectedRecords, ...filteredWithoutSelected]
    }, [selectedRecords, filteredRecords])

    const handleChange = useCallback(
        (value) => {
            onChange?.(value)

            // let the caller know when a record has been selected if desired
            if (onRecordsSelected) {
                const recordSids = ensureArray(value)
                const selectedRecords = recordSids
                    .map((sid) => availableRecords?.find((x) => x._sid === sid))
                    .filter((record): record is RecordDto => !!record)
                onRecordsSelected(selectedRecords)
            }
        },
        [onChange, onRecordsSelected, availableRecords]
    )

    useAutoSelectSingleRecord({
        records: availableRecords,
        value,
        onChange: handleChange,
        mode: props.mode,
        autoSelectEnabled: !!props.autoSelectEnabled,
        isMulti: props.isMulti,
    })

    return (
        <Dropdown
            value={value}
            ref={forwardedRef}
            {...props}
            className={classNames(
                `basic-multi-select ${isSupportLoginPermitted ? '' : STYLE_CLASSES.DATA_BLOCK}`,
                props.className
            )}
            classNamePrefix="select"
            onChange={handleChange}
            options={recordsToOptions(availableRecords, distinctBy, distinctOrderBy)}
            onInputChange={onInputChange}
            style={{
                flex: 1,
                margin: showCreateButton ? '0.25rem 0.25rem 0.25rem 0' : '0',
                ...style,
            }}
            isLoading={isServerLoading}
        />
    )
}
