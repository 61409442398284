import React from 'react'

import { CheckboxAttributeDisplay } from 'features/views/attributes/CheckboxAttributeDisplay'
import { useDetailViewAttributeValue } from 'features/views/DetailView/attributes/hooks/useDetailViewAttributeValue'

import { stopPropagation } from 'ui/helpers/utilities'

import { BaseAttribute } from './BaseAttribute'
import { PrimaryHighlightsAttributeComponent } from './types'

type CheckboxAttributeProps = {}

export const CheckboxAttribute: PrimaryHighlightsAttributeComponent<CheckboxAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const value = useDetailViewAttributeValue<boolean>(field.api_name)

    return (
        <BaseAttribute {...props} onClick={stopPropagation} field={field} isLoading={isLoading}>
            <CheckboxAttributeDisplay value={value} field={field} isLoading={isLoading} />
        </BaseAttribute>
    )
}
