import React from 'react'

import { TableViewPaginationStyle } from 'features/views/ListView/TableView/TableView.css'

import { Box } from 'ui/components/Box'
import { Pagination } from 'ui/components/Pagination'

import { useTableViewPaginationState } from './hooks/useTableViewPaginationState'

type TableViewPaginationProps = React.ComponentPropsWithoutRef<typeof Box> & {}

export const TableViewPagination: React.FC<TableViewPaginationProps> = (props) => {
    const { pageIndex, onChangePageIndex, pageSize, totalItemsCount, showPagination } =
        useTableViewPaginationState()

    if (!showPagination) return null

    return (
        <Box
            flex
            center
            alignSelf="stretch"
            px={{ mobile: 'xl', tablet: 0 }}
            className={TableViewPaginationStyle}
            {...props}
        >
            <Pagination
                grow
                pageSize={pageSize}
                totalItemsCount={totalItemsCount}
                pageIndex={pageIndex}
                onChangePageIndex={onChangePageIndex}
            />
        </Box>
    )
}
