import React, { useCallback, useEffect, useState } from 'react'

import { AgentTrigger, TriggerType } from 'data/hooks/agents/types'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Field } from 'ui/components/Field'
import { Input } from 'ui/components/Input'
import {
    Modal,
    ModalContent,
    ModalContentInner,
    ModalHeader,
    ModalPortal,
    ModalTrigger,
} from 'ui/components/Modal'

import { AppChatBotTriggerConfig } from './AppChatBotTriggerConfig'
import { TRIGGER_TYPES } from './constants'
import { CronJobTriggerConfig } from './CronJobTriggerConfig'
import { EmailReceivedTriggerConfig } from './EmailReceivedTriggerConfig'
import { ExternalChatbotTriggerConfig } from './ExternalChatbotTriggerConfig'
import { RecordCreatedTriggerConfig } from './RecordCreatedTriggerConfig'
import { TriggerListItem } from './TriggerListItem'
import { TriggerConfigProps } from './types'

type ConfigureTriggerModalProps = {
    children: React.ReactNode
    agentSid: string
    instructionsSid?: string
    trigger?: AgentTrigger
    onSubmit: (trigger: AgentTrigger) => void
}

const TRIGGER_CONFIG_COMPONENTS: Partial<
    Record<TriggerType['type'], React.FC<TriggerConfigProps<any>>>
> = {
    app_chatbot: AppChatBotTriggerConfig,
    external_chatbot: ExternalChatbotTriggerConfig,
    record_created: RecordCreatedTriggerConfig,
    email_received: EmailReceivedTriggerConfig,
    cron_job: CronJobTriggerConfig,
}

export function ConfigureTriggerModal({
    children,
    agentSid,
    instructionsSid,
    trigger,
    onSubmit,
}: ConfigureTriggerModalProps) {
    const [isOpen, setIsOpen] = useState(false)
    const [screen, setScreen] = useState<'select' | 'configure'>(trigger ? 'configure' : 'select')
    const [selectedType, setSelectedType] = useState<TriggerType['type'] | undefined>(
        trigger?.type as TriggerType['type'] | undefined
    )
    const [config, setConfig] = useState<Record<string, any>>(trigger?.config || {})
    const [name, setName] = useState(trigger?.name)

    const handleSubmit = () => {
        const newTrigger: AgentTrigger = {
            _sid: trigger?._sid || crypto.randomUUID(),
            name: name || '',
            type: selectedType!,
            config,
            is_enabled: true,
            created_date: new Date().toISOString(),
            modified_date: new Date().toISOString(),
            is_deleted: false,
        }
        onSubmit(newTrigger)
        setIsOpen(false)
        resetForm()
    }

    const resetValues = useCallback(() => {
        const triggerType = TRIGGER_TYPES[trigger?.type || '']
        setSelectedType(trigger?.type)
        setName(trigger?.name || triggerType?.name || '')
        setConfig(trigger?.config || {})
    }, [trigger?.config, trigger?.name, trigger?.type])

    const resetForm = useCallback(() => {
        if (trigger) {
            setScreen('configure')
        } else {
            setScreen('select')
        }

        resetValues()
    }, [trigger, resetValues])

    const handleTypeSelect = (type: TriggerType) => {
        setSelectedType(type.type)
        setName((v) => v || type.name)
        setScreen('configure')
    }

    useEffect(() => {
        if (!isOpen) {
            resetForm()
        }
    }, [isOpen, resetForm])

    const TriggerConfigComponent = TRIGGER_CONFIG_COMPONENTS[selectedType!]

    return (
        <Modal open={isOpen} onOpenChange={setIsOpen}>
            <ModalTrigger asChild>{children}</ModalTrigger>
            <ModalPortal>
                <ModalContent>
                    <ModalHeader
                        title={screen === 'select' ? 'Select Trigger Type' : 'Configure Trigger'}
                        showBackButton={screen === 'configure'}
                        onBackButtonClick={() => setScreen('select')}
                    />
                    <ModalContentInner>
                        {screen === 'select' && (
                            <Box flex column gap="m" p="l">
                                <Box display="flex" column gap="m">
                                    {Object.values(TRIGGER_TYPES).map((type) => (
                                        <TriggerListItem
                                            key={type.type}
                                            type={type.type}
                                            onClick={() => handleTypeSelect(type)}
                                        />
                                    ))}
                                </Box>
                            </Box>
                        )}
                        {screen === 'configure' && (
                            <Box flex column gap="xl" p="l">
                                <Field label="Trigger Type">
                                    <TriggerListItem
                                        type={selectedType!}
                                        onClick={() => setScreen('select')}
                                    />
                                </Field>
                                <Field label="Name">
                                    <Input
                                        value={name}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            setName(e.target.value)
                                        }
                                        placeholder="Enter trigger name"
                                    />
                                </Field>
                                {TriggerConfigComponent && (
                                    <TriggerConfigComponent
                                        agentSid={agentSid}
                                        triggerSid={trigger?._sid}
                                        instructionsSid={instructionsSid}
                                        config={config}
                                        onChange={(newConfig) => setConfig(newConfig)}
                                        name={name}
                                    />
                                )}

                                <Box flex justifyContent="flex-end" gap="m">
                                    <Button variant="secondary" onClick={() => setIsOpen(false)}>
                                        Cancel
                                    </Button>
                                    <Button onClick={handleSubmit} disabled={!name}>
                                        {trigger ? 'Update' : 'Create'} Trigger
                                    </Button>
                                </Box>
                            </Box>
                        )}
                    </ModalContentInner>
                </ModalContent>
            </ModalPortal>
        </Modal>
    )
}
