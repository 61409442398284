import { getDetailViewHeader } from 'features/views/DetailView/utils'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'

export function useDetailViewHeaderControlsState() {
    const { view, fields } = useLayoutEditorContext()

    const header = getDetailViewHeader(view?.layout, fields)

    return {
        header,
        fields,
    }
}
