import { useCallback, useEffect, useMemo, useRef } from 'react'

import { useControllableState } from '@radix-ui/react-use-controllable-state'

import { useProcessFilter } from 'features/records/components/RecordFilters'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'
import { SectionWidgetType } from 'features/views/LayoutEditor/widgets/sectionWidgetTypes'
import { useRecordManagerContext } from 'features/views/RecordManager/useRecordManagerContext'

export function useSectionWidgetState(widget: SectionWidgetType) {
    const {
        isCollapsible,
        startCollapsed,
        actionButtonsPlacement = 'hide',
        visibilityFilters = [],
    } = widget.attrs

    const { isEditing } = useLayoutEditorContext()

    const { record } = useRecordManagerContext()
    const recordRef = useRef(record)
    recordRef.current = record

    let defaultState = isCollapsible ? !startCollapsed : true
    if (isEditing) defaultState = true

    const [isOpen, setIsOpen] = useControllableState({
        defaultProp: defaultState,
    })

    useEffect(() => {
        if (isEditing) {
            setIsOpen(true)
        } else if (!!record?._sid) {
            const storedState = restoreFromLocalStorage(record._sid, widget.id)
            setIsOpen(storedState ?? defaultState)
        }
    }, [isEditing, setIsOpen, record?._sid, widget.id, defaultState])

    const toggleIsOpen = useCallback(
        (open: boolean) => {
            if (isEditing) return

            setIsOpen(open)

            if (!!recordRef.current) {
                persistToLocalStorage(recordRef.current._sid, widget.id, open)
            }
        },
        [isEditing, setIsOpen, widget]
    )

    const passesFilters = useProcessFilter()

    const matchesFilters = useMemo(() => {
        if (!record) return false

        return passesFilters([record], visibilityFilters).length > 0
    }, [passesFilters, visibilityFilters, record])

    const isVisible = isEditing || matchesFilters

    return useMemo(
        () => ({
            isOpen,
            setIsOpen: toggleIsOpen,
            isCollapsible,
            actionButtonsPlacement,
            isVisible,
        }),
        [isOpen, toggleIsOpen, isCollapsible, actionButtonsPlacement, isVisible]
    )
}

function getLocalStorageKey(recordSid: string, widgetId: string) {
    return `${recordSid}_${widgetId}_is_collapsed`
}

function persistToLocalStorage(recordSid: string, widgetId: string, state: boolean) {
    const key = getLocalStorageKey(recordSid, widgetId)
    localStorage.setItem(key, state.toString())
}

function restoreFromLocalStorage(recordSid: string, widgetId: string): boolean | undefined {
    const key = getLocalStorageKey(recordSid, widgetId)
    const value = localStorage.getItem(key)
    if (!value) return

    return value.toLowerCase() === 'true'
}
