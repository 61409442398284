import React from 'react'

import { TextAttributeDisplay } from 'features/views/attributes/TextAttributeDisplay'
import { useDetailViewAttributeValue } from 'features/views/DetailView/attributes/hooks/useDetailViewAttributeValue'

import { BaseAttribute } from './BaseAttribute'
import { PrimaryHighlightsAttributeComponent } from './types'

type TextAttributeProps = {}

export const TextAttribute: PrimaryHighlightsAttributeComponent<TextAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const value = useDetailViewAttributeValue<string>(field.api_name)
    const isEmpty = !isLoading && !value

    return (
        <BaseAttribute
            key="text-attribute"
            {...props}
            field={field}
            isEmpty={isEmpty}
            isLoading={isLoading}
        >
            <TextAttributeDisplay
                value={value}
                field={field}
                isLoading={isLoading}
                maxLines={1}
                style={{
                    fontSize: 'inherit',
                    fontWeight: 'inherit',
                    lineHeight: 'inherit',
                }}
                alwaysShowOverflowTooltip={{
                    mobile: false,
                    tablet: true,
                }}
                maxWidth="full"
                minWidth={0}
                trim
            />
        </BaseAttribute>
    )
}
