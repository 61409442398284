import React from 'react'

import { TextAttributeDisplay } from 'features/views/attributes/TextAttributeDisplay'

import { BaseAttribute } from './BaseAttribute'
import { MAX_LENGTH, MAX_LINES, MAX_OVERFLOW_LENGTH } from './constants'
import { ThreadViewAttributeComponent } from './types'

type TextAttributeProps = {}

export const TextAttribute: ThreadViewAttributeComponent<string, TextAttributeProps> = ({
    value,
    attribute,
    isLoading,
    ...props
}) => {
    const { field, type, rawOptions } = attribute

    const isEmpty = !isLoading && !value
    const isMessage = type === 'message'
    const isTitle = type === 'title'
    const isEyebrow = type === 'eyebrow'
    const isFooter = type === 'footer'

    let maxLength: number | undefined = MAX_LENGTH
    if (isMessage || isTitle) {
        maxLength = undefined
    }

    let maxLines: number | undefined = MAX_LINES
    if (isMessage) {
        maxLines = undefined
    }

    return (
        <BaseAttribute attribute={attribute} isEmpty={isEmpty} isLoading={isLoading} {...props}>
            <TextAttributeDisplay
                alwaysShowOverflowTooltip={!isMessage}
                style={{
                    fontSize: 'inherit',
                    lineHeight: 'inherit',
                    letterSpacing: 'inherit',
                    fontWeight: 'inherit',
                    display: isEyebrow || isFooter ? 'block' : undefined,
                }}
                showOverflowTooltip={{
                    mobile: false,
                    tablet: true,
                }}
                value={value}
                field={field}
                maxLines={maxLines}
                maxLength={maxLength}
                maxOverflowLength={MAX_OVERFLOW_LENGTH}
                isLoading={isLoading}
                trim={!isMessage}
                treatAsUrl={rawOptions.treatAsUrl as boolean}
                buttonTitle={rawOptions.buttonTitle as string}
                displayAsImage={rawOptions.displayAsImage as boolean}
                imageAltText={rawOptions.imageAltText as string}
                openInNewTab={rawOptions.openInNewTab as boolean}
                py="3xs"
            />
        </BaseAttribute>
    )
}
