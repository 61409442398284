import React from 'react'

import { PercentageAttributeDisplay } from 'features/views/attributes/PercentageAttributeDisplay'
import { PercentageAttributeTag } from 'features/views/attributes/PercentageAttributeTag'
import { PercentageDisplay } from 'features/views/attributes/types'

import { BaseAttribute } from './BaseAttribute'
import { BoardViewAttributeComponent } from './types'

const MAX_LENGTH_TAG = 20

type PercentageAttributeProps = {}

export const PercentageAttribute: BoardViewAttributeComponent<number, PercentageAttributeProps> = ({
    value,
    attribute,
    isLoading,
    displayStyle,
    showIcon,
    ...props
}) => {
    const {
        field,
        rawOptions: { percentageDisplay },
        type,
    } = attribute

    const isEmpty = !isLoading && typeof value === 'undefined'

    const isFooter = type === 'footer'

    const isDisplayedAsTag = displayStyle === 'tags'

    return (
        <BaseAttribute
            attribute={attribute}
            isEmpty={isEmpty}
            lineHeight={isDisplayedAsTag ? '1' : undefined}
            {...props}
        >
            {displayStyle === 'tags' ? (
                <PercentageAttributeTag
                    value={value}
                    field={field}
                    isLoading={isLoading}
                    maxLength={MAX_LENGTH_TAG}
                    showIcon={showIcon}
                />
            ) : (
                <PercentageAttributeDisplay
                    grow={percentageDisplay === 'percentBar'}
                    value={value}
                    valueDisplay={percentageDisplay as PercentageDisplay}
                    field={field}
                    whiteSpace="nowrap"
                    isLoading={isLoading}
                    trim={isFooter}
                    style={{
                        display: isFooter ? 'block' : undefined,
                        maxWidth: isFooter ? '100%' : undefined,
                    }}
                />
            )}
        </BaseAttribute>
    )
}
