import React, { useCallback } from 'react'

import classNames from 'classnames'

import { TipTapDocumentEditor } from 'features/tiptap/TipTapDocumentEditor'
import { LongTextValue } from 'features/views/attributes/LongTextValue'

import { Skeleton } from 'ui/components/Skeleton'
import { Textarea } from 'ui/components/Textarea'

import { useRichTextAttributeEditorState } from './hooks/useRichTextAttributeEditorState'
import { useRichTextAttributeState } from './hooks/useRichTextAttributeState'
import { BaseAttribute } from './BaseAttribute'
import { FIELD_VALIDATION_REQUIRED_ERROR_MESSAGE } from './constants'
import { InlineValueWrapper } from './InlineValueWrapper'
import { FieldsWidgetAttributeComponent } from './types'

import { RichTextAttributeEditorStyle, RichTextAttributeStyle } from './RichTextAttribute.css'

const MAX_LINES = 3

type RichTextAttributeProps = {}

export const RichTextAttribute: FieldsWidgetAttributeComponent<RichTextAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const { isEmpty, content, recordSid, stackSid, value, isEditingValue, isEditable } =
        useRichTextAttributeState({
            isLoading,
        })

    if (isEditingValue) {
        return (
            <RichTextAttributeEditor
                key="long-text-attribute"
                field={field}
                isLoading={isLoading}
                {...props}
            />
        )
    }

    const { disableTruncation } = field
    const children = (
        <TipTapDocumentEditor
            content={content}
            readOnly={true}
            stackId={stackSid}
            placeholder="Press '/' for commands or just start typing..."
            width="full"
            border={false}
            recordId={recordSid}
            fieldId={field?.sid}
            allowComments={false}
            className={RichTextAttributeStyle}
            disableToolbar
        />
    )

    return (
        <BaseAttribute
            {...props}
            field={field}
            isEmpty={isEmpty}
            isLoading={isLoading}
            center={false}
            cursor={isEditable ? 'text' : undefined}
        >
            <InlineValueWrapper>
                <Skeleton isLoading={isLoading}>
                    {disableTruncation ? (
                        children
                    ) : (
                        <LongTextValue textValue={value?.plainTextContent} maxLines={MAX_LINES}>
                            {children}
                        </LongTextValue>
                    )}
                </Skeleton>
            </InlineValueWrapper>
        </BaseAttribute>
    )
}

const RichTextAttributeEditor: FieldsWidgetAttributeComponent<RichTextAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const {
        value,
        onInputBlur,
        onInputChange,
        onInputKeyDown,
        onClearValue,
        editorRef,
        isEmpty,
        isRequired,
    } = useRichTextAttributeEditorState({ isLoading })

    const handleClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
    }, [])

    return (
        <BaseAttribute
            {...props}
            field={field}
            isEmpty={isEmpty}
            isLoading={isLoading}
            isClearValueButtonDisabled={isEmpty}
            onClearValue={onClearValue}
            validationError={
                isRequired && isEmpty ? FIELD_VALIDATION_REQUIRED_ERROR_MESSAGE : undefined
            }
        >
            <Textarea
                richText={true}
                ref={editorRef as any}
                size="m"
                autoSize
                onBlur={onInputBlur}
                onChange={onInputChange}
                onKeyDown={onInputKeyDown}
                onClick={handleClick}
                value={value}
                width="full"
                autoFocus
                className={classNames(RichTextAttributeEditorStyle, RichTextAttributeStyle)}
                fieldProps={{
                    style: {
                        minWidth: 0,
                    },
                }}
            />
        </BaseAttribute>
    )
}
