import React from 'react'

import { useComposedRefs } from '@radix-ui/react-compose-refs'
import classNames from 'classnames'

import { Box } from 'ui/components/Box'
import { Input } from 'ui/components/Input'
import { Body } from 'ui/components/Text'

import { useAttributeTextInputState } from './hooks/useAttributeTextInputState'

import { AttributeTextInputStyle, AttributeTextInputWrapperStyle } from './AttributeTextInput.css'

type AttributeTextInputRef = HTMLInputElement

type AttributeTextInputProps = React.ComponentPropsWithoutRef<typeof Box> & {
    value?: string
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
    onBlur?: () => void
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void
    maxLength?: number
    prefix?: React.ReactNode
    suffix?: React.ReactNode
    autoFocus?: boolean
}

export const AttributeTextInput = React.forwardRef<AttributeTextInputRef, AttributeTextInputProps>(
    (
        {
            className,
            maxLength,
            value,
            onChange,
            onBlur,
            onKeyDown,
            prefix,
            suffix,
            autoFocus,
            ...props
        },
        ref
    ) => {
        const { inputRef, textRef } = useAttributeTextInputState({
            value,
        })

        const composedRefs = useComposedRefs(ref, inputRef)

        return (
            <Box
                height="full"
                flex
                center
                width="full"
                minWidth={0}
                className={classNames(className, AttributeTextInputWrapperStyle)}
                {...props}
            >
                {prefix}
                {/* This element is used to calculate the width of the text,
                 *  so that the input can be sized accordingly.
                 */}
                <Body
                    size="m"
                    ref={textRef}
                    style={{
                        userSelect: 'none',
                        position: 'absolute',
                        visibility: 'hidden',
                        opacity: 0,
                        pointerEvents: 'none',
                        whiteSpace: 'pre',
                    }}
                >
                    {value}
                </Body>
                <Input
                    ref={composedRefs}
                    variant="borderless"
                    size="m"
                    className={AttributeTextInputStyle}
                    inputProps={{
                        className: 'ignore-ios-zoom',
                        style: {
                            maxWidth: '100%',
                            overflow: 'hidden',
                            minWidth: 0,
                        },
                    }}
                    maxLength={maxLength}
                    onInput={onChange}
                    style={{ minWidth: '2px' }}
                    onBlur={onBlur}
                    onKeyDown={onKeyDown}
                    value={value}
                    autoFocus={autoFocus}
                />
                {suffix}
            </Box>
        )
    }
)
