import { useMemo } from 'react'

import { getFieldIcon } from 'features/admin/fields/icons/utils'
import { ThreadViewAttribute } from 'features/views/ListView/ThreadView/types'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useThreadViewContext } from './useThreadViewContext'

type UseThreadViewMessageContentStateProps = {
    record?: RecordDto
}

export function useThreadViewMessageContentState({
    record,
}: UseThreadViewMessageContentStateProps) {
    const { contentField, messageSize } = useThreadViewContext()

    const attribute: ThreadViewAttribute | undefined = useMemo(() => {
        if (!contentField) return undefined

        const fieldIcon = getFieldIcon(contentField)

        return {
            id: contentField._sid,
            title: contentField.label,
            field: contentField,
            rawOptions: {},
            type: 'message',
            icon: fieldIcon,
        }
    }, [contentField])

    const value = useMemo(() => {
        if (!contentField || !record) return undefined

        return record[contentField.api_name] ?? undefined
    }, [contentField, record])

    return useDeepEqualsMemoValue({
        attribute,
        value,
        messageSize,
    })
}
