import React from 'react'

import { Box } from 'ui/components/Box'
import { Body } from 'ui/components/Text'

type EmbeddedNoRecordsFiltersEmptyStateProps = {}

export const EmbeddedNoRecordsFiltersEmptyState: React.FC<EmbeddedNoRecordsFiltersEmptyStateProps> =
    React.memo(function EmbeddedNoRecordsFiltersEmptyState() {
        return (
            <Box flex flexDirection="column" alignSelf="stretch" py={{ mobile: '2xl', tablet: 0 }}>
                <Body size="m" color="textWeaker">
                    No records found
                </Body>
            </Box>
        )
    })
