import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'

import { useUrlAttributeDisplayState } from 'features/views/attributes/hooks/useUrlAttributeDisplayState'
import { useAttributeContext } from 'features/views/LayoutEditor/widgets/FieldsWidget/attributes/hooks/useAttributeContext'
import { FieldsWidgetAttributeField } from 'features/views/LayoutEditor/widgets/FieldsWidget/attributes/types'

type UseUrlAttributeStateProps = {
    field: FieldsWidgetAttributeField
    isLoading?: boolean
}

export function useUrlAttributeState({ field, isLoading }: UseUrlAttributeStateProps) {
    const { value, isEditable } = useAttributeContext<string>()

    const isEmpty = !isLoading && !value

    const { target, to, href, label } = useUrlAttributeDisplayState({
        field: field.field,
        value,
        isLoading,
    })

    const handleClick = useCallback((e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
    }, [])

    const valueElementRef = useRef<HTMLDivElement>(null)
    const [attributeWidth, setAttributeWidth] = useState<number>(0)

    const handleResize = useCallback(() => {
        const valueElement = valueElementRef.current
        if (!valueElement) {
            return
        }

        setAttributeWidth(valueElement.clientWidth)
    }, [])

    useLayoutEffect(() => {
        const valueElement = valueElementRef.current
        if (!valueElement) {
            return
        }

        const resizeObserver = new ResizeObserver(handleResize)
        resizeObserver.observe(valueElement)

        handleResize()

        return () => resizeObserver.disconnect()
    }, [handleResize])

    return useMemo(
        () => ({
            isEmpty,
            isEditable,
            href,
            to,
            label,
            valueElementRef,
            attributeWidth,
            target,
            handleClick,
        }),
        [isEmpty, isEditable, href, to, label, attributeWidth, target, handleClick]
    )
}
