import { useEffect } from 'react'

import { useAppUserContext } from 'app/useAppUserContext'
import useLDFlags from 'data/hooks/useLDFlags'

import { websocketHandler } from './WebsocketHandler'

export const WebsocketListener = (): null => {
    const { user } = useAppUserContext()
    const { flags } = useLDFlags()

    useEffect(() => {
        if (flags.websockets) {
            websocketHandler.onUserChange(user)
        }
    }, [flags.websockets, user])

    return null
}
