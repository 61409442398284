import React, { useState } from 'react'

import { useAgents } from 'data/hooks/agents/agents'
import { useAgentInstructions } from 'data/hooks/agents/instructions'
import { AgentChatBox } from 'features/Agents/chat/AgentChatBox'
import {
    conditionalVisibility,
    configuratorWrapper,
} from 'features/pages/blocks/settings/attributes/items/primitives'

import { ConfigureWidgetPlaceholder } from 'v2/ui'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'
import { Body } from 'ui/components/Text'

import AIAgentBlockEditor from './AIAgentBlockEditor'

type AIAgentBlockProps = {
    attrs: {
        agent: {
            agentSid?: string
            selectedInstructionSet?: string
        }
    }
    context: {
        editor: {
            isEditing: boolean
        }
        record: {
            id: string
        }
    }
    showAttributePopover?: () => void
}

const AIAgentBlock = (props: AIAgentBlockProps) => {
    const { attrs, context } = props
    const { data: agents } = useAgents()
    const [conversationSid, setConversationSid] = useState<string>()
    const isEditing = context.editor.isEditing

    const { data: instructionsList = [] } = useAgentInstructions(attrs.agent?.agentSid || '')
    const instructions = instructionsList.find(
        (x) => x._sid === attrs.agent?.selectedInstructionSet
    )
    const trigger = instructions?.triggers.find((x) => x.type === 'app_chatbot')

    const conversationContext = `
        You are currently on the detail page for the following record:
        ${JSON.stringify(context.record)}
    `
    console.log(conversationContext)

    const agent = agents?.find((a) => a._sid === attrs.agent?.agentSid)

    if (isEditing && !agent) {
        return (
            <ConfigureWidgetPlaceholder
                message="AI Agent"
                subtitle={`Select an AI agent to chat with ${JSON.stringify(attrs)}`}
                Icon={() => <Icon name="Bot" />}
                onClick={props.showAttributePopover}
            />
        )
    }

    if (!agent || !instructions || !trigger) {
        return (
            <Box p="l">
                <Body>Please select an agent in the block settings.</Body>
            </Box>
        )
    }

    return (
        <Box
            flex
            column
            height="full"
            width="full"
            background="surface"
            borderRadius="m"
            borderColor="border"
            p="l"
            mb="xl"
            alignItems="center"
            justifyContent="center"
        >
            <Box maxWidth="800px" flex grow width="full" minHeight="600px" alignItems="flex-end">
                <AgentChatBox
                    agent={agent}
                    instructions={instructions}
                    trigger={trigger}
                    conversationSid={conversationSid}
                    setConversationSid={setConversationSid}
                    conversationContext={conversationContext}
                />
            </Box>
        </Box>
    )
}

AIAgentBlock.attributes = [
    conditionalVisibility({
        noLabel: true,
        field: 'filters',
        simple: true,
    }),
    configuratorWrapper({
        Element: AIAgentBlockEditor,
        field: 'agent',
        simple: true,
        fullWidth: true,
        hideLabel: true,
        requireObject: false,
    }),
]

export default AIAgentBlock
