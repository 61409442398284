import React from 'react'

import { SortableContext } from '@dnd-kit/sortable'

import { Box } from 'ui/components/Box'
import { Body } from 'ui/components/Text'
import { theme } from 'ui/styling/Theme.css'
import { ThemeProvider } from 'ui/styling/themes/ThemeProvider'

import { useLayoutEditorWidgetAreaState } from './hooks/useLayoutEditorWidgetAreaState'
import { LayoutEditorNewWidgetDropdown } from './LayoutEditorNewWidgetDropdown'
import { LayoutEditorDnDWidget } from './LayoutEditorWidget'
import { LayoutEditorWidgetAreaContextProvider } from './LayoutEditorWidgetAreaContext'

type LayoutEditorWidgetAreaProps = Omit<React.ComponentPropsWithoutRef<typeof Box>, 'id'> & {
    id: string
    isDragging?: boolean
}

export const LayoutEditorWidgetArea: React.FC<LayoutEditorWidgetAreaProps> = React.memo(
    function LayoutEditorWidgetArea({ id, ...props }) {
        return (
            <LayoutEditorWidgetAreaContextProvider id={id}>
                <LayoutEditorWidgetAreaInner id={id} {...props} />
            </LayoutEditorWidgetAreaContextProvider>
        )
    }
)

const LayoutEditorWidgetAreaInner: React.FC<LayoutEditorWidgetAreaProps> = ({
    id,
    children: providedChildren,
    ...props
}) => {
    const {
        children,
        ref,
        sortableContextId,
        isDragging,
        isEditing,
        onClickPlaceholderWidget,
        isMainWidgetArea,
        isRootWidgetArea,
        isDraggingOver,
        sortingStrategy,
    } = useLayoutEditorWidgetAreaState({
        id,
    })

    // If we are dragging, we don't want to render nested widget areas, to improve performance.
    // Also, without this check we could drag a widget into its own child nested widget area, which would result in a memory leak.
    if (isDragging) return null

    return (
        <Box ref={ref} {...props}>
            <SortableContext items={children} id={sortableContextId} strategy={sortingStrategy}>
                {!!children.length &&
                    children.map((widget) => (
                        <LayoutEditorDnDWidget key={widget.id} widget={widget} />
                    ))}
                {!children.length && isEditing && !isMainWidgetArea && !isDraggingOver && (
                    <Placeholder onClickWidget={onClickPlaceholderWidget} />
                )}
                {isEditing && isRootWidgetArea && isMainWidgetArea && (
                    <AddNewWidgetPlaceholder onClickWidget={onClickPlaceholderWidget} />
                )}
            </SortableContext>
            {providedChildren}
        </Box>
    )
}

type PlaceholderProps = {
    onClickWidget: (widgetType: string) => void
}

const Placeholder: React.FC<PlaceholderProps> = ({ onClickWidget }) => {
    return (
        <ThemeProvider theme="admin">
            <Box
                width="full"
                py="xl"
                flex
                center
                justifyContent="center"
                rounded="xl"
                background="theme50"
            >
                <LayoutEditorNewWidgetDropdown
                    variant="secondary"
                    startIcon={{ name: 'Plus' }}
                    size="s"
                    onClickWidget={onClickWidget}
                />
            </Box>
        </ThemeProvider>
    )
}

type AddNewWidgetPlaceholderProps = {
    onClickWidget: (widgetType: string) => void
}

const AddNewWidgetPlaceholder: React.FC<AddNewWidgetPlaceholderProps> = ({ onClickWidget }) => {
    return (
        <ThemeProvider theme="admin">
            <Box py="l" flex center justifyContent="center">
                <Box
                    width="400px"
                    maxWidth="full"
                    py="3xl"
                    flex
                    center
                    column
                    background="theme50"
                    textAlign="center"
                    gap="m"
                    style={{
                        outlineColor: theme.color.theme600,
                        outlineWidth: theme.borderWidths[1],
                        outlineStyle: theme.borderStyles.dashed,
                    }}
                >
                    <LayoutEditorNewWidgetDropdown
                        variant="secondary"
                        startIcon={{ name: 'Plus' }}
                        size="s"
                        onClickWidget={onClickWidget}
                    />
                    <Body size="s" weight="medium" color="theme700">
                        Add or drag widget here
                    </Body>
                </Box>
            </Box>
        </ThemeProvider>
    )
}
