import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'

import {
    useCreateAgentInstructions,
    useUpdateAgentInstructions,
} from 'data/hooks/agents/instructions'
import { AgentInstructions } from 'data/hooks/agents/types'

type EditAgentInstructionsFormWrapperProps = {
    instructions?: AgentInstructions // Make optional since we might be creating new instructions
    agentSid?: string // Add agentId as separate prop
    onSuccess?: (result: AgentInstructions) => void
    setError?: (error: string) => void
    children: React.ReactNode
}

export function EditAgentInstructionsFormWrapper({
    instructions,
    agentSid,
    onSuccess,
    setError,
    children,
}: EditAgentInstructionsFormWrapperProps) {
    const createInstructions = useCreateAgentInstructions(agentSid)
    const updateInstructions = useUpdateAgentInstructions(agentSid)

    const methods = useForm<Partial<AgentInstructions>>({
        defaultValues: instructions || {
            name: '',
            prompt: '',
            available_skills: [],
            configured_tools: [],
            configured_renderers: [],
            agent_id: agentSid,
            triggers: [],
            options: {},
        },
    })

    const handleSubmit = async (data: Partial<AgentInstructions>) => {
        try {
            setError?.('')

            let result: AgentInstructions
            if (instructions?._sid) {
                result = await updateInstructions.mutateAsync({
                    id: instructions._sid,
                    patch: { ...data },
                })
            } else {
                result = await createInstructions.mutateAsync({
                    ...data,
                    agent_id: agentSid, // Ensure agent_id is included in create
                })
            }
            methods.reset(data)
            onSuccess?.(result)
        } catch (error) {
            console.error('Failed to save agent instructions:', error)
            setError?.('Failed to save agent instructions')
        }
    }

    return (
        <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(handleSubmit)}>{children}</form>
        </FormProvider>
    )
}
