import { useMemo } from 'react'

import { getFieldIcon } from 'features/admin/fields/icons/utils'
import { ThreadViewAttribute } from 'features/views/ListView/ThreadView/types'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useThreadViewContext } from './useThreadViewContext'

type UseThreadViewMessageHeaderStateProps = {
    record?: RecordDto
}

export function useThreadViewMessageHeaderState({ record }: UseThreadViewMessageHeaderStateProps) {
    const { titleField, eyebrowField, view, titleSize } = useThreadViewContext()

    const titleAttribute: ThreadViewAttribute | undefined = useMemo(() => {
        if (!titleField) return undefined

        const fieldIcon = getFieldIcon(titleField)

        return {
            id: titleField._sid,
            title: titleField.label,
            field: titleField,
            rawOptions: {},
            type: 'title',
            icon: fieldIcon,
        }
    }, [titleField])

    const titleValue = useMemo(() => {
        if (!titleField || !record) return undefined

        return record[titleField.api_name] ?? undefined
    }, [titleField, record])

    const eyebrowAttribute: ThreadViewAttribute | undefined = useMemo(() => {
        if (!eyebrowField) return undefined

        const eyebrowFieldDisplayOptions =
            view.options.threadMessageEyebrow?.fieldDisplayOptions ?? {}
        const fieldIcon = getFieldIcon(eyebrowField)

        return {
            id: eyebrowField._sid,
            title: eyebrowField.label,
            field: eyebrowField,
            rawOptions: eyebrowFieldDisplayOptions as ThreadViewAttribute['rawOptions'],
            type: 'eyebrow',
            icon: fieldIcon,
        }
    }, [eyebrowField, view.options.threadMessageEyebrow?.fieldDisplayOptions])

    const eyebrowValue = useMemo(() => {
        if (!eyebrowField || !record) return undefined

        return record[eyebrowField.api_name] ?? undefined
    }, [eyebrowField, record])

    return useDeepEqualsMemoValue({
        titleAttribute,
        titleValue,
        eyebrowAttribute,
        eyebrowValue,
        titleSize,
    })
}
