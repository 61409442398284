import { useEffect, useMemo, useState } from 'react'

import { ActivityType, RelatedToType } from 'data/hooks/activityTypes'
import { useLayoutEditorContext } from 'features/views/LayoutEditor/useLayoutEditorContext'
import { useRecordManagerContext } from 'features/views/RecordManager/useRecordManagerContext'

export function useFeedWidgetState() {
    const { record } = useRecordManagerContext()
    const { stack } = useLayoutEditorContext()

    const hasRecord = !!record

    const [showFeed, setShowFeed] = useState(false)
    useEffect(() => {
        if (hasRecord) {
            // Delay mount to prevent lag on tab switch.
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    setShowFeed(true)
                })
            })
        } else {
            setShowFeed(false)
        }
    }, [hasRecord])

    const recordSid = record?._sid
    const objectSid = record?._object_id

    const feedParams = useMemo(
        () => ({
            types: [ActivityType.Comment],
        }),
        []
    )

    const feedTarget = useMemo(
        () => ({
            relatedTo: recordSid,
            relatedToType: RelatedToType.Record,
            objectId: objectSid,
            stackId: stack?._sid,
        }),
        [recordSid, objectSid, stack?._sid]
    )

    return useMemo(
        () => ({
            showFeed,
            objectSid,
            recordSid,
            feedParams,
            feedTarget,
        }),
        [recordSid, objectSid, showFeed, feedParams, feedTarget]
    )
}
