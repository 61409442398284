import { useAppUserContext } from 'app/useAppUserContext'
import { useAgentInstructions } from 'data/hooks/agents/instructions'
import { AgentTrigger } from 'data/hooks/agents/types'

export function visibleAgentChatbotTriggerFilter(trigger: AgentTrigger, role?: RoleDto | null) {
    const roleMatches =
        role?.api_name === 'internal_admin' ||
        trigger.config?.show_in_app_bar_for_roles?.includes(role?.api_name)
    return trigger.type === 'app_chatbot' && roleMatches
}

export function useAgentAppChatbotTrigger(agentId: string) {
    // Returns the first instructions and trigger with type app_chatbot
    const { role } = useAppUserContext()

    const { data: instructionsList } = useAgentInstructions(agentId)

    function triggerFilter(trigger: AgentTrigger) {
        return visibleAgentChatbotTriggerFilter(trigger, role)
    }

    const instructions = instructionsList?.find((instruction) =>
        instruction.triggers.find(triggerFilter)
    )

    const trigger = instructions?.triggers.find(triggerFilter)

    return { instructions, trigger }
}
