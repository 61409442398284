import React, { useMemo } from 'react'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'
import { Skeleton } from 'ui/components/Skeleton'
import { ResponsiveValue, useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { AttributeDisplayComponent, CheckboxDisplay } from './types'

type CheckboxAttributeDisplayProps = React.ComponentPropsWithoutRef<typeof Box> & {
    valueDisplay?: CheckboxDisplay
    isLoading?: boolean
    size?: ResponsiveValue<'s' | 'm' | 'l'>
}

export const CheckboxAttributeDisplay: AttributeDisplayComponent<
    boolean,
    CheckboxAttributeDisplayProps
> = React.memo(function CheckboxAttributeDisplay({
    size,
    value,
    valueDisplay = 'default',
    isLoading,
    ...props
}) {
    const effectiveSize = useResponsiveValue(size)

    const isFilled = valueDisplay === 'bigFilled' || valueDisplay === 'filled'

    const iconSize: React.ComponentPropsWithoutRef<typeof Icon>['size'] = useMemo(() => {
        // Be able to override the size if provided.
        if (!!effectiveSize) {
            return effectiveSize
        }

        switch (valueDisplay) {
            case 'big':
                return '2xl'
            case 'bigFilled':
                return 'xl'
            case 'filled':
                return 'm'
            default:
                return 'l'
        }
    }, [valueDisplay, effectiveSize])

    const iconColor: React.ComponentPropsWithoutRef<typeof Icon>['color'] = useMemo(() => {
        if (isFilled) {
            return 'iconInverse'
        } else if (value) {
            return 'iconSuccess'
        }

        return 'iconDisabled'
    }, [isFilled, value])

    const backgroundColor: React.ComponentPropsWithoutRef<typeof Box>['background'] =
        useMemo(() => {
            if (isFilled) {
                return value ? 'surfaceSuccess' : 'surfaceDisabledStrong'
            }

            return 'transparent'
        }, [isFilled, value])

    return (
        <Skeleton isLoading={isLoading}>
            <Box
                p={isFilled ? '2xs' : undefined}
                borderRadius={isFilled ? 'pill' : undefined}
                background={backgroundColor}
                {...props}
            >
                <Icon name={value ? 'Check' : 'X'} color={iconColor} size={iconSize} />
            </Box>
        </Skeleton>
    )
})
