import React from 'react'

import { PercentageAttributeDisplay } from 'features/views/attributes/PercentageAttributeDisplay'

import { Body } from 'ui/components/Text'

import { useAttributeContext } from './hooks/useAttributeContext'
import { usePercentageAttributeEditorState } from './hooks/usePercentageAttributeEditorState'
import { AttributeTextInput } from './AttributeTextInput'
import { BaseAttribute } from './BaseAttribute'
import { FIELD_VALIDATION_REQUIRED_ERROR_MESSAGE } from './constants'
import { InlineValueWrapper } from './InlineValueWrapper'
import { FieldsWidgetAttributeComponent } from './types'

type PercentageAttributeProps = {}

export const PercentageAttribute: FieldsWidgetAttributeComponent<PercentageAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const { value, isEditingValue, isEditable } = useAttributeContext<number>()
    const isEmpty = !isLoading && typeof value === 'undefined'

    if (isEditingValue) {
        return (
            <PercentageAttributeEditor
                key="percentage-attribute"
                field={field}
                isLoading={isLoading}
                {...props}
            />
        )
    }

    return (
        <BaseAttribute
            key="percentage-attribute"
            {...props}
            field={field}
            isEmpty={isEmpty}
            isLoading={isLoading}
            cursor={isEditable ? 'text' : undefined}
        >
            <InlineValueWrapper>
                <PercentageAttributeDisplay
                    value={value}
                    field={field.field}
                    isLoading={isLoading}
                    maxLines={1}
                    whiteSpace="nowrap"
                    size="m"
                    trim
                    maxWidth="full"
                    minWidth={0}
                />
            </InlineValueWrapper>
        </BaseAttribute>
    )
}

const PercentageAttributeEditor: FieldsWidgetAttributeComponent<PercentageAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const { value, onInputBlur, onInputChange, onInputKeyDown, onClearValue, isRequired } =
        usePercentageAttributeEditorState()

    const isEmpty = !isLoading && !value

    return (
        <BaseAttribute
            {...props}
            field={field}
            isEmpty={isEmpty}
            isLoading={isLoading}
            isClearValueButtonDisabled={isEmpty}
            onClearValue={onClearValue}
            validationError={
                isRequired && isEmpty ? FIELD_VALIDATION_REQUIRED_ERROR_MESSAGE : undefined
            }
        >
            <AttributeTextInput
                autoFocus={true}
                onBlur={onInputBlur}
                onChange={onInputChange}
                onKeyDown={onInputKeyDown}
                value={value}
                suffix={
                    <Body size="m" color="textWeaker" style={{ marginLeft: '-2px' }} noShrink>
                        %
                    </Body>
                }
            />
        </BaseAttribute>
    )
}
