import React from 'react'

import { DropdownAttributeDisplay } from 'features/views/attributes/DropdownAttributeDisplay'

import { Box } from 'ui/components/Box'
import {
    Dropdown,
    DropdownContent,
    DropdownHeadSearch,
    DropdownItem,
    DropdownItemTag,
    DropdownTrigger,
} from 'ui/components/Dropdown'
import { ScrollArea } from 'ui/components/ScrollArea'
import { Skeleton } from 'ui/components/Skeleton'
import { Tag } from 'ui/components/Tag'
import { Body } from 'ui/components/Text'
import { stopPropagation } from 'ui/helpers/utilities'

import { useAttributeContext } from './hooks/useAttributeContext'
import { useDropdownAttributeEditorState } from './hooks/useDropdownAttributeEditorState'
import { BaseAttribute } from './BaseAttribute'
import { FIELD_VALIDATION_REQUIRED_ERROR_MESSAGE } from './constants'
import { FieldsWidgetAttributeComponent } from './types'

import { DropdownAttributeEditorScrollAreaStyle } from './DropdownAttribute.css'

type DropdownAttributeProps = {}

export const DropdownAttribute: FieldsWidgetAttributeComponent<DropdownAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const { value, labelPlacement, isEditingValue, isEditable } = useAttributeContext<
        string | string[]
    >()
    const isEmpty = !isLoading && (!value || (Array.isArray(value) && value.length < 1))

    if (isEditingValue) {
        return (
            <DropdownAttributeEditor
                key="dropdown-attribute"
                field={field}
                isLoading={isLoading}
                {...props}
            />
        )
    }

    return (
        <BaseAttribute
            key="dropdown-attribute"
            {...props}
            field={field}
            isEmpty={isEmpty}
            labelPlacement={labelPlacement}
            isLoading={isLoading}
            alignItems={labelPlacement === 'left' ? 'center' : 'flex-start'}
            cursor={isEditable ? 'pointer' : undefined}
        >
            <DropdownAttributeDisplay
                value={value}
                field={field.field}
                isLoading={isLoading}
                maxLines={1}
                maxOptions={3}
                size="m"
            />
        </BaseAttribute>
    )
}

const DropdownAttributeEditor: FieldsWidgetAttributeComponent<DropdownAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const {
        valueOptions,
        selectableOptions,
        onClearValue,
        isSingle,
        value,
        onValueChange,
        isOpen,
        onOpenChange,
        onEscapeKeyDown,
        onInteractOutside,
        isRequired,
    } = useDropdownAttributeEditorState({
        isLoading,
    })
    const isEmpty = !isLoading && Array.isArray(valueOptions) && valueOptions.length < 1

    return (
        <Dropdown open={isOpen} onOpenChange={onOpenChange} modal={false}>
            <DropdownTrigger asChild>
                <BaseAttribute
                    {...props}
                    field={field}
                    isEmpty={isEmpty}
                    isLoading={isLoading}
                    isClearValueButtonDisabled={isEmpty}
                    onClearValue={onClearValue}
                    validationError={
                        isRequired && isEmpty ? FIELD_VALIDATION_REQUIRED_ERROR_MESSAGE : undefined
                    }
                >
                    <ScrollArea
                        direction="vertical"
                        type="auto"
                        minWidth={0}
                        maxWidth="full"
                        className={DropdownAttributeEditorScrollAreaStyle}
                    >
                        <Box
                            flex
                            wrap
                            gap="xs"
                            minWidth={0}
                            maxWidth="full"
                            onPointerDown={stopPropagation}
                        >
                            {valueOptions.map((option) => (
                                <Skeleton
                                    isLoading={isLoading}
                                    key={option.value}
                                    style={{
                                        maxWidth: '100%',
                                        minWidth: 0,
                                    }}
                                >
                                    <Tag
                                        color={option.color}
                                        size="xs"
                                        tabIndex={-1}
                                        style={{
                                            wordBreak: 'break-word',
                                            maxWidth: '100%',
                                            minWidth: 0,
                                        }}
                                        pointerEvents={isSingle ? 'none' : undefined}
                                        showRemoveButton={!isSingle}
                                        onRemove={() => onValueChange(option.value, false)}
                                    >
                                        {option.label}
                                    </Tag>
                                </Skeleton>
                            ))}
                        </Box>
                    </ScrollArea>
                </BaseAttribute>
            </DropdownTrigger>
            <DropdownContent
                onClick={stopPropagation}
                head={<DropdownHeadSearch />}
                onEscapeKeyDown={onEscapeKeyDown}
                onInteractOutside={onInteractOutside}
                onCloseAutoFocus={stopPropagation}
            >
                {selectableOptions.map((item) => (
                    <DropdownItemTag
                        key={item.value}
                        size="2xs"
                        multiSelect
                        color={item.color}
                        label={item.label}
                        checked={value.has(item.value)}
                        closeOnSelect={isSingle}
                        onCheckedChange={(checked) => {
                            onValueChange(item.value, checked)
                        }}
                    />
                ))}
                {selectableOptions.length < 1 && (
                    <DropdownItem
                        label={
                            <Body size="m" color="textDisabled">
                                No options available
                            </Body>
                        }
                        disabled
                    />
                )}
            </DropdownContent>
        </Dropdown>
    )
}
