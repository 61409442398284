import { useActionButtons } from 'features/actions/actionHooks'
import { useDetailViewContext } from 'features/views/DetailView/hooks/useDetailViewContext'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

export function useDetailViewHeaderActions() {
    const { object, recordManager, view } = useDetailViewContext()!

    const activeButtons = view?.layout?.actions?.pageButtons ?? []
    const actions = useActionButtons(activeButtons, recordManager.record!, object!)

    return useDeepEqualsMemoValue(actions.map((a) => a.action))
}
