// @ts-strict-ignore
import React, { useMemo } from 'react'

import { useExtDataIntegrations } from 'data/hooks/dataConnections/useExtDataIntegrations'
import { useObjects, useSharedWorkspaceObjects } from 'data/hooks/objects'
import { importSharedObject } from 'data/hooks/objects/objectOperations'
import { refetchObjects } from 'data/hooks/objects/refetchObjects'
import useLDFlags from 'data/hooks/useLDFlags'
import { useNewNativeTableModal } from 'features/admin/data-connector/editors/native-tables/useNewNativeTableModal'
import { useNewDataConnectionModal } from 'features/DataConnections/useNewDataConnectionModal'

import { Box, Button, Icon } from 'v2/ui'
import { colors } from 'v2/ui/theme/styles/default'

import {
    Dropdown,
    DropdownContent,
    DropdownHeadSearch,
    DropdownItem,
    DropdownSeparator,
    DropdownSub,
    DropdownSubContent,
    DropdownSubTrigger,
    DropdownTrigger,
} from 'ui/components/Dropdown'

type ManageDataActionsDropdownProps = {
    onSelectObject: (objectId: string) => void
    onOpenCsvModal: () => void
    onOpenTableDataModal: () => void
}

export const ManageDataActionsDropdown: React.VFC<ManageDataActionsDropdownProps> = ({
    onSelectObject,
    onOpenCsvModal,
    onOpenTableDataModal,
}) => {
    const { show: showNewTableModal } = useNewNativeTableModal()
    const { data: objects = [] } = useObjects()
    const { show: showNewDataConnectionModal } = useNewDataConnectionModal()
    const { data: integrationsData } = useExtDataIntegrations()
    const { data: workspaceObjects } = useSharedWorkspaceObjects()
    const { flags } = useLDFlags()

    const dataSyncIntegrations = integrationsData?.integrations.filter(
        (integration) => integration.supports_data_sync
    )

    const onAddTable = () => {
        showNewTableModal({
            onObjectCreated: (_tableName: string, objectId: string) => {
                if (objectId) {
                    onSelectObject(objectId)
                }
            },
        })
    }

    const sharedObjects = useMemo(() => {
        if (!workspaceObjects || !objects || !flags.externalData) return []

        return workspaceObjects.filter(
            (obj) => !objects.find((existing) => existing._sid === obj._sid)
        )
    }, [workspaceObjects, objects, flags.externalData])

    return (
        <Dropdown>
            <DropdownTrigger>
                <Button
                    variant="iconButton"
                    buttonSize="smallSquare"
                    icon="plus"
                    iconProps={{ pr: 0 }}
                    size="sm"
                >
                    <Icon icon="add" size="sm" color={colors.userInterface.neutral[0]} />
                </Button>
            </DropdownTrigger>
            <DropdownContent>
                <DropdownItem
                    label="Add new table"
                    onClick={onAddTable}
                    startIcon={{ name: 'Plus' }}
                />
                <DropdownSeparator />
                <DropdownItem
                    label="Import from CSV"
                    onClick={onOpenCsvModal}
                    startIcon={{ name: 'Import' }}
                />
                <DropdownItem
                    label="Paste data from spreadsheet"
                    onClick={onOpenTableDataModal}
                    startIcon={{ name: 'Table' }}
                />
                {flags.externalData &&
                    dataSyncIntegrations?.map((integration) => (
                        <DropdownItem
                            key={integration.id}
                            onClick={() => {
                                showNewDataConnectionModal({
                                    initialIntegrationId: integration.id,
                                    initialSelectedExternalAccountId: '',
                                })
                            }}
                            label={
                                <Box display="flex" alignItems="center" gap={2}>
                                    <img
                                        width="16px"
                                        height="16px"
                                        src={integration.image_url}
                                        alt={`${integration.name} logo`}
                                    />
                                    Import from {integration.name}
                                </Box>
                            }
                        />
                    ))}
                {sharedObjects?.length > 0 && (
                    <DropdownSub>
                        <DropdownSeparator />
                        <DropdownSubTrigger
                            label="Add shared table"
                            startIcon={{ name: 'Share2' }}
                        />
                        <DropdownSubContent head={<DropdownHeadSearch />}>
                            {sharedObjects
                                .filter(
                                    (obj) => !objects.find((existing) => existing._sid === obj._sid)
                                )
                                .map((workspaceObject) => (
                                    <DropdownItem
                                        key={workspaceObject._sid}
                                        label={workspaceObject.name}
                                        onClick={async () => {
                                            try {
                                                const importedObject: ObjectDto | undefined =
                                                    await importSharedObject(workspaceObject._sid)
                                                if (importedObject?._sid) {
                                                    onSelectObject(importedObject?._sid)
                                                    await refetchObjects()
                                                }
                                            } catch (resp) {
                                                if (resp.status === 400) {
                                                    alert(
                                                        'A table with this name already exists in this app.'
                                                    )
                                                } else {
                                                    throw resp
                                                }
                                            }
                                        }}
                                    />
                                ))}
                        </DropdownSubContent>
                    </DropdownSub>
                )}
            </DropdownContent>
        </Dropdown>
    )
}
