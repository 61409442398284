import React, { useCallback, useMemo, useRef } from 'react'

import ActionButtonsSelector from 'v2/views/ActionButtonsSelector'

import { ActionsContextProvider } from 'features/actions/ActionsContextProvider'
import { useActionsFromObjectId } from 'features/actions/helpers'
import { useListViewContext } from 'features/views/ListView/useListViewContext'
import { isTurboView } from 'features/views/ListView/utils'

import { Heading } from 'v2/ui'
import { Item } from 'v2/ui/components/OrderableListSelector/types'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Icon } from 'ui/components/Icon'
import { RadioCard, RadioCardGroup } from 'ui/components/Radio'
import { Select, SelectOption } from 'ui/components/Select'
import { Body } from 'ui/components/Text'
import { Tooltip } from 'ui/components/Tooltip'
import { theme } from 'ui/styling/Theme.css'

import { isSystemAction, useSystemActions } from './hooks/useSystemActions'
import { getRecordActionDisplay } from './utils'

type ActionControlsProps = {}

export const ActionControls: React.FC<ActionControlsProps> = () => {
    const { object, view, updateViewOptions } = useListViewContext()

    const systemActions = useSystemActions()
    const showSystemActions = !!systemActions.length

    const selectedButtons = useMemo(() => {
        const activeButtons = view.options.actionButtons?.slice() ?? []

        // If the view still has the default system actions, let's show all of them.
        if (!view.options.hasCustomSystemActions) {
            const existingActionButtonIds = new Set(activeButtons.map(({ id }) => id))

            for (const action of systemActions) {
                if (!existingActionButtonIds.has(action._sid)) {
                    activeButtons.push({ id: action._sid, conditions: [] })
                }
            }
        }

        return activeButtons
    }, [systemActions, view.options.actionButtons, view.options.hasCustomSystemActions])
    const selectedButtonsRef = useRef(selectedButtons)

    const updateConfig = useCallback(
        ({ pageButtons }: { pageButtons: ActionButton[] }) => {
            const existingButtons = selectedButtonsRef.current
            const existingSystemButtons = existingButtons.filter((action) =>
                isSystemAction(action.id)
            )

            const newButtons = [...existingSystemButtons, ...pageButtons]
            updateViewOptions({ actionButtons: newButtons })
            selectedButtonsRef.current = newButtons
        },
        [updateViewOptions]
    )

    const updateSystemActionsConfig = useCallback(
        ({ pageButtons }: { pageButtons: ActionButton[] }) => {
            const existingButtons = selectedButtonsRef.current
            const existingUserButtons = existingButtons.filter(
                (action) => !isSystemAction(action.id)
            )

            const newButtons = [...existingUserButtons, ...pageButtons]
            updateViewOptions({
                actionButtons: newButtons,
                hasCustomSystemActions: true,
            })
            selectedButtonsRef.current = newButtons
        },
        [updateViewOptions]
    )

    const actionDisplay = getRecordActionDisplay(view)

    const display = view.options.display ?? 'tableV2'

    const { data: userActions } = useActionsFromObjectId(object._sid)
    const showCustomActions = !!actionDisplay || display === 'cardV2'
    const hasUserActions = showCustomActions && !!userActions?.length

    const provideIcon = useCallback(
        (item: Item) => {
            const systemAction = systemActions.find((action) => action._sid === item.id)
            if (!systemAction?.icon) {
                return null
            }

            return <Icon size="m" name={systemAction.icon} mr="s" />
        },
        [systemActions]
    )

    return (
        <Box height="full" pt="l">
            <Heading variant="sideMenuSubHeading" value="Appearance" />
            <Box role="group" mt="l">
                <RadioCardGroup
                    value={actionDisplay ?? ''}
                    onValueChange={(value: string) => {
                        const newValue = value as ListViewOptions['actionDisplay']
                        updateViewOptions({
                            actionDisplay: newValue,
                        })
                    }}
                >
                    <RadioCard value="" icon={{ name: 'EyeOff' }}>
                        None
                    </RadioCard>
                    {display !== 'boardV2' && display !== 'cardV2' && display !== 'thread' && (
                        <RadioCard value="buttons" icon={{ name: 'Zap' }}>
                            Buttons
                        </RadioCard>
                    )}
                    <RadioCard value="dropdown" icon={{ name: 'Blinds' }}>
                        Dropdown
                    </RadioCard>
                </RadioCardGroup>
            </Box>
            {(showCustomActions || showSystemActions) && (
                <Box role="group" mt="3xl">
                    <Heading variant="sideMenuSubHeading" value="Visibility" />
                    {showCustomActions && (
                        <Box mt="l">
                            <ActionsContextProvider object={object}>
                                <ActionButtonsSelector
                                    object={object}
                                    selectedButtons={selectedButtons}
                                    setConfig={updateConfig}
                                    canDeleteActions={false}
                                    canEditActions={false}
                                    canSearchActions={false}
                                    animate={false}
                                    height="auto"
                                    customHeader={({ onCreateActionClick }) => (
                                        <Box
                                            flex
                                            center
                                            justifyContent="space-between"
                                            style={{
                                                textTransform: 'uppercase',
                                            }}
                                        >
                                            <Body size="xs" weight="bold" color="textWeaker">
                                                Custom actions
                                            </Body>
                                            <Tooltip
                                                asChild
                                                zIndex={1500}
                                                side="bottom"
                                                align="end"
                                                content="Create new action"
                                            >
                                                <Button
                                                    variant="secondary"
                                                    size="xs"
                                                    onClick={onCreateActionClick}
                                                    startIcon={{ name: 'Plus' }}
                                                    aria-label="Create new action"
                                                />
                                            </Tooltip>
                                        </Box>
                                    )}
                                />
                            </ActionsContextProvider>
                            {!hasUserActions && (
                                <Box
                                    style={{
                                        marginTop: '-13px',
                                    }}
                                >
                                    <Body size="s" color="textWeakest">
                                        No custom actions configured
                                    </Body>
                                </Box>
                            )}
                        </Box>
                    )}
                    {showSystemActions && (
                        <Box mt="l">
                            <Box
                                flex
                                center
                                style={{ textTransform: 'uppercase', height: theme.space['3xl'] }}
                            >
                                <Body size="xs" weight="bold" color="textWeaker">
                                    System buttons
                                </Body>
                            </Box>
                            <ActionButtonsSelector
                                object={object}
                                additionalActions={systemActions}
                                selectedButtons={selectedButtons}
                                setConfig={updateSystemActionsConfig}
                                showHeader={false}
                                canDeleteActions={false}
                                canEditActions={false}
                                canSearchActions={false}
                                canReorderActions={false}
                                animate={false}
                                height="auto"
                                maxHeight="none"
                                showObjectActions={false}
                                provideIcon={provideIcon}
                            />
                        </Box>
                    )}
                </Box>
            )}
            {isTurboView(display) && (
                <Box role="group" mt="l" pb="l">
                    <Heading variant="sideMenuSubHeading" value="Click record action" />
                    <Box mt="l">
                        <Select
                            defaultValue="preview"
                            value={view.options.onRecordClick}
                            onChange={(value: string) => {
                                const newValue = value as ListViewOptions['onRecordClick']
                                updateViewOptions({
                                    onRecordClick: newValue,
                                })
                            }}
                        >
                            <SelectOption key="preview" value="preview" label="Open as preview" />
                            <SelectOption key="detail" value="detail" label="Open as full page" />
                            <SelectOption key="new_tab" value="new_tab" label="Open in new tab" />
                            <SelectOption key="none" value="none" label="None" />
                        </Select>
                    </Box>
                </Box>
            )}
        </Box>
    )
}
