import { IconName } from 'ui/components/Icon/Icon'

export const RENDERER_TYPES = [
    {
        type: 'queryResult',
        label: 'Query Result',
        description: 'Render query results in a formatted way',
        icon: 'Database' as IconName<'lucide'>,
    },
    {
        type: 'email',
        label: 'Email',
        description: 'Render email content with proper formatting',
        icon: 'Mail' as IconName<'lucide'>,
    },
] as const
