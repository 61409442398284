import { useEffect } from 'react'

import { fetchWithAuth } from 'data/utils/fetchWithAuth'
import { isPortalUser } from 'features/auth/utils/roleUtils'

import { Rights } from './accountUserContextConstants'
import { useAccountUserContext } from './useAccountUserContext'

const APP_ID = 'ff138c3e-9043-400a-8972-1ae09ad2a5ef'
const PYLON_TOKEN_URL = 'pylon-token/'

type ChatSettings = {
    app_id: string
    email: string
    name: string
    avatar_url?: string
    user_hash?: string
    // 👇 Add any additional custom fields here
}

const _bootPylon = (chatSettings: ChatSettings) => {
    try {
        //@ts-expect-error
        if (window.Pylon) {
            //@ts-expect-error
            window.pylon = { chat_settings: chatSettings }

            const customFields = {
                workspace_type_2: 'astra',
                // see https://docs.usepylon.com/pylon-docs/in-app-chat/javascript-api#window-pylon-setnewissuecustomfields
                // 👇 Add any additional custom fields here
            }

            //@ts-ignore
            window.Pylon('setNewIssueCustomFields', customFields)
        } else {
            setTimeout(() => {
                _bootPylon(chatSettings)
            }, 3000)
        }
    } catch (err) {
        console.error('Unable to boot Pylon', err)
    }
}

export const bootPylon = async ({
    user,
    extraSettings = {},
}: {
    user: any
    extraSettings?: Record<string, any>
}) => {
    let userHash: string | undefined

    try {
        const response = await fetchWithAuth(PYLON_TOKEN_URL)
        const data = await response.json()
        userHash = data.user_hash
    } catch (err) {
        console.error('Failed to fetch Pylon user hash:', err)
    }

    const chatSettings = {
        app_id: APP_ID,
        email: user.email,
        name: user.name,
        avatar_url: user.avatar,
        ...extraSettings,
        // https://docs.usepylon.com/pylon-docs/in-app-chat/identity-verification
        ...(userHash ? { email_hash: userHash } : {}),
    }

    _bootPylon(chatSettings)
}

export const PylonChatBot: React.FC = () => {
    const { user, hasRight } = useAccountUserContext()

    useEffect(() => {
        if (user && !isPortalUser(user) && hasRight(Rights.ContactSupport)) {
            bootPylon({ user })
        }
    }, [user, hasRight])

    return null
}
