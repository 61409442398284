import React, { useCallback, useState } from 'react'

import classNames from 'classnames'

import { Dropdown, DropdownButton, DropdownContent } from 'ui/components/Dropdown'

import { ActionMenuContent } from './ActionMenuContent'

import { ActionMenuButtonStyle } from './ActionMenu.css'

type ActionMenuProps = React.ComponentPropsWithoutRef<typeof DropdownButton> & {
    record: RecordDto
    onOpenChange?: React.ComponentProps<typeof Dropdown>['onOpenChange']
    onCloseAutoFocus?: React.ComponentProps<typeof DropdownContent>['onCloseAutoFocus']
    actionButtons?: ActionDto[]
    additionalEditFields?: FieldDto[]
    includeFields: string[]
}

export const ActionMenu: React.FC<ActionMenuProps> = React.memo(function ActionMenu({
    record,
    onOpenChange,
    onCloseAutoFocus,
    actionButtons,
    additionalEditFields,
    includeFields,
    className,
    ...props
}) {
    const [isOpen, setIsOpen] = useState(false)

    const handleOpenChange = useCallback(
        (open: boolean) => {
            setIsOpen(open)
            onOpenChange?.(open)
        },
        [onOpenChange]
    )

    return (
        <Dropdown open={isOpen} onOpenChange={handleOpenChange}>
            <DropdownButton
                size="xs"
                variant="secondary"
                startIcon={{ name: 'MoreHorizontal' }}
                aria-label="Record actions"
                className={classNames(className, ActionMenuButtonStyle)}
                {...props}
            />
            {isOpen && (
                <ActionMenuContent
                    align="end"
                    onCloseAutoFocus={onCloseAutoFocus}
                    record={record}
                    actionButtons={actionButtons}
                    additionalEditFields={additionalEditFields}
                    includeFields={includeFields}
                    onOpenChange={handleOpenChange}
                />
            )}
        </Dropdown>
    )
})
