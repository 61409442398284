import { useCallback, useMemo, useRef } from 'react'

import { useAttributeContext } from './useAttributeContext'

type BaseAttributeStateProps = {
    onClearValue?: () => void
    isLoading?: boolean
}

export function useBaseAttributeState({ onClearValue, isLoading }: BaseAttributeStateProps) {
    const {
        isEditingValue,
        icon,
        label,
        isSavingSlow,
        requestEditValueMode,
        showFieldIcon,
        labelPlacement,
        field,
        clearValue,
        isRequired,
    } = useAttributeContext<unknown>()

    const isLabelLeft = labelPlacement === 'left' || (labelPlacement === 'hide' && showFieldIcon)
    const isLabelTop = labelPlacement === 'top' || !isLabelLeft

    const isEditable = field.isEditable && !isLoading

    const isEditingValueRef = useRef(isEditingValue)
    isEditingValueRef.current = isEditingValue

    const onAttributeClick = useCallback(() => {
        requestAnimationFrame(() => {
            if (!isEditingValueRef.current) {
                requestEditValueMode()
            }
        })
    }, [requestEditValueMode])

    const onValueClear = useCallback(
        (e: React.MouseEvent<HTMLElement>) => {
            e.preventDefault()
            e.stopPropagation()

            if (typeof onClearValue === 'function') {
                onClearValue()
            } else {
                clearValue()
            }
        },
        [onClearValue, clearValue]
    )

    const attributeRef = useRef<HTMLDivElement>(null)

    const onKeyDown = useCallback((e: React.KeyboardEvent<HTMLDivElement>) => {
        if (e.key === 'Enter' && document.activeElement === attributeRef.current) {
            attributeRef.current?.click()
        }
    }, [])

    return useMemo(
        () => ({
            icon,
            label,
            onAttributeClick,
            onValueClear,
            isSaving: isSavingSlow,
            isEditingValue,
            showFieldIcon,
            labelPlacement,
            onKeyDown,
            attributeRef,
            isLabelLeft,
            isLabelTop,
            isEditable,
            isRequired,
        }),
        [
            icon,
            label,
            onAttributeClick,
            onValueClear,
            isSavingSlow,
            isEditingValue,
            showFieldIcon,
            labelPlacement,
            onKeyDown,
            isLabelLeft,
            isLabelTop,
            isEditable,
            isRequired,
        ]
    )
}
