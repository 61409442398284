import { useCallback } from 'react'

import { useAgents } from 'data/hooks/agents/agents'
import { useAgentSkills } from 'data/hooks/agents/skills'
import { useWorkflowSchema } from 'data/hooks/workflows/workflows'
import { WorkflowSchemaActionStateItemAvailability } from 'data/hooks/workflowTypes'
import { convertOutputSchemaToOutputs } from 'features/workflows/OutputSchema/utils'

export function useAgentSkillActionFinalSchema() {
    const { data: agents } = useAgents()
    const { data: schema } = useWorkflowSchema()
    const { data: skills } = useAgentSkills(agents?.[0]?._sid || '')

    return useCallback(
        (config: WorkflowActionConfig): WorkflowSchemaNodeType | undefined => {
            const type = schema?.nodes.find(
                (t) => t.id === config.action_type
            ) as WorkflowSchemaActionType

            if (!type || !config.settings?.agent_id || !config.settings?.skill_id) {
                return undefined
            }

            const selectedAgent = agents?.find((a) => a._sid === config.settings?.agent_id)
            const selectedSkill = skills?.find((s) => s._sid === config.settings?.skill_id)

            if (!selectedAgent || !selectedSkill) {
                return undefined
            }

            const responseSchema = selectedSkill.output_schema as WorkflowOutputSchema | undefined

            let skill_result:
                | WorkflowSchemaActionStateItem
                | WorkflowSchemaStateItemGroup
                | undefined = undefined
            if (!responseSchema) {
                skill_result = {
                    id: 'result',
                    name: 'Skill Result',
                    type: 'long_text',
                    availability: WorkflowSchemaActionStateItemAvailability.AfterExecution,
                } as WorkflowSchemaActionStateItem
            } else {
                skill_result = {
                    id: 'result',
                    name: 'Skill Result',
                    type: 'group',
                    availability: WorkflowSchemaActionStateItemAvailability.AfterExecution,
                    items: convertOutputSchemaToOutputs(responseSchema),
                } as WorkflowSchemaStateItemGroup
            }

            return {
                ...type,
                inputs: type.inputs.map((o) => {
                    if (['skill_inputs'].includes(o.id)) {
                        const group = o as WorkflowSchemaNodeInputGroup
                        return {
                            ...group,
                            items: group.items.concat(
                                (selectedSkill.inputs || []).map((input) => ({
                                    id: input.name,
                                    name: input.description || input.name,
                                    type: 'long_text',
                                })) as (WorkflowSchemaNodeInput | WorkflowSchemaNodeInputGroup)[]
                            ),
                        }
                    }
                    return o
                }),
                state: [...type.state, skill_result],
            }
        },
        [agents, schema?.nodes, skills]
    )
}
