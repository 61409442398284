import React from 'react'

import { Plugin } from '@tiptap/pm/state'
import { CommandProps, Range } from '@tiptap/react'

import { useUserRecord } from 'data/hooks/users/main'
import {
    createTokenExtension,
    TokenExtensionSettings,
} from 'features/tiptap/Extensions/TokenExtension'
import { hasTrAddedChar, hasTrRemovedChar } from 'features/tiptap/utilities'
import { useStackerUsersObject } from 'features/workspace/stackerUserUtils'

import { ContextSchema } from './types'
import { useCheckContextItemType } from './useCheckContextItemType'
import { useReturnTypeGroup } from './useReturnTypeGroup'
import { buildContextSchemaWithPaths } from './utilities'

declare module '@tiptap/core' {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface Commands<ReturnType> {
        contextItem: {
            insertToken: (
                attrs: { id: string; label: string; tag?: string },
                range: Range
            ) => ReturnType
            openMentionContextMenu(): ReturnType
            closeMentionContextMenu(): ReturnType
        }
    }
}

const MENTION_PREFIX = '@'

type Settings = TokenExtensionSettings & {
    allowMentions?: boolean
}

const defaultSettings: Settings = {
    allowMentions: false,
}

export function useExpressionTokenExtension(settings: Settings) {
    const { data: userRecord } = useUserRecord()
    const usersObject = useStackerUsersObject()
    const userLookupTarget = userRecord?.[0]?._object_id
    const settingsRef = React.useRef<Settings>({ ...defaultSettings, ...settings })
    settingsRef.current = { ...defaultSettings, ...settings }

    const returnType = 'lookup'
    const returnExtraOptions: WorkflowExtraOptions = {
        link_target_object_id: usersObject?._sid,
    }

    const returnTypeGroup = useReturnTypeGroup({
        type: returnType,
        extraOptions: returnExtraOptions,
    })
    const { applyTypeCheckToItems } = useCheckContextItemType('lookup', {
        link_target_object_id: userLookupTarget,
    })

    const filterSchema = (schema: ContextSchema) => {
        return buildContextSchemaWithPaths({
            contextSchema: applyTypeCheckToItems(schema),
            returnTypeGroup,
        })
    }

    return createTokenExtension({
        name: 'contextItem',
        getSettings: () => settingsRef.current,
    })
        .extend({
            addProseMirrorPlugins() {
                return [
                    new Plugin({
                        appendTransaction: (_, oldState, newState) => {
                            const { allowMentions } = settingsRef.current
                            if (!allowMentions) return null

                            const oldSelection = oldState.selection
                            const newSelection = newState.selection

                            if (oldSelection.from < 1 || newSelection.from < 1) return null

                            const oldSelectionText = oldState.doc.textBetween(
                                oldSelection.from - 1,
                                oldSelection.to
                            )

                            const newSelectionText = newState.doc.textBetween(
                                newSelection.from - 1,
                                newSelection.to
                            )

                            // If we're no longer near the mention prefix, and no text was entered, close the menu.
                            if (
                                oldSelectionText === MENTION_PREFIX &&
                                newSelectionText !== MENTION_PREFIX &&
                                oldState.doc.eq(newState.doc)
                            ) {
                                this.editor.commands.closeMentionContextMenu()
                            }

                            // If we are now near the mention prefix, open the menu.
                            if (
                                oldSelectionText !== MENTION_PREFIX &&
                                newSelectionText === MENTION_PREFIX
                            ) {
                                this.editor.commands.openMentionContextMenu()
                            }

                            return null
                        },
                        filterTransaction: (tr, state) => {
                            const { allowMentions } = settingsRef.current
                            if (!allowMentions) return true

                            // The user entered the mention prefix.
                            const hasAddedMentionChar = hasTrAddedChar(MENTION_PREFIX, tr)
                            if (hasAddedMentionChar) {
                                this.editor.commands.openMentionContextMenu()

                                return true
                            }

                            // The user removed the mention prefix.
                            const hasRemovedChar = hasTrRemovedChar(MENTION_PREFIX, tr)
                            if (hasRemovedChar) {
                                this.editor.commands.closeMentionContextMenu()
                            }

                            // The user added a space after the mention prefix.
                            const hasAddedSpaceChar = hasTrAddedChar(' ', tr)
                            if (hasAddedSpaceChar) {
                                const currentPos = state.selection.$from.pos
                                if (currentPos < 1) return true

                                const prevCharPos = currentPos - 1
                                const charBeforeCursor = state.doc.textBetween(
                                    prevCharPos,
                                    currentPos
                                )
                                if (charBeforeCursor !== MENTION_PREFIX) return true
                            }
                            if (hasAddedSpaceChar) {
                                this.editor.commands.closeMentionContextMenu()

                                return true
                            }

                            return true
                        },
                    }),
                ]
            },
            addCommands() {
                return {
                    insertToken:
                        (attrs: { id: string; label: string }, range: Range) =>
                        ({ editor, commands }: CommandProps) => {
                            let actualRange = {
                                ...range,
                            }

                            const prefix = editor.state.doc.textBetween(
                                actualRange.from - 1,
                                actualRange.from
                            )
                            const isMention = prefix === MENTION_PREFIX
                            // If this is a mention, replace the `@` prefix as well.
                            if (isMention) {
                                actualRange.from -= 1
                            }

                            const content = [
                                {
                                    type: this.name,
                                    attrs: {
                                        ...attrs,
                                        isMention,
                                    },
                                },
                            ]

                            const { clearContent } = settingsRef.current
                            if (clearContent) {
                                return commands.setContent(content, true)
                            }
                            return commands.insertContentAt(actualRange, content)
                        },
                    openMentionContextMenu: () => {
                        return ({ tr }) => {
                            tr.setMeta('addToHistory', false)
                            tr.setMeta('showContextItemsMenu', {
                                isOpen: true,
                                filter: filterSchema,
                            })

                            return true
                        }
                    },
                    closeMentionContextMenu: () => {
                        return ({ tr }) => {
                            tr.setMeta('addToHistory', false)
                            tr.setMeta('showContextItemsMenu', {
                                isOpen: false,
                                filter: null,
                            })

                            return true
                        }
                    },
                }
            },
            addAttributes() {
                return {
                    ...this.parent?.(),
                    isMention: {
                        default: null,
                        parseHTML: (element) => element.getAttribute('data-is-mention'),
                        renderHTML: (attributes) => {
                            if (!attributes.isMention) {
                                return {}
                            }

                            return {
                                'data-is-mention': attributes.isMention,
                            }
                        },
                    },
                }
            },
        })
        .configure({
            renderLabel({ node }) {
                const label = `${node.attrs.label ?? node.attrs.id}`

                const isMention = node.attrs.isMention
                if (isMention) {
                    return `@${label}`
                }

                return label
            },
        })
}
