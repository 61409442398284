import React from 'react'

import { useThreadViewContentState } from './hooks/useThreadViewContentState'
import { ThreadViewDayDivider } from './ThreadViewDayDivider'
import { ThreadViewMessage } from './ThreadViewMessage'

type ThreadViewContentProps = {}

export const ThreadViewContent: React.FC<ThreadViewContentProps> = React.memo(
    function ThreadViewContent() {
        const { groupedRecords, showDayDivider } = useThreadViewContentState()

        return (
            <>
                {groupedRecords?.map(([day, records]) => (
                    <>
                        {showDayDivider && <ThreadViewDayDivider day={day} />}
                        {records?.map((record, idx) => (
                            <ThreadViewMessage key={idx} record={record} />
                        ))}
                    </>
                ))}
            </>
        )
    }
)
