import { WidgetSchema } from 'features/views/LayoutEditor/types'
import { getRequiredFieldsFromField } from 'features/views/LayoutEditor/utils'

import { FieldsWidget, FieldsWidgetAdminControls } from './FieldsWidget'
import { FieldsWidgetType } from './fieldWidgetTypes'

export const FieldsWidgetMeta: WidgetSchema<FieldsWidgetType> = {
    category: 'data',
    label: 'Fields',
    icon: 'List',
    component: FieldsWidget,
    adminControlsComponent: FieldsWidgetAdminControls,
    defaultAttrs: {
        labelPlacement: 'top',
        showFieldIcon: false,
        fields: [],
        showAllFields: true,
    },
    requireFields: (attrs, fields) => {
        if (attrs.showAllFields) {
            const allFields = Array.from(fields?.values() ?? [])
            return allFields.flatMap((f) => getRequiredFieldsFromField(f))
        }

        return attrs.fields.flatMap((f) => {
            const field = fields?.get(f.sid)
            if (!field) return []

            return getRequiredFieldsFromField(field)
        })
    },
}
