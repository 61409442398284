import React from 'react'
import { Link } from 'react-router-dom'

import { useComposedRefs } from '@radix-ui/react-compose-refs'
import { isEqual } from 'lodash'

import { ActionContextMenu } from 'features/views/ListView/Actions/ActionContextMenu'
import { RecordActionButton } from 'features/views/ListView/Actions/RecordActionButton'

import { Box } from 'ui/components/Box'
import { Divider } from 'ui/components/Divider'
import { Icon } from 'ui/components/Icon'
import { Skeleton } from 'ui/components/Skeleton'

import { CardViewRecordActions } from './Actions/CardViewRecordActions'
import { Attribute } from './attributes/Attribute'
import { useCardViewCardState } from './hooks/useCardViewCardState'
import * as Parts from './CardView.parts'
import { CardViewCommentCount } from './CardViewCommentCount'
import { CardViewCoverImage } from './CardViewCoverImage'
import { CardViewEyebrow } from './CardViewEyebrow'
import { CardViewProfileImage } from './CardViewProfileImage'
import { CardViewSubtitle } from './CardViewSubtitle'
import { CardViewAttribute, CardViewFooterAttribute } from './types'
import { CardViewContextValue } from './useCardViewContext'

import {
    CardInnerStyles,
    CardViewCardContentStyle,
    CardViewContentFooterStyle,
    CardViewContentHeaderStyle,
    CardViewContentLeftStyles,
    CardViewContentRightStyles,
    CardViewFooterColumnStyle,
} from './CardView.css'

type CardViewCardRef = HTMLDivElement

type CardViewCardProps = React.ComponentPropsWithoutRef<typeof Parts.Card> & {
    record?: RecordDto
    siblingRecords?: RecordDto[]
}

const CardViewCardInner = React.forwardRef<CardViewCardRef, CardViewCardProps>(
    function CardViewCard({ record, siblingRecords, isLoading = false, ...props }, ref) {
        const {
            to,
            onClick,
            titleAttribute,
            contentAttributes,
            labelStyle,
            actionContextMenuRef,
            cardRef,
            onContextMenu,
            isPending,
            actionButtons,
            includeFields,
            footerLeftButton,
            footerRightButton,
            footerLeftAttribute,
            footerRightAttribute,
            coverImageSrc,
            coverImageField,
            profileImageField,
            cardStyle,
            leftContentType,
            isFullWidth,
            coverWrapperRef,
            innerRef,
            contentRef,
            placeholderAttribute,
            placeholderValue,
            additionalEditFields,
            cardSize,
        } = useCardViewCardState({
            record,
            siblingRecords,
        })

        const composedRef = useComposedRefs(ref, cardRef)

        return (
            <Parts.Card
                ref={composedRef}
                as={Link}
                to={to}
                onClick={onClick}
                data-recordid={record?._sid}
                isLoading={isLoading}
                onContextMenu={onContextMenu}
                isPending={isPending}
                tabIndex={isLoading || isPending ? -1 : undefined}
                cardStyle={cardStyle}
                cardSize={cardSize}
                {...props}
            >
                {record && (
                    <div
                        ref={innerRef}
                        className={CardInnerStyles.styleFunction({
                            hasCoverImage: leftContentType === 'cover',
                        })}
                    >
                        {leftContentType === 'cover' ? (
                            <Box grow={!isFullWidth} flexShrink={1} alignSelf="stretch">
                                <div
                                    ref={coverWrapperRef}
                                    className={CardViewContentLeftStyles.styleFunction({
                                        hasCoverImage: true,
                                    })}
                                >
                                    <CardViewCoverImage
                                        isLoading={isLoading}
                                        src={coverImageSrc}
                                        placeholderAttribute={placeholderAttribute}
                                        placeholderValue={placeholderValue}
                                    />
                                    <CardViewEyebrow
                                        record={record}
                                        isLoading={isLoading}
                                        hasCoverImage={true}
                                    />
                                </div>
                            </Box>
                        ) : leftContentType === 'profile' ? (
                            <div
                                className={CardViewContentLeftStyles.styleFunction({
                                    hasCoverImage: false,
                                })}
                            >
                                <CardViewProfileImage
                                    record={record}
                                    isLoading={isLoading}
                                    hasCoverImage={false}
                                />
                            </div>
                        ) : null}
                        <div className={CardViewContentRightStyles}>
                            <Box ref={contentRef} width="full" flex column alignSelf="stretch">
                                <CardViewCardHeader
                                    record={record}
                                    titleAttribute={titleAttribute}
                                    isLoading={isLoading}
                                    hasCoverImage={!!coverImageField}
                                    showProfileImage={
                                        !!profileImageField && leftContentType !== 'profile'
                                    }
                                    showEyebrow={leftContentType !== 'cover'}
                                />
                                <Box flex flexGrow={1} />
                                <CardViewCardContent
                                    record={record}
                                    attributes={contentAttributes}
                                    isLoading={isLoading}
                                    labelStyle={labelStyle}
                                    isFullWidth={isFullWidth}
                                />
                                <CardViewCardFooter
                                    record={record}
                                    isLoading={isLoading}
                                    leftAttribute={footerLeftAttribute}
                                    leftAction={footerLeftButton}
                                    rightAttribute={footerRightAttribute}
                                    rightAction={footerRightButton}
                                    includeFields={includeFields}
                                />
                                {!isLoading && (
                                    <>
                                        <CardViewRecordActions
                                            record={record}
                                            actionButtons={actionButtons}
                                            includeFields={includeFields}
                                            additionalEditFields={additionalEditFields}
                                        />
                                        <ActionContextMenu
                                            ref={actionContextMenuRef}
                                            record={record}
                                            actionButtons={actionButtons}
                                            includeFields={includeFields}
                                            additionalEditFields={additionalEditFields}
                                        />
                                    </>
                                )}
                            </Box>
                        </div>
                    </div>
                )}
            </Parts.Card>
        )
    }
)

export const CardViewCard = React.memo(CardViewCardInner, isEqual)

type CardViewCardHeaderProps = {
    record: RecordDto
    titleAttribute?: CardViewAttribute
    isLoading?: boolean
    hasCoverImage?: boolean
    showProfileImage?: boolean
    showEyebrow?: boolean
}

const CardViewCardHeader: React.FC<CardViewCardHeaderProps> = React.memo(
    function CardViewCardHeader({
        titleAttribute,
        isLoading,
        record,
        hasCoverImage,
        showProfileImage,
        showEyebrow,
    }) {
        const recordTitle = titleAttribute ? record[titleAttribute.field.api_name] : undefined
        const placeholder =
            titleAttribute && titleAttribute.field.type === 'multi_file' && recordTitle
                ? recordTitle.filename
                : recordTitle

        return (
            <Box position="relative">
                {showProfileImage && (
                    <CardViewProfileImage
                        record={record}
                        isLoading={isLoading}
                        hasCoverImage={hasCoverImage}
                        placeholder={placeholder}
                    />
                )}

                <Box className={CardViewContentHeaderStyle}>
                    <Box style={{ width: '100%', overflow: 'hidden' }}>
                        {showEyebrow && (
                            <CardViewEyebrow
                                record={record}
                                isLoading={isLoading}
                                hasCoverImage={false}
                            />
                        )}
                        {titleAttribute && (
                            <Attribute
                                attribute={titleAttribute}
                                isLoading={isLoading}
                                displayStyle="list"
                                value={recordTitle}
                            />
                        )}
                        <CardViewSubtitle record={record} isLoading={isLoading} />
                    </Box>
                </Box>
            </Box>
        )
    }
)

type CardViewCardContentProps = {
    record: RecordDto
    attributes: CardViewAttribute[]
    isLoading?: boolean
    labelStyle?: CardViewContextValue['labelStyle']
    isFullWidth?: boolean
}

const CardViewCardContent: React.FC<CardViewCardContentProps> = React.memo(
    function CardViewCardContent({ record, attributes, isLoading, labelStyle, isFullWidth }) {
        const hasLabels = labelStyle === 'text'
        const hasIcons = labelStyle === 'icon'

        if (attributes.length < 1) return null

        return (
            <Box className={CardViewCardContentStyle.styleFunction({ hasLabels, hasIcons })}>
                {attributes.map((attribute, idx) => {
                    const value = record[attribute.field.api_name] ?? undefined
                    const showSeparator =
                        !hasLabels && !hasIcons && isFullWidth && idx < attributes.length - 1

                    return (
                        <Box key={attribute.id} display="flex" alignItems="center">
                            <Attribute
                                attribute={attribute}
                                isLoading={isLoading}
                                value={value}
                                displayStyle="list"
                            />
                            {showSeparator && <Icon name="Dot" size="xs" ml="xs" color="gray900" />}
                        </Box>
                    )
                })}
            </Box>
        )
    },
    isEqual
)

type FooterAttributeProps = {
    record: RecordDto
    attribute?: CardViewFooterAttribute
    isLoading?: boolean
    action?: ActionDto
    includeFields?: string[]
    actionVariant?: 'primary' | 'secondary'
}

const FooterAttribute: React.FC<FooterAttributeProps> = React.memo(function FooterAttribute({
    record,
    isLoading,
    attribute,
    action,
    includeFields,
    actionVariant = 'primary',
}) {
    if (action) {
        return (
            <Skeleton isLoading={isLoading}>
                <RecordActionButton
                    key={action._sid}
                    record={record}
                    action={action}
                    includeFields={includeFields ?? []}
                    size="s"
                    variant={actionVariant}
                    maxWidth="full"
                />
            </Skeleton>
        )
    }

    if (!attribute) return null

    if (attribute === '_record_stats') {
        return <CardViewCommentCount record={record} isLoading={isLoading} />
    }

    return (
        <Attribute
            attribute={attribute}
            isLoading={isLoading}
            value={record[attribute.field.api_name] ?? undefined}
        />
    )
})

type CardViewCardFooterProps = {
    record: RecordDto
    isLoading?: boolean
    leftAttribute?: CardViewFooterAttribute
    leftAction?: ActionDto
    rightAttribute?: CardViewFooterAttribute
    rightAction?: ActionDto
    includeFields?: string[]
}

const CardViewCardFooter: React.FC<CardViewCardFooterProps> = React.memo(
    function CardViewCardFooter({
        isLoading,
        record,
        leftAttribute,
        leftAction,
        rightAttribute,
        rightAction,
        includeFields,
    }) {
        if (!leftAttribute && !leftAction && !rightAttribute && !rightAction) return null

        const hasDoubleActions = !!leftAction && !!rightAction

        return (
            <Box className={CardViewContentFooterStyle}>
                <Divider variant="weak" />
                <Box
                    flex
                    center
                    gap={hasDoubleActions ? 'xs' : 's'}
                    alignSelf="stretch"
                    justifyContent={hasDoubleActions ? 'flex-start' : 'space-between'}
                    mt="xs"
                >
                    <Box minWidth={0} className={CardViewFooterColumnStyle}>
                        {(leftAttribute || leftAction) && (
                            <FooterAttribute
                                record={record}
                                isLoading={isLoading}
                                attribute={leftAttribute}
                                action={leftAction}
                                includeFields={includeFields}
                            />
                        )}
                    </Box>
                    <Box minWidth={0} className={CardViewFooterColumnStyle}>
                        {(rightAttribute || rightAction) && (
                            <FooterAttribute
                                record={record}
                                isLoading={isLoading}
                                attribute={rightAttribute}
                                action={rightAction}
                                includeFields={includeFields}
                                actionVariant={hasDoubleActions ? 'secondary' : 'primary'}
                            />
                        )}
                    </Box>
                </Box>
            </Box>
        )
    }
)
