import React from 'react'

import type { History, Location } from 'history'
import { ListViewOrderBy } from 'v2/views/utils/orderBy/types'

import { useAppUserContext } from 'app/useAppUserContext'
import { EditModeListContextProvider } from 'features/admin/edit-mode/ListView/EditModeListContextProvider'
import { getIsStackerNativeObject } from 'features/admin/stackerNativeObjectUtils'

import List from 'v2/ui/components/List/List'

import ListViewButtons from './ListViewButtons'
import {
    CustomListRendererComponent,
    OpenRecordFunction,
    RowLinkFunction,
    ViewColumn,
} from './types'
import { ViewState } from './viewStateType'

type ListViewEditModeProps = {
    setConfig: (patch: Partial<ListViewOptions>, shouldSave?: boolean) => void
    setEndUserFilters: (newFilters: Filter) => void
    setEndUserFilters__NotForBackend: (newFilters: Filter) => void
    rowLinkFunction: RowLinkFunction
    setOrderBy?: (order?: ListViewEditModeProps['orderBy']) => void
    showControls?: boolean
    isRecordList?: boolean
    noLinks?: boolean
    isServerLoading?: boolean
    view?: ViewDto
    viewState?: ViewState
    object?: ObjectDto
    title?: string
    columns?: ViewColumn[]
    filteredRecords?: RecordDto[]
    overrideViewOptions?: ListViewOptions
    endUserFilters?: Filter[]
    allFilters__NotForBackend?: Filter[]
    dereferencedRecords?: RecordDto[]
    totalResults?: number
    relatedFieldMayCreateNewRecords?: boolean
    hideEditControls?: boolean
    isRecordListOnCreate?: boolean
    listEditControls?: React.ComponentType
    relatedListSymmetricColumnName?: string
    relatedListType?: string
    autoFilledRelatedListRecord?: string
    orderBy?: ListViewOrderBy
    relatedListAttrs?: { viewId?: string }
    location?: Location
    history?: History
    relatedListField?: string
    hiddenColumnIds: string[]
    userFilterFieldIds: string[]
    hideSearch?: boolean
    getPageSizeOptions?: (display?: string) => number[]
    getDefaultPageSize?: (display?: string) => number
    customListRenderer?: CustomListRendererComponent
    allowDownload?: boolean
    onDownloadCsv?: () => Promise<void>
    lastFilters?: any
    setLastFilters?: (filters: any) => void
    openRecord?: OpenRecordFunction
    setPageIndex: (pageIdx: number) => void
    setPageSize: (pageSize: number) => void
    pageSize?: number
    pageIndex: number
    includeFields: string[]
}

const ListViewEditMode: React.FC<ListViewEditModeProps> = ({
    setConfig,
    showControls,
    setEndUserFilters,
    setEndUserFilters__NotForBackend,
    rowLinkFunction,
    setOrderBy,
    isRecordList,
    noLinks,
    isServerLoading,
    view,
    viewState,
    object,
    title,
    columns,
    hiddenColumnIds,
    userFilterFieldIds,
    filteredRecords,
    overrideViewOptions,
    dereferencedRecords,
    totalResults,
    relatedFieldMayCreateNewRecords,
    hideEditControls,
    isRecordListOnCreate,
    listEditControls,
    relatedListSymmetricColumnName,
    relatedListType,
    autoFilledRelatedListRecord,
    orderBy,
    relatedListAttrs,
    history,
    location,
    hideSearch,
    getPageSizeOptions,
    getDefaultPageSize,
    customListRenderer,
    allowDownload,
    onDownloadCsv,
    lastFilters,
    setLastFilters,
    relatedListField,
    openRecord,
    setPageIndex,
    setPageSize,
    pageSize,
    pageIndex,
    includeFields,
}) => {
    const { isAdmin } = useAppUserContext()

    // When in SB mode, we show an `Add new` button on any empty list for admins and end users
    const emptyStateAdditionalActions =
        getIsStackerNativeObject(object) && isAdmin ? (
            <ListViewButtons
                object={object}
                viewState={viewState}
                isRecordList={isRecordList}
                relatedFieldMayCreateNewRecords={relatedFieldMayCreateNewRecords}
                hideEditControls={hideEditControls}
                isRecordListOnCreate={isRecordListOnCreate}
                listEditControls={listEditControls}
                relatedListSymmetricColumnName={relatedListSymmetricColumnName}
                relatedListType={relatedListType}
                autoFilledRelatedListRecord={autoFilledRelatedListRecord}
            />
        ) : null

    return (
        <>
            <EditModeListContextProvider disableEditControls={isRecordList}>
                <List
                    key={viewState?.view?.options?.display}
                    object={object}
                    isRecordList={isRecordList}
                    title={title}
                    // @ts-expect-error
                    columns={columns}
                    // @ts-expect-error
                    hiddenColumnIds={hiddenColumnIds}
                    userFilterFieldIds={userFilterFieldIds}
                    // @ts-expect-error
                    data={filteredRecords}
                    dereferencedRecords={dereferencedRecords}
                    totalResults={totalResults}
                    isServerLoading={isServerLoading}
                    setEndUserFilters={setEndUserFilters}
                    setEndUserFilters__NotForBackend={setEndUserFilters__NotForBackend}
                    showFilters={viewState?.view?.options?.enableEndUserFilters}
                    display={viewState?.view?.options?.display}
                    viewOptions={overrideViewOptions}
                    showControls={showControls}
                    lastFilters={lastFilters}
                    setLastFilters={setLastFilters}
                    buttons={
                        <ListViewButtons
                            object={object}
                            viewState={viewState}
                            isRecordList={isRecordList}
                            relatedFieldMayCreateNewRecords={relatedFieldMayCreateNewRecords}
                            hideEditControls={hideEditControls}
                            isRecordListOnCreate={isRecordListOnCreate}
                            listEditControls={listEditControls}
                            relatedListSymmetricColumnName={relatedListSymmetricColumnName}
                            relatedListType={relatedListType}
                            autoFilledRelatedListRecord={autoFilledRelatedListRecord}
                        />
                    }
                    rowLink={noLinks || !object ? undefined : rowLinkFunction}
                    setConfig={setConfig}
                    history={history}
                    location={location}
                    showPageSizeOptions={viewState?.view?.options?.allowChangePageSize}
                    getPageSizeOptions={getPageSizeOptions}
                    getDefaultPageSize={getDefaultPageSize}
                    emptyStateAdditionalActions={emptyStateAdditionalActions}
                    onOrderByChange={setOrderBy}
                    orderBy={orderBy}
                    hideSearch={hideSearch}
                    customRenderer={customListRenderer}
                    allowDownload={allowDownload}
                    onDownloadCsv={onDownloadCsv}
                    view={view}
                    relatedListFieldId={relatedListField}
                    relatedListFieldValue={autoFilledRelatedListRecord}
                    relatedListViewId={relatedListAttrs?.viewId}
                    openRecord={openRecord}
                    setServerPageIndex={setPageIndex}
                    setServerPageSize={setPageSize}
                    serverPageSize={pageSize}
                    serverPageIndex={pageIndex}
                    includeFields={includeFields}
                />
            </EditModeListContextProvider>
        </>
    )
}

export default ListViewEditMode
