import React, { useState } from 'react'

import { updateObject } from 'data/hooks/objects/objectOperations'
import { Divider, ExpandSection } from 'legacy/v1/ui'

import { ONBOARDING_CLASSES } from 'v2/ui/styleClasses'

import { Toggle } from 'ui/components/Toggle'

import { DeleteTableSettingContent } from './DeleteTableSetting'

type SharedCopySettingsProps = {
    object: ObjectDto
    openErrorToast: (operation?: 'update' | 'delete' | 'unshare') => void
}

export const SharedCopySettings: React.FC<SharedCopySettingsProps> = ({
    object,
    openErrorToast,
}) => {
    const [isReadOnlySchema, setIsReadOnlySchema] = useState(object.is_schema_read_only)

    const handleToggleChange = async (checked: boolean) => {
        try {
            await updateObject(object._sid, { is_schema_read_only: checked })
            setIsReadOnlySchema(checked)
        } catch {
            openErrorToast('update')
        }
    }

    return (
        <>
            <Divider />
            {/* @ts-expect-error */}
            <ExpandSection
                heading="Sharing settings"
                text="Manage how this table is imported in this app."
                helpUrl=""
                helpText=""
                classes={{
                    heading:
                        ONBOARDING_CLASSES.APP_SETTINGS_DATA_CONNECTION.SETTINGS_ITEM.DELETE_OBJECT,
                }}
            >
                <Toggle
                    id="read-only-schema"
                    checked={isReadOnlySchema}
                    onCheckedChange={handleToggleChange}
                    pb="2xl"
                >
                    Prevent schema modifications from this app (not a security feature)
                </Toggle>
                <DeleteTableSettingContent object={object} openErrorToast={openErrorToast} />
            </ExpandSection>
        </>
    )
}
