import { getCachedFields } from 'data/hooks/fields'
import { getLinksFromObject } from 'data/utils/getLinks'

const CURRENT_USER_FIELDS = [
    'isCurrentUser',
    'containsCurrentUser',
    'isNotCurrentUser',
    'doesNotContainCurrentUser',
]
const CURRENT_USER_FIELD_FIELDS = ['isCurrentUserField', 'isNotCurrentUserField']

const hasMultipleUserProfilesPerTable = (filter: Filter, userObjectId: string): boolean => {
    const allFields = getCachedFields()
    const userLinks = getLinksFromObject(allFields, userObjectId)

    // i.e. 'the record is current user' when multiple links exists to profile object'
    if (CURRENT_USER_FIELDS.includes(filter?.options?.option!)) {
        const targetObjectId = filter?.field?.link_target_object_id
        const linksToTargetObject = userLinks.filter(
            (link) =>
                link.source.objectId === userObjectId && link.target.objectId === targetObjectId
        )

        return linksToTargetObject?.length > 1
    }

    // i.e. '[field] is current user's [field on profile object]' when multiple links exists to profile object
    if (CURRENT_USER_FIELD_FIELDS.includes(filter?.options?.option!)) {
        const filterComparisonField = allFields?.find((f) => f.api_name === filter?.options?.value)
        const targetObjectId = filterComparisonField?.object_id

        const linksToTargetObject = userLinks.filter(
            (link) =>
                link.source.objectId === userObjectId && link.target.objectId === targetObjectId
        )

        return linksToTargetObject?.length > 1
    }

    return false
}

const getObjectName = (objectId: string, userProfileObjects: ObjectDto[]): string =>
    userProfileObjects?.find((o) => o._sid === objectId)?.name || 'same'

const getUserProfileName = (filter: Filter, userProfileObjects: ObjectDto[]): string => {
    const objectId = filter?.field?.link_target_object_id
    return objectId ? getObjectName(objectId, userProfileObjects) : ''
}

const isFilterLabelError = (
    options: { label: string; value: string }[],
    filterOptions: FilterOptions
): boolean => {
    return !options.find((f) => f.value === filterOptions?.option)?.label
}

const dropdownFilterHasError = (filter: Filter, field: Partial<FieldDto>): boolean => {
    if (field.type !== 'dropdown') return false

    const dropdownFieldOptions = field?.options?.options?.map((option) => option.value)
    if (!dropdownFieldOptions || !filter?.options?.value) return false

    const filterValues = Array.isArray(filter.options.value)
        ? filter.options.value
        : [filter.options.value]

    return filterValues.some((value) => !dropdownFieldOptions.includes(value))
}

const filterErrorToDisplay = (
    multipleUserProfilesError: boolean,
    dropdownFieldFilterError: boolean,
    filterLabelError: boolean,
    filter: Filter,
    userProfileObjects: ObjectDto[]
): string | null => {
    if (dropdownFieldFilterError) {
        return 'Some dropdown values have been changed (shown in red). Please update this filter.'
    }
    if (filterLabelError) {
        return 'Please update this filter as its field type has been changed.'
    }
    if (multipleUserProfilesError) {
        return `This filter may produce unexpected results as you have multiple user profile links to the ${getUserProfileName(
            filter,
            userProfileObjects
        )} table.`
    }
    return null
}

export const getFilterErrorState = (
    filter: Filter,
    field: Partial<FieldDto>,
    userObject: ObjectDto,
    userProfileObjects: ObjectDto[],
    options: { label: string; value: string }[],
    disableValidation?: boolean
) => {
    if (disableValidation) {
        return { hasFilterError: false, filterErrorMessage: null }
    }

    const multipleUserProfilesError = hasMultipleUserProfilesPerTable(filter, userObject?._sid)
    const dropdownFieldFilterError = dropdownFilterHasError(filter, field)
    const filterLabelError = isFilterLabelError(options, filter?.options)

    const filterErrorMessage = filterErrorToDisplay(
        multipleUserProfilesError,
        dropdownFieldFilterError,
        filterLabelError,
        filter,
        userProfileObjects
    )

    return { hasFilterError: !!filterErrorMessage, filterErrorMessage }
}
