import React, { useCallback } from 'react'

import {
    DetailViewTabControl,
    DetailViewTabControlItem,
} from 'features/views/DetailView/DetailViewTabControl'
import { WidgetAdminControlsComponent } from 'features/views/LayoutEditor/types'
import { ListViewWidgetType } from 'features/views/LayoutEditor/widgets/ListViewWidget/listViewWidgetTypes'

import { Box } from 'ui/components/Box'
import { RadioCard, RadioCardGroup } from 'ui/components/Radio'
import { theme } from 'ui/styling/Theme.css'

import { ListViewWidgetActionControls } from './ListViewWidgetActionControls'
import { ListViewWidgetContentControls } from './ListViewWidgetContentControls'
import { ListViewWidgetDataControls } from './ListViewWidgetDataControls'
import { ListViewWidgetStyleControls } from './ListViewWidgetStyleControls'

export const ListViewWidgetAdminControls: WidgetAdminControlsComponent<ListViewWidgetType> = ({
    widget,
    onChange,
    ...props
}) => {
    const display = widget.attrs.display || 'table'

    const onChangeDisplay = useCallback(
        (value: string) => {
            onChange((attrs) => {
                attrs.set('display', value)
            })
        },
        [onChange]
    )

    const hasObject = !!widget.attrs.objectSid

    return (
        <Box flex column gap="3xl" height="full" minWidth={0} width="full" overflowY="auto">
            <Box px="l" flex column>
                <RadioCardGroup
                    value={display}
                    onValueChange={onChangeDisplay}
                    style={{
                        display: 'grid',
                        gridTemplateColumns: 'repeat(auto-fit, minmax(0, 1fr))',
                        gap: theme.space.m,
                    }}
                >
                    <RadioCard value="table" icon={{ name: 'Table' }}>
                        Table
                    </RadioCard>
                    <RadioCard value="cards" icon={{ name: 'LayoutGrid' }}>
                        Cards
                    </RadioCard>
                    <RadioCard value="board" icon={{ name: 'Kanban' }}>
                        Board
                    </RadioCard>
                    <RadioCard value="thread" icon={{ name: 'MessagesSquare' }}>
                        Thread
                    </RadioCard>
                </RadioCardGroup>
            </Box>
            <DetailViewTabControl>
                <DetailViewTabControlItem value="content" label="Content" disabled={!hasObject}>
                    <ListViewWidgetContentControls widget={widget} onChange={onChange} {...props} />
                </DetailViewTabControlItem>
                <DetailViewTabControlItem value="style" label="Style" disabled={!hasObject}>
                    <ListViewWidgetStyleControls widget={widget} onChange={onChange} {...props} />
                </DetailViewTabControlItem>
                <DetailViewTabControlItem value="controls" label="Controls" disabled={!hasObject}>
                    <ListViewWidgetActionControls widget={widget} onChange={onChange} {...props} />
                </DetailViewTabControlItem>
                <DetailViewTabControlItem value="data" label="Data">
                    <ListViewWidgetDataControls widget={widget} onChange={onChange} {...props} />
                </DetailViewTabControlItem>
            </DetailViewTabControl>
        </Box>
    )
}
