import React from 'react'

import { useConfirmModal } from 'app/useConfirmModal'
import { refetchObjects } from 'data/hooks/objects/refetchObjects'
import { getIsStackerNativeObject } from 'features/admin/stackerNativeObjectUtils'

import { Button, ButtonProps } from 'ui/components/Button'

import { getDeleteTableModalData } from './utils'

type DeleteNativeTableButtonProps = {
    object: ObjectDto
    onDeleteObject: (sid: string) => Promise<unknown>
    // if this table is a copy of a shared table, then deletion is allowed but the wording is different
    isSharedTableCopy: boolean
} & ButtonProps

export const DeleteNativeTableButton: React.FC<DeleteNativeTableButtonProps> = ({
    object,
    onDeleteObject,
    isSharedTableCopy,
    ...buttonProps
}) => {
    const deleteTableModalData = getDeleteTableModalData(object)
    const { show: showConfirm, setData } = useConfirmModal({
        ...deleteTableModalData,
        onConfirm: (modal: any) => {
            setData({ ...deleteTableModalData, isSaving: true })
            onDeleteObject(object?._sid)
                .then(() => {
                    refetchObjects()
                })
                .then(() => {
                    setData({ ...deleteTableModalData, isSaving: false })
                    modal.toggle()
                })
        },
    })

    if (!getIsStackerNativeObject(object)) {
        return null
    }

    return (
        <div>
            <Button
                startIcon={{ name: isSharedTableCopy ? 'XCircle' : 'Trash' }}
                onClick={showConfirm}
                size="s"
                {...buttonProps}
            >
                {isSharedTableCopy ? 'Remove table' : 'Delete this table'}
            </Button>
        </div>
    )
}
