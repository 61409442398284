import React from 'react'

import classNames from 'classnames'

import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'
import { theme } from 'ui/styling/Theme.css'

import { useAdvancedFiltersState } from './hooks/useAdvancedFiltersState'
import { AddNewAdvancedFilter } from './AddNewAdvancedFilter'
import { AdvancedFiltersItem } from './AdvancedFiltersItem'

import { AdvancedFiltersStyle } from './AdvancedFilters.css'

type AdvancedFiltersProps = React.ComponentPropsWithoutRef<typeof Box> & {}

export const AdvancedFilters: React.FC<AdvancedFiltersProps> = React.memo(function AdvancedFilters({
    className,
    ...props
}) {
    const {
        clearFilters,
        isActive,
        onAddNewFilter,
        onUpdateFilter,
        onRemoveFilter,
        filters,
        onCommitDraft,
        recommendedFields,
    } = useAdvancedFiltersState()

    const clearButtonSize = useResponsiveValue({
        mobile: '2xs' as const,
        tablet: 's' as const,
    })

    const clearButtonHeight = useResponsiveValue({
        mobile: theme.button.sizes.xs,
        tablet: theme.button.sizes.s,
    })

    const recommendedButtonSize = useResponsiveValue({
        mobile: 'xs' as const,
        tablet: 's' as const,
    })

    return (
        <Box
            flex
            alignItems="flex-start"
            gap="m"
            justifyContent="space-between"
            className={classNames(AdvancedFiltersStyle, className)}
            {...props}
        >
            <Box flex center gap="s" wrap>
                {filters.map((f) => (
                    <AdvancedFiltersItem
                        key={f._id}
                        filter={f}
                        onUpdateFilter={onUpdateFilter}
                        onRemoveFilter={() => onRemoveFilter(f._id!)}
                        onCommitDraft={onCommitDraft}
                    />
                ))}
                {recommendedFields.map((f) => (
                    <Button
                        key={f.field._sid}
                        size={recommendedButtonSize}
                        variant="dotted"
                        onClick={() => onAddNewFilter(f.field)}
                    >
                        {f.label}
                    </Button>
                ))}
                <AddNewAdvancedFilter onFieldSelect={onAddNewFilter} />
            </Box>
            {isActive && (
                <Button
                    variant="ghost"
                    size={clearButtonSize}
                    onClick={clearFilters}
                    style={{ height: clearButtonHeight }}
                    noShrink
                >
                    Clear filters
                </Button>
            )}
        </Box>
    )
})
