import React from 'react'

import { CurrencyAttributeDisplay } from 'features/views/attributes/CurrencyAttributeDisplay'
import { useDetailViewAttributeValue } from 'features/views/DetailView/attributes/hooks/useDetailViewAttributeValue'

import { BaseAttribute } from './BaseAttribute'
import { PrimaryHighlightsAttributeComponent } from './types'

type CurrencyAttributeProps = {}

export const CurrencyAttribute: PrimaryHighlightsAttributeComponent<CurrencyAttributeProps> = ({
    field,
    isLoading,
    ...props
}) => {
    const value = useDetailViewAttributeValue<number>(field.api_name)
    const isEmpty = !isLoading && typeof value === 'undefined'

    return (
        <BaseAttribute {...props} field={field} isEmpty={isEmpty} isLoading={isLoading}>
            <CurrencyAttributeDisplay
                value={value}
                field={field}
                isLoading={isLoading}
                maxLines={1}
                style={{
                    fontSize: 'inherit',
                    fontWeight: 'inherit',
                    lineHeight: 'inherit',
                }}
                trim
                maxWidth="full"
                minWidth={0}
            />
        </BaseAttribute>
    )
}
