import React from 'react'

import { ObjectFieldsFilterV4 as Filters } from 'features/records/components/RecordFilters'
import { LayoutEditorCollapsibleControl } from 'features/views/LayoutEditor/controls/LayoutEditorCollapsibleControl'
import { LayoutEditorControlSeparator } from 'features/views/LayoutEditor/controls/LayoutEditorControlSeparator'
import { WidgetAdminControlsComponent, WidgetComponent } from 'features/views/LayoutEditor/types'

import { Banner } from 'ui/components/Banner'
import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import { Field } from 'ui/components/Field'
import { Input } from 'ui/components/Input'
import { Select, SelectOption } from 'ui/components/Select'
import { Body } from 'ui/components/Text'
import { Textarea } from 'ui/components/Textarea'
import { Toggle } from 'ui/components/Toggle'
import { theme } from 'ui/styling/Theme.css'

import { useBannerWidgetAdminControlsState } from './hooks/useBannerWidgetAdminControlsState'
import { useBannerWidgetState } from './hooks/useBannerWidgetState'
import { BannerWidgetType } from './bannerWidgetTypes'

type BannerWidgetProps = {}

export const BannerWidget: WidgetComponent<BannerWidgetType, BannerWidgetProps> = ({
    widget,
    isEditing,
}) => {
    const {
        type,
        title,
        subtitle,
        icon,
        isDismissable,
        isVisible,
        handleButtonClick,
        buttonLabel,
        link,
        onDismiss,
    } = useBannerWidgetState(widget)

    if (!isVisible) return null

    return (
        <Banner
            my="l"
            type={type}
            dismissable={isDismissable}
            title={title}
            helper={subtitle}
            icon={icon}
            pointerEvents={isEditing ? 'none' : undefined}
            link={link}
            onDismiss={onDismiss}
            button={
                !!buttonLabel
                    ? {
                          onClick: handleButtonClick,
                          children: (
                              <Box trim maxWidth="full" py="3xs" minWidth={0}>
                                  {buttonLabel}
                              </Box>
                          ),
                      }
                    : undefined
            }
        />
    )
}

type BannerWidgetAdminControlsProps = {}

export const BannerWidgetAdminControls: WidgetAdminControlsComponent<
    BannerWidgetType,
    BannerWidgetAdminControlsProps
> = ({ widget, onChange }) => {
    const {
        title,
        onChangeTitle,
        subtitle,
        onChangeSubtitle,
        isDismissable,
        onChangeIsDismissable,
        styleOptions,
        selectedStyleOption,
        onChangeStyle,
        object,
        fields,
        visibilityFilters,
        onChangeVisibilityFilters,
        actionOptions,
        selectedActionId,
        onChangeActionButton,
        linkHref,
        onChangeLinkHref,
        linkLabel,
        onChangeLinkLabel,
        linkOpenAs,
        onChangeLinkOpenAs,
    } = useBannerWidgetAdminControlsState({
        widget,
        onChange,
    })

    return (
        <Box flex column gap="l" height="full" overflowY="auto" pb="l">
            <LayoutEditorCollapsibleControl
                label="Content"
                startIcon={{ name: 'Text' }}
                defaultOpen={true}
            >
                <Box flex column gap="l">
                    <Select
                        label="Style"
                        value={selectedStyleOption?.value}
                        onChange={onChangeStyle}
                    >
                        {styleOptions.map((option) => (
                            <SelectOption
                                key={option.value}
                                value={option.value}
                                label={option.label}
                            />
                        ))}
                    </Select>
                    <LayoutEditorControlSeparator px={0} />
                    <Input label="Title" value={title} onChange={onChangeTitle} />
                    <Textarea
                        label="Subtitle"
                        value={subtitle}
                        onChange={onChangeSubtitle}
                        style={{
                            paddingTop: theme.input.padding.verticalM,
                            paddingBottom: theme.input.padding.verticalM,
                            width: '100%',
                            minHeight: theme.input.size.m,
                            alignItems: 'center',
                        }}
                        autoSize
                        textareaProps={{
                            style: {
                                maxHeight: theme.textarea.size.m,
                            },
                        }}
                    />
                    <LayoutEditorControlSeparator px={0} />
                    <Input
                        label="Link URL"
                        value={linkHref}
                        onChange={onChangeLinkHref}
                        placeholder="https://..."
                    />
                    {!!linkHref && (
                        <>
                            <Input
                                label="Link label"
                                value={linkLabel}
                                onChange={onChangeLinkLabel}
                                placeholder="Learn more"
                            />
                            <Field
                                htmlFor="linkOpenAs"
                                label="Open link in new tab"
                                rightSlotContent={
                                    <Toggle
                                        id="linkOpenAs"
                                        checked={linkOpenAs}
                                        onCheckedChange={onChangeLinkOpenAs}
                                    />
                                }
                            />
                        </>
                    )}
                    <LayoutEditorControlSeparator px={0} />
                    <Select
                        label="Action button"
                        value={selectedActionId}
                        onChange={onChangeActionButton}
                        placeholder="No button selected"
                        isClearable
                        isSearchable
                    >
                        {actionOptions.map((option) => (
                            <SelectOption
                                key={option.value}
                                value={option.value}
                                label={option.label}
                            />
                        ))}
                    </Select>
                </Box>
            </LayoutEditorCollapsibleControl>
            <LayoutEditorControlSeparator />
            <LayoutEditorCollapsibleControl
                label="Conditional visibility"
                startIcon={{ name: 'Eye' }}
                defaultOpen={false}
            >
                <Body size="s" mb="l" display="block">
                    Conditional visibility lets you show and hide an element, based on a filter.
                    This only changes the appearance of the app, and is not a security feature.
                </Body>
                <Filters
                    object={object}
                    value={visibilityFilters}
                    showRoleFilter
                    fields={fields}
                    onChange={onChangeVisibilityFilters}
                    withHeader={false}
                    place="bottom-end"
                    hideTheRecordFilter={false}
                    customButtonRender={(props) => (
                        <Box>
                            <Button
                                {...props}
                                size="2xs"
                                variant="primary"
                                startIcon={{ name: 'Plus' }}
                            >
                                Add filter
                            </Button>
                        </Box>
                    )}
                />
            </LayoutEditorCollapsibleControl>
            <LayoutEditorControlSeparator />
            <Box px="l">
                <Field
                    htmlFor="dismissable"
                    label="Dismissable"
                    startIcon={{ name: 'X' }}
                    rightSlotContent={
                        <Toggle
                            id="dismissable"
                            checked={isDismissable}
                            onCheckedChange={onChangeIsDismissable}
                        />
                    }
                />
            </Box>
        </Box>
    )
}
