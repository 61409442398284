import React from 'react'

import { DEFAULT_WIDGET_AREA_ID } from 'features/views/LayoutEditor/constants'
import { LayoutEditorWidgetArea } from 'features/views/LayoutEditor/LayoutEditorWidgetArea'

import { Box } from 'ui/components/Box'

import { useDetailViewCompactLayoutState } from './hooks/useDetailViewCompactLayoutState'
import { DetailViewSidebar } from './DetailViewSidebar'

import { DetailViewCompactLayoutMainStyles } from './DetailViewLayout.css'

type DetailViewCompactLayoutProps = {}

export const DetailViewCompactLayout: React.FC<DetailViewCompactLayoutProps> = () => {
    const { sidebarStyle, headerStyle, rightOffset } = useDetailViewCompactLayoutState()

    const isSlimHeader = headerStyle === 'slim'

    const topPadding = {
        mobile: 0,
        tablet: isSlimHeader ? '3xl' : 0,
    } as const

    const sidePadding = {
        mobile: 0,
        tablet: '3xl',
    } as const

    const sideBorderWidth = {
        mobile: undefined,
        tablet: isSlimHeader ? 1 : 0,
    } as const

    const sidebarHeight = {
        mobile: undefined,
        tablet: isSlimHeader ? 'full' : undefined,
    } as const

    return (
        <Box
            display="grid"
            height="full"
            grow
            maxWidth="full"
            minWidth={0}
            pl={{
                mobile: 'xl',
                tablet: '3xl',
            }}
            pr={{
                mobile: 'xl',
                tablet: '3xl',
            }}
            overflowY={{
                mobile: 'auto',
                tablet: 'visible',
            }}
            position="relative"
            style={{
                marginRight: rightOffset,
            }}
        >
            <Box
                as="main"
                className={DetailViewCompactLayoutMainStyles.styleFunction({
                    sidebarStyle,
                })}
            >
                {sidebarStyle === 'left' && (
                    <DetailViewSidebar
                        pt={topPadding}
                        pr={sidePadding}
                        borderRightWidth={sideBorderWidth}
                        height={sidebarHeight}
                    />
                )}
                <LayoutEditorWidgetArea
                    id={DEFAULT_WIDGET_AREA_ID}
                    as="section"
                    minWidth={0}
                    maxWidth="full"
                    pt={topPadding}
                    pl={sidebarStyle === 'left' ? sidePadding : 0}
                    pr={sidebarStyle === 'right' ? sidePadding : 0}
                />
                {sidebarStyle === 'right' && (
                    <DetailViewSidebar
                        pt={topPadding}
                        pl={sidePadding}
                        borderLeftWidth={sideBorderWidth}
                        borderColor="dividerWeak"
                    />
                )}
            </Box>
        </Box>
    )
}
