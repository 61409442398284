import React from 'react'

import { ActionMenuContextProvider } from 'features/views/ListView/Actions/ActionMenuContext'
import { FiltersContextProvider } from 'features/views/ListView/Filters/FiltersContext'
import { PaginationContextProvider } from 'features/views/ListView/Pagination/PaginationContext'
import { SearchContextProvider } from 'features/views/ListView/Search/SearchContext'
import { SortContextProvider } from 'features/views/ListView/Sort/SortContext'

import { ThreadViewContextProvider } from './ThreadViewContext'
import { ThreadViewData } from './ThreadViewData'
import { ThreadViewListHeader } from './ThreadViewListHeader'

type ThreadViewProps = {}

export const ThreadView: React.FC<ThreadViewProps> = React.memo(function ThreadView() {
    return (
        <FiltersContextProvider>
            <SearchContextProvider>
                <SortContextProvider>
                    <PaginationContextProvider>
                        <ThreadViewContextProvider>
                            <ActionMenuContextProvider>
                                <ThreadViewListHeader />
                                <ThreadViewData />
                            </ActionMenuContextProvider>
                        </ThreadViewContextProvider>
                    </PaginationContextProvider>
                </SortContextProvider>
            </SearchContextProvider>
        </FiltersContextProvider>
    )
})
