import React from 'react'

import { AttachmentsAttributeDisplay } from 'features/views/attributes/AttachmentsAttributeDisplay'
import { AttachmentsAttributeTag } from 'features/views/attributes/AttachmentsAttributeTag'
import { AttachmentValue } from 'features/views/attributes/types'

import { useAttachmentsAttributeState } from './hooks/useAttachmentsAttributeState'
import { BaseAttribute } from './BaseAttribute'
import { MAX_LINES } from './constants'
import { ThreadViewAttributeComponent } from './types'

const MAX_ATTACHMENTS = 1
const MAX_ATTACHMENTS_IMAGE = 3

type AttachmentsAttributeProps = {}

export const AttachmentsAttribute: ThreadViewAttributeComponent<
    AttachmentValue[],
    AttachmentsAttributeProps
> = ({ value, attribute, isLoading, showIcon, ...props }) => {
    const { field, rawOptions, type } = attribute

    const isMessage = type === 'message'

    const { displayAsImage, displayAsTag, isEmpty, size } = useAttachmentsAttributeState({
        value,
        isLoading,
        attribute,
    })

    let maxLines: number | undefined = MAX_LINES
    if (isMessage) {
        maxLines = undefined
    }

    let maxLength: number | undefined = MAX_ATTACHMENTS
    if (displayAsImage) {
        maxLength = MAX_ATTACHMENTS_IMAGE
    }
    if (isMessage) {
        maxLength = undefined
    }

    return (
        <BaseAttribute attribute={attribute} isEmpty={isEmpty} {...props}>
            {displayAsTag ? (
                <AttachmentsAttributeTag
                    size={size}
                    value={value}
                    field={field}
                    isLoading={isLoading}
                    displayAsImage={displayAsImage}
                    imageAltText={rawOptions.imageAltText as string}
                    showIcon={showIcon}
                />
            ) : (
                <AttachmentsAttributeDisplay
                    size={size}
                    value={value}
                    field={field}
                    maxAttachments={maxLength}
                    maxLines={maxLines}
                    displayAsImage={displayAsImage}
                    imageAltText={rawOptions.imageAltText as string}
                    isLoading={isLoading}
                    minWidth={0}
                />
            )}
        </BaseAttribute>
    )
}
