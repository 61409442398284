import { useEffect, useRef } from 'react'

import { websocketHandler } from './WebsocketHandler'

export function useWebsocketListener(
    subject: string | undefined,
    listener: (message: unknown) => void
) {
    const listenerRef = useRef(listener)
    useEffect(() => {
        const listener = listenerRef.current
        if (subject && listener) {
            websocketHandler.addListener(subject, listener)
        }

        return () => {
            if (subject) {
                websocketHandler.removeListener(subject, listener)
            }
        }
    }, [subject])
}
