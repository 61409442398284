import { useMemo } from 'react'

import { formatDate } from 'utils/date/dateUtils'

import useDeepEqualsMemoValue from 'v2/ui/utils/useDeepEqualsMemoValue'

import { useThreadViewContext } from './useThreadViewContext'

export function useThreadViewContentState() {
    const { records, view, sortBy, allFields } = useThreadViewContext()

    const sortByField = allFields.find((field) => field.api_name === sortBy?.id)
    const showDayDivider =
        view.options?.threadShowDayDivider &&
        (sortByField?.type === 'date' || sortByField?.type === 'datetime')

    const showTime = sortByField?.type === 'datetime'
    const timezone = sortByField?.options?.timezone

    const groupedRecords: [string, RecordDto[]][] | undefined = useMemo(() => {
        if (!records) return undefined
        if (!showDayDivider) return [['', records]]

        const groupedByDate = records?.reduce((acc, record) => {
            const sortValue = record[sortByField.api_name] ?? undefined
            if (!sortValue) {
                return acc.set('', [...(acc.get('') ?? []), record])
            }

            const day = formatDate(sortValue, timezone, showTime).dateValue?.format('YYYY-MM-DD')
            if (!day) {
                return acc.set('', [...(acc.get('') ?? []), record])
            }

            return acc.set(day, [...(acc.get(day) ?? []), record])
        }, new Map<string, RecordDto[]>())

        const days = Array.from(groupedByDate.entries())

        return days.sort((a, b) => {
            // Keep empty day at the beginning.

            if (a[0] === '' && b[0] === '') return 0
            if (a[0] === '') return -1
            if (b[0] === '') return 1

            return 0
        })
    }, [records, showDayDivider, showTime, sortByField?.api_name, timezone])

    return useDeepEqualsMemoValue({
        groupedRecords,
        showDayDivider,
    })
}
