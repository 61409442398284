import { WidgetSchema } from 'features/views/LayoutEditor/types'

import { EmbedWidget, EmbedWidgetAdminControls } from './EmbedWidget'
import { EmbedWidgetType } from './embedWidgetTypes'

export const EmbedWidgetMeta: WidgetSchema<EmbedWidgetType> = {
    category: 'data',
    label: 'Embed',
    icon: 'Code2',
    component: EmbedWidget,
    adminControlsComponent: EmbedWidgetAdminControls,
    defaultAttrs: {
        size: 'medium',
    },
    requireFields: (attrs) => {
        const { src } = attrs

        if (src?.type === 'field') {
            return [src.fieldApiName]
        }

        return []
    },
}
