import React, { useCallback } from 'react'

import { useComposedRefs } from '@radix-ui/react-compose-refs'
import classNames from 'classnames'

import {
    FieldsWidgetAttributeStyle,
    FieldsWidgetFieldEditorStyles,
    FieldsWidgetFieldLabelStyles,
    FieldsWidgetFieldValidationErrorStyle,
    FieldsWidgetFieldValueControlsStyles,
    FieldsWidgetFieldValueStyle,
} from 'features/views/LayoutEditor/widgets/FieldsWidget/FieldsWidget.css'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'
import { Spinner } from 'ui/components/Spinner'
import { Body } from 'ui/components/Text'
import { theme } from 'ui/styling/Theme.css'

import { useBaseAttributeState } from './hooks/useBaseAttributeState'
import { FieldsWidgetAttributeProps } from './types'
import { ValuePlaceholder } from './ValuePlaceholder'

type BaseAttributeProps = React.ComponentPropsWithoutRef<typeof Box> &
    FieldsWidgetAttributeProps & {
        isEmpty?: boolean
        onClearValue?: () => void
        isClearValueButtonDisabled?: boolean
        hideClearValueButton?: boolean
        validationError?: string
    }

export const BaseAttribute = React.forwardRef<HTMLDivElement, BaseAttributeProps>(
    function BaseAttribute(
        {
            field,
            isEmpty,
            isEditingLayout,
            children,
            className,
            isClearValueButtonDisabled,
            hideClearValueButton,
            onClearValue,
            isLoading = false,
            validationError,
            ...props
        },
        ref
    ) {
        const {
            icon,
            onAttributeClick,
            onValueClear,
            isSaving,
            isEditingValue,
            showFieldIcon,
            labelPlacement,
            onKeyDown,
            attributeRef,
            isEditable,
            isLabelTop,
            isLabelLeft,
            label,
            isRequired,
        } = useBaseAttributeState({
            onClearValue,
            isLoading,
        })

        const handlePlaceholderClick = useCallback((e: React.MouseEvent<HTMLSpanElement>) => {
            e.preventDefault()
        }, [])

        const composedRef = useComposedRefs(ref, attributeRef)

        const effectiveIsClearButtonDisabled =
            typeof isClearValueButtonDisabled === 'boolean' ? isClearValueButtonDisabled : isEmpty

        const showPlaceholder = isEmpty && !isEditingValue

        return (
            <Box
                ref={composedRef}
                flex
                fontFamily="body"
                fontSize="bodyM"
                fontWeight="bodyRegular"
                lineHeight="bodyM"
                color="text"
                column={isLabelTop}
                alignItems="flex-start"
                gap={isLabelLeft ? 'm' : 0}
                trim={!isEditingLayout}
                className={classNames(FieldsWidgetAttributeStyle, className)}
                onClick={onAttributeClick}
                tabIndex={isEditable ? 0 : -1}
                onKeyDown={onKeyDown}
                {...props}
            >
                {(labelPlacement !== 'hide' || showFieldIcon) && (
                    <Box
                        flex
                        center
                        gap="s"
                        shrink
                        trim
                        className={FieldsWidgetFieldLabelStyles.styleFunction({
                            isLabelTop,
                            hasLabel: labelPlacement !== 'hide',
                            hasIcon: showFieldIcon,
                        })}
                    >
                        {!!icon && (
                            <Icon {...icon} size="l" color="icon" noShrink display="block" />
                        )}
                        {labelPlacement !== 'hide' && (
                            <Body size="m" color="text" weight="bold" trim>
                                {label}
                            </Body>
                        )}
                    </Box>
                )}
                <Box pl={isLabelTop && showFieldIcon ? '3xl' : 0} shrink width="full">
                    <Box
                        className={FieldsWidgetFieldEditorStyles.styleFunction({
                            isEditing: isEditingValue,
                            isEditable: isEditable,
                        })}
                    >
                        <Box className={FieldsWidgetFieldValueStyle}>
                            {showPlaceholder ? (
                                <ValuePlaceholder onClick={handlePlaceholderClick} field={field} />
                            ) : (
                                children
                            )}
                            {isEditable && (
                                <Box
                                    pl="s"
                                    flex
                                    justifyContent="flex-end"
                                    alignItems="flex-start"
                                    textAlign="right"
                                    height="full"
                                >
                                    <Box
                                        className={FieldsWidgetFieldValueControlsStyles.styleFunction(
                                            {
                                                isSaving,
                                                isEditable,
                                                isEditing: isEditingValue,
                                            }
                                        )}
                                    >
                                        {isSaving && (
                                            <Box p="s">
                                                <Spinner size="xs" />
                                            </Box>
                                        )}
                                        {!isSaving &&
                                            isEditable &&
                                            !isRequired &&
                                            !showPlaceholder &&
                                            !hideClearValueButton && (
                                                <ClearButton
                                                    onClick={onValueClear}
                                                    disabled={effectiveIsClearButtonDisabled}
                                                />
                                            )}
                                    </Box>
                                </Box>
                            )}
                        </Box>
                        {validationError && (
                            <Box className={FieldsWidgetFieldValidationErrorStyle}>
                                {validationError}
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        )
    }
)

type ClearButtonProps = React.ComponentPropsWithoutRef<typeof Box> & {
    disabled?: boolean
}

const ClearButton: React.FC<ClearButtonProps> = ({ disabled, onClick, ...props }) => {
    return (
        <Box
            as="button"
            cursor={disabled ? 'default' : 'pointer'}
            tabIndex={disabled ? -1 : 0}
            p="s"
            aria-label="Clear value"
            onClick={onClick}
            disabled={disabled}
            aria-disabled={disabled}
            color={{
                default: 'icon',
                hover: 'iconHover',
                active: 'iconActive',
                disabled: 'iconDisabled',
            }}
            {...props}
        >
            <Icon
                name="X"
                size="s"
                style={{
                    color: 'inherit',
                    transition: theme.transition.colors,
                }}
            />
        </Box>
    )
}
