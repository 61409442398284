import * as Y from 'yjs'

import { LayoutEditorCommands } from 'features/views/LayoutEditor/useLayoutEditorContext'

import { FIRST_COLUMN_ID, SECOND_COLUMN_ID } from './sectionWidgetConstants'

export function moveWidgetsToFirstColumn(commands: LayoutEditorCommands) {
    commands.mutateSelectedWidget(
        (data) => {
            let children = data.get('children')
            if (!children) {
                children = data.set('children', new Y.Map())
            }

            const firstColumn = children.get(FIRST_COLUMN_ID)
            const secondColumn = children.get(SECOND_COLUMN_ID)

            if (!!firstColumn && !!secondColumn) {
                // Clone the second column to avoid mutating the original objects.
                const secondColumnArray = secondColumn.toArray().map((w: any) => w.clone())
                firstColumn.push(secondColumnArray)

                children.delete(SECOND_COLUMN_ID)
            }
        },
        {
            skipHistory: true,
        }
    )
}
