// @ts-strict-ignore
import React from 'react'

import styled from '@emotion/styled'
import { get } from 'lodash'

import { supportsItemsPerPage } from 'features/records/utils/supportsItemsPerPage'
import { supportsRowsPerPage } from 'features/records/utils/supportsRowsPerPage'
import FieldPicker from 'features/studio/ui/FieldPicker'
import {
    BoardTitleSizeControlCardStyle,
    BoardTitleSizeControlIconStyle,
} from 'features/views/ListView/BoardView/BoardView.css'
import {
    CardSizeControlCardStyle,
    CardSizeIconInnerContainer,
    CardSizeIconInnerRectangle,
    CardSizeIconOuterContainer,
} from 'features/views/ListView/CardView/CardView.css'

import { Box, Checkbox, Collapse, Dropdown, Flex, Heading, Text } from 'v2/ui'
import { MAX_COLUMNS } from 'v2/ui/components/kanbanUtils'

import { RadioCard, RadioCardGroup } from 'ui/components/Radio'
import { Body } from 'ui/components/Text'

import { CardStyleSettings } from './CardStyleSettings'
import { displayType } from './DisplayTypes'
import LayoutButton from './LayoutButton'

type ListViewDisplaySettingsProps = {
    object: ObjectDto
    displayTypes: Partial<Record<ListViewDisplay, displayType>>
    config: ListViewOptions
    setConfig: (config: Partial<ListViewOptions>) => void
    getDefaultPageSize?: (display?: string) => number
    getPageSizeOptions?: (display?: string) => number[]
    onDisplayChange?: (displayType: string) => void
    showPageSizeOptions?: boolean
}

const ListViewDisplaySettings = ({
    object,
    displayTypes,
    config,
    setConfig,
    getPageSizeOptions,
    getDefaultPageSize,
    onDisplayChange,
    showPageSizeOptions,
}: ListViewDisplaySettingsProps) => {
    const changeDisplay = (value: ListViewDisplay) => {
        const patch: { display: ListViewDisplay; pageSize?: number } = { display: value }
        // Make sure the page size we end up with is one of the
        // available page sizes for the newly selected display type.
        const availablePageSizes = getPageSizeOptions?.(value) ?? []
        if (
            getDefaultPageSize &&
            config.pageSize &&
            !availablePageSizes.includes(config.pageSize)
        ) {
            patch.pageSize = getDefaultPageSize(value)
        }
        setConfig(patch)
        if (onDisplayChange) {
            onDisplayChange(value)
        }
    }

    const pageSizeOptions =
        getPageSizeOptions?.(config.display).map((x) => ({
            label: x,
            value: x,
        })) ?? []

    const pageRowsOptions = [
        { label: 3, value: 3 },
        { label: 6, value: 6 },
        { label: 9, value: 9 },
        { label: 12, value: 12 },
    ]

    const pageRowsDefaultValue = 6

    const kanBanStatusField =
        (config.display === 'kanban' || config.display === 'boardV2') &&
        config.statusField &&
        object.fields.find((field) => field._sid === config.statusField)

    const kanBanStatusFieldOptions = (get(kanBanStatusField, 'options.options') || []).concat([
        { label: 'None', value: null },
    ])
    const selectedKanbanStatusColumns =
        config.statusColumns || kanBanStatusFieldOptions.map((o) => o.value)

    return (
        <div
            style={{
                width: '420px',
            }}
        >
            <Heading as="h5" size="md" p={3} value="Layout" />
            <FlexDisplayOptions>
                {Object.entries(displayTypes).map(
                    ([type, options = {} as displayType]: [
                        type: ListViewDisplay,
                        options: displayType,
                    ]) => {
                        const isSelected = config.display === type

                        if (!options.isReleased) return null

                        return (
                            <FlexDisplayItem key={type}>
                                <LayoutButton
                                    //@ts-expect-error
                                    view={options}
                                    isSelected={isSelected}
                                    onClick={() => changeDisplay(type)}
                                    data-testid={`${options.title}-button`}
                                />
                            </FlexDisplayItem>
                        )
                    }
                )}
            </FlexDisplayOptions>
            <Flex m="10px">
                {showPageSizeOptions && (
                    <Flex mt={2} align="center">
                        <Flex style={{ flexBasis: '100%' }}>
                            {supportsItemsPerPage(config) ? (
                                <>
                                    <Text variant="dataSourceSubTitle" mr={2}>
                                        Records per page:
                                    </Text>
                                    <Dropdown
                                        value={
                                            config.pageSize || getDefaultPageSize?.(config.display)
                                        }
                                        options={pageSizeOptions}
                                        onChange={(value: number) => setConfig({ pageSize: value })}
                                        width={50}
                                        isSearchable={false}
                                    />
                                </>
                            ) : supportsRowsPerPage(config) ? (
                                <>
                                    <Text variant="dataSourceSubTitle" mr={2}>
                                        Rows per page:
                                    </Text>
                                    <Dropdown
                                        value={config.cardPageRows || pageRowsDefaultValue}
                                        options={pageRowsOptions}
                                        onChange={(value: number) =>
                                            setConfig({ cardPageRows: value })
                                        }
                                        width={50}
                                        isSearchable={false}
                                    />
                                </>
                            ) : null}
                        </Flex>
                    </Flex>
                )}

                {(config.display === 'kanban' || config.display === 'boardV2') && (
                    <Box>
                        <Text variant="adminFieldLabel" mt="0">
                            Board Status
                        </Text>
                        <Text variant="checkbox">Choose a single select status field</Text>
                        <FieldPicker
                            objectId={object._sid}
                            style={{ width: '405px', marginTop: '8px' }}
                            filter={(field) =>
                                field.type === 'dropdown' &&
                                get(field.connection_options, 'is_disabled', false) === false
                            }
                            placeholder="Select status field"
                            value={config.statusField}
                            onChange={(fieldId) => setConfig({ statusField: fieldId })}
                            isDisabled={false}
                            autoSelectAnyOption
                        />
                        {config.display === 'kanban' && (
                            <Checkbox
                                isChecked={config.userCanUpdateStatusField}
                                variant="admin"
                                mt={1}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                    setConfig({ userCanUpdateStatusField: e.target.checked })
                                }
                            >
                                Let user customize
                            </Checkbox>
                        )}
                        <Collapse isOpen={!!kanBanStatusField}>
                            {kanBanStatusField && (
                                <>
                                    <Text variant="adminFieldLabel">Columns</Text>
                                    <Text m={'0 0 8px 0'} variant="checkbox">
                                        Choose what columns you want to display
                                    </Text>
                                    <Dropdown
                                        value={selectedKanbanStatusColumns}
                                        options={kanBanStatusFieldOptions}
                                        isMulti
                                        allowNullValues
                                        onChange={(value: (string | null)[]) =>
                                            setConfig({ statusColumns: value })
                                        }
                                    />
                                    {config.display === 'kanban' &&
                                        !config.userCanUpdateStatusField && (
                                            <Checkbox
                                                isChecked={config.userCanUpdateStatusColumns}
                                                variant="admin"
                                                mt={1}
                                                onChange={(
                                                    e: React.ChangeEvent<HTMLInputElement>
                                                ) =>
                                                    setConfig({
                                                        userCanUpdateStatusColumns:
                                                            e.target.checked,
                                                    })
                                                }
                                            >
                                                Let user customize
                                            </Checkbox>
                                        )}
                                    <Collapse
                                        isOpen={
                                            !!(
                                                config.statusColumns &&
                                                config.statusColumns.length > MAX_COLUMNS
                                            )
                                        }
                                    >
                                        <Text variant="error" mt={2}>
                                            Please select a maximum of {MAX_COLUMNS} columns
                                        </Text>
                                    </Collapse>
                                </>
                            )}
                        </Collapse>
                    </Box>
                )}
                {config.display === 'tableV2' && (
                    <Box width="100%" display="flex" flexDirection="column">
                        <Box>
                            <Text variant="adminFieldLabel" mt="0">
                                Label icon
                            </Text>
                            <Box role="group">
                                <RadioCardGroup
                                    value={config.tableShowFieldIcons?.toString() ?? 'false'}
                                    onValueChange={(value: string) => {
                                        const newValue = value || undefined

                                        setConfig({
                                            tableShowFieldIcons: newValue
                                                ? newValue === 'true'
                                                : undefined,
                                        })
                                    }}
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr',
                                    }}
                                >
                                    <RadioCard value="false" icon={{ name: 'EyeOff' }}>
                                        Hide
                                    </RadioCard>
                                    <RadioCard value="true" icon={{ name: 'Eye' }}>
                                        Show
                                    </RadioCard>
                                </RadioCardGroup>
                            </Box>
                        </Box>
                    </Box>
                )}
                {config.display === 'tableV2' && (
                    <Box w="100%" mt={2}>
                        <Box display="inline-block">
                            <Checkbox
                                mr={2}
                                variant="admin"
                                isChecked={config.tablePinPrimaryColumn ?? true}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setConfig({ tablePinPrimaryColumn: e.target.checked })
                                }}
                            >
                                Pin first column
                            </Checkbox>
                        </Box>
                    </Box>
                )}
                {config.display === 'boardV2' && (
                    <Box width="100%" display="flex" flexDirection="column">
                        <Box>
                            <Text variant="adminFieldLabel" mt="0">
                                Title size
                            </Text>
                            <Box role="group">
                                <RadioCardGroup
                                    value={config.boardTitleSize ?? 'medium'}
                                    onValueChange={(value: string) => {
                                        const newValue =
                                            (value as ListViewOptions['boardTitleSize']) ||
                                            undefined

                                        setConfig({
                                            boardTitleSize: newValue,
                                        })
                                    }}
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr 1fr',
                                    }}
                                >
                                    <RadioCard
                                        value="small"
                                        icon={() => (
                                            <Box className={BoardTitleSizeControlIconStyle}>
                                                <Body size="m" weight="bold">
                                                    Abc
                                                </Body>
                                            </Box>
                                        )}
                                        className={BoardTitleSizeControlCardStyle}
                                    >
                                        Small
                                    </RadioCard>
                                    <RadioCard
                                        value="medium"
                                        icon={() => (
                                            <Box className={BoardTitleSizeControlIconStyle}>
                                                <Body size="l" weight="bold">
                                                    Abc
                                                </Body>
                                            </Box>
                                        )}
                                        className={BoardTitleSizeControlCardStyle}
                                    >
                                        Medium
                                    </RadioCard>
                                    <RadioCard
                                        value="large"
                                        icon={() => (
                                            <Box className={BoardTitleSizeControlIconStyle}>
                                                <Body size="xl" weight="bold">
                                                    Abc
                                                </Body>
                                            </Box>
                                        )}
                                        className={BoardTitleSizeControlCardStyle}
                                    >
                                        Large
                                    </RadioCard>
                                </RadioCardGroup>
                            </Box>
                        </Box>
                        <Box>
                            <Text variant="adminFieldLabel" mt="0">
                                Label style
                            </Text>
                            <Box role="group">
                                <RadioCardGroup
                                    value={config.boardLabelStyle ?? ''}
                                    onValueChange={(value: string) => {
                                        const newValue =
                                            (value as ListViewOptions['boardLabelStyle']) ||
                                            undefined

                                        setConfig({
                                            boardLabelStyle: newValue,
                                        })
                                    }}
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr 1fr',
                                    }}
                                >
                                    <RadioCard value="" icon={{ name: 'EyeOff' }}>
                                        None
                                    </RadioCard>
                                    <RadioCard value="icon" icon={{ name: 'FlaskConical' }}>
                                        Icon
                                    </RadioCard>
                                    <RadioCard value="text" icon={{ name: 'Text' }}>
                                        Text
                                    </RadioCard>
                                </RadioCardGroup>
                            </Box>
                        </Box>
                        <Box>
                            <Text variant="adminFieldLabel" mt="0">
                                Fields style
                            </Text>
                            <Box role="group">
                                <RadioCardGroup
                                    value={config.boardFieldStyle ?? 'list'}
                                    onValueChange={(value: string) => {
                                        const newValue = value as ListViewOptions['boardFieldStyle']

                                        setConfig({
                                            boardFieldStyle: newValue,
                                        })
                                    }}
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr',
                                    }}
                                >
                                    <RadioCard value="list" icon={{ name: 'LayoutList' }}>
                                        List
                                    </RadioCard>
                                    <RadioCard value="tags" icon={{ name: 'Tags' }}>
                                        Tags
                                    </RadioCard>
                                </RadioCardGroup>
                            </Box>
                        </Box>
                    </Box>
                )}

                {config.display === 'cardV2' && (
                    <Box width="100%" display="flex" flexDirection="column">
                        <Box>
                            <Text variant="adminFieldLabel" mt="0">
                                Card size
                            </Text>
                            <Box role="group">
                                <RadioCardGroup
                                    value={config.cardCardSize ?? 'medium'}
                                    onValueChange={(value: string) => {
                                        const newValue =
                                            (value as ListViewOptions['cardCardSize']) || undefined

                                        setConfig({
                                            cardCardSize: newValue,
                                        })
                                    }}
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr 1fr',
                                    }}
                                >
                                    <RadioCard
                                        value="medium"
                                        icon={() => (
                                            <Box className={CardSizeIconOuterContainer}>
                                                <div className={CardSizeIconInnerContainer}>
                                                    <div className={CardSizeIconInnerRectangle} />
                                                    <div className={CardSizeIconInnerRectangle} />
                                                    <div className={CardSizeIconInnerRectangle} />
                                                </div>
                                            </Box>
                                        )}
                                        className={CardSizeControlCardStyle}
                                    >
                                        Regular
                                    </RadioCard>
                                    <RadioCard
                                        value="large"
                                        icon={() => (
                                            <Box className={CardSizeIconOuterContainer}>
                                                <div className={CardSizeIconInnerContainer}>
                                                    <div className={CardSizeIconInnerRectangle} />
                                                    <div className={CardSizeIconInnerRectangle} />
                                                </div>
                                            </Box>
                                        )}
                                        className={CardSizeControlCardStyle}
                                    >
                                        Large
                                    </RadioCard>
                                    <RadioCard
                                        value="full_width"
                                        icon={() => (
                                            <Box className={CardSizeIconOuterContainer}>
                                                <div className={CardSizeIconInnerContainer}>
                                                    <div className={CardSizeIconInnerRectangle} />
                                                </div>
                                            </Box>
                                        )}
                                        className={CardSizeControlCardStyle}
                                    >
                                        Full-width
                                    </RadioCard>
                                </RadioCardGroup>
                            </Box>
                        </Box>
                        <CardStyleSettings
                            config={config}
                            value={config.cardCardStyle ?? 'border'}
                            onChange={(value: string) => {
                                const newValue =
                                    (value as ListViewOptions['cardCardStyle']) || undefined

                                setConfig({
                                    cardCardStyle: newValue,
                                })
                            }}
                        />
                        <Box>
                            <Text variant="adminFieldLabel" mt="0">
                                Title size
                            </Text>
                            <Box role="group">
                                <RadioCardGroup
                                    value={config.cardTitleSize ?? 'medium'}
                                    onValueChange={(value: string) => {
                                        const newValue =
                                            (value as ListViewOptions['cardTitleSize']) || undefined

                                        setConfig({
                                            cardTitleSize: newValue,
                                        })
                                    }}
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr 1fr',
                                    }}
                                >
                                    <RadioCard
                                        value="small"
                                        icon={() => (
                                            <Box className={BoardTitleSizeControlIconStyle}>
                                                <Body size="m" weight="bold">
                                                    Abc
                                                </Body>
                                            </Box>
                                        )}
                                        className={BoardTitleSizeControlCardStyle}
                                    >
                                        Small
                                    </RadioCard>
                                    <RadioCard
                                        value="medium"
                                        icon={() => (
                                            <Box className={BoardTitleSizeControlIconStyle}>
                                                <Body size="l" weight="bold">
                                                    Abc
                                                </Body>
                                            </Box>
                                        )}
                                        className={BoardTitleSizeControlCardStyle}
                                    >
                                        Medium
                                    </RadioCard>
                                    <RadioCard
                                        value="large"
                                        icon={() => (
                                            <Box className={BoardTitleSizeControlIconStyle}>
                                                <Body size="xl" weight="bold">
                                                    Abc
                                                </Body>
                                            </Box>
                                        )}
                                        className={BoardTitleSizeControlCardStyle}
                                    >
                                        Large
                                    </RadioCard>
                                </RadioCardGroup>
                            </Box>
                        </Box>
                        <Box>
                            <Text variant="adminFieldLabel" mt="0">
                                Label style
                            </Text>
                            <Box role="group">
                                <RadioCardGroup
                                    value={config.cardLabelStyle ?? ''}
                                    onValueChange={(value: string) => {
                                        const newValue =
                                            (value as ListViewOptions['cardLabelStyle']) ||
                                            undefined

                                        setConfig({
                                            cardLabelStyle: newValue,
                                        })
                                    }}
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr 1fr',
                                    }}
                                >
                                    <RadioCard value="" icon={{ name: 'EyeOff' }}>
                                        None
                                    </RadioCard>
                                    <RadioCard value="icon" icon={{ name: 'FlaskConical' }}>
                                        Icon
                                    </RadioCard>
                                    <RadioCard value="text" icon={{ name: 'Text' }}>
                                        Text
                                    </RadioCard>
                                </RadioCardGroup>
                            </Box>
                        </Box>
                    </Box>
                )}

                {config.display === 'thread' && (
                    <Box width="100%" display="flex" flexDirection="column">
                        <Box>
                            <Text variant="adminFieldLabel" mt="0">
                                Message size
                            </Text>
                            <Box role="group">
                                <RadioCardGroup
                                    value={config.threadMessageSize ?? 'medium'}
                                    onValueChange={(value: string) => {
                                        const newValue =
                                            (value as ListViewOptions['threadMessageSize']) ||
                                            undefined

                                        setConfig({
                                            threadMessageSize: newValue,
                                        })
                                    }}
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: '1fr 1fr',
                                    }}
                                >
                                    <RadioCard value="medium" icon={{ name: 'Rows4' }}>
                                        Regular
                                    </RadioCard>
                                    <RadioCard value="large" icon={{ name: 'Rows2' }}>
                                        Large
                                    </RadioCard>
                                </RadioCardGroup>
                            </Box>
                        </Box>
                        <Box>
                            <Text variant="adminFieldLabel" mt="0">
                                Title size
                            </Text>
                            <Box role="group">
                                <RadioCardGroup
                                    value={config.threadMessageTitleSize ?? 'small'}
                                    onValueChange={(value: string) => {
                                        const newValue =
                                            (value as ListViewOptions['threadMessageTitleSize']) ||
                                            undefined

                                        setConfig({
                                            threadMessageTitleSize: newValue,
                                        })
                                    }}
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(3, 1fr)',
                                    }}
                                >
                                    <RadioCard
                                        value="small"
                                        icon={() => (
                                            <Box className={BoardTitleSizeControlIconStyle}>
                                                <Body size="m" weight="bold">
                                                    Abc
                                                </Body>
                                            </Box>
                                        )}
                                        className={BoardTitleSizeControlCardStyle}
                                    >
                                        Small
                                    </RadioCard>
                                    <RadioCard
                                        value="medium"
                                        icon={() => (
                                            <Box className={BoardTitleSizeControlIconStyle}>
                                                <Body size="l" weight="bold">
                                                    Abc
                                                </Body>
                                            </Box>
                                        )}
                                        className={BoardTitleSizeControlCardStyle}
                                    >
                                        Medium
                                    </RadioCard>
                                    <RadioCard
                                        value="large"
                                        icon={() => (
                                            <Box className={BoardTitleSizeControlIconStyle}>
                                                <Body size="xl" weight="bold">
                                                    Abc
                                                </Body>
                                            </Box>
                                        )}
                                        className={BoardTitleSizeControlCardStyle}
                                    >
                                        Large
                                    </RadioCard>
                                </RadioCardGroup>
                            </Box>
                            <Box>
                                <Text variant="adminFieldLabel" mt="0">
                                    Label style
                                </Text>
                                <Box role="group">
                                    <RadioCardGroup
                                        value={config.threadFieldLabelStyle ?? ''}
                                        onValueChange={(value: string) => {
                                            const newValue =
                                                (value as ListViewOptions['threadFieldLabelStyle']) ||
                                                undefined

                                            setConfig({
                                                threadFieldLabelStyle: newValue,
                                            })
                                        }}
                                        style={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(3, 1fr)',
                                        }}
                                    >
                                        <RadioCard value="" icon={{ name: 'EyeOff' }}>
                                            None
                                        </RadioCard>
                                        <RadioCard value="icon" icon={{ name: 'FlaskConical' }}>
                                            Icon
                                        </RadioCard>
                                        <RadioCard value="text" icon={{ name: 'Text' }}>
                                            Text
                                        </RadioCard>
                                    </RadioCardGroup>
                                </Box>
                            </Box>
                        </Box>
                        <Box>
                            <Text variant="adminFieldLabel" mt="0">
                                Field direction
                            </Text>
                            <Box role="group">
                                <RadioCardGroup
                                    value={config.threadFieldDirection ?? 'horizontal'}
                                    onValueChange={(value: string) => {
                                        const newValue =
                                            (value as ListViewOptions['threadFieldDirection']) ||
                                            undefined

                                        setConfig({
                                            threadFieldDirection: newValue,
                                        })
                                    }}
                                    style={{
                                        display: 'grid',
                                        gridTemplateColumns: 'repeat(2, 1fr)',
                                    }}
                                >
                                    <RadioCard
                                        value="horizontal"
                                        icon={{ name: 'StretchVertical' }}
                                    >
                                        Horizontal
                                    </RadioCard>
                                    <RadioCard
                                        value="vertical"
                                        icon={{ name: 'StretchHorizontal' }}
                                    >
                                        Vertical
                                    </RadioCard>
                                </RadioCardGroup>
                            </Box>
                        </Box>
                        {config.threadLayout === 'split' && (
                            <Box>
                                <Text variant="adminFieldLabel" mt="0">
                                    Sender field display
                                </Text>
                                <Box role="group">
                                    <RadioCardGroup
                                        value={Boolean(config.threadHideSenderFields).toString()}
                                        onValueChange={(value: string) => {
                                            const newValue = value === 'true'

                                            setConfig({
                                                threadHideSenderFields: newValue,
                                            })
                                        }}
                                        style={{
                                            display: 'grid',
                                            gridTemplateColumns: 'repeat(2, 1fr)',
                                        }}
                                    >
                                        <RadioCard value="true" icon={{ name: 'EyeOff' }}>
                                            Hide
                                        </RadioCard>
                                        <RadioCard value="false" icon={{ name: 'Eye' }}>
                                            Show
                                        </RadioCard>
                                    </RadioCardGroup>
                                </Box>
                            </Box>
                        )}
                    </Box>
                )}

                {config.display === 'single_record' && (
                    <Box mt={2}>
                        <Checkbox
                            mr={2}
                            variant="admin"
                            isChecked={config.disableCreateForm != true}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                setConfig({ disableCreateForm: !e.target.checked })
                            }
                        >
                            Show create form view when empty
                        </Checkbox>
                    </Box>
                )}
            </Flex>
        </div>
    )
}
export default ListViewDisplaySettings

const FlexDisplayItem = styled('div')`
    justify-content: start;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 12px;
    flex-basis: 25%;
`

const FlexDisplayOptions = styled(Flex)`
    justify-content: start;
    gap: 12px 8px;
    margin-bottom: 12px;
    margin-left: 10px;
`
