import React, { useCallback } from 'react'

import { useDeleteExtConnection } from 'data/hooks/dataConnections/useDeleteExtConnection'
import { INTEGRATION_ID_TO_NAME } from 'features/DataConnections/constants'

import { Banner } from 'ui/components/Banner'
import { Box } from 'ui/components/Box'
import { Button } from 'ui/components/Button'
import {
    Modal,
    ModalCloseTrigger,
    ModalContent,
    ModalFooter,
    ModalFooterButtonGroup,
    ModalHeader,
} from 'ui/components/Modal'
import { Body } from 'ui/components/Text'

type DeleteExtConnectionConfirmationModalProps = {
    isOpen: boolean
    onOpenChange: (open: boolean) => void
    extConnection: ExtDataIntegrationConnectionDto
}
export const DeleteExtConnectionConfirmationModal: React.FC<
    DeleteExtConnectionConfirmationModalProps
> = ({ isOpen, onOpenChange, extConnection }) => {
    const { mutateAsync: deleteExtConnection, isLoading: isDeletingExtConnection } =
        useDeleteExtConnection()

    const integrationName = INTEGRATION_ID_TO_NAME[extConnection.provider_id]

    const handleDelete = useCallback(async () => {
        await deleteExtConnection({
            nangoConnectionId: extConnection.id,
            integrationId: extConnection.provider_id,
        })

        onOpenChange(false)
    }, [deleteExtConnection, extConnection.id, extConnection.provider_id, onOpenChange])

    return (
        <Modal open={isOpen} onOpenChange={onOpenChange}>
            <ModalContent>
                <ModalHeader
                    subtitle={
                        <>
                            Are you sure you want to disconnect your{' '}
                            <Body weight="bold">{integrationName}</Body> account?
                        </>
                    }
                    title="Disconnect account"
                    showCloseButton={false}
                />
                <Box px="3xl" py="xl">
                    <Banner
                        type="warning"
                        icon={{ name: 'Info' }}
                        helper="Disconnecting an external account will break any active data sources using it, accross all apps and workspaces."
                    />
                </Box>
                <ModalFooter flexDirection="column">
                    <ModalFooterButtonGroup layout="inline">
                        <ModalCloseTrigger asChild>
                            <Button size="l" variant="ghost">
                                Cancel
                            </Button>
                        </ModalCloseTrigger>
                        <Button
                            size="l"
                            variant="destructive"
                            onClick={handleDelete}
                            isLoading={isDeletingExtConnection}
                        >
                            Disconnect and remove related data
                        </Button>
                    </ModalFooterButtonGroup>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
