import React from 'react'

import { BANNER_HEIGHT } from 'features/core/appBannerConstants'
import { CollapsedNavigationTrigger } from 'features/navigation/CollapsedNavigationTrigger'
import { AdvancedFilters } from 'features/views/ListView/ListHeader/Filters/Advanced/AdvancedFilters'
import { ListHeaderAdvancedFilterControl } from 'features/views/ListView/ListHeader/Filters/Advanced/ListHeaderAdvancedFilterControl'
import { QuickFilters } from 'features/views/ListView/ListHeader/Filters/QuickFilters/QuickFilters'
import { ListHeaderAddNewButton } from 'features/views/ListView/ListHeader/ListHeaderAddNewButton'
import { ListHeaderMoreActions } from 'features/views/ListView/ListHeader/ListHeaderMoreActions'
import { ListHeaderSearchControl } from 'features/views/ListView/ListHeader/Search/ListHeaderSearchControl'
import { ListHeaderSortControl } from 'features/views/ListView/ListHeader/Sort/ListHeaderSortControl'

import { Box } from 'ui/components/Box'
import { Body, Headline } from 'ui/components/Text'
import { Tooltip } from 'ui/components/Tooltip'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'
import { theme } from 'ui/styling/Theme.css'

import { useListHeaderPortalHeaderImageState } from './hooks/useListHeaderPortalHeaderImageState'
import { useListHeaderPortalState } from './hooks/useListHeaderPortalState'

import {
    ListHeaderPortalActionsStyles,
    ListHeaderPortalCoverImageOverlayStyles,
    ListHeaderPortalCoverImageStyles,
    ListHeaderPortalNavMenuButtonStyle,
    ListHeaderPortalStyles,
} from './ListHeaderPortal.css'

const SUBTITLE_MAX_LINES = 3

type ListHeaderPortalProps = {
    isSticky?: boolean
}

export const ListHeaderPortal: React.FC<ListHeaderPortalProps> = ({ isSticky }) => {
    const {
        eyebrow,
        title,
        subtitle,
        recordCount,
        supportsInlineSorting,
        supportsQuickFilters,
        supportsAdvancedFilters,
        advancedFiltersVisible,
        toggleAdvancedFilters,
        isEmbedded,
        allowCreate,
        actionsRef,
        backgroundColor,
        headerStyle,
        showRecordCount,
        setShowRecordCount,
        coverImage,
        searchPlacement,
        textAlignment,
        isPreviewing,
        showMenuButton,
    } = useListHeaderPortalState()

    const quickFiltersPosition = useResponsiveValue({
        mobile: 'separate' as const,
        tablet: 'inline' as const,
    })

    const actionsHeight = useResponsiveValue({
        mobile: 'auto',
        tablet: supportsQuickFilters ? theme.tag.size.m : theme.button.sizes.s,
    })

    const effectiveIsSticky = isSticky && !isEmbedded

    const showToolbarSearch = useResponsiveValue({
        mobile: true,
        tablet: searchPlacement === 'toolbar',
    })

    const showHeaderSearchCentered = useResponsiveValue({
        mobile: false,
        tablet: searchPlacement === 'header' && textAlignment === 'center',
    })

    return (
        <>
            <Box
                as="header"
                pt={{
                    mobile: headerStyle === 'full' ? 0 : 'm',
                    tablet: headerStyle === 'full' ? 0 : 'xl',
                }}
                pb={{
                    mobile: headerStyle === 'full' ? 0 : 'm',
                    tablet: 0,
                }}
                px={{
                    mobile: headerStyle === 'full' ? 0 : 'm',
                    tablet: headerStyle === 'full' ? 0 : '4xl',
                }}
                width="full"
                color="textInverse"
            >
                <Box
                    flex
                    grow
                    shrink
                    minWidth={0}
                    gap={{ mobile: 'xs' }}
                    borderWidth={0}
                    px={{
                        mobile: 'l',
                        tablet: headerStyle === 'full' ? '4xl' : '3xl',
                    }}
                    pt={{
                        mobile: 'l',
                        tablet: '3xl',
                    }}
                    pb={{
                        mobile: 'l',
                        tablet: headerStyle === 'full' ? '6xl' : '3xl',
                    }}
                    overflow="hidden"
                    background={backgroundColor}
                    rounded={{
                        mobile: headerStyle === 'full' ? 0 : '2xl',
                        tablet: headerStyle === 'full' ? 0 : 'xl',
                    }}
                    alignItems={{
                        mobile: 'flex-start',
                        tablet: textAlignment === 'left' ? 'flex-end' : 'center',
                    }}
                    className={ListHeaderPortalStyles.styleFunction({
                        hasCoverImage: !!coverImage,
                    })}
                    justifyContent={{
                        mobile: 'space-between',
                        tablet: textAlignment === 'left' ? 'flex-start' : 'center',
                    }}
                    flexDirection={{ mobile: 'column', tablet: 'row' }}
                    position="relative"
                >
                    {showMenuButton && (
                        <Box
                            alignSelf="flex-start"
                            style={{ zIndex: 1 }}
                            position={{
                                mobile: 'relative',
                                desktop: 'absolute',
                            }}
                            top={{
                                desktop: '2xl',
                            }}
                            left={{
                                desktop: '2xl',
                            }}
                        >
                            <CollapsedNavigationTrigger
                                className={ListHeaderPortalNavMenuButtonStyle}
                            />
                        </Box>
                    )}
                    <Box
                        grow
                        flex
                        gap="xl"
                        alignItems={{
                            mobile: 'flex-end',
                            tablet: textAlignment === 'left' ? 'flex-end' : 'center',
                        }}
                        flexDirection={{
                            mobile: 'row',
                            tablet: textAlignment === 'left' ? 'row' : 'column',
                        }}
                        justifyContent={{
                            mobile: 'space-between',
                            tablet: textAlignment === 'left' ? 'space-between' : 'center',
                        }}
                        width="full"
                        style={{ zIndex: 1 }}
                        textAlign={{
                            mobile: 'left',
                            tablet: textAlignment === 'left' ? 'left' : 'center',
                        }}
                    >
                        <HeaderContent
                            eyebrow={eyebrow}
                            title={title}
                            subtitle={subtitle}
                            showSearch={!showToolbarSearch && !showHeaderSearchCentered}
                            textAlignment={textAlignment}
                        />
                        <Box flex center gap="m" textAlign="left" maxWidth="full" noShrink>
                            {showHeaderSearchCentered && (
                                <ListHeaderSearchControl
                                    variant="expanded"
                                    width="300px"
                                    maxWidth="full"
                                    shrink
                                />
                            )}
                            {allowCreate && (
                                <ListHeaderAddNewButton size="m" variant="secondary" noShrink />
                            )}
                        </Box>
                    </Box>
                    {coverImage && (
                        <HeaderImage src={coverImage} alt={title} textAlignment={textAlignment} />
                    )}
                </Box>
            </Box>
            <Box
                ref={actionsRef}
                position={effectiveIsSticky ? 'sticky' : 'relative'}
                px={{
                    mobile: 0,
                    tablet: headerStyle === 'full' ? '4xl' : 0,
                }}
                top={{
                    mobile: 0,
                    tablet: headerStyle === 'full' && effectiveIsSticky ? 'xl' : 0,
                }}
                width="full"
                className={ListHeaderPortalActionsStyles.styleFunction({
                    headerStyle,
                })}
                style={{
                    zIndex: effectiveIsSticky ? 198 : 0,
                    top: isPreviewing && !isEmbedded ? `${BANNER_HEIGHT}px` : undefined,
                }}
            >
                <Box
                    flex
                    flexDirection="column"
                    background="surface"
                    py={{
                        mobile: 'm',
                        tablet: 'xl',
                    }}
                    boxShadow={{
                        mobile: 0,
                        tablet: headerStyle === 'full' ? 'm' : 0,
                    }}
                    rounded={{
                        mobile: 0,
                        tablet: headerStyle === 'full' ? '2xl' : 0,
                    }}
                    gap={{ mobile: 'm', tablet: 'xl' }}
                >
                    <Box
                        flex
                        alignItems="flex-start"
                        justifyContent="space-between"
                        overflow="hidden"
                        width="full"
                        borderWidth={0}
                        borderBottomWidth={{
                            mobile: 'base',
                            tablet: 0,
                        }}
                        borderStyle="base"
                        borderColor="borderWeak"
                        px={{
                            mobile: 'xl',
                            tablet: headerStyle === 'full' ? 'xl' : '4xl',
                        }}
                        pb={{
                            mobile: 'm',
                            tablet: 0,
                        }}
                    >
                        <HeaderRecordCount
                            style={{ height: actionsHeight }}
                            supportsQuickFilters={supportsQuickFilters}
                            quickFiltersPosition={quickFiltersPosition}
                            recordCount={recordCount}
                            shouldShow={showRecordCount}
                        />
                        {quickFiltersPosition === 'inline' && supportsQuickFilters && (
                            <QuickFilters
                                rootProps={{
                                    grow: true,
                                    alignSelf: 'stretch',
                                    minWidth: 0,
                                    shrink: true,
                                    style: {
                                        minHeight: actionsHeight,
                                    },
                                }}
                            />
                        )}
                        <HeaderControls
                            style={{
                                height: actionsHeight,
                            }}
                            supportsAdvancedFilters={supportsAdvancedFilters}
                            toggleAdvancedFilters={toggleAdvancedFilters}
                            advancedFiltersVisible={advancedFiltersVisible}
                            supportsInlineSorting={supportsInlineSorting}
                            setShowRecordCount={setShowRecordCount}
                            showSearch={showToolbarSearch}
                            hideControls={isEmbedded}
                        />
                    </Box>
                    {quickFiltersPosition === 'separate' && supportsQuickFilters && (
                        <QuickFilters
                            rootProps={{
                                grow: true,
                                alignSelf: 'stretch',
                                px: {
                                    mobile: 'xl',
                                    tablet: 0,
                                },
                                style: {
                                    minHeight: actionsHeight,
                                },
                            }}
                        />
                    )}
                    {advancedFiltersVisible && (
                        <AdvancedFilters
                            grow
                            px={{
                                mobile: 'xl',
                                tablet: headerStyle === 'full' ? 'xl' : '4xl',
                            }}
                        />
                    )}
                </Box>
            </Box>
        </>
    )
}

const EYEBROW_MAX_LENGTH = 20

type HeaderContentProps = {
    eyebrow: string
    title: string
    subtitle: string
    showSearch?: boolean
    textAlignment?: ListViewHeaderPortal['textAlignment']
}

const HeaderContent: React.FC<HeaderContentProps> = ({
    eyebrow,
    title,
    subtitle,
    showSearch,
    textAlignment,
}) => {
    const titleMaxWidth = useResponsiveValue({
        mobile: '100%',
        tablet: '80%',
    })

    const subtitleMaxWidth = useResponsiveValue({
        mobile: '100%',
        tablet: '60%',
    })

    return (
        <Box
            shrink
            minWidth={0}
            flex
            grow
            column
            width={textAlignment === 'left' ? 'auto' : 'full'}
            gap="2xs"
            alignItems={{
                mobile: 'flex-start',
                tablet: textAlignment === 'left' ? 'flex-start' : 'center',
            }}
        >
            {eyebrow && (
                <Box
                    flex
                    column
                    alignItems={{
                        mobile: 'flex-start',
                        tablet: textAlignment === 'left' ? 'flex-start' : 'center',
                    }}
                >
                    <Tooltip
                        content={eyebrow}
                        side="bottom"
                        align={textAlignment === 'left' ? 'end' : 'center'}
                        zIndex={200}
                        asChild
                    >
                        <Box role="button" display="inline-block">
                            <Body
                                size={{ mobile: 's', tablet: 'm' }}
                                color="lightAlpha700"
                                maxLength={EYEBROW_MAX_LENGTH}
                            >
                                {eyebrow}
                            </Body>
                        </Box>
                    </Tooltip>
                </Box>
            )}
            {title && (
                <Box
                    flex
                    column
                    trim
                    alignItems={{
                        mobile: 'flex-start',
                        tablet: textAlignment === 'left' ? 'flex-start' : 'center',
                    }}
                    style={{ maxWidth: titleMaxWidth }}
                >
                    <Tooltip
                        content={title}
                        side="bottom"
                        align={textAlignment === 'left' ? 'end' : 'center'}
                        zIndex={200}
                        asChild
                    >
                        <Box
                            minWidth={0}
                            shrink
                            trim
                            maxWidth="full"
                            role="button"
                            display="inline-block"
                            fontSize={{
                                mobile: 'headlineM',
                                tablet: 'headlineXl',
                            }}
                        >
                            <Headline size={{ mobile: 'm', tablet: 'xl' }}>{title}</Headline>
                        </Box>
                    </Tooltip>
                </Box>
            )}
            {subtitle && (
                <Box
                    minWidth={0}
                    shrink
                    flex
                    column
                    alignItems={{
                        mobile: 'flex-start',
                        tablet: textAlignment === 'left' ? 'flex-start' : 'center',
                    }}
                    style={{ maxWidth: subtitleMaxWidth }}
                >
                    <Tooltip
                        content={subtitle}
                        side="bottom"
                        align={textAlignment === 'left' ? 'end' : 'center'}
                        zIndex={200}
                        asChild
                    >
                        <Box role="button" display="inline-block">
                            <Body
                                size={{ mobile: 'm', tablet: 'l' }}
                                maxLines={SUBTITLE_MAX_LINES}
                                color="textInverse"
                            >
                                {subtitle}
                            </Body>
                        </Box>
                    </Tooltip>
                </Box>
            )}
            {showSearch && (
                <ListHeaderSearchControl variant="expanded" width="300px" maxWidth="full" mt="m" />
            )}
        </Box>
    )
}

type HeaderControlsProps = {
    supportsAdvancedFilters?: boolean
    toggleAdvancedFilters: () => void
    advancedFiltersVisible?: boolean
    supportsInlineSorting?: boolean
    setShowRecordCount: (showRecordCount: boolean) => void
    showSearch?: boolean
    hideControls?: boolean
    style?: React.CSSProperties
}

const HeaderControls: React.FC<HeaderControlsProps> = ({
    style,
    supportsAdvancedFilters,
    toggleAdvancedFilters,
    advancedFiltersVisible,
    supportsInlineSorting,
    setShowRecordCount,
    showSearch,
    hideControls,
}) => {
    return (
        <Box
            style={{
                gap: theme.button.gap.s,
                ...style,
            }}
            flex
            center
            noShrink
            pl="m"
        >
            {showSearch && (
                <ListHeaderSearchControl
                    showLabel={false}
                    setShowRecordCount={setShowRecordCount}
                />
            )}
            {supportsAdvancedFilters && (
                <ListHeaderAdvancedFilterControl
                    onClick={toggleAdvancedFilters}
                    isActive={advancedFiltersVisible}
                />
            )}
            {supportsInlineSorting && <ListHeaderSortControl />}
            {!hideControls && <ListHeaderMoreActions />}
        </Box>
    )
}

type HeaderRecordCountProps = {
    style?: React.CSSProperties
    supportsQuickFilters?: boolean
    quickFiltersPosition?: 'inline' | 'separate'
    recordCount?: string
    shouldShow?: boolean
    supportsAdvancedFilters?: boolean
}

const HeaderRecordCount: React.FC<HeaderRecordCountProps> = ({
    style,
    supportsQuickFilters,
    quickFiltersPosition,
    recordCount,
    shouldShow,
}) => {
    const isVisibleInline = !supportsQuickFilters && quickFiltersPosition === 'inline'
    const isVisibleSeparate = quickFiltersPosition === 'separate'

    return (
        <Box flex center shrink trim alignSelf="stretch" color="textWeaker" style={style}>
            {(isVisibleInline || isVisibleSeparate) && recordCount && shouldShow && (
                <Body
                    size={{ mobile: 's', tablet: 'm' }}
                    weight={{ mobile: 'medium', tablet: 'regular' }}
                    color="textWeaker"
                >
                    Showing {recordCount}
                </Body>
            )}
        </Box>
    )
}

type HeaderImageProps = {
    src: string
    alt: string
    textAlignment?: ListViewHeaderPortal['textAlignment']
}

const HeaderImage: React.FC<HeaderImageProps> = React.memo(function HeaderImage({
    src,
    alt,
    textAlignment,
}) {
    const { isLoaded, onLoad } = useListHeaderPortalHeaderImageState({ src })

    return (
        <Box
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            pointerEvents="none"
            style={{ zIndex: 0 }}
        >
            <Box
                className={ListHeaderPortalCoverImageOverlayStyles.styleFunction({
                    textAlignment,
                })}
            />
            <Box
                as="img"
                src={src}
                alt={alt}
                className={ListHeaderPortalCoverImageStyles.styleFunction({
                    isLoaded,
                })}
                onLoad={onLoad}
            />
        </Box>
    )
})
