import React from 'react'

import { Box } from 'ui/components/Box'
import { Body } from 'ui/components/Text'

type EmbeddedServerErrorEmptyStateProps = {}

export const EmbeddedServerErrorEmptyState: React.FC<EmbeddedServerErrorEmptyStateProps> =
    React.memo(function EmbeddedServerErrorEmptyState() {
        return (
            <Box flex flexDirection="column" alignSelf="stretch" py={{ mobile: '2xl', tablet: 0 }}>
                <Body size="m" color="textError">
                    Something&apos;s wrong. We were unable to connect to the service
                </Body>
            </Box>
        )
    })
