import { useMemo } from 'react'

import { useThreadViewContext } from 'features/views/ListView/ThreadView/hooks/useThreadViewContext'
import { ThreadViewAttribute } from 'features/views/ListView/ThreadView/types'

type UseThreadViewBaseAttributeStateOptions = {
    attribute: ThreadViewAttribute
}

export function useThreadViewBaseAttributeState(options: UseThreadViewBaseAttributeStateOptions) {
    const { attribute } = options

    const { labelStyle } = useThreadViewContext()

    const showLabel = labelStyle === 'text' && !!attribute.title && attribute.type === 'content'
    const showIcon = labelStyle === 'icon' && !!attribute.icon && attribute.type === 'content'

    let emptyPlaceholder = 'None'
    if (attribute.type === 'message') {
        emptyPlaceholder = 'No content'
    } else if (attribute.type !== 'title' || !showLabel) {
        emptyPlaceholder = `No ${attribute.title.toLowerCase()}`
    }

    return useMemo(
        () => ({ showLabel, showIcon, emptyPlaceholder }),
        [showIcon, showLabel, emptyPlaceholder]
    )
}
