import { WidgetSchema } from 'features/views/LayoutEditor/types'

import { TextWidget, TextWidgetAdminControls, TextWidgetType } from './TextWidget'

export const TextWidgetMeta: WidgetSchema<TextWidgetType> = {
    category: 'hidden',
    label: 'Text',
    icon: 'Text',
    component: TextWidget,
    adminControlsComponent: TextWidgetAdminControls,
}
