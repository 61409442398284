import React, { useContext } from 'react'

import * as Y from 'yjs'

import { LayoutEditorSchema, Widget } from './types'

// Using an interface instead of a type alias so we could extend it from other files.
// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
export interface LayoutEditorCommands {
    initEditor: (options: { viewSid: string }) => void
    closeEditor: () => void
    saveViewChanges: () => Promise<void>
    discardViewChanges: () => void
    updateViewName: (newName: string) => void
    insertWidgetAtPath: (widgetType: string, path: string[]) => void
    selectWidgetAtPath: (widgetId: string, path: string[]) => void
    deselectWidget: () => void
    removeSelectedWidget: () => void
    duplicateSelectedWidget: () => void
    onChangeSelectedWidgetAttrs: (tr: (widget: Y.Map<any>) => void) => void
    mutateWidgetAtPath: (
        widgetId: string,
        path: string[],
        tr: (widget: Y.Map<any>) => void,
        options?: {
            skipHistory?: boolean
        }
    ) => void
    mutateSelectedWidget: (
        tr: (widget: Y.Map<any>) => void,
        options?: {
            skipHistory?: boolean
        }
    ) => void
    undo: () => void
    redo: () => void
    transaction: (
        tr: (data: Y.Map<any>) => void,
        options?: {
            skipHistory?: boolean
        }
    ) => void
}

export type LayoutEditorContextValue = {
    isInitialized: boolean
    isEditing: boolean
    view?: ViewDto
    object?: ObjectDto
    stack?: StackDto
    fields: FieldDto[]
    commands: LayoutEditorCommands
    isViewDirty: boolean
    schema: LayoutEditorSchema
    isSchemaOutdated: boolean
    selection?: {
        type: 'widget'
        widget: Widget
        path: string[]
        widgetPath: Widget[]
        layoutArea: 'sidebar' | 'content'
    }

    handleCopyEvent: (e: React.ClipboardEvent) => void
    handlePasteEvent: (e: React.ClipboardEvent) => void
    urlParams: {
        values: Record<string, string>
        setParam: (key: string, value?: string, replace?: boolean) => void
    }
    requiredFields?: string[]
}
export const LayoutEditorContext = React.createContext<LayoutEditorContextValue | null>(null)

export function useLayoutEditorContext(): LayoutEditorContextValue {
    const context = useContext(LayoutEditorContext)

    if (!context) {
        throw new Error('useLayoutEditorContext must be used within a LayoutEditorContextProvider')
    }

    return context
}
