import React from 'react'

import { useThumbnailState } from 'features/views/attributes/hooks/useThumbnailState'
import { WidgetComponent } from 'features/views/LayoutEditor/types'

import { Box } from 'ui/components/Box'
import { Icon } from 'ui/components/Icon'
import { Link } from 'ui/components/Link'
import { Body } from 'ui/components/Text'

import { useLinkBlocksWidgetLinkListState } from './hooks/useLinkBlocksWidgetLinkListState'
import {
    LinkBlocksWidgetLink,
    LinkBlocksWidgetLinkHeaderIcon,
    LinkBlocksWidgetLinkHeaderImage,
    LinkBlocksWidgetType,
} from './types'
import { isEmptyLink, isInlineLink } from './utils'

import {
    LinkBlocksWidgetLinkIconWrapperStyles,
    LinkBlocksWidgetLinkImageStyle,
    LinkBlocksWidgetLinkImageWrapperStyles,
    LinkBlocksWidgetLinkListItemContentStyles,
    LinkBlocksWidgetLinkListItemStyles,
    LinkBlocksWidgetLinkListStyles,
    LinkBlocksWidgetLinkTargetWrapperStyles,
    LinkBlocksWidgetStyle,
} from './LinkBlocksWidget.css'

type LinkBlocksWidgetProps = {}

export const LinkBlocksWidget: WidgetComponent<LinkBlocksWidgetType, LinkBlocksWidgetProps> = ({
    widget,
    isEditing,
}) => {
    return (
        <Box
            py="l"
            pointerEvents={isEditing ? 'none' : undefined}
            className={LinkBlocksWidgetStyle}
        >
            <LinkBlocksWidgetLinkList widget={widget} />
        </Box>
    )
}

type LinkBlocksWidgetLinkListProps = {
    widget: LinkBlocksWidgetType
}

const LinkBlocksWidgetLinkList: React.FC<LinkBlocksWidgetLinkListProps> = ({ widget }) => {
    const { wrapperRef, visibleLinks, hasOnlyInlineLinks, size, style } =
        useLinkBlocksWidgetLinkListState(widget)

    return (
        <Box
            ref={wrapperRef}
            className={LinkBlocksWidgetLinkListStyles.styleFunction({
                size,
                style,
                hasOnlyInlineLinks,
            })}
        >
            {visibleLinks.map((l) => (
                <LinkBlocksWidgetLinkListItem key={l.id} link={l} size={size} style={style} />
            ))}
        </Box>
    )
}

const REGULAR_ITEM_TITLE_MAX_LINES = 2
const REGULAR_ITEM_SUBTITLE_MAX_LINES = 3
const FULL_WIDTH_ITEM_TITLE_MAX_LINES = 1
const FULL_WIDTH_ITEM_SUBTITLE_MAX_LINES = 2

type LinkBlocksWidgetLinkListItemProps = {
    link: LinkBlocksWidgetLink
    size: LinkBlocksWidgetType['attrs']['size']
    style: LinkBlocksWidgetType['attrs']['style']
}

const LinkBlocksWidgetLinkListItem: React.FC<LinkBlocksWidgetLinkListItemProps> = ({
    link,
    size,
    style,
}) => {
    const { title, subtitle } = link

    const isInline = isInlineLink(link)
    const hasImageHeader = link.header?.type === 'image'

    const hasSingleTextLabel = (!!title && !subtitle) || (!title && !!subtitle)
    const isEmpty = isEmptyLink(link)

    if (isEmpty) return null

    return (
        <Box
            className={LinkBlocksWidgetLinkListItemStyles.styleFunction({
                isInlineLink: isInline,
                size,
                style,
                hasImageHeader,
                hasSingleTextLabel,
            })}
        >
            <LinkBlocksWidgetLinkImage link={link} size={size} style={style} />
            <LinkBlocksWidgetLinkIcon
                link={link}
                size={size}
                style={style}
                isInlineLink={isInline}
            />
            <Box
                className={LinkBlocksWidgetLinkListItemContentStyles.styleFunction({
                    size,
                    style,
                    hasImageHeader,
                })}
            >
                {(!!title || !!subtitle) && (
                    <Box flex column gap="2xs" minWidth={0}>
                        {!!title && (
                            <Body
                                maxLines={
                                    size === 'fullWidth'
                                        ? FULL_WIDTH_ITEM_TITLE_MAX_LINES
                                        : REGULAR_ITEM_TITLE_MAX_LINES
                                }
                                color="text"
                                size="m"
                                weight="bold"
                                style={{ wordBreak: 'break-word' }}
                                trim={size === 'fullWidth'}
                            >
                                {title}
                            </Body>
                        )}
                        {!!subtitle && (
                            <Body
                                maxLines={
                                    size === 'fullWidth'
                                        ? FULL_WIDTH_ITEM_SUBTITLE_MAX_LINES
                                        : REGULAR_ITEM_SUBTITLE_MAX_LINES
                                }
                                color="textWeaker"
                                size="s"
                                weight="regular"
                                style={{ wordBreak: 'break-word' }}
                            >
                                {subtitle}
                            </Body>
                        )}
                    </Box>
                )}
                <LinkBlocksWidgetLinkTarget
                    link={link}
                    size={size}
                    style={style}
                    hasSingleTextLabel={hasSingleTextLabel}
                />
            </Box>
        </Box>
    )
}

type LinkBlocksWidgetLinkImageProps = {
    link: LinkBlocksWidgetLink
    size: LinkBlocksWidgetType['attrs']['size']
    style: LinkBlocksWidgetType['attrs']['style']
}

const LinkBlocksWidgetLinkImage: React.FC<LinkBlocksWidgetLinkImageProps> = ({
    link,
    size,
    style,
}) => {
    const { header, title } = link
    const src = (header as LinkBlocksWidgetLinkHeaderImage)?.image?.url
    const alt = title || 'Link block image'

    const { isError, isLoaded, onError, onLoad } = useThumbnailState({ src })

    if (header?.type !== 'image') return null

    return (
        <Box
            className={LinkBlocksWidgetLinkImageWrapperStyles.styleFunction({
                size,
                style,
            })}
            noShrink
        >
            {(isError || !src) && <Icon name="ImageOff" color="icon" size="l" noShrink />}
            {!isError && !!src && (
                <Box
                    as="img"
                    width="full"
                    height="full"
                    src={src}
                    opacity={isLoaded ? 1 : 0}
                    onLoad={onLoad}
                    onError={onError}
                    alt={alt}
                    pointerEvents="none"
                    className={LinkBlocksWidgetLinkImageStyle}
                />
            )}
        </Box>
    )
}

type LinkBlocksWidgetLinkIconProps = {
    link: LinkBlocksWidgetLink
    size: LinkBlocksWidgetType['attrs']['size']
    style: LinkBlocksWidgetType['attrs']['style']
    isInlineLink: boolean
}

const LinkBlocksWidgetLinkIcon: React.FC<LinkBlocksWidgetLinkIconProps> = ({
    link,
    isInlineLink,
    size,
    style,
}) => {
    const { header } = link
    if (header?.type !== 'icon') return null

    const icon = (header as LinkBlocksWidgetLinkHeaderIcon)?.icon

    return (
        <Box
            className={LinkBlocksWidgetLinkIconWrapperStyles.styleFunction({
                isInlineLink,
                size,
                style,
            })}
            noShrink
        >
            {icon && <Icon family={icon.type} name={icon.name} size="s" noShrink />}
        </Box>
    )
}

type LinkBlocksWidgetLinkTargetProps = {
    link: LinkBlocksWidgetLink
    size: LinkBlocksWidgetType['attrs']['size']
    style: LinkBlocksWidgetType['attrs']['style']
    hasSingleTextLabel: boolean
}

const LinkBlocksWidgetLinkTarget: React.FC<LinkBlocksWidgetLinkTargetProps> = ({
    link,
    size,
    style,
    hasSingleTextLabel,
}) => {
    const { link: linkTarget, title, subtitle } = link
    if (!linkTarget?.href) return null

    const label = linkTarget.label || linkTarget.href
    const hasLabel = !!title || !!subtitle

    return (
        <Box
            className={LinkBlocksWidgetLinkTargetWrapperStyles.styleFunction({
                hasSingleTextLabel,
                hasLabel,
                size,
                style,
            })}
            noShrink
        >
            <Link
                href={linkTarget.href}
                target={linkTarget.openAs === 'new_tab' ? '_blank' : undefined}
            >
                <Box flex center gap="2xs">
                    <Box minWidth={0} trim>
                        {label}
                    </Box>
                    <Icon name="ArrowRight" size="s" noShrink />
                </Box>
            </Link>
        </Box>
    )
}
