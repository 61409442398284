import React from 'react'

import classNames from 'classnames'

import { RecordActions } from 'features/views/ListView/Actions/RecordActions'
import { ThreadViewMessageOnHoverStyle } from 'features/views/ListView/ThreadView/ThreadView.css'

import { Box } from 'ui/components/Box'
import { stopPropagation } from 'ui/helpers/utilities'
import { useResponsiveValue } from 'ui/styling/helpers/useResponsiveValue'

import { useThreadViewRecordActionsState } from './hooks/useThreadViewRecordActionsState'

type ThreadViewRecordActionsProps = React.ComponentPropsWithoutRef<typeof Box> & {
    record?: RecordDto
    includeFields: string[]
    actionButtons?: ActionDto[]
    additionalEditFields?: FieldDto[]
}

export const ThreadViewRecordActions: React.FC<ThreadViewRecordActionsProps> = React.memo(
    function ThreadViewRecordActions({
        record,
        className,
        style,
        additionalEditFields,
        actionButtons,
        includeFields,
        ...props
    }) {
        const { isOpen, setIsOpen } = useThreadViewRecordActionsState()

        const opacity = useResponsiveValue({
            mobile: 1,
            tabletLarge: isOpen ? 1 : undefined,
        })

        if (!record || !actionButtons?.length) return null

        return (
            <Box
                flex
                center
                style={{
                    opacity,
                    ...style,
                }}
                className={classNames(className, ThreadViewMessageOnHoverStyle)}
                {...props}
            >
                <RecordActions
                    actionButtons={actionButtons}
                    onOpenChange={setIsOpen}
                    onCloseAutoFocus={stopPropagation}
                    record={record}
                    additionalEditFields={additionalEditFields}
                    includeFields={includeFields}
                    menuVariant="ghost"
                />
            </Box>
        )
    }
)
