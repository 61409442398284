import React from 'react'

import { GorgiasInputs } from './GorgiasInputs'
import { NetSuiteInputs } from './NetSuiteInputs'
import { ConnectionSetupProps } from './types'

const CONNECTION_SETUP_COMPONENTS: Partial<
    Record<ExternalIntegrationId, React.FC<ConnectionSetupProps>>
> = {
    gorgias: GorgiasInputs,
    'netsuite-tba': NetSuiteInputs,
}

type ConnectionSetupInputsProps = {
    integrationId: ExternalIntegrationId
    handleConfirmed: (config: any) => void
    handleClose: () => void
}
export const ConnectionSetupInputs: React.FC<ConnectionSetupInputsProps> = ({
    integrationId,
    handleConfirmed,
    handleClose,
}) => {
    const ConnectionSetupComponent = CONNECTION_SETUP_COMPONENTS[integrationId]
    return ConnectionSetupComponent ? (
        <ConnectionSetupComponent
            integrationId={integrationId}
            handleConfirmed={handleConfirmed}
            handleClose={handleClose}
        />
    ) : null
}
