import React from 'react'

import { OutputSchemaEditor } from 'features/workflows/OutputSchema/OutputSchemaEditor'
import { ConfigurationLabel } from 'features/workflows/WorkflowUI'

import { WorkflowActionEditorComponent } from './types'

export const SetVariablesActionConfig: WorkflowActionEditorComponent = ({ action, onChange }) => {
    const newVariablesSchema = action.settings?.new_variables as WorkflowOutputSchema | undefined

    const handleChangeSettings = (patch: Record<string, unknown>) => {
        onChange({
            settings: {
                ...action.settings,
                ...patch,
            },
        })
    }

    return (
        <>
            <ConfigurationLabel>New variables</ConfigurationLabel>
            <OutputSchemaEditor
                value={newVariablesSchema}
                onChange={(value) => handleChangeSettings({ new_variables: value })}
                mb="l"
            />
        </>
    )
}
